import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import Header from "../../header/header";
import { getHeadersShared } from "../../utility/utility";

class CountryOptions extends Component {
  state = {
    outboundLimitCtl: "",
    inboundLimitCtl: "",
    viewCurtain: "",
    govtSanctionSwitch: "",
    outboundCorridorLimitCtl: "",
    blockDisplayPrincipal: "",
    outboundAgtNetworkLimitCtl: "",
    usdExchangeRateCalculation: "",
    fraudPreventionFilter: "",
    apnMaxPayoutBypass: "",
    receiverNotifEx: "",
    noOfDaysForFullRefunds: "",
    duplicateMtcnDetection: "",
    sendReceivNameMinLength: "",
    retainFinancialAtPayTime: "",
    allowQuickCollectSend: "",
    usdMaxPayout: "",
    airEmailNotification: "",
    airSMSNotification: "",
    showCountryOptionsConfirm: false,
    submitStatusCountryOptions: null,
    submitMsgCountryOptions: "",
  };

  componentDidMount = () => {
    const countryCode = this.props.location.state.data.countryCode;
    this.setState({
      tokenCM: this.props.location.state.state.tokenCM,
      accessGrantedCM: this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/flags/${countryCode}`,

        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        this.setState({
          countryCode: this.props.location.state.data.countryCode,
          outboundLimitCtl: response.data.outboundLimitCtl,
          inboundLimitCtl: response.data.inboundLimitCtl,
          viewCurtain: response.data.viewCurtain,
          govtSanctionSwitch: response.data.govtSanctionSwitch,
          outboundCorridorLimitCtl: response.data.outboundCorridorLimitCtl,
          blockDisplayPrincipal: response.data.blockDisplayPrincipal,
          outboundAgtNetworkLimitCtl: response.data.outboundAgtNetworkLimitCtl,
          usdExchangeRateCalculation: response.data.usdExchangeRateCalculation,
          fraudPreventionFilter: response.data.fraudPreventionFilter,
          duplicateMtcnDetection: response.data.duplicateMtcnDetection,
          sendReceivNameMinLength: response.data.sendReceivNameMinLength,
          retainFinancialAtPayTime: response.data.retainFinancialAtPayTime,
          allowQuickCollectSend: response.data.allowQuickCollectSend,
          usdMaxPayout: response.data.usdMaxPayout,
          airEmailNotification: response.data.airEmailNotification,
          airSMSNotification: response.data.airSMSNotification,
          apnMaxPayoutBypass: response.data.apnMaxPayoutBypass,
          receiverNotifEx: response.data.receiverNotifEx
        });

        if (response.data.noOfDaysForFullRefunds == 'A') {
          this.setState({
            noOfDaysForFullRefunds: 'A - 30 Days'
          });
        } else if (response.data.noOfDaysForFullRefunds == 'B') {
          this.setState({
            noOfDaysForFullRefunds: 'B - 60 Days'
          });
        } else if (response.data.noOfDaysForFullRefunds == 'C') {
          this.setState({
            noOfDaysForFullRefunds: 'C - 90 Days'
          });
        } else if (response.data.noOfDaysForFullRefunds == 'D') {
          this.setState({
            noOfDaysForFullRefunds: 'D - 120 Days'
          });
        } else {
          this.setState({
            noOfDaysForFullRefunds: response.data.noOfDaysForFullRefunds
          });
        }
      });
  };

  onChangeControlCountryOptions = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onHandleBackCountryOptions = () => {
    const data = {
      countryCode: this.props.location.state.data.countryCode,
      countryName: this.props.location.state.data.countryName
    }
    this.props.history.push({
      pathname: "/country-details",
      state: { mode: null, data: data, state: this.state },
    });
  };

  onHandleUpdateCountryOptions = () => {
    // if (!this.onValidateCountryOptions()) {
    //   return;
    // }
    let submittedObj = {};
    submittedObj.countryCode = this.props.location.state.data.countryCode;
    submittedObj.outboundLimitCtl = this.state.outboundLimitCtl;
    submittedObj.inboundLimitCtl = this.state.inboundLimitCtl;
    submittedObj.viewCurtain = this.state.viewCurtain;
    submittedObj.govtSanctionSwitch = this.state.govtSanctionSwitch;
    submittedObj.outboundCorridorLimitCtl = this.state.outboundCorridorLimitCtl;
    submittedObj.blockDisplayPrincipal = this.state.blockDisplayPrincipal;
    submittedObj.outboundAgtNetworkLimitCtl = this.state.outboundAgtNetworkLimitCtl;
    submittedObj.usdExchangeRateCalculation = this.state.usdExchangeRateCalculation;
    submittedObj.fraudPreventionFilter = this.state.fraudPreventionFilter;
    submittedObj.duplicateMtcnDetection = this.state.duplicateMtcnDetection;
    submittedObj.sendReceivNameMinLength = this.state.sendReceivNameMinLength;
    submittedObj.retainFinancialAtPayTime = this.state.retainFinancialAtPayTime;
    submittedObj.allowQuickCollectSend = this.state.allowQuickCollectSend;
    submittedObj.usdMaxPayout = this.state.usdMaxPayout;
    submittedObj.airEmailNotification = this.state.airEmailNotification;
    submittedObj.airSMSNotification = this.state.airSMSNotification;
    submittedObj.apnMaxPayoutBypass = this.state.apnMaxPayoutBypass;
    submittedObj.receiverNotifEx = this.state.receiverNotifEx;
    if (this.state.noOfDaysForFullRefunds == 'A - 30 Days') {
      submittedObj.noOfDaysForFullRefunds = 'A';
    } else if (this.state.noOfDaysForFullRefunds == 'B - 60 Days') {
      submittedObj.noOfDaysForFullRefunds = 'B';
    } else if (this.state.noOfDaysForFullRefunds == 'C - 90 Days') {
      submittedObj.noOfDaysForFullRefunds = 'C';
    } else if (this.state.noOfDaysForFullRefunds == 'D - 120 Days') {
      submittedObj.noOfDaysForFullRefunds = 'D';
    } else {
      submittedObj.noOfDaysForFullRefunds = this.state.noOfDaysForFullRefunds;
    }
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/flags`,
        submittedObj,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            submitStatusCountryOptions: "OK",
            submitMsgCountryOptions: response.data.responseString,
          });
        } else {
          this.setState({
            submitStatusCountryOptions: "Error",
            submitMsgCountryOptions: response.data.responseString,
          });
        }
      });
    this.setState({ showCountryOptionsConfirm: false });
  };

  getCountryOptionsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showCountryOptionsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Update Country Flag Details?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showCountryOptionsConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleUpdateCountryOptions}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  getsendReceivNameMinLengthValues = () => {
    let countryOptionsMinLength = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "N"];
    let countryOptionsValuesMinLength = countryOptionsMinLength.map((item, i) => (
      <option key={item + i} value={item}>
        {item}
      </option>
    ));
    return [<option value="">-Select-</option>, countryOptionsValuesMinLength];
  }
  getCountryOptionsValues = () => {
    let countryOptions = ["Y", "N"];
    let countryOptionsValues = countryOptions.map((item, i) => (
      <option key={item + i} value={item}>
        {item}
      </option>
    ));
    return [<option value=" ">-Select-</option>, countryOptionsValues];
  };

  getRefundDayOptionsValues = () => {
    let refundDayOptions = ["A - 30 Days", "B - 60 Days", "C - 90 Days", "D - 120 Days"];
    let refundDayOptionsValues = refundDayOptions.map((item, i) => (
      <option key={item + i} value={item}>
        {item}
      </option>
    ));
    return [<option value=" ">-Select-</option>, refundDayOptionsValues];
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">COUNTRY OPTIONS</h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                COUNTRY OPTIONS
              </div>
              <div className="card-body card-body-custom">
                <div className="form-group row">
                  <div className="col-lg">
                    <label>
                      <span className="font-weight-bold">COUNTRY CODE: </span>
                      {this.state.countryCode}
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="outboundLimitCtl"
                      className="font-weight-bold"
                    >
                      OUTBOUND LIMITS CONTROL (Yes/No/Test)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="outboundLimitCtl"
                      value={this.state.outboundLimitCtl}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-outboundLimitCtl"
                    >
                      {this.getCountryOptionsValues()}
                      <option value="T">Test</option>
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="inboundLimitCtl"
                      className="font-weight-bold"
                    >
                      INBOUND LIMITS CONTROL (Yes/No/Test)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="inboundLimitCtl"
                      value={this.state.inboundLimitCtl}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-inboundLimitCtl"
                    >
                      {this.getCountryOptionsValues()}
                      <option value="T">Test</option>
                    </select>
                  </div>
                  <div className="col-lg">
                    <label htmlFor="viewCurtain" className="font-weight-bold">
                      VIEW CURTAIN (Yes/No)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="viewCurtain"
                      value={this.state.viewCurtain}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-viewCurtain"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="govtSanctionSwitch"
                      className="font-weight-bold"
                    >
                      GOVERNMENT SANCTION SWITCH (S/P/B)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="govtSanctionSwitch"
                      value={this.state.govtSanctionSwitch}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-govtSanctionSwitch"
                    >
                      <option value="">--Select--</option>
                      <option value="S">S</option>
                      <option value="P">P</option>
                      <option value="B">B</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="outboundCorridorLimitCtl"
                      className="font-weight-bold"
                    >
                      OUTBOUND CORRIDOR LIMITS CTL (Y/N)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="outboundCorridorLimitCtl"
                      value={this.state.outboundCorridorLimitCtl}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-outboundCorridorLimitCtl"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="blockDisplayPrincipal"
                      className="font-weight-bold"
                    >
                      BLOCK DISPLAY PRINCIPAL ON MENU (Yes/No)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="blockDisplayPrincipal"
                      value={this.state.blockDisplayPrincipal}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-blockDisplayPrincipal"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="outboundAgtNetworkLimitCtl"
                      className="font-weight-bold"
                    >
                      OUTBOUND AGENT NETWORK LIMITS CTL (Y/N)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="outboundAgtNetworkLimitCtl"
                      value={this.state.outboundAgtNetworkLimitCtl}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-outboundAgtNetworkLimitCtl"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="usdExchangeRateCalculation"
                      className="font-weight-bold"
                    >
                      USD EXCHANGE RATE CALCULATION (Y/N)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="usdExchangeRateCalculation"
                      value={this.state.usdExchangeRateCalculation}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-usdExchangeRateCalculation"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="fraudPreventionFilter"
                      className="font-weight-bold"
                    >
                      FRAUD PREVENTION FILTER (FPFUPD UPDATES)
                    </label>
                    <input
                      type="text"
                      maxLength="1"
                      value={this.state.fraudPreventionFilter}
                      className="form-control form-control-sm"
                      name="fraudPreventionFilter"
                      disabled
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-fraudPreventionFilter"
                      placeholder="FRAUD PREVENTION FILTER"
                    />
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="duplicateMtcnDetection"
                      className="font-weight-bold"
                    >
                      DUPLICATE MTCN DETECTION (Y/N)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="duplicateMtcnDetection"
                      value={this.state.duplicateMtcnDetection}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-duplicateMtcnDetection"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="sendReceivNameMinLength"
                      className="font-weight-bold"
                    >
                      SENDER/RECEIVER NAME MINIMUM LENGTH
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="sendReceivNameMinLength"
                      value={this.state.sendReceivNameMinLength}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-sendReceivNameMinLength"
                    >
                      {this.getsendReceivNameMinLengthValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="retainFinancialAtPayTime"
                      className="font-weight-bold"
                    >
                      RETAIN FINANCIAL AT PAY TIME (Y/N)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="retainFinancialAtPayTime"
                      value={this.state.retainFinancialAtPayTime}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-retainFinancialAtPayTime"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="allowQuickCollectSend"
                      className="font-weight-bold"
                    >
                      ALLOW QUICK COLLECT SEND (Y/N)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="allowQuickCollectSend"
                      value={this.state.allowQuickCollectSend}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-allowQuickCollectSend"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="usdMaxPayout"
                      className="font-weight-bold"
                    >
                      {" "}
                      USD MAX PAYOUT
                    </label>
                    <input
                      type="text"
                      maxLength="1"
                      value={this.state.usdMaxPayout}
                      className="form-control form-control-sm"
                      name="usdMaxPayout"
                      disabled
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-usdMaxPayout"
                      placeholder=" USD MAX PAYOUT"
                    />
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="airEmailNotification"
                      className="font-weight-bold"
                    >
                      AIR EMAIL NOTIFICATIONS (Y/N)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="airEmailNotification"
                      value={this.state.airEmailNotification}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-airEmailNotification"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="airSMSNotification"
                      className="font-weight-bold"
                    >
                      AIR SMS NOTIFICATIONS (Y/N)
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="airSMSNotification"
                      value={this.state.airSMSNotification}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      data-test="text-airSMSNotification"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="apnMaxPayoutBypass"
                      className="font-weight-bold"
                    >
                      APN MAX PAYOUT BYPASS
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="apnMaxPayoutBypass"
                      value={this.state.apnMaxPayoutBypass}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      //data-test="text-apnMaxPayoutBypass"
                      placeholder="APN MAX PAYOUT BYPASS"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="receiverNotifEx"
                      className="font-weight-bold"
                    >
                      RECEIVER NOTIFICATION EXCLUSION
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="receiverNotifEx"
                      value={this.state.receiverNotifEx}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      //data-test="text-receiverNotifEx"
                      placeholder="RECEIVER NOTIFICATION EXCLUSION"
                    >
                      {this.getCountryOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label
                      htmlFor="noOfDaysForFullRefunds"
                      className="font-weight-bold"
                    >
                      NUMBER OF DAYS FOR FULL REFUND
                    </label>
                    <select
                      className="form-control  form-control-sm"
                      name="noOfDaysForFullRefunds"
                      value={this.state.noOfDaysForFullRefunds}
                      onChange={(e) => {
                        this.onChangeControlCountryOptions(e);
                      }}
                      //data-test="text-noOfDaysForFullRefunds"
                      placeholder="NUMBER OF DAYS FOR FULL REFUND"
                    >
                      {this.getRefundDayOptionsValues()}
                    </select>
                  </div>
                  <div className="col-lg"></div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3" />
                  <div className="col-lg-6 text-center mar-bottom5">
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={this.onHandleBackCountryOptions}
                      data-test="button-back"
                    >
                      CNTRY DETAIL
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={() => {
                        this.setState({
                          showCountryOptionsConfirm: true,
                        });
                      }}
                      data-test="button-update"
                      disabled={
                        !this.props.location.state.state.accessGrantedCM
                      }
                    >
                      SAVE
                    </button>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4" />
                  <div className="col-lg-4">
                    {this.state.submitStatusCountryOptions === "OK" && (
                      <div className="alert alert-success alert-padding notification text-center">
                        {this.state.submitMsgCountryOptions}
                      </div>
                    )}
                    {this.state.submitStatusCountryOptions === "Error" && (
                      <div className="alert alert-danger alert-padding notification text-center">
                        {this.state.submitMsgCountryOptions}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.getCountryOptionsModal()}
          </div>
        </div>
      </>
    );
  }
}
export default CountryOptions;
