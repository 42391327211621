import React, { Component } from "react";
import Header from "../../header/header";
import Sidebar from "../sidebar/sidebar";
import Home from "../home/home";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import PhoneBlock from "../gsp-sys-tables/phone-block/phone-block";
import NameBlock from "../gsp-sys-tables/name-block/name-block";
import NetworkBlock from "../gsp-sys-tables/network-block/network-block";
import CorridorBlock from "../gsp-sys-tables/corridor-block/corridor-block";
import Corridor2Block from "../gsp-sys-tables/corridor2-block/corridor2-block";
import QuickPayTable from "../gsp-sys-tables/quick-pay-block/quick-pay-block";
import IsoRegistrationBlock from "../gsp-sys-tables/iso-registration-block/iso-registration-block";
import SettlementReasonCodeBlock from "../gsp-sys-tables/settlement-reason-code-block/settlement-reason-code-block";

export default withOktaAuth(
  class Dashboard extends Component {
    constructor(props) {
      super(props);
      this.state = {
        name: "Home",
        component: {
          Home: Home,
          Phone: PhoneBlock,
          Name: NameBlock,
          Network: NetworkBlock,
          Corridor: CorridorBlock,
          Corridor2: Corridor2Block,
          Quick: QuickPayTable,
          Iso: IsoRegistrationBlock,
          Settlement: SettlementReasonCodeBlock,
          accessToken: "",
          role: [],
          accessGranted: false,
        },
      };
      this.changeComponent = this.changeComponent.bind(this);
    }
    changeComponent(comp) {
      this.setState({
        name: comp,
      });
    }
    componentDidMount() {
      const accessToken = this.props.authState.accessToken;
      const jwt = this.props.authState.accessToken.value;
      try {
        const token = jwt_decode(accessToken.value);
        const accessRole = jwt_decode(accessToken.value).role;
        const accessGrant = 
          accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READ)||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READWRITE);
        this.setState({
          jwtToken:jwt,
          accessToken: token,
          role: accessRole,
          accessGranted: accessGrant,
        });
        // valid token format
      } catch (error) {
        // invalid token format
      }
    }
    render() {
      const TagName = this.state.component[this.state.name];
      return this.props.authState.isAuthenticated ? (
        <div className="dashboard">
          <Header
            changeComponent={this.changeComponent}
            history={this.props.history}
            heading="GLOBAL SYSTEM PARAMETER" 
          />
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2">
                {this.state.accessGranted && <Sidebar changeComponent={this.changeComponent} /> }
              </div>
              <div className="col-lg-10">
                {this.state.accessGranted ? (
                  <TagName
                    history={this.props.history}
                    accessRole={this.state.role}
                    jwtToken={this.state.jwtToken}
                  />
                ) : <div style={{marginLeft:"300px",marginTop:"50px"}}>Unauthorized! Access Is Denied.</div>}
              </div>
            </div>
          </div>
        </div>
      ) : null;
    }
  }
);
