import React, { Component } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { getHeadersCustomer } from "../../utility/utility";

class WebTmtCustomerInformation extends Component {
  state = {
    records: [],
    singleData: {},
    count: 0,
    customerNumber: "",
    pcpNumberr: "",
    trafficAllow: "",
    trafficAllowFlag: "N",
    customerName: "",
    emailAddress: "",
    cusomerAddress: "",
    altPhone: "",
    customerDOB: "",
    custPhoneSource: "",
    customerPin: 0,
    customerLevelCode: 1,
    optValidation: "",
    optValidationFlag: "N",
    optValidtnDate: "",
    tmtGuarantee: "",
    id: "",
    password: "",
    createDate: "",
    createOPID: "",
    updateDate: "",
    updateOPID: "",
    ratingDate: "",
    ratingCode: "",
    activityDate: "",
    data: {
      customerNumber: "",
      accountNumber: "",
    },
    mtcnDate: "",
    mtcn: "",
    showWebTmtCustInformationConfirm: false,
    enableBtn: false,
    gridApi: null,
    isCustInfoWebTmtLoading: false,
    isCustInfoAcctLoading: false,
    isCustInfoCustAcctLoading: false,
    isCustInfoHistoryLoading: false,
    isCustInfoNextLoading:false,
    isCustInfoPrevLoading: false,
    isCustInfoCommLoading: false,
    submitStatusWebTmtCustInformation: null,
    submitMsgWebTmtCustInformation: "",    
  };
 
  componentDidMount() {    
    this.setState((prevState) => {
      return {
        // enableBtn: true,
        data: {
          ...prevState,
          state: this.props.location.state,
          records: this.props.location.state.records,
          singleData: this.props.location.state.singleData,
          customerId: this.props.location.state.accountNumber,
          customerNumber: this.props.location.state.customerNumber,
        },
      };

    });
    this.setState({
      state: this.props.location.state,   
      tokenWebTmt:this.props.location.state.tokenWebTmt,
      webAdmin:this.props.location.state.webAdmin,
      accessGrantedWrite: this.props.location.state.accessGrantedWrite,
      isAddressDecryprtRequired: this.props.location.state.isAddressDecryprtRequired,
      isCreditCardDecryprtRequired: this.props.location.state.isCreditCardDecryprtRequired,
      accessMTCCDisplay:this.props.location.state.accessMTCCDisplay,
      records: this.props.location.state.records,
      singleData: this.props.location.state.singleData,
      customerId: this.props.location.state.accountNumber,
      accountNumber: this.props.location.state.customerId,
      customerNumber: this.props.location.state.customerNum,
      operatorId: this.props.location.state.operatorId
    });
    let tempEnableNext =
      this.props.location.state.records.length > 1 ? true : false;
    this.setState({
      enableNext: tempEnableNext,
    });
    ///////////////////////////////////////////////////
    let customerAccountIdData;
    let customerAccountIdCount;
    if(this.props.location.state.customerAccountId){
       customerAccountIdData =this.props.location.state.records.find(o => o.customerNumber === this.props.location.state.customerAccountId) ;
       customerAccountIdCount=this.props.location.state.records.indexOf(this.props.location.state.records.find(o => o.customerNumber === this.props.location.state.customerAccountId))
    }
    //////////////////////////////////////////
    if (this.props.location.state.records.length > 0) {
      this.setState({
        records: this.props.location.state.records,
        //singleData: this.props.location.state.records[0],
        singleData:this.props.location.state.customerAccountId && customerAccountIdData?  
        customerAccountIdData: this.props.location.state.records[0],
        count: this.props.location.state.customerAccountId && customerAccountIdCount !== -1? customerAccountIdCount:this.state.count,
      });
      let currCustomerRecord = this.props.location.state.records.indexOf(this.props.location.state.records.find(o => o.customerNumber === this.props.location.state.customerAccountId));
      let count=this.props.location.state.customerAccountId && customerAccountIdCount !== -1? customerAccountIdCount:this.state.count;
      let tempEnablePrev = currCustomerRecord >0  ? true : false;
      tempEnableNext = this.props.location.state.records.length !== (count+1)  ? true : false;
      
    this.setState({
      enablePrev: tempEnablePrev,
      enableNext: tempEnableNext
    });
    }
  }

  onHandleBufferData = (type) => {
    if (type === "prev") {
      this.setState(
        {
          isCustInfoPrevLoading: true,
          count: this.state.count - 1,
        },
        () => {
          this.setState({
            enableNext: true,
            singleData: this.state.records[this.state.count],
            isCustInfoPrevLoading: false
          });
          if (this.state.count === 0) {
            this.setState({
              enablePrev: false,
              enableNext: true,
            });
          }
        }
      );
    } else {
      if (this.state.count < this.state.records.length - 1) {
        this.setState(
          {
            isCustInfoNextLoading: true,
            count: this.state.count + 1,
          },
          () => {
            this.setState({
              enablePrev: true,
              singleData: this.state.records[this.state.count],
              isCustInfoNextLoading: false
            });
            if (this.state.count === this.state.records.length - 1) {
              this.setState({
                enablePrev: true,
                enableNext: false,
              });
            }
          }
        );
      }
    }
  };

  onChangeWebTmtCustInformation = (e) => {    
    this.setState({
      [e.target.name]: e.target.value,
    });

    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value,
        },
      };
    });
  };
  onChangeWebTmtCustInformationCheckBox = (e) => {
    const { name, value } = e.target;
    const flag = value !== "" ? value : "N";
    if (flag === "N") {
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: "Y",
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: "N",
          },
        };
      });
    }
  };
  onChangeControlPhoneNums = (e) => {
    const { name, value } = e.target;
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });    
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: value,
          },
        };
      });
    }
  }

  onChangeControlAlphanumericValues = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^A-Za-z0-9\s-.()]/, ""),
    });
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value.replace(/[^A-Za-z0-9\s-.()]/, ""),
        },
      };
    });
  };

  onChangeControlNumericValues = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
    });
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value.replace(/[^0-9]/, ""),
        },
      };
    });
  };

  
  onHandleBackWebTmtMain = () => {
    this.setState({ 
      isCustInfoWebTmtLoading: true
    })
    this.props.history.push("/web-tmt-db-maintenance");
    this.setState({ 
      isCustInfoWebTmtLoading: false
    })
  };

 onHandleWebTmtCustCommentInfo = () => {
    this.setState({
      customerNumber: this.state.singleData.customerNumber
        ? this.state.singleData.customerNumber
        : "",
      customerId: this.state.customerId ? this.state.customerId : "",
      customerName: this.state.singleData.customerName
        ? this.state.singleData.customerName
        : "",
        operatorId: this.state.operatorId
    });
    this.getCommentsInfo();
    this.props.history.push({
      pathname: "/web-tmt-customer-comment-information",
      state: this.state,
    });
  };

  onHandleWebTmtCustHistoryInfo = () => {
    this.setState({
      customerId: this.state.customerId,
      operatorId: this.state.operatorId
    });
    this.getHistoryInfo();
  };

  getHistoryInfo = () => {
    this.setState({
      isCustInfoHistoryLoading: true,
    });
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/txnhistory`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            customerId: this.state.singleData.customerNumber ? this.state.singleData.customerNumber : "",
            mtcnDate: this.state.mtcnDate ? this.state.mtcnDate : "",
            mtcn: this.state.mtcn ? this.state.mtcn :"",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            userId: this.state.operatorId
          },
        }
      )
      .then((response) => {        
        this.setState({
          isCustInfoHistoryLoading: false,
        });
        if (response.status===200 && response.data.records && response.data.records.length > 0) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
            customerNumber:this.state.singleData.customerNumber,
            operatorId: this.state.operatorId
          });
          this.props.history.push({
            pathname: "/web-tmt-transaction-history",
            state: this.state,
          });
        } else {
          this.setState({
            submitStatusWebTmtCustInformation: "Error",
            submitMsgWebTmtCustInformation: response.data.errMsg,
          });
          return false;
        }
      });        
  };

  getCommentsInfo = () => {
    this.setState({
      isCustInfoCommLoading: true
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            searchKey: this.state.singleData.customerNumber
              ? this.state.singleData.customerNumber
              : this.state.customerId
              ? this.state.customerId
              : "",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
          },
        }
      )
      .then((response) => {
        this.setState({
          isCustInfoCommLoading: false
        })
        if (response.status===200 && response.data.records && response.data.records.length > 0) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
            operatorId: this.state.operatorId,
            customerNumber:this.state.singleData.customerNumber,
          });
        } else if (
          response.data.records &&
          response.data.records.length === 0
        ) {
          this.setState({
            submitStatusWebTmtCustInformation: "Error",
            submitMsgWebTmtCustInformation: response.data.errMsg,
          });
          return false;
        }
      });
  };

  getCustomerInformation = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customerinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            accountNumber: this.state.customerId ? this.state.customerId :"",
            customerNumber: this.state.customerNum
              ? this.state.customerNum
              : this.state.singleData.customerNumber,
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            userId:this.state.operatorId,
          },
        }
      )
      .then((response) => {
         if (response.status===200 && response.data.records && response.data.records.length > 0) {
           let tmpRecords = response.data.records
           this.setState(prev=>({
             ...prev.records, tmpRecords,
           }));
           this.setState({
            singleData: response.data.records[0],
            accountNumber: this.state.customerId,
          });
         } else {
           this.setState({
            submitStatusWebTmtCustInformation: "Error",
            submitMsgWebTmtCustInformation: response.data.errMsg,
          });
          return false;
        }
      });
  };

  onValidateCustomerInformation = () => {
    if (this.state.singleData.customerName === "") {
      this.setState({
        submitStatusWebTmtCustInformation: "Error",
        submitMsgWebTmtCustInformation: "Please Enter Value In Customer Name",
      });
      return false;
    }
    return true;
  };

  onHandleUpdateCustomerInfo = () => {
    if (!this.onValidateCustomerInformation()) {
      return;
    }
    let submittedObj = {};
    submittedObj.responseType = 1;
    submittedObj.customerNumber = this.state.singleData.customerNumber;
    submittedObj.pcpNumber = this.state.singleData.pcpNumber;
    submittedObj.customerName = this.state.singleData.customerName;
    submittedObj.emailAddress = this.state.singleData.emailAddress;
    submittedObj.cusomerAddress = this.state.singleData.cusomerAddress;
    submittedObj.custCity = this.state.singleData.custCity;
    submittedObj.custState = this.state.singleData.custState;
    submittedObj.custZip = this.state.singleData.custZip;
    submittedObj.custCountry = this.state.singleData.custCountry;
    submittedObj.phone = this.state.singleData.phone;
    submittedObj.altPhone = this.state.singleData.altPhone;
    submittedObj.customerDOB = this.state.singleData.customerDOB;
    submittedObj.custPhoneSource = this.state.singleData.custPhoneSource;
    submittedObj.customerPin = this.state.singleData.customerPin;
    submittedObj.customerLevelCode = this.state.singleData.customerLevelCode;
    submittedObj.optValidtnDate = this.state.singleData.optValidtnDate;
    submittedObj.optValidation = this.state.singleData.optValidation;
    submittedObj.trafficAllow = this.state.singleData.trafficAllow;
    submittedObj.tmtGuarantee = this.state.singleData.tmtGuarantee;
    submittedObj.id = this.state.singleData.id;
    submittedObj.password = this.state.singleData.password;
    submittedObj.createDate = this.state.singleData.createDate;
    submittedObj.createOPID = this.state.singleData.createOPID;
    submittedObj.updateDate = this.state.singleData.updateDate;
    //submittedObj.updateOPID = this.state.singleData.updateOPID;
    submittedObj.updateOPID = this.state.operatorId;
    submittedObj.activityDate = this.state.singleData.activityDate;
    submittedObj.ratingDate = this.state.singleData.ratingDate;
    submittedObj.ratingCode = this.state.singleData.ratingCode;
    this.setState({
      isCustInfoUpdateLoading: true
    })
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customerinfo`,
        submittedObj,
        {
          headers: {
            ...getHeadersCustomer().headers,
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
          },
        }
      )
      .then((response) => { 
        if (response.status === 200) {
          let tempRecord = this.state.records;
          tempRecord[this.state.count] = this.state.singleData;
          this.setState({
            submitStatusWebTmtCustInformation: "OK",
            submitMsgWebTmtCustInformation: response.data.responseString,
            records: tempRecord,
          });
          if(response.data.responseCode!=="400"){
            this.getCustomerInformation();
          }                 
        this.setState({
          isCustInfoUpdateLoading: false
        })
        } else {
          this.setState({
            submitStatusWebTmtCustInformation: "Error",
            submitMsgWebTmtCustInformation: response.data.responseString,
            isCustInfoUpdateLoading: false
          });
        }
        this.onHandleClearWebTmtCustomerInfo();
      });
  };

  
  onHandleClearWebTmtCustomerInfo = () => {
    setTimeout(
      () =>
        this.setState({
          submitStatusWebTmtCustInformation: null,
          submitMsgWebTmtCustInformation: "",
        }),
      5000
    );
  };

  onHandleWebTmtAccountInfo = () => {
    if (this.state.singleData.customerNumber) {
      this.setState({
        isCustInfoAcctLoading: true
      })
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/accountinfo`,
          {
            headers: {
              ...getHeadersCustomer().headers,
              BillPhone: this.state.billPhone ? this.state.billPhone : "",
              CustomerNumber: this.state.singleData.customerNumber
                ? this.state.singleData.customerNumber
                : "",
              Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
              userId:this.state.operatorId,
            },
          }
        )
        .then((response) => {
          this.setState({
            isCustInfoAcctLoading: false
          })
          let accountRecords = [];
          if (response.status===200 && response.data.records !== null && response.data.records && response.data.records.length > 0) {
            accountRecords = response.data.records;
            this.setState({
              records: accountRecords,
              singleData: response.data.records[0],
              customerNumber:this.state.singleData.customerNumber,
              operatorId: this.state.operatorId
            });
            let tempEnableNext = this.state.records.length > 1 ? true : false;
            this.setState({
              enableNext: tempEnableNext,
            });
            this.props.history.push({
              pathname: "/web-tmt-account-information",
              state: this.state,
            });
          } else {
            this.setState({
              submitStatusWebTmtCustInformation: "Error",
              submitMsgWebTmtCustInformation: response.data.errMsg,
            });
            return false;
          }
        });
    }
  };

  onHandleWebTmtCustomerAccountInfo = () => {
    if (this.state.singleData.customerNumber) {
      this.setState({
        isCustInfoCustAcctLoading: true
      })
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customeraccountinfo`,
          {
            headers: {
              ...getHeadersCustomer().headers,
              aliasType: this.state.aliasType ? this.state.aliasType :"",
              aliasIssuer: this.state.aliasIssuer ? this.state.aliasIssuer :"",
              aliasId: this.state.aliasId ? this.state.aliasId :"",
              customerId: this.state.singleData.customerNumber ? this.state.singleData.customerNumber :"",
              Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
              userId:this.state.operatorId,
            },
          }
        )
        .then((response) => {
          this.setState({
            isCustInfoCustAcctLoading: false
          })
          if (response.status===200 && response.data.records && response.data.records.length > 0) {
            console.log("sjdhjds")
            this.setState({
              records: response.data.records,
              singleData: response.data.records[0],
              customerNumber: this.state.singleData.customerNumber,
              operatorId: this.state.operatorId
            });
            if (this.state.singleData.responseType === 0) {
              this.props.history.push({
                pathname: "/web-tmt-customer-account-information",
                state: this.state,
              });             
            }
          }else {
            console.log("else")

            this.setState({
              submitStatusWebTmtCustInformation: "Error",
              submitMsgWebTmtCustInformation: response.data.errMsg,
            });
            return false;
          }
        });
    }
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WEB/TMT Database Maintenance - Customer Information"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                WEB/TMT DATABASE MAINTENANCE - Customer Information
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  Records {this.state.count + 1} of{" "}
                  {this.state.records ? this.state.records.length : ""}
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="customerNumber"
                          className="font-weight-bold"
                        >
                          Customer Number
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.customerNumber
                              ? this.state.singleData.customerNumber
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="customerNumber"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-customerNumber"
                          placeholder="Customer Number"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="pcpNumber" className="font-weight-bold">
                          PCP Number
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.pcpNumber
                              ? this.state.singleData.pcpNumber
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="pcpNumber"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-pcpNumber"
                          placeholder="PCP Number"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="customerName"
                          className="font-weight-bold"
                        >
                          Customer Name
                        </label>
                        <input
                          type="text"
                          maxLength="65"
                          value={
                            this.state.singleData.customerName
                              ? this.state.singleData.customerName
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="customerName"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-customerName"
                          placeholder="Customer Name"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="emailAddress"
                          className="font-weight-bold"
                        >
                          Email Address
                        </label>
                        <input
                          type="text"
                          maxLength="65"
                          value={
                            this.state.singleData.emailAddress
                              ? this.state.singleData.emailAddress
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="emailAddress"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-emailAddress"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="cusomerAddress"
                          className="font-weight-bold"
                        >
                          Customer Address
                        </label>
                        <input
                          type="text"
                          maxLength="65"
                          value={
                            this.state.singleData.cusomerAddress
                              ? this.state.singleData.cusomerAddress
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="cusomerAddress"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-cusomerAddress"
                          placeholder="Customer Address"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="custCity" className="font-weight-bold">
                          City
                        </label>
                        <input
                          type="text"
                          maxLength="24"
                          value={
                            this.state.singleData.custCity
                              ? this.state.singleData.custCity
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="custCity"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-custCity"
                          placeholder="City"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="custState" className="font-weight-bold">
                          State
                        </label>
                        <input
                          type="text"
                          maxLength="2"
                          value={
                            this.state.singleData.custState
                              ? this.state.singleData.custState
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="custState"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-custState"
                          placeholder="State"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="custZip" className="font-weight-bold">
                          Zip
                        </label>
                        <input
                          type="text"
                          maxLength="10"
                          value={
                            this.state.singleData.custZip
                              ? this.state.singleData.custZip
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="custZip"
                          onChange={(e) => {
                            this.onChangeControlAlphanumericValues(e);
                          }}
                          data-test="text-custZip"
                          placeholder="Zip"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="custCountry"
                          className="font-weight-bold"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          maxLength="3"
                          value={
                            this.state.singleData.custCountry
                              ? this.state.singleData.custCountry
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="custCountry"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-custCountry"
                          placeholder="Country"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="phone" className="font-weight-bold">
                          Phone
                        </label>
                        <input
                          type="text"
                          maxLength="16"
                          value={
                            this.state.singleData.phone
                              ? this.state.singleData.phone
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="phone"
                          onChange={(e) => {
                            this.onChangeControlPhoneNums(e);
                          }}
                          data-test="text-phone"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="altPhone" className="font-weight-bold">
                          Alt Phone
                        </label>
                        <input
                          type="text"
                          maxLength="12"
                          value={
                            this.state.singleData.altPhone
                              ? this.state.singleData.altPhone
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="altPhone"
                          onChange={(e) => {
                            this.onChangeControlPhoneNums(e);
                          }}
                          data-test="text-altPhone"
                          placeholder="Alt Phone"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="customerDOB"
                          className="font-weight-bold"
                        >
                          Customer DOB
                        </label>

                        <input
                          type="text"
                          maxLength="8"
                          value={
                            this.state.singleData.customerDOB
                              ? this.state.singleData.customerDOB
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="customerDOB"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-customerDOB"
                          placeholder="Customer DOB"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="custPhoneSource"
                          className="font-weight-bold"
                        >
                          Cust Phone Source
                        </label>
                        <input
                          type="text"
                          maxLength="1"
                          value={
                            this.state.singleData.custPhoneSource
                              ? this.state.singleData.custPhoneSource
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="custPhoneSource"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-custPhoneSource"
                          placeholder="Cust Phone Source"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="customerPin"
                          className="font-weight-bold"
                        >
                          Customer Pin
                        </label>
                        <input
                          type="text"
                          maxLength="5"
                          value={
                            this.state.singleData.customerPin
                              ? this.state.singleData.customerPin
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="customerPin"
                          onChange={(e) => {
                            this.onChangeControlNumericValues(e);
                          }}
                          data-test="text-customerPin"
                          placeholder="Customer Pin"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="customerLevelCode"
                          className="font-weight-bold"
                        >
                          Customer Level Code
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.customerLevelCode
                              ? this.state.singleData.customerLevelCode
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="customerLevelCode"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-customerLevelCode"
                          placeholder="Customer Level Code"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="optValidtnDate"
                          className="font-weight-bold"
                        >
                          Opt Validation Date
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.optValidtnDate
                              ? this.state.singleData.optValidtnDate
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="optValidtnDate"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-optValidtnDate"
                          placeholder="Opt Validation Date"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <input
                          style={{
                            marginLeft: 5,
                            marginRight: 5,
                            marginTop: 5,
                          }}
                          //class="form-check-input"
                          type="checkbox"
                          disabled={!this.state.webAdmin}
                          value={
                            this.state.singleData.optValidation
                              ? this.state.singleData.optValidation
                              : ""
                          }
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformationCheckBox(e);
                          }}
                          checked={this.state.singleData.optValidation === "Y"}
                          data-test="text-optValidation"
                          name="optValidation"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Opt Validation
                        </label>
                        {/*</div>
                      <div className="col-lg">*/}
                        <input
                          style={{
                            marginLeft: 50,
                            marginRight: 5,
                            marginTop: 5,
                          }}
                          //class="form-check-input"
                          type="checkbox"
                          value={
                            this.state.singleData.trafficAllow
                              ? this.state.singleData.trafficAllow
                              : ""
                          }
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformationCheckBox(e);
                          }}
                          checked={this.state.singleData.trafficAllow === "Y"}
                          data-test="text-trafficAllow"
                          name="trafficAllow"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Traffic Allow
                        </label>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="tmtGuarantee"
                          className="font-weight-bold"
                        >
                          TMT Guarantee
                        </label>
                        <input
                          type="text"
                          maxLength="1"
                          value={
                            this.state.singleData.tmtGuarantee
                              ? this.state.singleData.tmtGuarantee
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="tmtGuarantee"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-tmtGuarantee"
                          placeholder="TMT Guarantee"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="id" className="font-weight-bold">
                          Id
                        </label>
                        <input
                          type="text"
                          maxLength="3"
                          value={
                            this.state.singleData.id
                              ? this.state.singleData.id
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="id"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-id"
                          placeholder="Id"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="password" className="font-weight-bold">
                          Password
                        </label>
                        <input
                          type="text"
                          maxLength="5"
                          value={
                            this.state.singleData.password
                              ? this.state.singleData.password
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="password"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-password"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="createDate"
                          className="font-weight-bold"
                        >
                          Create Date
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.createDate
                              ? this.state.singleData.createDate
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="createDate"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-createDate"
                          placeholder="Create Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="createOPID"
                          className="font-weight-bold"
                        >
                          Create OPID
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.createOPID
                              ? this.state.singleData.createOPID
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="createOPID"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-createOPID"
                          placeholder="Create OPID"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="updateDate"
                          className="font-weight-bold"
                        >
                          Update Date
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.updateDate
                              ? this.state.singleData.updateDate
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="updateDate"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-updateDate"
                          placeholder="Update Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="updateOPID"
                          className="font-weight-bold"
                        >
                          Update OPID
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.updateOPID
                              ? this.state.singleData.updateOPID
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="updateOPID"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-updateOPID"
                          placeholder="Update OPID"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="activityDate"
                          className="font-weight-bold"
                        >
                          Activity Date
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.activityDate
                              ? this.state.singleData.activityDate
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="activityDate"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-activityDate"
                          placeholder="Activity Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="ratingDate"
                          className="font-weight-bold"
                        >
                          Rating Date
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.ratingDate
                              ? this.state.singleData.ratingDate
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="ratingDate"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          data-test="text-ratingDate"
                          placeholder="Rating Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="ratingCode"
                          className="font-weight-bold"
                        >
                          Rating Code
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={
                            this.state.singleData.ratingCode
                              ? this.state.singleData.ratingCode
                              : this.state.singleData.ratingCode=== 0 ? 0 :""
                          }
                          className="form-control form-control-sm"
                          name="ratingCode"
                          onChange={(e) => {
                            this.onChangeWebTmtCustInformation(e);
                          }}
                          disabled={!this.state.webAdmin}
                          data-test="text-ratingCode"
                          placeholder="Rating Code"
                        />
                      </div>
                      <div className="col-lg"></div>
                    </div>
                    <div className="form-group row text-center">
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4">
                        {this.state.submitStatusWebTmtCustInformation ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgWebTmtCustInformation}
                          </div>
                        )}
                        {this.state.submitStatusWebTmtCustInformation ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgWebTmtCustInformation}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      {/* <div className="col-lg-3" /> */}
                      <div className="col-lg text-center mar-bottom5 mar-top10">
                        {this.state.isCustInfoWebTmtLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleBackWebTmtMain}
                            data-test="button-webTmtMaint"
                            disabled={
                              this.state.isCustInfoWebTmtLoading === true ||
                              this.state.isCustInfoAcctLoading === true ||
                              this.state.isCustInfoCustAcctLoading === true ||
                              this.state.isCustInfoPrevLoading === true ||
                              this.state.isCustInfoHistoryLoading === true ||
                              this.state.isCustInfoNextLoading === true ||
                              this.state.isCustInfoCommLoading === true
                            }
                          >
                            WEBTMT MAINT
                          </button>
                        )}
                        {this.state.isCustInfoPrevLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mr-2"
                            onClick={() => this.onHandleBufferData("prev")}
                            data-test="WEBTMTAcctInfo-button-prev"
                            disabled={
                              !this.state.enablePrev ||
                              this.state.isCustInfoWebTmtLoading === true ||
                              this.state.isCustInfoAcctLoading === true ||
                              this.state.isCustInfoCustAcctLoading === true ||
                              this.state.isCustInfoPrevLoading === true ||
                              this.state.isCustInfoHistoryLoading === true ||
                              this.state.isCustInfoNextLoading === true ||
                              this.state.isCustInfoCommLoading === true
                            }
                          >
                            Previous
                          </button>
                        )}
                        {this.state.isCustInfoNextLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mr-2"
                            onClick={() => this.onHandleBufferData("next")}
                            data-test="WEBTMTAcctInfo-button-tcn"
                            disabled={
                              !this.state.enableNext ||
                              this.state.isCustInfoWebTmtLoading === true ||
                              this.state.isCustInfoAcctLoading === true ||
                              this.state.isCustInfoCustAcctLoading === true ||
                              this.state.isCustInfoPrevLoading === true ||
                              this.state.isCustInfoHistoryLoading === true ||
                              this.state.isCustInfoNextLoading === true ||
                              this.state.isCustInfoCommLoading === true
                            }
                          >
                            Next
                          </button>
                        )}
                        {this.state.isCustInfoUpdateLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleUpdateCustomerInfo}
                            data-test="update-webTmtMaint"
                            disabled={
                              !this.state.accessGrantedWrite ||
                              this.state.isCustInfoWebTmtLoading === true ||
                              this.state.isCustInfoAcctLoading === true ||
                              this.state.isCustInfoCustAcctLoading === true ||
                              this.state.isCustInfoPrevLoading === true ||
                              this.state.isCustInfoHistoryLoading === true ||
                              this.state.isCustInfoNextLoading === true ||
                              this.state.isCustInfoCommLoading === true
                            }
                          >
                            UPDATE
                          </button>
                        )}
                        {this.state.isCustInfoHistoryLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleWebTmtCustHistoryInfo}
                            data-test="history-webTmtMaint"
                            disabled={
                              this.state.isCustInfoWebTmtLoading === true ||
                              this.state.isCustInfoAcctLoading === true ||
                              this.state.isCustInfoCustAcctLoading === true ||
                              this.state.isCustInfoPrevLoading === true ||
                              this.state.isCustInfoHistoryLoading === true ||
                              this.state.isCustInfoNextLoading === true ||
                              this.state.isCustInfoCommLoading === true
                            }
                          >
                            HISTORY
                          </button>
                        )}
                        {this.state.isCustInfoAcctLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleWebTmtAccountInfo}
                            data-test="account-webTmtMaint"
                            disabled={
                              this.state.isCustInfoWebTmtLoading === true ||
                              this.state.isCustInfoAcctLoading === true ||
                              this.state.isCustInfoCustAcctLoading === true ||
                              this.state.isCustInfoPrevLoading === true ||
                              this.state.isCustInfoHistoryLoading === true ||
                              this.state.isCustInfoNextLoading === true ||
                              this.state.isCustInfoCommLoading === true
                            }
                          >
                            ACCOUNT
                          </button>
                        )}
                        {this.state.isCustInfoCommLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleWebTmtCustCommentInfo}
                            data-test="comments-webTmtMaint"
                            disabled={
                              this.state.isCustInfoWebTmtLoading === true ||
                              this.state.isCustInfoAcctLoading === true ||
                              this.state.isCustInfoCustAcctLoading === true ||
                              this.state.isCustInfoPrevLoading === true ||
                              this.state.isCustInfoHistoryLoading === true ||
                              this.state.isCustInfoNextLoading === true ||
                              this.state.isCustInfoCommLoading === true
                            }
                          >
                            COMMENTS
                          </button>
                        )}
                        {this.state.isCustInfoCustAcctLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleWebTmtCustomerAccountInfo}
                            data-test="custacc-webTmtMaint"
                            disabled={
                              this.state.isCustInfoWebTmtLoading === true ||
                              this.state.isCustInfoAcctLoading === true ||
                              this.state.isCustInfoCustAcctLoading === true ||
                              this.state.isCustInfoPrevLoading === true ||
                              this.state.isCustInfoHistoryLoading === true ||
                              this.state.isCustInfoNextLoading === true ||
                              this.state.isCustInfoCommLoading === true
                            }
                          >
                            CUST-ACCNT
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebTmtCustomerInformation;
