import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactIso } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { getHeadersGSPS } from "../../../utility/utility";
import { AiTwotoneEdit as AiTwotoneEditIso } from "react-icons/ai";
import { IconContext as IconContextIso } from "react-icons";

class IsoRegistrationBlock extends Component {
  state = {
    key: "",
    region: "",
    regionid: "",
    subregion: "",
    subregionid: "",
    describ: "",
    country: "",
    showIsoRegistrationBlockConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() =>this.state.accessFlagIsoRegion && this.handleEditIsoRegistrationBlock(params)}  
            >
              <IconContextIso.Provider value={{ color: this.state.accessFlagIsoRegion?"#FDD017":'#cec9b4', size: "20px" }}>
                <AiTwotoneEditIso />
              </IconContextIso.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
      },
      {field: "key", headerName: "Key",},
      {field: "region",headerName: "Region",},
      {field: "regionid",headerName: "Region ID",},
      {field: "subregion",headerName: "Sub Region",},
      {field: "subregionid",headerName: " Sub Region ID",},
      {field: "describ",headerName: "Description",},
      {field: "country",headerName: "Country",},
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValIso: "25",
    defaultColumnDef: {
      resizable: true,
      flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusIsoRegistrationBlock: null,
    submitMsgIsoRegistrationBlock: "",
    paginationOptionIsoBlock: [25, 50, 100, 500],
    overlayLoadingTemplateIso:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateIso: '<span style="">No Records Found</span>',
  };

  onChangeControlIsoRegistrationBlock = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlIsoRegistrationBlockOnlyChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z]/, ""),
    });
  };

  onChangeControlIsoRegistrationBlockCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onChangeControlIsoRegistrationBlockCharNumberAndSpecialChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9\s'/*_&()-]/, ""),
    });
  };

  onGridReadyIsoRegistrationBlock = (params) => {
    let accessFlagIsoRegion = this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) || this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READWRITE);
    this.setState({accessFlagIsoRegion});
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/isoregion/getisoregiondetails`,
        {headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken? this.props.jwtToken: "",
          },
        }
      )
      .then((response) => {
        let recIso = [];
        if (response.data.records !== null) {
          recIso = response.data.records;
        }
        this.setState({
          rows: recIso,
        });
      });
  };

  handleEditIsoRegistrationBlock = (params) => {
    let data = params.node.data;
    this.setState({
      key: data.key,
      region: data.region,
      regionid: data.regionid,
      subregion: data.subregion,
      subregionid: data.subregionid,
      describ: data.describ,
      country: data.country,
      enableBtn: true,
    });
  };

  onPageSizeChangedIsoRegistrationBlock = (e) => {
    this.setState({ paginationValIso: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackIsoRegistrationBlock = () => {
    this.props.history.push("/");
  };

  onValidateIsoRegistrationBlock = () => {
    if (this.state.key === "") {
      this.setState({
        submitStatusIsoRegistrationBlock: "Error",
        submitMsgIsoRegistrationBlock: "Please Enter The Value For Key",
      });
      return false;
    }
    if (this.state.region === "") {
      this.setState({
        submitStatusIsoRegistrationBlock: "Error",
        submitMsgIsoRegistrationBlock: "Please Enter The Value For Region ",
      });
      return false;
    }
    if (this.state.regionid === "") {
      this.setState({
        submitStatusIsoRegistrationBlock: "Error",
        submitMsgIsoRegistrationBlock: "Please Enter The Value For Region ID",
      });
      return false;
    }
    if (this.state.subregionid === "") {
      this.setState({
        submitStatusIsoRegistrationBlock: "Error",
        submitMsgIsoRegistrationBlock: "Please Enter The Value For Sub Region ID",
      });
      return false;
    }
    if (this.state.describ === "") {
      this.setState({
        submitStatusIsoRegistrationBlock: "Error",
        submitMsgIsoRegistrationBlock: "Please Enter The Value For Description ",
      });
      return false;
    }
    if (this.state.country === "") {
      this.setState({
        submitStatusIsoRegistrationBlock: "Error",
        submitMsgIsoRegistrationBlock: "Please Enter The Value For Country ",
      });
      return false;
    }
    return true;
  };
  onHandleAddIsoRegistrationBlock = () => {
    if (!this.onValidateIsoRegistrationBlock()) {
      return;
    }

    let submittedObj = {};
    submittedObj.key = this.state.key;
    submittedObj.region = this.state.region;
    submittedObj.regionid = this.state.regionid;
    submittedObj.subregion = this.state.subregion;
    submittedObj.subregionid = this.state.subregionid;
    submittedObj.describ = this.state.describ;
    submittedObj.country = this.state.country;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/isoregion/addisoregiondata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        },
      }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusIsoRegistrationBlock: "OK",
            submitMsgIsoRegistrationBlock: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          this.onHandleClearIsoRegistrationBlock();
        } else {
          this.setState({
            submitStatusIsoRegistrationBlock: "Error",
            submitMsgIsoRegistrationBlock: response.data.responseString,
          });
          this.onHandleClearIsoRegistrationBlock();
        }
      });
  };

  onHandleUpdateIsoRegistrationBlock = () => {
    if (!this.onValidateIsoRegistrationBlock()) {
      return;
    }
    let submittedObj = {};
    submittedObj.key = this.state.key;
    submittedObj.region = this.state.region;
    submittedObj.regionid = this.state.regionid;
    submittedObj.subregion = this.state.subregion;
    submittedObj.subregionid = this.state.subregionid;
    submittedObj.describ = this.state.describ;
    submittedObj.country = this.state.country;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/isoregion/updateisoregiondata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        },
      }
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.key === submittedObj.key
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusIsoRegistrationBlock: "OK",
            submitMsgIsoRegistrationBlock: response.data.responseString,
            rows: updatedRows,
          });
          this.onHandleClearIsoRegistrationBlock();
        } else {
          this.setState({
            submitStatusIsoRegistrationBlock: "Error",
            submitMsgIsoRegistrationBlock: response.data.responseString,
          });
          this.onHandleClearIsoRegistrationBlock();
        }
      });
  };

  onHandleClearIsoRegistrationBlock = () => {
    this.setState({
      key: "",
      region: "",
      regionid: "",
      subregion: "",
      subregionid: "",
      describ: "",
      country: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusIsoRegistrationBlock: null,
        }),
      5000
    );
  };

  getIsoRegistrationModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showIsoRegistrationBlockConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete The Key {this.state.key} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showIsoRegistrationBlockConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteIsoRegistrationBlock}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteIsoRegistrationBlock = () => {
    let submittedObj = {};
    submittedObj.key = this.state.key;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/isoregion/deleteisoregiondata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        },
      }
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.key !== submittedObj.key;
          });

          this.setState({
            submitStatusIsoRegistrationBlock: "OK",
            submitMsgIsoRegistrationBlock: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearIsoRegistrationBlock();
        } else {
          this.setState({
            submitStatusIsoRegistrationBlock: "Error",
            submitMsgIsoRegistrationBlock: response.data.responseString,
          });
          this.onHandleClearIsoRegistrationBlock();
        }
      });
    this.setState({ showIsoRegistrationBlockConfirm: false });
  };

  render() {
    return (
      <div className="container-fluid padding-left0">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title main-heading">ISO REGISTRATION</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                Block ISO Region
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="key" className="font-weight-bold">Key</label>
                      <input
                        type="text"
                        maxLength="3"
                        size="3"
                        value={this.state.key}
                        className="form-control form-control-sm"
                        name="key"
                        onChange={(e) => {
                          this.onChangeControlIsoRegistrationBlockCharNumber(e);
                        }}
                        data-test="text-key"
                        placeholder="Key"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="region" className="font-weight-bold">Region</label>
                      <input
                        type="text"
                        maxLength="4"
                        size="4"
                        value={this.state.region}
                        className="form-control form-control-sm"
                        name="region"
                        onChange={(e) => {
                          this.onChangeControlIsoRegistrationBlockCharNumber(e);
                        }}
                        data-test="text-region"
                        placeholder="Region"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="regionid" className="font-weight-bold">Region ID</label>
                      <input
                        type="text"
                        maxLength="1"
                        size="1"
                        value={this.state.regionid}
                        className="form-control form-control-sm"
                        name="regionid"
                        onChange={(e) => {
                          this.onChangeControlIsoRegistrationBlockCharNumber(e);
                        }}
                        data-test="text-regionid"
                        placeholder="Region ID"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="subregion" className="font-weight-bold">Sub Region</label>
                      <input
                        type="text"
                        maxLength="20"
                        size="20"
                        value={this.state.subregion}
                        className="form-control form-control-sm"
                        name="subregion"
                        onChange={(e) => {
                          this.onChangeControlIsoRegistrationBlockCharNumber(e);
                        }}
                        data-test="text-subregion"
                        placeholder="Sub Region"
                      />
                    </div>
                  </div>
                  <div className="form-group row ">
                    <div className="col-lg">
                      <label htmlFor="subregionid" className="font-weight-bold">Sub Region ID</label>
                      <input
                        type="text"
                        maxLength="2"
                        size="2"
                        value={this.state.subregionid}
                        className="form-control form-control-sm"
                        name="subregionid"
                        onChange={(e) => {
                          this.onChangeControlIsoRegistrationBlockCharNumber(e);
                        }}
                        data-test="text-subregionid"
                        placeholder="Sub Region ID"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="describ" className="font-weight-bold">Description</label>
                      <input
                        type="text"
                        maxLength="40"
                        size="40"
                        value={this.state.describ}
                        className="form-control form-control-sm"
                        name="describ"
                        onChange={(e) => {
                          this.onChangeControlIsoRegistrationBlockCharNumberAndSpecialChar(
                            e
                          );
                        }}
                        data-test="text-describ"
                        placeholder="Description"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="country" className="font-weight-bold">Country</label>
                      <input
                        type="text"
                        maxLength="100"
                        size="100"
                        value={this.state.country}
                        className="form-control form-control-sm"
                        name="country"
                        onChange={(e) => {
                          this.onChangeControlIsoRegistrationBlockCharNumberAndSpecialChar(
                            e
                          );
                        }}
                        data-test="text-country"
                        placeholder="Country"
                      />
                    </div>
                    <div className="col-lg"></div>
                  </div>
                  <div className="form-group row margin-0">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackIsoRegistrationBlock}
                        data-test="button-back"
                      >
                        GSP SYS
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddIsoRegistrationBlock}
                        data-test="button-add"
                        disabled = {this.state.enableBtn || !this.state.accessFlagIsoRegion}
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateIsoRegistrationBlock}
                        data-test="button-update"
                        disabled = {!this.state.enableBtn}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearIsoRegistrationBlock}
                        disabled = {!this.state.enableBtn}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({
                            showIsoRegistrationBlockConfirm: true,
                          });
                        }}
                        data-test="button-delete"
                        disabled = {!this.state.enableBtn}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                          {this.state.submitStatusIsoRegistrationBlock === "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgIsoRegistrationBlock}
                            </div>
                          )}
                          {this.state.submitStatusIsoRegistrationBlock ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgIsoRegistrationBlock}
                            </div>
                          )}
                          </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </form>
              </div>
            </div>
            {this.getIsoRegistrationModal()}
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                Blocked ISO Region
              </div>
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label" className="font-weight-bold">
                    No of records per page
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlIsoRegistrationBlock(e);
                      this.onPageSizeChangedIsoRegistrationBlock(e);
                    }}
                    value={this.state.paginationValIso}
                    name="paginationDropdownIso"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionIsoBlock.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactIso
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyIsoRegistrationBlock}
                    data-test="component-grid"
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateIso
                    }
                    overlayNoRowsTemplate={
                      this.state.overlayNoRowsTemplateIso
                    }
                  ></AgGridReactIso>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IsoRegistrationBlock;
