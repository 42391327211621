import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditRFPF } from "react-icons/ai";
import { IconContext as IconContextRFPF } from "react-icons";
import { getHeadersShared2 } from "../../utility/utility";

class RegularFraudPreventionFilter extends Component {
  state = {
    timestamp: "",
    sequenceNum: "",
    sendCountryCpc: "",
    startDate: "",
    endDate: "",
    action: "",
    delayMin: "",
    queueName: "",
    filterType: "",
    channel: "",
    moneyTransferType: [],
    siteId: "",
    specificCsc: "",
    comments: "",
    messageIndicator: "N",
    message1: "",
    message2: "",
    message3: "",
    sending: "",
    countryState: "",
    countryStateAll: false,
    phone: "",
    phoneAll: false,
    agent: "",
    agentAll: false,
    agentNetwork: "",
    agentNetworkAll: false,
    receiving: "",
    countryCpc: "",
    countryCpcAll: false,
    rcvState: "",
    rcvStateAll: false,
    sendingPrincipal: "",
    lowerLimit: 0,
    upperLimit: 0,
    operatorId: "",
    checked: false,
    showRegularFPFConfirm: false,
    showRegularFPFUpdateConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => this.handleEditRegularFPF(params)}
              data-test="btn-edit"
            >
              <IconContextRFPF.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditRFPF />
              </IconContextRFPF.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "100px",
      },
      {field: "sendCountryCpc",headerName: "SND CPC",width: "60px",},
      {field: "sending.countryState",headerName: "SND ST",width: "60px",},
      {field: "sending.agent",headerName: "SND AGENT",width: "60px",},
      {field: "sending.agentNetwork",headerName: "SND NETWORK",width: "60px",},
      {field: "receiving.countryCpc",headerName: "RCV CPC",width: "60px",},
      {field: "receiving.rcvState",headerName: "RCV ST",width: "60px",},
      {field: "filterType",headerName: "TYPE",width: "60px",},
      {field: "action",headerName: "ACTION",width: "60px",},
      {field: "queueName",headerName: "QUEUE",width: "60px",},
      {field: "startDate",headerName: "START DATE",width: "60px",filter: false,},
      {field: "endDate",headerName: "END DATE",width: "60px",filter: false,},
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValQueue: "25",
    defaultColumnDef: {
      resizable: true,
      flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusRegularFPF: null,
    submitMsgRegularFPF: "",
    paginationOptionRegularFPF: [25, 50, 100, 500],
    overlayLoadingTemplateRFPF:
      '<span class="ag-overlay-loading-center">Loading...</span>',
      overlayNoRowsTemplateRFPF: '<span style="">No Records Found</span>',
  };

  onChangeControlRegularFPF = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlRegularFPFCharNum = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };
  onChangeControlRegularFPFNum = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
    });
  };

  onChangeCheckBoxRegularFPF = (e) => {
    //let moneyTransferType = this.state.moneyTransferType;
    let { value } = e.target;
    let arrayIndex = this.state.moneyTransferType.indexOf(value);
    if (arrayIndex === -1) {
      this.setState((prevState) => ({
        moneyTransferType: [...prevState.moneyTransferType, value],
      }));
    } else {
      let array = this.state.moneyTransferType;
      array.splice(arrayIndex, 1);
      this.setState({ moneyTransferType: array });
    }
  };

  onChangeDateControlRegularFPF = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onGridReadyRegularFPF = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    this.onLoadingRegularFPF();
  };

  componentDidMount=()=>{
    this.setState({
      accessGrantedWriteFPF:this.props.location.records.accessGrantedWriteFPF
    });
  }

  onLoadingRegularFPF = () => {
    const countryCpcIso = this.props.location.state;
    if (countryCpcIso !== "") {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/search`,
          {
            headers: {
              ...getHeadersShared2().headers,
              countryCpcIso,
              Authorization:this.props.location.records.token,
            },
          }
        )
        .then((response) => {
          if(response.data.flag === 1){
          this.setState({
            rows: response.data.records,
          });
        }else{
          this.setState({
            rows: [],
            overlayNoRowsTemplateRFPF:`<span style="">${response.data.message}</span>`
          
        });
        }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/search`,
          {
            headers: {
              ...getHeadersShared2().headers,
              Authorization:this.props.location.records.token,
            },
          }
        )
        .then((response) => {
          if(response.data.flag === 1){
          this.setState({
            rows: response.data.records,
          });
        }else{
          this.setState({
            rows: [],
            overlayNoRowsTemplateRFPF:`<span style="">${response.data.message}</span>`
          
        });
        }
        });
    }
  };

  handleEditRegularFPF = (params) => {
    let dataRFPF = params.node.data;

    let d1RFPF = new Date(dataRFPF.startDate);
    let newStartDate = [
      d1RFPF.getFullYear(),
      ("0" + (d1RFPF.getMonth() + 1)).slice(-2),
      ("0" + d1RFPF.getDate()).slice(-2),
    ].join("-");

    let d2RFPF = new Date(dataRFPF.endDate);
    let newEndDate = [
      d2RFPF.getFullYear(),
      ("0" + (d2RFPF.getMonth() + 1)).slice(-2),
      ("0" + d2RFPF.getDate()).slice(-2),
    ].join("-");

    this.setState({
      sequenceNum: dataRFPF.sequenceNum,
      timestamp: dataRFPF.timestamp,
      sendCountryCpc: dataRFPF.sendCountryCpc,
      startDate: newStartDate,
      endDate: newEndDate,
      action: dataRFPF.action,
      delayMin: dataRFPF.delayMin,
      queueName: dataRFPF.queueName,
      filterType: dataRFPF.filterType,
      channel: dataRFPF.channel,
      moneyTransferType: dataRFPF.moneyTransferType,
      siteId: dataRFPF.siteId,
      specificCsc: dataRFPF.specificCsc,
      comments: dataRFPF.comments,
      messageIndicator: dataRFPF.messageIndicator,
      message1: dataRFPF.message1,
      message2: dataRFPF.message2,
      message3: dataRFPF.message3,
      lowerLimit: dataRFPF.sendingPrincipal.lowerLimit,
      upperLimit: dataRFPF.sendingPrincipal.upperLimit,
      enableBtn: true,
      countryState:dataRFPF.sending.countryState === "" ? "" : dataRFPF.sending.countryState,
      countryStateAll: dataRFPF.sending.countryState === "" ? true : false,
      phone: dataRFPF.sending.phone === "" ? "" : dataRFPF.sending.phone,
      phoneAll: dataRFPF.sending.phone === "" ? true : false,
      agent: dataRFPF.sending.agent === "" ? "" : dataRFPF.sending.agent,
      agentAll: dataRFPF.sending.agent === "" ? true : false,
      agentNetwork:dataRFPF.sending.agentNetwork === "" ? "" : dataRFPF.sending.agentNetwork,
      agentNetworkAll: dataRFPF.sending.agentNetwork === "" ? true : false,
      countryCpc:dataRFPF.receiving.countryCpc === "" ? "" : dataRFPF.receiving.countryCpc,
      countryCpcAll: dataRFPF.receiving.countryCpc === "" ? true : false,
      rcvState: dataRFPF.receiving.rcvState === "" ? "" : dataRFPF.receiving.rcvState,
      rcvStateAll: dataRFPF.receiving.rcvState === "" ? true : false,
    });
  };

  onPageSizeChangedRegularFPF = (e) => {
    this.setState({ paginationValQueue: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackRegularFPF = () => {
    this.props.history.push("/fraud-prevention-filter");
  };

  onValidateRegularFPF = (validationType) => {
  
    if (this.state.sendCountryCpc === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Sending Country CPC",
      });
      return false;
    }

    if (this.state.startDate === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Start Date",
      });
      return false;
    }
    if (this.state.endDate === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For End Date",
      });
      return false;
    }

    if(validationType === "Add")
    {
      let tDate=new Date()
      let newTDate = [
      tDate.getFullYear(),
       ("0" + (tDate.getMonth() + 1)).slice(-2),
       ("0" + tDate.getDate()).slice(-2),
       ].join("-");

      if( newTDate > this.state.startDate) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Start Date Cannot Be Less Than Today's Date",
     });
     return false;
    }
    }    
  
    if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "End Date Must Be Greater Than Or Equal To Start Date",
      });
      return false;
    }
    if (this.state.action === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Action",
      });
      return false;
    }
    if (
      (this.state.action === "S" && this.state.queueName !== "") ||
      (this.state.action === "D" && this.state.queueName !== "") 
    ) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF:
          "Only Queue Filter Action Should Have Queue Name Value",
      });
      return false;
    }

    if (this.state.action === "D" && this.state.delayMin === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Delay Period",
      });
      return false;
    }
    if (this.state.action === "D" && this.state.delayMin === "0") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Delay Period Value Should Be Greater Than Zero",
      });
      return false;
    }
    if (this.state.filterType === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Filter Type",
      });
      return false;
    }
    if (this.state.channel === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Channel",
      });
      return false;
    }
    if (this.state.moneyTransferType.length === 0) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Select The Value For Money Transfer Type",
      });
      return false;
    }
    if (this.state.siteId === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Select The Value For Site Id",
      });
      return false;
    }
    if (
      (this.state.specificCsc === "" || this.state.siteId !== "S") &&
      (this.state.specificCsc !== "" || this.state.siteId === "S")
    ) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Select The Value For Specific CSC and Value For Enter Site Id",
      });
      return false;
    }
    if (
      this.state.messageIndicator === "Y" &&
      this.state.message1 === "" &&
      this.state.messageIndicator === "Y" && 
	    this.state.message2 === "" &&
      this.state.messageIndicator === "Y" &&
      this.state.message3 === ""
    ) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF:
          "FPF Message Indicator Is selected. Please Enter The Value For FPF Message",
      });
      return false;
    }
    //console.log(this.state.countryState + "   " + this.state.countryStateAll);
    if (this.state.countryState !== "" && this.state.countryStateAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Country State Or Select ALL",
      });
      return false;
    }

    if (this.state.countryState === "" && !this.state.countryStateAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Country State Or Select ALL",
      });
      return false;
    }

    if (this.state.phone !== "" && this.state.phoneAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: " Please Enter The Value For Phone Or Select ALL",
      });
      return false;
    }

    if (this.state.phone === "" && !this.state.phoneAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Phone Or Select ALL",
      });
      return false;
    }
    if (this.state.agent !== "" && this.state.agentAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: " Please Enter The Value For Agent Or Select ALL",
      });
      return false;
    }

    if (this.state.agent === "" && !this.state.agentAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: " Please Enter The Value For Agent Or Select ALL",
      });
      return false;
    }
    if (this.state.agentNetwork !== "" && this.state.agentNetworkAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Agent Network Or Select ALL",
      });
      return false;
    }
    if (this.state.agentNetwork === "" && !this.state.agentNetworkAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Agent Network Or Select ALL",
      });
      return false;
    }
    if (this.state.countryCpc !== "" && this.state.countryCpcAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Receiving Country CPC Or Select ALL",
      });
      return false;
    }
    if (this.state.countryCpc === "" && !this.state.countryCpcAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Receiving Country CPC Or Select ALL",
      });
      return false;
    }
    if (this.state.rcvState !== "" && this.state.rcvStateAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Please Enter The Value For Receiving Country State Or Select ALL",
      });
      return false;
    }
    if (this.state.rcvState === "" && !this.state.rcvStateAll) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF:
          "Please Enter The Value For Receiving Country State Or Select ALL",
      });
      return false;
    }

    if (Number(this.state.upperLimit) < Number(this.state.lowerLimit)) {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF: "Lower Limit is Greater Than Upper Limit",
      });
      return false;
    }

    if (this.state.lowerLimit === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF:
          "Please Enter The Value For Lower Limit",
      });
      return false;
    }
    if (this.state.upperLimit === "") {
      this.setState({
        submitStatusRegularFPF: "Error",
        submitMsgRegularFPF:
          "Please Enter The Value For Upper Limit",
      });
      return false;
    }
    return true;
  };

  convertDateToLegacyFormat = (date) => {
    let d1RFPF = new Date(date);
    let newStartDate = [
      ("0" + (d1RFPF.getMonth() + 1)).slice(-2),
      ("0" + d1RFPF.getDate()).slice(-2),
      d1RFPF.getFullYear(),
    ].join("/");
    return newStartDate;
  };

  onHandleAddRegularFPF = () => {
    if (!this.onValidateRegularFPF("Add")) {
      return;
    }
    let submittedObj = {};
    submittedObj.sending = {};
    submittedObj.receiving = {};
    submittedObj.sendingPrincipal = {};
    submittedObj.timestamp = this.state.timestamp;
    submittedObj.sequenceNum = this.state.sequenceNum;
    submittedObj.sendCountryCpc = this.state.sendCountryCpc.toUpperCase();
    submittedObj.startDate = this.convertDateToLegacyFormat(
      this.state.startDate
    );
    submittedObj.endDate = this.convertDateToLegacyFormat(this.state.endDate);
    submittedObj.action = this.state.action;
    submittedObj.delayMin = this.state.delayMin;
    submittedObj.queueName = this.state.queueName;
    submittedObj.filterType = this.state.filterType;
    submittedObj.channel = this.state.channel;
    submittedObj.moneyTransferType = this.state.moneyTransferType;
    submittedObj.siteId = this.state.siteId;
    submittedObj.specificCsc = this.state.specificCsc;
    submittedObj.comments = this.state.comments;
    submittedObj.messageIndicator = this.state.messageIndicator;
    submittedObj.message1 = this.state.message1;
    submittedObj.message2 = this.state.message2;
    submittedObj.message3 = this.state.message3;

    // submittedObj.sending.countryState = this.state.countryState;
    this.onHandleCountryStateValue(submittedObj);

    // submittedObj.sending.phone = this.state.phone;
    // submittedObj.sending.agent = this.state.agent;
    // submittedObj.sending.agentNetwork = this.state.agentNetwork;
    // submittedObj.receiving.countryCpc = this.state.countryCpc;
    // submittedObj.receiving.rcvState = this.state.rcvState;
     if (this.state.upperLimit === 0) {
      submittedObj.sendingPrincipal.upperLimit = "9999999999";
    } else {
      submittedObj.sendingPrincipal.upperLimit = this.state.upperLimit;
    } 
    
    submittedObj.sendingPrincipal.lowerLimit = this.state.lowerLimit;
    submittedObj.operatorId = this.props.location.records.operatorIdFPF;

    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/search`,
        submittedObj,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusRegularFPF: "OK",
            submitMsgRegularFPF: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          this.onLoadingRegularFPF();
        }else {
          this.setState({
            submitStatusRegularFPF: "Error",
            submitMsgRegularFPF: response.data.responseString,
          });
        }
        this.onHandleClearRegularFPF();
      });
  };

  onHandleCountryStateValue = (submittedObj) => {
    if (this.state.countryStateAll) {
      submittedObj.sending.countryState = "";
    } else {
      submittedObj.sending.countryState = this.state.countryState;
    }
    if (this.state.phoneAll) {
      submittedObj.sending.phone = "";
    } else {
      submittedObj.sending.phone = this.state.phone;
    }
    if (this.state.agentAll) {
      submittedObj.sending.agent = "";
    } else {
      submittedObj.sending.agent = this.state.agent;
    }
    if (this.state.agentNetworkAll) {
      submittedObj.sending.agentNetwork = "";
    } else {
      submittedObj.sending.agentNetwork = this.state.agentNetwork;
    }
    if (this.state.countryCpcAll) {
      submittedObj.receiving.countryCpc = "";
    } else {
      submittedObj.receiving.countryCpc = this.state.countryCpc;
    }
    if (this.state.rcvStateAll) {
      submittedObj.receiving.rcvState = "";
    } else {
      submittedObj.receiving.rcvState = this.state.rcvState;
    }
  };

  onHandleUpdateRegularFPF = () => {
   /*  if (!this.onValidateRegularFPF()) {
      return;
    } */
    let submittedObj = {};
    submittedObj.sending = {};
    submittedObj.receiving = {};
    submittedObj.sendingPrincipal = {};
    submittedObj.timestamp = this.state.timestamp;
    submittedObj.sequenceNum = this.state.sequenceNum;
    submittedObj.sendCountryCpc = this.state.sendCountryCpc;
    submittedObj.startDate = this.convertDateToLegacyFormat(
      this.state.startDate
    );
    submittedObj.endDate = this.convertDateToLegacyFormat(this.state.endDate);
    submittedObj.action = this.state.action;
    submittedObj.delayMin = this.state.delayMin;
    submittedObj.queueName = this.state.queueName;
    submittedObj.filterType = this.state.filterType;
    submittedObj.channel = this.state.channel;
    submittedObj.siteId = this.state.siteId;
    submittedObj.specificCsc = this.state.specificCsc;
    submittedObj.comments = this.state.comments;
    submittedObj.messageIndicator = this.state.messageIndicator;
    submittedObj.message1 = this.state.message1;
    submittedObj.message2 = this.state.message2;
    submittedObj.message3 = this.state.message3;
    submittedObj.moneyTransferType = this.state.moneyTransferType;
    this.onHandleCountryStateValue(submittedObj);
    // submittedObj.sending.countryState = this.state.countryState;
    // submittedObj.sending.phone = this.state.phone;
    // submittedObj.sending.agent = this.state.agent;
    // submittedObj.sending.agentNetwork = this.state.agentNetwork;
    // submittedObj.receiving.rcvState = this.state.rcvState;
    // submittedObj.receiving.countryCpc = this.state.countryCpc;
    submittedObj.sendingPrincipal.lowerLimit = this.state.lowerLimit;
    if (this.state.upperLimit === 0 || this.state.upperLimit === "0") {
      submittedObj.sendingPrincipal.upperLimit =  "9999999999";
    } else {
      submittedObj.sendingPrincipal.upperLimit = this.state.upperLimit;
    } 
    submittedObj.operatorId = this.props.location.records.operatorIdFPF;

    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/search`,
        submittedObj,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.timestamp + "" + row.sequenceNum ===
              this.state.timestamp + "" + this.state.sequenceNum
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusRegularFPF: "OK",
            submitMsgRegularFPF: response.data.responseString,
            rows: updatedRows,
          });
          this.onLoadingRegularFPF();
        } else {
          this.setState({
            submitStatusRegularFPF: "Error",
            submitMsgRegularFPF: response.data.responseString,
          });
        }
        this.onHandleClearRegularFPF();
      });
      this.setState({ showRegularFPFUpdateConfirm: false });
  };

  getRegularFPFUpdateModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showRegularFPFUpdateConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Update This Fraud Prevention Filter ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showRegularFPFUpdateConfirm: false,
                  })
                }
                data-test="button-cancel"
              >
               Cancel
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleUpdateRegularFPF}
                data-test="button-ok"
              >
                Ok
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
 
  onHandleClearRegularFPF = () => {
    this.setState({
      id: "",
      sendCountryCpc: "",
      startDate: "",
      endDate: "",
      action: "",
      delayMin: "",
      queueName: "",
      filterType: "",
      channel: "",
      moneyTransferType: "",
      siteId: "",
      specificCsc: "",
      comments: "",
      messageIndicator: "N",
      message1: "",
      message2: "",
      message3: "",
      lowerLimit: 0,
      upperLimit: 0,
      countryState: "",
      countryStateAll: false,
      phone: "",
      phoneAll: false,
      agent: "",
      agentAll: false,
      agentNetwork: "",
      agentNetworkAll: false,
      countryCpc: "",
      countryCpcAll: false,
      rcvState: "",
      rcvStateAll: false,
      enableBtn: false,
    });

    setTimeout(
      () =>
        this.setState({
          submitStatusRegularFPF: null,
        }),
      5000
    );
  };

  getRegularFPFModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showRegularFPFConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete The Sending Country CPC {this.state.sendCountryCpc} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showRegularFPFConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteRegularFPF}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteRegularFPF = () => {
    let submittedObj = {};
    submittedObj.timestamp = this.state.timestamp;
    submittedObj.sequenceNum = this.state.sequenceNum;
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/search/${this.state.timestamp}/${this.state.sequenceNum}`,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          let newRows = this.state.rows.filter((row) => {
            return row.timestamp !== submittedObj.timestamp;
          });

          this.setState({
            submitStatusRegularFPF: "OK",
            submitMsgRegularFPF: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusRegularFPF: "Error",
            submitMsgRegularFPF: response.data.responseString,
          });
        }
        this.onHandleClearRegularFPF();
      });
    this.setState({ showRegularFPFConfirm: false });
  };

  onChangeControlFPCFDCALL = (e) => {
    // console.log("e.target.value"+ e.target.value)
    this.setState({
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };
  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="FRAUD PREVENTION FILTER MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">FRAUD PREVENTION FILTER MAINTENANCE</h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  CPC FILTER DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="sendCountryCpc" className="font-weight-bold">
                          Sending Country CPC
                        </label>

                        <input
                          type="text"
                          maxLength="3"
                          disabled={this.state.enableBtn}
                          value={this.state.sendCountryCpc}
                          className="form-control form-control-sm"
                          name="sendCountryCpc"
                          onChange={(e) => {
                            this.onChangeControlRegularFPFCharNum(e);
                          }}
                          data-test="text-sendCountryCpc"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="startDate" className="font-weight-bold">Start Date</label>
                        <br />
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="startDate"
                          value={this.state.startDate}
                          onChange={this.onChangeDateControlRegularFPF}
                          data-test="date-startDate"
                        ></input>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="endDate" className="font-weight-bold">End Date</label>
                        <br />
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="endDate"
                          value={this.state.endDate}
                          onChange={this.onChangeDateControlRegularFPF}
                          data-test="date-endDate"
                        ></input>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Action</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="action"
                              value="Q"
                              data-test="text-action-Q"
                              checked={this.state.action === "Q"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="Q">
                              Queue
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="action"
                              value="S"
                              data-test="text-action-S"
                              checked={this.state.action === "S"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="S">
                              Suspend
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="action"
                              value="D"
                              data-test="text-action-D"
                              checked={this.state.action === "D"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="D">
                              Delay Period
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="delayMin" className="font-weight-bold">Delay Min</label>
                        <input
                          type="text"
                          maxLength="5"
                          value={this.state.delayMin}
                          className="form-control form-control-sm"
                          name="delayMin"
                          onChange={(e) => {
                            this.onChangeControlRegularFPFNum(e);
                          }}
                          data-test="text-delayMin"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="queueName" className="font-weight-bold">Queue Name</label>
                        <input
                          type="text"
                          maxLength="8"
                          value={this.state.queueName}
                          className="form-control form-control-sm"
                          name="queueName"
                          onChange={(e) => {
                            this.onChangeControlRegularFPFCharNum(e);
                          }}
                          data-test="text-queueName"
                        />
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Filter Type</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="filterType"
                              value="A"
                              data-test="text-filterType-A"
                              checked={this.state.filterType === "A"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="A">
                              Agent
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="filterType"
                              value="C"
                              data-test="text-filterType-C"
                              checked={this.state.filterType === "C"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="C">
                              Consumer
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="filterType"
                              value="B"
                              data-test="text-filterType-B"
                              checked={this.state.filterType === "B"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="B">
                              Both
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Channel</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="channel"
                              value="1"
                              data-test="text-channel-1"
                              checked={this.state.channel === "1"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="1">
                              Will Call
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="channel"
                              value="2"
                              data-test="text-channel-2"
                              checked={this.state.channel === "2"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="2">
                              Debit Card
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="channel"
                              value=" "
                              data-test="text-channel-All"
                              checked={this.state.channel === " "}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor=" ">
                              ALL
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <div className="row">
                          <div className="col-lg-5">
                            <label htmlFor="moneyTransferType" className="font-weight-bold">
                              Money Transfer Type
                            </label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="moneyTransferType"
                                value="1"
                                checked={this.state.moneyTransferType.includes(
                                  "1"
                                )}
                                data-test="check-moneyTransferType1"
                                onChange={this.onChangeCheckBoxRegularFPF}
                              />
                              <label className="form-check-label">
                                Will Call
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="moneyTransferType"
                                value="2"
                                checked={this.state.moneyTransferType.includes(
                                  "2"
                                )}
                                onChange={this.onChangeCheckBoxRegularFPF}
                                data-test="check-moneyTransferType2"
                              />
                              <label className="form-check-label">D2B</label>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="moneyTransferType"
                                value="3"
                                checked={this.state.moneyTransferType.includes(
                                  "3"
                                )}
                                onChange={this.onChangeCheckBoxRegularFPF}
                                data-test="check-moneyTransferType3"
                              />
                              <label className="form-check-label">H2H</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="moneyTransferType"
                                value="4"
                                checked={this.state.moneyTransferType.includes(
                                  "4"
                                )}
                                onChange={this.onChangeCheckBoxRegularFPF}
                                data-test="check-moneyTransferType4"
                              />
                              <label className="form-check-label">Staged</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="moneyTransferType"
                                value="5"
                                checked={this.state.moneyTransferType.includes(
                                  "5"
                                )}
                                onChange={this.onChangeCheckBoxRegularFPF}
                                data-test="check-moneyTransferType5"
                              />
                              <label className="form-check-label">
                                Quick Cash
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="moneyTransferType"
                                value="6"
                                checked={this.state.moneyTransferType.includes(
                                  "6"
                                )}
                                onChange={this.onChangeCheckBoxRegularFPF}
                                data-test="check-moneyTransferType6"
                              />
                              <label className="form-check-label">ABMT</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="moneyTransferType"
                                checked={this.state.moneyTransferType.includes(
                                  "7"
                                )}
                                value="7"
                                onChange={this.onChangeCheckBoxRegularFPF}
                                data-test="check-moneyTransferType7"
                              />
                              <label className="form-check-label">ALL</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Site Id</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="MOM6"
                              data-test="text-siteId-MOM6"
                              checked={this.state.siteId === "MOM6"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="MOM6">
                              Agent
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="CSC"
                              data-test="text-siteId-CSC"
                              checked={this.state.siteId === "CSC"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="CSC">
                              CSC's or
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="S"
                              data-test="text-siteId-S"
                              checked={this.state.siteId === "S"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="S">
                              Specific CSC:
                            </label>
                            <input
                              className="form-check-input "
                              type="text"
                              maxLength="4"
                              name="specificCsc"
                              value={this.state.specificCsc}
                              data-test="text-siteId-specificCsc"
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value=" "
                              data-test="text-siteId-All"
                              checked={this.state.siteId === " "}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor=" ">
                              ALL
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="comments" className="font-weight-bold">Comments</label>
                        <input
                          type="text"
                          maxLength="80"
                          value={this.state.comments}
                          className="form-control form-control-sm"
                          name="comments"
                          onChange={(e) => {
                            this.onChangeControlRegularFPF(e);
                          }}
                          data-test="text-comments"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Message Indicator</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="messageIndicator"
                              value="Y"
                              data-test="text-messageIndicator-Y"
                              checked={this.state.messageIndicator === "Y"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="messageIndicator"
                              value="N"
                              data-test="text-messageIndicator-N"
                              checked={this.state.messageIndicator === "N"}
                              onChange={(e) => {
                                this.onChangeControlRegularFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message1" className="font-weight-bold">Message 1</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message1}
                          className="form-control form-control-sm"
                          name="message1"
                          onChange={(e) => {
                            this.onChangeControlRegularFPF(e);
                          }}
                          data-test="text-message1"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message2" className="font-weight-bold">Message 2</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message2}
                          className="form-control form-control-sm"
                          name="message2"
                          onChange={(e) => {
                            this.onChangeControlRegularFPF(e);
                          }}
                          data-test="text-message2"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message3" className="font-weight-bold">Message 3</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message3}
                          className="form-control form-control-sm"
                          name="message3"
                          onChange={(e) => {
                            this.onChangeControlRegularFPF(e);
                          }}
                          data-test="text-message3"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="mar-top5">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        SENDING
                      </div>
                      <div className="card-body">
                      <div className="form-group row">
                        <div className="col-lg">
                          <label htmlFor="countryState" className="font-weight-bold">Country State</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={this.state.countryState}
                            className="form-control form-control-sm"
                            name="countryState"
                            onChange={(e) => {
                              this.onChangeControlRegularFPFCharNum(e);
                            }}
                            data-test="text-countryState"
                          />
                        </div>
                        <div className="form-check form-check-inline mar-top25">
                          <label className="form-check-label">or ALL :&nbsp;</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="countryStateAll"
                            value={this.state.countryStateAll}
                            data-test="text-countryState-ALL"
                            checked={this.state.countryStateAll}
                            onChange={(e) => {
                              this.onChangeControlFPCFDCALL(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg">
                          <label htmlFor="phone" className="font-weight-bold">Phone
                          </label>
                          <input
                            type="text"
                            maxLength="15"
                            value={this.state.phone}
                            className="form-control form-control-sm"
                            name="phone"
                            onChange={(e) => {
                              this.onChangeControlRegularFPF(e);
                            }}
                            data-test="text-phone"
                          />
                        </div>
                        <div className="form-check form-check-inline mar-top25">
                          <label className="form-check-label">or ALL :&nbsp;</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="phoneAll"
                            checked={this.state.phoneAll}
                            value={this.state.phoneAll}
                            data-test="text-phoneAll"
                            onChange={(e) => {
                              this.onChangeControlFPCFDCALL(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg">
                          <label htmlFor="agent" className="font-weight-bold">Agent</label>
                          <input
                            type="text"
                            maxLength="9"
                            value={this.state.agent}
                            className="form-control form-control-sm"
                            name="agent"
                            onChange={(e) => {
                              this.onChangeControlRegularFPF(e);
                            }}
                            data-test="text-agent"
                          />
                        </div>
                        <div className="form-check form-check-inline mar-top25">
                          <label className="form-check-label">or ALL :&nbsp;</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="agentAll"
                            value={this.state.agentAll}
                            checked={this.state.agentAll}
                            data-test="text-agent-all"
                            onChange={(e) => {
                              this.onChangeControlFPCFDCALL(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="agentNetwork" className="font-weight-bold">Agent Network</label>
                        <input
                          type="text"
                          maxLength="5"
                          value={this.state.agentNetwork}
                          className="form-control form-control-sm"
                          name="agentNetwork"
                          onChange={(e) => {
                            this.onChangeControlRegularFPF(e);
                          }}
                          data-test="text-agentNetwork"
                        />
                      </div>
                      <div className="form-check form-check-inline mar-top25">
                        <label className="form-check-label">or ALL :&nbsp;</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="agentNetworkAll"
                          value={this.state.agentNetworkAll}
                          checked={this.state.agentNetworkAll}
                          data-test="text-agentNetworkAll"
                          onChange={(e) => {
                            this.onChangeControlFPCFDCALL(e);
                          }}
                        />
                      </div>
                    </div>
                    </div>      
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        SENDING PRINCIPAL
                      </div>
                      <div className="card-body">
                        <div className="col-lg">
                          <label htmlFor="lowerLimit" className="font-weight-bold">Lower Limit : $</label>
                          <input
                            type="text"
                            maxLength="10"
                            value={this.state.lowerLimit}
                            className="form-control form-control-sm"
                            name="lowerLimit"
                            onChange={(e) => {
                              this.onChangeControlRegularFPFNum(e);
                            }}
                            data-test="text-lowerLimit"
                          />
                        </div>
                        <div className="col-lg">
                        <label className="font-weight-bold">Upper Limit : $</label>
                        <input
                          type="text"
                          maxLength="10"
                          value={this.state.upperLimit}
                          className="form-control form-control-sm"
                          name="upperLimit"
                          onChange={(e) => {
                            this.onChangeControlRegularFPFCharNum(e);
                          }}
                          data-test="text-upperLimit"
                        
                        />
                        <label>(USD Only)</label>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header bg-dark text-white card-header-custom">
                          RECEIVING
                        </div>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg">
                              <label htmlFor="countryCpc" className="font-weight-bold">Country CPC</label>
                              <input
                                type="text"
                                maxLength="3"
                                value={this.state.countryCpc}
                                className="form-control form-control-sm"
                                name="countryCpc"
                                onChange={(e) => {
                                  this.onChangeControlRegularFPFCharNum(e);
                                }}
                                data-test="text-countryCpc"
                              />
                            </div>
                            <div className="form-check form-check-inline mar-top25">
                              <label className="form-check-label">or ALL :&nbsp;</label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="countryCpcAll"
                                value={this.state.countryCpcAll}
                                checked={this.state.countryCpcAll}
                                data-test="text-countryCpcAll"
                                onChange={(e) => {
                                  this.onChangeControlFPCFDCALL(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                        <div className="col-lg">
                          <label htmlFor="rcvState" className="font-weight-bold">Country State</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={this.state.rcvState}
                            className="form-control form-control-sm"
                            name="rcvState"
                            onChange={(e) => {
                              this.onChangeControlRegularFPFCharNum(e);
                            }}
                            data-test="text-rcvState"
                          />
                        </div>
                        <div className="form-check form-check-inline mar-top25">
                          <label className="form-check-label">or ALL :&nbsp;</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="rcvStateAll"
                            value={this.state.rcvStateAll}
                            checked={this.state.rcvStateAll}
                            data-test="text-rcvState-all"
                            onChange={(e) => {
                              this.onChangeControlFPCFDCALL(e);
                            }}
                          />
                        </div>
                      </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row mar-top5">
                <div className="col-lg-3" />
                <div className="col-lg-6 text-center mar-bottom5">
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackRegularFPF}
                    data-test="button-back"
                  >
                    FRAUD PRVT
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    disabled={this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                    onClick={this.onHandleAddRegularFPF}
                    data-test="button-add"
                  >
                    ADD
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    //onClick={this.onHandleUpdateRegularFPF}
                  /*   onClick={() => {
                      this.setState({
                        showRegularFPFUpdateConfirm: true,
                      });
                    }} */
                    onClick={() => {
                      if (!this.onValidateRegularFPF()) {
                          return;
                        }           
                        this.setState({
                        showRegularFPFUpdateConfirm: true,
                        });
                        }}
                    data-test="button-update"
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    UPDATE
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    data-test="button-clear"
                    onClick={this.onHandleClearRegularFPF}
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    CLEAR
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => {
                      this.setState({
                        showRegularFPFConfirm: true,
                      });
                    }}
                    data-test="button-delete"
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    DELETE
                  </button>
                </div>
                <div className="col-lg-3">
                </div>
              </div>
              <div className="row mar-top10">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                  {this.state.submitStatusRegularFPF === "OK" && (
                    <div className="alert alert-success alert-padding notification text-center">
                      {this.state.submitMsgRegularFPF}
                    </div>
                  )}
                  {this.state.submitStatusRegularFPF === "Error" && (
                    <div className="alert alert-danger alert-padding notification text-center">
                      {this.state.submitMsgRegularFPF}
                    </div>
                  )}
                </div>
                <div className="col-lg-4"></div>
              </div>
              {this.getRegularFPFModal()}
              {this.getRegularFPFUpdateModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  CPC FILTER DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlRegularFPF(e);
                        this.onPageSizeChangedRegularFPF(e);
                      }}
                      value={this.state.paginationValQueue}
                      name="paginationDropdownQueue"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionRegularFPF.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReact
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyRegularFPF}
                      data-test="component-grid"
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateRFPF
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateRFPF
                      }
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RegularFraudPreventionFilter;
