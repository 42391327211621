import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersShared2 } from "../../utility/utility";

class WhitelistFraudRulePrepaid extends Component {
  state = {
    WhiteListData: [],
    ruleType: "",
    ruleAccount: "",
    timestamp: "",
    sequenceNum: "",
    whiteListCount: "",
    whiteListFlag: false,
    countryCpcIso: "",
    submitStatusWhitelistFRPrepaid: null,
    submitMsgWhitelistFRPrepaid: "",
  };

  onChangeControlWhitelistFRPrepaid = (e) => {
    if(this.state.ruleType === "P" ){
      this.setState({
        [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
      });
    }else{
      this.setState({
        [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
      });
    }
  };
  
  onLoadingWhitelistFRPrepaid = () => {
    const timeStamp = this.props.location.state.timeStamp;
    const seqNum = this.props.location.state.seqNum;
    if (this.props.location.state.whiteListIndicator === "Y" || this.props.location.state.whiteListIndicator === "N") {
      axios
        .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/whitelist/${timeStamp}/${seqNum}`,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
          },
        }
        )
        .then((response) => {
            this.setState({
              WhiteListData: response.data.records,
            });
            if(response.data.flag===0){
              this.setState({
                submitStatusWhitelistFRPrepaid: "Error",
                submitMsgWhitelistFRPrepaid: response.data.message,
              });
            }
        });
       
    }
  }
  componentDidMount() {
    this.onLoadingWhitelistFRPrepaid()
    this.setState({
      accessGrantedWriteFPF:this.props.location.records.accessGrantedWriteFPF
    });
  }

  onHandleBackWhitelistFRPrepaid = () => {
    const countrycpcIso = this.state.countryCpcIso;
    this.props.history.push({
      pathname: "/prepaid-fraud-prevention-filter",
       state:  countrycpcIso,
       records:this.props.location.records
    });
  };

  onValidateWhitelistFRPrepaid = () => {
    if (this.state.ruleType === "") {
      this.setState({
        submitStatusWhitelistFRPrepaid: "Error",
        submitMsgWhitelistFRPrepaid: "Please select The Value For Rule type",
      });
      return false;
    }
    if (this.state.ruleAccount === "") {
      this.setState({
        submitStatusWhitelistFRPrepaid: "Error",
        submitMsgWhitelistFRPrepaid: "Please Enter The Value For Rule Account ",
      });
      return false;
    }
    if (this.state.ruleType === "A" && this.state.ruleAccount.length !== 9) {
      this.setState({
        submitStatusWhitelistFRPrepaid: "Error",
        submitMsgWhitelistFRPrepaid: "Agent Account Should Be 9 Alpha-Numeric Character",
      });
      return false;
    }
    if (this.state.ruleType === "P" && this.state.ruleAccount.length !== 16) {
      this.setState({
        submitStatusWhitelistFRPrepaid: "Error",
        submitMsgWhitelistFRPrepaid: "Please Enter 16 Digit Numeric Prepaid Account Number",
      });
      return false;
    }  
    return true;
  };

  onHandleAddWhitelistFRPrepaid = () => {
    if (!this.onValidateWhitelistFRPrepaid()) {
      return;
    }
    const WhiteListData = this.state.WhiteListData;
    let submittedObjWhitelistPrepaid = {};
    submittedObjWhitelistPrepaid.timestamp = this.props.location.state.timeStamp;
    submittedObjWhitelistPrepaid.sequenceNum = this.props.location.state.seqNum;
    submittedObjWhitelistPrepaid.ruleType = this.state.ruleType;
    submittedObjWhitelistPrepaid.ruleAccount = this.state.ruleAccount;
    submittedObjWhitelistPrepaid.whiteListCount = WhiteListData.length;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/whitelist`,
        submittedObjWhitelistPrepaid,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusWhitelistFRPrepaid: "OK",
            submitMsgWhitelistFRPrepaid: response.data.responseString,
            WhiteListData: [submittedObjWhitelistPrepaid, ...this.state.WhiteListData],
          });
          this.onLoadingWhitelistFRPrepaid();
        } else {
          this.setState({
            submitStatusWhitelistFRPrepaid: "Error",
            submitMsgWhitelistFRPrepaid: response.data.responseString,
          });
          
        }
        this.onHandleClearWhitelistFRPrepaid();
      });
  };

  onHandleClearWhitelistFRPrepaid = () => {
    this.setState({
      ruleType: "",
      ruleAccount: "",
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusWhitelistFRPrepaid: null,
        }),
      5000
    );
  };

  onHandleDeleteWhitelistFRPrepaid = (e, data) => {
    let submittedObjWhitelistPrepaid = {};
    const timeStamp = this.props.location.state.timeStamp;
    const seqNum = this.props.location.state.seqNum;
    if (data.ruleType === "A" || data.ruleType === "N") {
      axios
        .delete(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/deletewhitelist/${data.ruleType}/${timeStamp}/${seqNum}`,
          {
            headers: {
              ...getHeadersShared2().headers,
              ruleAccount: data.ruleAccount,
              Authorization:this.props.location.records.token,
              "userId":this.props.location.records.operatorIdFPF
            }
          }
        )
        .then((response) => {
          if (response.status === 200) {
            let newRowsA = this.state.WhiteListData.filter((rowA) => {
              return rowA.ruleAccount !== submittedObjWhitelistPrepaid.ruleAccount;
            });

            this.setState({
              submitStatusWhitelistFRPrepaid: "OK",
              submitMsgWhitelistFRPrepaid: response.data.responseString,
              WhiteListData: newRowsA,
            });
            this.onLoadingWhitelistFRPrepaid();
          } else {
            this.setState({
              submitStatusWhitelistFRPrepaid: "Error",
              submitMsgWhitelistFRPrepaid: response.data.responseString,
            });  
          }
          this.onHandleClearWhitelistFRPrepaid();
        });
    } else if (data.ruleType === "P") {
      axios
        .delete(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/deletewhitelist/${data.ruleType}/${timeStamp}/${seqNum}`,
          {
            headers: {
              ...getHeadersShared2().headers,
              whiteListTimestamp: data.whiteListTimestamp,
              Authorization:this.props.location.records.token,
              "userId":this.props.location.records.operatorIdFPF
            }
          }
        )
        .then((response) => {
          if (response.status === 200) {
            let newRowsP = this.state.WhiteListData.filter((rowP) => {
              return rowP.ruleAccount !== submittedObjWhitelistPrepaid.ruleAccount;
            });

            this.setState({
              submitStatusWhitelistFRPrepaid: "OK",
              submitMsgWhitelistFRPrepaid: response.data.responseString,
              WhiteListData: newRowsP,
            });
            this.onLoadingWhitelistFRPrepaid();
          } else {
            this.setState({
              submitStatusWhitelistFRPrepaid: "Error",
              submitMsgWhitelistFRPrepaid: response.data.responseString,
            });
          }
          this.onHandleClearWhitelistFRPrepaid();
        });
    }

  };
  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="FRAUD PREVENTION FILTER MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                WHITELIST FRAUD RULE DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">

                  WHITELIST FRAUD RULE
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <label htmlFor="ruleType" className="font-weight-bold">Rule Type</label>
                    <div classNme="form-group row">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="ruleType"
                          value="A"
                          data-test="text-ruleType-A"
                          checked={this.state.ruleType === "A"}
                          onChange={(e) => {
                            this.onChangeControlWhitelistFRPrepaid(
                              e
                            );
                          }}
                        />
                        <label className="form-check-label" htmlFor="Block">
                          Agent
                            </label>
                      </div>
                      <div className="form-check form-check-inline">

                        <input
                          className="form-check-input "
                          type="radio"
                          name="ruleType"
                          value="N"
                          data-test="text-ruleType-N"
                          checked={this.state.ruleType === "N"}
                          onChange={(e) => {
                            this.onChangeControlWhitelistFRPrepaid(
                              e
                            );
                          }}
                        />
                        <label className="form-check-label" htmlFor="N">
                          Network
                            </label>

                      </div>
                      <div className="form-check form-check-inline">

                        <input
                          className="form-check-input "
                          type="radio"
                          name="ruleType"
                          value="P"
                          data-test="text-ruleType-P"
                          checked={this.state.ruleType === "P"}
                          onChange={(e) => {
                            this.onChangeControlWhitelistFRPrepaid(
                              e
                            );
                          }}
                        />
                        <label className="form-check-label" htmlFor="P">
                          Prepaid
                            </label>

                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="ruleAccount" className="font-weight-bold">Rule Account:</label>
                        <input
                          type="text"
                          maxlength ="16"
                          value={this.state.ruleAccount}
                          className="form-control form-control-sm"
                          name="ruleAccount"
                          onChange={(e) => {
                            this.onChangeControlWhitelistFRPrepaid(e);
                          }}
                          data-test="text-ruleAccount"

                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackWhitelistFRPrepaid}
                          data-test="button-back"
                        >
                          FRAUD FILTER
                        </button>
                      
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleAddWhitelistFRPrepaid}
                            data-test="button-add"
                            disabled={!this.state.accessGrantedWriteFPF}
                          >
                            ADD
                          </button>
                       


                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearWhitelistFRPrepaid}
                          data-test="button-clear"
                          disabled={!this.state.accessGrantedWriteFPF}
                        >
                          CLEAR
                          </button>

                     




                      </div>
                      <div className="col-lg-3">
                        
                      </div>
                    </div>

                    <div className="row mar-top10">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                        {this.state.submitStatusWhitelistFRPrepaid ===
                          "OK" && (
                            <div className="alert alert-success alert-padding notification text-center">
                              {this.state.submitMsgWhitelistFRPrepaid}
                            </div>
                          )}
                        {this.state.submitStatusWhitelistFRPrepaid ===
                          "Error" && (
                            <div className="alert alert-danger alert-padding notification text-center">
                              {this.state.submitMsgWhitelistFRPrepaid}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-4"></div>
                      </div>
                  </form>
                </div>
              </div>
            
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  WHITELIST FRAUD RULE DATA
                </div>
                <table className="table table-bordered margin-0"  >
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">TYPE</th>
                      <th scope="col">ACCOUNT</th>

                      <th scope="col">ACTION</th>

                    </tr>
                  </thead>
                  <tbody>

                    {
                      this.state.WhiteListData.map(
                        (data, i) => (
                          <tr key={i} data-test="tr">
                            <td>{data.ruleType}</td>
                            <td>{data.ruleAccount}</td>



                            <td>
                              <button
                                type="button"
                                className="btn btn-warning"
                      
                                onClick={(e) => this.onHandleDeleteWhitelistFRPrepaid(e, data)}

                                data-test="button-delete"
                                disabled={!this.state.accessGrantedWriteFPF}
                              >
                                DELETE
                              </button>
                            </td>
                          </tr>
                        )
                      )}


                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhitelistFraudRulePrepaid;
