import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

import { getHeadersShared2 } from "../../utility/utility";

class FraudPreventionFilter extends Component {
  state = {
    countryCpcIso: "",
    regular: "1",
    c2c: "",
    prepaid: "",
    queueLimit: "",
    currentCount: "",
    activeDate: "",
    lastUpdateOn: "",
    byOperator: "",
    terminalId: "",
    fraudPreveFilterMsg: "",
    showFraudPreventionFilterConfirm: false,
  };

  onHandleSearchFraudPreventionFilter = () => {
    if (this.state.regular === "1") {
      const countrycpcIso = this.state.countryCpcIso;
      this.props.history.push({
        pathname: "/regular-fraud-prevention-filter",
        state: countrycpcIso,
        records:this.state
      });
    } else if (this.state.regular === "2") {
      const countrycpcIso = this.state.countryCpcIso;
      this.props.history.push({
        pathname: "/c2c-fraud-prevention-filter",
        state: countrycpcIso,
        records:this.state
      });
    } else if (this.state.regular === "3") {
      const countrycpcIso = this.state.countryCpcIso;
      this.props.history.push({
        pathname: "/prepaid-fraud-prevention-filter",
        state: countrycpcIso,
        records:this.state
      });
    }
  };

  onHandlePrepayAccount = () => {
    this.props.history.push({
      pathname:"/prepay-fraud-account-details",
      records:this.state
    });
  };

  onHandleFraudPrev = () => {
    this.props.history.push({pathname:"/tip-log-search",stateData:{searchTypeTLS: "FPF", searchValueTLS: "Fraud Prevention",disableStatus:true}});
  };

  onHandleFraudPrevC2C = () => {
    this.props.history.push({pathname:"/tip-log-search",stateData:{searchTypeTLS: "FPFC", searchValueTLS: "Fraud Prev C2C/Pre",disableStatus:true}});
  };

  onChangeControlFraudPreventionFilter = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlFraudPreventionFilterNum = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
    });
  };

  onChangeControlFraudPreventionFilterOnlyChar = (e) => {
    let val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val.replace(/[^a-zA-Z]/, ""),
    });
  };

  onValidateFraudPreventionFilter = () => {
    let tDate=new Date()
    let newTDate = [
      tDate.getFullYear(),
      ("0" + (tDate.getMonth() + 1)).slice(-2),
      ("0" + tDate.getDate()).slice(-2),
    ].join("-");

    if (this.state.queueLimit === "") {
      this.setState({
        submitStatusFraudPreventionFilter: "Error",
        submitMsgFraudPreventionFilter: "Please Enter The Value For Queue Limit",
      });
      return false;
    }
    if(newTDate > this.state.activeDate) {
      this.setState({
        submitStatusFraudPreventionFilter: "Error",
        submitMsgFraudPreventionFilter: "Activity Date Cannot Be Less Than Today's Date",
      });
      return false;
    }
    return true;
  };

  componentDidMount() {
    //console.log(this.props.authState.accessToken)
    const accessToken = this.props.authState.accessToken;
    try {
      const token = jwt_decode(accessToken.value);
      const id=jwt_decode(accessToken.value).sub;
      const operatorIdFPF = id.replace(/\D/g,'');
      const accessRole = jwt_decode(accessToken.value).role;
      const accessGrantFPF =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS
        ) ||
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS
        )
        ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_FRAUDPREVENTION);
      const accessGrantWriteFPF =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS 
        )
        ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_FRAUDPREVENTION);
        const accessGrantFPFRecount =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS 
        ) ||
        (accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN)
        && accessRole.includes(process.env.REACT_APP_DB_VIEW_FRAUDPREVENTION));
      this.setState({
        accessToken: token,
        token:accessToken.value,
        role: accessRole,
        accessGrantedFPF: accessGrantFPF,
        accessGrantedWriteFPF: accessGrantWriteFPF,
        operatorIdFPF: operatorIdFPF,
        accessGrantedFPFRecount:accessGrantFPFRecount
      }, () => {
        if(!this.state.accessGrantedFPF) { 
          this.setState({
            submitStatusFraudPreventionFilter: 'Error',
            submitMsgFraudPreventionFilter: "Unauthorized!Access Is Denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/queuecontrol`,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:accessToken.value,
          },
        }
      )
      .then((response) => {
        if(response.data.flag === 1) {
        this.setState({
          activeDate: response.data.activeDate,
          queueLimit: response.data.queueLimit,
          currentCount: response.data.currentCount,
          terminalId: response.data.terminalId,
          byOperator: response.data.byOperator,
          lastUpdateOn: response.data.lastUpdateOn,
        });
      } else {
        this.setState({
          submitStatusFraudPreventionFilter: "Error",
          submitMsgFraudPreventionFilter: response.data.message,
        });
      }
      });
  }

  onLoadingFraudPreventionFilter =()=>{
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/queuecontrol`,
        {headers: {
            ...getHeadersShared2().headers,
            Authorization:this.state.token,
          },})
      .then((response) => {
        if(response.data.flag === 1) {
        this.setState({
          activeDate: response.data.activeDate,
          queueLimit: response.data.queueLimit,
          currentCount: response.data.currentCount,
          terminalId: response.data.terminalId,
          byOperator: response.data.byOperator,
          lastUpdateOn: response.data.lastUpdateOn,
        });} else {
        this.setState({
          submitStatusFraudPreventionFilter: "Error",
          submitMsgFraudPreventionFilter: response.data.message,
        });
      }});
  }

  convertDateToLegacyFormat = (date) => {
    let d1 = new Date(date);
    let newStartDate = [
      d1.getFullYear(),
      ("0" + (d1.getMonth() + 1)).slice(-2),
      ("0" + d1.getDate()).slice(-2),
    ].join("-");
    return newStartDate;
  };

  onHandleUpdateFraudPreventionFilter = () => {
    if (!this.onValidateFraudPreventionFilter()) {
      return;
    }
    let submittedObjUpdateFPF = {};
    submittedObjUpdateFPF.queueLimit = this.state.queueLimit;
    submittedObjUpdateFPF.activeDate = this.convertDateToLegacyFormat(
      this.state.activeDate
    );
    submittedObjUpdateFPF.byOperator = this.state.byOperator;
    submittedObjUpdateFPF.currentCount = this.state.currentCount;
    submittedObjUpdateFPF.terminalId = this.state.terminalId;
    submittedObjUpdateFPF.lastUpdateOn = this.state.lastUpdateOn;
    submittedObjUpdateFPF.operatorId = this.state.operatorIdFPF;
    submittedObjUpdateFPF.queueControlType = "update";
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/queuecontrol`,
        submittedObjUpdateFPF,
        { headers: {
            ...getHeadersShared2().headers,
            Authorization:this.state.token,
            "userId":this.state.operatorIdFPF
          },})
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusFraudPreventionFilter: "OK",
            submitMsgFraudPreventionFilter: response.data.responseString,
            enableBtn: false,
          });
          this.onLoadingFraudPreventionFilter();
        } else {
          this.setState({
            submitStatusFraudPreventionFilter: "Error",
            submitMsgFraudPreventionFilter: response.data.responseString,
          });
        }
        this.onHandleClearMessageFPF();
      });
  };

  onHandleRecountFraudPreventionFilter = () => {
    let submittedObjRecountFPF = {};
    submittedObjRecountFPF.queueLimit = this.state.queueLimit;
    submittedObjRecountFPF.activeDate = this.convertDateToLegacyFormat(
      this.state.activeDate
    );
    submittedObjRecountFPF.byOperator = this.state.byOperator;
    submittedObjRecountFPF.currentCount = this.state.currentCount;
    submittedObjRecountFPF.terminalId = this.state.terminalId;
    submittedObjRecountFPF.lastUpdateOn = this.state.lastUpdateOn;
    submittedObjRecountFPF.operatorId = this.state.operatorIdFPF;
    submittedObjRecountFPF.queueControlType = "recount";
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/queuecontrol`,
        submittedObjRecountFPF,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.state.token,
            "userId":this.state.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusFraudPreventionFilter: "OK",
            submitMsgFraudPreventionFilter: response.data.responseString,
            enableBtn: false,
          });
          this.onLoadingFraudPreventionFilter();
        } else {
          this.setState({
            submitStatusFraudPreventionFilter: "Error",
            submitMsgFraudPreventionFilter: response.data.responseString,
          });
        }
        this.onHandleClearMessageFPF();
      });
      this.setState({ showFraudPreventionFilterConfirm: false });
  };

  getFraudPreventionFilterModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showFraudPreventionFilterConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Recount Transaction Queue Control?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleRecountFraudPreventionFilter}
                data-test="button-ok"
              >
                Ok
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showFraudPreventionFilterConfirm: false,
                  })
                }
                data-test="button-cancel"
              >
               Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleClearMessageFPF = () => {
     setTimeout(
      () =>
        this.setState({
          submitStatusFraudPreventionFilter: null,
          submitMsgFraudPreventionFilter: "",
        }),
      5000
    );
  };

  render() {
    // console.log("token",this.state.token)
    return (
      <>
        <Header
          history={this.props.history}
          heading="FRAUD PREVENTION FILTER MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
              FRAUD PREVENTION FILTER MAINTENANCE
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  CPC FILTER SEARCH
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="countryCpcIso" className="font-weight-bold">
                          ENTER SENDING CPC/ISO FILTER:
                        </label>
                        <input
                          type="text"
                          value={this.state.countryCpcIso}
                          className="form-control form-control-sm"
                          name="countryCpcIso"
                          maxLength="3"
                          onChange={(e) => {
                            this.onChangeControlFraudPreventionFilterOnlyChar(e);
                          }}
                          data-test="text-countryCpcIso"
                          placeholder="CPC/ISO"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="regular"
                              value={1}
                              data-test="radio-regular"
                              checked={this.state.regular === "1"}
                              onChange={(e) => {
                                this.onChangeControlFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="1">
                              REGULAR
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="regular"
                              value={2}
                              data-test="radio-regular-c2c"
                              checked={this.state.regular === "2"}
                              onChange={(e) => {
                                this.onChangeControlFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="2">
                              C2C
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="regular"
                              value={3}
                              data-test="radio-regular-prepaid"
                              checked={this.state.regular === "3"}
                              onChange={(e) => {
                                this.onChangeControlFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="3">
                              PREPAID
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleSearchFraudPreventionFilter}
                          data-test="button-search"
                          disabled={!this.state.accessGrantedFPF}
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandlePrepayAccount}
                          data-test="button-prepay"
                          disabled={!this.state.accessGrantedFPF}
                        >
                          PREPAY ACCT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleFraudPrev}
                          data-test="button-fraud"
                        >
                          FRAUD HIST
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleFraudPrevC2C}
                          data-test="button-prehist"
                        >
                          FRAUD C2C/PRE HIST
                        </button>
                      </div>
                      <div className="col-lg-3">
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  CONSUMER FRAUD TRANSACTION QUEUE CONTROL
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="activeDate" className="font-weight-bold">ACTIVE DATE</label>
                        <input
                          type="date"
                          //type="text"
                          className="form-control form-control-sm"
                          name="activeDate"
                          value={this.state.activeDate}
                          onChange={this.onChangeControlFraudPreventionFilter}
                          data-test="date-activeDate"
                        ></input>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="queueLimit" className="font-weight-bold">QUEUE LIMIT:</label>

                        <input
                          type="text"
                          maxLength="5"
                          value={this.state.queueLimit}
                          className="form-control form-control-sm"
                          name="queueLimit"
                          onChange={this.onChangeControlFraudPreventionFilterNum}
                          data-test="text-queueLimit"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="currentCount" className="font-weight-bold">CURRENT CNT:</label>

                        <input
                          type="text"
                          value={this.state.currentCount}
                          className="form-control form-control-sm"
                          name="currentCount"
                          /*  onChange={(e) => {
                             this.onChangeControlFraudPreventionFilter(e);
                           }} */
                          data-test="text-currentCount"
                          placeholder="0"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="lastUpdateOn" className="font-weight-bold">LAST UPDATED ON</label>
                        <input
                          type="text"
                          value={this.state.lastUpdateOn}
                          name="lastUpdateOn"
                          className="form-control form-control-sm"
                          data-test="text-lastUpdateOn"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="byOperator" className="font-weight-bold">BY OPERATOR</label>
                        <input
                          type="text"
                          value={this.state.byOperator}
                          name="byOperator"
                          className="form-control form-control-sm"
                          data-test="text-byOperator"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="terminalId" className="font-weight-bold">TERMINAL ID</label>
                        <input
                          type="text"
                          value={this.state.terminalId}
                          name="terminalId"
                          className="form-control form-control-sm"
                          data-test="text-terminalId"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateFraudPreventionFilter}
                          data-test="button-update"
                          disabled={!this.state.accessGrantedWriteFPF}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                         // onClick={this.onHandleRecountFraudPreventionFilter}
                         onClick={() => {
                          if (!this.onValidateFraudPreventionFilter()) {
                            return;
                          }      
                          this.setState({
                            showFraudPreventionFilterConfirm: true,
                          });
                            }}
                          data-test="button-recount"
                          disabled={!this.state.accessGrantedFPFRecount}
                        >
                          Recount
                        </button>
                      </div>
                    </div>
                    <div className="row mar-top10">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4 ">
                        {this.state.submitStatusFraudPreventionFilter ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgFraudPreventionFilter}
                          </div>
                        )}
                        {this.state.submitStatusFraudPreventionFilter ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgFraudPreventionFilter}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-4"></div>
                      </div>
                    {this.getFraudPreventionFilterModal()}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withOktaAuth(FraudPreventionFilter);
