import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit } from "react-icons/ai";
import { IconContext } from "react-icons";
import { getHeadersShared2 } from "../../utility/utility";

class PrepayFraudAccountDetails extends Component {
  state = {
  
    
    prepayAccount: "",
    
    showPrepayFraudAccountConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => this.handleEditPrepayFraudAccount(params)}
            >
              <IconContext.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEdit />
              </IconContext.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },

      {
       
        field: "prepayAccount",
        headerName: "PREPAY FRAUD ACCOUNT",
        width: "1160px",
      },
     
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValQueue: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusPrepayFraudAccount: null,
    submitMsgPrepayFraudAccount: "",
    paginationOptionPrepayFraudAccount: [25, 50, 100, 500],
    overlayLoadingTemplatePrepay:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplatePrepay: '<span style="">No Records Found</span>',
  };

  onChangeControlPrepayFraudAccount = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onGridReadyPrepayFraudAccount = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/fraudaccount`,  
      {
        headers: {
          ...getHeadersShared2().headers,
          Authorization:this.props.location.records.token,
        },
      }
      )
      .then((response) => {
        if(response.data.flag === 1){
        this.setState({
          rows: response.data.records,
        });
      }else{
        this.setState({
          rows:[],
          overlayNoRowsTemplatePrepay:`<span style="">${response.data.message}</span>`
      });
      }
      }); 
  };

  componentDidMount=()=>{
    this.setState({
      accessGrantedWriteFPF:this.props.location.records.accessGrantedWriteFPF
    });
  }

  handleEditPrepayFraudAccount = (params) => {
    let data = params.node.data;
    this.setState({
      prepayAccount: data.prepayAccount,
      enableBtn: true,
    });
  };

  onPageSizeChangedPrepayFraudAccount = (e) => {
    this.setState({ paginationValQueue: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackPrepayFraudAccount = () => {
    this.props.history.push("/fraud-prevention-filter");
  };
 

  onValidatePrepayFraudAccount = () => {
    if (this.state.prepayAccount === "") {
      this.setState({
        submitStatusPrepayFraudAccount: "Error",
        submitMsgPrepayFraudAccount: "Please Enter The Value For Prepay Fraud Account ",
      });
      return false;
    }
    return true;
  };

  onHandleAddPrepayFraudAccount = () => {
    if (!this.onValidatePrepayFraudAccount()) {
      return;
    }
    let submittedObj = {}; 
    submittedObj.prepayAccount = this.state.prepayAccount;
    axios
         .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/fraudaccount/${this.state.prepayAccount}`,
        submittedObj,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
     )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusPrepayFraudAccount: "OK",
            submitMsgPrepayFraudAccount: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusPrepayFraudAccount: "Error",
            submitMsgPrepayFraudAccount: response.data.responseString,
          });
        }
        this.onHandleClearPrepayFraudAccount();
      });
  };
 

  onHandleClearPrepayFraudAccount = () => {
    this.setState({
      prepayAccount: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusPrepayFraudAccount: null,
        }),
      5000
    );
  };

  getPrepayFraudAccountModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showPrepayFraudAccountConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Prepay Account {this.state.prepayAccount} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showPrepayFraudAccountConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeletePrepayFraudAccount}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeletePrepayFraudAccount = () => {
    let submittedObj = {};
    submittedObj.prepayAccount = this.state.prepayAccount;
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/fraudaccount/${this.state.prepayAccount}`,
     
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
          },
        }
        
      )

   
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.prepayAccount !== submittedObj.prepayAccount;
          });

          this.setState({
            submitStatusPrepayFraudAccount: "OK",
            submitMsgPrepayFraudAccount: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearPrepayFraudAccount();
        } else {
          this.setState({
            submitStatusPrepayFraudAccount: "Error",
            submitMsgPrepayFraudAccount: response.data.responseString,
          });
          this.onHandleClearPrepayFraudAccount();
        }
      });
    this.setState({ showPrepayFraudAccountConfirm: false });
  };
  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="FRAUD PREVENTION FILTER MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
              PREPAY FRAUD ACCOUNT DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                PREPAY FRAUD ACCOUNT
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      
                    <div className="col-lg">
                        <label htmlFor="prepayAccount" className="font-weight-bold">Prepay Account:</label>
                        <input
                          type="text"
                          maxlength="9"
                          value={this.state.prepayAccount}
                          className="form-control form-control-sm"
                          name="prepayAccount"
                          onChange={(e) => {
                            this.onChangeControlPrepayFraudAccount(e);
                          }}
                          data-test="text-prepayAccount"
                          placeholder="PrepayAccount"
                        />
                      </div>
                      </div>
                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                      <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackPrepayFraudAccount}
                          data-test="button-back"
                        >
                          FRAUD PRVT
                        </button>
                        {this.state.enableBtn ? (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            disabled
                          >
                            ADD
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleAddPrepayFraudAccount}
                            data-test="button-add"
                            disabled={!this.state.accessGrantedWriteFPF}
                          >
                            ADD
                          </button>
                        )}
                        
                         {this.state.enableBtn ? (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleClearPrepayFraudAccount}
                            disabled={!this.state.accessGrantedWriteFPF}
                          >
                            CLEAR
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            disabled
                          >
                            CLEAR
                          </button>
                        )}
                        {this.state.enableBtn ? (
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showPrepayFraudAccountConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.accessGrantedWriteFPF}
                        >
                          DELETE
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-warning"
                          disabled
                        >
                          DELETE
                        </button>
                      )} 
                      </div>
                      <div className="col-lg-3">
                        
                      </div>
                    </div>
                    <div className="row mar-top10">
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4">
                      {this.state.submitStatusPrepayFraudAccount ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgPrepayFraudAccount}
                          </div>
                        )}
                        {this.state.submitStatusPrepayFraudAccount ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgPrepayFraudAccount}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-4"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getPrepayFraudAccountModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                PREPAY FRAUD ACCOUNT DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlPrepayFraudAccount(e);
                        this.onPageSizeChangedPrepayFraudAccount(e);
                      }}
                      value={this.state.paginationValQueue}
                      name="paginationDropdownQueue"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionPrepayFraudAccount.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReact
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyPrepayFraudAccount}
                      data-test="component-grid"
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplatePrepay
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplatePrepay
                      }
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PrepayFraudAccountDetails;
