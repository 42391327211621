import React, { Component } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { getHeadersOrder } from "../../utility/utility";

class QueueAgeWorkflowMaintenance extends Component {
  state = {
    functionNew: "",
    functionExisting: "",
    count1: 0,
    count2: 0,
    queueGroup: "",
    queueName: "",
    enableDisableAction: "",
    enableDisablePwd: "",
    curTime: new Date().toISOString(),
    showWorkflowMaintenanceConfirm: false,
    rows: [],
    enableBtn: false,
    submitStatusWorkflowMaintenance1: null,
    submitMsgWorkflowMaintenance1: "",
    submitStatusWorkflowMaintenance2: null,
    submitMsgWorkflowMaintenance2: "",
  };

  onSelectQueueAgeWorkflowMaintenance = (params, e) => {
    if (params.queueGroup === "ALL - ENABLE/DISABLE ALL QUEUES") {
      this.setState({
        enableDisableAction: "enableDisableAll",
      });
    } else {
      this.setState({
        enableDisableAction: "enableDisable",
      });
    }
    const selectedRowQAWM = {};
    selectedRowQAWM.functionNew = e.target.value;
    selectedRowQAWM.functionExisting = params.functionExisting;
    selectedRowQAWM.queueGroup = params.queueGroup;
    selectedRowQAWM.queueName = params.queueName;

    const updatedRecordIndexQAWM = this.state.rows.findIndex(
      (row) =>
        row.functionExisting + "" + row.queueGroup + "" + row.queueName ===
        selectedRowQAWM.functionExisting +
          "" +
          selectedRowQAWM.queueGroup +
          "" +
          selectedRowQAWM.queueName
    );
    const updatedRows = [
      ...this.state.rows.slice(0, updatedRecordIndexQAWM),
      selectedRowQAWM,
      ...this.state.rows.slice(updatedRecordIndexQAWM + 1),
    ];
    // let updatedRows = this.state.rows;
    // updatedRows[updatedRecordIndexQAWM] = selectedRowQAWM;

    this.setState({ rows: updatedRows });
  };

  onChangeControlWorkflowMaintenance = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      accessGrantQAWM:this.props.location.state.accessGrantQAWM,
      accessGrantWriteQAWM:this.props.location.state.accessGrantWriteQAWM,
      operatorIdQAWM:this.props.location.state.operatorIdQAWM,
      tokenQAWM:this.props.location.state.tokenQAWM,
    })
    this.onLoadDataQAWM();
  }
  onLoadDataQAWM = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/queuefunctions`,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.location.state.tokenQAWM,
            userId: this.props.location.state.operatorIdQAWM,
          },
        }
      )
      .then((response) => {
        let recQAWM = [];
        if (response.data.records !== null) {
          recQAWM = response.data.records;
        }
        this.setState({
          rows: recQAWM,
        });
      });
  };
  onHandleBackWorkflowMaintenance = () => {
    this.props.history.push("/queue-age-monitor");
  };

  onValidateWorkflowMaintenance1 = () => {
    let count1 = 0;
    let count2 = 0;
    let flag = false;
    this.state.rows.map((el) => {
      if (
        (el.functionNew === "" || el.functionNew === null) &&
        el.queueGroup === "ALL - ENABLE/DISABLE ALL QUEUES"
      ) {
        count1 = 1;
      } else if (el.functionNew === "" || el.functionNew === null) {
        count2 = 1;
      } else if (
        el.queueGroup !== "ALL - ENABLE/DISABLE ALL QUEUES" &&
        (el.functionNew !== "" || el.functionNew !== null)
      ) {
        flag = true;
      }
    });
    if (this.state.rows.length > 1) {
      if (count1 === 1 && count2 === 1) {
        this.setState({
          submitStatusWorkflowMaintenance1: "Error",
          submitMsgWorkflowMaintenance1:
            "Please select valid values (Y/N) for ALL or Individual Queues",
        });
        return false;
      }
      if (count1 === 0 && flag === true) {
        this.setState({
          submitStatusWorkflowMaintenance1: "Error",
          submitMsgWorkflowMaintenance1:
            "Please make selection for either ALL or Individual Queues not both",
        });
        return false;
      }
    } else {
      if (count1 === 1 || count2 === 1) {
        this.setState({
          submitStatusWorkflowMaintenance1: "Error",
          submitMsgWorkflowMaintenance1:
            "Please select valid values (Y/N) for ALL or Individual Queues",
        });
        return false;
      }
    }    
    if (this.state.enableDisablePwd === "") {
      this.setState({
        submitStatusWorkflowMaintenance1: "Error",
        submitMsgWorkflowMaintenance1:
          "Please enter a correct password for enable/disable ALL queues request",
      });
      return false;
    }
    if (this.state.enableDisablePwd.length > 10) {
      this.setState({
        submitStatusWorkflowMaintenance1: "Error",
        submitMsgWorkflowMaintenance1: "Please enter valid Passward",
      });
      return false;
    }
    return true;
  };

  onHandleUpdateWorkflowMaintenance = () => {
    if (!this.onValidateWorkflowMaintenance1()) {
      return;
    }
    let submittedObjQAWM = {};
    submittedObjQAWM.enableDisablePwd = this.state.enableDisablePwd;
    submittedObjQAWM.enableDisableAction = this.state.enableDisableAction;
    submittedObjQAWM.records = this.state.rows;
    //
    // submittedObj.functionExisting = this.state.functionExisting;
    // ;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/queuefunctions`,
        submittedObjQAWM,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.location.state.tokenQAWM,
            userId: this.props.location.state.operatorIdQAWM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (
            response.data.enableDisableMsg ===
            "Please enter a correct password for enable/disable queues request"
          ) {
            this.setState({
              submitStatusWorkflowMaintenance1: "Error",
              submitMsgWorkflowMaintenance1: response.data.enableDisableMsg,
            });
          } else {
            this.setState({
              submitStatusWorkflowMaintenance1: "OK",
              submitMsgWorkflowMaintenance1: response.data.enableDisableMsg,
            });
            this.onLoadDataQAWM();
          }
        }
      });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="QUEUE AGE MONITOR" />
        <div className="container-fluid" data-test="workflowMaintenance">
          <div className="card-body">
            <h4 className="card-title main-heading ">
              QUEUE AGE WORKFLOW MAINTENANCE
            </h4>
            <p className=" datetime-mar-left">
              <b>Date/Time : {this.state.curTime}</b>
            </p>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                ENABLE or DESABLE/FALLBACK QUEUE FUNCTIONS
              </div>
              <div className="card-body card-body-custom">
                <div className="ag-theme-balham">
                  <table className="table table-bordered">
                    <thead className="table-dark text-center">
                      <tr>
                        <th>FUNCTION(NEW)</th>
                        <th>FUNCTION(EXISTING)</th>
                        <th>QUE GROUP</th>
                        <th>QUE NAME</th>
                      </tr>
                    </thead>
                    <tbody className=" text-center">
                      {this.state.rows.map((el, i) => (
                        <tr key={i} data-test="tr">
                          <td>
                            <select
                              className="form-control  form-control-sm "
                              name="functionNew"
                              onChange={(e) => {
                                this.onSelectQueueAgeWorkflowMaintenance(el, e);
                              }}
                              value={el.functionNew}
                              data-test="select-dropdown"
                            >
                              <option value="">Select</option>
                              <option value="Y">Y</option>
                              <option value="N">N</option>
                            </select>
                          </td>
                          <td>{el.functionExisting}</td>
                          <td>{el.queueGroup}</td>
                          <td>{el.queueName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4" />
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label className="font-weight-bold">Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="enableDisablePwd"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={(e) => {
                              this.onChangeControlWorkflowMaintenance(e);
                            }}
                            data-test="text-enableDisablePwd"
                          />
                        </Form.Group>
                        <Card.Text>Y (UPPER-CASE)= ENABLE</Card.Text>
                        <Card.Text>
                          N (UPPER-CASE)= DISABLE/FALLBACK (BRING WORKFLOW QUEUE
                          TO UNISYS QUEUE)
                        </Card.Text>
                        <Card.Text>
                          NOTE : PLEASE DON'T REFRESH THIS PAGE USING F5 or
                          BROWSER REFRESH
                        </Card.Text>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-4" />
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={this.onHandleUpdateWorkflowMaintenance}
                            data-test="button-update"
                            disabled={!this.state.accessGrantWriteQAWM}
                          >
                            UPDATE
                          </button>
                        </div>
                        <div className="col-lg-4" />
                      </div>
                    </div>
                    <div className="col-lg-4" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4" />
                  <div className="col-lg-4">
                    {this.state.submitStatusWorkflowMaintenance1 === "OK" && (
                      <div className="alert alert-success alert-padding">
                        {this.state.submitMsgWorkflowMaintenance1}
                      </div>
                    )}
                    {this.state.submitStatusWorkflowMaintenance1 ===
                      "Error" && (
                      <div className="alert alert-danger alert-padding">
                        {this.state.submitMsgWorkflowMaintenance1}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mar-top5">
          <div className="col-lg text-center">
            <button
              type="button"
              className="btn btn-warning "
              onClick={this.onHandleBackWorkflowMaintenance}
              data-test="button-back"
            >
              GO TO QUEUE GROUPS
            </button>
          </div>
        </div>
      </>
    );
  }
}
export default QueueAgeWorkflowMaintenance;
