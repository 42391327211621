import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactDDCD } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditDDCD} from "react-icons/ai";
import { IconContext as IconContextDDCD} from "react-icons";
import { getHeadersShared } from "../../utility/utility";

class DelayedDeliverAndCorridorDetails extends Component {
  state = {
    id: "",
    senderCountryCode: "",
    receiverCountryCode: "",
    startDate: "",
    endDate: "",
    dm0: "",
    dm1: "",
    dm2: "",
    dm3: "",
    dm4: "",
    dm5: "",
    dm6: "",
    dm7: "",
    dm8: "",
    dm9: "",
    showDelayedDeliveryAndCorridorDetailsConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() =>
                this.props.location.state.accessGrantedCM &&
                this.handleEditDelayedDeliveryAndCorridorDetails(params)
              }
            >
              <IconContextDDCD.Provider
                value={{
                  color: this.props.location.state.accessGrantedCM
                    ? "#FDD017"
                    : "#cec9b4",
                  size: "20px",
                }}
              >
                <AiTwotoneEditDDCD />
              </IconContextDDCD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "91px",
      },
      { field: "senderCountryCode", headerName: "SND CC", width: "82px" },
      { field: "receiverCountryCode", headerName: "RCV CC	", width: "81px" },
      {
        field: "startDate",
        headerName: "SDATE",
        width: "101px",
        filter: false,
      },
      { field: "endDate", headerName: "EDATE", width: "99px", filter: false },
      { field: "dm0", headerName: "DM0", width: "79px" },
      { field: "dm1", headerName: "DM1", width: "79px" },
      { field: "dm2", headerName: "DM2", width: "78px" },
      { field: "dm3", headerName: "DM3", width: "82px" },
      { field: "dm4", headerName: "DM4", width: "77px" },
      { field: "dm5", headerName: "DM5", width: "83px" },
      { field: "dm6", headerName: "DM6", width: "84px" },
      { field: "dm7", headerName: "DM7", width: "76px" },
      { field: "dm8", headerName: "DM8", width: "85px" },
      { field: "dm9", headerName: "DM9", width: "75px" },
    ],
    rows: null,
    enableBtn: false,
    enableDate: false,
    gridApi: null,
    paginationValError: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusDelayedDeliveryAndCorridorDetails: null,
    submitMsgDelayedDeliveryAndCorridorDetails: "",
    paginationOptionDelayedDeliveryAndCorridorDetails: [25, 50, 100, 500],
  };

  onChangeControlDelayedDeliveryAndCorridorDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlDelayedDeliveryAndCorridorDetailsChar = (e) => {
    this.setState({ [e.target.name]: e.target.value.replace(/[^a-zA-Z]/, "") });
  };

  onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber = (e) => {
    this.setState({ [e.target.name]: e.target.value.replace(/[^\d]/, "") });
  };

  onChangeDateControlDelayedDeliveryAndCorridorDetails = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    this.setState({
      tokenCM: this.props.location.state.tokenCM,
      accessGrantedCM: this.props.location.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.operatorIdCM,
    });
  }
  onGridReadyDelayedDeliveryAndCorridorDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/delayedservice/${this.props.location.state.countryCode}`,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.tokenCM,
            userId: this.props.location.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        let recDDCD = [];
        if (response.data.records !== null) {
          recDDCD = response.data.records;
        }
        this.setState({
          rows: recDDCD,
          countryName: this.props.location.state.countryName,
        });
      });
  };
  handleEditDelayedDeliveryAndCorridorDetails = (params) => {
    let data = params.node.data;

    let d1 = new Date(data.startDate);
    let newStartDate = [
      d1.getFullYear(),
      ("0" + (d1.getMonth() + 1)).slice(-2),
      ("0" + d1.getDate()).slice(-2),
    ].join("-");

    let d2 = new Date(data.endDate);
    let newEndDate = [
      d2.getFullYear(),
      ("0" + (d2.getMonth() + 1)).slice(-2),
      ("0" + d2.getDate()).slice(-2),
    ].join("-");

    this.setState({
      id: data.id,
      senderCountryCode: data.senderCountryCode,
      receiverCountryCode: data.receiverCountryCode,
      startDate: newStartDate,
      endDate: newEndDate,
      dm0: data.dm0,
      dm1: data.dm1,
      dm2: data.dm2,
      dm3: data.dm3,
      dm4: data.dm4,
      dm5: data.dm5,
      dm6: data.dm6,
      dm7: data.dm7,
      dm8: data.dm8,
      dm9: data.dm9,
      enableBtn: true,
      enableDate:
        data.receiverCountryCode === "default" ||
        data.receiverCountryCode === "Default" ||
        data.receiverCountryCode === "DEFAULT"
          ? true
          : false,
    });
  };

  onPageSizeChangedDelayedDeliveryAndCorridorDetails = (e) => {
    this.setState({ paginationValError: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackDelayedDeliveryAndCorridorDetails = () => {
    const data = {
      countryName: this.props.location.state.countryName,
      countryCode: this.props.location.state.countryCode,
    };
    const allData = {
      tokenCM: this.props.location.state.tokenCM,
      accessGrantedCM: this.props.location.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.operatorIdCM,
    };
    this.props.history.push({
      pathname: "/country-delivery-service",
      state: { data: data, state: allData },
    });
  };

  onValidateDelayedDeliveryAndCorridorDetails1 = () => {
    let tDate = new Date();
    let newTDate = [
      tDate.getFullYear(),
      ("0" + (tDate.getMonth() + 1)).slice(-2),
      ("0" + tDate.getDate()).slice(-2),
    ].join("-");

    if (!this.state.enableDate) {
      if (newTDate > this.state.endDate) {
        this.setState({
          submitStatusDelayedDeliveryAndCorridorDetails: "Error",
          submitMsgDelayedDeliveryAndCorridorDetails:
            "End Date Cannot Be Less Than Todays Date",
        });
        return false;
      }
      if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
        this.setState({
          submitStatusDelayedDeliveryAndCorridorDetails: "Error",
          submitMsgDelayedDeliveryAndCorridorDetails:
            "Start Date Should Not Be Greater Than End Date",
        });
        return false;
      }
    }
    return true;
  };

  onValidateDelayedDeliveryAndCorridorDetails = () => {
    let tDate = new Date();
    let newTDate = [
      tDate.getFullYear(),
      ("0" + (tDate.getMonth() + 1)).slice(-2),
      ("0" + tDate.getDate()).slice(-2),
    ].join("-");

    if (this.state.receiverCountryCode === "") {
      this.setState({
        submitStatusDelayedDeliveryAndCorridorDetails: "Error",
        submitMsgDelayedDeliveryAndCorridorDetails:
          "Please Enter Valid Value For Destination",
      });
      return false;
    }
    if (this.state.startDate === "") {
      this.setState({
        submitStatusDelayedDeliveryAndCorridorDetails: "Error",
        submitMsgDelayedDeliveryAndCorridorDetails:
          "Please Enter Valid Value For Start Date",
      });
      return false;
    }
    if (this.state.endDate === "") {
      this.setState({
        submitStatusDelayedDeliveryAndCorridorDetails: "Error",
        submitMsgDelayedDeliveryAndCorridorDetails:
          "Please Enter Valid Value For End Date",
      });
      return false;
    }
    if (newTDate > this.state.startDate) {
      this.setState({
        submitStatusDelayedDeliveryAndCorridorDetails: "Error",
        submitMsgDelayedDeliveryAndCorridorDetails:
          "Start Date Cannot Be Less Than Todays Date",
      });
      return false;
    }
    if (newTDate > this.state.endDate) {
      this.setState({
        submitStatusDelayedDeliveryAndCorridorDetails: "Error",
        submitMsgDelayedDeliveryAndCorridorDetails:
          "End Date Cannot Be Less Than Todays Date",
      });
      return false;
    }
    if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
      this.setState({
        submitStatusDelayedDeliveryAndCorridorDetails: "Error",
        submitMsgDelayedDeliveryAndCorridorDetails:
          "Start Date Should Not Be Greater Than End Date",
      });
      return false;
    }
    return true;
  };

  convertDateToLegacyFormat = (date) => {
    let d1 = new Date(date);
    let newStartDate = [
      ("0" + (d1.getMonth() + 1)).slice(-2),
      ("0" + d1.getDate()).slice(-2),
      d1.getFullYear(),
    ].join("/");
    return newStartDate;
  };

  onHandleAddDelayedDeliveryAndCorridorDetails = () => {
    if (!this.onValidateDelayedDeliveryAndCorridorDetails()) {
      return;
    }

    let submittedObjAddDDCD = {};
    submittedObjAddDDCD.id = "";
    submittedObjAddDDCD.senderCountryCode =
      this.props.location.state.countryCode.toUpperCase();
    submittedObjAddDDCD.receiverCountryCode =
      this.state.receiverCountryCode.toUpperCase();
    submittedObjAddDDCD.startDate = this.convertDateToLegacyFormat(
      this.state.startDate
    );
    submittedObjAddDDCD.endDate = this.convertDateToLegacyFormat(
      this.state.endDate
    );
    submittedObjAddDDCD.dm0 = this.state.dm0;
    submittedObjAddDDCD.dm1 = this.state.dm1;
    submittedObjAddDDCD.dm2 = this.state.dm2;
    submittedObjAddDDCD.dm3 = this.state.dm3;
    submittedObjAddDDCD.dm4 = this.state.dm4;
    submittedObjAddDDCD.dm5 = this.state.dm5;
    submittedObjAddDDCD.dm6 = this.state.dm6;
    submittedObjAddDDCD.dm7 = this.state.dm7;
    submittedObjAddDDCD.dm8 = this.state.dm8;
    submittedObjAddDDCD.dm9 = this.state.dm9;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/delayedservice`,
        submittedObjAddDDCD,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.tokenCM,
            userId: this.props.location.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.responseString === "Record Added Successfully"
        ) {
          submittedObjAddDDCD.id = response.data.id;
          this.setState({
            submitStatusDelayedDeliveryAndCorridorDetails: "OK",
            submitMsgDelayedDeliveryAndCorridorDetails:
              response.data.responseString,
            rows: [submittedObjAddDDCD, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusDelayedDeliveryAndCorridorDetails: "Error",
            submitMsgDelayedDeliveryAndCorridorDetails:
              response.data.responseString,
          });
        }
        this.onHandleClearDelayedDeliveryAndCorridorDetails();
      });
  };

  onHandleUpdateDelayedDeliveryAndCorridorDetails = () => {
    if (!this.onValidateDelayedDeliveryAndCorridorDetails1()) {
      return;
    }
    let submittedObjUpdateDDCD = {};
    submittedObjUpdateDDCD.id = this.state.id;
    submittedObjUpdateDDCD.senderCountryCode =
      this.state.senderCountryCode.toUpperCase();
    submittedObjUpdateDDCD.receiverCountryCode =
      this.state.receiverCountryCode.toUpperCase();
    submittedObjUpdateDDCD.startDate = this.state.enableDate
      ? "000000"
      : this.convertDateToLegacyFormat(this.state.startDate);
    submittedObjUpdateDDCD.endDate = this.state.enableDate
      ? "000000"
      : this.convertDateToLegacyFormat(this.state.endDate);
    submittedObjUpdateDDCD.dm0 = this.state.dm0;
    submittedObjUpdateDDCD.dm1 = this.state.dm1;
    submittedObjUpdateDDCD.dm2 = this.state.dm2;
    submittedObjUpdateDDCD.dm3 = this.state.dm3;
    submittedObjUpdateDDCD.dm4 = this.state.dm4;
    submittedObjUpdateDDCD.dm5 = this.state.dm5;
    submittedObjUpdateDDCD.dm6 = this.state.dm6;
    submittedObjUpdateDDCD.dm7 = this.state.dm7;
    submittedObjUpdateDDCD.dm8 = this.state.dm8;
    submittedObjUpdateDDCD.dm9 = this.state.dm9;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/delayedservice`,
        submittedObjUpdateDDCD,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.tokenCM,
            userId: this.props.location.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.senderCountryCode + "" + row.receiverCountryCode ===
              submittedObjUpdateDDCD.senderCountryCode +
                "" +
                submittedObjUpdateDDCD.receiverCountryCode
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObjUpdateDDCD,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusDelayedDeliveryAndCorridorDetails: "OK",
            submitMsgDelayedDeliveryAndCorridorDetails:
              response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusDelayedDeliveryAndCorridorDetails: "Error",
            submitMsgDelayedDeliveryAndCorridorDetails:
              response.data.responseString,
          });
        }
        this.onHandleClearDelayedDeliveryAndCorridorDetails();
      });
  };

  onHandleClearDelayedDeliveryAndCorridorDetails = () => {
    this.setState({
      id: "",
      senderCountryCode: "",
      receiverCountryCode: "",
      startDate: "",
      endDate: "",
      dm0: "",
      dm1: "",
      dm2: "",
      dm3: "",
      dm4: "",
      dm5: "",
      dm6: "",
      dm7: "",
      dm8: "",
      dm9: "",
      enableBtn: false,
      enableDate: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusDelayedDeliveryAndCorridorDetails: null,
        }),
      5000
    );
  };

  getDelayedDeliveryAndCorridorDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showDelayedDeliveryAndCorridorDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Delete This Corridor Rule ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showDelayedDeliveryAndCorridorDetailsConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteDelayedDeliveryAndCorridorDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteDelayedDeliveryAndCorridorDetails = () => {
    let submittedObjDeleteDDCD = {};
    submittedObjDeleteDDCD.senderCountryCode = this.state.senderCountryCode;
    submittedObjDeleteDDCD.receiverCountryCode = this.state.receiverCountryCode;
    //submittedObj.receiverCountryCode = 'AAA';

    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/deletedelayedservice/${this.state.senderCountryCode}/${this.state.receiverCountryCode}`,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.tokenCM,
            userId: this.props.location.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return (
              row.senderCountryCode + "" + row.receiverCountryCode !==
              submittedObjDeleteDDCD.senderCountryCode +
                "" +
                submittedObjDeleteDDCD.receiverCountryCode
            );
          });
          this.setState({
            submitStatusDelayedDeliveryAndCorridorDetails: "OK",
            submitMsgDelayedDeliveryAndCorridorDetails:
              response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusDelayedDeliveryAndCorridorDetails: "Error",
            submitMsgDelayedDeliveryAndCorridorDetails:
              response.data.responseString,
          });
        }
        this.onHandleClearDelayedDeliveryAndCorridorDetails();
      });
    this.setState({ showDelayedDeliveryAndCorridorDetailsConfirm: false });
  };
  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card-body">
            <h4 className="card-title main-heading">
              DELAYED DELIVERY AND CORRIDOR DETAILS FOR :{" "}
              {this.props.location.state.countryName}
            </h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                DELIVERY AND CORRIDOR DETAILS FORM
              </div>
              <div className="card-body card-body-custom">
                <div className="form-group row">
                  <div className="col-lg">
                    <label
                      htmlFor="receiverCountryCode"
                      className="font-weight-bold"
                    >
                      Destination
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.receiverCountryCode}
                      className="form-control form-control-sm"
                      name="receiverCountryCode"
                      onChange={(e) => {
                        this.onChangeControlDelayedDeliveryAndCorridorDetailsChar(
                          e
                        );
                      }}
                      data-test="text-receiverCountryCode"
                      placeholder="Destination"
                      disabled={this.state.enableBtn}
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="startDate" className="font-weight-bold">
                      Start Date
                    </label>
                    <br />
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      name="startDate"
                      value={this.state.startDate}
                      disabled={this.state.enableDate}
                      onChange={
                        this
                          .onChangeDateControlDelayedDeliveryAndCorridorDetails
                      }
                      data-test="date-startDate"
                    ></input>
                  </div>
                  <div className="col-lg">
                    <label htmlFor="endDate" className="font-weight-bold">
                      End Date
                    </label>
                    <br />
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      name="endDate"
                      value={this.state.endDate}
                      onChange={
                        this
                          .onChangeDateControlDelayedDeliveryAndCorridorDetails
                      }
                      disabled={this.state.enableDate}
                      data-test="date-endDate"
                    ></input>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="dm0" className="font-weight-bold">
                      DM0
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm0}
                      className="form-control form-control-sm"
                      name="dm0"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm0"
                      placeholder="DMO"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm1" className="font-weight-bold">
                      DM1
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm1}
                      className="form-control form-control-sm"
                      name="dm1"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm1"
                      placeholder="DM1"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm2" className="font-weight-bold">
                      DM2
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm2}
                      className="form-control form-control-sm"
                      name="dm2"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm2"
                      placeholder="DM2"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm3" className="font-weight-bold">
                      DM3
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm3}
                      className="form-control form-control-sm"
                      name="dm3"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm3"
                      placeholder="DM3"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm4" className="font-weight-bold">
                      DM4
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm4}
                      className="form-control form-control-sm"
                      name="dm4"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm4"
                      placeholder="DM4"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm5" className="font-weight-bold">
                      DM5
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm5}
                      className="form-control form-control-sm"
                      name="dm5"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm5"
                      placeholder="DM5"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm6" className="font-weight-bold">
                      DM6
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm6}
                      className="form-control form-control-sm"
                      name="dm6"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm6"
                      placeholder="DM6"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm7" className="font-weight-bold">
                      DM7
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm7}
                      className="form-control form-control-sm"
                      name="dm7"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm7"
                      placeholder="DM7"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm8" className="font-weight-bold">
                      DM8
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm8}
                      className="form-control form-control-sm"
                      name="dm8"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm8"
                      placeholder="DM8"
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="dm9" className="font-weight-bold">
                      DM9
                    </label>
                    <input
                      type="text"
                      maxLength="5"
                      value={this.state.dm9}
                      className="form-control form-control-sm"
                      name="dm9"
                      onChange={(e) => {
                        this.onChangeDateControlDelayedDeliveryAndCorridorDetailsOnlyNumber(
                          e
                        );
                      }}
                      data-test="text-dm9"
                      placeholder="DM9"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3" />
                  <div className="col-lg-6 text-center mar-bottom5">
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={
                        this.onHandleBackDelayedDeliveryAndCorridorDetails
                      }
                      data-test="button-back"
                    >
                      CNTRY DETAIL
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={
                        this.onHandleAddDelayedDeliveryAndCorridorDetails
                      }
                      data-test="button-add"
                      disabled={
                        this.state.enableBtn ||
                        !this.props.location.state.accessGrantedCM
                      }
                    >
                      ADD
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={
                        this.onHandleUpdateDelayedDeliveryAndCorridorDetails
                      }
                      data-test="button-update"
                      disabled={!this.state.enableBtn}
                    >
                      UPDATE
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={
                        this.onHandleClearDelayedDeliveryAndCorridorDetails
                      }
                      disabled={!this.state.enableBtn}
                    >
                      CLEAR
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => {
                        this.setState({
                          showDelayedDeliveryAndCorridorDetailsConfirm: true,
                        });
                      }}
                      data-test="button-delete"
                      disabled={!this.state.enableBtn||this.state.enableDate }
                    >
                      DELETE
                    </button>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3" />
                  <div className="col-lg-6">
                    {this.state
                      .submitStatusDelayedDeliveryAndCorridorDetails ===
                      "OK" && (
                      <div className="alert alert-success alert-padding notification text-center">
                        {this.state.submitMsgDelayedDeliveryAndCorridorDetails}
                      </div>
                    )}
                    {this.state
                      .submitStatusDelayedDeliveryAndCorridorDetails ===
                      "Error" && (
                      <div className="alert alert-danger alert-padding notification text-center">
                        {this.state.submitMsgDelayedDeliveryAndCorridorDetails}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3" />
                </div>
              </div>
            </div>
            {this.getDelayedDeliveryAndCorridorDetailsModal()}
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                DELIVERY AND CORRIDOR DETAILS DATA
              </div>
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label
                    htmlFor="pagination-select-label"
                    className="font-weight-bold"
                  >
                    No of records per page:
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlDelayedDeliveryAndCorridorDetails(e);
                      this.onPageSizeChangedDelayedDeliveryAndCorridorDetails(
                        e
                      );
                    }}
                    value={this.state.paginationValError}
                    name="paginationDropdownError"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionDelayedDeliveryAndCorridorDetails.map(
                      (val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactDDCD
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={
                      this.onGridReadyDelayedDeliveryAndCorridorDetails
                    }
                    data-test="component-grid"
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateDDCD
                    }
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplateDDCD}
                  ></AgGridReactDDCD>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DelayedDeliverAndCorridorDetails;
