import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactSettlementRC } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { getHeadersGSPS } from "../../../utility/utility";
import { AiTwotoneEdit as AiTwotoneEditSRCB } from "react-icons/ai";
import { IconContext as IconContextSRCB } from "react-icons";
import { ExcelRenderer } from "react-excel-renderer";

export class SettlementReasonCodeBlock extends Component {
  constructor() {
    super();
    this.fileUploader = React.createRef();
    this.state = {
      selectedFile: null,
      error: false,
      success:false,
      message: "",
      id: "",
      reason_code: "",
      adjustment_type: "",
      refund_type: "",
      description: "",
      showSettelementReasonCodeBlockConfirm: false,
      columns: [
        {
          headerName: "Action",
          field: "value",
          cellRendererFramework: (params) => {
            return (
              <div
                className="cursor-pointer text-center"
                onClick={() =>this.state.accessFlagSettlementBlock && this.handleEditSettlementBlock(params)}
              >
                <IconContextSRCB.Provider
                  value={{ color: this.state.accessFlagSettlementBlock?"#FDD017":'#cec9b4', size: "20px" }}
                >
                  <AiTwotoneEditSRCB />
                </IconContextSRCB.Provider>
              </div>
            );
          },
          sortable: false,
          filter: false,
          floatingFilter: false,
          width: "80px",
        },
        {
          field: "reason_code",
          headerName: "Reason Code",
          //width: "230px",
        },
        {
          field: "adjustment_type",
          headerName: "Adjustment Type",
          // width: "230px",
        },
        {
          field: "refund_type",
          headerName: "Refund Type",
          //width: "230px",
        },
        {
          field: "description",
          headerName: "Description",
          width: "280px",
        },
      ],
      rows: null,
      enableBtn: false,
      gridApi: null,
      paginationValSettlement: "25",
      defaultColumnDef: {
        resizable: true,
        //flex: 1,
        sortable: true,
        filter: true,
        floatingFilter: true,
      },
      submitStatusSettlementBlock: null,
      submitMsgSettlementBlock: "",
      paginationOptionSettltmentBlock: [25, 50, 100, 500],
      overlayLoadingTemplateSettlementRC:
        '<span class="ag-overlay-loading-center">Loading...</span>',
      overlayNoRowsTemplateSettlementRC:
        '<span style="">No Records Found</span>',
    };
  }

  onChangeControlSettlementBlock = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlSettlementBlockCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onChangeControlSettlementBlockCharNumberAndSpecialChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9\s'/*_&()-]/, ""),
    });
  };

  onGridReadySettlementBlock = (params) => {
    let accessFlagSettlementBlock = this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) || this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READWRITE);
    this.setState({accessFlagSettlementBlock});
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/settlementrescode/getsettlementrescodedetails`,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        let recSettlementRC = [];
        if (response.data.records !== null) {
          recSettlementRC = response.data.records;
        }
        this.setState({
          rows: recSettlementRC,
        });
      });
  };

  handleEditSettlementBlock = (params) => {
    let data = params.node.data;
    this.setState({
      id: data.id,
      reason_code: data.reason_code,
      adjustment_type: data.adjustment_type,
      refund_type: data.refund_type,
      description: data.description,
      enableBtn: true,
    });
  };

  onPageSizeChangedSettlementBlock = (e) => {
    this.setState({ paginationValSettlement: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackSettlementBlock = () => {
    this.props.history.push("/");
  };

  onValidateSettlementBlock = () => {
    if (this.state.reason_code === "") {
      this.setState({
        submitStatusSettlementBlock: "Error",
        submitMsgSettlementBlock: "Please Enter The Value For Reason Code ",
      });
      return false;
    }
    if (this.state.adjustment_type === "") {
      this.setState({
        submitStatusSettlementBlock: "Error",
        submitMsgSettlementBlock: "Please Enter The Value For Adjustment type ",
      });
      return false;
    }
    return true;
  };

  onHandleAddSettlementBlock = () => {
    if (!this.onValidateSettlementBlock()) {
      return;
    }
    let submittedObj = {};
    submittedObj.reason_code = this.state.reason_code;
    submittedObj.adjustment_type = this.state.adjustment_type;
    submittedObj.refund_type = this.state.refund_type;
    submittedObj.description = this.state.description;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/settlementrescode/addsettlementrescodedata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusSettlementBlock: "OK",
            submitMsgSettlementBlock: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          this.onHandleClearSettlementBlock();
        } else {
          this.setState({
            submitStatusettlementSBlock: "Error",
            submitMsgSettlementBlock: response.data.responseString,
          });
          this.onHandleClearSettlementBlock();
        }
      });
  };
  onHandleUpdateSettlementBlock = () => {
    if (!this.onValidateSettlementBlock()) {
      return;
    }
    let submittedObj = {};
    submittedObj.id = this.state.reason_code + "" + this.state.adjustment_type;
    submittedObj.reason_code = this.state.reason_code;
    submittedObj.adjustment_type = this.state.adjustment_type;
    submittedObj.refund_type = this.state.refund_type;
    submittedObj.description = this.state.description;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/settlementrescode/updatesettlementrescodedata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.reason_code + "" + this.state.adjustment_type ===
              submittedObj.id
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusSettlementBlock: "OK",
            submitMsgSettlementBlock: response.data.responseString,
            rows: updatedRows,
          });
          this.onHandleClearSettlementBlock();
        } else {
          this.setState({
            submitStatusSettlementBlock: "Error",
            submitMsgSettlementBlock: response.data.responseString,
          });
          this.onHandleClearSettlementBlock();
        }
      });
  };
  onHandleClearSettlementBlock = () => {
    this.setState({
      id: "",
      reason_code: "",
      adjustment_type: "",
      refund_type: "",
      description: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusSettlementBlock: null,
        }),
      5000
    );
  };

  getSettlementReasonCodeBlockModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showSettelementReasonCodeBlockConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete The Reason Code {this.state.reason_code} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showSettelementReasonCodeBlockConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteSettlementBlock}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteSettlementBlock = () => {
    let submittedObj = {};
    submittedObj.reason_code = this.state.reason_code;
    submittedObj.adjustment_type = this.state.adjustment_type;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/settlementrescode/deletesettlementrescodedata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return (
              row.reason_code + "" + row.adjustment_type !==
              submittedObj.reason_code + "" + submittedObj.adjustment_type
            );
          });

          this.setState({
            submitStatusSettlementBlock: "OK",
            submitMsgSettlementBlock: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearSettlementBlock();
        } else {
          this.setState({
            submitStatusSettlementBlock: "Error",
            submitMsgSettlementBlock: response.data.responseString,
          });
          this.onHandleClearSettlementBlock();
        }
      });
    this.setState({ showSettelementReasonCodeBlockConfirm: false });
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ error: false, selectedFile: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    if (this.fileUploader.current) {
      this.fileUploader.current.value = null;
    }

    if (this.state.selectedFile != null) {
      let fileObj = this.state.selectedFile;
      var fileName = this.state.selectedFile.name;
      var fileExtension = fileName.split(".").pop();
      if (fileExtension === "xlsx" || fileExtension === "xls") {
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          const noOfRows = resp.rows.length;
          let totalRecords = [];
          if (noOfRows > 1) {
            for (let i = 1; i < noOfRows; i++) {
              let temporaryObj = {};
              temporaryObj.reason_code = resp.rows[i][0];
              temporaryObj.adjustment_type = resp.rows[i][1];
              temporaryObj.refund_type = resp.rows[i][2];
              temporaryObj.description = resp.rows[i][3];
              temporaryObj.status = 1;
              totalRecords.push(temporaryObj);
            }
          }
          let tempObj = {
            records: [],
          };
          tempObj.records = totalRecords;
          axios
            .post(
              `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/settlementrescode/uploadsettlementrescodedata`,
              tempObj,
              {headers: {
                ...getHeadersGSPS().headers,
                Authorization: this.props.jwtToken? this.props.jwtToken: "",
              }}
            )
            .then((uploadResponse) => {
              if (parseInt(uploadResponse.data.responseCode) === 200) {
                axios
                  .get(
                    `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/settlementrescode/getsettlementrescodedetails`,
                    {headers: {
                      ...getHeadersGSPS().headers,
                      Authorization: this.props.jwtToken? this.props.jwtToken: "",
                    }}
                  )
                  .then((response) => {
                    this.setState({
                      rows: response.data.records,
                    });
                  });
                this.setState({
                  success: !this.state.error,
                  error: true,
                  message: "File Uploaded Successfully",
                });
              } else {
                this.setState({
                  error: !this.state.error,
                  success: false,
                  message: "Data Error In Excel File. File Upload Unsuccessful",
                });
              }
            });
        });
      } else {
        this.setState({
          error: !this.state.error,
          success: false,
          message: "Please Upload An Excel File",
        });
      }
    } else {
      this.setState({
        error: !this.state.error,
        success: false,
        message: "Please Upload An Excel File",
      });
    }
    setTimeout(
      () =>
        this.setState({
          error: !this.state.error,
          success: false,
          message: "",
          selectedFile: null,
        }),
      5000
    );
    //this.state.fileRef.target.value = null;
    // Create an object of formData
    /*const formData = new FormData();

    if (this.state.selectedFile != null) {
      var fileName = this.state.selectedFile.name;
      var fileExtension = fileName.split(".").pop();
      if (fileExtension === "xlsx") {
        // Update the formData object
        formData.append(
          "file",
          this.state.selectedFile,
          this.state.selectedFile.name
        );

        axios
          .post(
            `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/settlementrescode/uploadsettlementrescodedata`,
            formData,
            getUploadHeaders()
          )
          .then((response) => {
            axios
              .get(
                `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/settlementrescode/getsettlementrescodedetails`,
                getHeadersGSPS()
              )
              .then((response) => {
                this.setState({
                  rows: response.data.records,
                });
              });
            this.setState({
              error: !this.state.error,
              message: "File Uploaded Successfully",
            });
          });
      } else {
        this.setState({
          error: !this.state.error,
          message: "Please upload an excel file",
        });
      }
    } else {
      this.setState({
        error: !this.state.error,
        message: "Please upload an excel file",
      });
    } */
  };
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div className="alert alert-success file-alert" role="alert">
          <p className='notification'>File Name: {this.state.selectedFile.name}</p>
          {/* <p>File Type: {this.state.selectedFile.type}</p> */}
          <p className='notification'>
            Last Modified:
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    }
  };

  render() {
    return (
      <div
        className="container-fluid padding-left0"
        data-test="component-settlement-reason-code"
      >
        <div className="card">
          <div className="card-body">
            <div className="card ">
              <div className="card-body">
                <h4 className="card-title main-heading ">
                  Settlement Reason Code
                </h4>
                <div className="row">
                  <div className="col-lg-4"></div>
                  <div className="col-lg-5">
                    <input
                      type="file"
                      className="btn btn-warning mar-right10 padding-top-bottom4"
                      onChange={this.onFileChange}
                      data-test="input-file"
                      accept=".xls,.xlsx"
                      ref={this.fileUploader}
                    />
                    <button
                      className="btn btn-warning"
                      onClick={this.onFileUpload}
                      data-test="button-upload"
                      disabled={!this.state.accessFlagSettlementBlock}
                    >
                      Upload
                    </button>
                  </div>
                  <div className="col-lg-3"></div>
                </div>

                {this.state.error ? (
                  this.state.success ? <div className="alert alert-success file-alert notification text-center" role="alert">
                   {this.state.message}
                  </div> :
                  <div className="alert alert-danger file-alert notification text-center" role="alert">
                    {this.state.message}
                  </div>

                ) : (
                  this.fileData()
                )}
              </div>
            </div>
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                Block Settlement Reason Code
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="reason_code" className="font-weight-bold">Reason Code</label>
                      <input
                        type="text"
                        maxLength="3"
                        size="3"
                        value={this.state.reason_code}
                        className="form-control form-control-sm"
                        name="reason_code"
                        onChange={(e) => {
                          this.onChangeControlSettlementBlockCharNumber(e);
                        }}
                        data-test="text-reason_code"
                        placeholder="Reason Code"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="adjustment_type" className="font-weight-bold">Adjustment Type</label>
                      <input
                        type="text"
                        maxLength="3"
                        size="3"
                        value={this.state.adjustment_type}
                        className="form-control form-control-sm"
                        name="adjustment_type"
                        onChange={(e) => {
                          this.onChangeControlSettlementBlockCharNumber(e);
                        }}
                        data-test="text-adjustment_type"
                        placeholder="Adjustment Type"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="refund_type" className="font-weight-bold">Refund Type</label>
                      <input
                        type="text"
                        maxLength="3"
                        size="3"
                        value={this.state.refund_type}
                        className="form-control form-control-sm"
                        name="refund_type"
                        onChange={(e) => {
                          this.onChangeControlSettlementBlockCharNumber(e);
                        }}
                        data-test="text-refund_type"
                        placeholder="Refund Type"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="description" className="font-weight-bold">Description</label>
                      <input
                        type="text"
                        maxLength="75"
                        size="75"
                        value={this.state.description}
                        className="form-control form-control-sm"
                        name="description"
                        onChange={(e) => {
                          this.onChangeControlSettlementBlockCharNumberAndSpecialChar(
                            e
                          );
                        }}
                        data-test="text-description"
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="form-group row margin-0">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackSettlementBlock}
                        data-test="button-back"
                      >
                        GSP SYS
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddSettlementBlock}
                        data-test="button-add"
                        disabled={this.state.enableBtn || !this.state.accessFlagSettlementBlock}
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateSettlementBlock}
                        disabled={!this.state.enableBtn}
                        data-test="button-update"
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearSettlementBlock}
                        disabled={!this.state.enableBtn}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({
                            showSettelementReasonCodeBlockConfirm: true,
                          });
                        }}
                        data-test="button-delete"
                        disabled={!this.state.enableBtn}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                        {this.state.submitStatusSettlementBlock === "OK" && (
                          <div className="alert alert-success alert-padding notification">
                            {this.state.submitMsgSettlementBlock}
                          </div>
                        )}
                        {this.state.submitStatusSettlementBlock === "Error" && (
                          <div className="alert alert-danger alert-padding notification">
                            {this.state.submitMsgSettlementBlock}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </form>
              </div>
            </div>
            {this.getSettlementReasonCodeBlockModal()}
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                Blocked Settlement Code
              </div>
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label" className="font-weight-bold">
                    No of records per page
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlSettlementBlock(e);
                      this.onPageSizeChangedSettlementBlock(e);
                    }}
                    value={this.state.paginationValSettlement}
                    name="paginationDropdownSettlement"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionSettltmentBlock.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactSettlementRC
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadySettlementBlock}
                    data-test="component-grid"
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateSettlementRC
                    }
                    overlayNoRowsTemplate={
                      this.state.overlayNoRowsTemplateSettlementRC
                    }
                  ></AgGridReactSettlementRC>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettlementReasonCodeBlock;
