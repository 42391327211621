import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import Header from "../../header/header";
import { getHeadersShared } from "../../utility/utility";

export default class CountryDeliveryServiceAddress extends Component {
  state = {
    serviceName: "",
    address: ["", "", "", ""],
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    data: {},
    submitStatusCountryDeliveryServiceAddress: null,
    submitMsgCountryDeliveryServiceAddress: "",
    showCDSA: false,
  };

  componentDidMount() {
    this.setState({
      tokenCM:this.props.location.state.state.statetokenCM,
      accessGrantedCM:this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    })
    this.onLoadData();
  }
  onLoadData = () => {
    const codeAdd = this.props.location.state.data.countryCode;
    const service = this.props.location.state.data.serviceName;

    if (this.props.location.state.mode === "update") {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/deliveryserviceadd/${codeAdd}/${service}`,
          {
            headers: {
              ...getHeadersShared().headers,              
              Authorization: this.props.location.state.state.tokenCM,
              userId: this.props.location.state.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          if (response.data !== "") {
            // var add = response.data.address;
            // var addArray = add.substring(0, add.length - 1).split(",");
            this.setState({
              data: response.data,
              address1: response.data.addressOne,
              address2: response.data.addressTwo,
              address3: response.data.addressThree,
              address4: response.data.addressFour,
            });
          }
        });
    }
  };
  getCDSAModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showCDSA}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                RECORD INSERTED SUCCESSFULLY
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() => {
                  this.setState({
                    showCDSA: false,
                  });

                  this.props.history.push({
                    pathname: "/country-delivery-service",
                    state: this.props.location.state,
                  });
                }}
                data-test="button-yes"
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  onChangeControlDeliveryServiceAddressOnlyChar = (e) => {
    const { value } = e.target;
    let val = value.toUpperCase();
    this.setState({
      [e.target.name]: val.replace(/[^a-zA-Z]/, ""),
    });
  };

  onChangeControlDeliveryServiceAddress = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };
  // onChangeControlDeliveryServiceAdd1 = (e, index) => {
  //   var { value } = e.target;
  //   var address = this.state.address;
  //   for (let i = address.length; i < 4; i++) {
  //     address.push("");
  //   }

  //   address[index] = value;

  //   this.setState({ address });
  // };

  onValidateDeliveryServiceAddress = () => {
    if (this.state.serviceName === "") {
      this.setState({
        submitStatusCountryDeliveryServiceAddress: "Error",
        submitMsgCountryDeliveryServiceAddress: " Please Enter Valid Value For Service Name ",
      });
      return false;
    }
    if (
      this.state.address1 === "" &&
      this.state.address2 === "" &&
      this.state.address3 === "" &&
      this.state.address4 === ""
    ) {
      this.setState({
        submitStatusCountryDeliveryServiceAddress: "Error",
        submitMsgCountryDeliveryServiceAddress:
          " Please Enter Atleast One Service Address",
      });
      return false;
    }
    return true;
  };

  onUpdateDeliveryServiceAddress = () => {
    if (
      this.props.location.state.mode !== "update" &&
      !this.onValidateDeliveryServiceAddress()
    ) {
      return;
    }
    const countryName = this.props.location.state.data.countryName;
    const countryCode = this.props.location.state.data.countryCode;
    let submittedObj = {};
    // var filtered = this.state.address.filter(function (el) {
    //   return el !== "";
    // });
    //let address = filtered.toString();
    submittedObj.countryName = countryName;
    //submittedObj.countryName = "XYZ";
    submittedObj.countryCode = countryCode;
    //submittedObj.countryCode = "ZYN";

    submittedObj.addressOne = this.state.address1;
    submittedObj.addressTwo = this.state.address2;
    submittedObj.addressThree = this.state.address3;
    submittedObj.addressFour = this.state.address4;
    if (this.props.location.state.mode === "update") {
      submittedObj.serviceName = this.props.location.state.data.serviceName;
      //submittedObj.serviceName = "XYZ";
      //submittedObj.address = address;
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/deliveryserviceadd`,
          submittedObj,
          {
            headers: {
              ...getHeadersShared().headers,              
              Authorization: this.props.location.state.state.tokenCM,
              userId: this.props.location.state.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({              
              submitStatusCountryDeliveryServiceAddress: "OK",
              submitMsgCountryDeliveryServiceAddress:
                response.data.responseString,
            });
          } else {
            this.setState({
              submitStatusCountryDeliveryServiceAddress: "Error",
              submitMsgCountryDeliveryServiceAddress:
                response.data.responseString,
            });
          }
        });
    } else {
      // let address = [];
      // address.push(this.state.address1);
      // address.push(this.state.address2);
      // address.push(this.state.address3);
      // address.push(this.state.address4);

      // submittedObj.address = address.toString();

      
      submittedObj.serviceName = this.state.serviceName;

      //submittedObj.serviceName = "LLL";
      submittedObj.operatorId = this.props.location.state.state.operatorIdCM;
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/deliveryserviceadd`,
          submittedObj,
          {
            headers: {
              ...getHeadersShared().headers,              
              Authorization: this.props.location.state.state.tokenCM,
              userId: this.props.location.state.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          if (response.data.responseCode === "200") {
            this.setState({
              showCDSA: true,
              submitStatusCountryDeliveryServiceAddress: "OK",
              submitMsgCountryDeliveryServiceAddress:
                response.data.responseString,
            });
          } else {
            this.setState({
              submitStatusCountryDeliveryServiceAddress: "Error",
              submitMsgCountryDeliveryServiceAddress:
                response.data.responseString,
            });
          }
        });
    }
  };

  onBackDeliveryServiceAddress = () => {
    const countryName = this.props.location.state.data.countryName;
    const code = this.props.location.state.data.countryCode;
    const data = {
      countryCode: code,
      countryName: countryName,
    };
    const allData={
      tokenCM:this.props.location.state.state.tokenCM,
      accessGrantedCM:this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    }
    this.props.history.push({
      pathname: "/country-delivery-service",
      state: { data: data, state:allData },
    });
  };
  render() {
    const length = this.state.address.length;
    let arr = this.state.address;
    for (let i = length; i < 4; i++) {
      arr.push("");
    }
    return (
      <>
      <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
      <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">
              COUNTRY DELIVERY SERVICE ADDRESS
            </h4>
            <div className="form-group row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="card ">
                  <div className="card-header bg-dark text-white card-header-custom">
                    DELIVERY SERVICE ADDRESS
                  </div>
                  <div className="card-body card-body-custom">
                    <form>
                      <div className="form-group row">
                        <div className="col-lg-3"><span className="font-weight-bold">Country Name:</span></div>
                        <div className="col-lg-9">
                          {this.props.location.state.data.countryName}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-3"> <span className="font-weight-bold">Service Name :</span></div>
                        {this.props.location.state.mode === "update" ? (
                          <div className="col-lg-9">
                            {this.props.location.state.data.serviceName}
                          </div>
                        ) : (
                          <div className="col-lg-9">
                            <input
                              type="text"
                              maxLength="4"
                              value={this.state.serviceName}
                              name="serviceName"
                              className="form-control form-control-sm"
                              onChange={(e) => {
                                this.onChangeControlDeliveryServiceAddressOnlyChar(
                                  e
                                );
                              }}
                              data-test="text-serviceName"
                              placeholder="Service Name"
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-3"><span className="font-weight-bold">Address:</span></div>
                        {/* {this.props.location.state.mode === "update" ? (
                          <div className="col-lg-9">
                            <input
                              type="text"
                              maxLength="40"
                              value={this.state.address[0]}
                              name="address"
                              onChange={(e) => {
                                this.onChangeControlDeliveryServiceAdd1(e, 0);
                              }}
                              className="form-control form-control-sm mar-bottom5"
                              data-test="text-address-update-01"
                              placeholder="Address"
                            />
                            <input
                              type="text"
                              maxLength="40"
                              value={this.state.address[1]}
                              name="address"
                              onChange={(e) => {
                                this.onChangeControlDeliveryServiceAdd1(e, 1);
                              }}
                              className="form-control form-control-sm mar-bottom5"
                              data-test="text-address-update-02"
                              placeholder="Address"
                            />
                            <input
                              type="text"
                              maxLength="40"
                              value={this.state.address[2]}
                              name="address"
                              onChange={(e) => {
                                this.onChangeControlDeliveryServiceAdd1(e, 2);
                              }}
                              className="form-control form-control-sm mar-bottom5"
                              data-test="text-address-update-03"
                              placeholder="Address"
                            />
                            <input
                              type="text"
                              maxLength="40"
                              value={this.state.address[3]}
                              name="address"
                              onChange={(e) => {
                                this.onChangeControlDeliveryServiceAdd1(e, 3);
                              }}
                              className="form-control form-control-sm mar-bottom5"
                              data-test="text-address-update-04"
                              placeholder="Address"
                            />
                          </div>
                        ) : ( */}
                        <div className="col-lg-9">
                          <input
                            type="text"
                            maxLength="40"
                            value={this.state.address1}
                            name="address1"
                            onChange={(e) => {
                              this.onChangeControlDeliveryServiceAddress(e);
                            }}
                            className="form-control form-control-sm mar-bottom5"
                            data-test="text-address-add-01"
                            placeholder="Address"
                          />
                          <input
                            type="text"
                            maxLength="40"
                            value={this.state.address2}
                            name="address2"
                            onChange={(e) => {
                              this.onChangeControlDeliveryServiceAddress(e);
                            }}
                            className="form-control form-control-sm mar-bottom5"
                            data-test="text-address-add-02"
                            placeholder="Address"
                          />
                          <input
                            type="text"
                            maxLength="40"
                            value={this.state.address3}
                            name="address3"
                            onChange={(e) => {
                              this.onChangeControlDeliveryServiceAddress(e);
                            }}
                            className="form-control form-control-sm mar-bottom5"
                            data-test="text-address-add-03"
                            placeholder="Address"
                          />
                          <input
                            type="text"
                            maxLength="40"
                            value={this.state.address4}
                            name="address4"
                            onChange={(e) => {
                              this.onChangeControlDeliveryServiceAddress(e);
                            }}
                            className="form-control form-control-sm mar-bottom5"
                            data-test="text-address-add-04"
                            placeholder="Address"
                          />
                        </div>
                        {/* )} */}
                      </div>
                      <div className="form-group row mar-top20">
                        <div className="col-lg-3" />
                        <div className="col-lg-6 text-center mar-bottom5">
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={(e) => {
                              this.onUpdateDeliveryServiceAddress(e);
                            }}
                            data-test="button-delivery-service-address-update"
                            disabled={!this.props.location.state.state.accessGrantedCM}
                          >
                            UPDATE
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={(e) => {
                              this.onBackDeliveryServiceAddress(e);
                            }}
                            data-test="button-delivery-service-address"
                          >
                            DELIVERY SERVICE
                          </button>
                        </div>
                        <div className="col-lg-3" />
                      </div>
                      <div className="form-group row mar-top20">
                        <div className="col-lg-3" />
                        <div className="col-lg-6">
                          {this.state
                            .submitStatusCountryDeliveryServiceAddress ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification text-center">
                              {
                                this.state
                                  .submitMsgCountryDeliveryServiceAddress
                              }
                            </div>
                          )}
                          {this.state
                            .submitStatusCountryDeliveryServiceAddress ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification text-center">
                              {
                                this.state
                                  .submitMsgCountryDeliveryServiceAddress
                              }
                            </div>
                          )}
                        </div>
                        <div className="col-lg-3" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {this.getCDSAModal()}
            </div>
          </div>
      </div>
      </>
    );
  }
}
