import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersCustomer } from "../../utility/utility";

class WebTmtAccountInformation extends Component {
  state = {
    records: [],
    singleData: { 
      expiryDate: "",
    },
    countWTAI: 0,
    data: {
      billPhone: "",
      customerNum: "",
    },
    trafficAllow: "N",
    accountNumber: "",
    accntCCNumber:'',
    expiryDate: "",
    accountType: "",
    billPhone: "",
    billingAddress: "",
    custCity: "",
    custState: "",
    custAddrPhone: "",
    custZip: "",
    custCountry: "",
    countryIso: "",
    activityDate: "",
    createDate: "",
    operatorId: "",
    comments: "",
    commentsHistory: "",
    commCreateDate: "",
    commUpdateDate: "",
    updateDate: "",
    commOperatorId: "",
    submitStatusWebTmtAcctInformation: null,
    submitMsgWebTmtAcctInformation: "",
    isAccountWebTmtLoading : false,
    isAccountUpdateLoading : false,
    isAccountCustInfoLoading : false,
    isAccountNextLoading : false,
    isAccountPrevLoading : false
  };

  onChangeWebTmtAccountInfo = (e) => {
    // this.setState({
    //   [e.target.name]: e.target.value,
    // });
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value,
        },
      };
    });
  };

  onChangeControlAccountPhoneNums = (e) => {
    const { name, value } = e.target;
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: value,
          },
        };
      });
    }
  };

  onChangeControlAlphanumericValues = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^A-Za-z0-9\s-.()]/, ""),
    });
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value.replace(/[^A-Za-z0-9\s-.()]/, ""),
        },
      };
    });
  };

  
  onChangeControlExpiryDate = (e) => {
    const { name, value } = e.target;
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: value,
            expiryMonth: name==="expiryMonth" && value ? value : this.state.singleData.expiryMonth,
            expiryYear: name==="expiryYear" && value ? value : this.state.singleData.expiryYear
          },
        };
      });
    }        
  };

  /*componentDidMount() {
    this.setState((prevState) => {
      return {
        // enableBtn: true,
        data: {
          ...prevState,
          BillPhone: this.props.location.state.billPhone, 
          CustomerNumber : this.props.location.state.customerNum
        },
      };
    })
    axios
        .get(`${baseURL}/v1/cus/dbview/webtmtdbmaintenance/accountinfo`, 
          {
            headers: { 
            ...getHeaders().headers,
            BillPhone:  this.props.location.state.billPhone,
            CustomerNumber: this.props.location.state.customerNum,
          }
        }
        )
        .then((response) => {
          if (response.data.records.length > 0) {
            this.setState({
              records: response.data.records,
              singleData: response.data.records[0],
            });
          }
          let tempEnableNext = this.state.records.length > 1 ? true : false;
          this.setState({
            enableNextWTAI: tempEnableNext,
          });
        });		
  }*/

  componentDidMount() {    
    this.setState((prevState) => {
      return {
        // enableBtn: true,
        data: {
          ...prevState,
          BillPhone: this.props.location.state.billPhone,  
          CustomerNumber: this.props.location.state.customerNum,
          state: this.props.location.state,
          records: this.props.location.state.records,
          singleData: this.props.location.state.singleData,
        },
      };
    });
    this.setState({
      state: this.props.location.state,
      tokenWebTmt:this.props.location.state.tokenWebTmt,
      webAdmin:this.props.location.state.webAdmin,
      accessGrantedWrite: this.props.location.state.accessGrantedWrite,
      isAddressDecryprtRequired: this.props.location.state.isAddressDecryprtRequired,
      isCreditCardDecryprtRequired: this.props.location.state.isCreditCardDecryprtRequired,
      records: this.props.location.state.records,
      singleData: this.props.location.state.singleData,
      BillPhone: this.props.location.state.billPhone,
      CustomerNumber: this.props.location.state.customerNum,
      operatorId: this.props.location.state.operatorId,
      
      //customerId: this.props.location.state.accountNumber,
      // accountNumber:  this.props.location.state.customerId,
      //customerNumber: this.props.location.state.customerNum,
    });
    //this.AssignExpiryMonthAndYear(this.props.location.state.singleData.expiryDate)
    let tempEnableNext =
      this.props.location.state.records.length > 1 ? true : false;
    this.setState({
      enableNextWTAI: tempEnableNext,
    });
    /*if (this.props.location.state.records.length > 0) {
      this.setState({
        records: this.props.location.state.records,
        singleData: this.props.location.state.records[0],
      });
    }*/
    this.getAccountInformation();
  }

  
  AssignExpiryMonthAndYear = (expiryDate) =>{
    if (expiryDate!=="" && expiryDate!==null && (expiryDate.length === 4 || expiryDate.length === 5)) {
      this.setState((prev)=>({
        singleData:{
          ...prev.singleData,
          expiryMonth: expiryDate && expiryDate.includes("/")? expiryDate.substring(0,2): (expiryDate!==null && expiryDate!=="")? expiryDate.substring(0,2) : "",
          expiryYear: expiryDate && expiryDate.includes("/")? ("20"+expiryDate.substring(3,5)): (expiryDate!==null && expiryDate!=="") ?("20"+expiryDate.substring(2,4)): "",
        }
    }));
		} else if (expiryDate!=="" && expiryDate!==null && (expiryDate.length === 6 || expiryDate.length === 7)) {
      this.setState(prev=>({
        singleData:{
          ...prev.singleData,
          expiryMonth: expiryDate && expiryDate.includes("/")? expiryDate.substring(0,2): (expiryDate!==null && expiryDate!=="") ? expiryDate.substring(0,2):"",
          expiryYear: expiryDate && expiryDate.includes("/")? (expiryDate.substring(3,7)): (expiryDate!==null && expiryDate!=="") ?(expiryDate.substring(2,6)): "",
        }
    }))
		} else if (expiryDate!=="" && expiryDate!==null && expiryDate.length>= 8) {
      this.setState(prev=>({
        singleData:{
          ...prev.singleData,
          expiryMonth: expiryDate && expiryDate.includes("/")? expiryDate.substring(5,7): (expiryDate!==null && expiryDate!=="") ? expiryDate.substring(4,6): "",
          expiryYear: expiryDate && expiryDate.includes("/")? (expiryDate.substring(0,4)): (expiryDate!==null && expiryDate!=="") ? (expiryDate.substring(0,4)):"",
        }
    }))
		} else {
      this.setState(prev=>({
        singleData:{
          ...prev.singleData,
          expiryMonth: "",
          expiryYear: "",
        }
    }))
		}
  }

  onHandleBackWebTmtSearch = () => {
    this.setState({
      isAccountWebTmtLoading:true
    })
    this.props.history.push({
      pathname: "/web-tmt-db-maintenance",
    });
    this.setState({
      isAccountWebTmtLoading:false
    })
  };

  onHandleBufferDataWTAI = (typeWTAI) => {
    if (typeWTAI === "prev") {
      this.setState({
        isAccountPrevLoading : true
      })
      this.setState(
        {
          countWTAI: this.state.countWTAI - 1,
        },
        () => {
         
          this.setState({
            enableNextWTAI: true,
            singleData: this.state.records[this.state.countWTAI],            
          });
          this.AssignExpiryMonthAndYear(this.state.records[this.state.countWTAI].expiryDate)
          
          if (this.state.countWTAI === 0) {
            this.setState({
              enablePrevWTAI: false,
              enableNextWTAI: true,
            });
          }
          this.setState({
            isAccountPrevLoading : false
          })
        }
      );
    } else {
      this.setState({
        isAccountNextLoading : true
      })
      if (this.state.countWTAI < this.state.records.length - 1) {
        this.setState(
          {
            countWTAI: this.state.countWTAI + 1,
          },
          () => {
           
            this.setState({
              enablePrevWTAI: true,
              singleData: this.state.records[this.state.countWTAI],
            });
            this.AssignExpiryMonthAndYear(this.state.records[this.state.countWTAI].expiryDate)   
                 
            if (this.state.countWTAI === this.state.records.length - 1) {
              this.setState({
                enablePrevWTAI: true,
                enableNextWTAI: false,
              });
            }             
            this.setState({
              isAccountNextLoading : false
            })   
          }
        );
      }
    }
  };
  onChangeWebTmtAcctInformationCheckBox = (e) => {
    const { name, value } = e.target;
    const flag = value !== "" ? value : "N";
    if (flag === "N") {
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: "Y",
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: "N",
          },
        };
      });
    }
  };

  onHandleUpdateAccountInfo = () => {
    if (!this.onValidateAccountInformation()) {
      return;
    }
    this.setState({
      isAccountUpdateLoading : true
    });
    let submittedObj = {};
    submittedObj.accountNumber = this.state.singleData.accountNumber;
    submittedObj.expiryDate = (this.state.singleData.expiryMonth ? this.state.singleData.expiryMonth : "") + (this.state.singleData.expiryYear? this.state.singleData.expiryYear.substring(2): "");
    submittedObj.accountType = this.state.singleData.accountType;
    submittedObj.billPhone = this.state.singleData.billPhone;
    submittedObj.billingAddress = this.state.singleData.billingAddress;
    submittedObj.custCity = this.state.singleData.custCity;
    submittedObj.custAddrPhone = this.state.singleData.custAddrPhone;
    submittedObj.custState = this.state.singleData.custState;
    submittedObj.custZip = this.state.singleData.custZip;
    submittedObj.custCountry = this.state.singleData.custCountry;
    submittedObj.countryIso = this.state.singleData.countryIso;
    submittedObj.trafficAllow = this.state.singleData.trafficAllow;
    submittedObj.activityDate = this.state.singleData.activityDate;
    submittedObj.createDate = this.state.singleData.createDate;
    submittedObj.updateDate = this.state.singleData.updateDate;
    //submittedObj.operatorId = this.state.singleData.operatorId;
    submittedObj.operatorId = this.state.operatorId;
    submittedObj.comments = this.state.singleData.comments;
    submittedObj.commentsHistory = this.state.singleData.commentsHistory;
    submittedObj.commCreateDate = this.state.singleData.commCreateDate;
    submittedObj.commUpdateDate = this.state.singleData.commUpdateDate;
    //submittedObj.commOperatorId = this.state.singleData.commOperatorId;
    submittedObj.commOperatorId = this.state.operatorId;
    submittedObj.comTblKey = this.state.singleData.comTblKey;
    submittedObj.comSearchKey = this.state.singleData.comSearchKey;

    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/accountinfo`,
        submittedObj,
        {
          headers: {
            ...getHeadersCustomer().headers,
            Authorization: this.state.tokenWebTmt ? this.state.tokenWebTmt : "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.responseCode !== "400") {
            this.getAccountInformation();
          }
          let tempRecord = this.state.records;

          tempRecord[this.state.countWTAI] = submittedObj;
          this.setState({
            submitStatusWebTmtAcctInformation: "OK",
            submitMsgWebTmtAcctInformation: response.data.responseString,
            records: tempRecord,
            isAccountUpdateLoading: false,
          });
        } else {
          this.setState({
            submitStatusWebTmtAcctInformation: "Error",
            submitMsgWebTmtAcctInformation: response.data.responseString,
            isAccountUpdateLoading: false,
          });
        }
        this.onHandleClearWebTmtAccountInfo();
      });
  };

  onHandleClearWebTmtAccountInfo = () => {
    setTimeout(
      () =>
        this.setState({
          submitStatusWebTmtAcctInformation: null,
          submitMsgWebTmtAcctInformation: "",
        }),
      5000
    );
  };

  getAccountInformation = () => {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/accountinfo`,
          {
            headers: {
              ...getHeadersCustomer().headers,
              //BillPhone: this.state.BillPhone,
              //CustomerNumber: this.state.CustomerNumber,
              BillPhone: this.props.location.state.billPhone
                ? this.props.location.state.billPhone
                : "",
              CustomerNumber: this.props.location.state.customerNumber
                ? this.props.location.state.customerNumber
                : "",
              Authorization: this.props.location.state.tokenWebTmt
                ? this.props.location.state.tokenWebTmt
                : "",
              userId:this.props.location.state.operatorId,
            },
          }
        )
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.records &&
            response.data.records.length > 0
          ) {
            this.setState({
              records: response.data.records,
              singleData: response.data.records[this.state.countWTAI],
            });
            this.AssignExpiryMonthAndYear(response.data.records[this.state.countWTAI].expiryDate)
            let tempEnableNext = this.state.records.length > 1 ? true : false;
            this.setState({
              enableNextWTAI: tempEnableNext,
            });
          } else {
            this.setState({
              submitStatusWebTmtAcctInformation: "Error",
              submitMsgWebTmtAcctInformation: response.data.errMsg,
            });
            return false;
          }
        });
       
  };

  onHandleBackCustomerInfo = () => {
    this.setState({
      isAccountCustInfoLoading : true
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customerinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            accountNumber: this.state.singleData.accountNumber
              ? this.state.singleData.accountNumber
              : "", //"2021-03-11 06:35:13.0"
            customerNumber: this.state.customerNumber
              ? this.state.customerNumber
              : "",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            userId:this.state.operatorId,
              //isAddressDecryprtRequired: this.state.isAddressDecryprtRequired,
          },
        }
      )
      .then((response) => {
        this.setState({
          isAccountCustInfoLoading: false
        })
        if (response.status===200 && response.data.records && response.data.records.length > 0) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
            customerAccountId:this.props.location.state.customerNumber,
            operatorId: this.state.operatorId            
          });
          if (this.state.singleData.responseType === 1) {
            this.props.history.push({
              pathname: "/web-tmt-customer-information",
              state: this.state,
            });
          }
        } else {
          this.setState({
            submitStatusWebTmtAcctInformation: "Error",
            submitMsgWebTmtAcctInformation: response.data.errMsg,
          });
          return false;
        }
      });
  };

  onValidateAccountInformation = () => {
    if ((this.state.singleData.expiryMonth === ''|| this.state.singleData.expiryMonth === undefined) ||
      (this.state.singleData.expiryMonth &&(
      Number(this.state.singleData.expiryMonth) > 12 ||
        Number(this.state.singleData.expiryMonth) === 0 ||
        this.state.singleData.expiryMonth === "00"))
    ) {
      this.setState({
        submitStatusWebTmtAcctInformation: "Error",
        submitMsgWebTmtAcctInformation: "Expiry Date :Expiry Month Is Invalid",
      });
      return false;
    }

    if ((this.state.singleData.expiryYear === ''|| this.state.singleData.expiryYear === undefined)||      
    (this.state.singleData.expiryYear &&(Number(this.state.singleData.expiryYear) < 2000))
    ) {
      this.setState({
        submitStatusWebTmtAcctInformation: "Error",
        submitMsgWebTmtAcctInformation: "Expiry Date :Expiry Year Is Invalid",
      });
      return false;
    }
    return true;
  };


  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WEB/TMT DATABASE MAINTENANCE - Account Information"
        />

        <div
          className="container-fluid"
          data-test="component-account-information"
        >
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                WEB/TMT DATABASE MAINTENANCE - ACCOUNT INFORMATION
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  Records {this.state.countWTAI + 1} of{" "}
                  {this.state.records ? this.state.records.length : ""}
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="accountNumber"
                          className="font-weight-bold"
                        >
                          Account Number
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.accntCCNumber}
                          className="form-control form-control-sm"
                          name="accountNumber"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-accountNumber"
                          placeholder="Account Number"
                          disabled
                        />
                      </div>
                      {/*<div className="col-lg">
                        <label htmlFor="expiryDate">Expiry Date</label>
                        <input
                          type="text"
                          maxlength="7"
                          value={(this.state.singleData.expiryDate && !this.state.singleData.expiryDate.includes("/")) ? this.state.singleData.expiryDate.substring(0,2)+"/"+this.state.singleData.expiryDate.substring(2,6): this.state.singleData.expiryDate}
                          className="form-control form-control-sm"
                          name="expiryDate"
                          onChange={(e) => {
                            this.onChangeControlExpiryDate(e);
                          }}
                          data-test="text-expiryDate"
                          placeholder="Expiry Date"
                          helperText="MM/YYYY"
                        />
                        <p>MM/YYYY</p>
                        </div>*/}
                      <div className="col-lg-1">
                        <label
                          htmlFor="expiryMonth"
                          className="font-weight-bold"
                        >
                          Expiry Month
                        </label>
                        <input
                          type="text"
                          maxlength="2"
                          //value={this.state.singleData.expiryMonth}
                          value={this.state.singleData.expiryMonth}
                          className="form-control form-control-sm"
                          name="expiryMonth"
                          onChange={(e) => {
                            this.onChangeControlExpiryDate(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlExpiryDate(e);
                          }}
                          data-test="text-expiryMonth"
                          placeholder="Month"
                          helperText="MM"
                        />
                        <p>MM</p>
                      </div>
                      <p>/</p>
                      <div className="col-lg-2">
                        <label
                          htmlFor="expiryYear"
                          className="font-weight-bold"
                        >
                          Expiry Year
                        </label>
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.singleData.expiryYear}
                          className="form-control form-control-sm"
                          name="expiryYear"
                          onChange={(e) => {
                            this.onChangeControlExpiryDate(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlExpiryDate(e);
                          }}
                          data-test="text-expiryYear"
                          placeholder="Year"
                          helperText="YYYY"
                        />
                        <p>YYYY</p>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="accountType"
                          className="font-weight-bold"
                        >
                          Account Type
                        </label>
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.singleData.accountType}
                          className="form-control form-control-sm"
                          name="accountType"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-accountType"
                          placeholder="Account Type"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="billPhone" className="font-weight-bold">
                          Bill Phone
                        </label>
                        <input
                          type="text"
                          maxlength="15"
                          size="10"
                          value={this.state.singleData.billPhone}
                          className="form-control form-control-sm"
                          name="billPhone"
                          onChange={(e) => {
                            this.onChangeControlAccountPhoneNums(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlAccountPhoneNums(e);
                          }}
                          data-test="text-billPhone"
                          placeholder="Bill Phone"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="billingAddress"
                          className="font-weight-bold"
                        >
                          Billing Address
                        </label>
                        <input
                          type="text"
                          maxlength="65"
                          size="60"
                          value={this.state.singleData.billingAddress}
                          className="form-control form-control-sm"
                          name="billingAddress"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-billingAddress"
                          placeholder="Billing Address"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="custCity" className="font-weight-bold">
                          City
                        </label>
                        <input
                          type="text"
                          maxlength="24"
                          size="20"
                          value={this.state.singleData.custCity}
                          className="form-control form-control-sm"
                          name="custCity"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-custCity"
                          placeholder="City"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="custAddrPhone"
                          className="font-weight-bold"
                        >
                          Phone
                        </label>
                        <input
                          type="text"
                          maxlength="16"
                          size="12"
                          value={this.state.singleData.custAddrPhone}
                          className="form-control form-control-sm"
                          name="custAddrPhone"
                          onChange={(e) => {
                            this.onChangeControlAccountPhoneNums(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlAccountPhoneNums(e);
                          }}
                          data-test="text-custAddrPhone"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="custState" className="font-weight-bold">
                          State
                        </label>
                        <input
                          type="text"
                          maxlength="2"
                          size="3"
                          value={this.state.singleData.custState}
                          className="form-control form-control-sm"
                          name="custState"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-custState"
                          placeholder="State"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="custZip" className="font-weight-bold">
                          Zip
                        </label>
                        <input
                          type="text"
                          maxlength="10"
                          size="10"
                          value={this.state.singleData.custZip}
                          className="form-control form-control-sm"
                          name="custZip"
                          onChange={(e) => {
                            this.onChangeControlAlphanumericValues(e);
                          }}
                          data-test="text-custZip"
                          placeholder="Zip"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="custCountry"
                          className="font-weight-bold"
                        >
                          Country Code
                        </label>
                        <input
                          type="text"
                          maxlength="3"
                          size="4"
                          value={this.state.singleData.custCountry}
                          className="form-control form-control-sm"
                          name="custCountry"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-custCountry"
                          placeholder="Country Code"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="countryIso"
                          className="font-weight-bold"
                        >
                          Currency ISO
                        </label>
                        <input
                          type="text"
                          maxlength="3"
                          size="5"
                          value={this.state.singleData.countryIso}
                          className="form-control form-control-sm"
                          name="countryIso"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-countryIso"
                          placeholder="Currency ISO"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={this.state.singleData.trafficAllow}
                          onChange={(e) => {
                            this.onChangeWebTmtAcctInformationCheckBox(e);
                          }}
                          checked={this.state.singleData.trafficAllow === "Y"}
                          id="trafficAllow"
                          name="trafficAllow"
                          data-test="text-trafficAllow"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Traffic Allow
                        </label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="activityDate"
                          className="font-weight-bold"
                        >
                          Activity Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.activityDate}
                          className="form-control form-control-sm"
                          name="activityDate"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-activityDate"
                          placeholder="Activity Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="createDate"
                          className="font-weight-bold"
                        >
                          Create Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.createDate}
                          className="form-control form-control-sm"
                          name="createDate"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-createDate"
                          placeholder="Create Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="updateDate"
                          className="font-weight-bold"
                        >
                          Update Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.updateDate}
                          className="form-control form-control-sm"
                          name="updateDate"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-updateDate"
                          placeholder="Update Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="operatorId"
                          className="font-weight-bold"
                        >
                          Operator ID
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.operatorId}
                          className="form-control form-control-sm"
                          name="operatorId"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-operatorId"
                          placeholder="Operator ID"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="comments" className="font-weight-bold">
                          Comments
                        </label>
                        <input
                          type="text"
                          maxlength="77"
                          size="90"
                          value={
                            this.state.singleData.comments === null
                              ? ""
                              : this.state.singleData.comments
                          }
                          className="form-control form-control-sm"
                          name="comments"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-comments"
                          placeholder="Comments"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="commentsHistory"
                          className="font-weight-bold"
                        >
                          Comments History
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.commentsHistory === null
                              ? ""
                              : this.state.singleData.commentsHistory
                          }
                          className="form-control form-control-sm"
                          name="commentsHistory"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-commentsHistory"
                          placeholder="Comments History"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="commCreateDate"
                          className="font-weight-bold"
                        >
                          Create Date
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.commCreateDate === null
                              ? ""
                              : this.state.singleData.commCreateDate
                          }
                          className="form-control form-control-sm"
                          name="commCreateDate"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-commCreateDate"
                          placeholder="Create Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="commUpdateDate"
                          className="font-weight-bold"
                        >
                          Update Date
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.commUpdateDate === null
                              ? ""
                              : this.state.singleData.commUpdateDate
                          }
                          className="form-control form-control-sm"
                          name="commUpdateDate"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-commUpdateDate"
                          placeholder="Update Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="commOperatorId"
                          className="font-weight-bold"
                        >
                          Operator ID
                        </label>
                        <input
                          type="text"
                          value={
                            this.state.singleData.commOperatorId === null
                              ? ""
                              : this.state.singleData.commOperatorId
                          }
                          className="form-control form-control-sm"
                          name="commOperatorId"
                          onChange={(e) => {
                            this.onChangeWebTmtAccountInfo(e);
                          }}
                          data-test="text-commOperatorId"
                          placeholder="Operator ID"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        {this.state.isAccountWebTmtLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleBackWebTmtSearch}
                            data-test="button-webTmtMaint"
                            disabled={
                              this.state.isAccountWebTmtLoading === true ||
                              this.state.isAccountUpdateLoading === true ||
                              this.state.isAccountCustInfoLoading === true ||
                              this.state.isAccountNextLoading === true ||
                              this.state.isAccountPrevLoading === true
                            }
                          >
                            WEBTMT MAINT
                          </button>
                        )}
                        {this.state.isAccountUpdateLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleUpdateAccountInfo}
                            data-test="button-update"
                            disabled={
                              !this.state.accessGrantedWrite ||
                              this.state.isAccountWebTmtLoading === true ||
                              this.state.isAccountUpdateLoading === true ||
                              this.state.isAccountCustInfoLoading === true ||
                              this.state.isAccountNextLoading === true ||
                              this.state.isAccountPrevLoading === true
                            }
                          >
                            UPDATE
                          </button>
                        )}
                        {this.state.isAccountPrevLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mr-2"
                            onClick={() => this.onHandleBufferDataWTAI("prev")}
                            data-test="WEBTMTAcctInfo-button-prev"
                            disabled={
                              !this.state.enablePrevWTAI ||
                              this.state.isAccountWebTmtLoading === true ||
                              this.state.isAccountUpdateLoading === true ||
                              this.state.isAccountCustInfoLoading === true ||
                              this.state.isAccountNextLoading === true ||
                              this.state.isAccountPrevLoading === true
                            }
                          >
                            Previous
                          </button>
                        )}
                        {this.state.isAccountNextLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mr-2"
                            onClick={() => this.onHandleBufferDataWTAI("next")}
                            data-test="WEBTMTAcctInfo-button-tcn"
                            disabled={
                              !this.state.enableNextWTAI ||
                              this.state.isAccountWebTmtLoading === true ||
                              this.state.isAccountUpdateLoading === true ||
                              this.state.isAccountCustInfoLoading === true ||
                              this.state.isAccountNextLoading === true ||
                              this.state.isAccountPrevLoading === true
                            }
                          >
                            Next
                          </button>
                        )}
                        {this.state.isAccountCustInfoLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleBackCustomerInfo}
                            data-test="WebTmtAcctInfo-CustomerInfo"
                            disabled={
                              this.state.isAccountWebTmtLoading === true ||
                              this.state.isAccountUpdateLoading === true ||
                              this.state.isAccountCustInfoLoading === true ||
                              this.state.isAccountNextLoading === true ||
                              this.state.isAccountPrevLoading === true
                            }
                          >
                            CUSTOMER INFO
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="form-froup row">
                      <div className="col-lg-4" />
                      <div className="col-lg-4">
                        {this.state.submitStatusWebTmtAcctInformation ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgWebTmtAcctInformation}
                          </div>
                        )}
                        {this.state.submitStatusWebTmtAcctInformation ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgWebTmtAcctInformation}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebTmtAccountInformation;
