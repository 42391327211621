import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactCRTM } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditCRTM } from "react-icons/ai";
import { IconContext as IconContextCRTM } from "react-icons";
import { getHeadersShared } from "../../utility/utility";

class CorridorRulesTableMaintenance extends Component {
  state = {
    id: "",
    orginCountryCode: "",
    destCountryCode: "",
    fix: "",
    mtcnReq: "N",
    hardFix: "N",
    operator: "",
    term: "",
    site: "",
    dateTime: "",
    showCorridorRulesTableMaintenanceConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() =>
                this.props.location.state.state.accessGrantedCM &&
                this.handleEditCorridorRulesTableMaintenance(params)
              }
            >
              <IconContextCRTM.Provider
                value={{
                  color: this.props.location.state.state.accessGrantedCM
                    ? "#FDD017"
                    : "#cec9b4",
                  size: "20px",
                }}
              >
                <AiTwotoneEditCRTM />
              </IconContextCRTM.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "120px",
      },
      { field: "orginCountryCode", headerName: "ORIG CC", width: "141px" },
      { field: "destCountryCode", headerName: "DEST CC", width: "121px" },
      { field: "fix", headerName: "FIX", width: "122px" },
      { field: "mtcnReq", headerName: "MTCN REQ", width: "118px" },
      { field: "hardFix", headerName: "HARD FIX", width: "119px" },
      { field: "operator", headerName: "OPER", width: "123px" },
      { field: "term", headerName: "TERM", width: "117px" },
      { field: "dateTime", headerName: "DATE TIME", width: "142px" },
    ],
    rows: [],
    enableBtn: false,
    gridApi: null,
    paginationValError: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusCorridorRulesTableMaintenance: null,
    submitMsgCorridorRulesTableMaintenance: "",
    paginationOptionCorridorRulesTableMaintenance: [25, 50, 100, 500],
    overlayLoadingTemplateCRTM:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateCRTM: '<span style="">No Records Found</span>',
  };

  onChangeControlCorridorRulesTableMaintenance = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlCorridorRulesTableMaintenanceOnlyChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z]/, ""),
    });
  };
  componentDidMount() {
    this.setState({
      tokenCM: this.props.location.state.state.tokenCM,
      accessGrantedCM: this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    });
  }
  onGridReadyCorridorRulesTableMaintenance = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    this.getCorridorRulesTableMaint();
  };

  getCorridorRulesTableMaint = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/corridorrules/${this.props.location.state.state.countryCode}`,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        let recCRTM = [];
        if (response.data.records !== null) {
          recCRTM = response.data.records;
        }
        this.setState({
          rows: recCRTM,
          countryName: this.props.location.state.state.countryName,
          orginCountryCode: this.props.location.state.state.countryCode,
        });
      });
  };
  handleEditCorridorRulesTableMaintenance = (params) => {
    let data = params.node.data;
    this.setState({
      id: data.id,
      orginCountryCode: data.orginCountryCode,
      destCountryCode: data.destCountryCode,
      fix: data.fix,
      mtcnReq: data.mtcnReq,
      hardFix: data.hardFix,
      operator: data.operator,
      term: data.term,
      site: data.site,
      dateTime: data.dateTime,
      enableBtn: true,
    });
  };

  onPageSizeChangedCorridorRulesTableMaintenance = (e) => {
    this.setState({ paginationValError: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackCorridorRulesTableMaintenance = () => {
    const data = {
      countryName: this.props.location.state.state.countryName,
      countryCode: this.props.location.state.state.countryCode,
    };
    const allData = {
      tokenCM: this.props.location.state.state.tokenCM,
      accessGrantedCM: this.props.location.state.state.accessGrantedCM,
      operatorIdCM:this.props.location.state.state.operatorIdCM,
    };
    this.props.history.push({
      pathname: "/country-details",
      state: { mode: null, data: data, state: allData },
    });
  };

  onValidateCorridorRulesTableMaintenance = () => {
    if (this.state.destCountryCode === "") {
      this.setState({
        submitStatusCorridorRulesTableMaintenance: "Error",
        submitMsgCorridorRulesTableMaintenance:
          "Please enter Valid Value Destination Country Code",
      });
      return false;
    }
    return true;
  };

  onHandleAddCorridorRulesTableMaintenance = () => {
    if (!this.onValidateCorridorRulesTableMaintenance()) {
      return;
    }

    let submittedObj = {};
    submittedObj.orginCountryCode = this.props.location.state.state.countryCode.toUpperCase();
    submittedObj.destCountryCode = this.state.destCountryCode.toUpperCase();
    submittedObj.paymentPrincipal = this.state.fix;
    submittedObj.mtcnReq = this.state.mtcnReq;
    submittedObj.hardFix = this.state.hardFix;
    // submittedObj.site = this.state.site;
    submittedObj.operator = this.props.location.state.state.operatorIdCM;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/corridorrules`,
        submittedObj,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.id = response.data.id;
          this.setState({
            submitStatusCorridorRulesTableMaintenance: "OK",
            submitMsgCorridorRulesTableMaintenance:
              response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusCorridorRulesTableMaintenance: "Error",
            submitMsgCorridorRulesTableMaintenance:
              response.data.responseString,
          });
        }
        this.getCorridorRulesTableMaint();
        this.onHandleClearCorridorRulesTableMaintenance();
      });
  };

  onHandleUpdateCorridorRulesTableMaintenance = () => {
    if (!this.onValidateCorridorRulesTableMaintenance()) {
      return;
    }
    let submittedObj = {};
    submittedObj.orginCountryCode = this.state.orginCountryCode.toUpperCase();
    submittedObj.destCountryCode = this.state.destCountryCode.toUpperCase();
    submittedObj.mtcnReq = this.state.mtcnReq;
    submittedObj.paymentPrincipal = this.state.fix;
    submittedObj.hardFix = this.state.hardFix;
    // submittedObj.site = this.state.site;
    submittedObj.operator = this.props.location.state.state.operatorIdCM;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/corridorrules`,
        submittedObj,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.orginCountryCode + "" + row.destCountryCode ===
              submittedObj.orginCountryCode + "" + submittedObj.destCountryCode
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusCorridorRulesTableMaintenance: "OK",
            submitMsgCorridorRulesTableMaintenance:
              response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusCorridorRulesTableMaintenance: "Error",
            submitMsgCorridorRulesTableMaintenance:
              response.data.responseString,
          });
        }
        this.getCorridorRulesTableMaint();
        this.onHandleClearCorridorRulesTableMaintenance();
      });
  };

  onHandleClearCorridorRulesTableMaintenance = () => {
    this.setState({
      id: "",
      orginCountryCode: "",
      destCountryCode: "",
      fix: "",
      mtcnReq: "N",
      hardFix: "N",
      operator: "",
      term: "",
      site: "",
      dateTime: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusCorridorRulesTableMaintenance: null,
        }),
      5000
    );
  };

  getCorridorRulesTableMaintenanceModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showCorridorRulesTableMaintenanceConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Delete This Corridor Rule ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showCorridorRulesTableMaintenanceConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteCorridorRulesTableMaintenance}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteCorridorRulesTableMaintenance = () => {
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/corridorrules/${this.state.orginCountryCode}/${this.state.destCountryCode}`,
        {
          headers: {
            ...getHeadersShared().headers,
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return (
              row.orginCountryCode + "" + row.destCountryCode !==
              this.state.orginCountryCode + "" + this.state.destCountryCode
            );
          });
          this.setState({
            submitStatusCorridorRulesTableMaintenance: "OK",
            submitMsgCorridorRulesTableMaintenance:
              response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusCorridorRulesTableMaintenance: "Error",
            submitMsgCorridorRulesTableMaintenance:
              response.data.responseString,
          });
        }
        this.onHandleClearCorridorRulesTableMaintenance();
      });
    this.setState({ showCorridorRulesTableMaintenanceConfirm: false });
  };
  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid" data-test="component-network-list">
            <div className="card-body">
              <h4 className="card-title main-heading">
                CORRIDOR RULES TABLE MAINTENANCE FOR : {this.state.countryName}
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  CORRIDOR RULES DETAILS FORM
                </div>
                <div className="card-body card-body-custom">
                  <div className="form-group row">
                    <div className="col-lg">
                      <label
                        htmlFor="destCountryCode"
                        className="font-weight-bold"
                      >
                        Destination
                      </label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.destCountryCode}
                        className="form-control form-control-sm"
                        name="destCountryCode"
                        onChange={(e) => {
                          this.onChangeControlCorridorRulesTableMaintenanceOnlyChar(
                            e
                          );
                        }}
                        data-test="text-destCountryCode"
                        placeholder="Destination"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="fix" className="font-weight-bold">
                        {" "}
                        Payment Principal{" "}
                      </label>
                      <div className="row">
                        <div className="mar-right10" />
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="fix"
                            value="F"
                            data-test="text-fix-F"
                            checked={this.state.fix === "F"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="F">
                            ROS
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="fix"
                            value="H"
                            data-test="text-fix-H"
                            checked={this.state.fix === "H"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="H">
                            FOS
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="fix"
                            value="B"
                            data-test="text-fix-B"
                            checked={this.state.fix === "B"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="B">
                            Best Buy
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="fix"
                            value="X"
                            data-test="text-fix-X"
                            checked={this.state.fix === "X"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="X">
                            X
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="fix"
                            value=""
                            data-test="text-fix"
                            checked={this.state.fix === ""}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="">
                            NA
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <label htmlFor="mtcnReq" className="font-weight-bold">
                        MTCN Required
                      </label>
                      <div className="row">
                        <div className="mar-right10" />
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="mtcnReq"
                            value="Y"
                            data-test="text-mtcnReq"
                            checked={this.state.mtcnReq === "Y"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="Y">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="mtcnReq"
                            value="N"
                            data-test="text-mtcnReq-N"
                            checked={this.state.mtcnReq === "N"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="N">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <label htmlFor="hardFix" className="font-weight-bold">
                        Block Hard-Fix POS REQ
                      </label>
                      <div className="row">
                        <div className="mar-right10" />
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="hardFix"
                            value="Y"
                            data-test="text-hardFix"
                            checked={this.state.hardFix === "Y"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="Y">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="hardFix"
                            value="N"
                            data-test="text-hardFix-N"
                            checked={this.state.hardFix === "N"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="N">
                            No
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="hardFix"
                            value="E"
                            data-test="text-hardFix-E"
                            checked={this.state.hardFix === "E"}
                            onChange={(e) => {
                              this.onChangeControlCorridorRulesTableMaintenance(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="E">
                            Exception
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackCorridorRulesTableMaintenance}
                        data-test="button-back"
                      >
                        CNTRY DETAIL
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddCorridorRulesTableMaintenance}
                        data-test="button-add"
                        disabled={
                          this.state.enableBtn ||
                          !this.props.location.state.state.accessGrantedCM
                        }
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={
                          this.onHandleUpdateCorridorRulesTableMaintenance
                        }
                        disabled={!this.state.enableBtn}
                        data-test="button-update"
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={
                          this.onHandleClearCorridorRulesTableMaintenance
                        }
                        disabled={!this.state.enableBtn}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({
                            showCorridorRulesTableMaintenanceConfirm: true,
                          });
                        }}
                        data-test="button-delete"
                        disabled={!this.state.enableBtn}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4" />
                    <div className="col-lg-4">
                      {this.state.submitStatusCorridorRulesTableMaintenance ===
                        "OK" && (
                        <div className="alert alert-success alert-padding notification text-center">
                          {this.state.submitMsgCorridorRulesTableMaintenance}
                        </div>
                      )}
                      {this.state.submitStatusCorridorRulesTableMaintenance ===
                        "Error" && (
                        <div className="alert alert-danger alert-padding notification text-center">
                          {this.state.submitMsgCorridorRulesTableMaintenance}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {this.getCorridorRulesTableMaintenanceModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  CORRIDOR RULES DETAILS DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label
                      htmlFor="pagination-select-label"
                      className="font-weight-bold"
                    >
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlCorridorRulesTableMaintenance(e);
                        this.onPageSizeChangedCorridorRulesTableMaintenance(e);
                      }}
                      value={this.state.paginationValError}
                      name="paginationDropdownError"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionCorridorRulesTableMaintenance.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactCRTM
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={
                        this.onGridReadyCorridorRulesTableMaintenance
                      }
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateCRTM
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateCRTM
                      }
                    ></AgGridReactCRTM>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
}

export default CorridorRulesTableMaintenance;
