import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactSRD } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditSRD } from "react-icons/ai";
import { IconContext as IconContextSRD } from "react-icons";
import { getHeadersProduct } from "../../utility/utility";

class SaturnResponseDetails extends Component {
  state = {
    responseId: "",
    disposition: "",
    toClient: "",
    toComment: "",
    showSaturnResponseDetailsConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={
                () => {
                    if(!this.props.location.state.accessGrantedWriteTMD2) { 
                    this.setState({
                      submitStatusSaturnResponseDetails: 'Error',
                      submitMsgSaturnResponseDetails: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                    });
                  }else{
                    this.handleEditSaturnResponseDetails(params)
                  }  
              }
            }
            >
              <IconContextSRD.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditSRD />
              </IconContextSRD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      { field: "responseId", headerName: "RESPONSE ID", width: 250 },
      { field: "disposition", headerName: "DISPOSITION", width: 250 },
      { field: "toClient", headerName: "TO CLIENT", width: 300 },
      { field: "toComment", headerName: "TO COMMENT", width: 350 },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValGsp: "25",
    defaultColumnDef: { resizable: true, sortable: true, filter: true, floatingFilter: true },
    submitStatusSaturnResponseDetails: null,
    submitMsgSaturnResponseDetails: "",
    paginationOptionSaturnResponseDetails: [25, 50, 100, 500],
    editable: true,
    overlayLoadingTemplateSRD:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateSRD: '<span style="">No Records Found</span>',
  };

  onChangeControlSaturnResponseDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlSaturnResponseDetailsNumber  = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^\d]/,''),
    });
  };

  onGridReadySaturnResponseDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/tmonfilemaintenance/saturn`, {
        headers: {
          ...getHeadersProduct().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        this.setState({
          rows: response.data.records,
        });
      });
  };

  handleEditSaturnResponseDetails = (params) => {
    let data = params.node.data;
    this.setState({
      saturnId: data.responseId,
      responseId: data.responseId,
      disposition: data.disposition,
      toClient: data.toClient,
      toComment: data.toComment,
      enableBtn: true,
      editable: false
    });
  };

  onPageSizeChangedSaturnResponseDetails = (e) => {
    this.setState({ paginationValSaturnResponseDetails: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackSaturnResponseDetails = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  onValidateSaturnResponseDetails = () => {
    if (this.state.responseId === "") {
      this.setState({
        submitStatusSaturnResponseDetails: "Error",
        submitMsgSaturnResponseDetails: "Please Enter The Value For Response Id",
      });
      return false;
    } else if (this.state.disposition === "") {
      this.setState({
        submitStatusSaturnResponseDetails: "Error",
        submitMsgSaturnResponseDetails: "Please Enter The Value For Disposition",
      });
      return false;
    } else if (this.state.toClient === "") {
      this.setState({
        submitStatusSaturnResponseDetails: "Error",
        submitMsgSaturnResponseDetails: "Please Enter The Value For To Client",
      });
      return false;
    } else if (this.state.toComment === "") {
      this.setState({
        submitStatusSaturnResponseDetails: "Error",
        submitMsgSaturnResponseDetails: "Please Enter The Value For To Comment",
      });
      return false;
    }
    return true;
  };

  onHandleAddSaturnResponseDetails = () => {
    if (!this.onValidateSaturnResponseDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.saturnId = "";
    submittedObj.responseId = this.state.responseId;
    submittedObj.disposition = this.state.disposition;
    submittedObj.toClient = this.state.toClient;
    submittedObj.toComment = this.state.toComment;

    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/tmonfilemaintenance/saturn`, submittedObj,  {
        headers: {
          ...getHeadersProduct().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseString ==='Record Added Successfully') {
          submittedObj.saturnId = this.state.responseId;
          this.setState({
            submitStatusSaturnResponseDetails: "OK",
            submitMsgSaturnResponseDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusSaturnResponseDetails: "Error",
            submitMsgSaturnResponseDetails: response.data.responseString,
          });
        }
        this.onHandleClearSaturnResponseDetails();
      });
  };

  onHandleUpdateSaturnResponseDetails = () => {
    if (!this.onValidateSaturnResponseDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.saturnId = this.state.saturnId;
    submittedObj.responseId = this.state.responseId;
    submittedObj.disposition = this.state.disposition;
    submittedObj.toClient = this.state.toClient;
    submittedObj.toComment = this.state.toComment;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/tmonfilemaintenance/saturn`, submittedObj,  {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.token,
            "userId":this.props.location.state.operatorIdTMD
          },
       })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.responseId === submittedObj.saturnId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusSaturnResponseDetails: "OK",
            submitMsgSaturnResponseDetails: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusSaturnResponseDetails: "Error",
            submitMsgSaturnResponseDetails: response.data.responseString,
          });
        }
        this.onHandleClearSaturnResponseDetails();
      });
  };

  onHandleClearSaturnResponseDetails = () => {
    this.setState({
      saturnId: "",
      responseId: "",
      disposition: "",
      toClient: "",
      toComment: "",
      enableBtn: false,
      editable: true
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusSaturnResponseDetails: null,
        }),
      5000
    );
  };

  getSaturnResponseDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showSaturnResponseDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
               Are You Sure, You Want To Delete Saturn Response Id {this.state.responseId} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showSaturnResponseDetailsConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteSaturnResponseDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteSaturnResponseDetails = () => {
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/tmonfilemaintenance/saturn/${this.state.saturnId}`, {
        headers: {
          ...getHeadersProduct().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.responseId !== this.state.saturnId;
          });

          this.setState({
            submitStatusSaturnResponseDetails: "OK",
            submitMsgSaturnResponseDetails: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusSaturnResponseDetails: "Error",
            submitMsgSaturnResponseDetails: response.data.responseString,
          });
        }
        this.onHandleClearSaturnResponseDetails();
      });
    this.setState({ showSaturnResponseDetailsConfirm: false });
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WU TMON FILE MAINTENANCE"
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                SATURN RESPONSE DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  SATURN RESPONSE FORM
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="responseId" className="font-weight-bold">Response Id</label>
                        <input
                          type="text"
                          maxLength="10"
                          value={this.state.responseId}
                          className="form-control form-control-sm"
                          name="responseId"
                          onChange={(e) => {
                            this.onChangeControlSaturnResponseDetailsNumber(e);
                          }}
                          data-test="text-responseId"
                          placeholder="Response ID"
                          readOnly={!this.state.editable}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="disposition" className="font-weight-bold">Disposition</label>
                        <input
                          type="text"
                          maxLength="1"
                          value={this.state.disposition}
                          className="form-control form-control-sm"
                          name="disposition"
                          onChange={(e) => {
                            this.onChangeControlSaturnResponseDetails(e);
                          }}
                          data-test="text-disposition"
                          placeholder="disposition"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="toClient" className="font-weight-bold">To Client</label>
                        <input
                          type="text"
                          maxLength="80"
                          value={this.state.toClient}
                          className="form-control form-control-sm"
                          name="toClient"
                          onChange={(e) => {
                            this.onChangeControlSaturnResponseDetails(e);
                          }}
                          data-test="text-toClient"
                          placeholder="To Client"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="toComment" className="font-weight-bold">To Comment</label>
                        <input
                          type="text"
                          maxLength="80"
                          value={this.state.toComment}
                          className="form-control form-control-sm"
                          name="toComment"
                          onChange={(e) => {
                            this.onChangeControlSaturnResponseDetails(e);
                          }}
                          data-test="text-toComment"
                          placeholder="To Comment"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackSaturnResponseDetails}
                          data-test="button-back"
                        >
                          TMON MAINT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddSaturnResponseDetails}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateSaturnResponseDetails}
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearSaturnResponseDetails}
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showSaturnResponseDetailsConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                          {this.state.submitStatusSaturnResponseDetails ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgSaturnResponseDetails}
                            </div>
                          )}
                          {this.state.submitStatusSaturnResponseDetails ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgSaturnResponseDetails}
                            </div>
                          )}
						              </div>
                          <div className="col-lg-2"></div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getSaturnResponseDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  SATURN RESPONSE DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlSaturnResponseDetails(e);
                        this.onPageSizeChangedSaturnResponseDetails(e);
                      }}
                      value={this.state.paginationValSaturnResponseDetails}
                      name="paginationDropdownGsp"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionSaturnResponseDetails.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactSRD
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadySaturnResponseDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateSRD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateSRD
                      }
                    ></AgGridReactSRD>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default SaturnResponseDetails;
