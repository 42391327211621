import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as  AgGridReactWTCCI} from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditWTCCI } from "react-icons/ai";
import { IconContext as IconContextWTCCI} from "react-icons";
import { getHeadersCustomer, getFormattedDateForComment  } from "../../utility/utility";

class WebTmtCustomerCommentInformation extends Component {
  state = {
    records: [],
    singleData: {},
    customerId: "",
    customerIdBankId: "",
    customerName: "",
    comment: "",
    updateDate: "",
    operatorId: "",
    mode: "",
    oldCustomerComment: "",
    data: {
      customerId: "",
      customerNumer: "",
      customerName: "",
    },
    isCustCommSaveLoading : false,
    isCustCommDeleteLoading: false,
    isCustCommWebTmtLoading: false,
    isCustCommCustInfoLoading: false,
    showWebTmtCustomerCommentInformationConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {this.state.accessGrantedWrite && this.handleEditCommentsInfo(params)}}
            >
              <IconContextWTCCI.Provider value={{ color: this.state.accessGrantedWrite ?"#FDD017":'#cec9b4', size: "20px" }}>
                <AiTwotoneEditWTCCI />
              </IconContextWTCCI.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "120px",
      },
      {
        field: "comment",
        headerName: "Comment",
        width: "500px",
      },
      {
        field: "updateDate",
        headerName: "Update Date",
        width: "270px",
      },
      {
        field: "operatorId",
        headerName: "Operator ID",
        width: "266px",
      },
    ],
    rows: [],
    enableBtn: false,
    enableDelBtn: false,
    gridApi: null,
    paginationValError: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusWebTmtCustomerCommentInfo: null,
    submitMsgWebTmtCustomerCommentInfo: "",
    paginationOptionWebTmtCustomerCommentInfo: [25, 50, 100, 500],
    editable: true,
    overlayLoadingTemplateWTCCI:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateWTCCI: '<span style="">No Records Found</span>',
  };
    
  componentDidMount() {
    this.setState({
      accessGrantedWrite: this.props.location.state.accessGrantedWrite,
      tokenWebTmt: this.props.location.state.tokenWebTmt,
      webAdmin:this.props.location.state.webAdmin,
      isAddressDecryprtRequired: this.props.location.state.isAddressDecryprtRequired,
      isCreditCardDecryprtRequired: this.props.location.state.isCreditCardDecryprtRequired,
      customerIdBankId: this.props.location.state.singleData.customerNumber,
      customerName: this.props.location.state.singleData.customerName,
      commOperatorId: this.props.location.state.operatorId
    });
    //this.getCommentsInfo();
  }

  getCommentsInfo = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            searchKey: this.state.customerIdBankId
              ? this.state.customerIdBankId
              : this.props.location.state.customerId
              ? this.props.location.state.customerId
              : "",
            Authorization: this.state.tokenWebTmt ? this.state.tokenWebTmt : "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.records !== null) {
          if (response.data.records.length > 0) {
            this.setState({
              records: response.data.records,

              //customerIdBankId: response.data.records[0].customerIdBankId,
              //customerName: response.data.records[0].customerName,
              singleData: response.data.records[0],
            });
          } else if (response.data.records.length === 0) {
            this.setState({
              records: response.data.records,
            });
          }
        }
      });
  };

  onChangeWebTmtCustomerCommentInfo = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onGridReadyWebTmtCustomerCommentInfo = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    this.getCommentsInfo();
  };

  handleEditCommentsInfo = (params) => {
    let data = params.node.data;
    this.setState({
      customerIdBankId: this.state.customerIdBankId,
      customerName: this.state.customerName,
      comment: data.comment,
      updateDate: data.updateDate,
      operatorId: data.operatorId,
      tableKey: data.tableKey,
      oldCustomerComment: data.comment,
      enableBtn: true,
      editable: true,
      enableDelBtn: this.state.webAdmin?true:false,
      mode: "edit",
    });
  };

  onPageSizeChangedWebTmtCustomerCommentInfo = (e) => {
    this.setState({ paginationValError: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onValidateWebTmtCustomerCommentInfo = () => {
    if (this.state.comment === "") {
      this.setState({
        submitStatusWebTmtCustomerCommentInfo: "Error",
        submitMsgWebTmtCustomerCommentInfo: "Please Enter The Value In Comment",
      });
      return false;
    }
    if (this.state.oldCustomerComment === this.state.comment) {
      this.setState({
        submitStatusWebTmtCustomerCommentInfo: "Error",
        submitMsgWebTmtCustomerCommentInfo: "Comment Has Not Been Changed",
      });
      return false;
    }
    return true;
  };

  onHandleUpdateWebTmtCustomerCommentInfo = () => {
   
    let submittedObjWTCCI = {};
    let timestampWTCCI = new Date().getUTCMilliseconds();
    submittedObjWTCCI.customerIdBankId = this.state.customerIdBankId;
    submittedObjWTCCI.customerName = this.state.customerName;
    submittedObjWTCCI.comment = this.state.comment;
    submittedObjWTCCI.updateDate = getFormattedDateForComment(); //this.state.updateDate;
    submittedObjWTCCI.operatorId = this.state.commOperatorId;
    if (!this.onValidateWebTmtCustomerCommentInfo()) {
      return;
    }
    this.setState({
      isCustCommSaveLoading: true
    })
    if (this.state.mode === "edit") {     
      submittedObjWTCCI.tableKey = this.state.tableKey;
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
          submittedObjWTCCI,
          {
            headers: {
              ...getHeadersCustomer().headers,
              Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            const updatedRecordIndexWTCCI = this.state.records.findIndex(
              (row) => row.tableKey === submittedObjWTCCI.tableKey
            );
            const updatedRowsWTCCI = [
              ...this.state.records.slice(0, updatedRecordIndexWTCCI),
              submittedObjWTCCI,
              ...this.state.records.slice(updatedRecordIndexWTCCI + 1),
            ];
            this.setState({
              submitStatusWebTmtCustomerCommentInfo: "OK",
              submitMsgWebTmtCustomerCommentInfo: response.data.responseString,
              mode: "",
              records: updatedRowsWTCCI,
              oldCustomerComment:""
            });
            this.getCommentsInfo();
            this.setState({
              isCustCommSaveLoading: false
            })
          } else {
            this.setState({
              submitStatusWebTmtCustomerCommentInfo: "Error",
              submitMsgWebTmtCustomerCommentInfo: response.data.responseString,
              mode: "",
              isCustCommSaveLoading: false,
            });
          }
          this.onHandleClearWebTmtCustomerCommentInfo();
        });
    } else {
      submittedObjWTCCI.tableKey = timestampWTCCI;

      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
          submittedObjWTCCI,
          {
            headers: {
              ...getHeadersCustomer().headers,
              Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              submitStatusWebTmtCustomerCommentInfo: "OK",
              submitMsgWebTmtCustomerCommentInfo: response.data.responseString,
              records: [submittedObjWTCCI, ...this.state.records],
              isCustCommSaveLoading : false,
              oldCustomerComment:""
            });
            //this.getCommentsInfo();
          } else {
            this.setState({
              submitStatusWebTmtCustomerCommentInfo: "Error",
              submitMsgWebTmtCustomerCommentInfo: response.data.responseString,
              isCustCommSaveLoading : false
            });
          }
          this.onHandleClearWebTmtCustomerCommentInfo();
        });
    }
  };

  onHandleClearWebTmtCustomerCommentInfo = () => {
    this.setState({
      comment: "",
      updateDate: "",
      operatorId: "",
      enableBtn: false,
      enableDelBtn:false,
      editable: true,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusWebTmtCustomerCommentInfo: null,
          submitMsgWebTmtCustomerCommentInfo: "",
        }),
      5000
    );
  };

  getWebTmtCustomerCommentInfoModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showWebTmtCustomerCommentInformationConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are you sure, you want to delete this comment?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showWebTmtCustomerCommentInformationConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteWebTmtCustomerCommentInfo}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteWebTmtCustomerCommentInfo = () => {
    if (this.state.tableKey !== "") {
      this.setState({
        isCustCommDeleteLoading: true
      })
      axios
        .delete(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
          {
            headers: {
              ...getHeadersCustomer().headers,
              //commentTblKey: 
              searchKey :this.state.tableKey ? this.state.tableKey : "",
              Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              submitStatusWebTmtCustomerCommentInfo: "OK",
              submitMsgWebTmtCustomerCommentInfo: response.data.responseString,
            });
            this.getCommentsInfo();
            this.setState({
              isCustCommDeleteLoading: false,
              enableDelBtn: false,
              oldCustomerComment:"",
              mode:""
            })
          } else {
            this.setState({
              submitStatusWebTmtCustomerCommentInfo: "Error",
              submitMsgWebTmtCustomerCommentInfo: response.data.responseString,
              isCustCommDeleteLoading: false
            });
          }
          this.onHandleClearWebTmtCustomerCommentInfo();         
        });
    }
    // else{
    //   this.setState({
    //     submitStatusWebTmtCustomerCommentInfo: "Error",
    //     submitMsgWebTmtCustomerCommentInfo: "Please select comment to delete",
    //     isCustCommDeleteLoading: false,
    //     mode:"",
    //     enableDelBtn:false,
    //   });
    // }
    this.setState({ showWebTmtCustomerCommentInformationConfirm: false });
  };

  onHandleBackWebTmtMain = () => {
    this.setState({
      isCustCommWebTmtLoading: true
    })
    this.props.history.push("/web-tmt-db-maintenance");
    this.setState({
      isCustCommWebTmtLoading: false
    })
  };

  onHandleBackCustomerInfo = () => {
    this.setState({
      isCustCommCustInfoLoading: true
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customerinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            accountNumber: this.state.customerId ? this.state.customerId : "",
            customerNumber: this.state.customerIdBankId
              ? this.state.customerIdBankId
              : "",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            userId:this.state.commOperatorId,
            //isAddressDecryprtRequired: this.state.isAddressDecryprtRequired,
          },
        }
      )
      .then((response) => {        
        this.setState({
          isCustCommCustInfoLoading: false
        })
        if (response.status===200 && response.data.records !== null) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
            accountNumber: this.state.customerId,
            customerAccountId:this.props.location.state.customerNumber,
            operatorId: this.state.commOperatorId
          });
          if (this.state.singleData.responseType === 1) {
            this.props.history.goBack({
              pathname: "/web-tmt-customer-information",
              state: this.state,
            });
          }
        } else {
          this.setState({
            submitStatusWebTmtCustomerCommentInfo: "Error",
            submitMsgWebTmtCustomerCommentInfo: response.data.errMsg,
          });
          return false;
        }
      });
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WEB/TMT Database Maintenance"
        />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading">
                WEB/TMT Database Maintenance - Customer Comment Information
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  COMMENT DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <div className="form-group row">
                    <div className="col-lg">
                      <label
                        htmlFor="customerIdBankId"
                        className="font-weight-bold"
                      >
                        Customer Id
                      </label>
                      <input
                        type="text"
                        value={this.state.customerIdBankId}
                        className="form-control form-control-sm"
                        name="customerIdBankId"
                        // onChange={(e) => {
                        //   this.onChangeWebTmtCustomerCommentInfo(e);
                        // }}
                        data-test="text-customerIdBankId"
                        placeholder="Customer Id"
                        disabled
                      />
                    </div>
                    <div className="col-lg">
                      <label
                        htmlFor="customerName"
                        className="font-weight-bold"
                      >
                        Customer Name
                      </label>
                      <input
                        type="text"
                        value={this.state.customerName}
                        className="form-control form-control-sm"
                        name="customerName"
                        data-test="text-customerName"
                        placeholder="Customer Name"
                        disabled
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="updateDate" className="font-weight-bold">
                        Update Date
                      </label>
                      <input
                        type="text"
                        value={this.state.updateDate}
                        className="form-control form-control-sm"
                        name="updateDate"
                        data-test="text-updateDate"
                        placeholder="Update Date"
                        disabled
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="operatorId" className="font-weight-bold">
                        Operator Id{" "}
                      </label>
                      <input
                        type="text"
                        value={this.state.operatorId}
                        className="form-control form-control-sm"
                        name="operatorId"
                        data-test="text-operatorId"
                        placeholder="Operator Id"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="comment" className="font-weight-bold">
                        Comment
                      </label>
                      <input
                        type="text"
                        maxLength="70"
                        value={this.state.comment}
                        className="form-control form-control-sm"
                        name="comment"
                        onChange={(e) => {
                          this.onChangeWebTmtCustomerCommentInfo(e);
                        }}
                        data-test="text-comment"
                        placeholder="Comment"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      {this.state.isCustCommWebTmtLoading ? (
                        <button
                          className="btn btn-warning mar-right10"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackWebTmtMain}
                          data-test="button-back"
                          disabled={
                            this.state.isCustCommSaveLoading === true ||
                            this.state.isCustCommDeleteLoading === true ||
                            this.state.isCustCommWebTmtLoading === true ||
                            this.state.isCustCommCustInfoLoading === true
                          }
                        >
                          WEBTMT MAINT
                        </button>
                      )}
                      {this.state.isCustCommSaveLoading ? (
                        <button
                          className="btn btn-warning mar-right10"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateWebTmtCustomerCommentInfo}
                          data-test="button-save"
                          disabled={
                            !this.state.accessGrantedWrite ||
                            this.state.isCustCommSaveLoading === true ||
                            this.state.isCustCommDeleteLoading === true ||
                            this.state.isCustCommWebTmtLoading === true ||
                            this.state.isCustCommCustInfoLoading === true
                          }
                        >
                          SAVE
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearWebTmtCustomerCommentInfo}
                        data-test="button-clear"
                      >
                        CLEAR
                      </button>
                      {this.state.isCustCommDeleteLoading ? (
                        <button
                          className="btn btn-warning mar-right10"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      ) : (
                        <button
                          disabled={
                            this.state.enableDelBtn
                              ? false
                              : true ||
                                this.state.isCustCommSaveLoading === true ||
                                this.state.isCustCommDeleteLoading === true ||
                                this.state.isCustCommWebTmtLoading === true ||
                                this.state.isCustCommCustInfoLoading === true
                          }
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={() => {
                            this.setState({
                              showWebTmtCustomerCommentInformationConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                        >
                          DELETE
                        </button>
                      )}
                      {this.state.isCustCommCustInfoLoading ? (
                        <button
                          className="btn btn-warning mar-right10"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      ) : (
                        <button
                          disabled={this.state.enableBtn}
                          type="button"
                          className="btn btn-warning"
                          onClick={this.onHandleBackCustomerInfo}
                          data-test="button-customerInfo"
                          disabled={
                            this.state.isCustCommSaveLoading === true ||
                            this.state.isCustCommDeleteLoading === true ||
                            this.state.isCustCommWebTmtLoading === true ||
                            this.state.isCustCommCustInfoLoading === true
                          }
                        >
                          CUSTOMER INFO
                        </button>
                      )}
                    </div>                    
                  </div>
                  <div className="form-group row">
                  <div className="col-lg-4"/>
                  <div className="col-lg-4">
                      {this.state.submitStatusWebTmtCustomerCommentInfo ===
                        "OK" && (
                        <div className="alert alert-success alert-padding notification text-center">
                          {this.state.submitMsgWebTmtCustomerCommentInfo}
                        </div>
                      )}
                      {this.state.submitStatusWebTmtCustomerCommentInfo ===
                        "Error" && (
                        <div className="alert alert-danger alert-padding notification text-center">
                          {this.state.submitMsgWebTmtCustomerCommentInfo}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {this.getWebTmtCustomerCommentInfoModal()}
              <div className="card mar-top20">
                {/*<div className="card-header bg-dark text-white card-header-custom">
                  ERROR DETAILS DATA
                        </div>*/}
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label
                      htmlFor="pagination-select-label"
                      className="font-weight-bold"
                    >
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeWebTmtCustomerCommentInfo(e);
                        this.onPageSizeChangedWebTmtCustomerCommentInfo(e);
                      }}
                      value={this.state.paginationValError}
                      name="paginationDropdownError"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionWebTmtCustomerCommentInfo.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactWTCCI
                      rowData={this.state.records}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyWebTmtCustomerCommentInfo}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateWTCCI
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateWTCCI
                      }
                    ></AgGridReactWTCCI>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebTmtCustomerCommentInformation;
