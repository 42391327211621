import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactTTL } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditTTL } from "react-icons/ai";
import { IconContext as IconContextTTL } from "react-icons";
import { getHeadersShared2 } from "../../utility/utility";

class TmonTxnList extends Component {
  state = {
    transCode: "",
    groupCode: "",
    tipRecNumber: "",
    disableFlag: "",
    debugLevel: "",
    count1: "",
    count2: "",
    amount: "",
    freeFormatParam1: "",
    freeFormatParam2: "",
    freeFormatParam3: "",
    freeFormatParam4: "",
    freeFormatParam5: "",
    freeFormatParam6: "",
    freeFormatParam7: "",
    freeFormatParam8: "",
    freeFormatParam9: "",
    freeFormatParam10: "",
    freeFormatParam11: "",
    freeFormatParam12: "",
    showTmonTxnListConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={
                () => {
                    if(!this.props.location.state.accessGrantedWriteTMD1) { 
                    this.setState({
                      submitStatusTmonTxnList: 'Error',
                      submitMsgTmonTxnList: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                    });
                  }else{
                    this.handleEditTmonTxnList(params)
                  }  
              }
            }
            >
              <IconContextTTL.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditTTL />
              </IconContextTTL.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "60px",
      },
      { field: "transCode", headerName: "TRANS CODE", width: 115, sortable: true, filter: true, floatingFilter: true },
      { field: "groupCode", headerName: "GROUP CODE", width: 115, sortable: true, filter: true, floatingFilter: true },
      { field: "tipRecNumber", headerName: "TIP REC NO", width: 115, sortable: true, filter: true, floatingFilter: true },
      { field: "disableFlag", headerName: "DISABLE FLAG", width: 110 },
      { field: "debugLevel", headerName: "DEBUG LEVEL", width: 100 },
      { field: "count1", headerName: "COUNT1", width: 80 },
      { field: "count2", headerName: "COUNT2", width: 80 },
      { field: "amount", headerName: "AMOUNT", width: 80 },
      { field: "freeFormatParam1", headerName: "1", width: 40 },
      { field: "freeFormatParam2", headerName: "2", width: 40 },
      { field: "freeFormatParam3", headerName: "3", width: 40 },
      { field: "freeFormatParam4", headerName: "4", width: 40 },
      { field: "freeFormatParam5", headerName: "5", width: 40 },
      { field: "freeFormatParam6", headerName: "6", width: 40 },
      { field: "freeFormatParam7", headerName: "7", width: 40 },
      { field: "freeFormatParam8", headerName: "8", width: 40 },
      { field: "freeFormatParam9", headerName: "9", width: 40 },
      { field: "freeFormatParam10", headerName: "10", width: 40 },
      { field: "freeFormatParam11", headerName: "11", width: 40 },
      { field: "freeFormatParam12", headerName: "12", width: 40 },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValTmon: "25",
    defaultColumnDef: {
      resizable: true,
    },
    submitStatusTmonTxnList: null,
    submitMsgTmonTxnList: "",
    paginationOptionTmonTxnList: [25, 50, 100, 500],
    editable: true,
    overlayLoadingTemplateTTL:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateTTL: '<span style="">No Records Found</span>',
  };

  onChangeControlTmonTxnList = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeControlTmonTxnListNumberOnly = (e) => {
    let regex = /[^\d]/;
    if (!regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onGridReadyTmonTxnList = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/tmontransaction`, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        this.setState({
          rows: response.data.records,
        });
      });
  };
  handleEditTmonTxnList = (params) => {
    let data = params.node.data;
    this.setState({
      transCode: data.transCode,
      groupCode: data.groupCode,
      tipRecNumber: data.tipRecNumber,
      disableFlag: data.disableFlag,
      debugLevel: data.debugLevel,
      count1: data.count1,
      count2: data.count2,
      amount: data.amount,
      freeFormatParam1: data.freeFormatParam1,
      freeFormatParam2: data.freeFormatParam2,
      freeFormatParam3: data.freeFormatParam3,
      freeFormatParam4: data.freeFormatParam4,
      freeFormatParam5: data.freeFormatParam5,
      freeFormatParam6: data.freeFormatParam6,
      freeFormatParam7: data.freeFormatParam7,
      freeFormatParam8: data.freeFormatParam8,
      freeFormatParam9: data.freeFormatParam9,
      freeFormatParam10: data.freeFormatParam10,
      freeFormatParam11: data.freeFormatParam11,
      freeFormatParam12: data.freeFormatParam12,
      enableBtn: true,
      editable: false
    });
  };
  onPageSizeChangedTmonTxnList = (e) => {
    this.setState({ paginationValTmon: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackTmonTxnList = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  onValidateTmonTxnList = () => {

    if (this.state.transCode === "" || this.state.transCode.trim().length < 4 //&& this.state.transType==="A"
    ) {
      this.setState({
        submitStatusTmonTxnList: "Error",
        submitMsgTmonTxnList: "Please Enter Valid Trans Code ",
      });
      return false;
    }
    // if (this.state.debugLevel === "" ) {
    //   this.setState({
    //     submitStatusTmonTxnList: "Error",
    //     submitMsgTmonTxnList: "Please enter 1-digit debug level ",
    //   });
    //   return false;
    // }
   
    return true;
  };

  onHandleAddTmonTxnList = () => {
    if (!this.onValidateTmonTxnList()) {
      return;
    }
    let submittedObj = {};
    submittedObj.transCode = this.state.transCode;
    submittedObj.groupCode = this.state.groupCode;
    submittedObj.tipRecNumber = this.state.tipRecNumber;
    submittedObj.disableFlag = this.state.disableFlag;
    submittedObj.debugLevel = this.state.debugLevel;
    submittedObj.count1 = this.state.count1;
    submittedObj.count2 = this.state.count2;
    submittedObj.amount = this.state.amount;
    submittedObj.freeFormatParam1 = this.state.freeFormatParam1;
    submittedObj.freeFormatParam2 = this.state.freeFormatParam2;
    submittedObj.freeFormatParam3 = this.state.freeFormatParam3;
    submittedObj.freeFormatParam4 = this.state.freeFormatParam4;
    submittedObj.freeFormatParam5 = this.state.freeFormatParam5;
    submittedObj.freeFormatParam6 = this.state.freeFormatParam6;
    submittedObj.freeFormatParam7 = this.state.freeFormatParam7;
    submittedObj.freeFormatParam8 = this.state.freeFormatParam8;
    submittedObj.freeFormatParam9 = this.state.freeFormatParam9;
    submittedObj.freeFormatParam10 = this.state.freeFormatParam10;
    submittedObj.freeFormatParam11 = this.state.freeFormatParam11;
    submittedObj.freeFormatParam12 = this.state.freeFormatParam12;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/tmontransaction`, submittedObj, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        //console.log(response);
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusTmonTxnList: "OK",
            submitMsgTmonTxnList: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          
        } else {
          this.setState({
            submitStatusTmonTxnList: "Error",
            submitMsgTmonTxnList: response.data.responseString,
          });
        }
        this.onHandleClearTmonTxnList();
      });
  };

  onHandleUpdateTmonTxnList = () => {
    if (!this.onValidateTmonTxnList()) {
      return;
    }
    let submittedObj = {};
    submittedObj.transCode = this.state.transCode;
    submittedObj.groupCode = this.state.groupCode;
    submittedObj.tipRecNumber = this.state.tipRecNumber;
    submittedObj.disableFlag = this.state.disableFlag;
    submittedObj.debugLevel = this.state.debugLevel;
    submittedObj.count1 = this.state.count1;
    submittedObj.count2 = this.state.count2;
    submittedObj.amount = this.state.amount;
    submittedObj.freeFormatParam1 = this.state.freeFormatParam1;
    submittedObj.freeFormatParam2 = this.state.freeFormatParam2;
    submittedObj.freeFormatParam3 = this.state.freeFormatParam3;
    submittedObj.freeFormatParam4 = this.state.freeFormatParam4;
    submittedObj.freeFormatParam5 = this.state.freeFormatParam5;
    submittedObj.freeFormatParam6 = this.state.freeFormatParam6;
    submittedObj.freeFormatParam7 = this.state.freeFormatParam7;
    submittedObj.freeFormatParam8 = this.state.freeFormatParam8;
    submittedObj.freeFormatParam9 = this.state.freeFormatParam9;
    submittedObj.freeFormatParam10 = this.state.freeFormatParam10;
    submittedObj.freeFormatParam11 = this.state.freeFormatParam11;
    submittedObj.freeFormatParam12 = this.state.freeFormatParam12;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/tmontransaction`, submittedObj, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
     })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.transCode === submittedObj.transCode
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusTmonTxnList: "OK",
            submitMsgTmonTxnList: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusTmonTxnList: "Error",
            submitMsgTmonTxnList: response.data.responseString,
          });
        }
        this.onHandleClearTmonTxnList();
      });
  };

  onHandleClearTmonTxnList = () => {
    this.setState({
      transCode: "",
      groupCode: "",
      tipRecNumber: "",
      disableFlag: "",
      debugLevel: "",
      count1: "",
      count2: "",
      amount: "",
      freeFormatParam1: "",
      freeFormatParam2: "",
      freeFormatParam3: "",
      freeFormatParam4: "",
      freeFormatParam5: "",
      freeFormatParam6: "",
      freeFormatParam7: "",
      freeFormatParam8: "",
      freeFormatParam9: "",
      freeFormatParam10: "",
      freeFormatParam11: "",
      freeFormatParam12: "",
      enableBtn: false,
      editable: true
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusTmonTxnList: null,
        }),
      5000
    );
  };

  getTmonTxnListModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showTmonTxnListConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
               Are You Sure, You Want To Delete Trans Code {this.state.transCode} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() => this.setState({ showTmonTxnListConfirm: false })}
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteTmonTxnList}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteTmonTxnList = () => {
    let submittedObj = {};
    submittedObj.transCode = this.state.transCode;
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/tmontransaction/${this.state.transCode}`,{
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.transCode !== submittedObj.transCode;
          });
          this.setState({
            submitStatusTmonTxnList: "OK",
            submitMsgTmonTxnList: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusTmonTxnList: "Error",
            submitMsgTmonTxnList: response.data.responseString,
          });
        }
        this.onHandleClearTmonTxnList();
      });
    this.setState({ showTmonTxnListConfirm: false });
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WU TMON FILE MAINTENANCE"
        />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                Transaction Monitor File Maintenance
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  LIST OF VALID TRAN CODES
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="transCode" className="font-weight-bold">Trans Code</label>
                        <input
                          type="text"                         
                          maxLength="6"
                          value={this.state.transCode}
                          className="form-control form-control-sm"
                          name="transCode"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-transCode"
                          placeholder="Trans Code"
                          disabled={!this.state.editable}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="groupCode" className="font-weight-bold">Group Code</label>
                        <input
                          type="text"                         
                          maxLength="2"
                          value={this.state.groupCode}
                          className="form-control form-control-sm"
                          name="groupCode"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnListNumberOnly(e);
                          }}
                          data-test="text-groupCode"
                          placeholder="Group Code"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="tipRecNumber" className="font-weight-bold">Tip Rec Number</label>
                        <input
                          type="text"                         
                          maxLength="5"
                          value={this.state.tipRecNumber}
                          className="form-control form-control-sm"
                          name="tipRecNumber"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-tipRecNumber"
                          placeholder="Tip Rec Number"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="disableFlag" className="font-weight-bold">Disable Flag</label>
                        <input
                          type="text"                         
                          maxLength="1"
                          value={this.state.disableFlag}
                          className="form-control form-control-sm"
                          name="disableFlag"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-disableFlag"
                          placeholder="Disable Flag"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="debugLevel" className="font-weight-bold">Debug Level</label>
                        <input
                          type="text"                         
                          maxLength="1"
                          value={this.state.debugLevel}
                          className="form-control form-control-sm"
                          name="debugLevel"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnListNumberOnly(e);
                          }}
                          data-test="text-debugLevel"
                          placeholder="Debug Level"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-4" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="count1" className="font-weight-bold">Count 1 </label>
                        <input
                          type="text"                         
                          maxLength="1"
                          value={this.state.count1}
                          className="form-control form-control-sm"
                          name="count1"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnListNumberOnly(e);
                          }}
                          data-test="text-count1"
                          placeholder="Count 1"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="count2" className="font-weight-bold">Count 2 </label>
                        <input
                          type="text"                         
                          maxLength="1"
                          value={this.state.count2}
                          className="form-control form-control-sm"
                          name="count2"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnListNumberOnly(e);
                          }}
                          data-test="text-count2"
                          placeholder="Count 2"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="amount" className="font-weight-bold">Amount </label>
                        <input
                          type="text"                         
                          maxLength="6"
                          value={this.state.amount}
                          className="form-control form-control-sm"
                          name="amount"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnListNumberOnly(e);
                          }}
                          data-test="text-amount"
                          placeholder="Amount"
                        />
                      </div>
                    </div>
                    <label htmlFor="freeFormatParam1" className="font-weight-bold">Free Format Params</label>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam1" className="font-weight-bold">1</label>
                        <input
                          type="text"                         
                          maxLength="1"
                          value={this.state.freeFormatParam1}
                          className="form-control form-control-sm  "
                          name="freeFormatParam1"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam1"
                          placeholder="1"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam2" className="font-weight-bold">2</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam2}
                          className="form-control form-control-sm"
                          name="freeFormatParam2"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam2"
                          placeholder="2"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam3" className="font-weight-bold">3</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam3}
                          className="form-control form-control-sm"
                          name="freeFormatParam3"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam3"
                          placeholder="3"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam4" className="font-weight-bold">4</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam4}
                          className="form-control form-control-sm"
                          name="freeFormatParam4"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam4"
                          placeholder="4"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam5" className="font-weight-bold">5</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam5}
                          className="form-control form-control-sm"
                          name="freeFormatParam5"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam5"
                          placeholder="5"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam6" className="font-weight-bold">6</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam6}
                          className="form-control form-control-sm"
                          name="freeFormatParam6"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam6"
                          placeholder="6"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam7" className="font-weight-bold">7</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam7}
                          className="form-control form-control-sm"
                          name="freeFormatParam7"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam7"
                          placeholder="7"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam8" className="font-weight-bold">8</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam8}
                          className="form-control form-control-sm"
                          name="freeFormatParam8"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam8"
                          placeholder="8"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam9" className="font-weight-bold">9</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam9}
                          className="form-control form-control-sm"
                          name="freeFormatParam9"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam9"
                          placeholder="9"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam10" className="font-weight-bold">10</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam10}
                          className="form-control form-control-sm"
                          name="freeFormatParam10"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam10"
                          placeholder="10"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam11" className="font-weight-bold">11</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam11}
                          className="form-control form-control-sm"
                          name="freeFormatParam11"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam11"
                          placeholder="11"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="freeFormatParam12" className="font-weight-bold">12</label>
                        <input
                          type="text"                        
                          maxLength="1"
                          value={this.state.freeFormatParam12}
                          className="form-control form-control-sm"
                          name="freeFormatParam12"
                          onChange={(e) => {
                            this.onChangeControlTmonTxnList(e);
                          }}
                          data-test="text-freeFormatParam12"
                          placeholder="12"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackTmonTxnList}
                          data-test="button-back"
                        >
                          TMON MAINT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddTmonTxnList}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD1}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateTmonTxnList}
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD1}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearTmonTxnList}
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD1}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({ showTmonTxnListConfirm: true });
                          }}
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD1}
                          data-test="button-delete"
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                            {this.state.submitStatusTmonTxnList === "OK" && (
                              <div className="alert alert-success alert-padding notification">
                                {this.state.submitMsgTmonTxnList}
                              </div>
                            )}
                            {this.state.submitStatusTmonTxnList === "Error" && (
                              <div className="alert alert-danger alert-padding notification">
                                {this.state.submitMsgTmonTxnList}
                              </div>
                            )}
						              </div>
                          <div className="col-lg-2"></div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getTmonTxnListModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  TMON FREE FORMAT PARAMETERS - LIST OF VALID TRANS CODES
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlTmonTxnList(e);
                        this.onPageSizeChangedTmonTxnList(e);
                      }}
                      value={this.state.paginationValTmon}
                      name="paginationDropdownTmon"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionTmonTxnList.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactTTL
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyTmonTxnList}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateTTL
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateTTL
                      }
                    ></AgGridReactTTL>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TmonTxnList;
