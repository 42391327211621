import React, { Component } from "react";
import axios from "axios";
// import { Button, Modal } from "react-bootstrap";
import Header from "../../../header/header";
import { getHeadersOrder } from "../../../utility/utility";

class ErrorQueueMaintenanceDetails extends Component {
  state = {
    queueId: '',
    queueCount: '',
    mtcn: '',
    status: '',
    runa: '',
    senderName: '',
    senderPhone: '',
    senderAddr: '',
    senderAcct: '',
    senderZip: '',
    senderCity: '',
    pageNo: 0,
    searchedMtcn: '',
    searchedQueueId: '',
    totalNoOfRecords: 0,
    submitStatusEqmd: null,
    submitMsgEqmd: "",
    showEqmdDetailsConfirm: false,
    isLoading: false,
    isNextDisabled: false,
    currentQueue: '',
  };

  componentDidMount() {
    this.setData(this.props.history.location.records[0]);
    this.setState( {
      searchedMtcn: this.props.history.location.mtcn,
      searchedQueueId: this.props.history.location.queueId,
      totalNoOfRecords:  this.props.history.location.totalNoOfRecords
    });
  }

  setData = (dataObj) => {
    this.setState({
      queueId: dataObj.queueId,
      currentQueue: dataObj.queueId,
      queueCount: dataObj.queueCount,
      mtcn: dataObj.mtcn,
      status: dataObj.status,
      runa: dataObj.runa,
      senderName: dataObj.sender.name,
      senderPhone: dataObj.sender.phone,
      senderAddr: dataObj.sender.addr,
      senderAcct: dataObj.sender.pcn,
      senderCity: dataObj.sender.city,
      senderZip: dataObj.sender.stZip,
      receiverName: dataObj.receiver.receiver,
      receiverPhone: dataObj.receiver.phone,
      receiverAddr: dataObj.receiver.address,
      receiverCity: dataObj.receiver.city,
      receiverZip: dataObj.receiver.stZip,
    });
  }

  onHandleNextErrorMaintenanceDetails  = () => {
    this.setState({
      pageNo: this.state.pageNo + 1
    }, () => {
      this.setState({isLoading:true});
      axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/queuerecords`,
        {
          headers: {
            ...getHeadersOrder().headers,
            queueId:this.state.searchedQueueId,
            mtcn: this.state.searchedMtcn,
            pageNo: this.state.pageNo,
            pageSize: 1,
            Authorization: this.props.location.state.token,
            "userId":this.props.location.state.operatorIdTMD  
          },
        }
      )
      .then((response) => {
        this.setState({isLoading:false})
        if(response.data.flag === 1) {       
          this.setData(response.data.records[0]);
        } else {
          this.setState({
            submitStatusEqmd: "Error",
            submitMsgEqmd: response.data.responseString,
          });
        }
        this.onHandleClearEqmd();
      });
    });
  }

  // getQueueMantenanceGroupDetailsModal = () => {
  //   return (
  //     <div>
  //       <Modal
  //         show={this.state.showEqmdDetailsConfirm}
  //         animation={true}
  //         size="md"
  //         className="shadow-lg border"
  //       >
  //         <Modal.Header className="bg-dark text-white text-center py-1">
  //           <Modal.Title className="text-center">
  //             <h5 className="modal-header-custom">
  //               Are you sure, you want to bust {this.state.mtcn}?
  //             </h5>
  //           </Modal.Title>
  //         </Modal.Header>
  //         <Modal.Footer className="py-1 d-flex justify-content-center">
  //           <div>
  //             <Button
  //               variant="warning"
  //               onClick={() =>
  //                 this.setState({
  //                   showEqmdDetailsConfirm: false,
  //                 })
  //               }
  //               data-test="button-no"
  //             >
  //               No
  //             </Button>
  //           </div>
  //           <div>
  //             <Button
  //               variant="warning"
  //               className="mx-2 px-3"
  //               onClick={this.handleBustErrorMaintenanceDetails}
  //               data-test="button-yes"
  //             >
  //               Yes
  //             </Button>
  //           </div>
  //         </Modal.Footer>
  //       </Modal>
  //     </div>
  //   );
  // };

  // handleBustErrorMaintenanceDetails = () => {
  //   axios
  //     .delete(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/deletemtcn`, {
  //       headers: {
  //         ...getHeadersOrder().headers,
  //         mtcn: this.state.mtcn,
  //         Authorization: this.props.location.state.token,
  //         "userId":this.props.location.state.operatorIdTMD  
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         this.setState({
  //           submitStatusEqmd: "OK",
  //           submitMsgEqmd: response.data.responseString,
  //         });
  //         if(this.state.pageNo === this.state.totalNoOfRecords -1) {
  //             this.onHandleBackErrorMaintenanceDetails();
  //         } else {
  //           this.setState({
  //             pageNo: this.state.pageNo - 1,
  //             totalNoOfRecords: this.state.totalNoOfRecords -1,
  //             searchedMtcn: '',
  //             searchedQueueId: this.state.currentQueue
  //           }, () => {
  //             this.onHandleNextErrorMaintenanceDetails();
  //           });
  //         }
  //       } else {
  //         this.setState({
  //           submitStatusEqmd: "Error",
  //           submitMsgEqmd: response.data.responseString,
  //         });
  //       }
  //      this.onHandleClearEqmd();
  //     });
  //     this.setState({ showEqmdDetailsConfirm: false });
  // };

  onHandleBackErrorMaintenanceDetails = () => {
    this.props.history.push({pathname:"/error-queue-maintenance-search",state:this.props.location.state});
  };

  onHandleClearEqmd = () => {
      setTimeout(
        () =>
          this.setState({
            submitStatusEqmd: null,
            submitMsgEqmd: '',
          }),
        5000
      );
  }

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WU TMON FILE MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                QUEUE MAINTENANCE MENU
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  QUEUE MAINTENANCE MENU FORM
                </div>
                <div className="card-body card-body-custom">
                  <div className="row">
                    <div className="col-lg-1" />
                    <div className="col-lg-2">
                      <label htmlFor="queueId" className="font-weight-bold">Queue Id : </label> {this.state.queueId}
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="queueCount" className="font-weight-bold">Q-count : </label> {this.state.queueCount}
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="mtcn" className="font-weight-bold">MTCN : </label> {this.state.mtcn}
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="status" className="font-weight-bold">Status : </label> {this.state.status}
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="runa" className="font-weight-bold">Runa : </label> {this.state.runa}
                    </div>
                    <div className="col-lg-1" />
                  </div>
                  <div className="row card-body">
                    <div className="col-lg-6">
                      <div className="card-header bg-dark text-white card-header-custom">
                        Sender
                      </div>
                      <div className="card">
                        <div className="card-body card-body-custom">
                          <div className="row">
                            <div className="col-lg-4">
                              <label htmlFor="senderName" className="font-weight-bold">Name : </label> {this.state.senderName}
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="senderPhone" className="font-weight-bold">Phone : </label> {this.state.senderPhone}
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="senderAddr" className="font-weight-bold">Address : </label> {this.state.senderAddr}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <label htmlFor="senderAcc" className="font-weight-bold">Account : </label> {this.state.senderAcct}
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="senderCity" className="font-weight-bold">City : </label> {this.state.senderCity}
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="senderZip" className="font-weight-bold">St Zip : </label> {this.state.senderZip}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="card-header bg-dark text-white card-header-custom">
                        Receiver
                      </div>
                      <div className="card">
                        <div className="card-body card-body-custom">
                          <div className="row">
                            <div className="col-lg-4">
                              <label htmlFor="receiverName" className="font-weight-bold">Name : </label> {this.state.receiverName}
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="receiverPhone" className="font-weight-bold">Phone : </label> {this.state.receiverPhone}
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="receiverAddr" className="font-weight-bold">Address : </label> {this.state.receiverAddr}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <label htmlFor="receiverCity" className="font-weight-bold">City : </label> {this.state.receiverCity}
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="receiverZip" className="font-weight-bold">St Zip : </label> {this.state.receiverZip}
                            </div>
                            <div className="col-lg-4" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mar-top5">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackErrorMaintenanceDetails}
                        data-test="button-back"
                      >
                        Search
                      </button>
                      {
                        this.state.isLoading ? 
                          <button className="btn btn-warning mar-right10" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      :
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleNextErrorMaintenanceDetails}
                        data-test="button-next"
                        disabled = {this.state.pageNo === this.state.totalNoOfRecords -1}
                      >
                        Next
                      </button>
                      }
                      {!this.props.location.state.accessGrantedWriteTMD4 ? 
                        <button
                        type="button"
                        className="btn btn-warning"
                        data-test="button-bust"
                        disabled={true}
                      >
                        BUST
                      </button>
                      :
                      <button
                        type="button"
                        className="btn btn-warning"
                        data-test="button-bust"
                        disabled={true}
                        onClick={() => {
                          this.setState({
                            showEqmdDetailsConfirm: true,
                          });
                        }}
                      >
                        BUST
                      </button>
                      }
                    </div>
                    {/* {this.getQueueMantenanceGroupDetailsModal()} */}
                    <div className="col-lg-3">
                      {this.state.submitStatusEqmd ===
                        "OK" && (
                        <div className="alert alert-success alert-padding notification text-center">
                          {this.state.submitMsgEqmd}
                        </div>
                      )}
                      {this.state.submitStatusEqmd ===
                        "Error" && (
                        <div className="alert alert-danger alert-padding notification text-center">
                          {this.state.submitMsgEqmd}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ErrorQueueMaintenanceDetails;
