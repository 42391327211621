import React from "react";
import { connect } from "react-redux";
import { doSearchMtcn } from "../../../actions/mtcn-search/mtcn-search-action";
import Header from "../../header/header";

class MoneyTransferDatabaseAgentRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

    componentDidMount = () => {
    //  console.log(this.props.history.location.jwtToken );
      this.setState({
        mtcn: this.props.history.location.mtcn,
        account: this.props.searchedData.account,
        firstChar: this.props.searchedData.account[0],
        status: this.props.searchedData.status,
        rid: this.props.searchedData.rid,
        rev: this.props.searchedData.rev,
        bingo: this.props.searchedData.bingo,
        class: this.props.searchedData.classVar,
        ctlOff: this.props.searchedData.ctlOff,
        rucNumber: this.props.searchedData.rucNumber,
        sin: this.props.searchedData.sin,
        name: this.props.searchedData.name,
        addr1: this.props.searchedData.addr1,
        addr2: this.props.searchedData.addr2,
        phone1: this.props.searchedData.phone1,
        phone2: this.props.searchedData.phone2,
        city: this.props.searchedData.city,
        stZip: this.props.searchedData.stZip,
        country: this.props.searchedData.country,
        demographicArea: this.props.searchedData.demographicArea,
        cscLoc: this.props.searchedData.cscLoc,
        regSet: this.props.searchedData.regSet,
        dateTime: this.props.searchedData.dateTime,
        tLimit: this.props.searchedData.tlimit,
        chgSite: this.props.searchedData.chgSite,
        chgOpId: this.props.searchedData.chgOpId,
        dLimit: this.props.searchedData.dlimit,
        chgTd: this.props.searchedData.chgTd,
        lastModiTd: this.props.searchedData.lastModiTd,
        dailytotal: this.props.searchedData.dailyTotal,
        maxMo: this.props.searchedData.maxMo,
        curMo: this.props.searchedData.curMo,
        cpcCode: this.props.searchedData.cpcCode,
        cntryName: this.props.searchedData.cntryName,
        currName: this.props.searchedData.currName,
        dailyPay: this.props.searchedData.dailyPay,
        region: this.props.searchedData.region,
        tSource: this.props.searchedData.tsource,
        routes: this.props.searchedData.routes,
        flatRate: this.props.searchedData.flatRate,
        lrt: this.props.searchedData.lrt,
        dailyOverlimitBump: this.props.searchedData.dailyOverlimitBump,
        rrt: this.props.searchedData.rrt,
        dtAppld: this.props.searchedData.dtAppld,
        art: this.props.searchedData.art,
        dineroPlus: this.props.searchedData.dineroPlus,
        fxSprdSplAcctCd: this.props.searchedData.fxSprdSplAcctCd,
        memo: this.props.searchedData.memo,
        agentId: this.props.searchedData.agentId,
        dbRate: this.props.searchedData.dbRate,
        crRate: this.props.searchedData.crRate,
        services: this.props.searchedData.services,
        agtNew: this.props.searchedData.agtNew,
        canRef: this.props.searchedData.canRef,
        unPay: this.props.searchedData.unPay,
        recMos: this.props.searchedData.recMos,
        agtChg: this.props.searchedData.agtChg,
        wevView: this.props.searchedData.wevView,
        txnCap: this.props.searchedData.txnCap,
        bpPfc: this.props.searchedData.bpPfc,
        checkless: this.props.searchedData.checkless,
        finAdj: this.props.searchedData.finAdj,
        payMos: this.props.searchedData.payMos,
        netChg: this.props.searchedData.netChg,
        mtcnReq: this.props.searchedData.mtcnReq,
        stgAgt: this.props.searchedData.stgAgt,
        bpFee: this.props.searchedData.bpFee,
        kioSkAllow: this.props.searchedData.kioSkAllow,
        netSet: this.props.searchedData.netSet,
        recMsg: this.props.searchedData.recMsg,
        favAgt: this.props.searchedData.favAgt,
        phyDel: this.props.searchedData.phyDel,
        bpAggr: this.props.searchedData.bpAggr,
        bpOfac: this.props.searchedData.bpOfac,
        flashCash: this.props.searchedData.flashCash,
        agtAdj: this.props.searchedData.agtAdj,
        pgpr: this.props.searchedData.pgpr,
        density: this.props.searchedData.density,
        dfReady: this.props.searchedData.dfReady,
        fuswbp: this.props.searchedData.fuswbp,
        fusDps: this.props.searchedData.fusDps,
        intchSetl: this.props.searchedData.intchSetl,
        netAdj: this.props.searchedData.netAdj,
        closeSp: this.props.searchedData.closeSp,
        openSp: this.props.searchedData.openSp,
        payRem: this.props.searchedData.payRem,
        qcshRfnd: this.props.searchedData.qcshRfnd,
        service1: this.props.searchedData.service1 ? this.props.searchedData.service1.match(/.{1,9}/g).join(' '): this.props.searchedData.service1,
        service2: this.props.searchedData.service2 ? this.props.searchedData.service2.match(/.{1,9}/g).join(' '): this.props.searchedData.service2,
        service3: this.props.searchedData.service3 ? this.props.searchedData.service3.match(/.{1,9}/g).join(' '): this.props.searchedData.service3,
        service4: this.props.searchedData.service4 ? this.props.searchedData.service4.match(/.{1,9}/g).join(' '): this.props.searchedData.service4,
        hoursOpenCloseMF: this.props.searchedData.hoursOpenCloseMF,
        sat: this.props.searchedData.sat,
        sun: this.props.searchedData.sun,
        contact: this.props.searchedData.contact,
        contactPhone: this.props.searchedData.contactPhone,
        companyName: this.props.searchedData.companyName,
        id: this.props.searchedData.id,
        network: this.props.searchedData.network,
        superAgent: this.props.searchedData.superAgent,
        securityFlag: this.props.searchedData.securityFlag,
        monitorFlag: this.props.searchedData.monitorFlag,
        checkControlLevel: this.props.searchedData.checkControlLevel,
        batches: this.props.searchedData.batches,
        lastReorder: this.props.searchedData.lastReorder,
        cksAvail: this.props.searchedData.cksAvail,
        used: this.props.searchedData.used,
        maxAmount: this.props.searchedData.maxAmount,
        encash: this.props.searchedData.encash,
        comments: this.props.searchedData.comments ,
        jwtToken: this.props.history.location.jwtToken,
        agtOpssndDtoList: this.props.searchedData.agtOpssndDtoList,      
      });
    };

  onHandleBackAgentRecordMTCNDetails = () => {

    let submittedObj = {};
    submittedObj.mtcn = this.state.mtcn;
    this.props.doSearchMtcn(submittedObj, "mtcn",this.state.jwtToken).then(() => {
      // console.log(this.props.searchedData);
      if (this.props.searchedData.mtcnFlag === 1) {
        this.props.history.push({
          pathname: "/mt-database-money-transfer-record",
          jwtToken: this.state.jwtToken   
        });
      } else {
        this.setState({
          error: true,
          errorMessage: "NO CURRENT MONEY RECORD",
        });
      }
      // this.onClearMessageAgentRecord();
    });
  };

  onHandleBackAgentRecordMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleSKRETFromMTCNAgentRecord = () => {

    // console.log(sessionStorage);
    this.props.history.push({
      pathname: "/money-transfer-search-key",
      searchKey: sessionStorage.searchKey,
      searchKeyValue:  sessionStorage.searchKeyValue,
      jwtToken: this.state.jwtToken
    });
  }

  // onClearMessageAgentRecord = () => {
  //   setTimeout(
  //     () =>
  //       this.setState({
  //         error: false,
  //         errorMessage: "",
  //       }),
  //     5000
  //   );
  // };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <h4 className="card-title main-heading">
            Money Transfer Database Agent Record
          </h4>
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body card-body-custom">
                 <div className="row mar-bottom5">
                  <div className="col-lg-6 font-size-13 text-center"><span className="font-weight-bold">MTCN : </span>{this.state.mtcn}</div>
                  <div className="col-lg-6 font-size-13 text-center"><span className="font-weight-bold">ACCOUNT : </span>{this.state.account}</div>
                </div>
                  <div className="card ">
                    <div className="card-body ">
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                        <span className="font-weight-bold">STATUS:</span> {this.state.status}
                        </div>
                        <div className="col-lg-3"><span className="font-weight-bold">RID : </span>{this.state.rid}</div>
                        <div className="col-lg-3"><span className="font-weight-bold">REV : </span>{this.state.rev}</div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">BINGO : </span>{this.state.bingo}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                        <span className="font-weight-bold">CLASS : </span>{this.state.class}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">CTL-OFF : </span>{this.state.ctlOff}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">RUC-NUMBER : </span>{this.state.rucNumber}
                        </div>
                        <div className="col-lg-3"><span className="font-weight-bold">SIN : </span>{this.state.sin}</div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                        <span className="font-weight-bold"> NAME : </span>{this.state.name}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">PHONE1 : </span>{this.state.phone1}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">ADDR1 : </span>{this.state.addr1}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">PHONE2 : </span>{this.state.phone2}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                        <span className="font-weight-bold">ADDR2 : </span>{this.state.addr2}
                        </div>
                        <div className="col-lg-3"><span className="font-weight-bold">CITY : </span>{this.state.city}</div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">ST-ZIP : </span>{this.state.stZip}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">COUNTRY : </span>{this.state.country}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div>
                          <span className="font-weight-bold">DEMOGRAPHIC AREA : </span>{this.state.demographicArea}
                          </div>
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">CSC Loc : </span>{this.state.cscLoc}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">Reg Set : </span>{this.state.regSet}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">D/T : </span>{this.state.dateTime}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-4">
                          <div><span className="font-weight-bold">T-LIMIT : </span>{this.state.tLimit}</div>
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CHG-SITE : </span>{this.state.chgSite}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CHG-TD : </span>{this.state.chgTd}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-4">
                          <div><span className="font-weight-bold">D-LIMIT : </span>{this.state.dLimit}</div>
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CHG-OPID : </span>{this.state.chgOpId}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">LAST-MOD-TD : </span>{this.state.lastModiTd}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-4">
                          <div><span className="font-weight-bold">DAILY-TOTAL : </span>{this.state.dailytotal}</div>
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MAX-MO : </span>{this.state.maxMo}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CURMO : </span>{this.state.curMo}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-4">
                          <div><span className="font-weight-bold">CPC Code : </span>{this.state.cpcCode}</div>
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CNTRY NAME : </span>{this.state.cntryName}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CURR NAME : </span>{this.state.currName}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-4">
                          <div><span className="font-weight-bold">DAILY-PAY : </span>{this.state.dailyPay}</div>
                        </div>
                        <div className="col-lg-4" />
                        <div className="col-lg-4" />
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-6">
                          <div><span className="font-weight-bold">REGION : </span>{this.state.region}</div>
                        </div>
                        <div className="col-lg-6">
                        <span className="font-weight-bold">TSOURCE : </span>{this.state.tSource}
                        </div>
                      </div>
                      
                        { this.state.firstChar === 'A' ? (
                        <>
                        <div className="row"> 
                         <div className="col-lg-6">
                         <span className="font-weight-bold">ROUTES : </span>{this.state.routes}
                         </div>
                         <div className="col-lg-6">
                         <span className="font-weight-bold">FLAT RATE : </span>{this.state.flatRate}
                         </div>
                       </div>
                       <div className="row">
                         {/* <div className="col-lg-1" /> */}
                         <div className="col-lg-6">
                           <div><span className="font-weight-bold">LRT : </span>{this.state.lrt}</div>
                         </div>
                         <div className="col-lg-6">
                         <span className="font-weight-bold">DLY OVRLMT BUMP : </span>{this.state.dailyOverlimitBump}
                         </div>
                         </div> 
                       <div className="row"> 
                         <div className="col-lg-6"><span className="font-weight-bold">RRT : </span>{this.state.rrt}</div>
                         <div className="col-lg-6">
                         <span className="font-weight-bold">D/T APPLD : </span>{this.state.dtAppld}
                         </div>
                       </div>
                       <div className="row">
                         {/* <div className="col-lg-1" /> */}
                         <div className="col-lg-6">
                           <div><span className="font-weight-bold">ART : </span>{this.state.art}</div>
                         </div>
                         <div className="col-lg-6">
                         <span className="font-weight-bold">DINERO PLUS : </span>{this.state.dineroPlus}
                         </div>
                         </div> 
                       <div className="row"> 
                         <div className="col-lg-6">
                         <span className="font-weight-bold">F/X SPRD SPL ACCT CD : </span>{this.state.fxSprdSplAcctCd}
                         </div>
                         <div className="col-lg-6"><span className="font-weight-bold">MEMO : </span>{this.state.memo}</div>
                       </div>
                       </>
                       ) : (
                         <>
                         <div className="row mar-bottom20"> 
                          <div className="col-lg-6">
                          <span className="font-weight-bold">SENDERS: </span>{this.state.fxSprdSplAcctCd}
                          </div>
                          <div className="col-lg-6"></div>
                        </div>                        
                         {this.state.agtOpssndDtoList &&
                            this.state.agtOpssndDtoList.map((data, i) => (
                              <div className="row"> 
                                <div className="col-lg-1 text-right">
                                  <span className="font-weight-bold">{i}: </span>
                                </div>
                                <div className="col-lg-4">
                                  <span className="font-weight-bold text-left">NAME: </span>{data.senderName}
                                </div>
                                <div className="col-lg-4">
                                  <span className="font-weight-bold">PHONE: </span>{data.senderPhone}
                                </div>
                                <div className="col-lg-3">
                                  <span className="font-weight-bold">CW: </span>{data.senderCW}
                                </div>
                              </div>
                            ))}
                         </>
                       )
                      }
                      
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">AGENT ID : </span>{this.state.agentId}</div>
                        </div>
                        <div className="col-lg-3"><span className="font-weight-bold">CLASS : </span>{this.state.class}</div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">DB RATE : </span>{this.state.dbRate}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">CR RATE : </span>{this.state.crRate}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                    <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">SERVICES : </span></div>
                        </div>                       
                      </div>                      
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                        <span className="font-weight-bold">AGT-NEW : </span>{this.state.agtNew}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">CAN/REF : </span>{this.state.canRef}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">UNPAY : </span>{this.state.unPay}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">REC-MOS : </span>{this.state.recMos}</div>
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">AGT-CHG : </span>{this.state.agtChg}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">WEV-VIEW : </span>{this.state.wevView}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">TXN-CAP : </span>{this.state.txnCap}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">BP-PFC : </span>{this.state.bpPfc}</div>
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">CHECKLESS : </span>{this.state.checkless}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">FIN-ADJ : </span>{this.state.finAdj}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">PAY-MOS : </span>{this.state.payMos}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">NET-CHG : </span>{this.state.netChg}</div>
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">MTCN-REQ : </span>{this.state.mtcnReq}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">STG-AGT : </span>{this.state.stgAgt}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">BP-FEE : </span>{this.state.bpFee}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">KIOSK-ALLOW : </span>{this.state.kioSkAllow}</div>
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">NET-SET : </span>{this.state.netSet}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">REC-MSG : </span>{this.state.recMsg}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">FAV-AGT : </span>{this.state.favAgt}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">PHY-DEL : </span>{this.state.phyDel}</div>
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">BP-AGGR : </span>{this.state.bpAggr}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">BP-OFAC : </span>{this.state.bpOfac}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">FLASH-CASH : </span>{this.state.flashCash}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">AGT-ADJ : </span>{this.state.agtAdj}</div>
                        </div>
                        <div className="col-lg-3"><span className="font-weight-bold">PGPR : </span>{this.state.pgpr}</div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">DENSITY : </span>{this.state.density}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">DF-READY : </span>{this.state.dfReady}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">FUS-WBP : </span>{this.state.fuswbp}</div>
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">FUS-DPS : </span>{this.state.fusDps}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">INTCH-SETL : </span>{this.state.intchSetl}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">NET-ADJ : </span>{this.state.netAdj}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">CLOSE SP : </span>{this.state.closeSp}</div>
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">OPEN SP : </span>{this.state.openSp}
                        </div>
                        <div className="col-lg-3">
                        <span className="font-weight-bold">PAY-REM : </span>{this.state.payRem}
                        </div>
                        <div className="col-lg-3"><span className="font-weight-bold">QCSH-RFND : </span>{this.state.qcshRfnd}</div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        <div className="col-lg-3" />
                        <div className="col-lg-5">
                          <div>&nbsp;123456789&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;123456789</div>
                        </div>
                        <div className="col-lg-3">&nbsp;123456789&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;123456789</div>
                        <div className="col-lg-1" />
                      </div>
                      <div className="row">
                        <div className="col-lg-2" />
                        <div className="col-lg-5">
                          <div><span className="font-weight-bold">SERVICE1 : </span>{this.state.service1}</div>
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">SERVICE2 : </span>{this.state.service2}
                        </div>
                        <div className="col-lg-1" />
                      </div>
                      <div className="row">
                        <div className="col-lg-2" />
                        <div className="col-lg-5">
                          <div><span className="font-weight-bold">SERVICE3 : </span>{this.state.service3}</div>
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">SERVICE4 : </span>{this.state.service4}
                        </div>
                        <div className="col-lg-1" />
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-6">
                          <div>
                          <span className="font-weight-bold">HOURS (OPEN-CLOSE) M-F : </span>{this.state.hoursOpenCloseMF}
                          </div>
                        </div>
                        <div className="col-lg-3"><span className="font-weight-bold">SAT : </span>{this.state.sat}</div>
                        <div className="col-lg-3"><span className="font-weight-bold">SUN : </span>{this.state.sun}</div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-1" /> */}
                        <div className="col-lg-4">
                          <div><span className="font-weight-bold">CONTACT : </span>{this.state.contact}</div>
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CONTACT PHONE : </span>{this.state.contactPhone}
                        </div>
                        <div className="col-lg-4" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card mar-top5">
                        <div className="card-body ">
                          <div className="row">
                            {/* <div className="col-lg-1" /> */}
                            <div className="col-lg-6">
                              <div><span className="font-weight-bold">COMPANY NAME : </span>{this.state.companyName}</div>
                            </div>
                            <div className="col-lg-6"><span className="font-weight-bold">ID : </span>{this.state.id}</div>
                          </div>
                          <div className="row">
                            {/* <div className="col-lg-1" /> */}
                            <div className="col-lg-6">
                              <div><span className="font-weight-bold">NETWORK : </span>{this.state.network}</div>
                            </div>
                            <div className="col-lg-6">
                            <span className="font-weight-bold">SUPER AGENT : </span>{this.state.superAgent}
                            </div>
                          </div>
                          <div className="row">
                            {/* <div className="col-lg-1" /> */}
                            <div className="col-lg-6">
                              <div><span className="font-weight-bold">SECURITY FLAG : </span>{this.state.securityFlag}</div>
                            </div>
                            <div className="col-lg-6">
                            <span className="font-weight-bold">MONITOR FLAG : </span>{this.state.monitorFlag}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="card mar-top5">
                        <div className="card-body ">
                          <div className="row">
                            {/* <div className="col-lg-1" /> */}
                            <div className="col-lg-6">
                              <div>
                              <span className="font-weight-bold">CHECK CONTROL LEVEL : </span>
                                {this.state.checkControlLevel}
                              </div>
                            </div>
                            <div className="col-lg-6">
                            <span className="font-weight-bold"># BATCHES : </span>{this.state.batches}
                            </div>
                          </div>
                          <div className="row">
                            {/* <div className="col-lg-1" /> */}
                            <div className="col-lg-6">
                              <div><span className="font-weight-bold">LAST RE-ORDER : </span>{this.state.lastReorder}</div>
                            </div>
                            <div className="col-lg-6">
                            <span className="font-weight-bold">CKS - AVAIL : </span>{this.state.cksAvail}
                            </div>
                          </div>
                          <div className="row">
                            {/* <div className="col-lg-1" /> */}
                            <div className="col-lg-6">
                              <div><span className="font-weight-bold">MAX AMT : </span>{this.state.maxAmount}</div>
                            </div>
                            <div className="col-lg-6">
                            <span className="font-weight-bold">ENCASH : </span>{this.state.encash}
                            </div>
                          </div>
                          <div className="row">
                            {/* <div className="col-lg-1" /> */}
                            <div className="col-lg-6">
                              <div><span className="font-weight-bold">USED : </span>{this.state.used}</div>
                            </div>
                            <div className="col-lg-6" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                       
                        <div className="col-lg-3">
                          <div><span className="font-weight-bold">COMMENTS : </span></div>
                        </div>
                        <div className="col-lg-8" />
                      </div>
                      {this.state.comments && <>
                        <div className="row">
                          <div className="col-lg"><span className="font-weight-bold">AANI</span></div>
                          <div className="col-lg"></div>
                          <div className="col-lg"><span className="font-weight-bold">STS</span></div>
                          <div className="col-lg"><span className="font-weight-bold">AANI</span></div>
                          <div className="col-lg"></div>
                          <div className="col-lg"><span className="font-weight-bold">STS</span></div>
                          <div className="col-lg"><span className="font-weight-bold">AANI</span></div>
                          <div className="col-lg"></div>
                          <div className="col-lg"><span className="font-weight-bold">STS</span></div>
                        </div>
                        <div className="row">
                            <div className="col-lg">
                            {this.state.comments[0][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[0][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[0][2]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[4][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[4][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[4][2]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[8][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[8][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[8][2]}
                            </div> 
                        </div>
                        <div className="row">
                            
                          <div className="col-lg">
                            {this.state.comments[1][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[1][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[1][2]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[5][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[5][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[5][2]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[9][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[9][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[9][2]}
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-lg">
                            {this.state.comments[2][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[2][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[2][2]}
                            </div>

                            <div className="col-lg">
                            {this.state.comments[6][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[6][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[6][2]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[10][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[10][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[10][2]}
                            </div> 
                        </div>
                        <div className="row">
                          <div className="col-lg">
                            {this.state.comments[3][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[3][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[3][2]}
                            </div>

                            <div className="col-lg">
                            {this.state.comments[7][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[7][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[7][2]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[11][0]}
                            </div> 
                            <div className="col-lg">
                              {this.state.comments[11][1]}
                            </div>
                            <div className="col-lg">
                            {this.state.comments[11][2]}
                            </div> 
                        </div>
                      </>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mar-top5 mar-bottom5 ">
                <button
                  type="button"
                  className="btn btn-warning mr-2"
                  onClick={this.onHandleBackAgentRecordMTCNDetails}
                  data-test="MTCNDetails-button-back"
                >
                  MTCN Details
                </button>
                <button
                  type="button"
                  className="btn btn-warning mar-right10"
                  onClick={this.onHandleSKRETFromMTCNAgentRecord}
                  data-test="MTCNDetails-sk-ret"
                  disabled = {sessionStorage.searchKey === ''}
                >
                  SK RET
                </button>
                <button
                  type="button"
                  className="btn btn-warning mar-right10"
                  onClick={this.onHandleBackAgentRecordMTCNSearch}
                  data-test="Search-button-back"
                >
                 SEARCH
                </button>
                {this.state.error && (
                  <div className="alert alert-danger alert-padding search-key-error">
                    {this.state.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToPropsAR = ({ MTCNSearchReducer }) => {
  return {
    searchedData: MTCNSearchReducer.dataInfo,
  };
};
export default connect(mapStateToPropsAR, { doSearchMtcn })(MoneyTransferDatabaseAgentRecord);
