import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../header/header";

export default class QueueMaintenance extends Component {

  onHandleQueueDetails = () => {
    this.props.history.push({pathname:"/queue-details",state:this.props.location.state});
  }

  onHandleGroupDetails = () => {
    this.props.history.push({pathname:"/group-details",state:this.props.location.state});
  }

  render() {
   
    return (
      <div data-test="queue-maintenance">
        <Header
          changeComponent={this.changeComponent}
          history={this.props.history}
          heading="	WU TMON FILE MAINTENANCE"
        />
        <div className="container-fluid">
          <h4 className="card-title main-heading ">
            QUEUE MAINTENANCE DETAILS
          </h4>
          <div className="row">
            <div className="col-lg-4" />
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body padding-bottom5 maintenance-menu-box">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.props.location.state.accessGrantedReadTMD1 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-queueDetailsD"
                          >
                           QUEUE DETAILS
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleQueueDetails}
                            data-test="button-queueDetailsE"
                          >
                            QUEUE DETAILS
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.props.location.state.accessGrantedReadTMD1 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-groupDetailsD"
                          >
                           GROUP DETAILS
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleGroupDetails}
                            data-test="button-groupDetailsE"
                          >
                            GROUP DETAILS
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <Link to="/tmon-maintenance-details"  className="btn btn-warning btn-block">TMON MAINTENANCE</Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4" />
          </div>
        </div>
      </div>
    );
  }
}
