import React, { Component } from "react";
import axios from "axios";
import { AgGridReact as AgGridReactATPD } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditATPD } from "react-icons/ai";
import { IconContext as IconContextATPD } from "react-icons";
import { getHeadersAgent } from "../../utility/utility";

class AgentThirdPartyDetails extends Component {
  state = {
    agentThirdPartyId: "",
    agentPrimAcct: "",
    agentProductCode: "",
    agentTPAcct: "",
    showAgentThirdPartyDetailsConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={
                () => {
                    if(!this.props.location.state.accessGrantedWriteTMD2) { 
                    this.setState({
                      submitStatusAgentThirdPartyDetails: 'Error',
                      submitMsgAgentThirdPartyDetails: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                    });
                  }else{
                    this.handleEditAgentThirdPartyDetails(params)
                  }  
              }
            }
            >
              <IconContextATPD.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditATPD />
              </IconContextATPD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      { field: "agentPrimAcct", headerName: "Agent Primary Account", width: "390px" },
      { field: "agentProductCode", headerName: "Agent Product Code", width: "380px" },
      { field: "agentTPAcct", headerName: "Agent Third Party Account", width: "380px" },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValAgent: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusAgentThirdPartyDetails: null,
    submitMsgAgentThirdPartyDetails: "",
    paginationOptionAgentThirdPartyDetails: [25, 50, 100, 500],
    editable: true,
    overlayLoadingTemplateATPD:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateATPD: '<span style="">No Records Found</span>',
  };

  onChangeControlAgentThirdPartyDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlAgentThirdPartyDetailsCharNumb = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onChangeControlAgentThirdPartyDetailsNumbCharAndSpclChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9/()-."]/, ""),
    });
  };

  onGridReadyAgentThirdPartyDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/tmonfilemaintenance/agent`, {
        headers: {
          ...getHeadersAgent().headers,
          Authorization: this.props.location.state.token,
        },
      })
      .then((response) => {
        //console.log(response.data);
        this.setState({
          rows: response.data.records,
        });
      });
  };

  handleEditAgentThirdPartyDetails = (params) => {
    let data = params.node.data;
    this.setState({
      agentThirdPartyId: data.agentThirdPartyId,
      agentPrimAcct: data.agentPrimAcct,
      agentProductCode: data.agentProductCode,
      agentTPAcct: data.agentTPAcct,
      enableBtn: true,
      editable: false,
    });
  };

  onPageSizeChangedAgentThirdPartyDetails = (e) => {
    this.setState({ paginationValAgent: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackAgentThirdPartyDetails = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  onValidateAgentThirdPartyDetails = () => {
    if (this.state.agentPrimAcct === "") {
      this.setState({
        submitStatusAgentThirdPartyDetails: "Error",
        submitMsgAgentThirdPartyDetails: "Please Enter The Value For Agent Primary Account",
      });
      return false;
    }
    if (this.state.agentProductCode === "") {
      this.setState({
        submitStatusAgentThirdPartyDetails: "Error",
        submitMsgAgentThirdPartyDetails: "Please Enter The Value For Agent Product Code ",
      });
      return false;
    }
    if (this.state.agentTPAcct === "") {
      this.setState({
        submitStatusAgentThirdPartyDetails: "Error",
        submitMsgAgentThirdPartyDetails:
          "Please Enter The Value For Agent Third Party Account",
      });
      return false;
    }
    return true;
  };

  onHandleAddAgentThirdPartyDetails = () => {
    if (!this.onValidateAgentThirdPartyDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.agentThirdPartyId = "";
    submittedObj.agentPrimAcct = this.state.agentPrimAcct;
    submittedObj.agentProductCode = this.state.agentProductCode;
    submittedObj.agentTPAcct = this.state.agentTPAcct;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/tmonfilemaintenance/agent`, submittedObj,  {
        headers: {
          ...getHeadersAgent().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.agentThirdPartyId =
            this.state.agentPrimAcct + "" + this.state.agentProductCode;
          this.setState({
            submitStatusAgentThirdPartyDetails: "OK",
            submitMsgAgentThirdPartyDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusAgentThirdPartyDetails: "Error",
            submitMsgAgentThirdPartyDetails: response.data.responseString,
          });
          
        }
        this.onHandleClearAgentThirdPartyDetails();
      });
  };

  onHandleUpdateAgentThirdPartyDetails = () => {
    if (!this.onValidateAgentThirdPartyDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.agentThirdPartyId = this.state.agentThirdPartyId;
    submittedObj.agentPrimAcct = this.state.agentPrimAcct;
    submittedObj.agentProductCode = this.state.agentProductCode;
    submittedObj.agentTPAcct = this.state.agentTPAcct;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/tmonfilemaintenance/agent`, submittedObj,   {
        headers: {
          ...getHeadersAgent().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.agentPrimAcct + "" + row.agentProductCode ===
              submittedObj.agentThirdPartyId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusAgentThirdPartyDetails: "OK",
            submitMsgAgentThirdPartyDetails: response.data.responseString,
            rows: updatedRows,
          });
         
        } else {
          this.setState({
            submitStatusAgentThirdPartyDetails: "Error",
            submitMsgAgentThirdPartyDetails: response.data.responseString,
          });
        }
        this.onHandleClearAgentThirdPartyDetails();
      });
  };

  onHandleClearAgentThirdPartyDetails = () => {
    this.setState({
      agentThirdPartyId: "",
      agentPrimAcct: "",
      agentProductCode: "",
      agentTPAcct: "",
      enableBtn: false,
      editable: true,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusAgentThirdPartyDetails: null,
        }),
      5000
    );
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="	WU TMON FILE MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                AGENT THIRD PARTY DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  AGENT THIRD PARTY FORM
                </div>
                <div className="card-body card-body-custom">
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="agentPrimAcct" className="font-weight-bold">
                        Agent Primary Account
                      </label>
                      <input
                        type="text"
                        maxlength="9"
                        value={this.state.agentPrimAcct}
                        className="form-control form-control-sm"
                        name="agentPrimAcct"
                        onChange={(e) => {
                          this.onChangeControlAgentThirdPartyDetailsNumbCharAndSpclChar(
                            e
                          );
                        }}
                        data-test="text-agentPrimAcct"
                        placeholder="Agent Primary Account "
                        readOnly={!this.state.editable}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="agentProductCode" className="font-weight-bold">
                        Agent Product Code
                      </label>
                      <input
                        type="text"
                        maxlength="4"
                        value={this.state.agentProductCode}
                        className="form-control form-control-sm"
                        name="agentProductCode"
                        onChange={(e) => {
                          this.onChangeControlAgentThirdPartyDetailsCharNumb(e);
                        }}
                        data-test="text-agentProductCode"
                        placeholder="Agent Product Code"
                        readOnly={!this.state.editable}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="agentTPAcct" className="font-weight-bold">
                        Agent Third Party Account
                      </label>
                      <input
                        type="text"
                        maxlength="9"
                        value={this.state.agentTPAcct}
                        className="form-control form-control-sm"
                        name="agentTPAcct"
                        onChange={(e) => {
                          this.onChangeControlAgentThirdPartyDetails(e);
                        }}
                        data-test="text-agentTPAcct"
                        placeholder="Agent Third Party Account"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackAgentThirdPartyDetails}
                        data-test="button-back"
                      >
                        TMON MAINT
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddAgentThirdPartyDetails}
                        data-test="button-add"
                        disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateAgentThirdPartyDetails}
                        data-test="button-update"
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearAgentThirdPartyDetails}
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        CLEAR
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                          {this.state.submitStatusAgentThirdPartyDetails ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgAgentThirdPartyDetails}
                            </div>
                          )}
                          {this.state.submitStatusAgentThirdPartyDetails ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgAgentThirdPartyDetails}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div> 
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </div>
              </div>
              {/* {this.getAgentThirdPartyDetailsModal()} */}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  AGENT THIRD PARTY DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlAgentThirdPartyDetails(e);
                        this.onPageSizeChangedAgentThirdPartyDetails(e);
                      }}
                      value={this.state.paginationValNetwork}
                      name="paginationDropdownAgent"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionAgentThirdPartyDetails.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactATPD
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyAgentThirdPartyDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateATPD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateATPD
                      }
                    ></AgGridReactATPD>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AgentThirdPartyDetails;
