import React, { Component } from "react";
import Header from "../../header/header";
import axios from "axios";
import { getHeadersOrder } from "../../utility/utility";

class MoneyTransferMtub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      singleData: {},
      mtcn: "",
      count: 0,
      enablePrev: false,
      enableNext: true,
      mtubbuffPars: "",
      mtmtubMsg: ''
    };
  }

  componentDidMount() {
    // console.log(this.props.history.location.jwtToken);
    this.setState({
      mtcn: this.props.history.location.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
    let submittedObj = {};
    submittedObj.mtcn = this.props.history.location.mtcn;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/mtubbuff`,
        submittedObj,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.history.location.jwtToken
          },
        }
      )
      .then((response) => {
        let tempEnableNext = (response.data.records !== null && response.data.records.length) > 1 ? true : false;
        this.setState({
          enableNext: tempEnableNext
        });
     
        if (response.data.flag === 1) {
          this.setState({
            allData: response.data.records,
            singleData: response.data.records[0],
            mtubbuffPars: response.data.records[0].mtubbuffPars,
          });
        } else {
          this.setState({
            mtmtubMsg: response.data.message
          });
        }
      });
  }

  onHandleBackMtubMTCNDetails = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackMtubMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  onHandleBufferData = (type) => {
    if (type === "prev") {
      this.setState(
        {
          count: this.state.count - 1,
        },
        () => {
          this.setState({
            enableNext: true,
            singleData: this.state.allData[this.state.count],
            mtubbuffPars: this.state.allData[this.state.count].mtubbuffPars,
          });
          if (this.state.count === 0) {
            this.setState({
              enablePrev: false,
              enableNext: true,
            });
          }
        }
      );
    } else {
      if (this.state.count < this.state.allData.length - 1) {
        this.setState(
          {
            count: this.state.count + 1,
          },
          () => {
            this.setState({
              enablePrev: true,
              singleData: this.state.allData[this.state.count],
              mtubbuffPars: this.state.allData[this.state.count].mtubbuffPars,
            });
            if (this.state.count === this.state.allData.length - 1) {
              this.setState({
                enablePrev: true,
                enableNext: false,
              });
            }
          }
        );
      }
    }
  };

  render() {
    // console.log(this.state.singleData)
    return (
      <div>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <div className="card-body">
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                Money Transfer MTUB Buffer Dump
              </div>
              <div className="card-body card-body-custom">
                <div className="form-row">
                  <div className="col-lg-2">
                  <span className="font-weight-bold">MTCN :</span>{this.state.mtcn}
                  </div>
                  {/* { Object.keys(this.state.singleData).length > 0 && */}
                  <>
                  <div className="col-lg-2">
                  <span className="font-weight-bold">TS :</span> {this.state.singleData.mtub_date_time}
                  </div>
                  <div className="col-lg">
                  <span className="font-weight-bold">TYPE :</span> {this.state.singleData.mtub_type}
                  </div>
                  <div className="col-lg">
                  <span className="font-weight-bold">OPER :</span> {this.state.singleData.operId}
                  </div>
                  <div className="col-lg">
                  <span className="font-weight-bold">TERM :</span> {this.state.singleData.termId}
                  </div>
                  <div className="col-lg">
                  <span className="font-weight-bold">SITE :</span> {this.state.singleData.siteId}
                  </div>
                   {/* <div className="col-lg">
                   <label>LEN: {this.state.singleData.length}</label> 
                  </div>*/}
                  <div className="col-lg">
                  <span className="font-weight-bold">ENCRYPTED :</span>  {this.state.singleData.encrypted}
                  </div>
                  </>
                  {/* } */}
                  </div>
                  {/* { Object.keys(this.state.mtubbuffPars).length > 0 &&   */}
                <div className="card">
                  <div className="card-header bg-dark text-white card-header-custom">
                    MTUB Buffer Details
                  </div>
                  <div className="card-body card-body-custom">
                    {this.state.singleData.buffData}
                  </div>
                </div>
                  {/* } */}
                <div className="text-center mt-2">
                  <button
                    type="button"
                    className="btn btn-warning mr-2"
                    onClick={() => this.onHandleBufferData("prev")}
                    data-test="MTCNDetails-button-prev"
                    disabled={!this.state.enablePrev}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mr-2"
                    onClick={() => this.onHandleBufferData("next")}
                    data-test="MTCNDetails-button-tcn"
                    disabled={!this.state.enableNext}
                  >
                    Next
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mr-2"
                    onClick={this.onHandleBackMtubMTCNDetails}
                    data-test="MTCNDetails-button-back"
                  >
                    MTCN Details
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackMtubMTCNSearch}
                    data-test="Search-button-back"
                  >
                    SEARCH
                  </button>
                  {this.state.mtmtubMsg !== '' && <div className="row"><div className="col-lg text-center mar-top5 font-weight-bold">{this.state.mtmtubMsg}</div></div>}
                </div>
                {/* {Object.keys(this.state.mtubbuffPars).length > 0 ?  */}
                <div className="card mar-top5">
                  <div className="card-body mtcn-mtub-data card-body-custom">
                    <div className="table-responsive">
                      <table className="table table-bordered margin-0 table-sm">
                        <thead>
                          <tr>
                            <th scope="col">FIELD ID</th>
                            <th scope="col">FIELD DATA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.mtubbuffPars &&
                            this.state.mtubbuffPars.map((data, i) => (
                              <tr key={`${i}+len`}>
                                <td>{data.name}</td>
                                <td>{data.type}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* {this.state.mtmtubMsg !== '' && <div className="row"><div className="col-lg text-center mar-top5">{this.state.mtmtubMsg}</div></div>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MoneyTransferMtub;
