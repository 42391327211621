import React, { Component } from "react";
import Logo from "../../Asset/wuLogo.png";
import { withOktaAuth } from "@okta/okta-react";
import IdleTimer from "react-idle-timer";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineLogout,AiOutlineHome } from "react-icons/ai";
import { IconContext } from "react-icons";
import axios from "axios";
import { getHeadersLogin } from "../utility/utility";

class Header extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      userName: "",
      timeout: 1000 * 60 * 30,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      firedFirstTime: true,
      isLoggedIn: false
    };
  }
 async componentDidMount(){
    
    let currentComponent = this;
    //console.log(this.props)
    const accessToken = this.props.authState.accessToken;
    if (accessToken) {
      try {
        // const token = jwt_decode(accessToken.value);
        // console.log("sessionStorage.userName",sessionStorage.userName)
        // if(sessionStorage.userName === '') {
          if(localStorage.userName === '') {
         await this.props.oktaAuth.token
          .getUserInfo()
          .then(function (response) {
            const name = response.name;
            localStorage.userName = name;
             currentComponent.setState({ userName: name });
             let submittedObjLogin = {};
             submittedObjLogin.userId = response.preferred_username.substring(0,8);
             submittedObjLogin.timestamp = "";
          
            axios.post(`${process.env.REACT_APP_DB_VIEW_BASE_TIPLOG_SERVICE_URL}/v1/sha/dbview/tiplog/LoginLog`,submittedObjLogin, {headers: {...getHeadersLogin().headers,Authorization: accessToken.accessToken,userId: submittedObjLogin.userId},})
            .then(() => {});
        
          });


       }
       
          // console.log(getUser,token)
        // valid token format
      } catch (error) {
        // invalid token format
      }
    }
  }
  onTimeOut = () => {
    return (
      <div>
        <Modal
          show={this.state.showModal && this.props.authState.isAuthenticated}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                You are inactive for long time
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() => this.logout()}
                data-test="button-no"
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onAction = () => {
    this.setState({ isTimedOut: false });
  };

  onActive = () => {
    this.setState({ isTimedOut: false });
  };

  onIdle = () => {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.onTimeOut();
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  };
  onHandleBack = () => {
    this.props.history.push("/");
  };
  async login() {
    localStorage.userName = "";
    this.setState({isLoggedIn:true})
    await this.props.oktaAuth.signInWithRedirect();
    // window.location.reload()
  }

  async logout() {
    this.setState({isLoggedIn:false})
    localStorage.userName = "";
    await this.props.oktaAuth.signOut("/");
    await this.props.oktaAuth.tokenManager.clear();

  }
        
  render() {
  //  console.log('localStorage',localStorage.userName)
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
          data-test="button-timeout"
        />
        {this.onTimeOut()}
        <nav className="navbar navbar-dark bg-dark">
          <div className="nav-logo">
          <img
            src={Logo}
            width="120"
            height="50"
            className="cursor-pointer"
            alt="Home"
            onClick={this.onHandleBack}
            data-test="button-back"
          />
            <IconContext.Provider
              value={{ color: "#FDD017", size: "30px"}}
              style={{  cursor:"pointer" }}
            >
              <AiOutlineHome
                onClick={this.onHandleBack}
                data-test="button-home-icon"
              />
            </IconContext.Provider>
          </div>
          <div className="nav-env-name">
            <h4 className="heading-header">{process.env.REACT_APP_DB_VIEW_ENV}</h4>
          </div>
         
          <div className="nav-login-details">
          {this.props.authState.isAuthenticated ? (
            <>
              <label className="logged-in-user">{localStorage.userName !== ''? localStorage.userName: this.state.userName }</label>

              {/* <div className="cursor-pointer"> */}
                <IconContext.Provider
                  value={{ color: "#FDD017", size: "30px" }}
                >
                  <AiOutlineLogout
                    onClick={this.logout}
                    data-test="button-logoff"
                  />
                </IconContext.Provider>
              {/* </div> */}
            </>
          ) : (
            <button
              className="btn btn-warning"
              onClick={this.login}
              data-test="button-login"
            >
              LOGIN{" "}
            </button>
          )}
          </div>
         
        </nav>
      </div>        
    );
  }
}

export default withOktaAuth(Header);
