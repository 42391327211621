import React from "react";
import { connect } from "react-redux";
import Header from "../../header/header";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import { doSearchMtcn } from "../../../actions/mtcn-search/mtcn-search-action";

class MoneyTransferDatabaseUtility extends React.Component {
  state = {
    mtcn: "",
    agentAccountNumber: "",
    accountId: "",
    recordingAgentAccount: "",
    recordingDate: "",
    payeeName: "",
    senderName: "",
    callingPhone: "",
    // unpaidPayee: "",
    // unpaidSender: "",
    // unpaidCallingPhone: "",
    controlNumber: "",
    // dbk: "",
    // dbkType: "",
    type: "",
    phone: "",
    principle1: "",
    pinNumber: "",
    wuCard: "",
    principle2: "",
    error: false,
    errorMessage: "",
    isLoading: false
  };

  componentDidMount() {
   

    sessionStorage.searchKey  = "";
    sessionStorage.searchKeyValue  = "";
    const accessToken = this.props.authState.accessToken;
    const jwt = this.props.authState.accessToken.value;
    try {
      const token = jwt_decode(accessToken.value);
      const accessRole = jwt_decode(accessToken.value).role;
      // console.log("hello");
      // console.log(token);
      // console.log(accessRole);
      const accessGrant =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_MTUTILITY) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS);
       
      this.setState({
        jwtToken:jwt,
        accessToken: token,
        role: accessRole,
        accessGranted: accessGrant,
      }, () => {
        if(!this.state.accessGranted) { 
          this.setState({
            error: true,
            errorMessage: "Unauthorized!.Access is denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }


  onHandleSearchMTCN = (mtcnEvent) => {

    if(mtcnEvent) {
      mtcnEvent.preventDefault();
    }

    if (
      this.state.mtcn === "" &&
      this.state.agentAccountNumber === "" &&
      this.state.accountId === "" &&
      this.state.recordingAgentAccount === "" &&
      this.state.recordingDate === "" &&
      this.state.payeeName === "" &&
      this.state.senderName === "" &&
      this.state.callingPhone === "" &&
      // this.state.unpaidPayee === "" &&
      // this.state.unpaidSender === "" &&
      // this.state.unpaidCallingPhone === "" &&
      this.state.controlNumber === "" &&
      // this.state.dbk === "" &&
      // this.state.dbkType === "" &&
      this.state.type === "" &&
      this.state.phone === "" &&
      this.state.principle1 === "" &&
      this.state.pinNumber === "" &&
      this.state.wuCard === "" &&
      this.state.principle2 === ""
    ) {
      this.setState({
        error: true,
        errorMessage: "Please enter value in one of the fields",
      });
    }  else {
      let submittedObj = {};
      let errorMessageTemp = "";
      let fired = false;
      let group = "";

      if (this.state.mtcn !== "" && this.state.mtcn.length === 16) {
        submittedObj.mtcn = this.state.mtcn;
        group = "mtcn";
        fired = true;
      } else if(this.state.mtcn !=='') {
        errorMessageTemp = "MTCN should be of 16 characters";
      }

      if (this.state.agentAccountNumber !== "" && !fired) {
        submittedObj.searchKey = "agentAccountNumber";
        submittedObj.searchKeyValue = this.state.agentAccountNumber;
        group = "agentAccountNumber";
      } 

      // Account Id
      // if (this.state.accountId !== "" && !fired) {
      //   submittedObj.searchKey = "accountId";
      //   submittedObj.searchKeyValue = this.state.accountId;
      //   group = "skrecord";
      //   fired = true;
      // }

      // Recording Agent Acct && Recording Date
      if (this.state.recordingAgentAccount !== "" && !fired) {
        if (this.state.recordingDate !== "") {
          submittedObj.searchKey = "recAgtAcc";
          submittedObj.searchKeyValue = this.state.recordingAgentAccount+"\\"+this.state.recordingDate;
          submittedObj.pageNumber = 1;
          submittedObj.mtcn = "";
          fired = true;
          group = "skrecord";
        } else {
          errorMessageTemp =  "Please enter valid Recording Date";
        }
      }
      if ((this.state.recordingDate !== "")  && !fired) {
        if (this.state.recordingAgentAccount !== "") {
          submittedObj.searchKey = "recAgtAcc";
          submittedObj.searchKeyValue = this.state.recordingAgentAccount+"\\"+this.state.recordingDate;
          submittedObj.pageNumber = 1;
          submittedObj.mtcn = "";
          fired = true;
          group = "skrecord";
        } else {
          errorMessageTemp =  "Please enter valid Recording Agent Account";
        }
      }


      // Payee Name
      if (this.state.payeeName !== "" && !fired) {
        submittedObj.searchKey = "payeeName";
        submittedObj.searchKeyValue = this.state.payeeName;
        submittedObj.pageNumber = 1;
        submittedObj.mtcn = "";
        group = "skrecord";
        fired = true;
      }

      // Sender Name
      if (this.state.senderName !== "" && !fired) {
        submittedObj.searchKey = "senderName";
        submittedObj.searchKeyValue = this.state.senderName;
        submittedObj.pageNumber = 1;
        submittedObj.mtcn = "";
        group = "skrecord";
        fired = true;
      }

      // Calling Phone
      if (this.state.callingPhone !== "" && !fired) {
        submittedObj.searchKey = "callingPhone";
        submittedObj.searchKeyValue = this.state.callingPhone;
        submittedObj.pageNumber = 1;
        submittedObj.mtcn = "";
        group = "skrecord";
        fired = true;
      }

      // Unpaid Payee
      // if (this.state.unpaidPayee !== "" && !fired) {
      //   submittedObj.searchKey = "unpaidPayee";
      //   submittedObj.searchKeyValue = this.state.unpaidPayee;
      //   group = "skrecord";
      //   fired = true;
      // }

      // Unpaid Sender
      // if (this.state.unpaidSender !== "" && !fired) {
      //   submittedObj.searchKey = "unpaidSender";
      //   submittedObj.searchKeyValue = this.state.unpaidSender;
      //   group = "skrecord";
      //   fired = true;
      // }

      // Unpaid Calling Phone
      // if (this.state.unpaidCallingPhone !== "" && !fired) {
      //   submittedObj.searchKey = "unpaidCallingPhone";
      //   submittedObj.searchKeyValue = this.state.unpaidCallingPhone;
      //   group = "skrecord";
      //   fired = true;
      // }

      // Control Number
      if (this.state.controlNumber !== "" && !fired) {
        submittedObj.searchKey = "controlNumber";
        submittedObj.searchKeyValue = this.state.controlNumber;
        submittedObj.pageNumber = 1;
        submittedObj.mtcn = "";
        group = "skrecord";
        fired = true;
      }

      //Pinnumber & type
      if (this.state.pinNumber !== "" && !fired) {
        if (this.state.type !== "") {
          submittedObj.pinNumber = this.state.pinNumber;
          submittedObj.pinType = this.state.type;
          fired = true;
          group = "pinNumber";
        } else {
          errorMessageTemp = "Please enter valid type";
        }
      }

      if (this.state.type !== "" && !fired) {
        if (this.state.pinNumber !== "") {
          submittedObj.pinNumber = this.state.pinNumber;
          submittedObj.pinType = this.state.type;
          fired = true;
          group = "pinNumber";
        } else {
          errorMessageTemp = "Please enter valid pin number";
        }
      }

      // Phone & Principle1
      if (this.state.phone !== "" && !fired) {
        if (this.state.principle1 !== "") {
          submittedObj.phone = this.state.phone;
          submittedObj.phonePrincipal = this.state.principle1;
          fired = true;
          group = "phoneNumber";
        } else {
          errorMessageTemp =  "Please enter principle";
        }
      }

      if (this.state.principle1 !== "" && !fired) {
        if (this.state.phone !== "") {
          submittedObj.phone = this.state.phone;
          submittedObj.phonePrincipal = this.state.principle1;
          fired = true;
          group = "phoneNumber";
        } else {
          errorMessageTemp =  "Please enter phone";
        }
      }

      // WUCard & Principle2
      if (this.state.wuCard !== "" && !fired) {
        if (this.state.principle2 !== "") {
          submittedObj.wuCard = this.state.wuCard;
          submittedObj.wuCardPrincipal = this.state.principle2;
          // fired = true;
          group = "wuCard";
        } else {
          errorMessageTemp = "Please enter principle";
        }
      }

      if (this.state.principle2 !== "" && !fired) {
        if (this.state.wuCard !== "") {
          submittedObj.wuCard = this.state.wuCard;
          submittedObj.wuCardPrincipal = this.state.principle2;
          // fired = true;
          group = "wuCard";
        } else {
          errorMessageTemp = "Please enter Wu Card";
        }
      }

      if (Object.keys(submittedObj).length > 0 && errorMessageTemp === '') {

        // Loading Spinner Enabled
        this.setState({isLoading:true})
        this.props.doSearchMtcn(submittedObj, group, this.state.jwtToken).then(() => {
           //Loading Spinner Disabled
          this.setState({isLoading:false})
          // console.log(this.props.searchedData);
          if (this.props.searchedData.mtcnFlag === 1 || this.props.searchedData.skFlag === 1 || parseInt(this.props.searchedData.agentFlag) === 1 || parseInt(this.props.searchedData.flag) === 1) {
            let pathNameVal = "";
            if (group === "mtcn" || group === "pinNumber") {
              pathNameVal = "/mt-database-money-transfer-record";
            } else if (group === "skrecord") {
              sessionStorage.searchKey = submittedObj.searchKey;
              sessionStorage.searchKeyValue = submittedObj.searchKeyValue;
              sessionStorage.pageNumber = submittedObj.pageNumber;
              pathNameVal = "/money-transfer-search-key";
            } else if (group === "agentAccountNumber") {
               pathNameVal = "/money-transfer-database-agent-record";
            } else if (group === "phoneNumber" || group === "wuCard") {
              pathNameVal = "/mtcn-list";
           }
            
            this.props.history.push({
              pathname: pathNameVal,
              jwtToken: this.state.jwtToken,
              fromSearch: 1,
              accessGranted: this.state.accessGranted
            });
            this.setState({
              error: false,
              errorMessage: "",
            });
          } else {
            let message = this.props.searchedData.message ? this.props.searchedData.message : "Error occurs while processing request. Please connect with helpdesk.";
            
            this.setState({
              error: true,
              errorMessage: message,
              mtcn: '',
              agentAccountNumber: '',
              recordingAgentAccount: '',
              recordingDate: '',
              recor: '',
              payeeName: '',
              senderName: '',
              controlNumber:'',
              pinNumber: '',
              type: '',
              phone: '',
              principle1: '',
              wuCard: '',
              principle2: ''
            });
          }
        });
      } else {
        this.setState({
          error: true,
          errorMessage: errorMessageTemp
        });
      }
    }
  };
  onChangeControlOnlyNumberMTCNSearch = (e) => {
    let regex = /[^\d]/;
    if (!regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onChangeControlOnlyCharacterMTCNSearch = (e) => {
    let regex = /[^a-zA-Z]/;
    if (!regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onChangeControlOnlyCharacterWithSpaceAndFrontSlashMTCNSearch = (e) => {
    let regex = /[^a-zA-Z\s\\]/;
    if (!regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onChangeControlCharacterNumberMTCNSearch = (e) => {
    let regex = /[^a-zA-Z0-9]/;
    if (!regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onChangeControlDecimalMTCNSearch = (e) => {
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onChangeControlMTCNSearch = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlCN = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[-\s]/g, "").replace(/[^\d]/, ""),
    });
  };

  render() {
    return this.props.authState.isAuthenticated ? (
      <>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">
              {" "}
              MONEY TRANSFER DATABASE UTILITY
            </h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                  MONEY TRANSFER SEARCH DETAILS
              </div>
              <div className="card-body card-body-custom">
                <form onSubmit={this.onHandleSearchMTCN}>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="mtcn" className="font-weight-bold">MTCN</label>
                      <input
                        type="text"
                        maxLength="16"
                        value={this.state.mtcn}
                        className="form-control form-control-sm"
                        name="mtcn"
                        onChange={(e) => {
                          this.onChangeControlOnlyNumberMTCNSearch(e);
                        }}
                        data-test="text-mtcn"
                        placeholder="MTCN"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="agentAccountNumber" className="font-weight-bold">
                        Agent Account Number
                      </label>
                      <input
                        type="text"
                        maxLength="24"
                        value={this.state.agentAccountNumber}
                        className="form-control form-control-sm"
                        name="agentAccountNumber"
                        onChange={(e) => {
                          this.onChangeControlCharacterNumberMTCNSearch(e);
                        }}
                        data-test="text-agentAccountNumber"
                        placeholder="Agent Account Number"
                      />
                    </div>
                    {/* <div className="col-lg">
                      <label htmlFor="mtcn">Account ID</label>
                      <input
                        type="text"
                        maxLength="9"
                        value={this.state.accountId}
                        className="form-control form-control-sm"
                        name="accountId"
                        onChange={(e) => {
                          this.onChangeControlCharacterNumberMTCNSearch(e);
                        }}
                        data-test="text-accountId"
                        placeholder="Account ID"
                        disabled = {true}
                      />
                    </div> */}
                    <div className="col-lg">
                      <label htmlFor="recordingAgentAccount" className="font-weight-bold">
                        Recording Agent Acct
                      </label>
                      <input
                        type="text"
                        maxLength="24"
                        value={this.state.recordingAgentAccount}
                        className="form-control form-control-sm"
                        name="recordingAgentAccount"
                        onChange={(e) => {
                          this.onChangeControlCharacterNumberMTCNSearch(e);
                        }}
                        data-test="text-recordingAgentAccount"
                        placeholder="Recording Agent Acct"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="recordingDate" className="font-weight-bold">Recording Date</label>
                      <input
                        type="date"
                        value={this.state.recordingDate}
                        className="form-control form-control-sm"
                        name="recordingDate"
                        onChange={(e) => {
                          this.onChangeControlMTCNSearch(e);
                        }}
                        data-test="text-recordingDate"
                        placeholder="Recording Date"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="payeeName" className="font-weight-bold">*Payee Name</label>
                      <input
                        type="text"
                        maxLength="84"
                        value={this.state.payeeName}
                        className="form-control form-control-sm"
                        name="payeeName"
                        onChange={(e) => {
                          this.onChangeControlOnlyCharacterWithSpaceAndFrontSlashMTCNSearch(
                            e
                          );
                        }}
                        data-test="text-payeeName"
                        placeholder="Payee Name"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="senderName" className="font-weight-bold">*Sender Name</label>
                      <input
                        type="text"
                        maxLength="84"
                        value={this.state.senderName}
                        className="form-control form-control-sm"
                        name="senderName"
                        onChange={(e) => {
                          this.onChangeControlOnlyCharacterWithSpaceAndFrontSlashMTCNSearch(
                            e
                          );
                        }}
                        data-test="text-senderName"
                        placeholder="Sender Name"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="callingPhone" className="font-weight-bold">**Calling Phone</label>
                      <input
                        type="text"
                        maxLength="15"
                        value={this.state.callingPhone}
                        className="form-control form-control-sm"
                        name="callingPhone"
                        onChange={(e) => {
                          this.onChangeControlOnlyNumberMTCNSearch(e);
                        }}
                        data-test="text-callingPhone"
                        placeholder="Calling Phone"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="controlNumber" className="font-weight-bold">Control Number</label>
                      <input
                        type="text"
                        maxLength="16"
                        value={this.state.controlNumber}
                        className="form-control form-control-sm"
                        name="controlNumber"
                        onChange={(e) => {
                          this.onChangeControlCN(e)
                          // this.onChangeControlOnlyNumberMTCNSearch(e);
                        }}
                        data-test="text-controlNumber"
                        placeholder="Control Number"
                      />
                    </div>
                    {/* <div className="col-lg">
                      <label htmlFor="unpaidPayee">*Unpaid Payee</label>
                      <input
                        type="text"
                        maxLength="40"
                        value={this.state.unpaidPayee}
                        className="form-control form-control-sm"
                        name="unpaidPayee"
                        onChange={(e) => {
                          this.onChangeControlOnlyCharacterWithSpaceAndFrontSlashMTCNSearch(
                            e
                          );
                        }}
                        data-test="text-unpaidPayee"
                        placeholder="Unpaid Payee"
                      />
                    </div> */}
                    {/* <div className="col-lg">
                      <label htmlFor="unpaidSender">*Unpaid Sender</label>
                      <input
                        type="text"
                        maxLength="40"
                        value={this.state.unpaidSender}
                        className="form-control form-control-sm"
                        name="unpaidSender"
                        onChange={(e) => {
                          this.onChangeControlOnlyCharacterWithSpaceAndFrontSlashMTCNSearch(
                            e
                          );
                        }}
                        data-test="text-unpaidSender"
                        placeholder="Unpaid Sender"
                      />
                    </div> */}
                  </div>
                  <div className="form-group row">
                    {/* <div className="col-lg">
                      <label htmlFor="unpaidCallingPhone">
                        **Unpaid Calling Phone
                      </label>
                      <input
                        type="text"
                        maxLength="15"
                        value={this.state.unpaidCallingPhone}
                        className="form-control form-control-sm"
                        name="unpaidCallingPhone"
                        onChange={(e) => {
                          this.onChangeControlOnlyNumberMTCNSearch(e);
                        }}
                        data-test="text-unpaidCallingPhone"
                        placeholder="Unpaid Calling Phone"
                      />
                    </div> */}
                    {/* <div className="col-lg">
                      <label htmlFor="controlNumber">Control Number</label>
                      <input
                        type="text"
                        maxLength="16"
                        value={this.state.controlNumber}
                        className="form-control form-control-sm"
                        name="controlNumber"
                        onChange={(e) => {
                          this.onChangeControlCN(e)
                          // this.onChangeControlOnlyNumberMTCNSearch(e);
                        }}
                        data-test="text-controlNumber"
                        placeholder="Control Number"
                      />
                    </div> */}
                    {/* <div className="col-lg">
                      <label htmlFor="dbk">DBK:</label>
                      <input
                        type="text"
                        maxLength="12"
                        value={this.state.dbk}
                        className="form-control form-control-sm"
                        name="dbk"
                        onChange={(e) => {
                          this.onChangeControlMTCNSearch(e);
                        }}
                        data-test="text-dbk"
                        placeholder="DBK"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="dbkType-select-label">DBK Type</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          this.onChangeControlMTCNSearch(e);
                        }}
                        value={this.state.dbkType}
                        name="dbkType"
                      >
                        <option key="select" value="">
                          Select
                        </option>
                        <option key="Decimal" value="Decimal">
                          Decimal
                        </option>
                        <option key="Octal" value="Octal">
                          Octal
                        </option>
                      </select>
                    </div>*/}
                    <div className="col-lg"></div>
                    <div className="col-lg"></div>
                    <div className="col-lg"></div>
                  </div>
                  <div className="card ">
                    <div className="card-header bg-dark text-white card-header-custom">
                      STAGED MONEY TRANSFER
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="form-group row">
                        <div className="col-lg">
                          <label htmlFor="pinNumber" className="font-weight-bold">PIN NUMBER</label>
                          <input
                            type="text"
                            maxLength="20"
                            value={this.state.pinNumber}
                            className="form-control form-control-sm"
                            name="pinNumber"
                            onChange={(e) => {
                              this.onChangeControlOnlyNumberMTCNSearch(e);
                            }}
                            data-test="text-pinNumber"
                            placeholder="PIN NUMBER"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="type" className="font-weight-bold">Type</label>
                          <input
                            type="text"
                            maxLength="1"
                            value={this.state.type}
                            className="form-control form-control-sm"
                            name="type"
                            onChange={(e) => {
                              this.onChangeControlOnlyCharacterMTCNSearch(e);
                            }}
                            data-test="text-type"
                            placeholder="Type"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="phone" className="font-weight-bold">Phone</label>
                          <input
                            type="text"
                            value={this.state.phone}
                            maxLength="15"
                            className="form-control form-control-sm"
                            name="phone"
                            onChange={(e) => {
                              this.onChangeControlOnlyNumberMTCNSearch(e);
                            }}
                            data-test="text-phone"
                            placeholder="Phone"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="principle1" className="font-weight-bold">PRINCIPAL</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={this.state.principle1}
                            className="form-control form-control-sm"
                            name="principle1"
                            onChange={(e) => {
                              this.onChangeControlDecimalMTCNSearch(e);
                            }}
                            data-test="text-principle1"
                            placeholder="PRINCIPAL"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="wuCard" className="font-weight-bold">WU CARD</label>
                          <input
                            type="text"
                            maxLength="9"
                            value={this.state.wuCard}
                            className="form-control form-control-sm"
                            name="wuCard"
                            onChange={(e) => {
                              this.onChangeControlCharacterNumberMTCNSearch(e);
                            }}
                            data-test="text-wuCard"
                            placeholder="WU CARD"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="principle2" className="font-weight-bold">PRINCIPAL</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={this.state.principle2}
                            className="form-control form-control-sm"
                            name="principle2"
                            onChange={(e) => {
                              this.onChangeControlDecimalMTCNSearch(e);
                            }}
                            data-test="text-principle2"
                            placeholder="PRINCIPAL"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mar-top5 text-center">
                    <div className="col-lg">
                      <label htmlFor="help1" className="font-weight-bold">
                        *FIRST INITIAL OF FIRST NAME THEN SPELL OUT LAST NAME
                      </label><br/>
                      <label htmlFor="help2" className="font-weight-bold">
                        **FULL 10 DIGIT PHONE NUMBER
                      </label>
                    </div>
                  </div>
                  <div className="form-group row mar-top5">
                    <div className="col-lg-12 text-center">
                     {
                      this.state.isLoading ? 
                        <button   className="btn btn-warning" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        &nbsp;&nbsp;Loading...
                      </button>
                      :
                      (this.state.accessGranted ? 
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={this.onHandleSearchMTCN}
                          data-test="button-search"
                        >
                          Search
                        </button>
                        : 
                        <button
                        type="button"
                        className="btn btn-warning "
                        disabled
                        data-test="button-search"
                      >
                        Search
                      </button>)
                    }  
                    </div>
                  </div>
                  <div className="row mar-top5 text-center">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                      {this.state.error && (
                        <div className="alert alert-danger alert-padding notification">
                          {this.state.errorMessage}
                        </div>
                      )}   
                    </div>
                    <div className="col-lg-4"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    ):null
  }
}
const mapStateToProps = ({ MTCNSearchReducer }) => {
  return {
    searchedData: MTCNSearchReducer.dataInfo,
  };
};
export const MoneyTransferDatabaseUtilityRedux = connect(mapStateToProps, { doSearchMtcn })(MoneyTransferDatabaseUtility);
export default withOktaAuth(MoneyTransferDatabaseUtilityRedux);
