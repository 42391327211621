import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactPrepaid } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditPrepaid} from "react-icons/ai";

import { IconContext as IconContextPrepaid } from "react-icons";
import { getHeadersShared2 } from "../../utility/utility";

class PrepaidFraudPreventionFilter extends Component {
  state = {
    timestamp: "",
    sequenceNum: "",
    sendCountryIso: [],
    senderCntry1: "",
    senderCntry2: "",
    senderCntry3: "",
    senderCntry4: "",
    senderCntry5: "",
    senderCntry6: "",
    senderCntry7: "",
    senderCntry8: "",
    senderCntry9: "",
    senderCntry10: "",
    senderCntry11: "",
    senderCntry12: "",
    senderCntry13: "",
    senderCntry14: "",
    senderCntry15: "",
    senderCntry16: "",
    senderCntry17: "",
    senderCntry18: "",
    senderCntry19: "",
    senderCntry20: "",
    startDate: "",
    endDate: "",
    wind: "",
    monitorMode: "",
    action: "",
    queueName: "",
    ruleId: "",
    accountType: "",
    clientType: "",
    comments: "",
    messageIndicator: "N",
    message1: "",
    message2: "",
    message3: "",
    errorMessage: "",
    siteId: "",
    specificCsc: "",
    sendingReceiving: "",
    countryState: "",
    countryStateAll: false,
    agent: "",
    agentAll: false,
    agentNetwork: "",
    agentNetworkAll: false,
    regTran1: "",
    regTran2: "",
    regTran3: "",
    regTran4: "",
    regLowAmt1: "",
    regLowAmt2: "",
    regLowAmt3: "",
    regLowAmt4: "",
    regUppAmt1: "",
    regUppAmt2: "",
    regUppAmt3: "",
    regUppAmt4: "",
    regMin1: "",
    regMin2: "",
    regMin3: "",
    regMin4: "",
    catchTran1: "",
    catchTran2: "",
    catchTran3: "",
    catchTran4: "",
    catchAmt1: "",
    catchAmt2: "",
    catchAmt3: "",
    catchAmt4: "",
    catchMin1: "",
    catchMin2: "",
    catchMin3: "",
    catchMin4: "",
    floorUsdAmount: 0,
    enableBtn: false,
    count: 0,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => this.handleEditPrepaidFPF(params)}
            >
              <IconContextPrepaid.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditPrepaid />
              </IconContextPrepaid.Provider>
            </div>
          );
        },
        filter: false,
        sortable: false,
        width: "80px",
        floatingFilter: false,
      },
      {field: "ruleId",headerName: "RULE ID",width: "100px",},
      {field: "sndIso",headerName: "SND ISO",width: "100px",},
      {field: "sndState",headerName: "SND ST",width: "100px",},
      {field: "sndAgent",headerName: "SND AGENT",width: "100px",},
      {field: "sndNetwork",headerName: "SND NETWORK",width: "100px",},
      {field: "startDate",headerName: "START DATE",width: "100px",filter: false,},
      {field: "endDate",headerName: "END DATE",width: "100px",filter: false,},
      {field: "wind",headerName: "WIND",width: "100px",},
      {field: "monitorMode",headerName: "MMODE",width: "120px",},
    ],
    rows: null,
    gridApi: null,
    paginationValPrepaidFPF: "25",
    defaultColumnDef: {
      resizable: true,
      flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    paginationOptionPrepaidFPF: [25, 50, 100, 500],
    overlayLoadingTemplatePrepaid:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplatePrepaid: '<span style="">No Records Found</span>',
    showPrepaidFPFConfirm: false,
    showPFPFUpdateConfirm: false,
    submitStatusPrepaidFPF: null,
    submitMsgPrepaidFPF: "",
  };

  onPageSizeChangedPrepaidFPF = (e) => {
    this.setState({
      paginationValPrepaidFPF: e.target.value,
    });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onGridReadyPrepaidFPF = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    this.onLoadingPrepaidFPF();
  };

  componentDidMount=()=>{
    this.setState({
      accessGrantedWriteFPF:this.props.location.records.accessGrantedWriteFPF
    });
  }

  onChangeControlPrepaidFPF = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlPrepaidFPFAlphaNumeric = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

 /*  onChangeControlCountriesPrepaidFPF = (e) => {
    let val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val,
    });
  }; */

  onChangeDateControlPrepaidFPF = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeControlPrepaidFPFNum = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
    });
  };

  onLoadingPrepaidFPF = () => {
    this.onHandleClearPrepaidFPF();
    const countryCpcIso = this.props.location.state;
    //console.log(countryCpcIso)
    if (countryCpcIso !== "") {
      axios
        .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/prepaidsearch`, {
          headers: {
            ...getHeadersShared2().headers,
            countryCpcIso,
            Authorization:this.props.location.records.token,
          },
        })
        .then((response) => {
          if(response.data.flag === 1){
          this.setState({
            rows: response.data.records,
          });
        }else{
          this.setState({
            rows: [],
            overlayNoRowsTemplatePrepaid:`<span style="">${response.data.message}</span>`
        });
        }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/prepaidsearch`,
          {
            headers: {
              ...getHeadersShared2().headers,
              Authorization:this.props.location.records.token,
            },
          }
        )
        .then((response) => {
          if(response.data.flag === 1){
          this.setState({
            rows: response.data.records,
          });
        }else{
          this.setState({
            rows: [],
            overlayNoRowsTemplatePrepaid:`<span style="">${response.data.message}</span>`
        });
        }
        });
    }
  };

  handleEditPrepaidFPF = (params) => {
    this.onHandleClearPrepaidFPF();
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/prepaidsearch/${params.data.timestamp}/${params.data.sequenceNum} `,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
          },
        }
      )
      .then((response) => {
        if(response.data.flag === 1){
        const arrysendCountryIso = response.data.sendCountryIso;
        const arrRegTrans = response.data.regularRule.trans;
        const arrRegLowAmt = response.data.regularRule.lowerLimit;
        const arrUppAmt = response.data.regularRule.upperLimit;
        const arrMin = response.data.regularRule.minutes;
        const arrCatchTrans = response.data.catchAllRule.trans;
        const arrUsdAmt = response.data.catchAllRule.usdAmount;
        const arrCatchMin = response.data.catchAllRule.minutes;
        let d1PrepaidFPF = new Date(response.data.startDate);
        let newStartDatePrepaidFPF = [
          d1PrepaidFPF.getFullYear(),
          ("0" + (d1PrepaidFPF.getMonth() + 1)).slice(-2),
          ("0" + d1PrepaidFPF.getDate()).slice(-2),
        ].join("-");
        let d2PrepaidFPF = new Date(response.data.endDate);
        let newEndDatePrepaidFPF = [
          d2PrepaidFPF.getFullYear(),
          ("0" + (d2PrepaidFPF.getMonth() + 1)).slice(-2),
          ("0" + d2PrepaidFPF.getDate()).slice(-2),
        ].join("-");
        // console.log(response.data)
        this.setState({
          ruleId: response.data.ruleId,
          sequenceNum: response.data.sequenceNum,
          timestamp: response.data.timestamp,
          sendCountryCpc: response.data.sendCountryCpc,
          startDate: newStartDatePrepaidFPF,
          endDate: newEndDatePrepaidFPF,
          action: response.data.action,
          queueName: response.data.queueName,
          monitorMode: response.data.monitorMode,
          siteId: response.data.siteId,
          specificCsc: response.data.specificCsc,
          comments: response.data.comments,
          messageIndicator: response.data.messageIndicator,
          senderCntry1: arrysendCountryIso[0],
          senderCntry2: arrysendCountryIso[1],
          senderCntry3: arrysendCountryIso[2],
          senderCntry4: arrysendCountryIso[3],
          senderCntry5: arrysendCountryIso[4],
          senderCntry6: arrysendCountryIso[5],
          senderCntry7: arrysendCountryIso[6],
          senderCntry8: arrysendCountryIso[7],
          senderCntry9: arrysendCountryIso[8],
          senderCntry10: arrysendCountryIso[9],
          senderCntry11: arrysendCountryIso[10],
          senderCntry12: arrysendCountryIso[11],
          senderCntry13: arrysendCountryIso[12],
          senderCntry14: arrysendCountryIso[13],
          senderCntry15: arrysendCountryIso[14],
          senderCntry16: arrysendCountryIso[15],
          senderCntry17: arrysendCountryIso[16],
          senderCntry18: arrysendCountryIso[17],
          senderCntry19: arrysendCountryIso[18],
          senderCntry20: arrysendCountryIso[19],
          accountType: response.data.accountType,
          clientType: response.data.clientType,
          wind: response.data.whiteListIndicator,
          message1: response.data.message1,
          message2: response.data.message2,
          message3: response.data.message3,
          errorMessage: response.data.errorMessage,
          regTran1: arrRegTrans[0],
          regTran2: arrRegTrans[1],
          regTran3: arrRegTrans[2],
          regTran4: arrRegTrans[3],
          regLowAmt1: arrRegLowAmt[0],
          regLowAmt2: arrRegLowAmt[1],
          regLowAmt3: arrRegLowAmt[2],
          regLowAmt4: arrRegLowAmt[3],
          regUppAmt1: arrUppAmt[0],
          regUppAmt2: arrUppAmt[1],
          regUppAmt3: arrUppAmt[2],
          regUppAmt4: arrUppAmt[3],
          regMin1: arrMin[0],
          regMin2: arrMin[1],
          regMin3: arrMin[2],
          regMin4: arrMin[3],
          floorUsdAmount: response.data.regularRule.floorUsdAmount,
          //floorUsdAmount: response.data.regularRule  ? response.data.regularRule.floorUsdAmount : null,
          catchTran1: arrCatchTrans[0],
          catchTran2: arrCatchTrans[1],
          catchTran3: arrCatchTrans[2],
          catchTran4: arrCatchTrans[3],
          catchAmt1: arrUsdAmt[0],
          catchAmt2: arrUsdAmt[1],
          catchAmt3: arrUsdAmt[2],
          catchAmt4: arrUsdAmt[3],
          catchMin1: arrCatchMin[0],
          catchMin2: arrCatchMin[1],
          catchMin3: arrCatchMin[2],
          catchMin4: arrCatchMin[3],
          enableBtn: true,
		      countryState:response.data.sendingReceiving.countryState === ""?"":response.data.sendingReceiving.countryState,
          countryStateAll:response.data.sendingReceiving.countryState === ""?true:false,
          agent:response.data.sendingReceiving.agent === ""?"":response.data.sendingReceiving.agent,
          agentAll:response.data.sendingReceiving.agent === ""?true:false,
          agentNetwork:response.data.sendingReceiving.agentNetwork === ""?"":response.data.sendingReceiving.agentNetwork,
          agentNetworkAll:response.data.sendingReceiving.agentNetwork === ""?true:false,
        });
      }else{
        this.setState({
          submitStatusPrepaidFPF: "Error",
          submitMsgPrepaidFPF: response.data.message,
      });
      }
      
      });
  };

  onHandleBackPrepaidFPF = () => {
    this.props.history.push("/fraud-prevention-filter");
  };

  onValidatePrepaidFPF = (validationType) => {
   
    if (this.state.senderCntry1 === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Enter The Value For Sending Country ISO ",
      });
      return false;
    }
    if (this.state.startDate === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Enter The Value For Start Date",
      });
      return false;
    }
    if (this.state.endDate === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Enter The Value For End Date",
      });
      return false;
    }

    if(validationType === "Add")
    {
      let tDate=new Date()
      let newTDate = [
      tDate.getFullYear(),
       ("0" + (tDate.getMonth() + 1)).slice(-2),
       ("0" + tDate.getDate()).slice(-2),
       ].join("-");

      if( newTDate > this.state.startDate) {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Start Date Cannot Be Less Than Today's Date",
     });
     return false;
    }
    }    
   
    if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "End Date Must Be Greater Than Or Equal To Start Date",
      });
      return false;
    }
    if (this.state.action === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Enter The Value For Action",
      });
      return false;
    }
    if (this.state.queueName !== "" && this.state.action === "B") 
     {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          "Only Queue Filter Action Should Have Queue Name Value",
      });
      return false;
    }
    if (this.state.accountType === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Select The Value For Account Type",
      });
      return false;
    }
    if (this.state.clientType === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Select The Value For Client Type",
      });
      return false;
    }
    if (this.state.monitorMode === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Select The Value For Monitor Mode",
      });
      return false;
    }
    if (this.state.siteId === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Select The Value For Site Id",
      });
      return false;
    }
    if (
      (this.state.specificCsc === "" || this.state.siteId !== "S") &&
      (this.state.specificCsc !== "" || this.state.siteId === "S")
    ) {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Select The Value For Specific CSC and Value For Enter Site Id",
      });
      return false;
    }
    if (
      this.state.messageIndicator === "Y" &&
      this.state.message1 === "" &&
      this.state.messageIndicator === "Y" && 
	    this.state.message2 === "" &&
      this.state.messageIndicator === "Y" &&
      this.state.message3 === ""
    ) {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          "FPF Message Indicator Is selected. Please Enter The Value For FPF Message",
      });
      return false;
    }
    if (this.state.countryState === "" && !this.state.countryStateAll)
     {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          "Please Enter The Value For Country State Or Select ALL For Multiple Countries",
      });
      return false;
    }
    if (this.state.countryState !== "" && this.state.countryStateAll)  {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          "Please Enter The Value For Country State Or Select ALL For Multiple Countries",
      });
      return false;
    }
    if (this.state.agent === "" && !this.state.agentAll) {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          "Please Enter The Value For Agent Or Select ALL For Multiple Countries",
      });
      return false;
    }
    if (this.state.agent !== "" && this.state.agentAll) {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          "Please Enter The Value For Agent Or Select ALL For Multiple Countries",
      });
      return false;
    }
    if (this.state.agentNetwork === "" && !this.state.agentNetworkAll) {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          "Please Enter The Value For Agent Network Or Select ALL For Multiple Countries",
      });
      return false;
    }
    if (this.state.agentNetwork !== "" && this.state.agentNetworkAll) {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          "Please Enter The Value For Agent Network Or Select ALL For Multiple Countries",
      });
      return false;
    }

  if (
    ((this.state.regMin1 > 0 || this.state.regMin1 > "0") && (this.state.regUppAmt1 == 0 || this.state.regUppAmt1 === "0")) ||
    ((this.state.regMin2 > 0 || this.state.regMin2 > "0") && (this.state.regUppAmt2 == 0 || this.state.regUppAmt2 === "0")) ||
    ((this.state.regMin3 > 0 || this.state.regMin3 > "0") && (this.state.regUppAmt3 == 0 || this.state.regUppAmt3 === "0")) ||
    ((this.state.regMin4 > 0 || this.state.regMin4 > "0") && (this.state.regUppAmt4 == 0 || this.state.regUppAmt4 === "0"))
  ) {
    let indexPrepaidRegMinUpp = 0;
    if(this.state.regMin1 > 0 || this.state.regMin1 > "0"){
      indexPrepaidRegMinUpp = 1;
    }else if(this.state.regMin2 > 0 || this.state.regMin2 > "0"){
      indexPrepaidRegMinUpp = 2;
    }else if(this.state.regMin3 > 0 || this.state.regMin3 > "0"){
      indexPrepaidRegMinUpp = 3;
    }else if(this.state.regMin4 > 0 || this.state.regMin4 > "0"){
      indexPrepaidRegMinUpp = 4;
    }
    this.setState({
      submitStatusPrepaidFPF: "Error",
      submitMsgPrepaidFPF:
        `Regular Rule Upper USD Amount Value ${indexPrepaidRegMinUpp} Should Be Greater Than Zero`,
    });
    return false;
  }

    if (
      ((this.state.regMin1 > 0 || this.state.regMin1 > "0") && (this.state.regLowAmt1 == 0 || this.state.regLowAmt1 === "0")) ||
      ((this.state.regMin2 > 0 || this.state.regMin2 > "0") && (this.state.regLowAmt2 == 0 || this.state.regLowAmt2 === "0")) ||
      ((this.state.regMin3 > 0 || this.state.regMin3 > "0") && (this.state.regLowAmt3 == 0 || this.state.regLowAmt3 === "0")) ||
      ((this.state.regMin4 > 0 || this.state.regMin4 > "0") && (this.state.regLowAmt4 == 0 || this.state.regLowAmt4 === "0"))
    ) {
      let indexPrepaidRegMin = 0;
      if(this.state.regMin1 > 0 || this.state.regMin1 > "0"){
        indexPrepaidRegMin = 1;
      }else if(this.state.regMin2 > 0 || this.state.regMin2 > "0"){
        indexPrepaidRegMin = 2;
      }else if(this.state.regMin3 > 0 || this.state.regMin3 > "0"){
        indexPrepaidRegMin = 3;
      }else if(this.state.regMin4 > 0 || this.state.regMin4 > "0"){
        indexPrepaidRegMin = 4;
      }
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          `Regular Rule Lower USD Amount Value ${indexPrepaidRegMin} Should Be Greater Than Zero`,
      });
      return false;
    }

    if (
      ((this.state.catchMin1 > 0 || this.state.catchMin1 > "0") && (this.state.catchAmt1 == 0 || this.state.catchAmt1 === "0")) ||
      ((this.state.catchMin2 > 0 || this.state.catchMin2 > "0") && (this.state.catchAmt2 == 0 || this.state.catchAmt2 === "0") )||
      ((this.state.catchMin3 > 0 || this.state.catchMin3 > "0") && (this.state.catchAmt3 == 0 || this.state.catchAmt3 === "0") )||
      ((this.state.catchMin4 > 0 || this.state.catchMin4 > "0") && (this.state.catchAmt4 == 0 || this.state.catchAmt4 === "0") )
    ) {
      let indexPrepaidCatchMin = 0;
      if(this.state.catchMin1 > 0 || this.state.catchMin1 > "0"){
        indexPrepaidCatchMin = 1;
      }else if(this.state.catchMin2 > 0 || this.state.catchMin2 > "0"){
        indexPrepaidCatchMin = 2;
      }else if(this.state.catchMin3 > 0 || this.state.catchMin3 > "0"){
        indexPrepaidCatchMin = 3;
      }else if(this.state.catchMin4 > 0 || this.state.catchMin4 > "0"){
        indexPrepaidCatchMin = 4;
      }
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          `Catch Rule USD Amount Value ${indexPrepaidCatchMin} Should Be Greater Than Zero`,
      });
      return false;
    }

    if ((Number(this.state.regUppAmt1) < Number(this.state.regLowAmt1)) ||
      (Number(this.state.regUppAmt2) < Number(this.state.regLowAmt2)) ||
      (Number(this.state.regUppAmt3) < Number(this.state.regLowAmt3)) ||
      (Number(this.state.regUppAmt4) < Number(this.state.regLowAmt4))) {
      let indexPrepaidRegUppLowAmt = 0;
      if (Number(this.state.regUppAmt1) < Number(this.state.regLowAmt1)) {
        indexPrepaidRegUppLowAmt = 1;
      } else if (Number(this.state.regUppAmt2) < Number(this.state.regLowAmt2)) {
        indexPrepaidRegUppLowAmt = 2;
      } else if (Number(this.state.regUppAmt3) < Number(this.state.regLowAmt3)) {
        indexPrepaidRegUppLowAmt = 3;
      } else if (Number(this.state.regUppAmt4) < Number(this.state.regLowAmt4)) {
        indexPrepaidRegUppLowAmt = 4;
      }
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF:
          `Regular Rule Upper USD Amount Value ${indexPrepaidRegUppLowAmt} Should Be Greater Than Lower USD Amount`,
      });
      return false;
    }

    if (this.state.floorUsdAmount === "") {
      this.setState({
        submitStatusPrepaidFPF: "Error",
        submitMsgPrepaidFPF: "Please Enter The Value For Floor Amount Value",
      });
      return false;
    }
    
    return true;
  };

  convertDateToLegacyFormat = (date) => {
    let d1PrepaidFPF = new Date(date);
    let newStartDatePrepaidFPF = [
      ("0" + (d1PrepaidFPF.getMonth() + 1)).slice(-2),
      ("0" + d1PrepaidFPF.getDate()).slice(-2),
      d1PrepaidFPF.getFullYear(),
    ].join("/");
    return newStartDatePrepaidFPF;
  };

  onHandleAddPrepaidFPF = () => {
    if (!this.onValidatePrepaidFPF("Add")){
      return;
    }
    let submittedObjAddPFPF = {};
    let senderArrayAddPFPF = [];
    let transArrayAddPFPF = [];
    let lowerArrayAddPFPF = [];
    let upperArrayAddPFPF = [];
    let minArrayAddPFPF = [];
    let catchtransArrayAddPFPF = [];
    let catchAmtArrayAddPFPF = [];
    let catchminArrayAddPFPF = [];
    submittedObjAddPFPF.sendingReceiving = {};
    submittedObjAddPFPF.regularRule = {};
    submittedObjAddPFPF.catchAllRule = {};
    
    if (this.state.senderCntry1 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry1.toUpperCase());
    }

    if (this.state.senderCntry2 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry2.toUpperCase());
    }
    if (this.state.senderCntry3 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry3.toUpperCase());
    }
    if (this.state.senderCntry4 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry4.toUpperCase());
    }
    if (this.state.senderCntry5 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry5.toUpperCase());
    }
    if (this.state.senderCntry6 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry6.toUpperCase());
    }
    if (this.state.senderCntry7 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry7.toUpperCase());
    }
    if (this.state.senderCntry8 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry8.toUpperCase());
    }
    if (this.state.senderCntry9 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry9.toUpperCase());
    }
    if (this.state.senderCntry10 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry10.toUpperCase());
    }
    if (this.state.senderCntry11 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry11.toUpperCase());
    }
    if (this.state.senderCntry12 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry12.toUpperCase());
    }
    if (this.state.senderCntry13 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry13.toUpperCase());
    }
    if (this.state.senderCntry14 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry14.toUpperCase());
    }
    if (this.state.senderCntry15 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry15.toUpperCase());
    }
    if (this.state.senderCntry16 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry16.toUpperCase());
    }
    if (this.state.senderCntry17 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry17.toUpperCase());
    }
    if (this.state.senderCntry18 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry18.toUpperCase());
    }
    if (this.state.senderCntry19 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry19.toUpperCase());
    }
    if (this.state.senderCntry20 !== "") {
      senderArrayAddPFPF.push(this.state.senderCntry20.toUpperCase());
    }
    
    //console.log(senderArrayAddPFPF);
    // console.log(this.state);
    submittedObjAddPFPF.sendCountryIso = senderArrayAddPFPF;
    const uniqueString = [...new Set(senderArrayAddPFPF)];
    submittedObjAddPFPF.sendCountryIso = uniqueString;

    transArrayAddPFPF.push(this.state.regTran1);
    transArrayAddPFPF.push(this.state.regTran2);
    transArrayAddPFPF.push(this.state.regTran3);
    transArrayAddPFPF.push(this.state.regTran4);
    lowerArrayAddPFPF.push(this.state.regLowAmt1);
    lowerArrayAddPFPF.push(this.state.regLowAmt2);
    lowerArrayAddPFPF.push(this.state.regLowAmt3);
    lowerArrayAddPFPF.push(this.state.regLowAmt4);
    upperArrayAddPFPF.push(this.state.regUppAmt1);
    upperArrayAddPFPF.push(this.state.regUppAmt2);
    upperArrayAddPFPF.push(this.state.regUppAmt3);
    upperArrayAddPFPF.push(this.state.regUppAmt4);
    minArrayAddPFPF.push(this.state.regMin1);
    minArrayAddPFPF.push(this.state.regMin2);
    minArrayAddPFPF.push(this.state.regMin3);
    minArrayAddPFPF.push(this.state.regMin4);
    submittedObjAddPFPF.regularRule = {
      trans: transArrayAddPFPF,
      lowerLimit: lowerArrayAddPFPF.map(Number),
      upperLimit: upperArrayAddPFPF.map(Number),
      minutes: minArrayAddPFPF,
    };
    submittedObjAddPFPF.regularRule.floorUsdAmount = this.state.floorUsdAmount;
    catchtransArrayAddPFPF.push(this.state.catchTran1);
    catchtransArrayAddPFPF.push(this.state.catchTran2);
    catchtransArrayAddPFPF.push(this.state.catchTran3);
    catchtransArrayAddPFPF.push(this.state.catchTran4);
    catchAmtArrayAddPFPF.push(this.state.catchAmt1);
    catchAmtArrayAddPFPF.push(this.state.catchAmt2);
    catchAmtArrayAddPFPF.push(this.state.catchAmt3);
    catchAmtArrayAddPFPF.push(this.state.catchAmt4);
    catchminArrayAddPFPF.push(this.state.catchMin1);
    catchminArrayAddPFPF.push(this.state.catchMin2);
    catchminArrayAddPFPF.push(this.state.catchMin3);
    catchminArrayAddPFPF.push(this.state.catchMin4);
    submittedObjAddPFPF.catchAllRule = {
      trans: catchtransArrayAddPFPF,
      usdAmount: catchAmtArrayAddPFPF.map(Number),
      minutes: catchminArrayAddPFPF,
    };
    submittedObjAddPFPF.startDate = this.convertDateToLegacyFormat(
      this.state.startDate
    );
    submittedObjAddPFPF.endDate = this.convertDateToLegacyFormat(this.state.endDate);

    submittedObjAddPFPF.action = this.state.action;
    submittedObjAddPFPF.ruleId = this.state.ruleId;
    submittedObjAddPFPF.queueName = this.state.queueName;
    submittedObjAddPFPF.siteId = this.state.siteId;
    submittedObjAddPFPF.monitorMode = this.state.monitorMode;
    submittedObjAddPFPF.accountType = this.state.accountType;
    submittedObjAddPFPF.clientType = this.state.clientType;
    submittedObjAddPFPF.comments = this.state.comments;
    submittedObjAddPFPF.messageIndicator = this.state.messageIndicator;
    submittedObjAddPFPF.message1 = this.state.message1;
    submittedObjAddPFPF.message2 = this.state.message2;
    submittedObjAddPFPF.message3 = this.state.message3;
    submittedObjAddPFPF.errorMessage = this.state.errorMessage;

    this.onHandlePrepaidFPFAllValue(submittedObjAddPFPF);

    submittedObjAddPFPF.sendingReceiving.countryState = this.state.countryState;
    submittedObjAddPFPF.sendingReceiving.agent = this.state.agent;
    submittedObjAddPFPF.sendingReceiving.agentNetwork = this.state.agentNetwork;
    submittedObjAddPFPF.operatorId = this.props.location.records.operatorIdFPF;

    // console.log(submittedObjAddPFPF);

    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/prepaidsearch`,
        submittedObjAddPFPF,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusPrepaidFPF: "OK",
            submitMsgPrepaidFPF: response.data.responseString,
            rows: [submittedObjAddPFPF, ...this.state.rows],
          });
          this.onLoadingPrepaidFPF(); 
        } else {
          this.setState({
            submitStatusPrepaidFPF: "Error",
            submitMsgPrepaidFPF: response.data.responseString,
          });
        }
        this.onHandleClearPrepaidFPF();
      });
  };

  onHandlePrepaidFPFAllValue = (submittedObjPFPF) => {
    if (this.state.countryStateAll) {
      submittedObjPFPF.sendingReceiving.countryState = "";
    } else {
      submittedObjPFPF.sendingReceiving.countryState = this.state.countryState;
    }
    if (this.state.agentAll) {
      submittedObjPFPF.sendingReceiving.agent = "";
    } else {
      submittedObjPFPF.sendingReceiving.agent = this.state.agent;
    }
    if (this.state.agentNetworkAll) {
      submittedObjPFPF.sendingReceiving.agentNetwork = "";
    } else {
      submittedObjPFPF.sendingReceiving.agentNetwork = this.state.agentNetwork;
    }
  };

  onHandleUpdatePrepaidFPF = () => {
   /*  if (!this.onValidatePrepaidFPF()) {
      return;
    } */
    let submittedObjUpdatePFPF = {};
    let senderArrayUpdatePFPF = [];
    let transArrayUpdatePFPF = [];
    let lowerArrayUpdatePFPF = [];
    let upperArrayUpdatePFPF = [];
    let minArrayUpdatePFPF = [];
    let catchtransArrayUpdatePFPF = [];
    let catchAmtArrayUpdatePFPF = [];
    let catchminArrayUpdatePFPF = [];

    submittedObjUpdatePFPF.sendingReceiving = {};
    submittedObjUpdatePFPF.regularRule = {};
    submittedObjUpdatePFPF.catchAllRule = {};
    
    if (this.state.senderCntry1 !== undefined && this.state.senderCntry1 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry1.toUpperCase());
    }
    if (this.state.senderCntry2 !== undefined && this.state.senderCntry2 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry2.toUpperCase());
    }
    if (this.state.senderCntry3 !== undefined && this.state.senderCntry3 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry3.toUpperCase());
    }
    if (this.state.senderCntry4 !== undefined && this.state.senderCntry4 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry4.toUpperCase());
    }
    if (this.state.senderCntry5 !== undefined && this.state.senderCntry5 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry5.toUpperCase());
    }
    if (this.state.senderCntry6 !== undefined && this.state.senderCntry6 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry6.toUpperCase());
    }
    if (this.state.senderCntry7 !== undefined && this.state.senderCntry7 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry7.toUpperCase());
    }
    if (this.state.senderCntry8 !== undefined && this.state.senderCntry8 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry8.toUpperCase());
    }
    if (this.state.senderCntry9 !== undefined && this.state.senderCntry9 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry9.toUpperCase());
    }
    if (this.state.senderCntry10 !== undefined && this.state.senderCntry10 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry10.toUpperCase());
    }
    if (this.state.senderCntry11 !== undefined && this.state.senderCntry11 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry11.toUpperCase());
    }
    if (this.state.senderCntry12 !== undefined && this.state.senderCntry12 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry12.toUpperCase());
    }
    if (this.state.senderCntry13 !== undefined && this.state.senderCntry13 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry13.toUpperCase());
    }
    if (this.state.senderCntry14 !== undefined && this.state.senderCntry14 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry14.toUpperCase());
    }
    if (this.state.senderCntry15 !== undefined && this.state.senderCntry15 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry15.toUpperCase());
    }
    if (this.state.senderCntry16 !== undefined && this.state.senderCntry16 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry16.toUpperCase());
    }
    if (this.state.senderCntry17 !== undefined && this.state.senderCntry17 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry17.toUpperCase());
    }
    if (this.state.senderCntry18 !== undefined && this.state.senderCntry18 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry18.toUpperCase());
    }
    if (this.state.senderCntry19 !== undefined && this.state.senderCntry19 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry19.toUpperCase());
    }
    if (this.state.senderCntry20 !== undefined && this.state.senderCntry20 !== "") {
      senderArrayUpdatePFPF.push(this.state.senderCntry20.toUpperCase());
    }
    // submittedObjUpdatePFPF.sendCountryIso = senderArrayUpdatePFPF;
    const uniqueString = [...new Set(senderArrayUpdatePFPF)];
    submittedObjUpdatePFPF.sendCountryIso = uniqueString;

    transArrayUpdatePFPF.push(this.state.regTran1);
    transArrayUpdatePFPF.push(this.state.regTran2);
    transArrayUpdatePFPF.push(this.state.regTran3);
    transArrayUpdatePFPF.push(this.state.regTran4);
    lowerArrayUpdatePFPF.push(this.state.regLowAmt1);
    lowerArrayUpdatePFPF.push(this.state.regLowAmt2);
    lowerArrayUpdatePFPF.push(this.state.regLowAmt3);
    lowerArrayUpdatePFPF.push(this.state.regLowAmt4);
    upperArrayUpdatePFPF.push(this.state.regUppAmt1);
    upperArrayUpdatePFPF.push(this.state.regUppAmt2);
    upperArrayUpdatePFPF.push(this.state.regUppAmt3);
    upperArrayUpdatePFPF.push(this.state.regUppAmt4);
    minArrayUpdatePFPF.push(this.state.regMin1);
    minArrayUpdatePFPF.push(this.state.regMin2);
    minArrayUpdatePFPF.push(this.state.regMin3);
    minArrayUpdatePFPF.push(this.state.regMin4);
    submittedObjUpdatePFPF.regularRule = {
      trans: transArrayUpdatePFPF,
      lowerLimit: lowerArrayUpdatePFPF.map(Number),
      upperLimit: upperArrayUpdatePFPF.map(Number),
      minutes: minArrayUpdatePFPF,
    };
    submittedObjUpdatePFPF.regularRule.floorUsdAmount = this.state.floorUsdAmount;
    catchtransArrayUpdatePFPF.push(this.state.catchTran1);
    catchtransArrayUpdatePFPF.push(this.state.catchTran2);
    catchtransArrayUpdatePFPF.push(this.state.catchTran3);
    catchtransArrayUpdatePFPF.push(this.state.catchTran4);
    catchAmtArrayUpdatePFPF.push(this.state.catchAmt1);
    catchAmtArrayUpdatePFPF.push(this.state.catchAmt2);
    catchAmtArrayUpdatePFPF.push(this.state.catchAmt3);
    catchAmtArrayUpdatePFPF.push(this.state.catchAmt4);
    catchminArrayUpdatePFPF.push(this.state.catchMin1);
    catchminArrayUpdatePFPF.push(this.state.catchMin2);
    catchminArrayUpdatePFPF.push(this.state.catchMin3);
    catchminArrayUpdatePFPF.push(this.state.catchMin4);
    submittedObjUpdatePFPF.catchAllRule = {
      trans: catchtransArrayUpdatePFPF,
      usdAmount: catchAmtArrayUpdatePFPF.map(Number),
      minutes: catchminArrayUpdatePFPF,
    };
    submittedObjUpdatePFPF.startDate = this.convertDateToLegacyFormat(
      this.state.startDate
    );
    submittedObjUpdatePFPF.endDate = this.convertDateToLegacyFormat(this.state.endDate);
    submittedObjUpdatePFPF.timestamp = this.state.timestamp;
    submittedObjUpdatePFPF.sequenceNum = this.state.sequenceNum;
    submittedObjUpdatePFPF.action = this.state.action;
    submittedObjUpdatePFPF.ruleId = this.state.ruleId;
    submittedObjUpdatePFPF.queueName = this.state.queueName;
    submittedObjUpdatePFPF.siteId = this.state.siteId;
    submittedObjUpdatePFPF.specificCsc = this.state.specificCsc;
    submittedObjUpdatePFPF.monitorMode = this.state.monitorMode;
    submittedObjUpdatePFPF.accountType = this.state.accountType;
    submittedObjUpdatePFPF.clientType = this.state.clientType;
    submittedObjUpdatePFPF.comments = this.state.comments;
    submittedObjUpdatePFPF.messageIndicator = this.state.messageIndicator;
    submittedObjUpdatePFPF.message1 = this.state.message1;
    submittedObjUpdatePFPF.message2 = this.state.message2;
    submittedObjUpdatePFPF.message3 = this.state.message3;
    submittedObjUpdatePFPF.errorMessage = this.state.errorMessage;

    this.onHandlePrepaidFPFAllValue(submittedObjUpdatePFPF);

    submittedObjUpdatePFPF.sendingReceiving.countryState = this.state.countryState;
    submittedObjUpdatePFPF.sendingReceiving.agent = this.state.agent;
    submittedObjUpdatePFPF.sendingReceiving.agentNetwork = this.state.agentNetwork;
    submittedObjUpdatePFPF.operatorId = this.props.location.records.operatorIdFPF;

    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/prepaidsearch`,
        submittedObjUpdatePFPF,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.timestamp + "" + row.sequenceNum ===
              this.state.timestamp + "" + this.state.sequenceNum
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObjUpdatePFPF,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusPrepaidFPF: "OK",
            submitMsgPrepaidFPF: response.data.responseString,
            rows: updatedRows,
          });
          this.onLoadingPrepaidFPF();

          this.onHandleClearPrepaidFPF();
        } else {
          this.setState({
            submitStatusPrepaidFPF: "Error",
            submitMsgPrepaidFPF: response.data.responseString,
          });
          this.onHandleClearPrepaidFPF();
        }
      });
      this.setState({ showPFPFUpdateConfirm: false });
  };

  getPFPFUpdateModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showPFPFUpdateConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Update This Fraud Prevention Filter?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleUpdatePrepaidFPF}
                data-test="button-ok"
              >
                Ok
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showPFPFUpdateConfirm: false,
                  })
                }
                data-test="button-cancel"
              >
               Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  onHandleClearPrepaidFPF = () => {
    this.setState({
      sendCountryIso: "",
      senderCntry1: "",
      senderCntry2: "",
      senderCntry3: "",
      senderCntry4: "",
      senderCntry5: "",
      senderCntry6: "",
      senderCntry7: "",
      senderCntry8: "",
      senderCntry9: "",
      senderCntry10: "",
      senderCntry11: "",
      senderCntry12: "",
      senderCntry13: "",
      senderCntry14: "",
      senderCntry15: "",
      senderCntry16: "",
      senderCntry17: "",
      senderCntry18: "",
      senderCntry19: "",
      senderCntry20: "",
      startDate: "",
      endDate: "",
      action: "",
      ruleId: "",
      monitorMode: "",
      accountType: "",
      clientType: "",

      queueName: "",
      siteId: "",
      channel: "",
      specificCsc: "",
      comments: "",
      messageIndicator: "N",
      message1: "",
      message2: "",
      message3: "",
      errorMessage: "",
      countryState: "",
      countryStateAll: false,
      agent: "",
      agentAll: false,
      agentNetwork: "",
      agentNetworkAll: false,

      regTran1: "",
      regTran2: "",
      regTran3: "",
      regTran4: "",
      regLowAmt1: "",
      regLowAmt2: "",
      regLowAmt3: "",
      regLowAmt4: "",
      regUppAmt1: "",
      regUppAmt2: "",
      regUppAmt3: "",
      regUppAmt4: "",
      regMin1: "",
      regMin2: "",
      regMin3: "",
      regMin4: "",
      catchTran1: "",
      catchTran2: "",
      catchTran3: "",
      catchTran4: "",
      catchAmt1: "",
      catchAmt2: "",
      catchAmt3: "",
      catchAmt4: "",
      catchMin1: "",
      catchMin2: "",
      catchMin3: "",
      catchMin4: "",
      floorUsdAmount: 0,
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusPrepaidFPF: null,
        }),
      5000
    );
  };

  getPrepaidFPFModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showPrepaidFPFConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete The Sending Country CPC {this.state.senderCntry1} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showPrepaidFPFConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeletePrepaidFPF}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeletePrepaidFPF = () => {
    let submittedObjPFPF = {};
    submittedObjPFPF.timestamp = this.state.timestamp;
    submittedObjPFPF.sequenceNum = this.state.sequenceNum;
    //  console.log(this.state.sequenceNum)
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/prepaidsearch/${this.state.timestamp}/${this.state.sequenceNum}`,

        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          let newRows = this.state.rows.filter((row) => {
            return row.timestamp !== submittedObjPFPF.timestamp;
          });

          this.setState({
            submitStatusPrepaidFPF: "OK",
            submitMsgPrepaidFPF: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearPrepaidFPF();
        } else {
          this.setState({
            submitStatusPrepaidFPF: "Error",
            submitMsgPrepaidFPF: response.data.responseString,
          });
          this.onHandleClearPrepaidFPF();
        }
      });
    this.setState({ showPrepaidFPFConfirm: false });
  };

  onChangeControlPrepaidALL = (e) => {
    //console.log("e.target.value" + e.target.value)
    this.setState({
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };

  onHandleWhitePrepaidFPF = () => {
    const whiteListIndicator = this.state.wind;
    const timeStamp = this.state.timestamp;
    const seqNum = this.state.sequenceNum;
    //console.log(timeStamp)

    this.props.history.push({
      pathname: "/whitelist-fraud-rule-prepaid",
      state: { timeStamp, seqNum, whiteListIndicator },
      records:this.props.location.records
    });
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="FRAUD PREVENTION FILTER MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                PREPAID FRAUD PREVENTION FILTER MAINTENANCE
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  CPC FILTER DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <label htmlFor="sndIso" className="font-weight-bold">Send Cntry ISO</label>
                    <div className="form-group row">
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry1}
                          className="form-control form-control-sm"
                          name="senderCntry1"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry1"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry2}
                          className="form-control form-control-sm"
                          name="senderCntry2"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry2"
                        
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry3}
                          className="form-control form-control-sm"
                          name="senderCntry3"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry3"
                          
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry4}
                          className="form-control form-control-sm"
                          name="senderCntry4"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry4"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry5}
                          className="form-control form-control-sm"
                          name="senderCntry5"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry5"
                        />
                      </div>

                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry6}
                          className="form-control form-control-sm"
                          name="senderCntry6"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry6"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry7}
                          className="form-control form-control-sm"
                          maxlength="3"
                          name="senderCntry7"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry7"
                         
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry8}
                          maxlength="3"
                          className="form-control form-control-sm"
                          name="senderCntry8"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry8"
                         
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry9}
                          maxlength="3"
                          className="form-control form-control-sm"
                          name="senderCntry9"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry9"
                         
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry10}
                          className="form-control form-control-sm"
                          name="senderCntry10"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry10"
                          
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry11}
                          className="form-control form-control-sm"
                          maxlength="3"
                          name="senderCntry11"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry11"
                         
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry12}
                          className="form-control form-control-sm"
                          maxlength="3"
                          name="senderCntry12"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry12"
                       
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry13}
                          className="form-control form-control-sm"
                          name="senderCntry13"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry13"
                         
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry14}
                          className="form-control form-control-sm"
                          name="senderCntry14"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry14"
                         
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry15}
                          className="form-control form-control-sm"
                          name="senderCntry15"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry15"
                        
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry16}
                          className="form-control form-control-sm"
                          name="senderCntry16"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry16"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry17}
                          className="form-control form-control-sm"
                          name="senderCntry17"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry17"
                        
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry18}
                          maxlength="3"
                          className="form-control form-control-sm"
                          name="senderCntry18"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry18"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry19}
                          className="form-control form-control-sm"
                          name="senderCntry19"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry19"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          value={this.state.senderCntry20}
                          className="form-control form-control-sm"
                          name="senderCntry20"
                          maxlength="3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPFAlphaNumeric(e);
                          }}
                          data-test="text-senderCntry20"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="startDate" className="font-weight-bold">Start Date</label>
                        <br />
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="startDate"
                          value={this.state.startDate}
                          onChange={this.onChangeDateControlPrepaidFPF}
                          data-test="date-startDate"
                        ></input>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="endDate" className="font-weight-bold">End Date</label>
                        <br />
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="endDate"
                          value={this.state.endDate}
                          onChange={this.onChangeDateControlPrepaidFPF}
                          data-test="date-endDate"
                        ></input>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Action</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="action"
                              value="B"
                              data-test="text-action-B"
                              checked={this.state.action === "B"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="B">
                              Block
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="action"
                              value="Q"
                              data-test="text-action-Q"
                              checked={this.state.action === "Q"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="Q">
                              Queue
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="queueName" className="font-weight-bold">Queue Name</label>
                        <input
                          type="text"
                          maxlength="8"
                          value={this.state.queueName}
                          className="form-control form-control-sm"
                          name="queueName"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPF(e);
                          }}
                          data-test="text-queueName"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="ruleId" className="font-weight-bold">Rule ID</label>
                        <input
                          type="text"
                          maxlength="12"
                          value={this.state.ruleId}
                          className="form-control form-control-sm"
                          name="ruleId"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPF(e);
                          }}
                          data-test="text-ruleId"
                        />
                      </div>

                      <div className="col-lg">
                        <label className="font-weight-bold">Account Type</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="accountType"
                              value="S"
                              data-test="text-accountType-S"
                              checked={this.state.accountType === "S"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="S">
                              SEND
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="accountType"
                              value="P"
                              data-test="text-accountType-P"
                              checked={this.state.accountType === "P"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="P">
                              PAY
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="accountType"
                              value=" "
                              data-test="text-accountType-B"
                              checked={this.state.accountType === " "}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label">BOTH</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Client Type</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="clientType"
                              value="S"
                              data-test="text-clientType-S"
                              checked={this.state.clientType === "S"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="S">
                              SALES
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="clientType"
                              value="R"
                              data-test="text-clientType-R"
                              checked={this.state.clientType === "R"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="R">
                              RELOAD
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="clientType"
                              value=" "
                              data-test="text-clientType-B"
                              checked={this.state.clientType === " "}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label">BOTH</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg">
                        <label className="font-weight-bold">Monitor Mode</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="monitorMode"
                              value="N"
                              data-test="text-monitorMode-N"
                              checked={this.state.monitorMode === "N"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              OFF
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="monitorMode"
                              value="Y"
                              data-test="text-monitorMode-Y"
                              checked={this.state.monitorMode === "Y"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              ON
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Site Id</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="MOM6"
                              data-test="text-siteId-MOM6"
                              checked={this.state.siteId === "MOM6"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="MOM6">
                              Agent
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="CSC"
                              data-test="text-siteId-CSC"
                              checked={this.state.siteId === "CSC"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="CSC">
                              CSC's or
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="S"
                              data-test="text-siteId-S"
                              checked={this.state.siteId === "S"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="S">
                              Specific CSC :&nbsp;
                            </label>
                            <input
                              className="form-check-input "
                              type="text"
                              maxlength="4"
                              name="specificCsc"
                              value={this.state.specificCsc}
                              data-test="text-specificCsc-s"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value=" "
                              data-test="text-siteId-All"
                              checked={this.state.siteId === " "}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor=" ">
                              All
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="comments" className="font-weight-bold">Comments</label>
                        <input
                          type="text"
                          maxLength="40"
                          value={this.state.comments}
                          className="form-control form-control-sm"
                          name="comments"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPF(e);
                          }}
                          data-test="text-comments"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Message Indicator</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="messageIndicator"
                              value="Y"
                              data-test="text-messageIndicator-Y"
                              checked={this.state.messageIndicator === "Y"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="messageIndicator"
                              value="N"
                              data-test="text-messageIndicator-N"
                              checked={this.state.messageIndicator === "N"}
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPF(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="ruleId" className="font-weight-bold">WhiteList In</label>
                        <input
                          type="text"
                          value={this.state.wind}
                          className="form-control form-control-sm"
                          name="wind"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPF(e);
                          }}
                          data-test="text-wind"
                          placeholder="N"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message1" className="font-weight-bold">Message 1</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message1}
                          className="form-control form-control-sm"
                          name="message1"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPF(e);
                          }}
                          data-test="text-message1"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message2" className="font-weight-bold">Message 2</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message2}
                          className="form-control form-control-sm"
                          name="message2"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPF(e);
                          }}
                          data-test="text-message2"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message3" className="font-weight-bold">Message 3</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message3}
                          className="form-control form-control-sm"
                          name="message3"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPF(e);
                          }}
                          data-test="text-message3"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="errorMessage" className="font-weight-bold">Error Message</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.errorMessage}
                          className="form-control form-control-sm"
                          name="errorMessage"
                          onChange={(e) => {
                            this.onChangeControlPrepaidFPF(e);
                          }}
                          data-test="text-errorMessage"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="mar-top5">
                <div className="row">
                  <div className="col-lg-4 ">
                    <div className="card">
                        <div className="card-header bg-dark text-white card-header-custom">
                        SENDING/RECEIVING
                      </div>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg">
                              <label htmlFor="countryState" className="font-weight-bold">Country State</label>
                              <input
                                type="text"
                                size="3"
                                maxLength="3"
                                value={this.state.countryState}
                                className="form-control form-control-sm"
                                name="countryState"
                                onChange={(e) => {
                                  this.onChangeControlPrepaidFPFAlphaNumeric(e);
                                }}
                                data-test="text-countryState"
                              />
                            </div>
                            <div className="form-check form-check-inline mar-top25">
                              <label className="form-check-label">or ALL :&nbsp;</label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="countryStateAll"
                                checked={this.state.countryStateAll}
                                value={this.state.countryStateAll}
                                data-test="text-countryStateAll"
                                onChange={(e) => {
                                  this.onChangeControlPrepaidALL(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg">
                              <label htmlFor="agent" className="font-weight-bold">Agent</label>
                              <input
                                type="text"
                                maxLength="9"
                                value={this.state.agent}
                                className="form-control form-control-sm"
                                name="agent"
                                onChange={(e) => {
                                  this.onChangeControlPrepaidFPFAlphaNumeric(e);
                                }}
                                data-test="text-agent"
                              />
                            </div>
                            <div className="form-check form-check-inline mar-top25">
                              <label className="form-check-label">or ALL :&nbsp;</label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="agentAll"
                                checked={this.state.agentAll}
                                value={this.state.agentAll}
                                onChange={(e) => {
                                  this.onChangeControlPrepaidALL(e);
                                }}
                                data-test="text-agentAll"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                        <div className="col-lg">
                          <label htmlFor="agentNetwork" className="font-weight-bold">Agent Network</label>
                          <input
                            type="text"
                            maxLength="5"
                            value={this.state.agentNetwork}
                            className="form-control form-control-sm"
                            name="agentNetwork"
                            onChange={(e) => {
                              this.onChangeControlPrepaidFPFAlphaNumeric(e);
                            }}
                            data-test="text-agentNetwork"
                          />
                        </div>
                        <div className="form-check form-check-inline mar-top25">
                          <label className="form-check-label">or ALL :&nbsp;</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="agentNetworkAll"
                            checked={this.state.agentNetworkAll}
                            value={this.state.agentNetworkAll}
                            onChange={(e) => {
                              this.onChangeControlPrepaidALL(e);
                            }}
                            data-test="text-agentNetworkAll"
                          />
                        </div>
                      </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        REGULAR RULE
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="lowerLimit" className="font-weight-bold">#TRANS</label>
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.regTran1}
                              className="form-control form-control-sm"
                              name="regTran1"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regTran1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="upperLimit" className="font-weight-bold">LOW AMT</label>
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regLowAmt1}
                              className="form-control form-control-sm"
                              name="regLowAmt1"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regLowAmt1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="regUppAmt1" className="font-weight-bold">UPP AMT</label>
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regUppAmt1}
                              className="form-control form-control-sm"
                              name="regUppAmt1"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regUppAmt1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="regMin1" className="font-weight-bold">MINUTES</label>
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.regMin1}
                              className="form-control form-control-sm"
                              name="regMin1"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regMin1"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.regTran2}
                              className="form-control form-control-sm"
                              name="regTran2"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regTran2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regLowAmt2}
                              className="form-control form-control-sm"
                              name="regLowAmt2"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regLowAmt2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regUppAmt2}
                              className="form-control form-control-sm"
                              name="regUppAmt2"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regUppAmt2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.regMin2}
                              className="form-control form-control-sm"
                              name="regMin2"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regMin2"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.regTran3}
                              className="form-control form-control-sm"
                              name="regTran3"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regTran3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regLowAmt3}
                              className="form-control form-control-sm"
                              name="regLowAmt3"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regLowAmt3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regUppAmt3}
                              className="form-control form-control-sm"
                              name="regUppAmt3"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regUppAmt3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.regMin3}
                              className="form-control form-control-sm"
                              name="regMin3"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regMin3"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.regTran4}
                              className="form-control form-control-sm"
                              name="regTran4"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regTran4"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regLowAmt4}
                              className="form-control form-control-sm"
                              name="regLowAmt4"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regLowAmt4"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regUppAmt4}
                              className="form-control form-control-sm"
                              name="regUppAmt4"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regUppAmt4"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.regMin4}
                              className="form-control form-control-sm"
                              name="regMin4"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-regMin4"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                        <div className="col-lg">
                          <label htmlFor="floorUsdAmount" className="font-weight-bold">FLOOR USD AMOUNT</label>
                          <input
                            type="text"
                            maxLength="5"
                            value={this.state.floorUsdAmount}
                            className="form-control form-control-sm"
                            name="floorUsdAmount"
                            onChange={(e) => {
                              this.onChangeControlPrepaidFPFNum(e);
                            }}
                            data-test="text-floorUsdAmount"
                          
                          />
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        CATCH ALL RULE
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="catchTran1" className="font-weight-bold">#TRANS</label>
                            <input
                              type="text"
                              size="2"
                              maxLength="2"
                              value={this.state.catchTran1}
                              className="form-control form-control-sm"
                              name="catchTran1"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchTran1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="catchAmt1" className="font-weight-bold">USD AMT</label>
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.catchAmt1}
                              className="form-control form-control-sm"
                              name="catchAmt1"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchAmt1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="catchMin1" className="font-weight-bold">MINUTES</label>
                            <input
                              type="text"
                              maxLength="4"
                              value={this.state.catchMin1}
                              className="form-control form-control-sm"
                              name="catchMin1"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchMin1"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.catchTran2}
                              className="form-control form-control-sm"
                              name="catchTran2"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchTran2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.catchAmt2}
                              className="form-control form-control-sm"
                              name="catchAmt2"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchAmt2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="4"
                              value={this.state.catchMin2}
                              className="form-control form-control-sm"
                              name="catchMin2"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchMin2"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.catchTran3}
                              className="form-control form-control-sm"
                              name="catchTran3"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchTran3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.catchAmt3}
                              className="form-control form-control-sm"
                              name="catchAmt3"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchAmt3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="4"
                              value={this.state.catchMin3}
                              className="form-control form-control-sm"
                              name="catchMin3"
                              onChange={(e) => {
                                this.onChangeControlPrepaidFPFNum(e);
                              }}
                              data-test="text-catchMin3"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                        <div className="col-lg">
                          <input
                            type="text"
                            maxLength="2"
                            value={this.state.catchTran4}
                            className="form-control form-control-sm"
                            name="catchTran4"
                            onChange={(e) => {
                              this.onChangeControlPrepaidFPFNum(e);
                            }}
                            data-test="text-catchTran4"
                          />
                        </div>
                        <div className="col-lg">
                          <input
                            type="text"
                            maxLength="5"
                            value={this.state.catchAmt4}
                            className="form-control form-control-sm"
                            name="catchAmt4"
                            onChange={(e) => {
                              this.onChangeControlPrepaidFPFNum(e);
                            }}
                            data-test="text-catchAmt4"
                          />
                        </div>
                        <div className="col-lg">
                          <input
                            type="text"
                            maxLength="4"
                            value={this.state.catchMin4}
                            className="form-control form-control-sm"
                            name="catchMin4"
                            onChange={(e) => {
                              this.onChangeControlPrepaidFPFNum(e);
                            }}
                            data-test="text-catchMin4"
                          />
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row mar-top5">
                <div className="col-lg-3" />
                <div className="col-lg-6 text-center mar-bottom5">
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackPrepaidFPF}
                    data-test="button-back"
                  >
                    FRAUD PRVT
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleAddPrepaidFPF}
                    data-test="button-add"
                    disabled={this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    ADD
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    //onClick={this.onHandleUpdatePrepaidFPF}
                    onClick={() => {
                      if (!this.onValidatePrepaidFPF()) {
                        return;
                      }      
                        this.setState({
                        showPFPFUpdateConfirm: true,
                        });
                        }}
                    data-test="button-update"
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    UPDATE
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleClearPrepaidFPF}
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                    data-test="button-clear"
                  >
                    CLEAR
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={() => {
                      this.setState({
                        showPrepaidFPFConfirm: true,
                      });
                    }}
                    data-test="button-delete"
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    DELETE
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={this.onHandleWhitePrepaidFPF}
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                    data-test="button-whitelist"
                  >
                    WHITELIST
                  </button>
                </div>
                <div className="col-lg-3">
                 
                </div>
              </div>
              <div className="row mar-top10">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                {this.state.submitStatusPrepaidFPF === "OK" && (
                    <div className="alert alert-success alert-padding notification text-center">
                      {this.state.submitMsgPrepaidFPF}
                    </div>
                  )}
                  {this.state.submitStatusPrepaidFPF === "Error" && (
                    <div className="alert alert-danger alert-padding notification text-center">
                      {this.state.submitMsgPrepaidFPF}
                    </div>
                  )}
                </div>
                <div className="col-lg-4"></div>
              </div>
              {this.getPrepaidFPFModal()}
              {this.getPFPFUpdateModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  CPC FILTER DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page
                    </label>
                    <select
                      className="form-control  form-control-sm "
                      onChange={(e) => {
                        this.onChangeControlPrepaidFPF(e);
                        this.onPageSizeChangedPrepaidFPF(e);
                      }}
                      value={this.state.paginationValPrepaidFPF}
                      name="paginationDropdown"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionPrepaidFPF.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactPrepaid
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyPrepaidFPF}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplatePrepaid
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplatePrepaid
                      }
                    ></AgGridReactPrepaid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PrepaidFraudPreventionFilter;
