import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactNetworkB } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AiTwotoneEdit as AiTwotoneEditNW } from "react-icons/ai";
import { IconContext as IconContextNW } from "react-icons";

import { getHeadersGSPS } from "../../../utility/utility";

class NetworkBlock extends Component {
  state = {
    key: "",
    entry: "",
    showNetworkBlockConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => this.state.accessFlagNetworkBlock && this.handleEditNetworkBlock(params)}   
            >
              <IconContextNW.Provider value={{ color:  this.state.accessFlagNetworkBlock ?"#FDD017":'#cec9b4', size: "20px" }}>
                <AiTwotoneEditNW />
              </IconContextNW.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      {
        field: "netwKey",
        headerName: "Key",
        width: "440px",
      },
      {
        field: "netwEnteries",
        headerName: "Entry",
        width: "450px",
      },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValNetwork: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusNetworkBlock: null,
    submitMsgNetworkBlock: "",
    paginationOptionNetworkBlock: [25, 50, 100, 500],
    overlayLoadingTemplateNetwork:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateNetwork: '<span style="">No Records Found</span>',
  };

  onChangeControlNetworkBlock = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlNetworkBlockCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onChangeControlNetworkBlockCharNumberWithSpace = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9\s]/, ""),
    });
  };

  onGridReadyNetworkBlock = (params) => {
    let accessFlagNetworkBlock = this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) || this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READWRITE);
    this.setState({accessFlagNetworkBlock});
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/network/getnetworkdetails`,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        let recNetwork = [];
        if (response.data.records !== null) {
          recNetwork = response.data.records;
        }
        this.setState({
          rows: recNetwork,
        });
      });
  };

  handleEditNetworkBlock = (params) => {
    let data = params.node.data;
    this.setState({
      key: data.netwKey,
      entry: data.netwEnteries,
      enableBtn: true,
    });
  };

  onPageSizeChangedNetworkBlock = (e) => {
    this.setState({ paginationValNetwork: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackNetworkBlock = () => {
    this.props.history.push("/");
  };

  onValidateNetworkBlock = () => {
    if (this.state.key === "") {
      this.setState({
        submitStatusNetworkBlock: "Error",
        submitMsgNetworkBlock: "Please Enter The Value For Key",
      });
      return false;
    }
    return true;
  };

  onHandleAddNetworkBlock = () => {
    if (!this.onValidateNetworkBlock()) {
      return;
    }
    let submittedObj = {};
    submittedObj.netwKey = this.state.key;
    submittedObj.netwEnteries = this.state.entry;
    submittedObj.createUser = "";
    submittedObj.modifyUser = "";
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/network/addnetworkdata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.networkid = this.state.key;
          this.setState({
            submitStatusNetworkBlock: "OK",
            submitMsgNetworkBlock: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          this.onHandleClearNetworkBlock();
        } else {
          this.setState({
            submitStatusNetworkBlock: "Error",
            submitMsgNetworkBlock: response.data.responseString,
          });
          this.onHandleClearNetworkBlock();
        }
      });
  };

  onHandleUpdateNetworkBlock = () => {
    if (!this.onValidateNetworkBlock()) {
      return;
    }
    let submittedObj = {};
    submittedObj.netwKey = this.state.key;
    submittedObj.netwEnteries = this.state.entry;
    submittedObj.createUser = "";
    submittedObj.modifyUser = "";

    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/network/updatenetworkdata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.netwKey === submittedObj.netwKey
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusNetworkBlock: "OK",
            submitMsgNetworkBlock: response.data.responseString,
            rows: updatedRows,
          });
          this.onHandleClearNetworkBlock();
        } else {
          this.setState({
            submitStatusNetworkBlock: "Error",
            submitMsgNetworkBlock: response.data.responseString,
          });
          this.onHandleClearNetworkBlock();
        }
      });
  };

  onHandleClearNetworkBlock = () => {
    this.setState({
      key: "",
      entry: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusNetworkBlock: null,
        }),
      5000
    );
  };

  getNetworkBlockModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showNetworkBlockConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete The Key {this.state.key} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showNetworkBlockConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteNetworkBlock}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteNetworkBlock = () => {
    let submittedObj = {};
    submittedObj.netwKey = this.state.key;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/network/deletenetworkdata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.netwKey !== submittedObj.netwKey;
          });

          this.setState({
            submitStatusNetworkBlock: "OK",
            submitMsgNetworkBlock: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearNetworkBlock();
        } else {
          this.setState({
            submitStatusNetworkBlock: "Error",
            submitMsgNetworkBlock: response.data.responseString,
          });
          this.onHandleClearNetworkBlock();
        }
      });
    this.setState({ showNetworkBlockConfirm: false });
  };

  render() {
    return (
      <div
        className="container-fluid padding-left0"
        data-test="component-network-block"
      >
        <div className="card">
          <div className="card-body">
            <h4 className="card-title main-heading">CROSS-NETWORK AGENT UPDATE</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
              CROSS-NETWORK AGENT UPDATE
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="key" className="font-weight-bold">Key</label>
                      <input
                        type="text"
                        maxLength="5"
                        size="5"
                        value={this.state.key}
                        className="form-control form-control-sm"
                        name="key"
                        onChange={(e) => {
                          this.onChangeControlNetworkBlockCharNumber(e);
                        }}
                        data-test="text-key"
                        placeholder="Key"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="entry" className="font-weight-bold">Entry</label>
                      <input
                        type="text"
                        maxLength="125"
                        size="125"
                        value={this.state.entry}
                        className="form-control form-control-sm"
                        name="entry"
                        onChange={(e) => {
                          this.onChangeControlNetworkBlockCharNumberWithSpace(e);
                        }}
                        data-test="text-entry"
                        placeholder="Entry"
                      />
                    </div>
                  </div>
                  <div className="form-group row margin-0">
                    <div className="col-lg-3"></div>

                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackNetworkBlock}
                        data-test="button-back"
                      >
                        GSP SYS
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        disabled = {this.state.enableBtn || !this.state.accessFlagNetworkBlock}
                        onClick={this.onHandleAddNetworkBlock}
                        data-test="button-add"
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateNetworkBlock}
                        disabled = {!this.state.enableBtn}
                        data-test="button-update"
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearNetworkBlock}
                        disabled = {!this.state.enableBtn}
                        data-test="button-clear"
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({ showNetworkBlockConfirm: true });
                        }}
                        data-test="button-delete"
                        disabled = {!this.state.enableBtn}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                        {this.state.submitStatusNetworkBlock === "OK" && (
                          <div className="alert alert-success alert-padding notification">
                            {this.state.submitMsgNetworkBlock}
                          </div>
                        )}
                        {this.state.submitStatusNetworkBlock === "Error" && (
                          <div className="alert alert-danger alert-padding notification">
                            {this.state.submitMsgNetworkBlock}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </form>
              </div>
            </div>
            {this.getNetworkBlockModal()}
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                CROSS-NETWORK AGENT UPDATE DETAILS
              </div>
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label" className="font-weight-bold">
                    No of records per page
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlNetworkBlock(e);
                      this.onPageSizeChangedNetworkBlock(e);
                    }}
                    value={this.state.paginationValNetwork}
                    name="paginationDropdownNetwork"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionNetworkBlock.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactNetworkB
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyNetworkBlock}
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateNetwork
                    }
                    overlayNoRowsTemplate={
                      this.state.overlayNoRowsTemplateNetwork
                    }
                  ></AgGridReactNetworkB>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NetworkBlock;
