import React, { Component } from "react";
import Header from "../../header/header";

import axios from "axios";
import { getHeadersShared } from "../../utility/utility";

export default class DisplayCountryRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      merchant: [],
      path: "P",
      merchantId: "",
      paymentType: "VISA/MC",
      paymentSubType: 'V',
      productCode: "",
      prodCode: "",
      type: "",
      flag: 0,
      displayCountryRules: {
        country: {
          numericCode: "",
          alphaCode: "",
          isoCode: "",
          name: "",
        },
        businessRules: {
          available: "N",
          limit: {
            days: 0,
            limitNew: 0,
            repeat: 0,
          },
          limitDaily: "0",
          verifiedLimit: {
            days: 0,
            limitNew: 0,
            repeat: 0,
          },
          verifiedLimitDaily: "0",
          fxCountryNum: "",
          startDate: "",
        },
        velocity: {
          transaction: 0,
          days: 0,
        },
        byPass: {
          allow: "N",
          rc: 0,
          prin: 0,
          cvv: 0,
        },
        idRequest: {
          yn: "N",
          trans: 0,
          aa: "N",
        },
        request: {
          cvv: "N",
          avs: "N",
        },
        newRulesDef: "N",
        saturn: {
          status: "Not Enabled",
          autoApprRateCode: 0,
        },
        authReversal: "N",
        experian: {
          status: "N",
          mt3: "N",
          mt6: "N",
        },
        emailDetails: "N",
        ibpPayment: "N",
        localCardPayment: "N",
        euIdDetails: "N",
        humanValidation: {
          product: "N",
          queuelist:"",
          queueId:""
        },
      },
      mt6rulesflag: true,
      submitStatusDisplayCountryRules: null,
      submitMsgDisplayCountryRules: "",
    };
  }

  componentDidMount() {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/businessrules/countryrules`,
        {
          headers: {
            ...getHeadersShared().headers,
            countryCode: this.props.location.state.countryCode,
            channel: this.props.location.state.channel,
            product: this.props.location.state.product,
            Authorization:this.props.location.state.token,
          },
        }
      )
      .then((response) => {
        //console.log(response)
        if (this.props.location.state.channel === "190") {
          this.setState({
            displayCountryRulesChannel: "WEB",
          });
        } else if (this.props.location.state.channel === "280") {
          this.setState({
            displayCountryRulesChannel: "TMT",
          });
        } else if (this.props.location.state.channel === "SMP") {
          this.setState({
            displayCountryRulesChannel: "SMP",
          });
        }
        if (this.props.location.state.product === "1") {
          this.setState({
            displayCountryRulesProduct: "MT",
          });
        } else if (this.props.location.state.product === "2") {
          this.setState({
            displayCountryRulesProduct: "QC",
          });
        }
        this.setState({
          displayCountryRules: response.data,
          channel: this.props.location.state.channel,
          countryCode: this.props.location.state.countryCode,
          product: this.props.location.state.product,
          products: response.data.product,
          merchant: response.data.merchant,
          accessGrantedWriteBRM:this.props.location.state.accessGrantedWriteBRM,
        });
        if(response.data.humanValidation.product === "" || response.data.humanValidation.queuelist === []){
          this.setState({
            mt6rulesflag:false
          })
        }
      });
  
  }
  onChangeControlDisplayCountryRules = (inputName, e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    //console.log(newName, newValue);
    this.setState((prevState) => {
      return {
        displayCountryRules: {
          ...prevState.displayCountryRules,
          [inputName]: {
            ...prevState.displayCountryRules[inputName],
            [newName]: newValue,
          },
        },
      };
    });
  };

  onChangeControlDisplayCountryRulesOnlyDecimal = (inputName, e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    //console.log(newName, newValue);
    this.setState((prevState) => {
      return {
        displayCountryRules: {
          ...prevState.displayCountryRules,
          [inputName]: {
            ...prevState.displayCountryRules[inputName],
            [newName]: newValue.replace(/[^\d.]/, ""),
          },
        },
      };
    });
  };

  onChangeControlDisplayCountryRulesQueueList = (inputName, e) => {
    const { name, value } = e.target;
      this.setState((prevState) => {
        return {
          displayCountryRules: {
            ...prevState.displayCountryRules,
            [inputName]: {
              ...prevState.displayCountryRules[inputName],
                [name]: value,
                queueId: value,
            },
          },
        };
      });
  };

  onChangeControlDisplayCountryRulesOnlyNumbers = (inputName, e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    //console.log(newName, newValue);
    this.setState((prevState) => {
      return {
        displayCountryRules: {
          ...prevState.displayCountryRules,
          [inputName]: {
            ...prevState.displayCountryRules[inputName],
            [newName]: newValue.replace(/[^\d]/, ""),
          },
        },
      };
    });
  };

  onChangeControlNonNestedDisplayCountryRules = (e) => {
    const { name, value } = e.target;
    //console.log(name, value);
    this.setState((prevState) => {
      return {
        displayCountryRules: {
          ...prevState.displayCountryRules,
          [name]: value,
        },
      };
    });
  };
  // onChangeControlNestedDisplayCountryRules = (inputName1, inputName2, e) => {
  //   let newValue = e.target.value;
  //   let newName = e.target.name;
  //   this.setState((prevState) => {
  //     return {
  //       displayCountryRules: {
  //         ...prevState.displayCountryRules,
  //         [inputName1]: {
  //           ...prevState.displayCountryRules[inputName1],
  //           [inputName2]: {
  //             ...prevState.displayCountryRules[inputName1][inputName2],
  //             [newName]: newValue,
  //           },
  //         },
  //       },
  //     };
  //   });
  // };

  onChangeControlNestedDisplayCountryRulesOnlyNumber = (
    inputName1,
    inputName2,
    e
  ) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    this.setState((prevState) => {
      return {
        displayCountryRules: {
          ...prevState.displayCountryRules,
          [inputName1]: {
            ...prevState.displayCountryRules[inputName1],
            [inputName2]: {
              ...prevState.displayCountryRules[inputName1][inputName2],
              [newName]: newValue.replace(/[^\d]/, ""),
            },
          },
        },
      };
    });
  };

  onChangeControlNestedDisplayCountryRulesDecimalNumber = (
    inputName1,
    inputName2,
    e
  ) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    this.setState((prevState) => {
      return {
        displayCountryRules: {
          ...prevState.displayCountryRules,
          [inputName1]: {
            ...prevState.displayCountryRules[inputName1],
            [inputName2]: {
              ...prevState.displayCountryRules[inputName1][inputName2],
              [newName]: newValue.replace(/[^\d.]/, ""),
            },
          },
        },
      };
    });
  };

  onChangeProductTableDisplayCountryRules = (el, e) => {
    let index = this.state.products.indexOf(el);
    let newArray = this.state.products;
    newArray[index].productCode = e.target.value;
    //console.log(newArray);
    this.setState({ products: newArray });
  };

  onChangeMerchantTableDisplayCountryRules = (el, e) => {
    let index = this.state.merchant.indexOf(el);
    let newArray = this.state.merchant;
    newArray[index].merchantId = e.target.value;
    this.setState({ merchant: newArray });
  };

  onChangeDisplayCountryRules = (e) => {
    //console.log(e.target.name, e.target.value);
    //console.log(e.target);
    if (e.target.name === "paymentType") {
      var index = e.nativeEvent.target.selectedIndex;
      this.setState({
        paymentType: e.nativeEvent.target[index].text,
        paymentSubType: e.target.value,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onChangeDisplayCountryRulesCharInt = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onValidateDisplayCountryRules = () => {
    if (this.state.displayCountryRules.businessRules.available === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Select The Value For Available",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.limit.days === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Limit Days",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.limit.limitNew === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The  Value For Limit New",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.limit.limitNew > 9999999999.99) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Limit New Value Cannot Be Greater Than 9999999999.99",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.limit.repeat === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Limit Repeat",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.limit.repeat > 9999999999.99) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Limit Repeat Value Cannot Be Greater Than 9999999999.99",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.limitDaily === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Limit Daily",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.limitDaily > 9999999999.99) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Limit Daily Value Cannot Be Greater Than 9999999999.99",
      });
      return false;
    }
    if (
      this.state.displayCountryRules.businessRules.verifiedLimit.days === ""
    ) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Verified Days",
      });
      return false;
    }
    if (
      this.state.displayCountryRules.businessRules.verifiedLimit.limitNew === ""
    ) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Verified Limit New",
      });
      return false;
    }
    if (
      this.state.displayCountryRules.businessRules.verifiedLimit.limitNew > 9999999999.99
    ) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Verified Limit New Value Cannot Be Greater Than 9999999999.99",
      });
      return false;
    }
    if (
      this.state.displayCountryRules.businessRules.verifiedLimit.repeat === ""
    ) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules:
          "Please Enter The Value For Verified Limit Repeat",
      });
      return false;
    }
    if (
      this.state.displayCountryRules.businessRules.verifiedLimit.repeat > 9999999999.99
    ) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules:
          "Verified Limit Repeat Value Cannot Be Greater Than 9999999999.99",
      });
      return false;
    }
    if (
      this.state.displayCountryRules.businessRules.verifiedLimitDaily === ""
    ) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Verified Limit Daily",
      });
      return false;
    }
    if (
      this.state.displayCountryRules.businessRules.verifiedLimitDaily > 9999999999.99
    ) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Verified Limit Daily Value Cannot Be Greater Than 9999999999.99",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.fxCountryNum === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For FX Country Num",
      });
      return false;
    }
    if (this.state.displayCountryRules.businessRules.startDate === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Start Date",
      });
      return false;
    }
    if (this.state.displayCountryRules.velocity.transaction === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Velocity Transaction",
      });
      return false;
    }
    if (this.state.displayCountryRules.velocity.transaction > 99999) {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Velocity Transaction Value Cannot Be Greater Than 99999",
      });
      return false;
    }
    if (this.state.displayCountryRules.velocity.days === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Velocity Days",
      });
      return false;
    }
    if (this.state.displayCountryRules.byPass.allow === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Select The Value For By Pass Allow",
      });
      return false;
    }
    if (this.state.displayCountryRules.byPass.rc === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For By Pass Rate Code",
      });
      return false;
    }
    if (this.state.displayCountryRules.byPass.prin === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For By Pass Prin",
      });
      return false;
    }
    if (this.state.displayCountryRules.byPass.cvv === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For By Pass CVV",
      });
      return false;
    }
    if (this.state.displayCountryRules.idRequest.yn === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Select The Value For ID Request Y/N",
      });
      return false;
    }
    if (this.state.displayCountryRules.idRequest.trans === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For ID Request Trans",
      });
      return false;
    }
    if (this.state.displayCountryRules.idRequest.aa === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules:
          "Please Select The Value For ID Request Auto Approve",
      });
      return false;
    }
    if (this.state.displayCountryRules.request.cvv === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Select The Value For Request CVV",
      });
      return false;
    }
    if (this.state.displayCountryRules.request.avs === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Select The Value For Request AVS",
      });
      return false;
    }
    if (this.state.displayCountryRules.newRulesDef === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Select The Value For New Rules Def",
      });
      return false;
    }
    if (this.state.displayCountryRules.humanValidation.product === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Product",
      });
      return false;
    }
    if (this.state.displayCountryRules.humanValidation.queueId === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Select The Value For Queuelist",
      });
      return false;
    }
    if (this.state.productCode && this.state.type === "") {
      this.setState({
        submitStatusDisplayCountryRules: "Error",
        submitMsgDisplayCountryRules: "Please Enter The Value For Product Type",
      });
      return false;
    }
    return true;
  };

  handleSubmitDisplayCountryRules = () => {
    if (!this.onValidateDisplayCountryRules()) {
      return;
    }
    if (this.state.productCode !== "") {
      let newProduct = {
        productCode: this.state.productCode,
        type: this.state.type,
      };
      let ProductArray = this.state.products;
      ProductArray.push(newProduct);
      this.setState({ products: ProductArray });
    }
    if (this.state.merchantId !== "") {
      let newMerchant = {
        merchantId: this.state.merchantId,
        paymentSubType: this.state.paymentSubType,
        path: this.state.path,
      };
      let merchantArray = this.state.merchant;
      merchantArray.push(newMerchant);
      this.setState({ merchant: merchantArray });
    }
    this.setState((prevState) => {
      return {
        displayCountryRules: {
          ...prevState.displayCountryRules,
          product: this.state.products,
          merchant: this.state.merchant,
        },
      };
    });

    let submittedObj = {};
    submittedObj = this.state.displayCountryRules;
    submittedObj.humanValidation.queuelist = [];
    //console.log(submittedObj)
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/businessrules/countryrules`,
        submittedObj,
        {
          headers: {
            ...getHeadersShared().headers,
            channel: this.state.channel,
            countryCode: this.state.countryCode,
            product: this.state.product,
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusDisplayCountryRules: "OK",
            submitMsgDisplayCountryRules: response.data.responseString,
            mt6rulesflag:true
          });
        } else {
          this.setState({
            submitStatusDisplayCountryRules: "Error",
            submitMsgDisplayCountryRules: response.data.responseString,
          });
        }
      });
    this.setState({ productCode: "", merchantId: "", type: "" });
  };

  // handleMT6RulesDisplayCountryRules = () => {
  //   this.props.history.push({
  //     pathname: "/mt6-rules",
  //     state: this.props.location.state,
  //   });
  // };
  handleBusinessRuleMaintDisplayCountryRules = () => {
    this.props.history.push("/business-rules-maintenance");
  };

  handleQueueMaintenanceDetails = () => {
    // console.log(this.props.location.state);
    this.props.history.push({
      pathname: "/queue-maintenance",
      state: this.props.location.state,
    });
  };

  handlePartnerEmailDetails = () => {
    this.props.history.push({
      pathname: "/partner-email-details",
      state: this.props.location.state,
    });
  };

  getQueuelist = () => {
    let queuelist = [
      { namequeuelist: "ABCDEF", labelqueuelist: "TEST FOR DMS ERROR11" },
	  { namequeuelist: "ABCDEFGH", labelqueuelist: "TEST FOR DMS ER" },
	  { namequeuelist: "AGBR029", labelqueuelist: "WU BANK BRAZIL CB" },
	  { namequeuelist: "AGCCOFAC", labelqueuelist: "AGED WEB" },
	  { namequeuelist: "AGEDFAC", labelqueuelist: "AGED" },
	  { namequeuelist: "AGOVFAC", labelqueuelist: "AGED OV" },
	  { namequeuelist: "AGVGFAC", labelqueuelist: "AGED VIGO" },
	  { namequeuelist: "ANYSTAGE", labelqueuelist: "ANY STAGED" },
	  { namequeuelist: "APACKYC", labelqueuelist: "KYC APAC" },
	  { namequeuelist: "APACLPMT", labelqueuelist: "LPMT APAC" },
	  { namequeuelist: "APKYCCB", labelqueuelist: "KYC APAC CB" },
	  { namequeuelist: "APLPMTCB", labelqueuelist: "LPMT APAC CB" },
	  { namequeuelist: "AROCACR", labelqueuelist: "ASIA PACIFIC ADD HANDLING" },
	  { namequeuelist: "AROCOVL", labelqueuelist: "ASIA PACIFIC OVL" },
	  { namequeuelist: "AROCSPV", labelqueuelist: "ASIA PACIFIC SEQ" },
	  { namequeuelist: "BANKVALS", labelqueuelist: "AWAITING CB" },
	  { namequeuelist: "BRIDACR", labelqueuelist: "DOM ADD HANDL" },
	  { namequeuelist: "BRIDCALL", labelqueuelist: "SPV/OVL CB" },
	  { namequeuelist: "BRIDCBCP", labelqueuelist: "DOM CALL CAMP CB" },
	  { namequeuelist: "BRIDCCBR", labelqueuelist: "WU CCB REFUND" },
	  { namequeuelist: "BRIDCMPL", labelqueuelist: "WU CMPL" },
	  { namequeuelist: "BRIDCPGN", labelqueuelist: "DOM CALL CAMP" },
	  { namequeuelist: "BRIDCUBA", labelqueuelist: "CUBA REM" },
	  { namequeuelist: "BRIDFAC", labelqueuelist: "GOVSAN" },
	  { namequeuelist: "BRIDKYC", labelqueuelist: "KYC AMERICAS" },
	  { namequeuelist: "BRIDLPMT", labelqueuelist: "DOM LPMT" },
	  { namequeuelist: "BRIDOVL", labelqueuelist: "OVL" },
	  { namequeuelist: "BRIDOVLX", labelqueuelist: "DOM OVL DUPE" },
	  { namequeuelist: "BRIDPEP", labelqueuelist: "PEP" },
	  { namequeuelist: "BRIDPFC", labelqueuelist: "INTR" },
	  { namequeuelist: "BRIDSEZ", labelqueuelist: "COMPLIANCE SPL ESCALATIONS" },
	  { namequeuelist: "BRIDSNDR", labelqueuelist: "RTRA REFUND QUEUE" },
	  { namequeuelist: "BRIDSPV", labelqueuelist: "SPV" },
	  { namequeuelist: "CALLBACK", labelqueuelist: "CB QUEUE DESC" },
	  { namequeuelist: "CALLFAC", labelqueuelist: "GOVSAN CB" },
	  { namequeuelist: "CALLKYC", labelqueuelist: "KYC AMERICAS CB" },
	  { namequeuelist: "CALLPFC", labelqueuelist: "INTR CB" },
	  { namequeuelist: "CALLSEZ", labelqueuelist: "COMPLIANCE SPL ESCALATIONS CB" },
	  { namequeuelist: "CAN1LPMT", labelqueuelist: "CANADIAN LPMT" },
	  { namequeuelist: "CAOVFAC", labelqueuelist: "GOVSAN OV CB" },
	  { namequeuelist: "CAOVKYC", labelqueuelist: "KYC OV CB" },
	  { namequeuelist: "CAOVPFC", labelqueuelist: "INTR OV CB" },
	  { namequeuelist: "CAOVSEZ", labelqueuelist: "SEIZURE OV CB" },
	  { namequeuelist: "CAREDGSI", labelqueuelist: "DIGITAL GSI REFUND" },
	  { namequeuelist: "CAREDNGS", labelqueuelist: "DIGITAL NON-GSI REFUND" },
	  { namequeuelist: "CAREOGSI", labelqueuelist: "OV GSI REFUND" },
	  { namequeuelist: "CARERGSI", labelqueuelist: "RETAIL GSI REFUND" },
	  { namequeuelist: "CARERNGS", labelqueuelist: "RETAIL NON-GSI REFUND" },
	  { namequeuelist: "CARERPA", labelqueuelist: "RETAIL GSI ROBOTIC REFUND" },
	  { namequeuelist: "CAREVGSI", labelqueuelist: "VIGO GSI REFUND" },
	  { namequeuelist: "CAVGFAC", labelqueuelist: "GOVSAN VIGO CB" },
	  { namequeuelist: "CAVGKYC", labelqueuelist: "KYC VIGO CB" },
	  { namequeuelist: "CAVGPFC", labelqueuelist: "INTR VIGO CB" },
	  { namequeuelist: "CAVGSEZ", labelqueuelist: "SEIZURE VIGO CB" },
	  { namequeuelist: "CBCCAKYC", labelqueuelist: "KYC WEB CB" },
	  { namequeuelist: "CBCCAPFC", labelqueuelist: "INTR WEB CB" },
	  { namequeuelist: "CBCCOFAC", labelqueuelist: "GOVSAN WEB CB" },
	  { namequeuelist: "CBCCSEIZ", labelqueuelist: "SEIZURE WEB CB" },
	  { namequeuelist: "CCAA0001", labelqueuelist: "WEB VALIDATION INTERNATIONAL - APROC" },
	  { namequeuelist: "CCAA0002", labelqueuelist: "WEB VALIDATION INTERNATIONAL - LAROC" },
	  { namequeuelist: "CCAA0003", labelqueuelist: "EUROPE1 AUTH" },
	  { namequeuelist: "CCAA0004", labelqueuelist: "EUROPE2 AUTH" },
	  { namequeuelist: "CCAA0005", labelqueuelist: "EUROPE3 AUTH" },
	  { namequeuelist: "CCAAAKYC", labelqueuelist: "KYC WEB" },
	  { namequeuelist: "CCAAAPEP", labelqueuelist: "PEP WEB" },
	  { namequeuelist: "CCAAAPFC", labelqueuelist: "INTR WEB" },
	  { namequeuelist: "CCAAAUTH", labelqueuelist: "TMT VALIDATION" },
	  { namequeuelist: "CCAADOLL", labelqueuelist: "WEBMT VALIDATION" },
	  { namequeuelist: "CCAAOFAC", labelqueuelist: "GOVSAN WEB" },
	  { namequeuelist: "CCAAOTHR", labelqueuelist: "WEB VALIDATION INTERNATIONAL - FEXCO" },
	  { namequeuelist: "CCAASEIZ", labelqueuelist: "SEIZURE WEB" },
	  { namequeuelist: "CLHMSPV", labelqueuelist: "CONVENIENCE CALL" },
	  { namequeuelist: "CROCACR", labelqueuelist: "LAROC ACRQ" },
	  { namequeuelist: "CROCOVL", labelqueuelist: "LAROC OVLQ" },
	  { namequeuelist: "CROCSPV", labelqueuelist: "LAROC SEQ" },
	  { namequeuelist: "CUBACALL", labelqueuelist: "CUBA CB" },
	  { namequeuelist: "CUBACBSP", labelqueuelist: "CUBA CB SPECIAL" },
	  { namequeuelist: "CUBAECAL", labelqueuelist: "CUBA EMIGRATION CB" },
	  { namequeuelist: "CUBAEFAX", labelqueuelist: "CUBA EMI" },
	  { namequeuelist: "CUBAFAX", labelqueuelist: "CUBA REM" },
	  { namequeuelist: "CUBANATL", labelqueuelist: "OLD CUBA NATL" },
	  { namequeuelist: "CUBAREM", labelqueuelist: "CUBA NON TOURISM TRAVEL" },
	  { namequeuelist: "CUBASPL", labelqueuelist: "CUBA SPL LICENSE" },
	  { namequeuelist: "CUBASPLV", labelqueuelist: "VIGO CUBA SPL LICENSE" },
	  { namequeuelist: "DATACOCB", labelqueuelist: "GSI DATA COLLECTION CB" },
	  { namequeuelist: "DATACOLL", labelqueuelist: "GSI DATA COLLECTION" },
	  { namequeuelist: "DATACOOB", labelqueuelist: "GSI DATA COLLECTION OV CB" },
	  { namequeuelist: "DATACOOV", labelqueuelist: "GSI DATA COLLECTION OV" },
	  { namequeuelist: "DATACOVB", labelqueuelist: "GSI DATA COLLECTION VIGO CB" },
	  { namequeuelist: "DATACOVG", labelqueuelist: "GSI DATA COLLECTION VIGO" },
	  { namequeuelist: "DMLPMTCB", labelqueuelist: "LPMT AMERICAS CB" },
	  { namequeuelist: "DNOVFAC", labelqueuelist: "DENVER OV" },
	  { namequeuelist: "DNVGFAC", labelqueuelist: "DENVER VIGO" },
	  { namequeuelist: "DNVRFAC", labelqueuelist: "DENVER" },
	  { namequeuelist: "DOM1LPMT", labelqueuelist: "LPMT AMERICAS" },
	  { namequeuelist: "DUPESPV", labelqueuelist: "DUPE" },
	  { namequeuelist: "DVCCOFAC", labelqueuelist: "DENVER WEB" },
	  { namequeuelist: "EPAYSPV", labelqueuelist: "PAYCASH WILL PAY" },
	  { namequeuelist: "EPREJECT", labelqueuelist: "D2B REJECTIONS" },
	  { namequeuelist: "EUROACR", labelqueuelist: "EU ADD HANDL" },
	  { namequeuelist: "EUROOVL", labelqueuelist: "EU OVL" },
	  { namequeuelist: "EUROSPV", labelqueuelist: "EU SEQ" },
	  { namequeuelist: "FASL1REV", labelqueuelist: "INTL FAST REV<" },
	  { namequeuelist: "FASL2CAL", labelqueuelist: "INTL FAST REV CB" },
	  { namequeuelist: "FASL3DEN", labelqueuelist: "INTL FAST REV DVR" },
	  { namequeuelist: "FASL4AUT", labelqueuelist: "INTL FAST REV AUTH SRCE" },
	  { namequeuelist: "FASL6SEZ", labelqueuelist: "INTL FAST REV SEIZ" },
	  { namequeuelist: "FAST1PFC", labelqueuelist: "INTR FREV" },
	  { namequeuelist: "FAST1REV", labelqueuelist: "GOVSAN FREV" },
	  { namequeuelist: "FAST1SEZ", labelqueuelist: "SEIZURE FREV" },
	  { namequeuelist: "FAST2CAL", labelqueuelist: "FAST REV CB" },
	  { namequeuelist: "FAST3DEN", labelqueuelist: "FAST REV DVR" },
	  { namequeuelist: "FAST4AUT", labelqueuelist: "FAST REV AUTH SRCE" },
	  { namequeuelist: "FAST6SEZ", labelqueuelist: "FAST REV SEIZ" },
	  { namequeuelist: "FRD1SPV", labelqueuelist: "SUSPICIOUS" },
	  { namequeuelist: "FTCO1REV", labelqueuelist: "GOVSAN FREV WEB" },
	  { namequeuelist: "FTCO2CAL", labelqueuelist: "GOVSAN FREV WEB CB" },
	  { namequeuelist: "FTCP1REV", labelqueuelist: "INTR FREV WEB" },
	  { namequeuelist: "FTCP2CAL", labelqueuelist: "INTR FREV WEB CB" },
	  { namequeuelist: "FTCS1REV", labelqueuelist: "SEIZURE FREV WEB" },
	  { namequeuelist: "FTOV1PFC", labelqueuelist: "INTR FREVOV" },
	  { namequeuelist: "FTOV1REV", labelqueuelist: "GOVSAN FREV OV" },
	  { namequeuelist: "FTOV1SEZ", labelqueuelist: "SEIZURE FREV OV" },
	  { namequeuelist: "FTOV2CAL", labelqueuelist: "OV FAST REV CB" },
	  { namequeuelist: "FTOV3DEN", labelqueuelist: "OV FAST REV DVR" },
	  { namequeuelist: "FTOV4AUT", labelqueuelist: "OV FAST REV AUTH SRCE" },
	  { namequeuelist: "FTOV6SEZ", labelqueuelist: "OV FAST REV SEIZ" },
	  { namequeuelist: "FTVG1PFC", labelqueuelist: "INTR FREV VIGO" },
	  { namequeuelist: "FTVG1REV", labelqueuelist: "GOVSAN FREV VIGO" },
	  { namequeuelist: "FTVG1SEZ", labelqueuelist: "SEIZURE FREV VIGO" },
	  { namequeuelist: "FTVG2CAL", labelqueuelist: "VIGO FAST REV CB" },
	  { namequeuelist: "FTVG3DEN", labelqueuelist: "VIGO FAST REV DVR" },
	  { namequeuelist: "FTVG4AUT", labelqueuelist: "VIGO FAST REV AUTH SRCE" },
	  { namequeuelist: "FTVG6SEZ", labelqueuelist: "VIGO FAST REV SEIZ" },
	  { namequeuelist: "HIGHRISK", labelqueuelist: "HIGH RISK" },
	  { namequeuelist: "HOLDFAC", labelqueuelist: "GOVSAN HOLD GEO LOGIC" },
	  { namequeuelist: "HOLDOVL", labelqueuelist: "DOM KSK HOLD" },
	  { namequeuelist: "HOMEDLY", labelqueuelist: "HOME DELIVERY" },
	  { namequeuelist: "INKYCCB", labelqueuelist: "KYC EMEA CB" },
	  { namequeuelist: "INLPMTCB", labelqueuelist: "LPMT EMEA CB" },
	  { namequeuelist: "INTLACR", labelqueuelist: "INTL ADD HANDLING" },
	  { namequeuelist: "INTLCBCP", labelqueuelist: "INTL CALLING CAMPAIGN CB" },
	  { namequeuelist: "INTLCFAC", labelqueuelist: "INTL GOVSAN CB" },
	  { namequeuelist: "INTLCPFC", labelqueuelist: "INTL INTR CB" },
	  { namequeuelist: "INTLCPGN", labelqueuelist: "INTL CALLING CAMPAIGN" },
	  { namequeuelist: "INTLFAC", labelqueuelist: "INTL GOVSAN" },
	  { namequeuelist: "INTLKYC", labelqueuelist: "KYC EMEA" },
	  { namequeuelist: "INTLLPMT", labelqueuelist: "LPMT EMEA" },
	  { namequeuelist: "INTLPFC", labelqueuelist: "INTL INTR" },
	  { namequeuelist: "NATLCUBA", labelqueuelist: "CUBA NATL" },
	  { namequeuelist: "NATLCUBD", labelqueuelist: "CUBA NATL WEB" },
	  { namequeuelist: "NATVCUBA", labelqueuelist: "VIGO CUBA NATL" },
	  { namequeuelist: "NET4050", labelqueuelist: "SCOTIA BANK FRAUD" },
	  { namequeuelist: "NET4737", labelqueuelist: "US BANK FRAUD 4737" },
	  { namequeuelist: "NET4780", labelqueuelist: "US BANK FRAUD 4780" },
	  { namequeuelist: "NET5276", labelqueuelist: "ENSTREAM FRAUD" },
	  { namequeuelist: "ORVLACR", labelqueuelist: "OV VAL ADD HANDLING" },
	  { namequeuelist: "ORVLCCB", labelqueuelist: "OV FRAUD CALL BACK QUEUE" },
	  { namequeuelist: "ORVLCCBR", labelqueuelist: "OV CCB REFUND" },
	  { namequeuelist: "ORVLCMPL", labelqueuelist: "OV CMPL" },
	  { namequeuelist: "ORVLDUP", labelqueuelist: "OV DUPLICATE Q" },
	  { namequeuelist: "ORVLFAC", labelqueuelist: "GOVSAN OV" },
	  { namequeuelist: "ORVLKYC", labelqueuelist: "KYC OV" },
	  { namequeuelist: "ORVLLPMT", labelqueuelist: "OV LPMT" },
	  { namequeuelist: "ORVLOV", labelqueuelist: "OV OVL Q" },
	  { namequeuelist: "ORVLOVL", labelqueuelist: "OV FRAUD QUEUE" },
	  { namequeuelist: "ORVLPEP", labelqueuelist: "OV PEP" },
	  { namequeuelist: "ORVLPFC", labelqueuelist: "INTR OV" },
	  { namequeuelist: "ORVLSEZ", labelqueuelist: "SEIZURE OV" },
	  { namequeuelist: "ORVLSNDR", labelqueuelist: "OV RTRA REFUND QUEUE" },
	  { namequeuelist: "ORVLSPV", labelqueuelist: "OV VAL SEQ" },
	  { namequeuelist: "OVD2BREJ", labelqueuelist: "OV D2B REJECTIONS" },
	  { namequeuelist: "OVLPMTCB", labelqueuelist: "OV LPMT CB" },
	  { namequeuelist: "PKUP001", labelqueuelist: "PICKUP MOBILE" },
	  { namequeuelist: "PKUP002", labelqueuelist: "PICKUP GBS" },
	  { namequeuelist: "PKUP003", labelqueuelist: "PICKUP OTHER" },
	  { namequeuelist: "RCCAAFAC", labelqueuelist: "GOVSAN RCVR WEB" },
	  { namequeuelist: "RCCAAPFC", labelqueuelist: "INTR RCVR WEB" },
	  { namequeuelist: "RCCAASEZ", labelqueuelist: "SEIZURE RCVR WEB" },
	  { namequeuelist: "RCVRFAC", labelqueuelist: "GOVSAN RCVR" },
	  { namequeuelist: "RCVRPFC", labelqueuelist: "INTR RCVR" },
	  { namequeuelist: "RCVRSEZ", labelqueuelist: "SEIZURE RCVR" },
	  { namequeuelist: "RGNTACR", labelqueuelist: "ARGENTINA ADD HANDLING" },
	  { namequeuelist: "RGNTOVL", labelqueuelist: "ARGENTINA OVL" },
	  { namequeuelist: "RGNTSPV", labelqueuelist: "ARGENTINA SEQ" },
	  { namequeuelist: "RORVFAC", labelqueuelist: "GOVSAN RCVR OV" },
	  { namequeuelist: "RORVPFC", labelqueuelist: "INTR RCVR OV" },
	  { namequeuelist: "RORVSEZ", labelqueuelist: "SEIZURE RCVR OV" },
	  { namequeuelist: "RPSECQUE", labelqueuelist: "RTRA WUBS QCASH SEC" },
	  { namequeuelist: "RTRADGCB", labelqueuelist: "DIGITAL CONSUMER FRAUD CALLBACK" },
	  { namequeuelist: "RTRADIGF", labelqueuelist: "DIGITAL CONSUMER FRAUD" },
	  { namequeuelist: "RTRADIGR", labelqueuelist: "DIGITAL CCB REFUND" },
	  { namequeuelist: "RVIGFAC", labelqueuelist: "GOVSAN RCVR VIGO" },
	  { namequeuelist: "RVIGPFC", labelqueuelist: "INTR RCVR VIGO" },
	  { namequeuelist: "RVIGSEZ", labelqueuelist: "SEIZURE RCVR VIGO" },
	  { namequeuelist: "SEIZPAY", labelqueuelist: "SEIZURE" },
	  { namequeuelist: "SLNDSPV", labelqueuelist: "SOUTHLAND WILL PAY" },
	  { namequeuelist: "SPLCUBA", labelqueuelist: "CUBA NATL SPL" },
	  { namequeuelist: "SPLVCUBA", labelqueuelist: "VIGO CUBA NATL SPL" },
	  { namequeuelist: "SWPYSPV", labelqueuelist: "STAGED SWIFT PAY" },
	  { namequeuelist: "TEST01", labelqueuelist: "TESTING TLT" },
	  { namequeuelist: "TEST1", labelqueuelist: "HFGHFGH" },
	  { namequeuelist: "TEST123", labelqueuelist: "TEST123" },
	  { namequeuelist: "TEST1234", labelqueuelist: "TEST1234" },
	  { namequeuelist: "TESTN", labelqueuelist: "SDGSDG" },
	  { namequeuelist: "TESTNIT", labelqueuelist: "RTTRR" },
	  { namequeuelist: "TESTNITI", labelqueuelist: "HDFHF" },
	  { namequeuelist: "TESTSIVA", labelqueuelist: "TEST" },
	  { namequeuelist: "TEST_123", labelqueuelist: "TESTING" },
	  { namequeuelist: "TPMTSPV", labelqueuelist: "TMT WILL PAY" },
	  { namequeuelist: "VGD2BREJ", labelqueuelist: "VIGO D2B REJECTIONS" },
	  { namequeuelist: "VGLPMTCB", labelqueuelist: "VIGO LPMT CB" },
	  { namequeuelist: "VIGOACR", labelqueuelist: "VIGO ADD HANDLING" },
	  { namequeuelist: "VIGOCCB", labelqueuelist: "VIGO FRAUD CALL BACK QUEUE" },
	  { namequeuelist: "VIGOCCBR", labelqueuelist: "VIGO CCB REFUND" },
	  { namequeuelist: "VIGOCMPL", labelqueuelist: "VIGO CMPL" },
	  { namequeuelist: "VIGODUP", labelqueuelist: "VIGO DUPLICATE Q" },
	  { namequeuelist: "VIGOFAC", labelqueuelist: "GOVSAN VIGO" },
	  { namequeuelist: "VIGOKYC", labelqueuelist: "KYC VIGO" },
	  { namequeuelist: "VIGOLPMT", labelqueuelist: "VIGO LPMT" },
	  { namequeuelist: "VIGOOVL", labelqueuelist: "VIGO FRAUD QUEUE" },
	  { namequeuelist: "VIGOPEP", labelqueuelist: "VIGO PEP" },
	  { namequeuelist: "VIGOPFC", labelqueuelist: "INTR VIGO" },
	  { namequeuelist: "VIGOSEZ", labelqueuelist: "SEIZURE VIGO" },
	  { namequeuelist: "VIGOSNDR", labelqueuelist: "VIGO RTRA REFUND QUEUE" },
	  { namequeuelist: "WILDCARD", labelqueuelist: "ANY QUEUE" },
	  { namequeuelist: "YLPHSPV", labelqueuelist: "DIRCON" },
	  { namequeuelist: "YLPHXYZ", labelqueuelist: "DIRCON XYZ REMOVE TODAY" },
	  { namequeuelist: "YLPHZZZ", labelqueuelist: "DIRCON XYZ REMOVE TODAY" },
    ];
    let resultListDCR =  queuelist.map((item) => (
      <option key={[item.namequeuelist, '-', item.labelqueuelist]} value={item.namequeuelist}>
        {item.labelqueuelist}
      </option>
    ));
    return [<option value="">-Select-</option>, resultListDCR];
  };

  getYesNoDCR = (uniqueKey) => {
    let valOfYN = ["Y","N"];
    let resultValOfYN =  valOfYN.map((item) => (
      <option key={[item,uniqueKey]} value={item}>
        {item}
      </option>
    ));
    return [<option value="">-Select-</option>,resultValOfYN];
  };

  getEnableNotDCR = () => {
    let valENDCR = [{enKey:'Y',enVal:'Enabled'},{enKey:'N',enVal:'Not Enabled'}]
    return valENDCR.map((item) => (
      <option key={item.enKey} value={item.enKey}>
        {item.enVal}
      </option>
    ));
  }

  getPaymentType = (paymentSubType) => {
    if(paymentSubType === 'A') {
      return 'Am Express'
    } else if(paymentSubType === 'V') {
      return 'Visa / MC'
    }  else if(paymentSubType === 'P') {
      return 'BPAY'
    }  else if(paymentSubType === 'B') {
      return 'IBP'
    }  else if(paymentSubType === 'D') {
      return 'Discover Card'
    }  else if(paymentSubType === 'L') {
      return 'Local Card'
    }  else if(paymentSubType === 'Z') {
      return 'Pin Debit'
    }  else if(paymentSubType === 'U') {
      return 'Unknown'
    }
  }

  render() {
    // console.log(this.props.location.state);
    var date = new Date();
    var currentDate = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return (
      <>
        <Header
          history={this.props.history}
          heading="BUSINESS RULES MAINTENANCE"
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-center main-heading">
                BUSINESS RULE MAINTENANCE FOR{" "}
                {this.state.displayCountryRulesChannel}{" "}
                {this.state.displayCountryRulesProduct} Date&nbsp;:&nbsp;{month>9?month:'0'+month}/{currentDate>9?currentDate:'0'+currentDate}/{year.toString().substr(-2)}
              </h4>
              <div className="card ">
                <div className="card-header bg-dark text-white card-header-custom">COUNTRY</div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="numericCode" className="font-weight-bold">Numeric Code</label>
                        <input
                          type="text"
                          value={
                            this.state.displayCountryRules.country.numericCode
                          }
                          className="form-control form-control-sm"
                          placeholder="Numeric Code"
                          data-test="text-countryNumericCode"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="alphaCode" className="font-weight-bold">Alpha Code</label>
                        <input
                          type="text"
                          value={
                            this.state.displayCountryRules.country.alphaCode
                          }
                          className="form-control form-control-sm"
                          placeholder="Alpha Code"
                          data-test="text-countryAlphaCode"
                          disabled
                        />
                      </div>

                      <div className="col-lg">
                        <label htmlFor="name" className="font-weight-bold">Name</label>
                        <input
                          type="text"
                          value={this.state.displayCountryRules.country.name}
                          className="form-control form-control-sm"
                          placeholder="Name"
                          data-test="text-countryName"
                          disabled
                        />
                      </div>

                      <div className="col-lg">
                        <label htmlFor="isoCode" className="font-weight-bold">ISO Code</label>
                        <input
                          type="text"
                          value={this.state.displayCountryRules.country.isoCode}
                          className="form-control form-control-sm"
                          placeholder="ISO Code"
                          data-test="text-countryISOCode"
                          disabled
                        />
                      </div>


                    </div>
                  </form>
                </div>
              </div>{" "}
              <div className="card mar-top20 ">
                <div className="card-header bg-dark text-white card-header-custom">
                  BUSINESS RULES
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group ">
                      <div className="row">
                        <div className="col-lg">
                          <label htmlFor="available" className="font-weight-bold">Available</label>
                          <select
                            className="form-control  form-control-sm mar-bottom20"
                            name="available"
                            data-test="text-available"
                            onChange={(e) => {
                              this.onChangeControlDisplayCountryRules(
                                "businessRules",
                                e
                              );
                            }}
                            value={
                              this.state.displayCountryRules.businessRules
                                .available
                            }
                          >
                            {this.getYesNoDCR('key1')}
                          </select>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="days" className="font-weight-bold">Days</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={
                              this.state.displayCountryRules.businessRules.limit
                                .days
                            }
                            className="form-control form-control-sm"
                            name="days"
                            onChange={(e) => {
                              this.onChangeControlNestedDisplayCountryRulesOnlyNumber(
                                "businessRules",
                                "limit",
                                e
                              );
                            }}
                            placeholder="Days"
                            data-test="text-businessRulesLimitDays"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="new" className="font-weight-bold">Limit New</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.displayCountryRules.businessRules.limit
                                .limitNew
                            }
                            className="form-control form-control-sm"
                            name="limitNew"
                            onChange={(e) => {
                              this.onChangeControlNestedDisplayCountryRulesDecimalNumber(
                                "businessRules",
                                "limit",
                                e
                              );
                            }}
                            placeholder="Limit New"
                            data-test="text-businessRulesLimitNew"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="repeat" className="font-weight-bold">Limit Repeat</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.displayCountryRules.businessRules.limit
                                .repeat
                            }
                            className="form-control form-control-sm"
                            name="repeat"
                            onChange={(e) => {
                              this.onChangeControlNestedDisplayCountryRulesDecimalNumber(
                                "businessRules",
                                "limit",
                                e
                              );
                            }}
                            placeholder="Limit Repeat"
                            data-test="text-businessRulesLimitRepeat"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="limitDaily" className="font-weight-bold">Limit Daily</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.displayCountryRules.businessRules
                                .limitDaily
                            }
                            className="form-control form-control-sm"
                            name="limitDaily"
                            onChange={(e) => {
                              this.onChangeControlDisplayCountryRulesOnlyDecimal(
                                "businessRules",
                                e
                              );
                            }}
                            placeholder="Limit Daily"
                            data-test="text-businessRulesLimitDaily"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                          {" "}
                          <label htmlFor="available" className="font-weight-bold">Verified Limits</label>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="verifiedLimitdays" className="font-weight-bold">Days</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={
                              this.state.displayCountryRules.businessRules
                                .verifiedLimit.days
                            }
                            className="form-control form-control-sm"
                            name="days"
                            onChange={(e) => {
                              this.onChangeControlNestedDisplayCountryRulesOnlyNumber(
                                "businessRules",
                                "verifiedLimit",
                                e
                              );
                            }}
                            placeholder="Days"
                            data-test="text-businessRulesVerifiedLimitDays"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="verifiedLimitNew" className="font-weight-bold">Limit New</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.displayCountryRules.businessRules
                                .verifiedLimit.limitNew
                            }
                            className="form-control form-control-sm"
                            name="limitNew"
                            onChange={(e) => {
                              this.onChangeControlNestedDisplayCountryRulesDecimalNumber(
                                "businessRules",
                                "verifiedLimit",
                                e
                              );
                            }}
                            placeholder="Limit New"
                            data-test="text-businessRulesVerifiedLimitNew"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="verifiedLimitrepeat" className="font-weight-bold">
                            Limit Repeat
                          </label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.displayCountryRules.businessRules
                                .verifiedLimit.repeat
                            }
                            className="form-control form-control-sm"
                            name="repeat"
                            onChange={(e) => {
                              this.onChangeControlNestedDisplayCountryRulesDecimalNumber(
                                "businessRules",
                                "verifiedLimit",
                                e
                              );
                            }}
                            placeholder="Limit Repeat"
                            data-test="text-businessRulesVerifiedLimitRepeat"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="verifiedLimitDaily" className="font-weight-bold">
                            Limit Daily
                          </label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.displayCountryRules.businessRules
                                .verifiedLimitDaily
                            }
                            className="form-control form-control-sm"
                            name="verifiedLimitDaily"
                            onChange={(e) => {
                              this.onChangeControlDisplayCountryRulesOnlyDecimal(
                                "businessRules",
                                e
                              );
                            }}
                            placeholder="Limit Daily"
                            data-test="text-businessRulesVerifiedLimitDaily"
                          />
                        </div>
                      </div>
                      <div className="row mar-top5">
                        <div className="col-lg">
                          <label htmlFor="fxCountryNum" className="font-weight-bold">Fx Cntry Num</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={
                              this.state.displayCountryRules.businessRules
                                .fxCountryNum
                            }
                            className="form-control form-control-sm"
                            name="fxCountryNum"
                            onChange={(e) => {
                              this.onChangeControlDisplayCountryRulesOnlyNumbers(
                                "businessRules",
                                e
                              );
                            }}
                            placeholder="Fx Cntry Num"
                            data-test="text-businessRulesFxCountryNum"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="startDate" className="font-weight-bold"> Start Date(YYDDD)</label>
                          <input
                            type="text"
                            maxLength="5"
                            value={
                              this.state.displayCountryRules.businessRules
                                .startDate
                            }
                            className="form-control form-control-sm"
                            name="startDate"
                            onChange={(e) => {
                              this.onChangeControlDisplayCountryRulesOnlyNumbers(
                                "businessRules",
                                e
                              );
                            }}
                            placeholder="Start Date"
                            data-test="text-businessRulesStartDate"
                          />
                        </div>
                        <div className="col-lg"></div>    
                        <div className="col-lg"></div>    
                        <div className="col-lg"></div>    
                      </div>
                      <div className="row">
                        <div className="col-lg-2">
                          <div className="card mar-top20">


                            <div className="card-header bg-dark text-white card-header-custom">
                              Velocity
                            </div>


                            <div className="card-body padding-10">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="transaction" className="font-weight-bold">
                                    Transaction
                                  </label>
                                  <input
                                    type="text"
                                    maxLength="5"
                                    value={
                                      this.state.displayCountryRules.velocity
                                        .transaction
                                    }
                                    className="form-control form-control-sm"
                                    name="transaction"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRulesOnlyNumbers(
                                        "velocity",
                                        e
                                      );
                                    }}
                                    placeholder="transaction"
                                    data-test="text-velocityTranscation"
                                  />
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="days" className="font-weight-bold">Days</label>
                                  <input
                                    type="text"
                                    maxLength="2"
                                    value={
                                      this.state.displayCountryRules.velocity
                                        .days
                                    }
                                    className="form-control form-control-sm"
                                    name="days"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRulesOnlyNumbers(
                                        "velocity",
                                        e
                                      );
                                    }}
                                    placeholder="Days"
                                    data-test="text-velocityDays"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="card  mar-top20">
                            <div className="card-header bg-dark text-white card-header-custom">
                              By Pass{" "}
                            </div>
                            <div className="card-body padding-10">

                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="allow" className="font-weight-bold">Allow</label>
                                  <select
                                    value={
                                      this.state.displayCountryRules.byPass
                                        .allow
                                    }
                                    className="form-control form-control-sm"
                                    name="allow"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "byPass",
                                        e
                                      );
                                    }}
                                    data-test="text-byPassAllow"
                                  >
                                     {this.getYesNoDCR('key2')}
                                  </select>
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="rc" className="font-weight-bold">RC</label>
                                  <input
                                    type="text"
                                    maxLength="1"
                                    value={
                                      this.state.displayCountryRules.byPass.rc
                                    }
                                    className="form-control form-control-sm"
                                    name="rc"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRulesOnlyNumbers(
                                        "byPass",
                                        e
                                      );
                                    }}
                                    placeholder="RC"
                                    data-test="text-byPassRC"
                                  />
                                </div>{" "}
                              </div>
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="prin" className="font-weight-bold">Prin</label>
                                  <input
                                    type="text"
                                    value={
                                      this.state.displayCountryRules.byPass.prin
                                    }
                                    className="form-control form-control-sm"
                                    name="prin"
                                    maxLength="5"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRulesOnlyNumbers(
                                        "byPass",
                                        e
                                      );
                                    }}
                                    placeholder="Prin"
                                    data-test="text-byPassPrin"
                                  />
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="cvv" className="font-weight-bold">CVV</label>
                                  <input
                                    type="text"
                                    maxLength="5"
                                    value={
                                      this.state.displayCountryRules.byPass.cvv
                                    }
                                    className="form-control form-control-sm"
                                    name="cvv"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRulesOnlyNumbers(
                                        "byPass",
                                        e
                                      );
                                    }}
                                    placeholder="CVV"
                                    data-test="text-byPassCvv"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="card mar-top20">
                            <div className="card-header bg-dark text-white card-header-custom">
                              ID Request
                            </div>

                            <div className="card-body padding-10">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="yn" className="font-weight-bold">Y/N</label>
                                  <select
                                    value={
                                      this.state.displayCountryRules.idRequest
                                        .yn
                                    }
                                    className="form-control form-control-sm"
                                    name="yn"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "idRequest",
                                        e
                                      );
                                    }}
                                    data-test="text-idRequestYN"
                                  >
                                     {this.getYesNoDCR('key3')}
                                  </select>
                                </div>

                              </div>
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="trans" className="font-weight-bold">Trans</label>
                                  <input
                                    type="text"
                                    maxLength="1"
                                    value={
                                      this.state.displayCountryRules.idRequest
                                        .trans
                                    }
                                    className="form-control form-control-sm"
                                    name="trans"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRulesOnlyNumbers(
                                        "idRequest",
                                        e
                                      );
                                    }}
                                    placeholder="Trans"
                                    data-test="text-idRequestTrans"
                                  />
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="aa" className="font-weight-bold">AA</label>
                                  <select
                                    value={
                                      this.state.displayCountryRules.idRequest
                                        .aa
                                    }
                                    className="form-control form-control-sm"
                                    name="aa"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "idRequest",
                                        e
                                      );
                                    }}
                                    data-test="text-idRequestAA"
                                  >
                                    {this.getYesNoDCR('key4')}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="card mar-top20">
                            <div className="card-header bg-dark text-white card-header-custom">
                              Request
                            </div>
                            <div className="card-body padding-10">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="cvv" className="font-weight-bold">CVV</label>
                                  <select
                                    value={
                                      this.state.displayCountryRules.request.cvv
                                    }
                                    className="form-control form-control-sm"
                                    name="cvv"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "request",
                                        e
                                      );
                                    }}
                                    data-test="text-requestCVV"
                                  >
                                     {this.getYesNoDCR('key5')}
                                  </select>
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="avs" className="font-weight-bold">AVS</label>
                                  <select
                                    value={
                                      this.state.displayCountryRules.request.avs
                                    }
                                    className="form-control form-control-sm"
                                    name="avs"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "request",
                                        e
                                      );
                                    }}
                                    data-test="text-requestAVS"
                                  >
                                     {this.getYesNoDCR('key6')}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="card mar-top20">
                            <div className="card-header bg-dark text-white card-header-custom">
                              New Rules Def
                            </div>
                            <div className="card-body padding-10">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="newRulesDef" className="font-weight-bold">
                                    New Rules Def
                                  </label>
                                  <select
                                    value={
                                      this.state.displayCountryRules.newRulesDef
                                    }
                                    className="form-control form-control-sm"
                                    name="newRulesDef"
                                    onChange={(e) => {
                                      this.onChangeControlNonNestedDisplayCountryRules(
                                        e
                                      );
                                    }}
                                    data-test="text-newRulesDef"
                                  >
                                    <option value="">-Select-</option>
                                    <option value="Y">Y</option>
                                    <option value="N">N</option>
                                    <option value="A">A</option>
                                    <option value="R">R</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>

              <div className=" mar-top20 ">
                <div className="row">
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        SATURN
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="form-group row">
                            <div className="col-lg">
                              <div className="row">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input "
                                    type="radio"
                                    name="status"
                                    value="Mandatory"
                                    data-test="text-saturnStatusMandatory"
                                    checked={
                                      this.state.displayCountryRules.saturn.status === "Mandatory"
                                    }
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "saturn",
                                        e
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label font-weight-bold"
                                    htmlFor="mandatory"
                                    
                                  >
                                    Mandatory
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="Desired"
                                    name="status"
                                    data-test="text-saturnStatusDesired"
                                    checked={
                                      this.state.displayCountryRules.saturn.status === "Desired"
                                    }
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "saturn",
                                        e
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label font-weight-bold"
                                    htmlFor="desired"
                                    
                                  >
                                    Desired
                                  </label>
                                </div>
                                <div className="form-check form-check-inline ">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="Informational"
                                    name="status"
                                    data-test="text-saturnStatusInformational"
                                    checked={
                                      this.state.displayCountryRules.saturn.status === "Informational"
                                    }
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "saturn",
                                        e
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label font-weight-bold"
                                    htmlFor="informational"
                                    
                                  >
                                    Informational
                                  </label>
                                </div>
                                <div className="form-check form-check-inline ">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="Not Enabled"
                                    data-test="text-saturnStatusNotEnabled"
                                    name="status"
                                    checked={
                                      this.state.displayCountryRules.saturn.status === "Not Enabled"
                                    ||
                                      this.state.displayCountryRules.saturn.status === null
                                    }
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "saturn",
                                        e
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label font-weight-bold"
                                    htmlFor="notEnabled"
                                    
                                  >
                                    Not Enabled
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="autoApprRateCode" className="font-weight-bold">
                                    Auto Approve Rate Code
                                  </label>
                                  <input
                                    type="text"
                                    maxLength="1"
                                    value={
                                      this.state.displayCountryRules.saturn
                                        .autoApprRateCode
                                    }
                                    className="form-control form-control-sm"
                                    name="autoApprRateCode"
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "saturn",
                                        e
                                      );
                                    }}
                                    placeholder="Auto Approve Rate Code"
                                    data-test="text-saturnAutoApprRateCode"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        EXPERIAN{" "}
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="form-group row">
                            <div className="col-lg">
                              <div className="row">
                                <div className="form-check form-check-inline mar-top20">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    value="Y"
                                    data-test="text-experianStatusEnabled"
                                    checked={
                                      this.state.displayCountryRules.experian.status === "Y"
                                    }
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "experian",
                                        e
                                      );
                                    }}
                                    disabled
                                  />
                                  <label
                                    className="form-check-label font-weight-bold"
                                    htmlFor="y" 
                                  >
                                    Enabled
                                  </label>
                                </div>
                                <div className="form-check form-check-inline mar-top20">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    value="N"
                                    data-test="text-experianStatusNotEnabled"
                                    checked={
                                      this.state.displayCountryRules.experian.status === "N" || this.state.displayCountryRules.experian.status === null
                                    }
                                    onChange={(e) => {
                                      this.onChangeControlDisplayCountryRules(
                                        "experian",
                                        e
                                      );
                                    }}
                                    disabled
                                  />
                                  <label
                                    className="form-check-label font-weight-bold"
                                    htmlFor="n"
                                  >
                                    Not Enabled
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg">
                              <label htmlFor="mt3" className="font-weight-bold">MT3</label>
                              <input
                                type="text"
                                maxLength="1"
                                value={
                                  this.state.displayCountryRules.experian.mt3
                                }
                                className="form-control form-control-sm"
                                name="mt3"
                                onChange={(e) => {
                                  this.onChangeControlDisplayCountryRules(
                                    "experian",
                                    e
                                  );
                                }}
                                placeholder="MT3"
                                data-test="text-experianMT3"
                              />
                            </div>
                            <div className="col-lg">
                              <label htmlFor="mt6" className="font-weight-bold">MT6</label>
                              <input
                                type="text"
                                maxLength="1"
                                value={
                                  this.state.displayCountryRules.experian.mt6
                                }
                                className="form-control form-control-sm"
                                name="mt6"
                                onChange={(e) => {
                                  this.onChangeControlDisplayCountryRules(
                                    "experian",
                                    e
                                  );
                                }}
                                placeholder="MT6"
                                data-test="text-experianMT6"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mar-top20">
                <div className="row">
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        AUTH REVERSAL
                      </div>
                      <div className="card-body">
                        <select
                          className="form-control  form-control-sm"
                          name="authReversal"
                          data-test="text-authReversal"
                          onChange={(e) => {
                            this.onChangeControlNonNestedDisplayCountryRules(e);
                          }}
                          value={this.state.displayCountryRules.authReversal}
                        >
                          {this.getEnableNotDCR()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        EMAIL DETAILS
                      </div>
                      <div className="card-body">
                        <select
                          className="form-control  form-control-sm"
                          name="emailDetails"
                          data-test="text-emailDetails"
                          onChange={(e) => {
                            this.onChangeControlNonNestedDisplayCountryRules(e);
                          }}
                          value={this.state.displayCountryRules.emailDetails}
                        >
                          {this.getEnableNotDCR()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        IBP PAYMENT
                      </div>
                      <div className="card-body">
                        <select
                          className="form-control  form-control-sm "
                          name="ibpPayment"
                          data-test="text-ibpPayment"
                          onChange={(e) => {
                            this.onChangeControlNonNestedDisplayCountryRules(e);
                          }}
                          value={this.state.displayCountryRules.ibpPayment}
                        >
                          {this.getEnableNotDCR()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        LOCAL CARD PAYMENT
                      </div>
                      <div className="card-body">
                        <select
                          className="form-control  form-control-sm "
                          name="localCardPayment"
                          data-test="text-localCardPayment"
                          onChange={(e) => {
                            this.onChangeControlNonNestedDisplayCountryRules(e);
                          }}
                          value={
                            this.state.displayCountryRules.localCardPayment
                          }
                        >
                          {this.getEnableNotDCR()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        EUID DETAILS
                      </div>
                      <div className="card-body">
                        <select
                          className="form-control  form-control-sm"
                          name="euIdDetails"
                          data-test="text-euIdDetails"
                          onChange={(e) => {
                            this.onChangeControlNonNestedDisplayCountryRules(e);
                          }}
                          value={this.state.displayCountryRules.euIdDetails}
                        >
                          {this.getEnableNotDCR()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mar-top20">
                <div className="row">
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        HUMAN VALIDATION
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg">
                            <label htmlFor="product" className="font-weight-bold">Product</label>
                            <input
                              type="text"
                              maxLength="6"
                              value={
                                this.state.displayCountryRules.humanValidation
                                  .product
                              }
                              className="form-control form-control-sm"
                              name="product"
                              onChange={(e) => {
                                this.onChangeControlDisplayCountryRules(
                                  "humanValidation",
                                  e
                                );
                              }}
                              placeholder="Product"
                              data-test="text-humanValidationProduct"
                            />
                          </div>

                          <div className="col-lg">
                            <label htmlFor="queuelist" className="font-weight-bold">Queuelist</label>
                            <select
                              className="form-control  form-control-sm"
                              name="queuelist"
                              data-test="text-humanValidationQueuelist"
                              onChange={(e) => {
                                this.onChangeControlDisplayCountryRulesQueueList(
                                  "humanValidation",
                                  e
                                );
                              }}
                              value={
                                this.state.displayCountryRules.humanValidation.queueId
                              }
                            >
                              {this.getQueuelist()}
                            </select>
                          </div>
                          <div className="col-lg">
                            <label className="font-weight-bold">Queue ID:</label>
                            <input
                              type="text"
                              name="queueId"
                              value={
                                this.state.displayCountryRules.humanValidation.queueId
                              }
                              className="form-control form-control-sm"
                              placeholder="Queue ID"
                              data-test="text-queueID"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mar-top20">
                <div className="row">
                  <div className="col-lg">
                    <div className="card">
                      <table className="table table-striped table-hover card-header-custom">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col" className="font-weight-normal">Product Code</th>
                            <th scope="col" className="font-weight-normal">TYPE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.products.map((el,prodIndex) => (
                            <tr
                              key={el.type+""+prodIndex}
                              data-test="tr"
                            >
                              <td>
                                <input
                                  type="text"
                                  name="productCode"
                                  maxLength="6"
                                  value={el.productCode}
                                  onChange={(e) => {
                                    this.onChangeProductTableDisplayCountryRules(
                                      el,
                                      e
                                    );
                                  }}
                                  className="form-control form-control-sm"
                                  data-test="text-productCode"
                                />
                              </td>
                              <td>{el.type}</td>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <input
                                type="text"
                                maxLength="6"
                                name="productCode"
                                value={this.state.productCode}
                                onChange={this.onChangeDisplayCountryRules}
                                className="form-control form-control-sm"
                                data-test="text-productCode"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                maxLength="2"
                                name="type"
                                value={this.state.type}
                                onChange={this.onChangeDisplayCountryRulesCharInt}
                                className="form-control form-control-sm"
                                data-test="text-type"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <table className="table table-striped table-hover card-header-custom">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col" className="font-weight-normal">Merchant ID</th>
                            <th scope="col" className="font-weight-normal">Payment Type </th>
                            <th scope="col" className="font-weight-normal">Path </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.merchant.map((el,merIndex) => (
                            <tr
                              key={
                                el.paymentType +"" + el.path +""+merIndex
                              }
                              data-test="tr"
                            >
                              <td>
                                {" "}
                                <input
                                  type="text"
                                  maxLength="20"
                                  name="merchantId"
                                  value={el.merchantId}
                                  onChange={(e) => {
                                    this.onChangeMerchantTableDisplayCountryRules(
                                      el,
                                      e
                                    );
                                  }}
                                  className="form-control form-control-sm"
                                  data-test="text-merchantId"
                                />
                              </td>
                              <td>{this.getPaymentType(el.paymentSubType)}</td>
                              <td>
                                {(el.path === "T" && "Test") ||
                                  (el.path === "P" && "Prod")}
                              </td>
                            </tr>
                          ))}
                          <tr data-test="tr">
                            <td>
                              <input
                                type="text"
                                name="merchantId"
                                maxLength="20"
                                value={this.state.merchantId}
                                onChange={this.onChangeDisplayCountryRules}
                                className="form-control form-control-sm"
                                data-test="text-merchantId"
                              />
                            </td>
                            <td>
                              <select
                                className="form-control  form-control-sm"
                                name="paymentType"
                                data-test="text-paymentType"
                                onChange={this.onChangeDisplayCountryRules}
                                value={this.state.paymentSubType}
                              >
                                <option value="A">Am Express</option>
                                <option value="V" selected>Visa / MC</option>
                                <option value="P">BPAY</option>
                                <option value="B">IBP</option>
                                <option value="D">Discover Card</option>
                                <option value="L">Local Card</option>
                                <option value="Z">Pin Debit</option>
                                <option value="U">Unknown</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-control  form-control-sm"
                                name="path"
                                data-test="text-path"
                                onChange={this.onChangeDisplayCountryRules}
                                value={this.state.path}
                              >
                                <option value="P" selected>
                                  Prod
                                </option>
                                <option value="T">Test</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 text-center mar-top5 mar-bottom5">
                  <button
                    type="button"
                    className="btn btn-warning mar-right10 mar-left100"
                    onClick={this.handleSubmitDisplayCountryRules}
                    data-test="button-display-country-rules-update"
                    disabled={!this.state.accessGrantedWriteBRM}
                  >
                    UPDATE
                  </button>
                  {/* {this.state.mt6rulesflag && 
                  <button
                  type="button"
                  onClick={this.handleMT6RulesDisplayCountryRules}
                  className="btn btn-warning mar-right10 mar-left100"
                  data-test="button-mt6-rules"
                >
                  MT6 RULES
                </button>} */}
                  <button
                    type="button"
                    onClick={this.handleBusinessRuleMaintDisplayCountryRules}
                    className="btn btn-warning mar-right10 mar-left100"
                    data-test="button-business-rule-maintenance"
                  >
                    BUSINESS RULE MAINTENANCE
                  </button>
                  <button
                    type="button"
                    onClick={this.handlePartnerEmailDetails}
                    className="btn btn-warning mar-right10 mar-left100"
                    data-test="button-partner-email-details"
                  >
                    PTNR EMAIL
                  </button>
                  <button
                    type="button"
                    onClick={this.handleQueueMaintenanceDetails}
                    className="btn btn-warning mar-right10 mar-left100"
                    data-test="button-queue-maintenance-details"
                    disabled={!this.props.location.state.accessGrantedReadTMD1}
                  >
                    QUEUE MAINT
                  </button>
                </div>
                <div className="col-lg-3 mar-top5"> 
                </div>
              </div>
              <div className="row mar-top10">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                        {this.state.submitStatusDisplayCountryRules === "OK" && (
                    <div className="alert alert-success alert-padding notification text-center">
                      {this.state.submitMsgDisplayCountryRules}
                    </div>
                  )}
                  {this.state.submitStatusDisplayCountryRules === "Error" && (
                    <div className="alert alert-danger alert-padding notification text-center">
                      {this.state.submitMsgDisplayCountryRules}
                    </div>
                  )}
                        </div>
                        <div className="col-lg-4"></div>
                      </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
