import React from "react";
import Logo from "../../../Asset/WUPic.jpg";

function Home() {
  return (
    <div className="card mar-top5" data-test="component-home">
      <h4 className="main-heading">Moving Money For Better</h4>
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <img className="img-fluid" src={Logo} alt="Western Union" />
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
}

export default Home;
