import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersShared } from "../../utility/utility";

export default class CountryDeliveryService extends Component {
  state = {
    data: [],
    serviceName: "",
    submitStatusCountryDeliveryService: null,
    submitMsgCountryDeliveryService: "",
  };

  componentDidMount() {
    this.setState({
      tokenCM:this.props.location.state.state.tokenCM,
      accessGrantedCM:this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    })
    const code = this.props.location.state.data.countryCode;
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/deliveryservice/${code}`,
        {
          headers: {
            ...getHeadersShared().headers,              
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        let recCDS = [];
        if (response.data.records !== null) {
          recCDS = response.data.serviceList;
        }
        this.setState({ data: recCDS });
      });
  }

  onChangeControlCountryDeliveryService = (e, sName) => {
    this.setState({ serviceName: sName });
  };
  onHandleUpdateDeliveryService = () => {
    const countryName = this.props.location.state.data.countryName;
    const code = this.props.location.state.data.countryCode;
    const serviceName = this.state.serviceName;

    const data = {
      countryCode: code,
      countryName: countryName,
      serviceName: serviceName,
    };
    if (this.state.serviceName === "") {
      this.setState({
        submitStatusCountryDeliveryService: "Error",
        submitMsgCountryDeliveryService:
          "Please Select One Of The Services Available",
      });
      return false;
    } else {
      this.props.history.push({
        pathname: "/add-delivery-service",
        state: { mode: "update", data: data,state:this.state },
      });
    }
  };
  onHandleDelayedDeliveryService = () => {
    const countryName = this.props.location.state.data.countryName;
    const code = this.props.location.state.data.countryCode;
    const data = {
      countryCode: code,
      countryName: countryName,
      tokenCM:this.props.location.state.state.tokenCM,
      accessGrantedCM:this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    };
    this.props.history.push({
      pathname: "/delivery-service",
      state: data,
    });
  };
  onHandleAddDeliveryService = () => {
    const countryName = this.props.location.state.data.countryName;
    const code = this.props.location.state.data.countryCode;
    const data = {
      countryCode: code,
      countryName: countryName,
    };
    this.props.history.push({
      pathname: "/add-delivery-service",
      state: { data: data, mode: "add",state:this.state},
    });
  };
  onHandleBackDeliveryService = () => {
    const code = this.props.location.state.data.countryCode;
    const data = {
      countryCode: code,
    };

    this.props.history.push({
      pathname: "/country-details",
      state: { data: data, mode: null,state:this.state },
    });
  };
  render() {
    return (
      <>
      <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
      <div className="container-fluid">
       
          <div className="card-body">
            <h4 className="card-title main-heading ">
              COUNTRY DELIVERY SERVICE
            </h4>
            <div className="col-lg text-center mar-top20 mar-bottom20 main-heading">
              {" "}
              Country Name : {this.props.location.state.data.countryName}{" "}
            </div>

            <div className="form-group row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="card ">
                  <div className="card-header bg-dark text-white card-header-custom">
                    Services Available
                  </div>
                  <div className="card-body card-body-custom">
                    <form className="text-center">
                      {this.state.data.map((el) => {
                        return (
                          <div
                            className="form-check form-check-inline"
                            key={el.serviceName}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={el.serviceName}
                              //  checked={this.state.data.serviceList.serviceName === el.serviceName}
                              onChange={(e) => {
                                this.onChangeControlCountryDeliveryService(
                                  e,
                                  el.serviceName
                                );
                              }}
                              data-test="radio-status-serviceList"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="status"
                            >
                              {el.serviceName}
                            </label>
                          </div>
                        );
                      })}
                      <div className="col-lg text-center mar-bottom5 mar-top20">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleDelayedDeliveryService}
                          data-test="button-delayed-service"
                        >
                          DELAYED SERVICE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddDeliveryService}
                          data-test="button-add-service"
                          disabled={!this.props.location.state.state.accessGrantedCM}
                        >
                          ADD SERVICE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateDeliveryService}
                          data-test="button-update-service"
                          disabled={!this.props.location.state.state.accessGrantedCM}
                        >
                          UPDATE SERVICE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackDeliveryService}
                          data-test="button-back-delivery-service"
                        >
                          CNTRY DETAIL
                        </button>
                      </div>
                      <div className="form-group row mar-top20">
                        <div className="col-lg-3" />
                        <div className="col-lg-6">
                          {this.state.submitStatusCountryDeliveryService ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification text-center">
                              {this.state.submitMsgCountryDeliveryService}
                            </div>
                          )}
                          {this.state.submitStatusCountryDeliveryService ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification text-center">
                              {this.state.submitMsgCountryDeliveryService}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-3" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      </>                       
    );
  }
}
