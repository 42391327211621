import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactMTMD } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditMTMD } from "react-icons/ai";
import { IconContext as IconContextMTMD } from "react-icons";
import { getHeadersOrder } from "../../utility/utility";

class McisTypeMaintenanceDetails extends Component {
  state = {
    mtmdId:"",
    recordType: "",
    recordId: "",
    alphaId: "",
    description: "",
    showMcisTypeMaintenanceDetailsConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {
                if(!this.props.location.state.accessGrantedWriteTMD3) { 
                this.setState({
                  submitStatusMcisTypeMaintenanceDetails: 'Error',
                  submitMsgMcisTypeMaintenanceDetails: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                });
              }else{
                this.handleEditMcisTypeMaintenanceDetails(params)
              }  
            }}
            >
              <IconContextMTMD.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditMTMD />
              </IconContextMTMD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      { field: "recordType", headerName: "Record Type", width: 290 },
      { field: "recordId", headerName: "Record Id", width: 290 },
      { field: "alphaId", headerName: "Alpha Id", width: 280 },
      { field: "description", headerName: "Description", width: 290 },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValMcisTypeMaintenanceDetails: "25",
    defaultColumnDef: {resizable: true, sortable: true, filter: true, floatingFilter: true },
    editable: true,
    submitStatusMcisTypeMaintenanceDetails: null,
    submitMsgMcisTypeMaintenanceDetails: "",
    paginationOptionMcisTypeMaintenanceDetails: [25, 50, 100, 500],
    overlayLoadingTemplateMTMD:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateMTMD:'<span style="">No Records Found</span>',
  };

  onChangeControlMcisTypeMaintenanceDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlMcisTypeMaintenanceDetailsCharNumber  = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onGridReadyMcisTypeMaintenanceDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/mcistype`, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        this.setState({
          rows: response.data.records,
        });
      });
  };

  handleEditMcisTypeMaintenanceDetails = (params) => {
    let data = params.node.data;
    this.setState({
      mtmdId: data.recordType+''+data.recordId,
      recordType: data.recordType,
      recordId: data.recordId,
      alphaId: data.alphaId,
      description: data.description,
      enableBtn: true,
      editable: false
    });
  };

  onPageSizeChangedMcisTypeMaintenanceDetails = (e) => {
    this.setState({ paginationValMcisTypeMaintenanceDetails: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackMcisTypeMaintenanceDetails = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  onValidateMcisTypeMaintenanceDetails = () => {
    if (this.state.recordType === "") {
      this.setState({
        submitStatusMcisTypeMaintenanceDetails: "Error",
        submitMsgMcisTypeMaintenanceDetails: "Please Enter The Value For Record Type",
      });
      return false;
    }
    if (this.state.recordId === "") {
      this.setState({
        submitStatusMcisTypeMaintenanceDetails: "Error",
        submitMsgMcisTypeMaintenanceDetails: "Please Enter The Value For Record Id ",
      });
      return false;
    }
    return true;
  };

  onHandleAddMcisTypeMaintenanceDetails = () => {

    if (!this.onValidateMcisTypeMaintenanceDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.mtmdId = this.state.recordType+''+this.state.recordId;
    submittedObj.recordType = this.state.recordType;
    submittedObj.recordId = this.state.recordId;
    submittedObj.alphaId = this.state.alphaId;
    submittedObj.description = this.state.description;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/mcistype`, submittedObj, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200  && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusMcisTypeMaintenanceDetails: "OK",
            submitMsgMcisTypeMaintenanceDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusMcisTypeMaintenanceDetails: "Error",
            submitMsgMcisTypeMaintenanceDetails: response.data.responseString,
          });
        }
        this.onHandleClearMcisTypeMaintenanceDetails();
      });
  };

  onHandleUpdateMcisTypeMaintenanceDetails = () => {
    if (!this.onValidateMcisTypeMaintenanceDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.mtmdId = this.state.recordType+''+this.state.recordId;
    submittedObj.recordType = this.state.recordType;
    submittedObj.recordId = this.state.recordId;
    submittedObj.alphaId = this.state.alphaId;
    submittedObj.description = this.state.description;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/mcistype`, submittedObj, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => (row.recordType+''+row.recordId) === submittedObj.mtmdId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusMcisTypeMaintenanceDetails: "OK",
            submitMsgMcisTypeMaintenanceDetails: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusMcisTypeMaintenanceDetails: "Error",
            submitMsgMcisTypeMaintenanceDetails: response.data.responseString,
          });
        }
        this.onHandleClearMcisTypeMaintenanceDetails();
      });
  };

  onHandleClearMcisTypeMaintenanceDetails = () => {
    this.setState({
      mtmdId: "",
      recordType: "",
      recordId: "",
      alphaId: "",
      description: "",
      enableBtn: false,
      editable: true
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusMcisTypeMaintenanceDetails: null,
        }),
      5000
    );
  };

  getMcisTypeMaintenanceDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showMcisTypeMaintenanceDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Record Type {this.state.recordType} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showMcisTypeMaintenanceDetailsConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteMcisTypeMaintenanceDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteMcisTypeMaintenanceDetails = () => {
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/deletemcistype/${this.state.recordType}/${this.state.recordId}`, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return (row.recordType+''+row.recordId) !== this.state.mtmdId;
          });

          this.setState({
            submitStatusMcisTypeMaintenanceDetails: "OK",
            submitMsgMcisTypeMaintenanceDetails: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusMcisTypeMaintenanceDetails: "Error",
            submitMsgMcisTypeMaintenanceDetails: response.data.responseString,
          });
        }
        this.onHandleClearMcisTypeMaintenanceDetails();
      });
    this.setState({ showMcisTypeMaintenanceDetailsConfirm: false });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="WU SYSTEM MONITOR" />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">MCIS TYPE DETAILS</h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  MCIS TYPE FORM
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="recordType" className="font-weight-bold">Record Type</label>
                        <input
                          type="text"
                          maxLength="2"
                          value={this.state.recordType}
                          className="form-control form-control-sm"
                          name="recordType"
                          onChange={(e) => {
                            this.onChangeControlMcisTypeMaintenanceDetailsCharNumber(e);
                          }}
                          data-test="text-recordType"
                          placeholder="Record Type"
                          disabled={!this.state.editable}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="recordId" className="font-weight-bold">Record Id</label>
                        <input
                          type="text"
                          maxLength="2"
                          value={this.state.recordId}
                          className="form-control form-control-sm"
                          name="recordId"
                          onChange={(e) => {
                            this.onChangeControlMcisTypeMaintenanceDetailsCharNumber(e);
                          }}
                          data-test="text-recordId"
                          placeholder="Record Id"
                          disabled={!this.state.editable}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="alphaId" className="font-weight-bold">Alpha Id</label>
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.alphaId}
                          className="form-control form-control-sm"
                          name="alphaId"
                          onChange={(e) => {
                            this.onChangeControlMcisTypeMaintenanceDetails(e);
                          }}
                          data-test="text-alphaId"
                          placeholder="Alpha Id"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="description" className="font-weight-bold">Description</label>
                        <input
                          type="text"
                          maxlength="40"
                          value={this.state.description}
                          className="form-control form-control-sm"
                          name="description"
                          onChange={(e) => {
                            this.onChangeControlMcisTypeMaintenanceDetails(e);
                          }}
                          data-test="text-description"
                          placeholder="Description"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackMcisTypeMaintenanceDetails}
                          data-test="button-back"
                        >
                          TMON MAINT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddMcisTypeMaintenanceDetails}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={
                            this.onHandleUpdateMcisTypeMaintenanceDetails
                          }
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={
                            this.onHandleClearMcisTypeMaintenanceDetails
                          }
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showMcisTypeMaintenanceDetailsConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                          {this.state.submitStatusMcisTypeMaintenanceDetails ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgMcisTypeMaintenanceDetails}
                            </div>
                          )}
                          {this.state.submitStatusMcisTypeMaintenanceDetails ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgMcisTypeMaintenanceDetails}
                            </div>
                          )}
						              </div>
                          <div className="col-lg-2"></div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getMcisTypeMaintenanceDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  MCIS TYPE DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlMcisTypeMaintenanceDetails(e);
                        this.onPageSizeChangedMcisTypeMaintenanceDetails(e);
                      }}
                      value={this.state.paginationValMcis}
                      name="paginationDropdownMcis"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionMcisTypeMaintenanceDetails.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactMTMD
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyMcisTypeMaintenanceDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateMTMD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateMTMD
                      }
                    ></AgGridReactMTMD>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default McisTypeMaintenanceDetails;
