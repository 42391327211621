import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersShared } from "../../utility/utility";

class AccountDetails extends Component {
  state = {
    countryCode: "",
    acctPrefix: "",
    flagAD:1,
    showAccountDetailsConfirm: false,
    submitStatusAccountDetails: null,
    submitMsgAccountDetails: "",
  };

  onChangeControlAccountDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount = () => {
    let acctId = this.props.location.state.accountPrefix;
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/getaccount`,
        {
          headers: {
            ...getHeadersShared().headers,
            accountId:acctId,
            Authorization:this.props.location.state.tokenCM,
            userId: this.props.location.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if(response.data.countryCode===null){
          this.setState({
            flagAD:0
          })
        }
        this.setState({
          countryCode: response.data.countryCode,
          acctPrefix: acctId,
          accessGrantedCM:this.props.location.state.accessGrantedCM,
        });
      });
  };

  onHandleBackAccountDetails = () => {
    this.props.history.push("/country-maintenance");
  };

  onValidateAccountDetails = () => {
    if (
      this.state.countryCode === "" ){
      this.setState({
        submitStatusAccountDetails: "Error",
        submitMsgAccountDetails: "Please Enter The Value In Country Code",
      });
      return false;
    }
    return true;
  };

  onHandleUpdateAccountDetails = () => {
    if (!this.onValidateAccountDetails()) {
      return;
    }
    let submittedObjUpdateAD = {};
    submittedObjUpdateAD.countryCode = this.state.countryCode;
    submittedObjUpdateAD.accountId = this.state.acctPrefix;

    let modifiedHeaderAD = {
      headers: {
        ...getHeadersShared().headers,
        accountId:this.state.acctPrefix,
        Authorization:this.props.location.state.tokenCM,
        userId: this.props.location.state.operatorIdCM,
      },
    }
    if (this.state.flagAD === 0) {
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/getaccount`,
          submittedObjUpdateAD,
          modifiedHeaderAD
        )
        .then((response) => {
          this.responseAfterNwCallAD(response);
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/getaccount`,
          submittedObjUpdateAD,
          modifiedHeaderAD
        )
        .then((response) => {
          this.responseAfterNwCallAD(response);
        });
    }
  };
  responseAfterNwCallAD = (response) =>{
    if (response.status === 200) {
      this.setState({
        submitStatusAccountDetails: "OK",
        submitMsgAccountDetails: response.data.responseString,
      });
    } else {
      this.setState({
        submitStatusAccountDetails: "Error",
        submitMsgAccountDetails: response.data.responseString,
      });
    }
  }
  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">ACCOUNT DETAILS</h4>
            <div className="form-group row">
              <div className="col-lg-2" />
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-header bg-dark text-white card-header-custom">
                    ACCOUNT DETAILS
                  </div>
                  <div className="card-body card-body-custom">
                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg">
                        {this.state.submitStatusAccountDetails === "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgAccountDetails}
                          </div>
                        )}
                        {this.state.submitStatusAccountDetails === "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgAccountDetails}
                          </div>
                        )}
                      </div>
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg">
                        <label htmlFor="acctPrefix" className="font-weight-bold">Account Prefix</label>
                        <input
                          type="text"
                          maxLength="2"
                          value={this.state.acctPrefix}
                          className="form-control form-control-sm"
                          name="acctPrefix"
                          data-test="text-acctPrefix"
                          placeholder="Account Prefix"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="countryCode" className="font-weight-bold">Country Code</label>
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.countryCode}
                          className="form-control form-control-sm"
                          name="countryCode"
                          onChange={(e) => {
                            this.onChangeControlAccountDetails(e);
                          }}
                          data-test="text-countryCode"
                          placeholder="Country Code"
                        />
                      </div>
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          data-test="button-update"
                          onClick={this.onHandleUpdateAccountDetails}
                          disabled={!this.state.accessGrantedCM}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          data-test="button-back"
                          onClick={this.onHandleBackAccountDetails}
                        >
                          CNTRY MAINT
                        </button>
                      </div>
                      <div className="col-lg-3" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AccountDetails;
