import React, { Component } from "react";
import Header from "../../header/header";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { getHeadersProduct } from "../../utility/utility";

export default class QuickCollectClientInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickCollectClientInfo: {
        cityCodestate: "",
        account: "",
        recordVersion: 0,
        highVersion: "",
        clientName: "",
        group: 0,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        contactName: "",
        status: "Active",
        phoneNumber: "",
        clientId: 0,
        rppsBiller: "N",
        swiftPay: "",
        d2b: "",
        spayPurge: 0,
        ediscount: 0.0,
        efee: 0.0,
        reportDelivery: "D",
        qqcType: 2,
        commonBank: "",
        ofacViolation: "",
        dateAdd: "",
        lastUpd: "",
        delivery: "",
        notification: "",
        remove: "",
        creditCard: "",
        ccUsaCanRateTable: "",
        intRate: "",
        usaRateType: "G",
        usaSenderRate: 0.0,
        usaQCCRate: 0.0,
        canRateType: "G",
        canSenderRate: 0.0,
        canQCCRate: 0.0,
        usaStatus: "A",
        usaLastUpdate: "",
        canStatus: "A",
        canLastUpdate: "",
        intStatus: "N",
        intLastUpdate: "",
        intQccPricing: 0.0,
        emailAddress: "",
      },
      usaStatusOld: "",
      canStatusOld: "",
      intStatusOld: "",
      notificationOld: "",
      accountOld:'',
      rowQCCI: [],
      countQCCI: 0,
      submitStatusQuickCollectClientInfo: null,
      submitMsgQuickCollectClientInfo: "",
      showQuickCollectClientInfoModal: false,
      mode: "U",
      valOfCC:[]
    };
  }

  componentDidMount() {
    axios
    .get(
      `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/mcisconstants`,
      {
        headers: {
          ...getHeadersProduct().headers,
          Authorization:this.props.location.state.tokenQCDM,
          screenName: "quickCollectClientInfo"
        },
      }
    )
    .then((response) => {
      this.setState({
        valOfCC: response.data.blerchConstants,
      });
    });
    this.onLoadQCCI();
  }
  onLoadQCCI=()=>{
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/qqcclientinfo`,
        {
          headers: {
            ...getHeadersProduct().headers,
            codeCityState: this.props.location.state.cityCode,
            clientName: this.props.location.state.clientName,
            Authorization: this.props.location.state.tokenQCDM,
            userId: this.props.location.state.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (
          response.data.records[0].clientName === null ||
          response.data.records[0].clientName === ""
        ) {
          this.setState({
            enableNextQCCI: false,
            mode: "A",
            rowQCCI: response.data.records,
            quickCollectClientInfo: response.data.records[0],
          });
          this.setState((prevState) => {
            return {
              quickCollectClientInfo: {
                ...prevState.quickCollectClientInfo,
                group: (response.data.records[0].group===null || response.data.records[0].group==='')?0:response.data.records[0].group,
                spayPurge: (response.data.records[0].spayPurge===null || response.data.records[0].spayPurge==='')?0:response.data.records[0].spayPurge,
                ediscount: (response.data.records[0].ediscount===null || response.data.records[0].ediscount==='')?0.00:response.data.records[0].ediscount,
                efee: (response.data.records[0].efee===null || response.data.records[0].efee==='')?0.00:response.data.records[0].efee,
                ccUsaCanRateTable: (response.data.records[0].ccUsaCanRateTable===null || response.data.records[0].ccUsaCanRateTable==='')?'':response.data.records[0].ccUsaCanRateTable,
                intRate: (response.data.records[0].intRate===null || response.data.records[0].intRate==='')?'':response.data.records[0].intRate,
                usaSenderRate: (response.data.records[0].usaSenderRate===null || response.data.records[0].usaSenderRate==='')?0.00:response.data.records[0].usaSenderRate,
                usaQCCRate: (response.data.records[0].usaQCCRate===null || response.data.records[0].usaQCCRate==='')?0.00:response.data.records[0].usaQCCRate,
                canSenderRate: (response.data.records[0].canSenderRate===null || response.data.records[0].canSenderRate==='')?0.00:response.data.records[0].canSenderRate,
                canQCCRate: (response.data.records[0].canQCCRate===null || response.data.records[0].canQCCRate==='')?0.00:response.data.records[0].canQCCRate,
                usaStatus: (response.data.records[0].usaStatus===null || response.data.records[0].usaStatus==='')?'A':response.data.records[0].usaStatus,
                canStatus: (response.data.records[0].canStatus===null || response.data.records[0].canStatus==='')?'A':response.data.records[0].canStatus,
                intStatus: (response.data.records[0].intStatus===null || response.data.records[0].intStatus==='')?'N':response.data.records[0].intStatus,
                status: (response.data.records[0].status===null || response.data.records[0].status==='')?0:response.data.records[0].status,
                creditCard: (response.data.records[0].creditCard===null || response.data.records[0].creditCard==='')?'':response.data.records[0].creditCard,
                usaRateType: (response.data.records[0].usaRateType===null || response.data.records[0].usaRateType==='')?'G':response.data.records[0].usaRateType,
                canRateType: (response.data.records[0].canRateType===null || response.data.records[0].canRateType==='')?'G':response.data.records[0].canRateType,
                reportDelivery: (response.data.records[0].reportDelivery===null || response.data.records[0].reportDelivery==='')?'D':response.data.records[0].reportDelivery,
              },
            };
          });
        } else {
          this.setState({
            rowQCCI: response.data.records,
            quickCollectClientInfo: response.data.records[0],
            usaInactive: response.data.records[0].usaStatus,
            canInactive: response.data.records[0].canStatus,
            intInactive: response.data.records[0].intStatus,
            accountOld: response.data.records[0].account,
            enableNextQCCI: response.data.records.length > 1 ? true : false,
          });          
        }
      });
  }
  onChangeControlQuickCollectClientInfo = (e) => {    
    const { name, value } = e.target;
    let val = value.toUpperCase();
    this.setState((prevState) => {
      return {
        quickCollectClientInfo: {
          ...prevState.quickCollectClientInfo,
          [name]: val,
        },
      };
    });
  };
  onChangeControlQuickCollectClientInfoClientName = (e) => {    
    const { name, value } = e.target;
    let val = value.toUpperCase();
    this.setState((prevState) => {
      return {
        quickCollectClientInfo: {
          ...prevState.quickCollectClientInfo,
          [name]: val.replace(/[^&a-zA-Z0-9\s]/g, ""),
        },
      };
    });
  };
  onChangeControlOldQuickCollectClientInfo = (e, oldValue, nameOld) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        quickCollectClientInfo: {
          ...prevState.quickCollectClientInfo,
          [name]: value,
        },
      };
    });

    this.setState({
      [nameOld]: oldValue,
    });
  };
  onChangeControlCheckQuickCollectClientInfo = (e) => {
    const { name, value } = e.target;
    if (name === "rppsBiller" || name ==='ofacViolation') {
      if (value === "Y") {
        this.setState((prevState) => {
          return {
            quickCollectClientInfo: {
              ...prevState.quickCollectClientInfo,
              [name]: "N",
            },
          };
        });
      }else{
        this.setState((prevState) => {
          return {
            quickCollectClientInfo: {
              ...prevState.quickCollectClientInfo,
              [name]: "Y",
            },
          };
        });
      }
    }
    if (name === "d2b") {
      if (value === "1" || value === 1) {
        this.setState((prevState) => {
          return {
            quickCollectClientInfo: {
              ...prevState.quickCollectClientInfo,
              [name]: "0",
            },
          };
        });
      }else{
        this.setState((prevState) => {
          return {
            quickCollectClientInfo: {
              ...prevState.quickCollectClientInfo,
              [name]: "1",
            },
          };
        });
      }
    }
    if (name === "commonBank" || name ==='remove') {
      if (value === true  || value === 'true') {
        this.setState((prevState) => {
          return {
            quickCollectClientInfo: {
              ...prevState.quickCollectClientInfo,
              [name]: "false",
            },
          };
        });
      }else{
        this.setState((prevState) => {
          return {
            quickCollectClientInfo: {
              ...prevState.quickCollectClientInfo,
              [name]: "true",
            },
          };
        });
      }
    }   
    // if (value === "on") {
    //   this.setState((prevState) => {
    //     return {quickCollectClientInfo: {...prevState.quickCollectClientInfo,[name]: "off"},
    //     };
    //   });
    // } else if (value === "off") {
    //   this.setState((prevState) => {
    //     return {

    //       quickCollectClientInfo: {
    //         ...prevState.quickCollectClientInfo,
    //         [name]: "on",
    //       },
    //     };
    //   });
    // } else if (value === "Y") {
      
    //   this.setState((prevState) => {
    //     return {
    //       quickCollectClientInfo: {
    //         ...prevState.quickCollectClientInfo,
    //         [name]: "N",
    //       },
    //     };
    //   });
    // } else if (value === "N") {
    //   this.setState((prevState) => {
    //     return {quickCollectClientInfo: {
    //         ...prevState.quickCollectClientInfo,
    //         [name]: "Y",
    //       },
    //     };
    //   });
    // } else if (value === "true") {
    //   this.setState((prevState) => {
    //     return {
    //       quickCollectClientInfo: {
    //         ...prevState.quickCollectClientInfo,
    //         [name]: "false",
    //       }};
    //   });
    // } else if (value === "false") {
    //   this.setState((prevState) => {
    //     return {
    //       quickCollectClientInfo: {
    //         ...prevState.quickCollectClientInfo,[name]: "true",
    //       }};});
    // }else if (value === "1" || value === 1) {
    //   this.setState((prevState) => {
    //     return {
    //       quickCollectClientInfo: {
    //         ...prevState.quickCollectClientInfo,
    //         [name]: "0",
    //       },
    //     };

    //   });
    // }else if (value === "0" || value === 0 || value==='') {
    //   this.setState((prevState) => {
    //     return {
    //       quickCollectClientInfo: 
    //       {
    //         ...prevState.quickCollectClientInfo,
    //         [name]: "1",
    //       },
    //     };
    //   });
    // }
  };
  onHandleRefreshQCCI = () => {
    this.setState({
      quickCollectClientInfo: this.state.rowQCCI[this.state.countQCCI]
    });
  };
  onHandleBackQCCI = () => {
    this.props.history.push("/quick-collect-database-maintenance");
  };
  onHandleDelivery = () => {
    this.props.history.push({
      pathname: "/quick-collect-device-information",
      state: {
        deviceId: this.state.quickCollectClientInfo.delivery,
        agent: this.props.location.state.agent,
        data:this.props.location.state,
      },
    });
  };

  onHandleNotification = () => {
    this.props.history.push({
      pathname: "/quick-collect-device-information",
      state: {
        deviceId: this.state.quickCollectClientInfo.notification,
        agent: this.props.location.state.agent,
        data:this.props.location.state,
      },
    });
  };
  onHandleBufferDataQCCI = (type) => {
    if (type === "prevQCCI") {
      this.setState(
        {
          countQCCI: this.state.countQCCI - 1,
        },
        () => {
          this.setState({
            enableNextQCCI: true,
            quickCollectClientInfo: this.state.rowQCCI[this.state.countQCCI],
          });
          if (this.state.countQCCI === 0) {
            this.setState({
              enablePrevQCCI: false,
              enableNextQCCI: true,
            });
          }
        }
      );
    } else {
      if (this.state.countQCCI < this.state.rowQCCI.length - 1) {
        this.setState(
          {
            countQCCI: this.state.countQCCI + 1,
          },
          () => {
            this.setState({
              enablePrevQCCI: true,
              quickCollectClientInfo: this.state.rowQCCI[this.state.countQCCI],
            });
            if (this.state.countQCCI === this.state.rowQCCI.length - 1) {
              this.setState({
                enablePrevQCCI: true,
                enableNextQCCI: false,
              });
            }
          }
        );
      }
    }
  };

  getQuickCollectClientInfoModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showQuickCollectClientInfoModal}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Inactivate This QCC Client ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={this.onHandleInactiveQCCI}
                data-test="button-yes"
              >
                YES
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={() =>
                  this.setState({
                    showQuickCollectClientInfoModal: false,
                  })
                }
                data-test="button-no"
              >
                NO
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleInactiveQCCI = () => {
    this.setState({
      showQuickCollectClientInfoModal: false,
    });
    let submittedObjQCCI = {};
    submittedObjQCCI.cityCodeState =
      this.state.quickCollectClientInfo.cityCodeState;
    submittedObjQCCI.account = this.state.quickCollectClientInfo.account;
    submittedObjQCCI.recordVersion =
      this.state.quickCollectClientInfo.recordVersion;
    submittedObjQCCI.clientName = this.state.quickCollectClientInfo.clientName;
    submittedObjQCCI.group = this.state.quickCollectClientInfo.group;
    submittedObjQCCI.address1 = this.state.quickCollectClientInfo.address1;
    submittedObjQCCI.address2 = this.state.quickCollectClientInfo.address2;
    submittedObjQCCI.city = this.state.quickCollectClientInfo.city;
    submittedObjQCCI.state = this.state.quickCollectClientInfo.state;
    submittedObjQCCI.zipCode = this.state.quickCollectClientInfo.zipCode;
    submittedObjQCCI.country = this.state.quickCollectClientInfo.country;
    submittedObjQCCI.contactName =
      this.state.quickCollectClientInfo.contactName;
    submittedObjQCCI.status = this.state.quickCollectClientInfo.status;
    submittedObjQCCI.phoneNumber =
      this.state.quickCollectClientInfo.phoneNumber;
    submittedObjQCCI.clientId = this.state.quickCollectClientInfo.clientId;
    submittedObjQCCI.rppsBiller = this.state.quickCollectClientInfo.rppsBiller;
    submittedObjQCCI.swiftPay = this.state.quickCollectClientInfo.swiftPay;
    submittedObjQCCI.d2b = this.state.quickCollectClientInfo.d2b;
    submittedObjQCCI.reportDelivery =
      this.state.quickCollectClientInfo.reportDelivery;
    submittedObjQCCI.qqcType = this.state.quickCollectClientInfo.qqcType?this.state.quickCollectClientInfo.qqcType:1;    
    submittedObjQCCI.commonBank = this.state.quickCollectClientInfo.commonBank;
    submittedObjQCCI.ofacViolation =
      this.state.quickCollectClientInfo.ofacViolation;
    submittedObjQCCI.dateAdd = this.state.quickCollectClientInfo.dateAdd;
    submittedObjQCCI.lastUpd = this.state.quickCollectClientInfo.lastUpd;
    submittedObjQCCI.delivery = this.state.quickCollectClientInfo.delivery;
    submittedObjQCCI.notification =
      this.state.quickCollectClientInfo.notification;
    submittedObjQCCI.remove = this.state.quickCollectClientInfo.remove;
    submittedObjQCCI.creditCard = this.state.quickCollectClientInfo.creditCard;
    submittedObjQCCI.ccUsaCanRateTable =
      this.state.quickCollectClientInfo.ccUsaCanRateTable;
    submittedObjQCCI.intRate = this.state.quickCollectClientInfo.intRate;
    submittedObjQCCI.usaRateType =
      this.state.quickCollectClientInfo.usaRateType;
    submittedObjQCCI.usaSenderRate =
      this.state.quickCollectClientInfo.usaSenderRate;
    submittedObjQCCI.usaQCCRate = this.state.quickCollectClientInfo.usaQCCRate;
    submittedObjQCCI.canRateType =
      this.state.quickCollectClientInfo.canRateType;
    submittedObjQCCI.canSenderRate =
      this.state.quickCollectClientInfo.canSenderRate;
    submittedObjQCCI.canQCCRate = this.state.quickCollectClientInfo.canQCCRate;
    submittedObjQCCI.usaStatus = 'N';
    submittedObjQCCI.usaLastUpdate =
      this.state.quickCollectClientInfo.usaLastUpdate;
    submittedObjQCCI.canStatus = 'N';
    submittedObjQCCI.canLastUpdate =
      this.state.quickCollectClientInfo.canLastUpdate;
    submittedObjQCCI.intStatus = 'N';
    submittedObjQCCI.intLastUpdate =
      this.state.quickCollectClientInfo.intLastUpdate;
    submittedObjQCCI.intQccPricing =
      this.state.quickCollectClientInfo.intQccPricing;
    submittedObjQCCI.emailAddress =
      this.state.quickCollectClientInfo.emailAddress;
    submittedObjQCCI.highVersion =
      this.state.quickCollectClientInfo.highVersion;
    submittedObjQCCI.efee = this.state.quickCollectClientInfo.efee;
    submittedObjQCCI.ediscount = this.state.quickCollectClientInfo.ediscount;
    submittedObjQCCI.spayPurge = this.state.quickCollectClientInfo.spayPurge;
    submittedObjQCCI.operatorId= this.props.location.state.operatorIdQCDM;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/inactive`,
        submittedObjQCCI,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.tokenQCDM,
            userId: this.props.location.state.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            submitStatusQuickCollectClientInfo: "OK",
            submitMsgQuickCollectClientInfo: response.data.responseString,
            enableBtn: false,
          });
          this.onLoadQCCI();
        } else {
          this.setState({
            submitStatusQuickCollectClientInfo: "Error",
            submitMsgQuickCollectClientInfo: response.data.responseString,
          });
        }
      });
      
  };

  onValidateQuickCollectClientInfo = () => {
    if (this.state.quickCollectClientInfo.clientName === "" || this.state.quickCollectClientInfo.clientName === null) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Please Enter Value In Client Name",
      });
      return false;
    }
    if (
      (this.state.quickCollectClientInfo.swiftPay !== "") &&
      this.state.quickCollectClientInfo.d2b === "1"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo:
          "Either D2Bank Or SwiftPay Is Allowed",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.swiftPay !== "" ||
      this.state.quickCollectClientInfo.d2b === "1"
    ) {
      if (this.state.quickCollectClientInfo.commonBank === "true") {
        this.setState({
          submitStatusQuickCollectClientInfo: "Error",
          submitMsgQuickCollectClientInfo:
            "Common Bank Should Unchecked For D2Bank/SwiftPay",
        });
        return false;
      }
    }
    if (
      this.state.quickCollectClientInfo.commonBank === "true" &&
      this.state.quickCollectClientInfo.country === ""
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Please Enter Value In Country",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.commonBank === "true" &&
      this.state.quickCollectClientInfo.clientId === ""
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Please Enter Value In Client ID",
      });
      return false;
    }

    if (
      this.state.quickCollectClientInfo.commonBank === "true" &&
      (this.state.quickCollectClientInfo.clientId === 0 ||
        (this.state.quickCollectClientInfo.clientId !== "" &&
          /^\d+$/.test(this.state.quickCollectClientInfo.clientId) === false))
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Client Id Is Invalid ",
      });
      return false;
    }
    if (
      (this.state.quickCollectClientInfo.group !== "" &&
      /^[0-9]+$/.test(this.state.quickCollectClientInfo.group) === false) 
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Group Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.spayPurge !== "" &&
      /^\d+$/.test(this.state.quickCollectClientInfo.spayPurge) === false
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "SpayPurge Is Invalid ",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.d2b === "1" &&
      this.state.quickCollectClientInfo.clientId !== ""
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo:
          "Either D2bank Or Client ID Is Allowed",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.d2b !== "1" &&
      this.state.quickCollectClientInfo.commonBank !== "true" &&
      this.state.quickCollectClientInfo.swiftPay === "" &&
      this.state.quickCollectClientInfo.spayPurge > 0
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo:
          "Either D2bank OR SwiftPay Client Can Have SpayPurge Value",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.ediscount !== "" &&
      /^\d+(\.\d+)?$/.test(this.state.quickCollectClientInfo.ediscount) ===
        false
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "EDiscount Is Invalid  ",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.efee !== "" &&
      /^\d+(\.\d+)?$/.test(this.state.quickCollectClientInfo.efee) === false
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "EFee Is Invalid  ",
      });
      return false;
    }
    if (this.state.quickCollectClientInfo.ediscount > 99.999) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "EDiscount Value Is Too Large ",
      });
      return false;
    }
    if (this.state.quickCollectClientInfo.efee > 999.999) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "EFee Value Is Too Large ",
      });
      return false;
    }
    if (
      (this.state.quickCollectClientInfo.d2b === "1" ||
        this.state.quickCollectClientInfo.commonBank === "true" ||
        this.state.quickCollectClientInfo.swiftPay !== "") &&
      this.state.quickCollectClientInfo.account === ""
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Must Enter Account For QCC ",
      });
      return false;
    }

    if (
      (this.state.quickCollectClientInfo.delivery === "" || this.state.quickCollectClientInfo.delivery === null)&&
      (this.state.quickCollectClientInfo.notification === ""|| this.state.quickCollectClientInfo.notification === null)
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Must Be Atleast One Device ",
      });
      return false;
    }
    if (
      this.state.mode === "update" &&
      this.state.quickCollectClientInfo.remove === "true" &&
      this.state.quickCollectClientInfo.notification !==
        this.state.notificationOld
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: `Notification Remove Cannot Be Selected For Updated Device Notification - ${this.state.notificationOld} `,
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.remove === "true" &&
      this.state.quickCollectClientInfo.delivery === ""
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: `Notification Remove Cannot Be Selected, Atleast One Device Should Exist `,
      });
      return false;
    }

    if (
      (this.state.quickCollectClientInfo.ccUsaCanRateTable !== "" &&
      /^[A-Z0-9]?$/.test(
        this.state.quickCollectClientInfo.ccUsaCanRateTable
      ) === false)
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "CC/USA/CAN Rate Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.intRate !== "" &&
      /^[A-Z0-9]?$/.test(this.state.quickCollectClientInfo.intRate) === false
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Int Rate Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.usaSenderRate === "" ||
      /^\d+(\.\d+)?$/.test(this.state.quickCollectClientInfo.usaSenderRate) ===
        false
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Invalid USA Sender Rate  ",
      });
      return false;
    }
    if (
      /^\d+(\.\d+)?$/.test(this.state.quickCollectClientInfo.usaQCCRate) ===
        false ||
      this.state.quickCollectClientInfo.usaQCCRate === ""
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Invalid USA QCC Rate  ",
      });
      return false;
    }

    if (
      this.state.quickCollectClientInfo.usaSenderRate > 999.999 
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "USA Sender Rate Value Is Too Large",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.usaQCCRate > 999.999 
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "USA QCC Rate Value Is Too Large",
      });
      return false;
    }

    if (
      /^\d+(\.\d+)?$/.test(this.state.quickCollectClientInfo.canSenderRate) ===
        false ||
      this.state.quickCollectClientInfo.canSenderRate === ""
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Invalid CAN Sender Rate",
      });
      return false;
    }
    if (
      /^\d+(\.\d+)?$/.test(this.state.quickCollectClientInfo.canQCCRate) ===
        false ||
      this.state.quickCollectClientInfo.canQCCRate === ""
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Invalid CAN QCC Rate  ",
      });
      return false;
    }

    if (
      this.state.quickCollectClientInfo.canSenderRate > 999.999 
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "CAN Sender Rate Value Is Too Large",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.canQCCRate > 999.999
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "CAN QCC Rate Value Is Too Large",
      });
      return false;
    }

    if (this.state.quickCollectClientInfo.commonBank === "true") {
      if (
        !(
          (this.state.quickCollectClientInfo.usaRateType === "G" &&
            parseInt(this.state.quickCollectClientInfo.usaSenderRate) === 0 &&
            parseInt(this.state.quickCollectClientInfo.usaQCCRate) === 0) ||
          (this.state.quickCollectClientInfo.usaRateType === "R" &&
            parseInt(this.state.quickCollectClientInfo.usaQCCRate) === 0) ||
          (this.state.quickCollectClientInfo.usaRateType === "S" &&
            parseInt(this.state.quickCollectClientInfo.usaSenderRate) <=
              parseInt(this.state.quickCollectClientInfo.usaQCCRate))
        )
      ) {
        this.setState({
          submitStatusQuickCollectClientInfo: "Error",
          submitMsgQuickCollectClientInfo:
            "Invalid Rate(S) And Type Combination",
        });
        return false;
      }
      if (
        !(
          (this.state.quickCollectClientInfo.canRateType === "G" &&
            parseInt(this.state.quickCollectClientInfo.canSenderRate) === 0 &&
            parseInt(this.state.quickCollectClientInfo.canQCCRate) === 0) ||
          (this.state.quickCollectClientInfo.canRateType === "R" &&
            parseInt(this.state.quickCollectClientInfo.canQCCRate) === 0) ||
          (this.state.quickCollectClientInfo.canRateType === "S" &&
            parseInt(this.state.quickCollectClientInfo.canSenderRate) <=
              parseInt(this.state.quickCollectClientInfo.canQCCRate))
        )
      ) {
        this.setState({
          submitStatusQuickCollectClientInfo: "Error",
          submitMsgQuickCollectClientInfo:
            "CAN: Invalid Rate(S) And Type Combination",
        });
        return false;
      }
    }

    if (
      (this.state.usaStatusOld === "A" || this.state.usaStatusOld === "D") &&
      this.state.quickCollectClientInfo.usaStatus === "N"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo:
          "Change From A Or D To A Or D Only",
      });
      return false;
    }
    if (
      (this.state.canStatusOld === "A" || this.state.canStatusOld === "D") &&
      this.state.quickCollectClientInfo.canStatus === "N"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo:
          "Change From A Or D To A Or D Only",
      });
      return false;
    }
    if (
      (this.state.intStatusOld === "A" || this.state.intStatusOld === "D") &&
      this.state.quickCollectClientInfo.intStatus === "N"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo:
          "Change From A Or D To A Or D Only",
      });
      return false;
    }
    if (
      this.state.usaStatusOld === "N" &&
      this.state.quickCollectClientInfo.usaStatus === "D"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Can Only Change From N TO A",
      });
      return false;
    }
    if (
      this.state.canStatusOld === "N" &&
      this.state.quickCollectClientInfo.canStatus === "D"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Can Only Change From N TO A",
      });
      return false;
    }
    if (
      this.state.intStatusOld === "N" &&
      this.state.quickCollectClientInfo.intStatus === "D"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Can Only Change From N TO A",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.usaStatus === "N" &&
      this.state.quickCollectClientInfo.canStatus === "N" &&
      this.state.quickCollectClientInfo.intStatus === "N"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo:
          "No Changes Allowed-Client Marked For Delete  ",
      });
      return false;
    }
    if (
      this.state.quickCollectClientInfo.usaStatus !== "A" &&
      this.state.quickCollectClientInfo.canStatus !== "A" &&
      this.state.quickCollectClientInfo.intStatus !== "A"
    ) {
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo:
          "Atleast One Status Must Be A",
      });
      return false;
    } else {
      if (
        this.state.quickCollectClientInfo.usaStatus !== "A" &&
        this.state.quickCollectClientInfo.canStatus !== "A" &&
        this.state.quickCollectClientInfo.intStatus !== "A"
      ) {
        this.setState({
          submitStatusQuickCollectClientInfo: "Error",
          submitMsgQuickCollectClientInfo:
            "Atleast One Status Must Be A",
        });
        return false;
      }
      if (
        this.state.quickCollectClientInfo.usaStatus === "N" &&
        this.state.quickCollectClientInfo.canStatus === "N" &&
        this.state.quickCollectClientInfo.intStatus === "N"
      ) {
        this.setState({
          submitStatusQuickCollectClientInfo: "Error",
          submitMsgQuickCollectClientInfo: "All Status Cannot Be N",
        });
        return false;
      }
      if (
        this.state.quickCollectClientInfo.usaStatus === "D" &&
        this.state.quickCollectClientInfo.canStatus === "D" &&
        this.state.quickCollectClientInfo.intStatus === "D"
      ) {
        this.setState({
          submitStatusQuickCollectClientInfo: "Error",
          submitMsgQuickCollectClientInfo: "No Status Can Be D",
        });
        return false;
      }
    }
    return true;
  };
  onHandleFusionQCCI = () => {
    this.props.history.push({
      pathname: "/fusion-configuration",
      state: {
        data: this.props.location.state,
        cityCodeState: this.state.quickCollectClientInfo.cityCodeState,
        rppsBiller: this.state.quickCollectClientInfo.rppsBiller,
      },
    });
  };
  onHandleAgtIrpQCCI = () => {
    if(this.state.accountOld === ''|| this.state.accountOld === null ){
      this.setState({
        submitStatusQuickCollectClientInfo: "Error",
        submitMsgQuickCollectClientInfo: "Please Update QCC Client With Account To Perform IRP Function",
      });
    }
    else{
      this.props.history.push({
        pathname: "/quick-collect-agt-irp",
        state: {
          data: this.props.location.state,
          accountId: this.state.quickCollectClientInfo.account,
          flag: 1,
        },
      });
    }
    
  };

  onHandleUpdateQCCI = () => {
    if (!this.onValidateQuickCollectClientInfo()) {
      return;
    }
    let submittedObjQCCI = {};
    submittedObjQCCI.cityCodeState =
      this.state.quickCollectClientInfo.cityCodeState;
    submittedObjQCCI.account = this.state.quickCollectClientInfo.account;
    submittedObjQCCI.recordVersion =
      this.state.quickCollectClientInfo.recordVersion;
    submittedObjQCCI.clientName = this.state.quickCollectClientInfo.clientName;
    submittedObjQCCI.group = this.state.quickCollectClientInfo.group;
    submittedObjQCCI.address1 = this.state.quickCollectClientInfo.address1;
    submittedObjQCCI.address2 = this.state.quickCollectClientInfo.address2;
    submittedObjQCCI.city = this.state.quickCollectClientInfo.city;
    submittedObjQCCI.state = this.state.quickCollectClientInfo.state;
    submittedObjQCCI.zipCode = this.state.quickCollectClientInfo.zipCode;
    submittedObjQCCI.country = this.state.quickCollectClientInfo.country;
    submittedObjQCCI.contactName =
      this.state.quickCollectClientInfo.contactName;
    submittedObjQCCI.status = this.state.quickCollectClientInfo.status;
    submittedObjQCCI.phoneNumber =
      this.state.quickCollectClientInfo.phoneNumber;
    submittedObjQCCI.clientId = this.state.quickCollectClientInfo.clientId;
    submittedObjQCCI.rppsBiller = this.state.quickCollectClientInfo.rppsBiller;
    submittedObjQCCI.swiftPay = this.state.quickCollectClientInfo.swiftPay;
    submittedObjQCCI.d2b = this.state.quickCollectClientInfo.d2b;
    submittedObjQCCI.reportDelivery =
      this.state.quickCollectClientInfo.reportDelivery;
    submittedObjQCCI.qqcType = this.state.quickCollectClientInfo.qqcType?this.state.quickCollectClientInfo.qqcType:1;    
    submittedObjQCCI.commonBank = this.state.quickCollectClientInfo.commonBank;
    submittedObjQCCI.ofacViolation =
      this.state.quickCollectClientInfo.ofacViolation;
    submittedObjQCCI.dateAdd = this.state.quickCollectClientInfo.dateAdd;
    submittedObjQCCI.lastUpd = this.state.quickCollectClientInfo.lastUpd;
    submittedObjQCCI.delivery = this.state.quickCollectClientInfo.delivery;
    submittedObjQCCI.notification =
      this.state.quickCollectClientInfo.notification;
    submittedObjQCCI.remove = this.state.quickCollectClientInfo.remove;
    submittedObjQCCI.creditCard = this.state.quickCollectClientInfo.creditCard;
    submittedObjQCCI.ccUsaCanRateTable =
      this.state.quickCollectClientInfo.ccUsaCanRateTable;
    submittedObjQCCI.intRate = this.state.quickCollectClientInfo.intRate;
    submittedObjQCCI.usaRateType =
      this.state.quickCollectClientInfo.usaRateType;
    submittedObjQCCI.usaSenderRate =
      this.state.quickCollectClientInfo.usaSenderRate;
    submittedObjQCCI.usaQCCRate = this.state.quickCollectClientInfo.usaQCCRate;
    submittedObjQCCI.canRateType =
      this.state.quickCollectClientInfo.canRateType;
    submittedObjQCCI.canSenderRate =
      this.state.quickCollectClientInfo.canSenderRate;
    submittedObjQCCI.canQCCRate = this.state.quickCollectClientInfo.canQCCRate;
    submittedObjQCCI.usaStatus = this.state.quickCollectClientInfo.usaStatus;
    submittedObjQCCI.usaLastUpdate =
      this.state.quickCollectClientInfo.usaLastUpdate;
    submittedObjQCCI.canStatus = this.state.quickCollectClientInfo.canStatus;
    submittedObjQCCI.canLastUpdate =
      this.state.quickCollectClientInfo.canLastUpdate;
    submittedObjQCCI.intStatus = this.state.quickCollectClientInfo.intStatus;
    submittedObjQCCI.intLastUpdate =
      this.state.quickCollectClientInfo.intLastUpdate;
    submittedObjQCCI.intQccPricing =
      this.state.quickCollectClientInfo.intQccPricing;
    submittedObjQCCI.emailAddress =
      this.state.quickCollectClientInfo.emailAddress;
    submittedObjQCCI.highVersion =
      this.state.quickCollectClientInfo.highVersion;
    submittedObjQCCI.efee = this.state.quickCollectClientInfo.efee;
    submittedObjQCCI.ediscount = this.state.quickCollectClientInfo.ediscount;
    submittedObjQCCI.spayPurge = this.state.quickCollectClientInfo.spayPurge;
    submittedObjQCCI.operatorId= this.props.location.state.operatorIdQCDM;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/qqcclientinfo`,
        submittedObjQCCI,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.tokenQCDM,
            userId: this.props.location.state.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if(parseInt(response.data.responseCode) === 500) {
            this.setState({
              submitStatusQuickCollectClientInfo: "Error",
              submitMsgQuickCollectClientInfo: response.data.responseString,
            });
          } else {
            this.setState({
              submitStatusQuickCollectClientInfo: "OK",
              submitMsgQuickCollectClientInfo: response.data.responseString,
              enableBtn: false,
            });
            this.onLoadQCCI();
          }
         
        } else {
          this.setState({
            submitStatusQuickCollectClientInfo: "Error",
            submitMsgQuickCollectClientInfo: response.data.responseString,
          });
        }
      });
  };

  getStatusQCCI = (uniqueKey) => {
    let valOfST = ["A", "D", "N"];
    let resultValOfST = valOfST.map((item) => (
      <option key={[item, uniqueKey]} value={item}>
        {item}
      </option>
    ));
    return [resultValOfST];
  };
  getUsaRateTypeQCCI = (uniqueKey) => {
    let valOfRT = ["G", "R", "S"];
    let resultValOfRT = valOfRT.map((item) => (
      <option key={[item, uniqueKey]} value={item}>
        {item}
      </option>
    ));
    return [resultValOfRT];
  };
  getCreditCardQCCI = () => {
    if(this.state.valOfCC.length > 0) {
      return this.state.valOfCC.map((item) => (
        <option key={item.value} value={item.key}>
          {item.value}
        </option>
      ));
    } else {
      return null;
    }
  };
  getSwiftPayQCCI = () => {
    let valOfSP = [
      "X",
      "P",
      "Q",
      "G",
      "C",
      "R",
      "S",
      "A",
      "B",
      "D",
      "E",
      "F",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "T",
      "U",
      "V",
      "W",
      "Y",
      "Z",
    ];
    let resultValOfSP = valOfSP.map((item) => (
      <option key={[item]} value={item}>
        {item}
      </option>
    ));
    return [<option value=""></option>, resultValOfSP];
  };

  getReportDeliveryQCCI = () => {
    let valOfDO = ["D", "O"];
    let resultValOfDO = valOfDO.map((item) => (
      <option key={[item]} value={item}>
        {item}
      </option>
    ));
    return resultValOfDO;
    // return [<option value="">-Select-</option>, resultValOfDO];
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="QUICK COLLECT UPDATE" />
        <div className="container-fluid">
            <div className="card-body">
              <h4 className="card-title text-center main-heading">
                Quick Collect Client Information &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; High Version:{" "}
                {this.state.quickCollectClientInfo.highVersion}
              </h4>
              <div className="row ">
                <div className="col-lg">
                  <div className="card mar-top20 ">
                    <div className="card-header bg-dark text-white card-header-custom">
                      CLIENT DETAILS
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="cityCodestate" className="font-weight-bold">
                              City Code/State
                            </label>
                            <input
                              type="text"
                              name="cityCodeState"
                              className="form-control form-control-sm"
                              placeholder="City Code/State"
                              data-test="text-cityCodestate"
                              // onChange={(e) => {
                              //   this.onChangeControlQuickCollectClientInfo(e);
                              // }}
                              value={
                                this.state.quickCollectClientInfo.cityCodeState
                              }
                              disabled={true}
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="clientName" className="font-weight-bold">Client Name</label>
                            <input
                              type="text"
                              name="clientName"
                              className="form-control form-control-sm"
                              placeholder="Client Name"
                              data-test="text-clientName"
                              maxLength="28"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfoClientName(e);
                              }}
                              disabled={!this.props.location.state.adminQCCI}
                              value={
                                this.state.quickCollectClientInfo.clientName
                              }
                            />
                          </div>

                          <div className="col-lg">
                            <label htmlFor="account" className="font-weight-bold">Account</label>
                            <input
                              type="text"
                              name="account"
                              className="form-control form-control-sm"
                              placeholder="Account"
                              data-test="text-account"
                              maxLength="9"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.account}
                              disabled={!this.props.location.state.adminQCCI}
                            />
                          </div>

                          <div className="col-lg">
                            <label htmlFor="recordVersion" className="font-weight-bold">
                              Record Version
                            </label>
                            <input
                              type="text"
                              name="recordVersion"
                              className="form-control form-control-sm"
                              placeholder="Record Version"
                              data-test="text-recordVersion"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={
                                this.state.quickCollectClientInfo.recordVersion
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="group" className="font-weight-bold">Group</label>
                            <input
                              type="text"
                              name="group"
                              className="form-control form-control-sm"
                              placeholder="Group"
                              data-test="text-group"
                              maxLength="2"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.group}
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="address1" className="font-weight-bold">Address1</label>
                            <input
                              type="text"
                              name="address1"
                              className="form-control form-control-sm"
                              placeholder="Address1"
                              maxLength="40"
                              data-test="text-address1"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.address1}
                            />
                          </div>

                          <div className="col-lg">
                            <label htmlFor="address2" className="font-weight-bold">Address2</label>
                            <input
                              type="text"
                              name="address2"
                              className="form-control form-control-sm"
                              placeholder="Address2"
                              maxLength="40"
                              data-test="text-address2"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.address2}
                            />
                          </div>

                          <div className="col-lg">
                            <label htmlFor="city" className="font-weight-bold">City</label>
                            <input
                              type="text"
                              name="city"
                              className="form-control form-control-sm"
                              placeholder="City"
                              data-test="text-city"
                              maxLength="24"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.city}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="state" className="font-weight-bold">State</label>
                            <input
                              type="text"
                              name="state"
                              className="form-control form-control-sm"
                              placeholder="State"
                              maxLength="2"
                              data-test="text-state"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.state}
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="zipCode" className="font-weight-bold">Zip Code</label>
                            <input
                              type="text"
                              name="zipCode"
                              maxLength="10"
                              className="form-control form-control-sm"
                              placeholder="Zip Code"
                              data-test="text-zipCode"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.zipCode}
                            />
                          </div>

                          <div className="col-lg">
                            <label htmlFor="country" className="font-weight-bold">Country</label>
                            <input
                              type="text"
                              name="country"
                              className="form-control form-control-sm"
                              placeholder="Country"
                              maxLength="3"
                              data-test="text-country"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.country}
                              disabled={!this.props.location.state.adminQCCI}
                            />
                          </div>

                          <div className="col-lg">
                            <label htmlFor="contactName" className="font-weight-bold">Contact Name</label>
                            <input
                              type="text"
                              name="contactName"
                              className="form-control form-control-sm"
                              placeholder="Contact Name"
                              maxLength="40"
                              data-test="text-contactName"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={
                                this.state.quickCollectClientInfo.contactName
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <div className="row">
                              <div className="col-lg">
                                <label htmlFor="status" className="font-weight-bold">Status</label>
                                <input
                                  type="text"
                                  name="status"
                                  className="form-control form-control-sm"
                                  placeholder="Status"
                                  data-test="text-status"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo.status === 0|| this.state.quickCollectClientInfo.status === '0'? 'Active': 'Inactive'
                                  }
                                  disabled
                                />
                              </div>
                              <div className="col-lg">
                                <label htmlFor="phoneNumber" className="font-weight-bold">
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control form-control-sm"
                                  placeholder="Phone Number"
                                  data-test="text-phoneNumber"
                                  maxLength="20"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo
                                      .phoneNumber
                                  }
                                />
                              </div>
                              <div className="col-lg">
                                <label htmlFor="clientId" className="font-weight-bold">Client Id</label>
                                <input
                                  type="text"
                                  name="clientId"
                                  className="form-control form-control-sm"
                                  placeholder="Client Id"
                                  maxLength="10"
                                  data-test="text-clientId"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo.clientId
                                  }
                                />
                              </div>
                              <div className="form-check form-check-inline col-lg">
                                <input
                                  type="checkbox"
                                  name="rppsBiller"
                                  className="form-check-input rpps-biller-radio"
                                  placeholder="RPPS Biller"
                                  data-test="text-rppsBiller"
                                  onChange={(e) => {
                                    this.onChangeControlCheckQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  checked={
                                    this.state.quickCollectClientInfo
                                      .rppsBiller === "Y"
                                  }
                                  value={
                                    this.state.quickCollectClientInfo.rppsBiller
                                  }
                                />
                                <label htmlFor="rppsBiller" className="font-weight-bold rpps-biller-label">RPPS Biller</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg ">
                      <div className="card mar-top20 ">
                        <div className="card-header bg-dark text-white card-header-custom">
                          PAY DETAILS
                        </div>
                        <div className="card-body">
                          <form>
                            <div className="form-group row">
                              <div className="col-lg">
                                <label htmlFor="swiftPay" className="font-weight-bold"> Swift Pay</label>
                                <select
                                  className="form-control  form-control-sm mar-bottom20"
                                  name="swiftPay"
                                  data-test="text-swiftPay"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  disabled={!this.props.location.state.adminQCCI}
                                  value={
                                    this.state.quickCollectClientInfo.swiftPay
                                  }
                                >
                                  {this.getSwiftPayQCCI()}
                                </select>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  name="d2b"
                                  className="form-check-input"
                                  placeholder="D2B"
                                  data-test="text-d2b"
                                  onChange={(e) => {
                                    this.onChangeControlCheckQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  disabled={!this.props.location.state.adminQCCI}
                                  checked={
                                    this.state.quickCollectClientInfo.d2b ==="1" ||
                                    this.state.quickCollectClientInfo.d2b === 1
                                  }
                                  value={this.state.quickCollectClientInfo.d2b}
                                />
                                <label htmlFor="d2b" className="font-weight-bold mar-top10">D2B</label>
                              </div>
                              <div className="col-lg">
                                <label htmlFor="spayPurge" className="font-weight-bold">SPayPurge</label>
                                <input
                                  type="text"
                                  name="spayPurge"
                                  maxLength="2"
                                  className="form-control form-control-sm"
                                  placeholder="spayPurge"
                                  data-test="text-spayPurge"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo.spayPurge
                                  }
                                  disabled={!this.props.location.state.adminQCCI}
                                />
                              </div>
                              <div className="col-lg">
                                <label htmlFor="ediscount" className="font-weight-bold">EDiscount</label>
                                <input
                                  type="text"
                                  name="ediscount"
                                  className="form-control form-control-sm"
                                  placeholder="ediscount"
                                  maxLength="6"
                                  data-test="text-ediscount"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo.ediscount
                                  }
                                  disabled={!this.props.location.state.adminQCCI}
                                />
                              </div>
                              <div className="col-lg">
                                <label htmlFor="efee" className="font-weight-bold">EFee</label>
                                <input
                                  type="text"
                                  name="efee"
                                  className="form-control form-control-sm"
                                  placeholder="efee"
                                  maxLength="7"
                                  data-test="text-efee"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={this.state.quickCollectClientInfo.efee}
                                  disabled={!this.props.location.state.adminQCCI}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-lg">
                                <div className="row">
                                  <div className="col-lg">
                                    <label htmlFor="reportDelivery" className="font-weight-bold">
                                      Report Delivery
                                    </label>
                                    <select
                                      className="form-control  form-control-sm mar-bottom20"
                                      name="reportDelivery"
                                      data-test="text-reportDelivery"
                                      onChange={(e) => {
                                        this.onChangeControlQuickCollectClientInfo(
                                          e
                                        );
                                      }}
                                      value={
                                        this.state.quickCollectClientInfo
                                          .reportDelivery
                                      }
                                      disabled={!this.props.location.state.adminQCCI}
                                    >
                                      {this.getReportDeliveryQCCI()}
                                    </select>
                                  </div>

                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input "
                                      type="radio"
                                      name="qqcType"
                                      value={2 ||'2'}
                                      data-test="text-ach"
                                      checked={
                                        this.state.quickCollectClientInfo
                                          .qqcType === 2 ||  this.state.quickCollectClientInfo
                                          .qqcType === '2'
                                      }
                                      onChange={(e) => {
                                        this.onChangeControlQuickCollectClientInfo(
                                          e
                                        );
                                      }}
                                      disabled={!this.props.location.state.adminQCCI}
                                    />
                                    <label
                                      className="form-check-label font-weight-bold"
                                      htmlFor="ach"
                                    >
                                      ACH
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline col-lg">
                                    <input
                                      className="form-check-input "
                                      type="radio"
                                      name="qqcType"
                                      value={1||"1"}
                                      data-test="text-checkWriter"
                                      checked={
                                        this.state.quickCollectClientInfo
                                          .qqcType === 1|| this.state.quickCollectClientInfo
                                          .qqcType === '1'
                                      }
                                      onChange={(e) => {
                                        this.onChangeControlQuickCollectClientInfo(
                                          e
                                        );
                                      }}
                                      disabled
                                    />
                                    <label
                                      className="form-check-label font-weight-bold"
                                      htmlFor="ach"
                                    >
                                      Check Writer
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline col-lg">
                                    <input
                                      type="checkbox"
                                      name="commonBank"
                                      className="form-check-input"
                                      data-test="text-commonBank"
                                      onChange={(e) => {
                                        this.onChangeControlCheckQuickCollectClientInfo(
                                          e
                                        );
                                      }}
                                      checked={
                                        this.state.quickCollectClientInfo.commonBank === "true" ||
                                        this.state.quickCollectClientInfo.commonBank === true
                                      }
                                      value={
                                        this.state.quickCollectClientInfo
                                          .commonBank
                                      }
                                      disabled={!this.props.location.state.adminQCCI}
                                    />
                                    <label htmlFor="commonBank" className="font-weight-bold mar-top10">
                                      Common Bank
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline col-lg">
                                    <input
                                      type="checkbox"
                                      name="ofacViolation"
                                      className="form-check-input"
                                      data-test="text-ofacViolation"
                                      onChange={(e) => {
                                        this.onChangeControlCheckQuickCollectClientInfo(
                                          e
                                        );
                                      }}
                                      checked={
                                        this.state.quickCollectClientInfo
                                          .ofacViolation === "Y"
                                      }
                                      value={
                                        this.state.quickCollectClientInfo
                                          .ofacViolation
                                      }
                                      disabled={!this.props.location.state.adminQCCI}
                                    />
                                    <label htmlFor="ofacViolation" className="font-weight-bold mar-top10">
                                      OFAC Violation
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg ">
                      <div className="card mar-top20 ">
                        <div className="card-header bg-dark text-white card-header-custom">
                          GLOBAL RATING FLAGS AND RATES
                        </div>
                        <div className="card-body">
                          <form>
                            <div className="form-group row">
                              <div className="rate-type-label font-weight-bold">USA:</div>
                              <div className="col-lg">
                                <label htmlFor="usaRateType" className="font-weight-bold">Rate Type</label>
                                <select
                                  className="form-control  form-control-sm mar-bottom20"
                                  name="usaRateType"
                                  data-test="text-usaRateType"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo
                                      .usaRateType
                                  }
                                  disabled={!this.props.location.state.adminQCCI}
                                >
                                  {this.getUsaRateTypeQCCI("key1")}
                                </select>
                              </div>
                              <div className="col-lg">
                                <label htmlFor="usaSenderRate" className="font-weight-bold">
                                  Sender Rate
                                </label>
                                <input
                                  type="text"
                                  name="usaSenderRate"
                                  maxLength="7"
                                  className="form-control form-control-sm"
                                  placeholder="Sender Rate"
                                  data-test="text-usaSenderRate"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  disabled={!this.props.location.state.adminQCCI}
                                  value={
                                    this.state.quickCollectClientInfo
                                      .usaSenderRate
                                  }
                                />
                              </div>

                              <div className="col-lg">
                                <label htmlFor="usaQCCRate" className="font-weight-bold">QCC Rate</label>
                                <input
                                  type="text"
                                  name="usaQCCRate"
                                  maxLength="7"
                                  className="form-control form-control-sm"
                                  placeholder="QCC Rate"
                                  data-test="text-usaQCCRate"
                                  disabled={!this.props.location.state.adminQCCI}
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo.usaQCCRate
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="rate-type-label font-weight-bold">CAN:</div>
                              <div className="col-lg">
                                <label htmlFor="canRateType" className="font-weight-bold">Rate Type</label>
                                <select
                                  className="form-control  form-control-sm mar-bottom20"
                                  name="canRateType"
                                  data-test="text-canRateType"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo
                                      .canRateType
                                  }
                                >
                                  {this.getUsaRateTypeQCCI("key2")}
                                </select>
                              </div>
                              <div className="col-lg">
                                <label htmlFor="canSenderRate" className="font-weight-bold">
                                  Sender Rate
                                </label>
                                <input
                                  type="text"
                                  name="canSenderRate"
                                  maxLength="7"
                                  className="form-control form-control-sm"
                                  placeholder="Sender Rate"
                                  data-test="text-canSenderRate"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  disabled={!this.props.location.state.adminQCCI}
                                  value={
                                    this.state.quickCollectClientInfo
                                      .canSenderRate
                                  }
                                />
                              </div>

                              <div className="col-lg">
                                <label htmlFor="canQCCRate" className="font-weight-bold">QCC Rate</label>
                                <input
                                  type="text"
                                  name="canQCCRate"
                                  maxLength="7"
                                  className="form-control form-control-sm"
                                  placeholder="QCC Rate"
                                  data-test="text-canQCCRate"
                                  disabled={!this.props.location.state.adminQCCI}
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  value={
                                    this.state.quickCollectClientInfo.canQCCRate
                                  }
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg ">
                      <div className="card mar-top20 ">
                        <div className="card-header bg-dark text-white card-header-custom">
                          MODIFICATION HISTORY
                        </div>
                        <div className="card-body">
                          <form>
                            <div className="form-group row">
                              <div className="col-lg">
                                <label htmlFor="dateAdd" className="font-weight-bold">Date Add</label>
                                <input
                                  type="text"
                                  name="dateAdd"
                                  className="form-control form-control-sm"
                                  placeholder="Date Add"
                                  data-test="text-dateAdd"
                                  disabled
                                  value={
                                    this.state.quickCollectClientInfo.dateAdd
                                  }
                                />
                              </div>
                              <div className="col-lg">
                                <label htmlFor="lastUpd" className="font-weight-bold">Last Upd</label>
                                <input
                                  type="text"
                                  name="lastUpd"
                                  className="form-control form-control-sm"
                                  disabled
                                  placeholder="Last Upd"
                                  data-test="text-lastUpd"
                                  value={
                                    this.state.quickCollectClientInfo.lastUpd
                                  }
                                />
                              </div>
                              <div className="col-lg">
                                <label htmlFor="lastUpd" className="font-weight-bold">Operator Id</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder="Operator Id"
                                  disabled
                                  value={this.state.quickCollectClientInfo.operatorId}
                                  name='operatorId'
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg ">
                      <div className="card mar-top20 ">
                        <div className="card-header bg-dark text-white card-header-custom">
                          DEVICE ID
                        </div>
                        <div className="card-body">
                          <form>
                            <div className="form-group row">
                              <div className="col-lg">
                                <div className="row">
                                  <div className="col-lg">
                                    <label htmlFor="delivery" className="font-weight-bold">Delivery</label>
                                    <input
                                      type="text"
                                      name="delivery"
                                      maxLength="12"
                                      className="form-control form-control-sm"
                                      placeholder="Delivery"
                                      data-test="text-delivery"
                                      onChange={(e) => {
                                        this.onChangeControlQuickCollectClientInfo(
                                          e
                                        );
                                      }}
                                      value={
                                        this.state.quickCollectClientInfo
                                          .delivery
                                      }
                                      disabled={!this.props.location.state.adminQCCI}
                                    />
                                  </div>
                                  <div className="col-lg">
                                    <label htmlFor="notification" className="font-weight-bold">
                                      Notification
                                    </label>
                                    <input
                                      type="text"
                                      name="notification"
                                      className="form-control form-control-sm"
                                      maxLength="12"
                                      placeholder="Notification"
                                      data-test="text-notification"
                                      onChange={(e) => {
                                        this.onChangeControlQuickCollectClientInfo(
                                          e
                                        );
                                      }}
                                      value={
                                        this.state.quickCollectClientInfo
                                          .notification
                                      }
                                      disabled={!this.props.location.state.adminQCCI}
                                    />
                                  </div>

                                  <div className="form-check form-check-inline col-lg">
                                    <input
                                      type="checkbox"
                                      name="remove"
                                      className="form-check-input remove-checkbox"
                                      data-test="text-remove"
                                      onChange={(e) => {
                                        this.onChangeControlCheckQuickCollectClientInfo(
                                          e
                                        );
                                      }}
                                      checked={
                                        this.state.quickCollectClientInfo
                                          .remove === "true"
                                      }
                                      value={
                                        this.state.quickCollectClientInfo.remove
                                      }
                                      disabled={!this.props.location.state.adminQCCI}
                                    />
                                    <label
                                      className="form-check-label remove-checkbox-label font-weight-bold"
                                      htmlFor="remove"
                                    >
                                      Remove
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top20 ">
                    <div className="card-header bg-dark text-white card-header-custom">
                      TIERED RATING FLAGS AND TABLES
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="creditCard" className="font-weight-bold">Credit Card</label>
                            <select
                              className="form-control  form-control-sm mar-bottom20"
                              name="creditCard"
                              data-test="text-creditCard"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={
                                this.state.quickCollectClientInfo.creditCard
                              }
                              disabled={!this.props.location.state.adminQCCI}
                            >
                              {this.getCreditCardQCCI()}
                            </select>
                          </div>
                          <div className="col-lg">
                            <label htmlFor="ccUsaCanRateTable" className="font-weight-bold">
                              CC/USA/CAN Rate Table
                            </label>
                            <input
                              type="text"
                              maxLength="1"
                              name="ccUsaCanRateTable"
                              className="form-control form-control-sm"
                              placeholder="CC/USA/CAN Rate Table"
                              data-test="text-ccUsaCanRateTable"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={
                                this.state.quickCollectClientInfo
                                  .ccUsaCanRateTable
                              }
                              disabled={!this.props.location.state.adminQCCI}
                            />
                          </div>

                          <div className="col-lg">
                            <label htmlFor="intRate" className="font-weight-bold"> Int Rate</label>
                            <input
                              type="text"
                              name="intRate"
                              maxLength="1"
                              className="form-control form-control-sm"
                              placeholder="Int Rate"
                              data-test="text-intRate"
                              onChange={(e) => {
                                this.onChangeControlQuickCollectClientInfo(e);
                              }}
                              value={this.state.quickCollectClientInfo.intRate}
                              disabled={!this.props.location.state.adminQCCI}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="card mar-top20 ">
                    <div className="card-header bg-dark text-white card-header-custom">
                      DATABASE UPDATES
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group row">
                          <div className="col-lg">
                            <div className="form-group row">
                              <div className="col-lg">
                                <label htmlFor="usaRateType" className="font-weight-bold">
                                  Database List
                                </label>
                              </div>
                              <div className="col-lg">
                                <label htmlFor="usaSenderRate" className="font-weight-bold">Status</label>
                              </div>
                              <div className="col-lg">
                                <label htmlFor="usaQCCRate" className="font-weight-bold">Last Update</label>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-lg">
                                <label htmlFor="usaRateType" className="font-weight-bold">USA</label>
                              </div>
                              <div className="col-lg">
                                <select
                                  className="form-control  form-control-sm mar-bottom20"
                                  name="usaStatus"
                                  data-test="text-usaStatus"
                                  value={
                                    this.state.quickCollectClientInfo.usaStatus
                                  }
                                  onChange={(e) => {
                                    this.onChangeControlOldQuickCollectClientInfo(
                                      e,
                                      this.state.quickCollectClientInfo
                                        .usaStatus,
                                      "usaStatusOld"
                                    );
                                  }}
                                  disabled={!this.props.location.state.adminQCCI}
                                >
                                  {this.getStatusQCCI("key1")}
                                </select>
                              </div>
                              <div className="col-lg">
                                <input
                                  type="text"
                                  name="usaLastUpdate"
                                  className="form-control form-control-sm"
                                  disabled
                                  data-test="text-usaLastUpdate"
                                  value={
                                    this.state.quickCollectClientInfo
                                      .usaLastUpdate
                                  }
                                />{" "}
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-lg">
                                <label htmlFor="canRateType" className="font-weight-bold">CAN</label>
                              </div>
                              <div className="col-lg">
                                <select
                                  className="form-control  form-control-sm mar-bottom20"
                                  name="canStatus"
                                  data-test="text-canStatus"
                                  // onChange={(e) => {
                                  //   this.onChangeControlQuickCollectClientInfo(
                                  //     e
                                  //   );
                                  // }}
                                  value={
                                    this.state.quickCollectClientInfo.canStatus
                                  }
                                  disabled={!this.props.location.state.adminQCCI}
                                  onChange={(e) => {
                                    this.onChangeControlOldQuickCollectClientInfo(
                                      e,
                                      this.state.quickCollectClientInfo
                                        .canStatus,
                                      "canStatusOld"
                                    );
                                  }}
                                >
                                  {this.getStatusQCCI("key2")}
                                </select>
                              </div>
                              <div className="col-lg">
                                <input
                                  type="text"
                                  name="canLastUpdate"
                                  className="form-control form-control-sm"
                                  disabled
                                  data-test="text-canLastUpdate"
                                  value={
                                    this.state.quickCollectClientInfo
                                      .canLastUpdate
                                  }
                                />{" "}
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-lg">
                                <label htmlFor="intRateType" className="font-weight-bold">INT</label>
                              </div>
                              <div className="col-lg">
                                <select
                                  className="form-control  form-control-sm mar-bottom20"
                                  name="intStatus"
                                  data-test="text-intStatus"
                                  value={
                                    this.state.quickCollectClientInfo.intStatus
                                  }
                                  onChange={(e) => {
                                    this.onChangeControlOldQuickCollectClientInfo(
                                      e,
                                      this.state.quickCollectClientInfo
                                        .intStatus,
                                      "intStatusOld"
                                    );
                                  }}
                                  disabled={!this.props.location.state.adminQCCI}
                                >
                                  {this.getStatusQCCI("key3")}
                                </select>{" "}
                              </div>
                              <div className="col-lg">
                                <input
                                  type="text"
                                  name="intLastUpdate"
                                  className="form-control form-control-sm"
                                  disabled
                                  data-test="text-intLastUpdate"
                                  value={
                                    this.state.quickCollectClientInfo
                                      .intLastUpdate
                                  }
                                />{" "}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg">
                            <div className="form-group row">
                              <div className="col-lg">
                                <label className="font-weight-bold">Int QCC Pricing</label>
                                <input
                                  type="text"
                                  name="intQccPricing"
                                  maxLength="7"
                                  className="form-control form-control-sm"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  data-test="text-intQccPricing"
                                  value={
                                    this.state.quickCollectClientInfo
                                      .intQccPricing
                                  }
                                />
                              </div>
                              <div className="col-lg">
                                <label htmlFor="emailAddress" className="font-weight-bold">
                                  Email Address
                                </label>
                                <input
                                  type="text"
                                  name="emailAddress"
                                  maxLength="80"
                                  className="form-control form-control-sm"
                                  onChange={(e) => {
                                    this.onChangeControlQuickCollectClientInfo(
                                      e
                                    );
                                  }}
                                  data-test="text-emailAddress"
                                  value={
                                    this.state.quickCollectClientInfo
                                      .emailAddress
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg text-center mar-top5 mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-refresh"
                        onClick={() => {
                          this.onHandleRefreshQCCI();
                        }}
                        disabled={this.state.mode === "A"}
                      >
                        REFRESH
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-qccMenu"
                        onClick={() => {
                          this.onHandleBackQCCI();
                        }}
                      >
                        QCC MENU
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        onClick={() => this.onHandleBufferDataQCCI("prevQCCI")}
                        data-test="button-prev-client"
                        disabled={!this.state.enablePrevQCCI}
                      >
                        PREV CLIENT
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        onClick={() => this.onHandleBufferDataQCCI("nextQCCI")}
                        data-test="button-next-client"
                        disabled={!this.state.enableNextQCCI}
                      >
                        NEXT CLIENT
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-delivery"
                        onClick={() => {
                          this.onHandleDelivery();
                        }}
                        disabled={
                          this.state.mode === "A" ||
                          this.state.quickCollectClientInfo.delivery === ""
                        }
                      >
                        DELIVERY
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-notification"
                        onClick={() => {
                          this.onHandleNotification();
                        }}
                        disabled={
                          this.state.mode === "A" ||
                          this.state.quickCollectClientInfo.notification === ""
                        }
                      >
                        NOTIFICATION
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-update"
                        onClick={() => this.onHandleUpdateQCCI()}
                        disabled={!this.props.location.state.accessGrantWriteQCDM}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-irp"
                        disabled={
                          this.state.mode === "A" ||
                          !(this.state.usaInactive ==='A' ||
                          this.state.canInactive ==='A' ||
                          this.state.intInactive ==='A')
                        }
                        onClick={() => this.onHandleAgtIrpQCCI()}
                      >
                        IRP
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-fusion"
                        onClick={() => {
                          this.onHandleFusionQCCI();
                        }}
                        disabled={
                          this.state.mode === "A" ||
                          !(this.state.usaInactive ==='A' ||
                          this.state.canInactive ==='A' ||
                          this.state.intInactive ==='A')
                        }
                      >
                        FUSION
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-inactive"
                        onClick={() => {
                          this.setState({
                            showQuickCollectClientInfoModal: true,
                          });
                        }}
                        disabled={
                          this.state.mode === "A" ||
                          !(this.state.usaInactive ==='A' ||
                          this.state.canInactive ==='A' ||
                          this.state.intInactive ==='A') ||
                          !this.props.location.state.adminQCDM
                        }
                      >
                        INACTIVE
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 text-center">
                      {this.state.submitStatusQuickCollectClientInfo ===
                        "OK" && (
                        <div className="alert alert-success alert-padding notification">
                          {this.state.submitMsgQuickCollectClientInfo}
                        </div>
                      )}
                      {this.state.submitStatusQuickCollectClientInfo ===
                        "Error" && (
                        <div className="alert alert-danger alert-padding notification">
                          {this.state.submitMsgQuickCollectClientInfo}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {this.getQuickCollectClientInfoModal()}
            </div>
        </div>
      </>
    );
  }
}
