import React, { Component } from "react";
import Header from "../../header/header";
import axios from "axios";
import { getHeadersProduct } from "../../utility/utility";
//import { AiTwotoneInsurance } from "react-icons/ai";

export default class QuickCollectDeviceInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowQCDI: [],
      quickCollectDeviceInfo: {
        deviceId: "",
        deviceType: "E",
        sequenceNumber: "",
        varClass: "",
        date: "",
        databaseArea: "",
        noOfAcks: 0,
        noOfNaks: 0,
        awaitingDeliveryCount: 0,
        awaitingDeliveryDateTimeOfLastInsert: "",
        deliveryInprogressCount: "",
        deliveryInprogressDateTimeOfLastInsert: "",
        principal: 0,
        accountNum: "",
        companyName: "",
        address: "",
        contact: "",
        phoneNum: "",
        maxAmount: 0,
        multipleDelieryDay: "",
        trace: "",
        lastUpdate: "",
        operatorId: "",
        device1CheckFormat: 0,
        device1RouteType: "",
        device1StatusUPD: "",
        device1MaxDeliveryConnect: 0,
        device1CurrentOutstanding: 0,
        device1ConsecutiveNaks: 0,
        device1TotalAcks: 0,
        device1TotalNaks: 0,
        device1Route: "",
        device1TimeDateOfLastAck: "",
        device2CheckFormat: 0,
        device2RouteType: "",
        device2StatusUPD: "",
        device2MaxDeliveryConnect: 0,
        device2CurrentOutstanding: 0,
        device2ConsecutiveNaks: 0,
        device2TotalAcks: 0,
        device2TotalNaks: 0,
        device2Route: "",
        device2TimeDateOfLastAck: "",
        device3CheckFormat: 0,
        device3RouteType: "",
        device3StatusUPD: "",
        device3MaxDeliveryConnect: 0,
        device3CurrentOutstanding: 0,
        device3ConsecutiveNaks: 0,
        device3TotalAcks: 0,
        device3TotalNaks: 0,
        device3Route: "",
        device3TimeDateOfLastAck: "",
        timeZone: "",
        dstHonoured: "",
        sundayOpeningHour: 0,
        mondayOpeningHour: 0,
        tuesdayOpeningHour: 0,
        wednesdayOpeningHour: 0,
        thursdayOpeningHour: 0,
        fridayOpeningHour: 0,
        saturdayOpeningHour: 0,
        sundayclosingHour: 0,
        mondayclosingHour: 0,
        tuesdayclosingHour: 0,
        wednesdayclosingHour: 0,
        thursdayclosingHour: 0,
        fridayclosingHour: 0,
        saturdayclosingHour: 0,
        clearTiming: "",
        ignorehours: "",
        resumeDate: "",
        deliveryDelayInMinutes: 0,
      },
      data: {
        deviceId: "",
        deviceType: "E",
        sequenceNumber: "",
        varClass: "",
        date: "",
        databaseArea: "",
        noOfAcks: 0,
        noOfNaks: 0,
        awaitingDeliveryCount: 0,
        awaitingDeliveryDateTimeOfLastInsert: "",
        deliveryInprogressCount: "",
        deliveryInprogressDateTimeOfLastInsert: "",
        principal: 0,
        accountNum: "",
        companyName: "",
        address: "",
        contact: "",
        phoneNum: "",
        maxAmount: 0,
        multipleDelieryDay: "",
        trace: "",
        lastUpdate: "",
        operatorId: "",
        device1CheckFormat: 0,
        device1RouteType: "",
        device1StatusUPD: "",
        device1MaxDeliveryConnect: 0,
        device1CurrentOutstanding: 0,
        device1ConsecutiveNaks: 0,
        device1TotalAcks: 0,
        device1TotalNaks: 0,
        device1Route: "",
        device1TimeDateOfLastAck: "",
        device2CheckFormat: 0,
        device2RouteType: "",
        device2StatusUPD: "",
        device2MaxDeliveryConnect: 0,
        device2CurrentOutstanding: 0,
        device2ConsecutiveNaks: 0,
        device2TotalAcks: 0,
        device2TotalNaks: 0,
        device2Route: "",
        device2TimeDateOfLastAck: "",
        device3CheckFormat: 0,
        device3RouteType: "",
        device3StatusUPD: "",
        device3MaxDeliveryConnect: 0,
        device3CurrentOutstanding: 0,
        device3ConsecutiveNaks: 0,
        device3TotalAcks: 0,
        device3TotalNaks: 0,
        device3Route: "",
        device3TimeDateOfLastAck: "",
        timeZone: "",
        dstHonoured: "",
        sundayOpeningHour: 0,
        mondayOpeningHour: 0,
        tuesdayOpeningHour: 0,
        wednesdayOpeningHour: 0,
        thursdayOpeningHour: 0,
        fridayOpeningHour: 0,
        saturdayOpeningHour: 0,
        sundayclosingHour: 0,
        mondayclosingHour: 0,
        tuesdayclosingHour: 0,
        wednesdayclosingHour: 0,
        thursdayclosingHour: 0,
        fridayclosingHour: 0,
        saturdayclosingHour: 0,
        clearTiming: "",
        ignorehours: "",
        resumeDate: "",
        deliveryDelayInMinutes: 0,
      },
      submitStatusQuickCollectDeviceInfo: null,
      submitMsgQuickCollectDeviceInfo: "",
    };
  }

  componentDidMount() {
    if(this.props.location.state.data !== undefined){
      this.setState({
        Authorization:this.props.location.state.data.tokenQCDM,
        userId:this.props.location.state.data.operatorIdQCDM,
        accessGrantWriteQCDM:this.props.location.state.data.accessGrantWriteQCDM,
        adminQCDI:this.props.location.state.data.adminQCDI,
      })          
    }else{
      this.setState({
        Authorization:this.props.location.state.tokenQCDM,
        userId:this.props.location.state.operatorIdQCDM,
        accessGrantWriteQCDM:this.props.location.state.accessGrantWriteQCDM,
        adminQCDI:this.props.location.state.adminQCDI,
      })
    }
    this.setState({
      Authorization:
        this.props.location.state.data !== undefined
          ? this.props.location.state.data.tokenQCDM
          : this.props.location.state.tokenQCDM,
      userId:
        this.props.location.state.data !== undefined
          ? this.props.location.state.data.operatorIdQCDM
          : this.props.location.state.operatorIdQCDM,
    });
    this.onLoadQCDI();
  }
  onLoadQCDI =()=>{
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/qqcdeviceinfo/${this.props.location.state.deviceId}`,
        {
          headers: {
            ...getHeadersProduct().headers,
            agent:
              this.props.location.state.agent === ""
                ? "N"
                : this.props.location.state.agent,
            Authorization: this.props.location.state.data !== undefined
              ? this.props.location.state.data.tokenQCDM
              : this.props.location.state.tokenQCDM,
            userId: this.props.location.state.data !== undefined
              ? this.props.location.state.data.operatorIdQCDM
              : this.props.location.state.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (
          response.data.records !== null &&
          response.data.records.length > 0
        ) {
          this.setState({
            rowQCDI: response.data.records,
            quickCollectDeviceInfo: response.data.records[0],
          });

          if (response.data.records[0].daysOpenValues !== null) {
            this.setState((prevState) => {
              return {
                quickCollectDeviceInfo: {
                  ...prevState.quickCollectDeviceInfo,
                  daysOpenValues: response.data.records[0].daysOpenValues,
                  sundayMD:
                    response.data.records[0].daysOpenValues.includes("1") ||
                    response.data.records[0].daysOpenValues.includes(1)
                      ? 1
                      : 0,
                  mondayMD:
                    response.data.records[0].daysOpenValues.includes("2") ||
                    response.data.records[0].daysOpenValues.includes(2)
                      ? 1
                      : 0,
                  tuesdayMD:
                    response.data.records[0].daysOpenValues.includes("3") ||
                    response.data.records[0].daysOpenValues.includes(3)
                      ? 1
                      : 0,
                  wednesdayMD:
                    response.data.records[0].daysOpenValues.includes("4") ||
                    response.data.records[0].daysOpenValues.includes(4)
                      ? 1
                      : 0,
                  thursdayMD:
                    response.data.records[0].daysOpenValues.includes("5") ||
                    response.data.records[0].daysOpenValues.includes(5)
                      ? 1
                      : 0,
                  fridayMD:
                    response.data.records[0].daysOpenValues.includes("6") ||
                    response.data.records[0].daysOpenValues.includes(6)
                      ? 1
                      : 0,
                  saturdayMD:
                    response.data.records[0].daysOpenValues.includes("7") ||
                    response.data.records[0].daysOpenValues.includes(7)
                      ? 1
                      : 0,
                },
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                quickCollectDeviceInfo: {
                  ...prevState.quickCollectDeviceInfo,
                  daysOpenValues: [],
                },
              };
            });
          }
        } else {
          this.setState({
            rowQCDI: [],
            quickCollectDeviceInfo: [],
            flagNew: true,
          });
          this.setState((prevState) => {
            return {
              quickCollectDeviceInfo: {
                ...prevState.quickCollectDeviceInfo,
                maxAmount: "",
                device1CheckFormat: "",
                device1MaxDeliveryConnect: "",
                device2CheckFormat: "",
                device2MaxDeliveryConnect: "",
                device3CheckFormat: "",
                device3MaxDeliveryConnect: "",
                device4CheckFormat: "",
                device4MaxDeliveryConnect: "",
                sundayOpeningHour: "",
                sundayclosingHour: "",
                mondayOpeningHour: "",
                mondayclosingHour: "",
                tuesdayOpeningHour: "",
                tuesdayclosingHour: "",
                wednesdayOpeningHour: "",
                wednesdayclosingHour: "",
                thursdayOpeningHour: "",
                thursdayclosingHour: "",
                fridayOpeningHour: "",
                fridayclosingHour: "",
                saturdayOpeningHour: "",
                saturdayclosingHour: "",
                deliveryDelayInMinutes: "",
                deviceId: this.props.location.state.deviceId,
              },
            };
          });
        }
      });
  }

  onChangeControlQuickDeviceClientInfo = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        quickCollectDeviceInfo: {
          ...prevState.quickCollectDeviceInfo,
          [name]: value,
        },
      };
    });
  };

  onChangeControlCheckedQuickDeviceClientInfo = (e) => {
    const { name, value } = e.target;
    if (value === 1 || value === "1") {
      this.setState((prevState) => {
        return {
          quickCollectDeviceInfo: {
            ...prevState.quickCollectDeviceInfo,
            [name]: 0,
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          quickCollectDeviceInfo: {
            ...prevState.quickCollectDeviceInfo,
            [name]: 1,
          },
        };
      });
    }
  };

  onValidateDeleteQuickCollectDeviceInfo = () => {    
    if (
      (this.state.quickCollectDeviceInfo.awaitingDeliveryCount !== "" &&
        this.state.quickCollectDeviceInfo.awaitingDeliveryCount > 0) ||
      (this.state.quickCollectDeviceInfo.deliveryInprogressCount !== "" &&
        this.state.quickCollectDeviceInfo.deliveryInprogressCount > 0)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Queue Not Empty For This Device",
      });
      return false;
    }
    return true;
  };

  onValidateQuickCollectDeviceInfo = () => {
    if (this.state.quickCollectDeviceInfo.varClass === "" || this.state.quickCollectDeviceInfo.varClass === undefined) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Invalid Class Entered",
      });
      return false;
    }
    if (
      (this.state.quickCollectDeviceInfo.deviceType === "2" ||
        this.state.quickCollectDeviceInfo.deviceType === "5") &&
      this.state.quickCollectDeviceInfo.accountNum === ""
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Missing Account Number",
      });
      return false;
    }
    if (
      (this.state.quickCollectDeviceInfo.deviceType === "0" ||
        this.state.quickCollectDeviceInfo.deviceType === "1" ||
        this.state.quickCollectDeviceInfo.deviceType === "7") &&
      this.state.quickCollectDeviceInfo.multipleDelieryDay === "1"
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Multiple Delivery For Electronic Device Only",
      });
      return false;
    }

    if (
      this.state.quickCollectDeviceInfo.deviceType === "4" &&
      this.state.quickCollectDeviceInfo.varClass !== "BROWSER"
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Non 'Browser' Device Class Cannot Have Device Type Selected As A ",
      });
      return false;
    }
    if (
      (this.state.quickCollectDeviceInfo.databaseArea !== "" ||
        this.state.quickCollectDeviceInfo.databaseArea > 4) &&
      /^\d+$/.test(this.state.quickCollectDeviceInfo.databaseArea) === false
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Device Area Must Be 1 Thru 4",
      });
      return false;
    }
    if (
      ((this.state.quickCollectDeviceInfo.maxAmount !== "" &&
        /^\d+(\.\d+)?$/.test(this.state.quickCollectDeviceInfo.maxAmount) ===
          false) ||
      this.state.quickCollectDeviceInfo.maxAmount > 9999999.99) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Max Amount Is Invalid ",
      });
      return false;
    }
    if (
      (this.state.quickCollectDeviceInfo.device1CheckFormat !== "" &&
      /^\d+$/.test(this.state.quickCollectDeviceInfo.device1CheckFormat) ===
        false) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Device1 Check Format Is Invalid",
      });
      return false;
    }
    if (
      (this.state.quickCollectDeviceInfo.device1MaxDeliveryConnect !== "" &&
      /^\d+$/.test(
        this.state.quickCollectDeviceInfo.device1MaxDeliveryConnect
      ) === false) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Device1 Max # Delivery/Connect Is Invalid",
      });
      return false;
    }

    if (
      (this.state.quickCollectDeviceInfo.device2CheckFormat !== "" &&
      /^\d+$/.test(this.state.quickCollectDeviceInfo.device2CheckFormat) ===
        false) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Device2 Check Format Is Invalid",
      });
      return false;
    }
    if (
      (this.state.quickCollectDeviceInfo.device2MaxDeliveryConnect !== "" &&
      /^\d+$/.test(
        this.state.quickCollectDeviceInfo.device2MaxDeliveryConnect
      ) === false) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Device2 Max # Delivery/Connect Is Invalid",
      });
      return false;
    }

    if (
      (this.state.quickCollectDeviceInfo.device3CheckFormat !== "" &&
      /^\d+$/.test(this.state.quickCollectDeviceInfo.device3CheckFormat) ===
        false) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Device3 Check Format Is Invalid",
      });
      return false;
    }
    if (
      (this.state.quickCollectDeviceInfo.device3MaxDeliveryConnect !== "" &&
      /^\d+$/.test(
        this.state.quickCollectDeviceInfo.device3MaxDeliveryConnect
      ) === false) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Device3 Max # Delivery/Connect Is Invalid",
      });
      return false;
    }

    if (
     ( this.state.quickCollectDeviceInfo.device4CheckFormat !== "" &&
      /^\d+$/.test(this.state.quickCollectDeviceInfo.device4CheckFormat) ===
        false) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Device4 Check Format Is Invalid",
      });
      return false;
    }
    if (
      (this.state.quickCollectDeviceInfo.device4MaxDeliveryConnect !== "" &&
      /^\d+$/.test(
        this.state.quickCollectDeviceInfo.device4MaxDeliveryConnect
      ) === false) 
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Device4 Max # Delivery/Connect Is Invalid",
      });
      return false;
    }
    var countStatus = 0;
    if (this.state.quickCollectDeviceInfo.device1StatusUPD === 1) {
      countStatus++;
    }
    if (this.state.quickCollectDeviceInfo.device2StatusUPD === 1) {
      countStatus++;
    }
    if (this.state.quickCollectDeviceInfo.device3StatusUPD === 1) {
      countStatus++;
    }
    if (this.state.quickCollectDeviceInfo.device4StatusUPD === 1) {
      countStatus++;
    }
    if (
      countStatus > 1 &&
      (this.state.quickCollectDeviceInfo.deviceType === "0" ||
        this.state.quickCollectDeviceInfo.deviceType === "2" ||
        this.state.quickCollectDeviceInfo.deviceType === "3" ||
        this.state.quickCollectDeviceInfo.deviceType === "4" ||
        this.state.quickCollectDeviceInfo.deviceType === "5" ||
        this.state.quickCollectDeviceInfo.deviceType === "6" ||
        this.state.quickCollectDeviceInfo.deviceType === "9" ||
        this.state.quickCollectDeviceInfo.deviceType === "10" ||
        this.state.quickCollectDeviceInfo.deviceType === "11")
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Only One Device Allowed",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.sundayOpeningHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.sundayOpeningHour) ===
        false ||
        this.state.quickCollectDeviceInfo.sundayOpeningHour > 2400 ||
        this.state.quickCollectDeviceInfo.sundayOpeningHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Sunday Open Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.sundayclosingHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.sundayclosingHour) ===
        false ||
        this.state.quickCollectDeviceInfo.sundayclosingHour > 2400 ||
        this.state.quickCollectDeviceInfo.sundayclosingHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Sunday Close Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.sundayclosingHour <
      this.state.quickCollectDeviceInfo.sundayOpeningHour
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Sunday Close Hour Cannot Be Less Than Open Hour Invalid",
      });
      return false;
    }

    if (
      this.state.quickCollectDeviceInfo.mondayOpeningHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.mondayOpeningHour) ===
        false ||
        this.state.quickCollectDeviceInfo.mondayOpeningHour > 2400 ||
        this.state.quickCollectDeviceInfo.mondayOpeningHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Monday Open Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.mondayclosingHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.mondayclosingHour) ===
        false ||
        this.state.quickCollectDeviceInfo.mondayclosingHour > 2400 ||
        this.state.quickCollectDeviceInfo.mondayclosingHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Monday Close Hour Is Invalid",
      });
      return false;
    }

    if (
      this.state.quickCollectDeviceInfo.mondayclosingHour <
      this.state.quickCollectDeviceInfo.mondayOpeningHour
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Monday Close Hour Cannot Be Less Than Open Hour Invalid",
      });
      return false;
    }

    if (
      this.state.quickCollectDeviceInfo.tuesdayOpeningHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.tuesdayOpeningHour) ===
        false ||
        this.state.quickCollectDeviceInfo.tuesdayOpeningHour > 2400 ||
        this.state.quickCollectDeviceInfo.tuesdayOpeningHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Tuesday Open Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.tuesdayclosingHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.tuesdayclosingHour) ===
        false ||
        this.state.quickCollectDeviceInfo.tuesdayclosingHour > 2400 ||
        this.state.quickCollectDeviceInfo.tuesdayclosingHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Tuesday Close Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.tuesdayclosingHour <
      this.state.quickCollectDeviceInfo.tuesdayOpeningHour
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Tuesday Close Hour Cannot Be Less Than Open Hour Invalid",
      });
      return false;
    }

    if (
      this.state.quickCollectDeviceInfo.wednesdayOpeningHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.wednesdayOpeningHour) ===
        false ||
        this.state.quickCollectDeviceInfo.wednesdayOpeningHour > 2400 ||
        this.state.quickCollectDeviceInfo.wednesdayOpeningHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Wednesday Open Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.wednesdayclosingHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.wednesdayclosingHour) ===
        false ||
        this.state.quickCollectDeviceInfo.wednesdayclosingHour > 2400 ||
        this.state.quickCollectDeviceInfo.wednesdayclosingHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Wednesday Close Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.wednesdayclosingHour <
      this.state.quickCollectDeviceInfo.wednesdayOpeningHour
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Wednesday Close Hour Cannot Be Less Than Open Hour Invalid",
      });
      return false;
    }

    if (
      this.state.quickCollectDeviceInfo.thursdayOpeningHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.thursdayOpeningHour) ===
        false ||
        this.state.quickCollectDeviceInfo.thursdayOpeningHour > 2400 ||
        this.state.quickCollectDeviceInfo.thursdayOpeningHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Thursday Open Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.thursdayclosingHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.thursdayclosingHour) ===
        false ||
        this.state.quickCollectDeviceInfo.thursdayclosingHour > 2400 ||
        this.state.quickCollectDeviceInfo.thursdayclosingHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Thursday Close Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.thursdayclosingHour <
      this.state.quickCollectDeviceInfo.thursdayOpeningHour
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Thursday Close Hour Cannot Be Less Than Open Hour Invalid",
      });
      return false;
    }

    if (
      this.state.quickCollectDeviceInfo.fridayOpeningHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.fridayOpeningHour) ===
        false ||
        this.state.quickCollectDeviceInfo.fridayOpeningHour > 2400 ||
        this.state.quickCollectDeviceInfo.fridayOpeningHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Friday Open Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.fridayclosingHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.fridayclosingHour) ===
        false ||
        this.state.quickCollectDeviceInfo.fridayclosingHour > 2400 ||
        this.state.quickCollectDeviceInfo.fridayclosingHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Friday Close Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.fridayclosingHour <
      this.state.quickCollectDeviceInfo.fridayOpeningHour
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Friday Close Hour Cannot Be Less Than Open Hour Invalid",
      });
      return false;
    }

    if (
      this.state.quickCollectDeviceInfo.saturdayOpeningHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.saturdayOpeningHour) ===
        false ||
        this.state.quickCollectDeviceInfo.saturdayOpeningHour > 2400 ||
        this.state.quickCollectDeviceInfo.saturdayOpeningHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Saturday Open Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.saturdayclosingHour !== "" &&
      (/^\d+$/.test(this.state.quickCollectDeviceInfo.saturdayclosingHour) ===
        false ||
        this.state.quickCollectDeviceInfo.saturdayclosingHour > 2400 ||
        this.state.quickCollectDeviceInfo.saturdayclosingHour % 100 > 59)
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Saturday Close Hour Is Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.saturdayclosingHour <
      this.state.quickCollectDeviceInfo.saturdayOpeningHour
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Saturday Close Hour Cannot Be Less Than Open Hour Invalid",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.multipleDelieryDay === 1 &&
      this.state.quickCollectDeviceInfo.sundayMD === 0 &&
      this.state.quickCollectDeviceInfo.mondayMD === 0 &&
      this.state.quickCollectDeviceInfo.tuesdayMD === 0 &&
      this.state.quickCollectDeviceInfo.wednesdayMD === 0 &&
      this.state.quickCollectDeviceInfo.thursdayMD === 0 &&
      this.state.quickCollectDeviceInfo.fridayMD === 0 &&
      this.state.quickCollectDeviceInfo.saturdayMD === 0
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Please Select Atleast One Of The Days Of Operation",
      });
      return false;
    }
    if (
      this.state.quickCollectDeviceInfo.deliveryDelayInMinutes !== "" &&
      /^\d+$/.test(this.state.quickCollectDeviceInfo.deliveryDelayInMinutes) ===
        false
    ) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo: "Delivery Time Delay Is Invalid",
      });
      return false;
    }
    if (this.state.quickCollectDeviceInfo.deliveryDelayInMinutes > 1440) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Delivery Time Delay Should Be Less Than 1441",
      });
      return false;
    }
    if (this.state.quickCollectDeviceInfo.resumeDate > 1440) {
      this.setState({
        submitStatusQuickCollectDeviceInfo: "Error",
        submitMsgQuickCollectDeviceInfo:
          "Delivery Time Delay Should Be Less Than 1441",
      });
      return false;
    }
    return true;
  };

  onHandleRefreshQCDI = () => {
    if (this.state.rowQCDI[0]) {
      this.setState({
        quickCollectDeviceInfo: this.state.rowQCDI[0],
      });
    } else {
      this.setState({
        quickCollectDeviceInfo: this.state.data,
      });
    }
  };
  onHandleBackQCDI = () => {
    this.props.history.push("/quick-collect-database-maintenance");
  };

  onHandleDeleteQCDI = () => {
    if (!this.onValidateDeleteQuickCollectDeviceInfo()) {
      return;
    }
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/qqcdeviceinfo/${this.state.quickCollectDeviceInfo.deviceId}`,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization: this.props.location.state.data !== undefined
              ? this.props.location.state.data.tokenQCDM
              : this.props.location.state.tokenQCDM,
            userId: this.props.location.state.data !== undefined
              ? this.props.location.state.data.operatorIdQCDM
              : this.props.location.state.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if(response.data.responseCode === 200 || response.data.responseCode === '200'){
            this.setState({
              submitStatusQuickCollectDeviceInfo: "OK",
              submitMsgQuickCollectDeviceInfo: response.data.responseString,
              enableBtn: false,
            });
          }else{
            this.setState({
              submitStatusQuickCollectDeviceInfo: "Error",
              submitMsgQuickCollectDeviceInfo: response.data.responseString,              
            });
          }
          
        } else {
          this.setState({
            submitStatusQuickCollectDeviceInfo: "Error",
            submitMsgQuickCollectDeviceInfo: response.data.responseString,
          });
        }
      });
  };

  onHandleUpdateQCDI = () => {
    if (!this.onValidateQuickCollectDeviceInfo()) {
      return;
    }
    let arrQCDI = [];
    if (this.state.quickCollectDeviceInfo.sundayMD === 1) {
      arrQCDI.push(1);
    }
    if (this.state.quickCollectDeviceInfo.mondayMD === 1) {
      arrQCDI.push(2);
    }
    if (this.state.quickCollectDeviceInfo.tuesdayMD === 1) {
      arrQCDI.push(3);
    }
    if (this.state.quickCollectDeviceInfo.wednesdayMD === 1) {
      arrQCDI.push(4);
    }
    if (this.state.quickCollectDeviceInfo.thursdayMD === 1) {
      arrQCDI.push(5);
    }
    if (this.state.quickCollectDeviceInfo.fridayMD === 1) {
      arrQCDI.push(6);
    }
    if (this.state.quickCollectDeviceInfo.saturdayMD === 1) {
      arrQCDI.push(7);
    }
    let submittedObjQCDI = {};
    submittedObjQCDI.deviceId = this.state.quickCollectDeviceInfo.deviceId;
    submittedObjQCDI.deviceType = this.state.quickCollectDeviceInfo.deviceType;
    submittedObjQCDI.sequenceNumber =
      this.state.quickCollectDeviceInfo.sequenceNumber;
    submittedObjQCDI.varClass = this.state.quickCollectDeviceInfo.varClass;
    submittedObjQCDI.date = this.state.quickCollectDeviceInfo.date;
    submittedObjQCDI.databaseArea =
      this.state.quickCollectDeviceInfo.databaseArea;
    submittedObjQCDI.noOfAcks = this.state.quickCollectDeviceInfo.noOfAcks;
    submittedObjQCDI.noOfNaks = this.state.quickCollectDeviceInfo.noOfNaks;
    submittedObjQCDI.awaitingDeliveryCount =
      this.state.quickCollectDeviceInfo.awaitingDeliveryCount;
    submittedObjQCDI.awaitingDeliveryDateTimeOfLastInsert =
      this.state.quickCollectDeviceInfo.awaitingDeliveryDateTimeOfLastInsert;
    submittedObjQCDI.deliveryInprogressCount =
      this.state.quickCollectDeviceInfo.deliveryInprogressCount;
    submittedObjQCDI.deliveryInprogressDateTimeOfLastInsert =
      this.state.quickCollectDeviceInfo.deliveryInprogressDateTimeOfLastInsert;
    submittedObjQCDI.principal = this.state.quickCollectDeviceInfo.principal;
    submittedObjQCDI.accountNum = this.state.quickCollectDeviceInfo.accountNum;
    submittedObjQCDI.companyName =
      this.state.quickCollectDeviceInfo.companyName;
    submittedObjQCDI.address1 = this.state.quickCollectDeviceInfo.address1;
    submittedObjQCDI.address2 = this.state.quickCollectDeviceInfo.address2;
    submittedObjQCDI.city = this.state.quickCollectDeviceInfo.city;
    submittedObjQCDI.state = this.state.quickCollectDeviceInfo.state;
    submittedObjQCDI.zip = this.state.quickCollectDeviceInfo.zip;
    submittedObjQCDI.country = this.state.quickCollectDeviceInfo.country;
    submittedObjQCDI.contact = this.state.quickCollectDeviceInfo.contact;
    submittedObjQCDI.phoneNum = this.state.quickCollectDeviceInfo.phoneNum;
    submittedObjQCDI.maxAmount = this.state.quickCollectDeviceInfo.maxAmount;
    submittedObjQCDI.multipleDelieryDay =
      this.state.quickCollectDeviceInfo.multipleDelieryDay;
    submittedObjQCDI.trace = this.state.quickCollectDeviceInfo.trace;
    submittedObjQCDI.lastUpdate = this.state.quickCollectDeviceInfo.lastUpdate;
    submittedObjQCDI.operatorId = this.state.quickCollectDeviceInfo.operatorId;
    submittedObjQCDI.device1CheckFormat =
      this.state.quickCollectDeviceInfo.device1CheckFormat;
    submittedObjQCDI.device1RouteType =
      this.state.quickCollectDeviceInfo.device1RouteType;
    submittedObjQCDI.device1StatusUPD =
      this.state.quickCollectDeviceInfo.device1StatusUPD;
    submittedObjQCDI.device1MaxDeliveryConnect =
      this.state.quickCollectDeviceInfo.device1MaxDeliveryConnect;
    submittedObjQCDI.device1CurrentOutstanding =
      this.state.quickCollectDeviceInfo.device1CurrentOutstanding;
    submittedObjQCDI.device1ConsecutiveNaks =
      this.state.quickCollectDeviceInfo.device1ConsecutiveNaks;
    submittedObjQCDI.device1TotalAcks =
      this.state.quickCollectDeviceInfo.device1TotalAcks;
    submittedObjQCDI.device1TotalNaks =
      this.state.quickCollectDeviceInfo.device1TotalNaks;
    submittedObjQCDI.device1Route =
      this.state.quickCollectDeviceInfo.device1Route;
    submittedObjQCDI.device1TimeDateOfLastAck =
      this.state.quickCollectDeviceInfo.device1TimeDateOfLastAck;
    submittedObjQCDI.device2CheckFormat =
      this.state.quickCollectDeviceInfo.device2CheckFormat;
    submittedObjQCDI.device2RouteType =
      this.state.quickCollectDeviceInfo.device2RouteType;
    submittedObjQCDI.device2StatusUPD =
      this.state.quickCollectDeviceInfo.device2StatusUPD;
    submittedObjQCDI.device2MaxDeliveryConnect =
      this.state.quickCollectDeviceInfo.device2MaxDeliveryConnect;
    submittedObjQCDI.device2CurrentOutstanding =
      this.state.quickCollectDeviceInfo.device2CurrentOutstanding;
    submittedObjQCDI.device2ConsecutiveNaks =
      this.state.quickCollectDeviceInfo.device2ConsecutiveNaks;
    submittedObjQCDI.device2TotalAcks =
      this.state.quickCollectDeviceInfo.device2TotalAcks;
    submittedObjQCDI.device2TotalNaks =
      this.state.quickCollectDeviceInfo.device2TotalNaks;
    submittedObjQCDI.device2Route =
      this.state.quickCollectDeviceInfo.device2Route;
    submittedObjQCDI.device2TimeDateOfLastAck =
      this.state.quickCollectDeviceInfo.device2TimeDateOfLastAck;
    submittedObjQCDI.device3CheckFormat =
      this.state.quickCollectDeviceInfo.device3CheckFormat;
    submittedObjQCDI.device3RouteType =
      this.state.quickCollectDeviceInfo.device3RouteType;
    submittedObjQCDI.device3StatusUPD =
      this.state.quickCollectDeviceInfo.device3StatusUPD;
    submittedObjQCDI.device3MaxDeliveryConnect =
      this.state.quickCollectDeviceInfo.device3MaxDeliveryConnect;
    submittedObjQCDI.device3CurrentOutstanding =
      this.state.quickCollectDeviceInfo.device3CurrentOutstanding;
    submittedObjQCDI.device3ConsecutiveNaks =
      this.state.quickCollectDeviceInfo.device3ConsecutiveNaks;
    submittedObjQCDI.device3TotalAcks =
      this.state.quickCollectDeviceInfo.device3TotalAcks;
    submittedObjQCDI.device3TotalNaks =
      this.state.quickCollectDeviceInfo.device3TotalNaks;
    submittedObjQCDI.device3Route =
      this.state.quickCollectDeviceInfo.device3Route;
    submittedObjQCDI.device3TimeDateOfLastAck =
      this.state.quickCollectDeviceInfo.device3TimeDateOfLastAck;
    submittedObjQCDI.device4CheckFormat =
      this.state.quickCollectDeviceInfo.device4CheckFormat;
    submittedObjQCDI.device4RouteType =
      this.state.quickCollectDeviceInfo.device4RouteType;
    submittedObjQCDI.device4StatusUPD =
      this.state.quickCollectDeviceInfo.device4StatusUPD;
    submittedObjQCDI.device4MaxDeliveryConnect =
      this.state.quickCollectDeviceInfo.device4MaxDeliveryConnect;
    submittedObjQCDI.device4CurrentOutstanding =
      this.state.quickCollectDeviceInfo.device4CurrentOutstanding;
    submittedObjQCDI.device4ConsecutiveNaks =
      this.state.quickCollectDeviceInfo.device4ConsecutiveNaks;
    submittedObjQCDI.device4TotalAcks =
      this.state.quickCollectDeviceInfo.device4TotalAcks;
    submittedObjQCDI.device4TotalNaks =
      this.state.quickCollectDeviceInfo.device4TotalNaks;
    submittedObjQCDI.device4Route =
      this.state.quickCollectDeviceInfo.device4Route;
    submittedObjQCDI.device4TimeDateOfLastAck =
      this.state.quickCollectDeviceInfo.device4TimeDateOfLastAck;
    submittedObjQCDI.timeZone = this.state.quickCollectDeviceInfo.timeZone;
    submittedObjQCDI.dstHonoured =
      this.state.quickCollectDeviceInfo.dstHonoured;
    submittedObjQCDI.sundayOpeningHour =
      this.state.quickCollectDeviceInfo.sundayOpeningHour;
    submittedObjQCDI.mondayOpeningHour =
      this.state.quickCollectDeviceInfo.mondayOpeningHour;
    submittedObjQCDI.tuesdayOpeningHour =
      this.state.quickCollectDeviceInfo.tuesdayOpeningHour;
    submittedObjQCDI.wednesdayOpeningHour =
      this.state.quickCollectDeviceInfo.wednesdayOpeningHour;
    submittedObjQCDI.thursdayOpeningHour =
      this.state.quickCollectDeviceInfo.thursdayOpeningHour;
    submittedObjQCDI.fridayOpeningHour =
      this.state.quickCollectDeviceInfo.fridayOpeningHour;
    submittedObjQCDI.saturdayOpeningHour =
      this.state.quickCollectDeviceInfo.saturdayOpeningHour;
    submittedObjQCDI.sundayclosingHour =
      this.state.quickCollectDeviceInfo.sundayclosingHour;
    submittedObjQCDI.mondayclosingHour =
      this.state.quickCollectDeviceInfo.mondayclosingHour;
    submittedObjQCDI.tuesdayclosingHour =
      this.state.quickCollectDeviceInfo.tuesdayclosingHour;
    submittedObjQCDI.wednesdayclosingHour =
      this.state.quickCollectDeviceInfo.wednesdayclosingHour;
    submittedObjQCDI.thursdayclosingHour =
      this.state.quickCollectDeviceInfo.thursdayclosingHour;
    submittedObjQCDI.fridayclosingHour =
      this.state.quickCollectDeviceInfo.fridayclosingHour;
    submittedObjQCDI.saturdayclosingHour =
      this.state.quickCollectDeviceInfo.saturdayclosingHour;
    submittedObjQCDI.clearTiming =
      this.state.quickCollectDeviceInfo.clearTiming;
    submittedObjQCDI.ignorehours =
      this.state.quickCollectDeviceInfo.ignorehours;
    submittedObjQCDI.resumeDate = this.state.quickCollectDeviceInfo.resumeDate;
    submittedObjQCDI.deliveryDelayInMinutes =
      this.state.quickCollectDeviceInfo.deliveryDelayInMinutes;
    submittedObjQCDI.daysOpenValues = arrQCDI;

    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/qqcdeviceinfo`,
        submittedObjQCDI,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization: this.props.location.state.data !== undefined
              ? this.props.location.state.data.tokenQCDM
              : this.props.location.state.tokenQCDM,
            userId: this.props.location.state.data !== undefined
              ? this.props.location.state.data.operatorIdQCDM
              : this.props.location.state.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            submitStatusQuickCollectDeviceInfo: "OK",
            submitMsgQuickCollectDeviceInfo: response.data.responseString,
            enableBtn: false,
          });
          this.onLoadQCDI();
        } else {
          this.setState({
            submitStatusQuickCollectDeviceInfo: "Error",
            submitMsgQuickCollectDeviceInfo: response.data.responseString,
          });
        }
      });
  };
  getTimeZoneQCDI = () => {
    let valOfTT = [
      { key: "EST", value: "7" },
      { key: "ALT", value: "8" },
      { key: "CST", value: "6" },
      { key: "MST", value: "5" },
      { key: "PST", value: "4" },
      { key: "YST", value: "3" },
      { key: "AHT", value: "2" },
    ];
    let resultValOfTT = valOfTT.map((item) => (
      <option key={[item.value]} value={item.value}>
        {item.key}
      </option>
    ));
    return resultValOfTT;
  };
  getDeviceTypeQCDI = () => {
    let valOfDT = [
      { key: "O", value: "0" },
      { key: "E", value: "1" },
      { key: "C", value: "2" },
      { key: "N", value: "3" },
      { key: "A", value: "4" },
      { key: "G", value: "5" },
      { key: "I", value: "6" },
      { key: "M", value: "7" },
      { key: "S", value: "8" },
      { key: "Y", value: "9" },
      { key: "F", value: "10" },
      { key: "Z", value: "11" },
    ];
    let resultValOfDT = valOfDT.map((item) => (
      <option key={[item.value]} value={item.value}>
        {item.key}
      </option>
    ));
    return resultValOfDT;
  };
  render() {
    return (
      <>
        <Header history={this.props.history} heading="QUICK COLLECT UPDATE" />
        <div className="container-fluid">
          <div className="card-body">
              <h4 className="card-title text-center main-heading">
                Quick Collect Device Information
              </h4>
              <div className="row ">
                <div className="col-lg">
                  <div className="card ">
                    <div className="card-header bg-dark text-white card-header-custom">
                      DEVICE DETAILS
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="deviceId"
                              className="font-weight-bold"
                            >
                              Device Id
                            </label>
                            <input
                              type="text"
                              name="deviceId"
                              className="form-control form-control-sm"
                              placeholder="Device Id"
                              data-test="text-deviceId"
                              value={this.props.location.state.deviceId}
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="deviceType"
                              className="font-weight-bold"
                            >
                              Device Type
                            </label>
                            <select
                              className="form-control  form-control-sm mar-bottom20"
                              name="deviceType"
                              data-test="text-deviceType"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              value={
                                this.state.quickCollectDeviceInfo.deviceType
                              }
                            >
                              {this.getDeviceTypeQCDI()}
                            </select>
                          </div>

                          <div className="col-lg">
                            <label
                              htmlFor="sequenceNumber"
                              className="font-weight-bold"
                            >
                              Sequence Number
                            </label>
                            <input
                              type="text"
                              name="sequenceNumber"
                              className="form-control form-control-sm"
                              placeholder="Sequence Number"
                              data-test="text-sequenceNumber"
                              maxLength="9"
                              value={
                                this.state.quickCollectDeviceInfo.sequenceNumber
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              disabled
                            />
                          </div>

                          <div className="col-lg">
                            <label
                              htmlFor="varClass"
                              className="font-weight-bold"
                            >
                              Class
                            </label>
                            <input
                              type="text"
                              name="varClass"
                              maxLength="8"
                              className="form-control form-control-sm"
                              placeholder="Class"
                              data-test="text-varClass"
                              value={this.state.quickCollectDeviceInfo.varClass}
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="date" className="font-weight-bold">
                              Date
                            </label>
                            <input
                              type="text"
                              name="date"
                              className="form-control form-control-sm"
                              placeholder="Date"
                              data-test="text-date"
                              value={this.state.quickCollectDeviceInfo.date}
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="databaseArea"
                              className="font-weight-bold"
                            >
                              Database Area
                            </label>
                            <input
                              type="text"
                              name="databaseArea"
                              className="form-control form-control-sm"
                              placeholder="Database Area"
                              value={
                                this.state.quickCollectDeviceInfo.databaseArea
                              }
                              data-test="text-databaseArea"
                              maxLength="1"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              disabled={!this.state.flagNew}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-lg"></div>
                          <div className="col-lg"></div>
                          <div className="col-lg"></div>
                          <div className="col-lg">
                            <label htmlFor="count" className="font-weight-bold">
                              Count
                            </label>
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="date/time"
                              className="font-weight-bold"
                            >
                              Date/Time of Last Insert
                            </label>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="noOfAcks"
                              className="font-weight-bold"
                            >
                              No. Of Acks
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="noOfAcks"
                              value={this.state.quickCollectDeviceInfo.noOfAcks}
                              maxLength="8"
                              className="form-control form-control-sm"
                              data-test="text-noOfAcks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="awaitingDeliveryCount"
                              className="font-weight-bold"
                            >
                              Awaiting Delivery
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="awaitingDeliveryCount"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .awaitingDeliveryCount
                              }
                              maxLength="8"
                              className="form-control form-control-sm"
                              data-test="text-awaitingDeliveryCount"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="awaitingDeliveryDateTimeOfLastInsert"
                              maxLength="8"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .awaitingDeliveryDateTimeOfLastInsert
                              }
                              className="form-control form-control-sm"
                              data-test="text-awaitingDeliveryDateTimeOfLastInsert"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="noOfAcks"
                              className="font-weight-bold"
                            >
                              No. Of Naks
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="noOfNaks"
                              value={this.state.quickCollectDeviceInfo.noOfNaks}
                              maxLength="8"
                              className="form-control form-control-sm"
                              data-test="text-noOfNaks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="awaitingDeliveryCount"
                              className="font-weight-bold"
                            >
                              Delivery In Progress
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="deliveryInprogressCount"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .deliveryInprogressCount
                              }
                              maxLength="8"
                              className="form-control form-control-sm"
                              data-test="text-deliveryInprogressCount"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="deliveryInprogressDateTimeOfLastInsert"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .deliveryInprogressDateTimeOfLastInsert
                              }
                              maxLength="8"
                              className="form-control form-control-sm"
                              data-test="text-deliveryInprogressDateTimeOfLastInsert"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="accountNum"
                              className="font-weight-bold"
                            >
                              Account Num
                            </label>
                            <input
                              type="text"
                              maxLength="9"
                              name="accountNum"
                              value={
                                this.state.quickCollectDeviceInfo.accountNum
                              }
                              className="form-control form-control-sm"
                              placeholder="Account Num"
                              data-test="text-accountNum"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="principal"
                              className="font-weight-bold"
                            >
                              Principal
                            </label>
                            <input
                              type="text"
                              name="principal"
                              value={
                                this.state.quickCollectDeviceInfo.principal
                              }
                              maxLength="8"
                              className="form-control form-control-sm"
                              placeholder="Principal"
                              data-test="text-principal"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="companyName"
                              className="font-weight-bold"
                            >
                              {" "}
                              Company Name
                            </label>
                            <input
                              type="text"
                              name="companyName"
                              value={
                                this.state.quickCollectDeviceInfo.companyName
                              }
                              className="form-control form-control-sm"
                              placeholder="Company Name"
                              data-test="text-companyName"
                              maxLength="40"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="address1"
                              className="font-weight-bold"
                            >
                              Address1
                            </label>
                            <input
                              type="text"
                              name="address1"
                              value={this.state.quickCollectDeviceInfo.address1}
                              className="form-control form-control-sm"
                              placeholder="Address1"
                              data-test="text-address1"
                              maxLength="40"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="address2"
                              className="font-weight-bold"
                            >
                              Address2
                            </label>
                            <input
                              type="text"
                              name="address2"
                              value={this.state.quickCollectDeviceInfo.address2}
                              className="form-control form-control-sm"
                              placeholder="Address2"
                              data-test="text-address2"
                              maxLength="40"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="city" className="font-weight-bold">
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              value={this.state.quickCollectDeviceInfo.city}
                              className="form-control form-control-sm"
                              placeholder="City"
                              data-test="text-city"
                              maxLength="24"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="state" className="font-weight-bold">
                              State
                            </label>
                            <input
                              type="text"
                              name="state"
                              className="form-control form-control-sm"
                              value={this.state.quickCollectDeviceInfo.state}
                              placeholder="State"
                              data-test="text-state"
                              maxLength="2"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="zip" className="font-weight-bold">
                              Zip Code
                            </label>
                            <input
                              type="text"
                              name="zip"
                              className="form-control form-control-sm"
                              value={this.state.quickCollectDeviceInfo.zip}
                              placeholder="Zip Code"
                              data-test="text-zip"
                              maxLength="10"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="country"
                              className="font-weight-bold"
                            >
                              Country
                            </label>
                            <input
                              type="text"
                              name="country"
                              value={this.state.quickCollectDeviceInfo.country}
                              className="form-control form-control-sm"
                              placeholder="Country"
                              data-test="text-country"
                              maxLength="3"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>

                          <div className="col-lg">
                            <label
                              htmlFor="contact"
                              className="font-weight-bold"
                            >
                              Contact Name
                            </label>
                            <input
                              type="text"
                              name="contact"
                              value={this.state.quickCollectDeviceInfo.contact}
                              className="form-control form-control-sm"
                              placeholder="Contact Name"
                              data-test="text-contact"
                              maxLength="40"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="phoneNum"
                              className="font-weight-bold"
                            >
                              Phone Num
                            </label>
                            <input
                              type="text"
                              value={this.state.quickCollectDeviceInfo.phoneNum}
                              name="phoneNum"
                              className="form-control form-control-sm"
                              placeholder="Phone Num"
                              data-test="text-phoneNum"
                              maxLength="20"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>

                          <div className="col-lg">
                            <label
                              htmlFor="maxAmount"
                              className="font-weight-bold"
                            >
                              Max Amount
                            </label>
                            <input
                              type="text"
                              name="maxAmount"
                              className="form-control form-control-sm"
                              value={
                                this.state.quickCollectDeviceInfo.maxAmount
                              }
                              placeholder="Max Amount"
                              data-test="text-maxAmount"
                              maxLength="10"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="card mar-top20 ">
                    <div className="card-header bg-dark text-white card-header-custom">
                      TRACE DETAILS
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group row">
                          <div className="col-lg">
                            <div className="row ">
                              <div className="form-check form-check-inline col-lg ">
                                <input
                                  type="checkbox"
                                  name="multipleDelieryDay"
                                  className="form-check-input"
                                  data-test="text-multipleDelieryDay"
                                  onChange={(e) => {
                                    this.onChangeControlCheckedQuickDeviceClientInfo(
                                      e
                                    );
                                  }}
                                  checked={
                                    this.state.quickCollectDeviceInfo
                                      .multipleDelieryDay === 1
                                  }
                                  value={
                                    this.state.quickCollectDeviceInfo
                                      .multipleDelieryDay
                                  }
                                />
                                <label
                                  htmlFor="multipleDelieryDay"
                                  className="font-weight-bold mar-top10"
                                >
                                  Multiple Delivery/Day
                                </label>
                              </div>
                              <div className="form-check form-check-inline col-lg">
                                <input
                                  type="checkbox"
                                  name="trace"
                                  className="form-check-input"
                                  data-test="text-trace"
                                  checked={
                                    this.state.quickCollectDeviceInfo.trace ===
                                      1 ||
                                    this.state.quickCollectDeviceInfo.trace ===
                                      "1"
                                  }
                                  value={
                                    this.state.quickCollectDeviceInfo.trace
                                  }
                                  onChange={(e) => {
                                    this.onChangeControlCheckedQuickDeviceClientInfo(
                                      e
                                    );
                                  }}
                                />
                                <label
                                  htmlFor="trace"
                                  className="font-weight-bold mar-top10"
                                >
                                  Trace
                                </label>
                              </div>
                              <div className="col-lg">
                                <label
                                  htmlFor="lastUpdate"
                                  className="font-weight-bold"
                                >
                                  Last Update
                                </label>
                                <input
                                  type="text"
                                  name="lastUpdate"
                                  value={
                                    this.state.quickCollectDeviceInfo.lastUpdate
                                  }
                                  className="form-control form-control-sm"
                                  placeholder="Last Update"
                                  data-test="text-lastUpdate"
                                  disabled
                                />
                              </div>
                              <div className="col-lg">
                                <label
                                  htmlFor="operatorId"
                                  className="font-weight-bold"
                                >
                                  {" "}
                                  Operator Id
                                </label>
                                <input
                                  type="text"
                                  name="operatorId"
                                  value={
                                    this.state.quickCollectDeviceInfo.operatorId
                                  }
                                  className="form-control form-control-sm"
                                  placeholder="Operator Id"
                                  data-test="text-operatorId"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="card mar-top20 ">
                    <div className="card-header bg-dark text-white card-header-custom">
                      <div className="row">
                        <div className="col-lg"></div>
                        <div className="col-lg">Device1</div>
                        <div className="col-lg">Device2</div>
                        <div className="col-lg">Device3</div>
                        <div className="col-lg">Device4</div>          
                      </div>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="checkFormat"
                              className="font-weight-bold"
                            >
                              Check Format
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device1CheckFormat"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device1CheckFormat
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              maxLength="2"
                              className="form-control form-control-sm"
                              data-test="text-device1CheckFormat"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device2CheckFormat
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              name="device2CheckFormat"
                              className="form-control form-control-sm"
                              data-test="text-device2CheckFormat"
                            />
                          </div>

                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              name="device3CheckFormat"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device3CheckFormat
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              className="form-control form-control-sm"
                              data-test="text-device3CheckFormat"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              name="device4CheckFormat"
                              className="form-control form-control-sm"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4CheckFormat
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              data-test="text-device4CheckFormat"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="routeType"
                              className="font-weight-bold"
                            >
                              {" "}
                              Route Type
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="6"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device1RouteType
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              name="device1RouteType"
                              className="form-control form-control-sm"
                              data-test="text-device1RouteType"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="6"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              name="device2RouteType"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device2RouteType
                              }
                              className="form-control form-control-sm"
                              data-test="text-device2RouteType"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="6"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              name="device3RouteType"
                              className="form-control form-control-sm"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device3RouteType
                              }
                              data-test="text-device3RouteType"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="6"
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              name="device4RouteType"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4RouteType
                              }
                              className="form-control form-control-sm"
                              data-test="text-device4RouteType"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-lg">
                              <label
                                htmlFor="statusUPD"
                                className="font-weight-bold"
                              >
                                Status UPD
                              </label>{" "}
                            </div>
                            <div className="col-lg">
                              <input
                                type="checkbox"
                                name="device1StatusUPD"
                                onChange={(e) => {
                                  this.onChangeControlCheckedQuickDeviceClientInfo(
                                    e
                                  );
                                }}
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .device1StatusUPD
                                }
                                checked={
                                  this.state.quickCollectDeviceInfo
                                    .device1StatusUPD === 1 ||
                                  this.state.quickCollectDeviceInfo
                                    .device1StatusUPD === "1"
                                }
                                
                                data-test="text-device1StatusUPD"
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="checkbox"
                                name="device2StatusUPD"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .device2StatusUPD
                                }
                                checked={
                                  this.state.quickCollectDeviceInfo
                                    .device2StatusUPD === 1 ||
                                  this.state.quickCollectDeviceInfo
                                    .device2StatusUPD === "1"
                                }
                                onChange={(e) => {
                                  this.onChangeControlCheckedQuickDeviceClientInfo(
                                    e
                                  );
                                }}
                                
                                data-test="text-device2StatusUPD "
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="checkbox"
                                name="device3StatusUPD"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .device3StatusUPD
                                }
                                checked={
                                  this.state.quickCollectDeviceInfo
                                    .device3StatusUPD === 1 ||
                                  this.state.quickCollectDeviceInfo
                                    .device3StatusUPD === "1"
                                }
                                onChange={(e) => {
                                  this.onChangeControlCheckedQuickDeviceClientInfo(
                                    e
                                  );
                                }}
                               
                                data-test="text-device3StatusUPD "
                              />
                            </div>
                            <div className="col-lg">
                            <input
                              type="checkbox"
                              name="device4StatusUPD"
                              checked={
                                this.state.quickCollectDeviceInfo
                                  .device4StatusUPD === 1 ||
                                this.state.quickCollectDeviceInfo
                                  .device4StatusUPD === "1"
                              }
                              onChange={(e) => {
                                this.onChangeControlCheckedQuickDeviceClientInfo(
                                  e
                                );
                              }}
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4StatusUPD
                              }
                            
                              data-test="text-device4StatusUPD "
                            />
                          </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="maxDeliveryConnect"
                              className="font-weight-bold"
                            >
                              MAX # Delivery/Connect
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              name="device1MaxDeliveryConnect"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device1MaxDeliveryConnect
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              className="form-control form-control-sm"
                              data-test="text-device1MaxDeliveryConnect"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              name="device2MaxDeliveryConnect"
                              className="form-control form-control-sm"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device2MaxDeliveryConnect
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              data-test="text-device2MaxDeliveryConnect"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              name="device3MaxDeliveryConnect"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device3MaxDeliveryConnect
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              className="form-control form-control-sm"
                              data-test="text-device3MaxDeliveryConnect"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              name="device4MaxDeliveryConnect"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4MaxDeliveryConnect
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              className="form-control form-control-sm"
                              data-test="text-device4MaxDeliveryConnect"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="deviceCurrentOutstanding"
                              className="font-weight-bold"
                            >
                              Current Outstanding
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device1CurrentOutstanding
                              }
                              name="device1CurrentOutstanding"
                              className="form-control form-control-sm"
                              data-test="text-device1CurrentOutstanding"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device2CurrentOutstanding"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device2CurrentOutstanding
                              }
                              className="form-control form-control-sm"
                              data-test="text-device2CurrentOutstanding"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device3CurrentOutstanding"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device3CurrentOutstanding
                              }
                              className="form-control form-control-sm"
                              data-test="text-device3CurrentOutstanding"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device4CurrentOutstanding"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4CurrentOutstanding
                              }
                              className="form-control form-control-sm"
                              data-test="text-device4CurrentOutstanding"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="deviceCurrentOutstanding"
                              className="font-weight-bold"
                            >
                              # Consecutive NAKs
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device1ConsecutiveNaks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device1ConsecutiveNaks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device1ConsecutiveNaks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device2ConsecutiveNaks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device2ConsecutiveNaks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device2ConsecutiveNaks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device3ConsecutiveNaks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device3ConsecutiveNaks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device3ConsecutiveNaks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device4ConsecutiveNaks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4ConsecutiveNaks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device4ConsecutiveNaks"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="deviceCurrentOutstanding"
                              className="font-weight-bold"
                            >
                              Total # Acks
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device1TotalAcks
                              }
                              name="device1TotalAcks"
                              className="form-control form-control-sm"
                              data-test="text-device1TotalAcks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device2TotalAcks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device2TotalAcks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device2TotalAcks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device3TotalAcks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device3TotalAcks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device3TotalAcks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device4TotalAcks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4TotalAcks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device4TotalAcks"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="deviceCurrentOutstanding"
                              className="font-weight-bold"
                            >
                              Total # Naks
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device1TotalNaks"
                              className="form-control form-control-sm"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device1TotalNaks
                              }
                              data-test="text-device1TotalNaks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device2TotalNaks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device2TotalNaks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device2TotalNaks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device3TotalNaks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device3TotalNaks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device3TotalNaks"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device4TotalNaks"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4TotalNaks
                              }
                              className="form-control form-control-sm"
                              data-test="text-device4TotalNaks"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="routeType"
                              className="font-weight-bold"
                            >
                              {" "}
                              Route{" "}
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="20"
                              name="device1Route"
                              value={
                                this.state.quickCollectDeviceInfo.device1Route
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              className="form-control form-control-sm"
                              data-test="text-device1Route"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              maxLength="20"
                              type="text"
                              value={
                                this.state.quickCollectDeviceInfo.device2Route
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              name="device2Route"
                              className="form-control form-control-sm"
                              data-test="text-device2Route"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="20"
                              name="device3Route"
                              value={
                                this.state.quickCollectDeviceInfo.device3Route
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              className="form-control form-control-sm"
                              data-test="text-device3Route"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="20"
                              name="device4Route"
                              value={
                                this.state.quickCollectDeviceInfo.device4Route
                              }
                              onChange={(e) => {
                                this.onChangeControlQuickDeviceClientInfo(e);
                              }}
                              className="form-control form-control-sm"
                              data-test="text-device4Route"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="deviceCurrentOutstanding"
                              className="font-weight-bold"
                            >
                              Time/Date od Last Ack
                            </label>
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device1TimeDateOfLastAck"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device1TimeDateOfLastAck
                              }
                              className="form-control form-control-sm"
                              data-test="text-device1TimeDateOfLastAck"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device2TimeDateOfLastAck"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device2TimeDateOfLastAck
                              }
                              className="form-control form-control-sm"
                              data-test="text-device2TimeDateOfLastAck"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device3TimeDateOfLastAck"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device3TimeDateOfLastAck
                              }
                              className="form-control form-control-sm"
                              data-test="text-device3TimeDateOfLastAck"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              name="device4TimeDateOfLastAck"
                              value={
                                this.state.quickCollectDeviceInfo
                                  .device4TimeDateOfLastAck
                              }
                              className="form-control form-control-sm"
                              data-test="text-device4TimeDateOfLastAck"
                              disabled
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {this.state.quickCollectDeviceInfo.multipleDelieryDay !==
                  1 ? (
                    <div className="card mar-top20 ">
                      <div className="card-header bg-dark text-white card-header-custom">
                        HOURS OF OPERATION
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="form-group row">
                            <div className="col-lg">
                              <label
                                htmlFor="timeZone"
                                className="font-weight-bold"
                              >
                                Time Zone
                              </label>
                              <select
                                className="form-control  form-control-sm mar-bottom20"
                                name="timeZone"
                                data-test="text-timeZone"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                                value={
                                  this.state.quickCollectDeviceInfo.timeZone
                                }
                              >
                                {this.getTimeZoneQCDI()}
                              </select>
                            </div>
                            <div className="form-check form-check-inline col-lg ">
                              <input
                                type="checkbox"
                                name="dstHonoured"
                                className="form-check-input"
                                value={
                                  this.state.quickCollectDeviceInfo.dstHonoured
                                }
                                checked={
                                  this.state.quickCollectDeviceInfo
                                    .dstHonoured === 1
                                }
                                onChange={(e) => {
                                  this.onChangeControlCheckedQuickDeviceClientInfo(
                                    e
                                  );
                                }}
                                data-test="text-dstHonoured"
                              />
                              <label
                                htmlFor="dstHonoured"
                                className="font-weight-bold mar-top10"
                              >
                                DST Honoured
                              </label>
                            </div>
                            <div className="form-check form-check-inline col-lg ">
                              <input
                                type="checkbox"
                                name="clearTiming"
                                className="form-check-input"
                                data-test="text-clearTiming "
                                value={
                                  this.state.quickCollectDeviceInfo.clearTiming
                                }
                                checked={
                                  this.state.quickCollectDeviceInfo
                                    .clearTiming === 1
                                }
                                onChange={(e) => {
                                  this.onChangeControlCheckedQuickDeviceClientInfo(
                                    e
                                  );
                                }}
                              />
                              <label
                                htmlFor="clearTiming"
                                className="font-weight-bold mar-top10"
                              >
                                Clear Timing
                              </label>
                            </div>
                            <div className="form-check form-check-inline col-lg ">
                              <input
                                type="checkbox"
                                name="ignorehours"
                                className="form-check-input"
                                value={
                                  this.state.quickCollectDeviceInfo.ignorehours
                                }
                                checked={
                                  this.state.quickCollectDeviceInfo
                                    .ignorehours === 1
                                }
                                onChange={(e) => {
                                  this.onChangeControlCheckedQuickDeviceClientInfo(
                                    e
                                  );
                                }}
                                data-test="text-ignorehours "
                              />
                              <label
                                htmlFor="ignorehours"
                                className="font-weight-bold mar-top10"
                              >
                                Ignore Hours
                              </label>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="resumeDate"
                                className="font-weight-bold"
                              >
                                ResumeDate
                              </label>
                              <br />
                              <input
                                type="date"
                                value={
                                  this.state.quickCollectDeviceInfo.resumeDate
                                }
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                                className="form-control form-control-sm"
                                name="resumeDate"
                                data-test="text-resumeDate"
                              ></input>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="deliveryDelayInMinutes"
                                className="font-weight-bold"
                              >
                                Delivery Time Delay (In Mins)
                              </label>
                              <input
                                type="text"
                                name="deliveryDelayInMinutes"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .deliveryDelayInMinutes
                                }
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                                className="form-control form-control-sm"
                                placeholder="Delivery Time Delay (In Mins)"
                                data-test="text-deliveryDelayInMinutes"
                              />
                            </div>
                          </div>
                          <div className="form-group row text-center">
                            <div className="col-lg">
                              <label
                                htmlFor="days"
                                className="font-weight-bold"
                              >
                                Days{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="sunday"
                                className="font-weight-bold"
                              >
                                Sunday{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="monday"
                                className="font-weight-bold"
                              >
                                Monday{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="tuesday"
                                className="font-weight-bold"
                              >
                                Tuesday{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="wednesday"
                                className="font-weight-bold"
                              >
                                Wednesday{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="thursday"
                                className="font-weight-bold"
                              >
                                Thursday{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="friday"
                                className="font-weight-bold"
                              >
                                Friday{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="saturday"
                                className="font-weight-bold"
                              >
                                Saturday{" "}
                              </label>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg">
                              <label
                                htmlFor="openingHour"
                                className="font-weight-bold"
                              >
                                Opening Hour{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="sundayOpeningHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .sundayOpeningHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-sundayOpeningHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .mondayOpeningHour
                                }
                                name="mondayOpeningHour"
                                className="form-control form-control-sm"
                                data-test="text-mondayOpeningHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="tuesdayOpeningHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .tuesdayOpeningHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-tuesdayOpeningHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="wednesdayOpeningHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .wednesdayOpeningHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-wednesdayOpeningHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="thursdayOpeningHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .thursdayOpeningHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-thursdayOpeningHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="fridayOpeningHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .fridayOpeningHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-fridayOpeningHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="saturdayOpeningHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .saturdayOpeningHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-saturdayOpeningHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg">
                              <label
                                htmlFor="closingHour"
                                className="font-weight-bold"
                              >
                                Closing Hour{" "}
                              </label>
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="sundayclosingHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .sundayclosingHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-sundayclosingHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .mondayclosingHour
                                }
                                name="mondayclosingHour"
                                className="form-control form-control-sm"
                                data-test="text-mondayclosingHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="tuesdayclosingHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .tuesdayclosingHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-tuesdayclosingHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="wednesdayclosingHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .wednesdayclosingHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-wednesdayclosingHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="thursdayclosingHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .thursdayclosingHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-thursdayclosingHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="fridayclosingHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .fridayclosingHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-fridayclosingHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                            <div className="col-lg">
                              <input
                                type="text"
                                maxLength="4"
                                name="saturdayclosingHour"
                                value={
                                  this.state.quickCollectDeviceInfo
                                    .saturdayclosingHour
                                }
                                className="form-control form-control-sm"
                                data-test="text-saturdayclosingHour"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div className="card mar-top20 ">
                      <div className="card-header bg-dark text-white card-header-custom">
                        MULTIPLE DAILY DELIVERY INTERVALS
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="form-group row">
                            <div className="col-lg">
                              <label
                                htmlFor="timeZone"
                                className="font-weight-bold"
                              >
                                Time Zone
                              </label>
                              <select
                                className="form-control  form-control-sm mar-bottom20"
                                name="timeZone"
                                data-test="text-timeZone"
                                onChange={(e) => {
                                  this.onChangeControlQuickDeviceClientInfo(e);
                                }}
                                value={
                                  this.state.quickCollectDeviceInfo.timeZone
                                }
                              >
                                {this.getTimeZoneQCDI()}
                              </select>
                            </div>
                            <div className="form-check form-check-inline col-lg ">
                              <input
                                type="checkbox"
                                name="dstHonoured"
                                className="form-check-input"
                                value={
                                  this.state.quickCollectDeviceInfo.dstHonoured
                                }
                                checked={
                                  this.state.quickCollectDeviceInfo
                                    .dstHonoured === 1
                                }
                                onChange={(e) => {
                                  this.onChangeControlCheckedQuickDeviceClientInfo(
                                    e
                                  );
                                }}
                                data-test="text-dstHonoured"
                              />
                              <label
                                htmlFor="dstHonoured"
                                className="font-weight-bold mar-top10"
                              >
                                DST Honoured
                              </label>
                            </div>
                          </div>
                          <div className="card mar-top20 ">
                            <div className="card-header bg-dark text-white card-header-custom">
                              DELIVERY INTERVALS
                            </div>
                            <div className="card-body">
                              <div className="form-group row text-center">
                                <div className="col-lg">
                                  <label
                                    htmlFor="days"
                                    className="font-weight-bold"
                                  >
                                    {" "}
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <label
                                    htmlFor="sunday"
                                    className="font-weight-bold"
                                  >
                                    #1{" "}
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <label
                                    htmlFor="monday"
                                    className="font-weight-bold"
                                  >
                                    #2{" "}
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <label
                                    htmlFor="tuesday"
                                    className="font-weight-bold"
                                  >
                                    #3{" "}
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <label
                                    htmlFor="wednesday"
                                    className="font-weight-bold"
                                  >
                                    #4{" "}
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <label
                                    htmlFor="thursday"
                                    className="font-weight-bold"
                                  >
                                    #5{" "}
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="friday">#6 </label>
                                </div>
                                <div className="col-lg">
                                  <label
                                    htmlFor="saturday"
                                    className="font-weight-bold"
                                  >
                                    #7{" "}
                                  </label>
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-lg">
                                  <label
                                    htmlFor="openingHour"
                                    className="font-weight-bold"
                                  >
                                    Start
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="sundayOpeningHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .sundayOpeningHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-sundayOpeningHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .mondayOpeningHour
                                    }
                                    name="mondayOpeningHour"
                                    className="form-control form-control-sm"
                                    data-test="text-mondayOpeningHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="tuesdayOpeningHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .tuesdayOpeningHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-tuesdayOpeningHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="wednesdayOpeningHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .wednesdayOpeningHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-wednesdayOpeningHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="thursdayOpeningHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .thursdayOpeningHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-thursdayOpeningHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="fridayOpeningHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .fridayOpeningHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-fridayOpeningHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="saturdayOpeningHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .saturdayOpeningHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-saturdayOpeningHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <div className="col-lg">
                                  <label
                                    htmlFor="closingHour"
                                    className="font-weight-bold"
                                  >
                                    End{" "}
                                  </label>
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="sundayclosingHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .sundayclosingHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-sundayclosingHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .mondayclosingHour
                                    }
                                    name="mondayclosingHour"
                                    className="form-control form-control-sm"
                                    data-test="text-mondayclosingHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="tuesdayclosingHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .tuesdayclosingHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-tuesdayclosingHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="wednesdayclosingHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .wednesdayclosingHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-wednesdayclosingHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="thursdayclosingHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .thursdayclosingHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-thursdayclosingHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="fridayclosingHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .fridayclosingHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-fridayclosingHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-lg">
                                  <input
                                    type="text"
                                    maxLength="4"
                                    name="saturdayclosingHour"
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .saturdayclosingHour
                                    }
                                    className="form-control form-control-sm"
                                    data-test="text-saturdayclosingHourMD"
                                    onChange={(e) => {
                                      this.onChangeControlQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card mar-top20 ">
                            <div className="card-header bg-dark text-white card-header-custom">
                              DAYS OF OPERATION
                            </div>
                            <div className="card-body">
                              <div className="form-group row ">
                                <div className="form-check form-check-inline col-lg">
                                  <input
                                    type="checkbox"
                                    name="sundayMD"
                                    onChange={(e) => {
                                      this.onChangeControlCheckedQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                    value={
                                      this.state.quickCollectDeviceInfo.sundayMD
                                    }
                                    checked={
                                      this.state.quickCollectDeviceInfo
                                        .sundayMD === 1 ||
                                      this.state.quickCollectDeviceInfo
                                        .sundayMD === "1"
                                    }
                                    className="form-check-input "
                                    data-test="text-sundayMD"
                                  />
                                  <label className="font-weight-bold mar-top10">
                                    SUN
                                  </label>
                                </div>
                                <div className="form-check form-check-inline col-lg">
                                  <input
                                    type="checkbox"
                                    name="mondayMD"
                                    onChange={(e) => {
                                      this.onChangeControlCheckedQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                    value={
                                      this.state.quickCollectDeviceInfo.mondayMD
                                    }
                                    checked={
                                      this.state.quickCollectDeviceInfo
                                        .mondayMD === 1 ||
                                      this.state.quickCollectDeviceInfo
                                        .mondayMD === "1"
                                    }
                                    className="form-check-input "
                                    data-test="text-mondayMD"
                                  />
                                  <label className="font-weight-bold mar-top10">
                                    MON
                                  </label>
                                </div>
                                <div className="form-check form-check-inline col-lg">
                                  <input
                                    type="checkbox"
                                    name="tuesdayMD"
                                    onChange={(e) => {
                                      this.onChangeControlCheckedQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .tuesdayMD
                                    }
                                    checked={
                                      this.state.quickCollectDeviceInfo
                                        .tuesdayMD === 1 ||
                                      this.state.quickCollectDeviceInfo
                                        .tuesdayMD === "1"
                                    }
                                    className="form-check-input "
                                    data-test="text-tuesdayMD"
                                  />
                                  <label className="font-weight-bold mar-top10">
                                    TUE
                                  </label>
                                </div>
                                <div className="form-check form-check-inline col-lg">
                                  <input
                                    type="checkbox"
                                    name="wednesdayMD"
                                    onChange={(e) => {
                                      this.onChangeControlCheckedQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .wednesdayMD
                                    }
                                    checked={
                                      this.state.quickCollectDeviceInfo
                                        .wednesdayMD === 1 ||
                                      this.state.quickCollectDeviceInfo
                                        .wednesdayMD === "1"
                                    }
                                    className="form-check-input "
                                    data-test="text-wednesdayMD"
                                  />
                                  <label className="font-weight-bold mar-top10">
                                    WED
                                  </label>
                                </div>
                                <div className="form-check form-check-inline col-lg">
                                  <input
                                    type="checkbox"
                                    name="thursdayMD"
                                    onChange={(e) => {
                                      this.onChangeControlCheckedQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .thursdayMD
                                    }
                                    checked={
                                      this.state.quickCollectDeviceInfo
                                        .thursdayMD === 1 ||
                                      this.state.quickCollectDeviceInfo
                                        .thursdayMD === "1"
                                    }
                                    className="form-check-input "
                                    data-test="text-thursdayMD"
                                  />
                                  <label className="font-weight-bold mar-top10">
                                    THURS
                                  </label>
                                </div>
                                <div className="form-check form-check-inline col-lg">
                                  <input
                                    type="checkbox"
                                    name="fridayMD"
                                    onChange={(e) => {
                                      this.onChangeControlCheckedQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                    value={
                                      this.state.quickCollectDeviceInfo.fridayMD
                                    }
                                    checked={
                                      this.state.quickCollectDeviceInfo
                                        .fridayMD === 1 ||
                                      this.state.quickCollectDeviceInfo
                                        .fridayMD === "1"
                                    }
                                    className="form-check-input "
                                    data-test="text-fridayMD"
                                  />
                                  <label className="font-weight-bold mar-top10">
                                    FRI
                                  </label>
                                </div>
                                <div className="form-check form-check-inline col-lg">
                                  <input
                                    type="checkbox"
                                    name="saturdayMD"
                                    onChange={(e) => {
                                      this.onChangeControlCheckedQuickDeviceClientInfo(
                                        e
                                      );
                                    }}
                                    value={
                                      this.state.quickCollectDeviceInfo
                                        .saturdayMD
                                    }
                                    checked={
                                      this.state.quickCollectDeviceInfo
                                        .saturdayMD === 1 ||
                                      this.state.quickCollectDeviceInfo
                                        .saturdayMD === "1"
                                    }
                                    className="form-check-input "
                                    data-test="text-saturdayMD"
                                  />
                                  <label className="font-weight-bold mar-top10">
                                    SAT
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  <div className="form-group row">
                    <div className="col-lg text-center mar-top5 mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-refresh"
                        onClick={() => {
                          this.onHandleRefreshQCDI();
                        }}
                      >
                        REFRESH
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-qccMenu"
                        onClick={() => {
                          this.onHandleBackQCDI();
                        }}
                      >
                        QCC MENU
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        onClick={() => this.onHandleUpdateQCDI()}
                        data-test="button-update"
                        disabled={!this.state.accessGrantWriteQCDM}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10 mar-left100"
                        data-test="button-delete"
                        onClick={() => this.onHandleDeleteQCDI()}
                        disabled={!this.state.adminQCDI}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 text-center">
                      {this.state.submitStatusQuickCollectDeviceInfo ===
                        "OK" && (
                        <div className="alert alert-success alert-padding notification">
                          {this.state.submitMsgQuickCollectDeviceInfo}
                        </div>
                      )}
                      {this.state.submitStatusQuickCollectDeviceInfo ===
                        "Error" && (
                        <div className="alert alert-danger alert-padding notification">
                          {this.state.submitMsgQuickCollectDeviceInfo}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
}
