import {
  MTCN_SEARCH_STARTED,
  MTCN_SEARCH_SUCCESS,
  MTCN_SEARCH_ERROR,
} from "./type";

import axios from "axios";
import { getHeadersOrder, getHeadersAgent } from "../../components/utility/utility";

export const doSearchMtcn = (submittedData, group, jwtToken) => {
  //console.log('doSearchMtcn', submittedData, group);
  return function doSearchMtcnAction(dispatch) {
    dispatch(doMtcnSearchStarted());
    let endPoint = '';
    if(group === "mtcn" || group === "pinNumber") {
      endPoint = '/v1/ord/dbview/moneytransferutility/mtcnsearch';
    } else if(group === "phoneNumber" || group === "wuCard") {
      endPoint = '/v1/ord/dbview/moneytransferutility/wucardsearch';
    } else if(group === "skrecord") {
      endPoint = '/v1/ord/dbview/moneytransferutility/skrecords';
    } else if(group === "agentAccountNumber") {
      endPoint = '/v1/ord/dbview/moneytransferutility/agent/';
    }
    //ORDER
    if(group !== "agentAccountNumber") {
      return axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}${endPoint}`,submittedData, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: jwtToken
        },
      })
      .then((response) => {
        dispatch(doMtcnSearchSuccess(response.data));
      })
      .catch(error=>{
        dispatch(doMtcnSearchError(error));
      });
    } else {
      //AGENT
      return axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}${endPoint}${submittedData.searchKeyValue}`, {
        headers: {
          ...getHeadersAgent().headers,
          Authorization: jwtToken
        },
      })
      .then((response) => {
        dispatch(doMtcnSearchSuccess(response.data));
      })
      .catch(error=>{
        dispatch(doMtcnSearchError(error));
      });
    }
    
  };
};

export const doMtcnSearchStarted = () => {
  return { type: MTCN_SEARCH_STARTED };
};

export const doMtcnSearchSuccess = (dataInfo) => {
  return { type: MTCN_SEARCH_SUCCESS, payload: dataInfo };
};

export const doMtcnSearchError = (error) => {
  return { type: MTCN_SEARCH_ERROR, payload: error };
};
