import React, { Component } from "react";
import axios from "axios";
import { getHeadersProduct } from "../../utility/utility";
import Header from "../../header/header";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

class PartnerFraudPreventionSearch extends Component {
  state = {
    fraudType: "A",
    fraudEntry: "",
    originCountry: "",
    destinationCountry: "",
    showPartnerFraudPreventionDetailsConfirm: false,
    submitStatusPartnerFraudPrevention: null,
    submitMsgPartnerFraudPrevention: "",
  };

  onChangeControlPartnerFraudPreventionDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  
  onChangeControlPartnerFraudPreventionFraudEntry = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onChangeControlPartnerFraudPreventionCountry = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9\s]/, '')
    });
  };

  onValidatePartnerFraudPreventionDetail = () => {
    // console.log(this.state);
    if (this.state.fraudType === "" || this.state.fraudEntry === "") {
      this.setState({
        submitStatusPartnerFraudPrevention: "Error",
        submitMsgPartnerFraudPrevention:
          "Please Select Fraud Type & Enter The Value For Agent Account / Network",
      });
      return false;
    }
    if (this.state.fraudType === 'A' && this.state.fraudEntry.length !== 9) {
      this.setState({
        submitStatusPartnerFraudPrevention: "Error",
        submitMsgPartnerFraudPrevention:
          "Please Check The Length Of The Agent Account",
      });
      return false;
    }
    if (this.state.originCountry && this.state.originCountry.length !== 3) {
      this.setState({
        submitStatusPartnerFraudPrevention: "Error",
        submitMsgPartnerFraudPrevention:
          "Please Check The Origin Country",
      });
      return false;
    }
    if (
      this.state.destinationCountry &&
      this.state.destinationCountry.length !== 3
    ) {
      this.setState({
        submitStatusPartnerFraudPrevention: "Error",
        submitMsgPartnerFraudPrevention:
          "Please Check The Destination Country",
      });
      return false;
    }
    return true;
  };

  componentDidMount(){    
    const accessToken = this.props.authState.accessToken;
    try {
      const token = jwt_decode(accessToken.value);
      const id=jwt_decode(accessToken.value).sub;
      const operatorIdPFP = id.replace(/\D/g,'');
      const accessRole = jwt_decode(accessToken.value).role;
      const accessGrantPFP =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS
        )||accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS 
        )||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_CCDATABASE) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK);
      const accessGrantWritePFP =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS 
        )||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_CCDATABASE) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK);

      this.setState({
        accessToken: token,
        token:accessToken.value,
        role: accessRole,
        accessGrantedPFP: accessGrantPFP,
        accessGrantedWritePFP: accessGrantWritePFP,
        operatorIdPFP: operatorIdPFP
      }, () => {
        if(!this.state.accessGrantedPFP) { 
          this.setState({
            submitStatusPartnerFraudPrevention: 'Error',
            submitMsgPartnerFraudPrevention: "Unauthorized! Access Is Denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }

  onHandlePFPNewSearch = (btnTypePFP) => {
    if (!this.onValidatePartnerFraudPreventionDetail()) {
      return;
    }
    // if (this.state.fraudType && this.state.fraudEntry.length === 9) {
      this.setState({ fraudEntry: this.state.fraudEntry.toUpperCase() });
      axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/partnerfraudprevention/details/${this.state.fraudType}/${this.state.fraudEntry}`,{
          headers:{
            ...getHeadersProduct().headers,
            originCountry: this.state.originCountry,
            destinationCountry: this.state.destinationCountry,
            Authorization:this.state.token,
          }
        }
      )
      .then((response) => {
               
        // console.log(response);
        if(response.data.records.length > 0) {
           if(btnTypePFP === 'new') {
              this.setState({
                submitStatusPartnerFraudPrevention: "Error",
                submitMsgPartnerFraudPrevention:
                  "Record Already Exist",
              });
              this.onClearMessagePFP();
           } else {
             // Search has been invoked
             this.props.history.push({
              pathname: "/partner-fraud-prevention-details",
              mode: 'search',
              records: response.data.records,
              state: this.state
            });
           }
        } else {
          if(btnTypePFP === 'search') {
            this.setState({
              submitStatusPartnerFraudPrevention: "Error",
              submitMsgPartnerFraudPrevention:
                response.data.message,
            });
            this.onClearMessagePFP();
          } else {
            // New button has been invoked
            this.props.history.push({
              pathname: "/partner-fraud-prevention-details",
              mode: 'add',
              state: this.state,
            });
          }
        }
        
      });
    // } 
  }

  onClearMessagePFP = () => {
    setTimeout(() =>
    this.setState({
      submitStatusPartnerFraudPrevention: null,
      submitMsgPartnerFraudPrevention: "",
    }),5000);
  }

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="PARTNER FRAUD PREVENTION"
        />
        <div className="container-fluid">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3" />
              <div className="col-lg-6">
                <div className="card mar-top20">
                  <div className="card-header bg-dark text-white card-header-custom">
                    PARTNER FRAUD PREVENTION
                  </div>
                  <div className="card-body card-body-custom">
                    <div className="form-group row">
                      <div className="col-lg">
                        {this.state
                          .submitStatusPartnerFraudPrevention ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgPartnerFraudPrevention}
                          </div>
                        )}
                        {this.state
                          .submitStatusPartnerFraudPrevention ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgPartnerFraudPrevention}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label className="font-weight-bold">Fraud Type</label>
                      </div>
                      <div className="col-lg-8"> 
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="fraudType"
                              value="A"
                              data-test="text-fraudType-Yes"
                              checked={this.state.fraudType === "A"}
                              onChange={(e) => {
                                this.onChangeControlPartnerFraudPreventionDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              Agent
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="fraudType"
                              value="N"
                              data-test="text-fraudType-No"
                              checked={this.state.fraudType === "N"}
                              onChange={(e) => {
                                this.onChangeControlPartnerFraudPreventionDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              Network
                            </label>
                          </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="fraudEntry" className="font-weight-bold">
                          Agent Account/Network
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          maxLength="9"
                          value={this.state.fraudEntry}
                          className="form-control form-control-sm"
                          name="fraudEntry"
                          onChange={(e) => {
                            this.onChangeControlPartnerFraudPreventionFraudEntry(
                              e
                            );
                          }}
                          data-test="text-fraudEntry"
                          placeholder="Agent Account/Network"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="originCountry" className="font-weight-bold">Origin Country</label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.originCountry}
                          className="form-control form-control-sm"
                          name="originCountry"
                          onChange={(e) => {
                            this.onChangeControlPartnerFraudPreventionCountry(
                              e
                            );
                          }}
                          data-test="text-originCountry"
                          placeholder="Origin Country"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label htmlFor="destinationCountry" className="font-weight-bold">
                          Destination Country
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.destinationCountry}
                          className="form-control form-control-sm"
                          name="destinationCountry"
                          onChange={(e) => {
                            this.onChangeControlPartnerFraudPreventionCountry(
                              e
                            );
                          }}
                          data-test="text-destinationCountry"
                          placeholder="Destination Country"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          data-test="button-new"
                          onClick={() => this.onHandlePFPNewSearch('new')}
                          disabled={!this.state.accessGrantedWritePFP}
                        >
                          NEW
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          data-test="button-search"
                          onClick={() => this.onHandlePFPNewSearch('search')}
                          disabled={!this.state.accessGrantedPFP}
                        >
                          SEARCH
                        </button>
                      </div>
                      <div className="col-lg-3" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withOktaAuth(PartnerFraudPreventionSearch);
