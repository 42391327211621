import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactWudpd } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit } from "react-icons/ai";
import { IconContext } from "react-icons";


import { getHeadersProduct } from "../../utility/utility";

class WuDirectedPayerDetails extends Component {
  state = {
    wuDirectedPayerid: "",
    deliveryService: "",
    isoCode: "",
    network: "",
    associatedNetworks: "",
    networkName: "",
    showWuDirectedPayerDetailsConfirm: false,
    columns: [
      {
        headerName: "ACTION",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {
                if(!this.props.location.state.accessGrantedWriteDMD) { 
                this.setState({
                  submitStatusWuDirectedPayerDetails: 'Error',
                  submitMsgWuDirectedPayerDetails: "Update Not Allowed By This Operator",
                });
              }else{
                this.handleEditWuDirectedPayerDetails(params)}
              }}
            >
              <IconContext.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEdit />
              </IconContext.Provider>
            </div>
          );
        },
        sortable: false,filter: false,floatingFilter: false,width: "80px",
      },
      {field: "deliveryService",headerName: "DELIVERY SERVICE",width: "220px",},
      {field: "isoCode",headerName: "ISO CODE",width: "220px",},
      {field: "network",headerName: "NETWORK",width: "230px",},
      {field: "associatedNetworks",headerName: "ASSOCIATED NETWORKS",width: "240px",},
      {field: "networkName",headerName: "NETWORK NAME",width: "240px",},
    ],
    rows: null,enableBtn: false,gridApi: null,paginationValQueue: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      //flex: 1,
    },
    submitStatusWuDirectedPayerDetails: null,
    submitMsgWuDirectedPayerDetails: "",
    paginationOptionWuDirectedPayerDetails: [25, 50, 100, 500],
    overlayLoadingTemplateWDPD:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateWDPD: '<span style="">No Records Found</span>',
  };

  onChangeControlWuDirectedPayerDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeControlWuDirectedPayerDetailsCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onGridReadyWuDirectedPayerDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/directedmanagement/directedpayerdetails`,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.token,
          },
        }
      )
      .then((response) => {
        //console.log(response)
        let recPayerDetails =[];
        if(response.data.records !== null){
          recPayerDetails = response.data.records;
        }else{
          this.setState({
            overlayNoRowsTemplateWDPD:`<span style="">${response.data.message}</span>`
          });
        }
        this.setState({
          rows: recPayerDetails,
        });
      });
  };
  handleEditWuDirectedPayerDetails = (wuDirectedPayerEditparams) => {
    let wuDirectedPayerEditData = wuDirectedPayerEditparams.node.data;
    this.setState({
      wuDirectedPayerid:
        wuDirectedPayerEditData.deliveryService +
        "" +
        wuDirectedPayerEditData.isoCode,
      deliveryService: wuDirectedPayerEditData.deliveryService,
      isoCode: wuDirectedPayerEditData.isoCode,
      network: wuDirectedPayerEditData.network,
      associatedNetworks: wuDirectedPayerEditData.associatedNetworks,
      networkName: wuDirectedPayerEditData.networkName,
      enableBtn: true,
    });
  };

  onPageSizeChangedWuDirectedPayerDetails = (e) => {
    this.setState({ paginationValQueue: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackWuDirectedPayerDetails = () => {
    this.props.history.push("/directed-maintenance-details");
  };

  onValidateWuDirectedPayerDetails = () => {
    if (this.state.deliveryService === "") {
      this.setState({
        submitStatusWuDirectedPayerDetails: "Error",
        submitMsgWuDirectedPayerDetails: "Please Enter The Value For Delivery Service ",
      });
      return false;
    }
    if (this.state.isoCode === "") {
      this.setState({
        submitStatusWuDirectedPayerDetails: "Error",
        submitMsgWuDirectedPayerDetails: "Please Enter The Value For ISO Code ",
      });
      return false;
    }
    if (this.state.network === "") {
      this.setState({
        submitStatusWuDirectedPayerDetails: "Error",
        submitMsgWuDirectedPayerDetails: "Please Enter The Value For Network ",
      });
      return false;
    }

    return true;
  };

  onHandleAddWuDirectedPayerDetails = () => {
    if (!this.onValidateWuDirectedPayerDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.wuDirectedPayerid = "";
    submittedObj.deliveryService = this.state.deliveryService;
    submittedObj.isoCode = this.state.isoCode;
    submittedObj.network = this.state.network;
    submittedObj.associatedNetworks = this.state.associatedNetworks;
    submittedObj.networkName = this.state.networkName;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/directedmanagement/directedpayerdetails`,
        submittedObj,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.token,
            "userId":this.props.location.state.operatorIdDMD
          },
        }
      )
      .then((response) => {
        //console.log(response)
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.wuDirectedPayerid =
            this.state.deliveryService + "" + this.state.isoCode;
          this.setState({
            submitStatusWuDirectedPayerDetails: "OK",
            submitMsgWuDirectedPayerDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusWuDirectedPayerDetails: "Error",
            submitMsgWuDirectedPayerDetails: response.data.responseString,
          });
        }
        this.onHandleClearWuDirectedPayerDetails();
      });
  };
  onHandleUpdateWuDirectedPayerDetails = () => {
    if (!this.onValidateWuDirectedPayerDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.deliveryService = this.state.deliveryService;
    submittedObj.isoCode = this.state.isoCode;
    submittedObj.network = this.state.network;
    submittedObj.associatedNetworks = this.state.associatedNetworks;
    submittedObj.networkName = this.state.networkName;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/directedmanagement/directedpayerdetails`,
        submittedObj,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.token,
            "userId":this.props.location.state.operatorIdDMD
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.deliveryService + "" + row.isoCode ===
              this.state.deliveryService + "" + this.state.isoCode
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusWuDirectedPayerDetails: "OK",
            submitMsgWuDirectedPayerDetails: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusWuDirectedPayerDetails: "Error",
            submitMsgWuDirectedPayerDetails: response.data.responseString,
          });
        }
        this.onHandleClearWuDirectedPayerDetails();
      });
  };

  onHandleClearWuDirectedPayerDetails = () => {
    this.setState({
      wuDirectedPayerid: "",
      deliveryService: "",
      isoCode: "",
      network: "",
      associatedNetworks: "",
      networkName: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusWuDirectedPayerDetails: null,
        }),
      5000
    );
  };

  getWuDirectedPayerDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showWuDirectedPayerDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Delivery Service {this.state.deliveryService} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showWuDirectedPayerDetailsConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteWuDirectedPayerDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteWuDirectedPayerDetails = () => {
    let submittedObj = {};
    submittedObj.deliveryService = this.state.deliveryService;
    submittedObj.isoCode = this.state.isoCode;
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/directedmanagement/directedpayerdetails`,
        {
          headers: {
            ...getHeadersProduct().headers,
            deliveryService: submittedObj.deliveryService,
            isoCode: submittedObj.isoCode,
            Authorization: this.props.location.state.token,
            "userId":this.props.location.state.operatorIdDMD
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          let newRows = this.state.rows.filter((row) => {
            return (
              row.deliveryService + "" + row.isoCode !==
              submittedObj.deliveryService + "" + submittedObj.isoCode
            );
          });
          this.setState({
            submitStatusWuDirectedPayerDetails: "OK",
            submitMsgWuDirectedPayerDetails: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusWuDirectedPayerDetails: "Error",
            submitMsgWuDirectedPayerDetails: response.data.responseString,
          });
        }
        this.onHandleClearWuDirectedPayerDetails();
      });
    this.setState({
      showWuDirectedPayerDetailsConfirm: false,
    });
  };
  render() {
    return (
      <>
        <Header history={this.props.history} heading="DIRECTED MANAGEMENT" />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                WU DIRECTED PAYER DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  DIRECTED PAYER FORM
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="deliveryService" className="font-weight-bold">
                          Delivery Service
                        </label>
                        <input
                          type="text"
                          maxLength="3"
                          size="5"
                          value={this.state.deliveryService}
                          className="form-control form-control-sm"
                          name="deliveryService"
                          onChange={(e) => {
                            this.onChangeControlWuDirectedPayerDetailsCharNumber(
                              e
                            );
                          }}
                          data-test="text-deliveryService"
                          placeholder="Delivery Service"
                          disabled={this.state.enableBtn}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="isoCode" className="font-weight-bold">ISO Code</label>
                        <input
                          type="text"
                          maxLength="3"
                          size="5"
                          value={this.state.isoCode}
                          className="form-control form-control-sm"
                          name="isoCode"
                          onChange={(e) => {
                            this.onChangeControlWuDirectedPayerDetailsCharNumber(
                              e
                            );
                          }}
                          data-test="text-isoCode"
                          placeholder="ISO Code"
                          disabled={this.state.enableBtn}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="network" className="font-weight-bold">Network</label>
                        <input
                          type="text"
                          maxLength="5"
                          size="8"
                          value={this.state.network}
                          className="form-control form-control-sm"
                          name="network"
                          onChange={(e) => {
                            this.onChangeControlWuDirectedPayerDetails(e);
                          }}
                          data-test="text-network"
                          placeholder="Network"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="associatedNetworks" className="font-weight-bold">
                          Associated Networks
                        </label>
                        <input
                          type="text"
                          maxLength="25"
                          size="40"
                          value={this.state.associatedNetworks}
                          className="form-control form-control-sm"
                          name="associatedNetworks"
                          onChange={(e) => {
                            this.onChangeControlWuDirectedPayerDetails(e);
                          }}
                          data-test="text-associatedNetworks"
                          placeholder="Associated Networks"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="networkName" className="font-weight-bold">Network Name</label>
                        <input
                          type="text"
                          maxLength="40"
                          size="55"
                          value={this.state.networkName}
                          className="form-control form-control-sm"
                          name="networkName"
                          onChange={(e) => {
                            this.onChangeControlWuDirectedPayerDetails(e);
                          }}
                          data-test="text-networkName"
                          placeholder="Network Name"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackWuDirectedPayerDetails}
                          data-test="button-back"
                        >
                          DIRECTED MAINT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddWuDirectedPayerDetails}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteDMD}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateWuDirectedPayerDetails}
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteDMD}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearWuDirectedPayerDetails}
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteDMD}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showWuDirectedPayerDetailsConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteDMD}
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                            {this.state.submitStatusWuDirectedPayerDetails ===
                              "OK" && (
                              <div className="alert alert-success alert-padding notification">
                                {this.state.submitMsgWuDirectedPayerDetails}
                              </div>
                            )}
                            {this.state.submitStatusWuDirectedPayerDetails ===
                              "Error" && (
                              <div className="alert alert-danger alert-padding notification">
                                {this.state.submitMsgWuDirectedPayerDetails}
                              </div>
                            )}
                          </div>
                          <div className="col-lg-2"></div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getWuDirectedPayerDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  DIRECTED PAYER DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlWuDirectedPayerDetails(e);
                        this.onPageSizeChangedWuDirectedPayerDetails(e);
                      }}
                      value={this.state.paginationValQueue}
                      name="paginationDropdownQueue"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionWuDirectedPayerDetails.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactWudpd
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyWuDirectedPayerDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateWDPD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateWDPD
                      }
                    ></AgGridReactWudpd>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WuDirectedPayerDetails;
