import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactBRMI } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AiTwotoneEdit as AiTwotoneEditBRMI } from "react-icons/ai";
import { IconContext as IconContextBRMI } from "react-icons";

import { getHeadersProduct } from "../../utility/utility";

class BusinessRuleMerchantId extends Component {
  state = {
    pmtMethodValues:[],
    pmtEmulatedValues:[],
    BusinessRulesMerchantid: "",
    paymentGateway: "",
    paymentGatewayOld: "",
    channelOld: "",
    transTypeOld: "",
    pmtMethodOld: "",
    thirdPartyIdenOld: "",
    transType: "",
    channel: "",
    pmtMethod: "",
    thirdPartyIden: "",
    pmtEmulated: "",
    billingID: "",
    fillerField: "",
    showBusinessRuleMerchantIdConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {
                if(!this.state.accessGrantedWriteBRM) { 
                  this.setState({
                    submitStatusBusinessRuleMerchantId: 'Error',
                    submitMsgBusinessRuleMerchantId: "Update Not Allowed By This Operator",
                  });
                }else{this.handleEditBusinessRuleMerchantId(params)}
                }}
            >
              <IconContextBRMI.Provider
                value={{ color: "#FDD017", size: "20px" }}
              >
                <AiTwotoneEditBRMI />
              </IconContextBRMI.Provider>
            </div>
          );
        },
        sortable: false, filter: false,floatingFilter: false,width: "80px",
      },
      {field: "paymentGateway",headerName: "PMT GATEWAY",width: "170px",},
      {field: "channel",headerName: "CHANNEL",width: "170px",},
      {field: "transType",headerName: "TXN TYPE",width: "170px",},
      {field: "pmtMethod",headerName: "PMT METHOD",width: "170px",},
      {field: "thirdPartyIden", headerName: "THIRD PARTY ID	",width: "170px",},
      {field: "pmtEmulated",headerName: "PMT EMULATED",width: "170px",},
      {field: "billingID", headerName: "BILL ID",width: "150px",},
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValCorridor: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusBusinessRuleMerchantId: null,
    submitMsgBusinessRuleMerchantId: "",
    paginationOptionBusinessRuleMerchantId: [25, 50, 100, 500],
    overlayLoadingTemplateBRMI:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateBRMI: '<span style="">No Records Found</span>',
  };

  onChangeControlBusinessRuleMerchantId = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount = () =>{
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/mcisconstants`,
        {
          headers: {
            ...getHeadersProduct().headers,
            // countryCode: this.props.location.state.data.country.alphaCode,
            Authorization:this.props.location.state.token,
            screenName: "merchantId"
          },
        }
      )
      .then((response) => {
        this.setState({
          pmtEmulatedValues: response.data.paymentEmulationConstants,
          pmtMethodValues:response.data.paymentMethodConstants,
        });
      });
  }

  onGridReadyBusinessRuleMerchantId = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/businessrules/merchantid`,       
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization:this.props.location.state.token,
          },
        }
      )
      .then((response) => {
        let recMerchantId = [];
        if (response.data.records !== null) {
          recMerchantId = response.data.records;
        }
        this.setState({
          rows: recMerchantId,
          accessGrantedWriteBRM:this.props.location.state.accessGrantedWriteBRM,
        });
      });
  };

  handleEditBusinessRuleMerchantId = (BusinessRulesMerchantidEditparams) => {
    let BusinessRulesMerchantidEditData =
      BusinessRulesMerchantidEditparams.node.data;
    this.setState({
      BusinessRulesMerchantid:
        BusinessRulesMerchantidEditData.paymentGateway +
        "" +
        BusinessRulesMerchantidEditData.channel +
        "" +
        BusinessRulesMerchantidEditData.transType +
        "" +
        BusinessRulesMerchantidEditData.pmtMethod +
        "" +
        BusinessRulesMerchantidEditData.thirdPartyIden,
      paymentGateway: BusinessRulesMerchantidEditData.paymentGateway,
      channel: BusinessRulesMerchantidEditData.channel,
      transType: BusinessRulesMerchantidEditData.transType,
      pmtMethod: BusinessRulesMerchantidEditData.pmtMethod,
      thirdPartyIden: BusinessRulesMerchantidEditData.thirdPartyIden,
      pmtEmulated: BusinessRulesMerchantidEditData.pmtEmulated,
      billingID: BusinessRulesMerchantidEditData.billingID,
      fillerField: BusinessRulesMerchantidEditData.fillerField,
      paymentGatewayOld: BusinessRulesMerchantidEditData.paymentGateway,
      channelOld: BusinessRulesMerchantidEditData.channel,
      transTypeOld: BusinessRulesMerchantidEditData.transType,
      pmtMethodOld: BusinessRulesMerchantidEditData.pmtMethod,
      thirdPartyIdenOld: BusinessRulesMerchantidEditData.thirdPartyIden,
      enableBtn: true,
    });
  };

  onPageSizeChangedBusinessRuleMerchantId = (e) => {
    this.setState({ paginationValCorridor: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackBusinessRuleMerchantId = () => {
    this.props.history.push("/business-rules-maintenance");
  };

  onValidateBusinessRuleMerchantId = () => {
    if (this.state.paymentGateway === "") {
      this.setState({
        submitStatusBusinessRuleMerchantId: "Error",
        submitMsgBusinessRuleMerchantId: "Please Select The Value For Payment Gateway ",
      });
      return false;
    }
    if (this.state.channel === "") {
      this.setState({
        submitStatusBusinessRuleMerchantId: "Error",
        submitMsgBusinessRuleMerchantId: "Please Select The Value For Channel ",
      });
      return false;
    }
    if (this.state.transType === "") {
      this.setState({
        submitStatusBusinessRuleMerchantId: "Error",
        submitMsgBusinessRuleMerchantId: "Please Select The Value For Transaction Type ",
      });
      return false;
    }
    if (this.state.pmtMethod === "") {
      this.setState({
        submitStatusBusinessRuleMerchantId: "Error",
        submitMsgBusinessRuleMerchantId: "Please Select The Value For Payment Method ",
      });
      return false;
    }
    if (this.state.pmtEmulated === "") {
      this.setState({
        submitStatusBusinessRuleMerchantId: "Error",
        submitMsgBusinessRuleMerchantId:
          "Please Select The Value For Payment Method Emulated ",
      });
      return false;
    }
    return true;
  };

  onHandleAddBusinessRuleMerchantId = () => {
    if (!this.onValidateBusinessRuleMerchantId()) {
      return;
    }
    let submittedObj = {};
    submittedObj.BusinessRulesMerchantid = "";
    submittedObj.paymentGateway = this.state.paymentGateway;
    submittedObj.channel = this.state.channel;
    submittedObj.transType = this.state.transType;
    submittedObj.pmtMethod = this.state.pmtMethod;
    submittedObj.thirdPartyIden = this.state.thirdPartyIden;
    submittedObj.pmtEmulated = this.state.pmtEmulated;
    submittedObj.billingID = this.state.billingID;
    submittedObj.fillerField = this.state.fillerField;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/businessrules/merchantid`,
        submittedObj,{headers: {
            ...getHeadersProduct().headers,            
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },})
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.BusinessRulesMerchantid =
            this.state.paymentGateway +
            "" +
            this.state.channel +
            "" +
            this.state.transType +
            "" +
            this.state.pmtMethod +
            "" +
            this.state.thirdPartyIden;
          this.setState({
            submitStatusBusinessRuleMerchantId: "OK",
            submitMsgBusinessRuleMerchantId: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusBusinessRuleMerchantId: "Error",
            submitMsgBusinessRuleMerchantId: response.data.responseString,
          });
        }
        this.onHandleClearBusinessRuleMerchantId();
      });
  };

  onHandleUpdateBusinessRuleMerchantId = () => {
    if (!this.onValidateBusinessRuleMerchantId()) {
      return;
    }
    let submittedObj = {};
    submittedObj.paymentGateway = this.state.paymentGateway;
    submittedObj.channel = this.state.channel;
    submittedObj.transType = this.state.transType;
    submittedObj.pmtMethod = this.state.pmtMethod;
    submittedObj.thirdPartyIden = this.state.thirdPartyIden;
    submittedObj.pmtEmulated = this.state.pmtEmulated;
    submittedObj.billingID = this.state.billingID;
    submittedObj.fillerField = this.state.fillerField;
    submittedObj.paymentGatewayOld = this.state.paymentGatewayOld;
    submittedObj.channelOld = this.state.channelOld;
    submittedObj.transTypeOld = this.state.transTypeOld;
    submittedObj.pmtMethodOld = this.state.pmtMethodOld;
    submittedObj.thirdPartyIdenOld = this.state.thirdPartyIdenOld;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/businessrules/merchantid`,
        submittedObj,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.paymentGateway +
                "" +
                row.channel +
                "" +
                row.transType +
                "" +
                row.pmtMethod +
                "" +
                row.thirdPartyIden ===
              this.state.paymentGatewayOld +
                "" +
                this.state.channelOld +
                "" +
                this.state.transTypeOld +
                "" +
                this.state.pmtMethodOld +
                "" +
                this.state.thirdPartyIdenOld
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusBusinessRuleMerchantId: "OK",
            submitMsgBusinessRuleMerchantId: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusBusinessRuleMerchantId: "Error",
            submitMsgBusinessRuleMerchantId: response.data.responseString,
          });
        }
        this.onHandleClearBusinessRuleMerchantId();
      });
  };

  onHandleClearBusinessRuleMerchantId = () => {
    this.setState({
      BusinessRulesMerchantid: "",
      paymentGateway: "",
      channel: "",
      transType: "",
      pmtMethod: "",
      thirdPartyIden: "",
      pmtEmulated: "",
      billingID: "",
      fillerField: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusBusinessRuleMerchantId: null,
        }),
      5000
    );
  };

  getBusinessRuleMerchantId = () => {
    return (
      <div>
        <Modal
          show={this.state.showBusinessRuleMerchantIdConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">Are You Sure, You Want To Delete This Merchant ID Entry ?</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showBusinessRuleMerchantIdConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteBusinessRuleMerchantId}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteBusinessRuleMerchantId = () => {
    let submittedObj = {};
    submittedObj.paymentGateway = this.state.paymentGateway;
    submittedObj.channel = this.state.channel;
    submittedObj.transType = this.state.transType;
    submittedObj.pmtMethod = this.state.pmtMethod;
    if (this.state.thirdPartyIden === "") {
      submittedObj.thirdPartyIden = "ET";
    } else {
      submittedObj.thirdPartyIden = this.state.thirdPartyIden;
    }
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/businessrules/merchantid/${submittedObj.channel}/${submittedObj.transType}/${submittedObj.pmtMethod}/${submittedObj.thirdPartyIden}/`,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          let newRows = this.state.rows.filter((row) => {
            return (
              row.paymentGateway +
                "" +
                row.channel +
                "" +
                row.transType +
                "" +
                row.pmtMethod !==
              submittedObj.paymentGateway +
                "" +
                submittedObj.channel +
                "" +
                submittedObj.transType +
                "" +
                submittedObj.pmtMethod
            );
          });

          this.setState({
            submitStatusBusinessRuleMerchantId: "OK",
            submitMsgBusinessRuleMerchantId: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusBusinessRuleMerchantId: "Error",
            submitMsgBusinessRuleMerchantId: response.data.responseString,
          });
        }
        this.onHandleClearBusinessRuleMerchantId();
      });
    this.setState({ showBusinessRuleMerchantIdConfirm: false });
  };

  getChannels = () => {
    let channels = [
      { nameChannel: "IP", labelChannel: "SMART PHONE" },
      { nameChannel: "WB", labelChannel: "WEB" },
      { nameChannel: "WT", labelChannel: "MARS" },
    ];
    return channels.map((item) => (
      <option key={item.labelChannel} value={item.nameChannel}>
        {item.labelChannel}
      </option>
    ));
  };

  getTransType = () => {
    let transType = [
      { nametransType: "MT", labeltransType: "PERSON TO PERSON" },
      { nametransType: "QC", labeltransType: "QUICK COLLECT" },
    ];
    return transType.map((item) => (
      <option key={item.labeltransType} value={item.nametransType}>
        {item.labeltransType}
      </option>
    ));
  };

  getPtMethod = () => {
    if(this.state.pmtMethodValues.length > 0) {
      return this.state.pmtMethodValues.map((item) => (
        <option key={item.value} value={item.key}>
          {item.value}
        </option>
      ));
    } else {
      return null;
    }
  };

  getPmtEmulated = () => {
    if(this.state.pmtEmulatedValues.length > 0) {
      return this.state.pmtEmulatedValues.map((item) => (
        <option key={item.value} value={item.key}>
          {item.value}
        </option>
      ));
    } else {
      return null;
    }
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="BUSINESS RULES MAINTENANCE"
        />
        <div
          className="container-fluid"
          data-test="component-business-rule-merchant-id"
        >
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                BUSINESS RULE MERCHANT ID
              </h4>
              <div className="card ">
                <div className="card-header bg-dark text-white card-header-custom">
                  BUSINESS RULE MERCHANT ID FORM
                </div>

                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="paymentGateway" className="font-weight-bold">Payment Gateway</label>
                        <select
                          className="form-control  form-control-sm "
                          name="paymentGateway"
                          data-test="text-paymentGateway"
                          onChange={(e) => {
                            this.onChangeControlBusinessRuleMerchantId(e);
                          }}
                          value={this.state.paymentGateway}
                          // readOnly={this.state.enableBtn}
                        >
                          <option value="">SELECT</option>
                          <option value="AP ">APOLLO</option>
                        </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="channel" className="font-weight-bold">Channel</label>
                        <select
                          className="form-control  form-control-sm "
                          name="channel"
                          data-test="text-channel"
                          onChange={(e) => {
                            this.onChangeControlBusinessRuleMerchantId(e);
                          }}
                          value={this.state.channel}
                          // readOnly={this.state.enableBtn}
                        >
                          <option value="">SELECT</option>
                          {this.getChannels()}
                        </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="transType" className="font-weight-bold">Transaction Type</label>
                        <select
                          className="form-control  form-control-sm "
                          name="transType"
                          data-test="text-transType"
                          onChange={(e) => {
                            this.onChangeControlBusinessRuleMerchantId(e);
                          }}
                          value={this.state.transType}
                          //readOnly={this.state.enableBtn}
                        >
                          <option value="">SELECT</option>
                          {this.getTransType()}
                        </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="pmtMethod" className="font-weight-bold">Payment Method</label>
                        <select
                          className="form-control  form-control-sm "
                          name="pmtMethod"
                          data-test="text-pmtMethod"
                          onChange={(e) => {
                            this.onChangeControlBusinessRuleMerchantId(e);
                          }}
                          value={this.state.pmtMethod}
                          //readOnly={this.state.enableBtn}
                        >
                          {/* <option value="">SELECT</option> */}
                          {this.getPtMethod()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="thirdPartyIden" className="font-weight-bold">
                          Third Party Identifier
                        </label>
                        <input
                          type="text"
                          maxLength="1"
                          size="1"
                          value={this.state.thirdPartyIden}
                          className="form-control form-control-sm"
                          name="thirdPartyIden"
                          onChange={(e) => {
                            this.onChangeControlBusinessRuleMerchantId(e);
                          }}
                          data-test="text-thirdPartyIden"
                          placeholder="Third Party Identifier"
                          //readOnly={this.state.enableBtn}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="pmtEmulated" className="font-weight-bold">
                          Payment Method Emulated
                        </label>
                        <select
                          className="form-control  form-control-sm "
                          name="pmtEmulated"
                          data-test="text-pmtEmulated"
                          onChange={(e) => {
                            this.onChangeControlBusinessRuleMerchantId(e);
                          }}
                          value={this.state.pmtEmulated}
                        >
                          {/* <option value="">SELECT</option> */}
                          {this.getPmtEmulated()}
                        </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="billingID" className="font-weight-bold">CSC Billing ID</label>
                        <input
                          type="text"
                          maxLength="4"
                          size="4"
                          value={this.state.billingID}
                          className="form-control form-control-sm"
                          name="billingID"
                          onChange={(e) => {
                            this.onChangeControlBusinessRuleMerchantId(e);
                          }}
                          data-test="text-billingID"
                          placeholder="CSC Billing ID"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="fillerField" className="font-weight-bold">Filler field</label>
                        <input
                          type="text"
                          maxLength="14"
                          size="14"
                          value={this.state.fillerField}
                          className="form-control form-control-sm"
                          name="fillerField"
                          onChange={(e) => {
                            this.onChangeControlBusinessRuleMerchantId(e);
                          }}
                          data-test="text-fillerField"
                          placeholder="Filler field"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackBusinessRuleMerchantId}
                          data-test="button-back"
                        >
                          BUSINESS RULE MAINT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddBusinessRuleMerchantId}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.state.accessGrantedWriteBRM}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateBusinessRuleMerchantId}
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.state.accessGrantedWriteBRM}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearBusinessRuleMerchantId}
                          disabled={!this.state.enableBtn || !this.state.accessGrantedWriteBRM}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showBusinessRuleMerchantIdConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn || !this.state.accessGrantedWriteBRM}
                        >
                          DELETE
                        </button>
                      </div>
                      <div className="col-lg-3">
                        
                      </div>
                    </div>
                    <div className="row mar-top10">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                        {this.state.submitStatusBusinessRuleMerchantId ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgBusinessRuleMerchantId}
                          </div>
                        )}
                        {this.state.submitStatusBusinessRuleMerchantId ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgBusinessRuleMerchantId}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-4"></div>
                      </div>
                  </form>
                </div>
              </div>
              {this.getBusinessRuleMerchantId()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  BUSINESS RULE MERCHANT ID DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlBusinessRuleMerchantId(e);
                        this.onPageSizeChangedBusinessRuleMerchantId(e);
                      }}
                      value={this.state.paginationValCorridor}
                      name="paginationDropdownCorridor"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionBusinessRuleMerchantId.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactBRMI
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyBusinessRuleMerchantId}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateBRMI
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateBRMI
                      }
                    ></AgGridReactBRMI>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BusinessRuleMerchantId;
