import React, { Component } from "react";
import Header from "../../header/header";
import axios from "axios";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

import { getHeadersShared } from "../../utility/utility";

class BusinessRuleMaintenance extends Component {
  state = {
    countryCode: "",
    channel: "190",
    product: "1",
    data: {},
    submitMsgBusinessRuleMaintenance: "",
    submitStatusBusinessRuleMaintenance: null,  
  }; 

  componentDidMount(){    
    const accessToken = this.props.authState.accessToken;
    try {
      const token = jwt_decode(accessToken.value);
      const id=jwt_decode(accessToken.value).sub;
      const operatorIdBRM = id.replace(/\D/g,'');
      const accessRole = jwt_decode(accessToken.value).role;
      const accessGrantBRM =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS
        ) ||
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS
        ) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCDELIVERYMONITOR) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCMANAGER) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK);
      const accessGrantWriteBRM =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS 
        ) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN);

      const accessGrantReadTMD1 = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)||
      accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
      accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS);

      const accessGrantWriteTMD3 = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
      (accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS) && accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN));


      this.setState({
        accessToken: token,
        token:accessToken.value,
        role: accessRole,
        accessGrantedBRM: accessGrantBRM,
        accessGrantedWriteBRM: accessGrantWriteBRM,
        operatorIdBRM: operatorIdBRM,
        accessGrantedReadTMD1: accessGrantReadTMD1,
        accessGrantedWriteTMD3: accessGrantWriteTMD3
      }, () => {
        if(!this.state.accessGrantedBRM) { 
          this.setState({
            submitStatusBusinessRuleMaintenance: 'Error',
            submitMsgBusinessRuleMaintenance: "Unauthorized! Access Is Denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }

  onChangeControlBusinessRuleMaintenance = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlBusinessRuleMaintenanceCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onHandleBusinessRulesSummary = () =>{
    this.props.history.push({pathname:"/business-rule-summary",state:this.state});
  }

  onHandleBusinessMerchantID = () =>{
    this.props.history.push({pathname:"/business-rule-merchant-id",state:this.state});
  }


  BusinessRuleMaintenanceHandleDisplayRules = () => {
    if (!this.onValidateBusinessRuleMaintenance()) {
      return;
    }
    let upperCountryCode = this.state.countryCode.toUpperCase();
    this.setState({ countryCode: upperCountryCode });
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/businessrules/countryrules`,
        {
          headers: {
            ...getHeadersShared().headers,
            countryCode: upperCountryCode,
            channel: this.state.channel,
            product: this.state.product,
            Authorization:this.state.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ data: response.data,
          });
          if (response.data.cntryRuleFlag === 1) {
            this.props.history.push({
              pathname: "/display-country-rules",
              state: this.state,
            });
          } else{
            this.setState({
              submitStatusBusinessRuleMaintenance: "Error",
              submitMsgBusinessRuleMaintenance:response.data.errMsg,
            });
          }
        } else {
          this.setState({
            submitStatusBusinessRuleMaintenance: "Error",
            submitMsgBusinessRuleMaintenance: response.data.errMsg,
          });
        }
      });
  };

  onValidateBusinessRuleMaintenance = () => {
    if (this.state.countryCode === "") {
      this.setState({
        submitStatusBusinessRuleMaintenance: "Error",
        submitMsgBusinessRuleMaintenance: "Please Enter The Value For Country Code ",
      });
      return false;
    }
    return true;
  };
  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="BUSINESS RULES MAINTENANCE"
        />
        <div className="container-fluid">
          <div className="row mar-top20">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="mar-topM25">
                <div className="card-body">
                <h4 className="card-title main-heading ">BUSINESS RULE MAINTENANCE</h4>
                  <div className="card ">
                    <div className="card-body">
                      <form>
                        <div className="form-group row">
                          <div className="col-lg">
                            <div className="card">
                              <div className="card-header bg-dark text-white card-header-custom">
                                Country Code
                              </div>
                              <div className="card-body business-country-code">
                              <label htmlFor="blockpay" className="font-weight-bold">Country Code :</label>
                              <input
                                  type="text"
                                  value={this.state.countryCode}
                                  className="form-control form-control-sm"
                                  name="countryCode"
                                  maxLength="3"
                                  onChange={(e) => {
                                    this.onChangeControlBusinessRuleMaintenanceCharNumber(e);
                                  }}
                                  data-test="text-countryCode"
                                  placeholder="Country Code"
                                />
                              </div>
                            </div>   
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <div className="card">
                              <div className="card-header bg-dark text-white card-header-custom">
                                Channel
                              </div>
                              <div className="form-check business-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="channel"
                                  value="190"
                                  checked={this.state.channel === "190"}
                                  onChange={(e) => {
                                    this.onChangeControlBusinessRuleMaintenance(
                                      e
                                    );
                                  }}
                                  data-test="select-radio-web"
                                />
                                <label className="form-check-label business-radio-text" htmlFor="WEB">
                                  WEB
                                </label>
                              </div>
                              <div className="form-check business-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="280"
                                  name="channel"
                                  checked={this.state.channel === "280"}
                                  onChange={(e) => {
                                    this.onChangeControlBusinessRuleMaintenance(
                                      e
                                    );
                                  }}
                                  data-test="select-radio-tmt"
                                />
                                <label className="form-check-label business-radio-text" htmlFor="TMT">
                                  TMT
                                </label>
                              </div>
                              <div className="form-check business-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="SMP"
                                  name="channel"
                                  checked={this.state.channel === "SMP"}
                                  onChange={(e) => {
                                    this.onChangeControlBusinessRuleMaintenance(
                                      e
                                    );
                                  }}
                                  data-test="select-radio-smart-phone"
                                />
                                <label
                                  className="form-check-label business-radio-text"
                                  htmlFor="SMART PHONE"
                                >
                                  SMART PHONE
                                </label>
                              </div>          
                            </div>           
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <div className="card">
                              <div className="card-header bg-dark text-white card-header-custom">
                                Product
                              </div>
                              <div className="form-check business-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="product"
                                  value="1"
                                  data-test="select-radio-mt"
                                  checked={this.state.product === "1"}
                                  onChange={(e) => {
                                    this.onChangeControlBusinessRuleMaintenance(
                                      e
                                    );
                                  }}
                                />
                                <label className="form-check-label business-radio-text" htmlFor="MT">
                                  MT
                                </label>
                              </div>
                              <div className="form-check business-radio">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="2"
                                  name="product"
                                  data-test="select-radio-qc"
                                  checked={this.state.product === "2"}
                                  onChange={(e) => {
                                    this.onChangeControlBusinessRuleMaintenance(
                                      e
                                    );
                                  }}
                                />
                                <label className="form-check-label business-radio-text" htmlFor="QC">
                                  QC
                                </label>
                              </div>         
                            </div>
                          </div>
                        </div>
                        
                        <div className="form-group d-inline text-center">
                          <div>
                            <button
                              type="button"
                              className="btn btn-warning mar-right5"
                              onClick={
                                this.BusinessRuleMaintenanceHandleDisplayRules
                              }
                              data-test="button-display-country-rules"
                              disabled={!this.state.accessGrantedBRM}
                            >
                              Display Country Rules
                            </button>
                            <button
                              type="button"
                              className="btn btn-warning mar-right5"
                              onClick={this.onHandleBusinessRulesSummary}
                              data-test="button-webmt-all-countries-summary"
                              disabled={!this.state.accessGrantedBRM}

                            >
                              WEBMT All Countries Summary
                            </button>
                            <button
                              type="button"
                              className="btn btn-warning mar-right5"
                              onClick={this.onHandleBusinessMerchantID}
                              data-test="button-merchant-id-update"
                            >
                              Merchant ID Update
                            </button>
                          </div>
                          <div className="col-lg mar-top10">
                          {this.state.submitStatusBusinessRuleMaintenance ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgBusinessRuleMaintenance}
                            </div>
                          )}
                          {this.state.submitStatusBusinessRuleMaintenance ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgBusinessRuleMaintenance}
                            </div>
                          )}
                        </div>
                        </div>
                      </form>
                      <div className="form-group row mar-top20">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-9 color-red">
                          This screen allows access to the business rules tables
                          by country.
                          <br />
                          The default channel is WEB business rules.
                          <br />
                          The default product type is MT.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </>
    );
  }
}

export default  withOktaAuth(BusinessRuleMaintenance);
