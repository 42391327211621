import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import Header from "../../header/header";
import { Button } from "react-bootstrap";

class TmonMaintenanceDetails extends Component {

  state={
    submitStatusTMD: null,
    submitMsgTMD: "",
  }

  componentDidMount(){
    const accessToken = this.props.authState.accessToken;
    try {
      const token = jwt_decode(accessToken.value);
      const id=jwt_decode(accessToken.value).sub;
      const operatorIdTMD = id.replace(/\D/g,'');
      const accessRole = jwt_decode(accessToken.value).role;

      const accessGrantReadTMD1 = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)||
      accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
      accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS);

      const accessGrantReadTMD2 = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)||
      accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
      accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_ACCESS) || 
      accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_UPDATE);
  

      const accessGrantWriteTMD1 =  accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        (accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS) && accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONTRACE));
      
      const accessGrantWriteTMD2 = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_UPDATE);

      const accessGrantWriteTMD3 = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        (accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS) && accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN));

      const accessGrantWriteTMD4 = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        (accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS) && accessRole.includes(process.env.REACT_APP_DB_VIEW_MTCS_OPERATION));

      this.setState({
        accessToken: token,
        token:accessToken.value,
        role: accessRole,
        accessGrantedReadTMD1: accessGrantReadTMD1,
        accessGrantedReadTMD2: accessGrantReadTMD2,
        accessGrantedWriteTMD1: accessGrantWriteTMD1,
        accessGrantedWriteTMD2: accessGrantWriteTMD2,
        accessGrantedWriteTMD3: accessGrantWriteTMD3,
        accessGrantedWriteTMD4: accessGrantWriteTMD4,
        operatorIdTMD: operatorIdTMD
      }, () => {
        if(!this.state.accessGrantedReadTMD1 && !this.state.accessGrantedReadTMD2) { 
          this.setState({
            submitStatusTMD: 'Error',
            submitMsgTMD: "Unauthorized! Access is denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }

  onHandleSRM = () => {
    this.props.history.push({
      pathname:"/saturn-response-details",state:this.state
    });
  }

  onHandleCSM = () => {
    this.props.history.push({
      pathname:"/country-state-maintenance",state:this.state
    });
  }

  onHandleMTM = () => {
    this.props.history.push({
      pathname:"/mcis-type-maintenance-details",state:this.state
    });
  }

  onHandleEQM = () => {
    this.props.history.push({
      pathname:"/error-queue-maintenance-search",state:this.state
    });
  }

  onHandleQM = () => {
    this.props.history.push({
      pathname:"/queue-maintenance",state:this.state
    });
  }

  onHandleATP = () => {
    this.props.history.push({
      pathname:"/agent-third-party-details",state:this.state
    });
  }

  onHandleEIM = () => {
    this.props.history.push({
      pathname:"/error-description-details",state:this.state
    });
  }

  onHandleGP = () => {
    this.props.history.push({
      pathname:"/gsp-param-details",state:this.state
    });
  }

  onHandleTTL = () => {
    this.props.history.push({
      pathname:"/tmon-txn-list",state:this.state
    });
  }

  onHandleTGP = () => {
    this.props.history.push({
      pathname:"/tmon-global-parameters",state:this.state
    });
  }

  render() {
    return (
      <div data-test="component-tmon-maintenance-details">
        <Header
          changeComponent={this.changeComponent}
          history={this.props.history}
          heading="	WU TMON FILE MAINTENANCE"
        />
        <div className="container-fluid ">
          <h4 className="card-title main-heading ">TMON MAINTENANCE DETAILS</h4>
          <div className="row">
            <div className="col-lg-4" />
            <div className="col-lg-4">
              {this.state.submitStatusTMD ===
                "Error" && (
                <div className="alert alert-danger alert-padding">
                  {this.state.submitMsgTMD}
                </div>
              )}
            </div>
            <div className="col-lg-4" />
          </div>
          <div className="row">
            <div className="col-lg-4" />
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body padding-bottom5 maintenance-menu-box">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD1 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-tgpD"
                          >
                            TMON GLOBAL PARAMETER
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleTGP}
                            data-test="button-tgpE"
                          >
                            TMON GLOBAL PARAMETER
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD1 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-ttlD"
                          >
                            TMON TXN LIST
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleTTL}
                            data-test="button-ttlE"
                          >
                            TMON TXN LIST
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD2 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-gpD"
                          >
                            GSP PARAMETER
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleGP}
                            data-test="button-gpE"
                          >
                            GSP PARAMETER
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD2 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-eimD"
                          >
                            ERROR INFO MAINTENANCE
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleEIM}
                            data-test="button-eimE"
                          >
                            ERROR INFO MAINTENANCE
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD2 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-atpD"
                          >
                            AGENT THIRD PARTY
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleATP}
                            data-test="button-atpE"
                          >
                            AGENT THIRD PARTY
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD1 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-qmD"
                          >
                            QUEUE MAINTENANCE
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleQM}
                            data-test="button-qmE"
                          >
                            QUEUE MAINTENANCE
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD1 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-eqmD"
                          >
                            ERROR QUEUE MAINTENANCE
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleEQM}
                            data-test="button-eqmE"
                          >
                            ERROR QUEUE MAINTENANCE
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD1 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-mtmD"
                          >
                            MCIS TYPE MAINTENANCE
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleMTM}
                            data-test="button-mtmE"
                          >
                            MCIS TYPE MAINTENANCE
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD2 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-srmD"
                          >
                            SATURN RESPONSE MAINTENANCE
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleSRM}
                            data-test="button-srmE"
                          >
                            SATURN RESPONSE MAINTENANCE
                          </Button>
                        )
                      }
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                      {!this.state.accessGrantedReadTMD2 ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-csmD"
                          >
                            COUNTRY STATE MAINTENANCE
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleCSM}
                            data-test="button-csmE"
                          >
                            COUNTRY STATE MAINTENANCE
                          </Button>
                        )
                      }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4" />
          </div>
        </div>
      </div>
    );
  }
}
export default withOktaAuth(TmonMaintenanceDetails);
