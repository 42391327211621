import React, { Component } from "react";
import Header from "../../header/header";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { getHeadersProduct } from "../../utility/utility";

class QuickCollectAgtIrp extends Component {
  state = {
    index: 0,
    agentIrpTierRequestResponseList: [],
    arrayQCAI: [],
    arrayCheck:[],
    activationDate: "",
    clientId: "",
    currency: "",
    lastUpdated: "",
    name: "",
    operator: "",
    ratingType: "",
    standardFee: "",
    checkflag: false,
    submitStatusQuickCollectAgtIrp: null,
    submitMsgQuickCollectAgtIrp: "",
    showQuickCollectAgtIrpModal: false,
  };
  getQuickCollectClientInfoAGTModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showQuickCollectAgtIrpModal}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Delete IRP Header And All IPR TIER
                Records?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() => {
                  this.setState({
                    showQuickCollectAgtIrpModal: false,
                    operation: "del",
                  });
                  this.onHandleDeleteQuickCollectAgtIrp();
                }}
                data-test="button-yes"
              >
                OK
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                onClick={() => {
                  this.setState({
                    showQuickCollectAgtIrpModal: false,
                  });
                }}
                data-test="button-no"
              >
                CANCEL
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  onChangeControlQuickCollectAgtIrp = (e) => {
    let val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val,
    });
  };

  onChangeControlQuickCollectAgtIrpCharAndNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };
  activationDateFormat = (date) => {
    let newactivationDate = "";
    if (date !== null) {
      let dateQCAI = new Date(date);
      newactivationDate = [
        dateQCAI.getFullYear(),
        ("0" + (dateQCAI.getMonth() + 1)).slice(-2),
        ("0" + dateQCAI.getDate()).slice(-2),
      ].join("-");
    }
    return newactivationDate;
  };

  loadAGTIRP = () => {
    const accountId = this.props.location.state.accountId;
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/agentirp/${accountId}`,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization: this.props.location.state.data.tokenQCDM,
            userId: this.props.location.state.data.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (response.data.clientId !== null) {
          let arrTemp = [];
          let dataQCAGT = response.data;
          this.setState({
            lengthAGT:
              response.data.agentIrpTierRequestResponseList !== null
                ? response.data.agentIrpTierRequestResponseList.length
                : 0,
            dataQCAGT: dataQCAGT,
            index:
              response.data.agentIrpTierRequestResponseList === null
                ? 0
                : response.data.agentIrpTierRequestResponseList.length,
            activationDate:
              this.state.operation === "del"
                ? ""
                : this.activationDateFormat(dataQCAGT.activationDate),
            clientId: dataQCAGT.clientId,
            currency: dataQCAGT.currency,
            lastUpdated:
              this.state.operation === "del" ? "" : dataQCAGT.lastUpdated,
            name: dataQCAGT.name,
            operator: this.state.operation === "del" ? "" : dataQCAGT.operator,
            ratingType: dataQCAGT.ratingType,
            standardFee:
              this.state.operation === "del" ? "0.00" : dataQCAGT.standardFee,
          });
          let testArray = [];
          for (let i = 0; i < 10; i++) {
            if (dataQCAGT.agentIrpTierRequestResponseList[i] === undefined || dataQCAGT.agentIrpTierRequestResponseList===null) {
              testArray.push(false);
            } else {
              testArray.push(true);
            }
          }
          this.setState({ arrayCheck: testArray });
          let arr =
            response.data.agentIrpTierRequestResponseList === null
              ? []
              : response.data.agentIrpTierRequestResponseList;

          for (
            let i =
              dataQCAGT.agentIrpTierRequestResponseList !== null
                ? dataQCAGT.agentIrpTierRequestResponseList.length
                : 0;
            i < 10;
            i++
          ) {
            arr.push({
              tierCeiling: "",
              feeAmount: "",
              prinPct: "",
              posFee: "",
              posPct: "",
            });
          }
          arrTemp.push(JSON.parse(JSON.stringify(arr)));

          this.setState({
            agentIrpTierRequestResponseList: arr,
            arrayTemp: arrTemp,
            operation: "",
          });
        } else {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp: response.data.responseString,
            operation: "",
          });
        }
      });
  };

  componentDidMount = () => {
    this.loadAGTIRP();
  };
  convertDateToLegacyFormat = (date) => {
    let d1QCAGT = new Date(date);
    let newactivationDate = [
      ("0" + (d1QCAGT.getMonth() + 1)).slice(-2),
      ("0" + d1QCAGT.getDate()).slice(-2),
      d1QCAGT.getFullYear(),
    ].join("/");
    return newactivationDate;
  };

  handleDeviceMaint = () => {
    this.props.history.push("/quick-collect-database-maintenance");
  };
  onHandleQccClientQuickCollectAgtIrp = () => {
    this.props.history.push({
      pathname: "/quick-collect-client-info",
      state: this.props.location.state.data,
    });
  };

  onHandleRefreshQuickCollectAgtIrp = () => {
    let arr = this.state.arrayTemp[0];
    let arrTemp = [];
    arrTemp.push(JSON.parse(JSON.stringify(arr)));
    this.setState({
      activationDate: this.activationDateFormat(
        this.state.dataQCAGT.activationDate
      ),
      clientId: this.state.dataQCAGT.clientId,
      currency: this.state.dataQCAGT.currency,
      lastUpdated: this.state.dataQCAGT.lastUpdated,
      name: this.state.dataQCAGT.name,
      operator: this.state.dataQCAGT.operator,
      ratingType: this.state.dataQCAGT.ratingType,
      standardFee: this.state.dataQCAGT.standardFee,
      agentIrpTierRequestResponseList: arr,
      arrayTemp: arrTemp,
    });
  };

  onChangeQuickCollectAgtIrpTable = (el, e, i) => {
    const { name, value } = e.target;

    let newArr = this.state.agentIrpTierRequestResponseList;
    let obj = newArr[i];
    obj[name] = value.replace(/[^0-9.]/, "");
    this.setState({
      agentIrpTierRequestResponseList: newArr,
    });
  };

  onvalidateQuickCollectAgtIrp = () => {
    let d1QCAGT = new Date(this.state.activationDate);
    let year = d1QCAGT.getFullYear();
    this.setState({ year });
    let tDate = new Date();
    let newTDate = [
      tDate.getFullYear(),
      ("0" + (tDate.getMonth() + 1)).slice(-2),
      ("0" + tDate.getDate()).slice(-2),
    ].join("-");
    if (
      this.state.standardFee === "" ||
      /^\d+(\.\d+)?$/.test(this.state.standardFee) === false
    ) {
      this.setState({
        submitStatusQuickCollectAgtIrp: "Error",
        submitMsgQuickCollectAgtIrp: "Invalid Standard Fee",
      });
      return false;
    }
    if (this.state.standardFee > 99999999.99) {
      this.setState({
        submitStatusQuickCollectAgtIrp: "Error",
        submitMsgQuickCollectAgtIrp: "Invalid Standard Fee",
      });
      return false;
    }
    if (this.state.activationDate === "") {
      this.setState({
        submitStatusQuickCollectAgtIrp: "Error",
        submitMsgQuickCollectAgtIrp: "Please Enter Value Of Activation Date",
      });
      return false;
    }
    if (this.state.year < 2007 || this.state.year > 3000) {
      this.setState({
        submitStatusQuickCollectAgtIrp: "Error",
        submitMsgQuickCollectAgtIrp: "Invalid Activation Date",
      });
      return false;
    }
    if (newTDate > this.state.activationDate) {
      this.setState({
        submitStatusQuickCollectAgtIrp: "Error",
        submitMsgQuickCollectAgtIrp:
          "Activation Date Cannot Be Less Than Todays Date",
      });
      return false;
    }
    if (!this.onHandleTableValidations()) {
      return false;
    }
    return true;
  };

  onHandleTableValidations = () => {
    let tempArr = [];
    for (
      let i = 0;
      i < this.state.agentIrpTierRequestResponseList.length;
      i++
    ) {
      let tierCeiling =
        this.state.agentIrpTierRequestResponseList[i].tierCeiling;
      let feeAmount = this.state.agentIrpTierRequestResponseList[i].feeAmount;
      let prinPct = this.state.agentIrpTierRequestResponseList[i].prinPct;
      let posFee = this.state.agentIrpTierRequestResponseList[i].posFee;
      let posPct = this.state.agentIrpTierRequestResponseList[i].posPct;
      if (tierCeiling !== "") {
        tempArr.push(tierCeiling.toString());
      }

      let findDuplicates = (arr) =>
        arr.filter((item, index) => arr.indexOf(item) !== index);

      if (tierCeiling !== "") {
        if (
          tierCeiling <= 0 ||
          /^\d+(\.\d+)?$/.test(tierCeiling) === false ||
          tierCeiling > 99999999.99
        ) {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp:
              " Tier Ceiling Must Be Greater Than Zero ",
          });
          return false;
        }
        let flag = findDuplicates(tempArr);
        if (
          feeAmount !== "" &&
          (/^\d+(\.\d+)?$/.test(feeAmount) === false || feeAmount > 99999999.99)
        ) {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp: " Invalid Fee Amount ",
          });
          return false;
        }
        if (
          prinPct !== "" &&
          (/^\d+(\.\d+)?$/.test(prinPct) === false || prinPct >= 100)
        ) {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp:
              "Invalid PRIN PCT Value, Should Be Less Than 100 ",
          });
          return false;
        }
        if (
          posFee !== "" &&
          (/^\d+(\.\d+)?$/.test(posFee) === false || posFee > 99999999.99)
        ) {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp: "Invalid POS Amount ",
          });
          return false;
        }
        if (
          posPct !== "" &&
          (/^\d+(\.\d+)?$/.test(posPct) === false || posPct >= 100)
        ) {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp:
              "Invalid POS PCT Value, Should Be Less Than 100 ",
          });
          return false;
        }
        if (flag.length !== 0) {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp: "Tier Ceiling Already Exists",
          });
          return false;
        }
      }
    }
    return true;
  };

  onHandleUpdateQuickCollectAgtIrp = () => {
    if (!this.onvalidateQuickCollectAgtIrp()) {
      return;
    }
    let submittedObj = {};
    submittedObj.clientId = this.state.clientId;
    submittedObj.name = this.state.name;
    submittedObj.ratingType = this.state.ratingType;
    submittedObj.currency = this.state.currency;
    submittedObj.standardFee = this.state.standardFee;
    submittedObj.activationDate = this.convertDateToLegacyFormat(
      this.state.activationDate
    );
    submittedObj.lastUpdated = this.state.lastUpdated;
    submittedObj.operator = this.state.operator;

    let array = this.state.agentIrpTierRequestResponseList.filter((row) => {
      if(row.feeAmount == ''){
        row.feeAmount = 0;
      }if(row.prinPct == ''){
        row.prinPct = 0;
      }if(row.posFee == ''){
        row.posFee = 0;
      }if(row.posPct == ''){
        row.posPct = 0;
      }
      return row.tierCeiling !== "";
    });

    submittedObj.agentIrpTierRequestResponseList = array;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/agentirp`,
        submittedObj,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization: this.props.location.state.data.tokenQCDM,
            userId: this.props.location.state.data.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            submitStatusQuickCollectAgtIrp: "OK",
            submitMsgQuickCollectAgtIrp: response.data.responseString,
          });
          this.loadAGTIRP();
        } else {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp: response.data.responseString,
          });
        }
      });
  };

  onHandleDeleteQuickCollectAgtIrp = () => {
    let submittedObjQCAI = {};
    const result = this.state.arrayQCAI.map((el) => el.tierCeiling.toString());
    //const id = this.state.arrayQCAI.map((el) => el.tierCeiling);
    submittedObjQCAI.agtClientId = this.state.clientId;
    submittedObjQCAI.tierCeilingIds = result;    
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/deleteagentirp`,
        submittedObjQCAI,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization: this.props.location.state.data.tokenQCDM,
            userId: this.props.location.state.data.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let myArray = this.state.agentIrpTierRequestResponseList.filter(
            (el) => !this.state.arrayQCAI.includes(el)
          );

          for (let i = myArray.length; i < 10; i++) {
            myArray.push({
              tierCeiling: "",
              feeAmount: "",
              prinPct: "",
              posFee: "",
              posPct: "",
            });
          }
          this.setState({
            submitStatusQuickCollectAgtIrp: "OK",
            submitMsgQuickCollectAgtIrp: response.data.responseString,
            agentIrpTierRequestResponseList: myArray,
            arrayQCAI: [],
          });
          this.loadAGTIRP();
        } else {
          this.setState({
            submitStatusQuickCollectAgtIrp: "Error",
            submitMsgQuickCollectAgtIrp: response.data.responseString,
          });
        }
      });
  };

  handleClick = (event, row, rowIndex) => {
    let array = this.state.arrayQCAI;
    const flagQCAI = (element) => element === row;
    let index = array.findIndex(flagQCAI);
    if (index === -1) {
      array.push(row);
    } else {
      array.splice(index, 1);
    }

    this.setState({ arrayQCAI: array });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">
              AGENT INTERNATIONAL RELATIONSHIP PRICING
            </h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                PRICING HEADER
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label name="clientId" data-test="text-clientId">
                        <span className="font-weight-bold">Client ID: </span>
                        {this.state.clientId}
                      </label>
                    </div>
                    <div className="col-lg">
                      <label name="name">
                        <span className="font-weight-bold">Name:</span>{" "}
                        {this.state.name}
                      </label>
                    </div>
                    <div className="col-lg">
                      <label name="lastUpdated">
                        <span className="font-weight-bold">Last Updated: </span>
                        {this.state.lastUpdated}
                      </label>
                    </div>

                    <div className="col-lg">
                      <label name="operator">
                        <span className="font-weight-bold"> Operator: </span>
                        {this.state.operator}
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="ratingType" className="font-weight-bold">
                        Rating Type:
                      </label>
                      <input
                        type="text"
                        value={this.state.ratingType}
                        maxLength="9"
                        className="form-control form-control-sm"
                        name="ratingType"
                        onChange={(e) => {
                          this.onChangeControlQuickCollectAgtIrp(e);
                        }}
                        disabled
                        data-test="text-ratingType"
                        placeholder="Rating Type"
                      />
                    </div>

                    <div className="col-lg">
                      <label htmlFor="currency" className="font-weight-bold">
                        Currency:
                      </label>
                      <input
                        type="text"
                        value={this.state.currency}
                        maxLength="12"
                        className="form-control form-control-sm"
                        name="currency"
                        onChange={(e) => {
                          this.onChangeControlQuickCollectAgtIrpCharAndNumber(
                            e
                          );
                        }}
                        disabled
                        data-test="text-currency"
                        placeholder="Currency"
                      />
                    </div>

                    <div className="col-lg">
                      <label htmlFor="standardFee" className="font-weight-bold">
                        Standard Fee:
                      </label>
                      <input
                        type="text"
                        value={this.state.standardFee}
                        maxLength="11"
                        className="form-control form-control-sm"
                        name="standardFee"
                        onChange={(e) => {
                          this.onChangeControlQuickCollectAgtIrp(e);
                        }}
                        data-test="text-standardFee"
                        placeholder="Standard Fee"
                      />
                    </div>

                    <div className="col-lg">
                      <label
                        htmlFor="activationDate"
                        className="font-weight-bold"
                      >
                        Activation Date:
                      </label>
                      <input
                        type="date"
                        value={this.state.activationDate}
                        className="form-control form-control-sm"
                        name="activationDate"
                        onChange={(e) => {
                          this.onChangeControlQuickCollectAgtIrp(e);
                        }}
                        data-test="text-activationDate"
                        //placeholder="Activation Date"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4"></div>
            <div className="col-lg text-center">
              {this.state.submitStatusQuickCollectAgtIrp === "OK" && (
                <div className="alert alert-success alert-padding notification">
                  {this.state.submitMsgQuickCollectAgtIrp}
                </div>
              )}
              {this.state.submitStatusQuickCollectAgtIrp === "Error" && (
                <div className="alert alert-danger alert-padding notification ">
                  {this.state.submitMsgQuickCollectAgtIrp}
                </div>
              )}
            </div>
            <div className="col-lg-4"></div>
          </div>
          <p align="center" className="red">
            POS DATA - INFORMATIONAL USE ONLY
          </p>
          <div>
            <table className="table">
              <thead className="bg-dark text-white table-bordered">
                <tr>
                  <td align="center"> DELETE </td>
                  <td align="center"> TIER CEILING </td>
                  <td align="center"> FEE AMOUNT </td>
                  <td align="center"> PRIN PCT </td>
                  <td align="center"> POS FEE </td>
                  <td align="center"> POS PCT </td>
                </tr>
              </thead>
              <tbody className="table table-bordered">
                {this.state.agentIrpTierRequestResponseList.map((el, i) => (
                  <tr key={i} data-test="tr">
                    <td align="center">
                      {" "}
                      <input
                        className="mar-top7"
                        type="checkbox"
                        data-test="text-checkbox"
                        disabled={i >= this.state.index}
                        onClick={(event) => this.handleClick(event, el, i)}
                        checked={this.state.arrayQCAI.includes(el)}
                      />{" "}
                    </td>
                    <td>
                      <input
                        type="text"
                        maxLength="11"
                        name="tierCeiling"
                        value={el.tierCeiling}
                        onChange={(e) => {
                          this.onChangeQuickCollectAgtIrpTable(el, e, i);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-tierCeiling"
                        disabled={this.state.arrayCheck[i] === true}          
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        maxLength="11"
                        name="feeAmount"
                        value={el.feeAmount}
                        onChange={(e) => {
                          this.onChangeQuickCollectAgtIrpTable(el, e, i);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-feeAmount"
                      />{" "}
                    </td>
                    <td>
                      <input
                        type="text"
                        maxLength="11"
                        name="prinPct"
                        value={el.prinPct}
                        onChange={(e) => {
                          this.onChangeQuickCollectAgtIrpTable(el, e, i);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-prinPct"
                      />{" "}
                    </td>
                    <td>
                      <input
                        type="text"
                        maxLength="11"
                        name="posFee"
                        value={el.posFee}
                        onChange={(e) => {
                          this.onChangeQuickCollectAgtIrpTable(el, e, i);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-posFee"
                      />{" "}
                    </td>
                    <td>
                      <input
                        type="text"
                        maxLength="11"
                        name="posPct"
                        value={el.posPct}
                        onChange={(e) => {
                          this.onChangeQuickCollectAgtIrpTable(el, e, i);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-posPct"
                      />{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="form-group row">
            <div className="col-lg text-center mar-bottom5 mar-top20">
              <button
                type="button"
                className="btn btn-warning mar-right10"
                data-test="button-device-maint"
                onClick={this.handleDeviceMaint}
              >
                QCC MENU
              </button>

              <button
                type="button"
                className="btn btn-warning mar-right10"
                data-test="button-update"
                onClick={this.onHandleUpdateQuickCollectAgtIrp}
                disabled={!this.props.location.state.data.accessGrantWriteQCDM}
              >
                UPDATE
              </button>

              <button
                type="button"
                className="btn btn-warning mar-right10"
                data-test="button-delete"
                disabled={
                  this.state.lengthAGT === 0 ||
                  !this.props.location.state.data.adminQCDM
                }
                onClick={(e) =>
                  this.state.arrayQCAI.length === 0
                    ? this.setState({
                        showQuickCollectAgtIrpModal: true,
                      })
                    : this.onHandleDeleteQuickCollectAgtIrp(e)
                }
              >
                DELETE
              </button>
              <button
                type="button"
                className="btn btn-warning mar-right10"
                data-test="button-back"
                disabled={this.props.location.state.flag !== 1}
                onClick={(e) => this.onHandleQccClientQuickCollectAgtIrp(e)}
              >
                QCC CLIENT
              </button>
              <button
                type="button"
                className="btn btn-warning mar-right10"
                data-test="button-refresh"
                disabled={this.props.location.state.flag !== 1}
                onClick={(e) => this.onHandleRefreshQuickCollectAgtIrp(e)}
              >
                REFRESH
              </button>
            </div>
          </div>
          {this.getQuickCollectClientInfoAGTModal()}
        </div>
      </>
    );
  }
}
export default QuickCollectAgtIrp;
