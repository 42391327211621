import React, { Component } from "react";
import Header from "../../header/header";
import axios from "axios";

import { getHeadersShared } from "../../utility/utility";

export default class BusinessRuleSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessRuleSummary: [],
    };
  }

  componentDidMount() {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/businessrules/businessrules`,
        {
          headers: {
            ...getHeadersShared().headers,            
            Authorization:this.props.location.state.token,
          },
        }
      )
      .then((res) => {
        let recBRS = [];
        if (res.data.records !== null) {
          recBRS = res.data.records;
        }
        this.setState({
          businessRuleSummary: recBRS,
        });

        //  this.setState({ businessRuleSummary: res.data.records });
      });
  }
  handleBusRuleMaintenance = () => {
    this.props.history.push("/business-rules-maintenance");
  };

  getBRSHeaders = () => {
    let brsHeader = [
      "Num",
      "Alpha",
      "ISO",
      "Days",
      "New",
      "Repeat",
      "Days",
      "New",
      "Repeat",
      "Num",
      "Days",
      "AVS",
      "CVV",
      "ByPass",
      "RC",
      "Queue",
      "Status",
      "AA",
      "Y/N",
      "Trans",
      "A/A",
      "Def",
      "Experian",
      "IBP",
      "LC",
      "EMAI",
      "EUID",
      "AUTHREV",
    ];
    return brsHeader.map((item, i) => <th key={i} scope="row">{item}</th>);
  };

  render() {
    var date = new Date();
    var currentDate = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return (
      <>
        <Header
          history={this.props.history}
          heading="BUSINESS RULES MAINTENANCE"
        />
        <div className="container-fluid">
          
          <h4 className="text-center main-heading">BUSINESS RULE SUMMARY</h4>
          <h4 className="text-right main-heading">
          Date&nbsp;:&nbsp;{month>9?month:'0'+month}/{currentDate>9?currentDate:'0'+currentDate}/{year.toString().substr(-2)}
          </h4>
          <div className=" overflow-auto ">
            <div className="">
              <table
                className="table table-striped table-hover table-bordered table-sm"
                data-test="table"
              >
                <thead className="thead-dark text-center">
                  <tr>
                    <th scope="col" colSpan="3">
                      Country
                    </th>
                    <th scope="col" colSpan="3">
                      Limit
                    </th>
                    <th scope="col" colSpan="3">
                      Verified Limit
                    </th>
                    <th scope="col" colSpan="2">
                      Velocity
                    </th>
                    <th scope="col" colSpan="2">
                      Request
                    </th>

                    <th scope="col" colSpan="2">
                      HV
                    </th>
                    <th scope="col">HV</th>
                    <th scope="col" colSpan="2">
                      Saturn
                    </th>
                    <th scope="col" colSpan="3">
                      {" "}
                      ID Reqd{" "}
                    </th>
                    <th scope="col" colSpan="3">
                      New Rules{" "}
                    </th>
                    <th scope="col" colSpan="6">
                      Flags{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="thead-dark">{this.getBRSHeaders()}</tr>
                  {this.state.businessRuleSummary.map((el, i) => (
                    <tr key={i} data-test="tr">
                      <td>{el.country.numericCode}</td>
                      <td>{el.country.alphaCode}</td>
                      <td>{el.country.isoCode}</td>
                      <td>{el.limit.days}</td>
                      <td>{el.limit.limitNew}</td>
                      <td>{el.limit.repeat}</td>
                      <td>{el.verifiedLimit.days}</td>
                      <td>{el.verifiedLimit.limitNew}</td>
                      <td>{el.verifiedLimit.repeat}</td>
                      <td>{el.velocity.transaction}</td>
                      <td>{el.velocity.days}</td>
                      <td>{el.request.avs}</td>
                      <td>{el.request.cvv}</td>
                      <td>{el.hv.byp}</td>
                      <td>{el.hv.rc}</td>
                      <td>{el.hv.queue}</td>
                      <td>{el.saturn.status}</td>
                      <td>{el.saturn.autoApprRateCode}</td>
                      <td>{el.idRequest.yn}</td>
                      <td>{el.idRequest.trans}</td>
                      <td>{el.idRequest.aa}</td>
                      <td>{el.newRulesDef}</td>
                      <td>{el.experian}</td>
                      <td>{el.ibpPayment}</td>
                      <td>{el.localCardPayment}</td>
                      <td>{el.emailDetails}</td>
                      <td>{el.euIdDetails}</td>
                      <td>{el.authReversal}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="text-center mar-bottom5">
                <div>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.handleBusRuleMaintenance}
                    data-test="button-business-rule-maintenance"
                  >
                    Business Rule Maintenance
                  </button>
                </div>
              </div>
           
        </div>
      </>
    );
  }
}
