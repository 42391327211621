import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersTipLog, getTiplogData } from "../../utility/utility";

class TipLogSearch extends Component {
  state = {
    searchTypeTLS: "ART",
    searchValueTLS: "Re-file Account Rule",
    searchKeyTLS: "",
    startDateTLS:"",
    endDateTLS: "",
    operatorIdTLS: "",
    tableType: "C",
    pageRecordsTLS: 25,
    pageNumberTLS: 1,
    errorTLS: false,
    errorMessageTLS: "",
    isLoading: false,
    disableStatus: false
  };

  componentDidMount() {

    const accessToken = this.props.authState.accessToken;
    try {
      const token = jwt_decode(accessToken.value);
      const id=jwt_decode(accessToken.value).sub;
      const operatorIdTL = id.replace(/\D/g,'');
      const accessRole = jwt_decode(accessToken.value).role;

      const accessGrantReadTL = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)||
      accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
      accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS);

      
      this.setState({
        accessToken: token,
        token:accessToken.value,
        role: accessRole,
        accessGrantedReadTL: accessGrantReadTL,
        operatorIdTL: operatorIdTL
      }, () => {
        if(!this.state.accessGrantedReadTL) { 
          this.setState({
            errorTLS: 'Error',
            errorMessageTLS: "Unauthorized!Access is denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
    if(this.props.history.location.stateData) {
      this.setState({
        searchTypeTLS: this.props.history.location.stateData.searchTypeTLS,
        searchValueTLS: this.props.history.location.stateData.searchValueTLS,
        disableStatus: this.props.history.location.stateData.disableStatus
      })
    }
  }

  getSearchTypeTipLogSearch = () => {
    let valTLS = [
      { stKey: "ART", stVal: "Re-file Account Rule" },
      { stKey: "FPF", stVal: "Fraud Prevention" },
      { stKey: "FPFC", stVal: "Fraud Prev C2C/Pre" },
      { stKey: "GSP", stVal: "GSP Params" },
      { stKey: "GRP", stVal: "Group" },
      { stKey: "QUE", stVal: "Queue" },
      // { stKey: "RULE", stVal: "Rule" },
      // { stKey: "SITE", stVal: "Site" },
      { stKey: "TMNS", stVal: "TMON System" },
      { stKey: "TMNP", stVal: "TMON Parameters" },
      { stKey: "TMNG", stVal: "TMON Global Params" },
      // { stKey: "CCMT", stVal: "TMON Operator ID" },
      { stKey: "IRP", stVal: "IRP Data" },
      { stKey: "CM03", stVal: "CNTRY Update" },
      { stKey: "CM04", stVal: "CNTRY Account Update" },
      { stKey: "CM06", stVal: "CNTRY Exchange Rates" },
      // { stKey: "CM07", stVal: "CNTRY Fee Update" },
      { stKey: "CM09", stVal: "CNTRY Delivery Address" },
      { stKey: "CM10", stVal: "CNTRY Delayed Delivery Service" },
      { stKey: "CM12", stVal: "CNTRY Payout Curtain" },
      { stKey: "CM13", stVal: "CNTRY Corridor Rules" },
      { stKey: "CM14", stVal: "CNTRY Options" },
      { stKey: "0001", stVal: "Login" },
      { stKey: "0002", stVal: "Customer" },
      { stKey: "0003", stVal: "Transactions" },
      { stKey: "0004", stVal: "Account" },
      { stKey: "0005", stVal: "Customer Account" },
      { stKey: "BRMT", stVal: "BusinessRule Maint" },
      { stKey: "BRPT", stVal: "BusinessRule Partner Email" },
      { stKey: "MRCH", stVal: "BusinessRule Merchant ID" },
      { stKey: "JQCC", stVal: "Quick Collect Client Info" },
      { stKey: "JFUS", stVal: "QC Fusion Info" },
      { stKey: "JDEV", stVal: "QC Device Info" },
      { stKey: "FXDB", stVal: "FX DB Maintenance" },
      // { stKey: "FXSD", stVal: "FX Spreads Maintenance" },
      { stKey: "DQMT", stVal: "Device Queue MTCN" },
      // { stKey: "JOVP", stVal: "OV/VIGO Payer Maint" },
      // { stKey: "FCAL", stVal: "TSC Fire Call Maint" },
      // { stKey: "WFLW", stVal: "Workflow" },
      // { stKey: "QUEA", stVal: "Que Age" },
      { stKey: "PTNR", stVal: "Partner" },
      { stKey: "WUDP", stVal: "Directed Payer" },
      { stKey: "ALL", stVal: "ALL" },
    ];
    return valTLS.map((item) => (
      <option key={item.stKey} value={item.stKey}>
        {item.stVal}
      </option>
    ));
  };

  onChangeControlTLS = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlSearchTypeTLS = (e) => {
    const {options, selectedIndex} = e.target;
    this.setState({
      searchTypeTLS: e.target.value,
      searchValueTLS: options[selectedIndex].innerHTML
    });
  };
 
  onChangeControlOnlyNumberTLS = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^\d]/, ""),
    });
  };

  onHandleAcctLog = () => {
    // this.props.history.push("/account-log-search");
    // console.log(this.state.token)
    this.props.history.push({
      pathname: "/account-log-search",
      authorization: this.state.token,
      userId:this.state.operatorIdTL,
      accessGrantedReadTL: this.state.accessGrantedReadTL
    });
  };

  onValidateTLS = () => {
    if (this.state.startDateTLS !== "" && this.state.endDateTLS !=='' && new Date(this.state.startDateTLS) > new Date(this.state.endDateTLS)) {
      this.setState({
        errorTLS: true,
        errorMessageTLS: "End Date Must be greater or equal to start date ",
      });
      return false;
    }
    
    if (this.state.pageRecordsTLS == "") {
      this.setState({
        errorTLS: true,
        errorMessageTLS: "Enter Page record to be displayed value",
      });
      return false;
    }
    
    return true;
  };

  onHandleSearchTLS = () => {
    if (!this.onValidateTLS()) {
      return;
    }
    // console.log(this.state);
    let submittedObjTLS = {};
    submittedObjTLS.searchType = this.state.searchTypeTLS;
    submittedObjTLS.searchKey = this.state.searchKeyTLS;
    submittedObjTLS.operatorId = this.state.operatorIdTLS;
    submittedObjTLS.startDate = this.state.startDateTLS;
    submittedObjTLS.endDate = this.state.endDateTLS;
    submittedObjTLS.table = this.state.tableType;
    submittedObjTLS.pageRecords = this.state.pageRecordsTLS;
    submittedObjTLS.pageNumber = this.state.pageNumberTLS;

    // console.log(submittedObjTLS);
    this.setState({
      isLoading: true
    });
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_TIPLOG_SERVICE_URL}/v1/sha/dbview/tiplog/logsearch`,submittedObjTLS, 
        {
          headers: {
            ...getHeadersTipLog().headers,
            Authorization: this.state.token,
            userId:this.state.operatorIdTL
          },
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false
        });
        //  console.log(response.data)
        this.props.history.push({
          pathname: "/tip-log-audit-report",
          records: getTiplogData(this.state.searchTypeTLS,response.data),
          submittedData: this.state,
          totalNoOfRecordsTLS: response.data.noOfRecords
        });
      });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="" />
        <div className="container-fluid ">
          <div className="card-body tip-log-search-container">
            <h4 className="card-title main-heading "> TIP Log Search Screen</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                TIP Log Search
              </div>
              <div className="card-body card-body-custom ">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                    <label htmlFor="searchTypeTLS" className="font-weight-bold">Search Type</label>
                      <select
                        className="form-control form-control-sm"
                        name="searchTypeTLS"
                        data-test="text-searchTypeTLS"
                        onChange={(e) => {
                          this.onChangeControlSearchTypeTLS(e);
                        }}
                        value={this.state.searchTypeTLS}
                        disabled={this.state.disableStatus}
                      >
                        {this.getSearchTypeTipLogSearch()}
                      </select>
                    </div>
                    <div className="col-lg">
                      <label htmlFor="searchKeyTLS" className="font-weight-bold">Search Key</label>
                      <input
                        type="text"
                        maxLength="40"
                        value={this.state.searchKeyTLS}
                        className="form-control form-control-sm"
                        name="searchKeyTLS"
                        onChange={(e) => {
                          this.onChangeControlTLS(e);
                        }}
                        data-test="text-searchKeyTLS"
                        placeholder="Search Key"
                      />  
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="startDateTLS" className="font-weight-bold">Start Date</label>
                      <input
                        type="date"
                        value={this.state.startDateTLS}
                        className="form-control form-control-sm"
                        name="startDateTLS"
                        onChange={(e) => {
                          this.onChangeControlTLS(e);
                        }}
                        data-test="text-startDateTLS"
                        placeholder="Start Date"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="endDateTLS" className="font-weight-bold">End Date</label>
                      <input
                        type="date"
                        value={this.state.endDateTLS}
                        className="form-control form-control-sm"
                        name="endDateTLS"
                        onChange={(e) => {
                          this.onChangeControlTLS(e);
                        }}
                        data-test="text-endDateTLS"
                        placeholder="End Date"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <label htmlFor="operatorIdTLS" className="font-weight-bold">Operator Id</label>
                      <input
                        type="text"
                        maxLength="10"
                        value={this.state.operatorIdTLS}
                        className="form-control form-control-sm"
                        name="operatorIdTLS"
                        onChange={(e) => {
                          this.onChangeControlTLS(e);
                        }}
                        data-test="text-operatorIdTLS"
                        placeholder="Operator Id"
                      />
                    </div>
                    <div className="col-lg-4">
                     <legend className="col-form-label font-weight-bold" >Table</legend>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="tableType" value="C" onChange={(e) => {
                          this.onChangeControlTLS(e);
                        }} checked={this.state.tableType === 'C'}
                        data-test="text-tableType-C"
                        />
                        <label className="form-check-label font-weight-bold">Current</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="tableType"  value="H"   onChange={(e) => {
                          this.onChangeControlTLS(e);
                        }} checked={this.state.tableType === 'H'}
                        data-test="text-tableType-H"/>
                        <label className="form-check-label font-weight-bold" >History</label>
                      </div>
                    </div> 
                    <div className="col-lg-4">
                      <label htmlFor="pageRecordsTLS" className="font-weight-bold">Page Records</label>
                      <input
                        type="text"
                        maxLength="4"
                        value={this.state.pageRecordsTLS}
                        className="form-control form-control-sm"
                        name="pageRecordsTLS"
                        onChange={(e) => {
                          this.onChangeControlOnlyNumberTLS(e);
                        }}
                        data-test="text-pageRecordsTLS"
                      />
                    </div>   
                  </div>
                  <div className="form-group row mar-top20 ">
                    <div className="col-lg-4" />
                    <div className="col-lg-4 text-center">
                    {this.state.isLoading ? 
                        <button className="btn btn-warning mar-right5" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          &nbsp;&nbsp;Loading...
                        </button> :
                        !this.state.accessGrantedReadTL ? (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            disabled
                          >
                            Search
                        </button>) :
                        (<button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleSearchTLS}
                            data-test="button-search"
                          >
                            Search
                        </button>
                        )
                     }
                     {
                     !this.state.accessGrantedReadTL ? (
                          <button
                          type="button"
                          className="btn btn-warning "
                          data-test="button-acct-log"
                          disabled
                        >
                          ACCT LOG
                        </button>) :
                        ( <button
                          type="button"
                          className="btn btn-warning "
                          onClick={this.onHandleAcctLog}
                          data-test="button-acct-log"
                        >
                          ACCT LOG
                        </button>
                        )
                        }
                    </div>                   
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                        {this.state.errorTLS && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.errorMessageTLS}
                          </div>
                        )}
                    </div>
                  </div>      
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withOktaAuth(TipLogSearch);
