import React, { Component } from "react";
import { AgGridReact as AgGridReactMtcnList } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AiOutlineZoomIn } from "react-icons/ai";
import { IconContext } from "react-icons";
import Header from "../../header/header";
import { connect } from "react-redux";
import { doSearchMtcn } from "../../../actions/mtcn-search/mtcn-search-action";

class MTCNList extends Component {
  state = {
    columns: [
      {
        headerName: "ACTION",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => this.handleMtcnDetails(params)}
            >
              <IconContext.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiOutlineZoomIn />
              </IconContext.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "70px",
      },
      {
        field: "source",
        headerName: "SOURCE",
        width: "150px"
      },
      {
        field: "mtcn",
        headerName: "MTCN",
        width: "255px"
      },
      {
        field: "principal",
        headerName: "PRINCIPAL",
        width: "150px"
      }
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValMtcnList: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    paginationOptionMtcnList: [25, 50, 100, 500],
    overlayLoadingTemplateMtcnList:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateMtcnList: '<span style="">No Records Found</span>',
  };

  onGridReadyMtcnList = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    //console.log(this.props.searchedData);
    let mtcnList = []
    if(this.props.searchedData.records !== null) {
      mtcnList = this.props.searchedData.records
    }
    this.setState({
      rows: mtcnList,
    });
  };

  handleMtcnDetails = (params) => {
    let submittedObj = {};
    submittedObj.mtcn = params.node.data.mtcn;
    this.props.doSearchMtcn(submittedObj, "mtcn",this.props.history.location.jwtToken).then(() => {
      //console.log(this.props.searchedData);
      if (this.props.searchedData.mtcnFlag === 1) {
        this.props.history.push({
          pathname: "/mt-database-money-transfer-record",
          jwtToken: this.props.history.location.jwtToken,
        });
      } else {
        this.setState({
          error: true,
          errorMessage: this.props.searchedData.message,
        });
      }
      this.onClearMessageMtcnList();
    });
  };

  onClearMessageMtcnList = () => {
    setTimeout(
      () =>
        this.setState({
          error: false,
          errorMessage: "",
        }),
      5000
    );
  };

  onPageSizeChangedMtcnList = (e) => {
    this.setState({ paginationValMtcnList: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackMtcnList = () => {
    this.props.history.push("/money-transfer-database-utility");
  };

  displayMTCNButtonContainer = () => {
    return (
      <div className="mtcn-list-button-container">
        <button
          type="button"
          className="btn btn-warning mar-right10"
          onClick={this.onHandleBackMtcnList}
          disabled={!this.props.history.location.accessGranted}
          data-test="Search-button-back"
        >
          SEARCH
        </button>
        {this.state.error && (
          <div className="alert alert-danger alert-padding search-key-error">
            {this.state.errorMessage}
          </div>
        )}
      </div>
    )
  }
  onChangeControlMtcnList = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="MTCN LIST" />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                ALTERNATE PIN MULTIPLE FIND DETAILS
              </h4>
              {this.displayMTCNButtonContainer()}
              <div className="card mar-top5 mar-bottom5 mtcn-list-container">
                <div className="card-header bg-dark text-white card-header-custom">
                MTCN LIST
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlMtcnList(e);
                        this.onPageSizeChangedMtcnList(e);
                      }}
                      value={this.state.paginationValMtcnList}
                      name="paginationDropdownPartner"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionMtcnList.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 350, width: "100%" }}
                  >
                    <AgGridReactMtcnList
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyMtcnList}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateMtcnList
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateMtcnList
                      }
                    ></AgGridReactMtcnList>
                  </div>
                </div>
              </div>
              {this.displayMTCNButtonContainer()}        
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ MTCNSearchReducer }) => {
  return {
    searchedData: MTCNSearchReducer.dataInfo,
  };
};
export default connect(mapStateToProps, { doSearchMtcn })(MTCNList);
