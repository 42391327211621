import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersShared2 } from "../../utility/utility";
class TmonGlobalParameters extends Component {
  state = {
    initialObject: {},
    generalParameter1: "",
    generalParameter2: "",
    pathFinderIpAddressP1: "",
    pathFinderIpAddressP2: "",
    pathFinderIpAddressP3: "",
    pathFinderIpAddressP4: "",
    tmon03TimeInterVal: "",
    tmon05TimeInterVal: "",
    dmsDownFlag: "",
    rdmsDownFlag: "",
    pinTimeOut: "",
    tmon01DisableFlag: "",
    hostId: "",
    bingoFlag: "",
    ofacIntlQuickCash: "",
    ofacIntlQuickCol: "",
    ofacIntlWcDd: "",
    ofacUsaQuickCash: "",
    ofacUsaQuickCol: "",
    ofacUsaWcDd: "",
    systemOverLimitAmount: "",
    systemTstqLimitAmount: "",
    usMaxPaymentAmount: "",
    fxSpreads: "",
    fxSubsys: "",
    disablePcpWuQue: "",
    duplicateQue: "",
    disableLog: "",
    eventLog: "",
    navyFed: "",

    submitStatusTmonGlobalParameters: null,
    submitMsgTmonGlobalParameters: "",
    isLoading: false
  };
  onChangeControlTmonGlobalParameters = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeControlTmonGlobalParamsNumberOnly = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^\d]/, ""),
    });
  };
  onChangeControlTmonGlobalParamsDecimal = (e) => {
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onHandleBackTmonGlobalParameters = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/tmonglobalparameters`, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        let dataObject = this.arrayToObject(response.data);
        this.onHandleRefreshTmonGlobalParameters(dataObject);
        this.setState({
          initialObject: dataObject,
          generalParameter1: dataObject.generalParameter1,
          generalParameter2: dataObject.generalParameter2,
          pathFinderIpAddressP1: dataObject.pathFinderIpAddressP1,
          pathFinderIpAddressP2: dataObject.pathFinderIpAddressP2,
          pathFinderIpAddressP3: dataObject.pathFinderIpAddressP3,
          pathFinderIpAddressP4: dataObject.pathFinderIpAddressP4,
          tmon03TimeInterVal: dataObject.tmon03TimeInterVal,
          tmon05TimeInterVal: dataObject.tmon05TimeInterVal,
          dmsDownFlag: dataObject.dmsDownFlag,
          rdmsDownFlag:dataObject.rdmsDownFlag,
          pinTimeOut: dataObject.pinTimeOut,
          tmon01DisableFlag: dataObject.tmon01DisableFlag,
          hostId: dataObject.hostId,
          bingoFlag: dataObject.bingoFlag,
          ofacIntlQuickCash: dataObject.ofacIntlQuickCash,
          ofacIntlQuickCol: dataObject.ofacIntlQuickCol,
          ofacIntlWcDd: dataObject.ofacIntlWcDd,
          ofacUsaQuickCash : dataObject.ofacUsaQuickCash,
          ofacUsaQuickCol : dataObject.ofacUsaQuickCol,
          ofacUsaWcDd: dataObject.ofacUsaWcDd,
          systemOverLimitAmount: dataObject.systemOverLimitAmount,
          systemTstqLimitAmount: dataObject.systemTstqLimitAmount,
          usMaxPaymentAmount: dataObject.usMaxPaymentAmount,
          fxSpreads: dataObject.fxSpreads,
          fxSubsys: dataObject.fxSubsys,
          disablePcpWuQue: dataObject.disablePcpWuQue,
          duplicateQue: dataObject.duplicateQue,
          disableLog: dataObject.disableLog,
          eventLog: dataObject.eventLog,
          navyFed: dataObject.navyFed
        });
      });
  }

  arrayToObject = (dataArray) => {
    let records = dataArray.records;
    let dataObject = {};
    for (let index = 0; index < records.length; index++) {
      if (records[index].paramName === "General Parameter 1") {
        dataObject.generalParameter1 = records[index].paramValue;
      } else if (records[index].paramName === "General Parameter2") {
        dataObject.generalParameter2 = records[index].paramValue;
      } else if (records[index].paramName === "Pathfinder IP Addr") {
        dataObject.pathFinderIpAddressP1 = records[index].paramValue;
      } else if (records[index].paramName === "Pathfinder IP Addr2") {
        dataObject.pathFinderIpAddressP2 = records[index].paramValue;
      } else if (records[index].paramName === "Pathfinder IP Addr3") {
        dataObject.pathFinderIpAddressP3 = records[index].paramValue;
      } else if (records[index].paramName === "Pathfinder IP Addr4") {
        dataObject.pathFinderIpAddressP4 = records[index].paramValue;
      } else if (records[index].paramName === "TMON03 Time Interval") {
        dataObject.tmon03TimeInterVal = records[index].paramValue;
      } else if (records[index].paramName === "TMON05 Time Interval") {
        dataObject.tmon05TimeInterVal = records[index].paramValue;
      } else if (records[index].paramName === "DMS Down Flag") {
        dataObject.dmsDownFlag = records[index].paramValue;
      } else if (records[index].paramName === "RDMS Down Flag") {
        dataObject.rdmsDownFlag = records[index].paramValue;
      } else if (records[index].paramName === "PIN Timeout") {
        dataObject.pinTimeOut = records[index].paramValue;
      } else if (records[index].paramName === "TMON01 Disabled Flag") {
        dataObject.tmon01DisableFlag = records[index].paramValue;
      } else if (records[index].paramName === "HOST ID") {
        dataObject.hostId = records[index].paramValue;
      } else if (records[index].paramName === "BINGO Flag") {
        dataObject.bingoFlag = records[index].paramValue;
      } else if (records[index].paramName === "OFAC INTL QUICK CASH") {
        dataObject.ofacIntlQuickCash = records[index].paramValue;
      } else if (records[index].paramName === "OFAC INTL QUICK COL") {
        dataObject.ofacIntlQuickCol = records[index].paramValue;
      } else if (records[index].paramName === "OFAC INTL W/C DD") {
        dataObject.ofacIntlWcDd = records[index].paramValue;
      } else if (records[index].paramName === "OFAC USA QUICK CASH") {
        dataObject.ofacUsaQuickCash = records[index].paramValue;
      } else if (records[index].paramName === "OFAC USA QUICK COL") {
        dataObject.ofacUsaQuickCol = records[index].paramValue;
      } else if (records[index].paramName === "OFAC USA W/C DD") {
        dataObject.ofacUsaWcDd = records[index].paramValue;
      } else if (records[index].paramName === "SYSTEM OVER LIMIT AMT") {
        dataObject.systemOverLimitAmount = records[index].paramValue;
      } else if (records[index].paramName === "SYSTEM TST/Q LIMIT AMT") {
        dataObject.systemTstqLimitAmount = records[index].paramValue;
      } else if (records[index].paramName === "US MAX PAYMENT AMT") {
        dataObject.usMaxPaymentAmount = records[index].paramValue;
      } else if (records[index].paramName === "FX SPREADS") {
        dataObject.fxSpreads = records[index].paramValue;
      } else if (records[index].paramName === "FX SUBSYS") {
        dataObject.fxSubsys = records[index].paramValue;
      } else if (records[index].paramName === "DISABLE PCP/WU QUE") {
        dataObject.disablePcpWuQue = records[index].paramValue;
      } else if (records[index].paramName === "DUPLICATE QUEUE") {
        dataObject.duplicateQue = records[index].paramValue;
      } else if (records[index].paramName === "DISABLE LOG") {
        dataObject.disableLog = records[index].paramValue;
      } else if (records[index].paramName === "EVENT LOG") {
        dataObject.eventLog = records[index].paramValue;
      } else if (records[index].paramName === "NAVY FED") {
        dataObject.navyFed = records[index].paramValue;
      } 
    }
    return dataObject;
  };

  objectToArray = (dataObject) => {
    //console.log(dataObject);
    let resultArray = [];
    if (dataObject.generalParameter1) {
      resultArray.push({
        paramName: "General Parameter 1",
        paramValue: dataObject.generalParameter1,
      });
    }
    if (dataObject.generalParameter2) {
      resultArray.push({
        paramName: "General Parameter2",
        paramValue: dataObject.generalParameter2,
      });
    }
    if (dataObject.pathFinderIpAddressP1) {
      resultArray.push({
        paramName: "Pathfinder IP Addr",
        paramValue: dataObject.pathFinderIpAddressP1,
      });
    }
    if (dataObject.pathFinderIpAddressP2) {
      resultArray.push({
        paramName: "Pathfinder IP Addr2",
        paramValue: dataObject.pathFinderIpAddressP2,
      });
    }
    if (dataObject.pathFinderIpAddressP3) {
      resultArray.push({
        paramName: "Pathfinder IP Addr3",
        paramValue: dataObject.pathFinderIpAddressP3,
      });
    }
    if (dataObject.pathFinderIpAddressP4) {
      resultArray.push({
        paramName: "Pathfinder IP Addr4",
        paramValue: dataObject.pathFinderIpAddressP4,
      });
    }
    if (dataObject.tmon03TimeInterVal) {
      resultArray.push({
        paramName: "TMON03 Time Interval",
        paramValue: dataObject.tmon03TimeInterVal,
      });
    }
    if (dataObject.tmon05TimeInterVal) {
      resultArray.push({
        paramName: "TMON05 Time Interval",
        paramValue: dataObject.tmon05TimeInterVal,
      });
    }
    if (dataObject.dmsDownFlag) {
      resultArray.push({
        paramName: "DMS Down Flag",
        paramValue: dataObject.dmsDownFlag,
      });
    }
    if (dataObject.rdmsDownFlag) {
      resultArray.push({
        paramName: "RDMS Down Flag",
        paramValue: dataObject.rdmsDownFlag,
      });
    }
    if (dataObject.pinTimeOut) {
      resultArray.push({
        paramName: "PIN Timeout",
        paramValue: dataObject.pinTimeOut,
      });
    }
    if (dataObject.tmon01DisableFlag) {
      resultArray.push({
        paramName: "TMON01 Disabled Flag",
        paramValue: dataObject.tmon01DisableFlag,
      });
    }
    if (dataObject.hostId) {
      resultArray.push({
        paramName: "HOST ID",
        paramValue: dataObject.hostId,
      });
    }
    if (dataObject.bingoFlag) {
      resultArray.push({
        paramName: "BINGO Flag",
        paramValue: dataObject.bingoFlag,
      });
    }
    if (dataObject.ofacIntlQuickCash) {
      resultArray.push({
        paramName: "OFAC INTL QUICK CASH",
        paramValue: dataObject.ofacIntlQuickCash,
      });
    }
    if (dataObject.ofacIntlQuickCol) {
      resultArray.push({
        paramName: "OFAC INTL QUICK COL",
        paramValue: dataObject.ofacIntlQuickCol,
      });
    }
    if (dataObject.ofacIntlWcDd) {
      resultArray.push({
        paramName: "OFAC INTL W/C DD",
        paramValue: dataObject.ofacIntlWcDd,
      });
    }
    if (dataObject.ofacUsaQuickCash) {
      resultArray.push({
        paramName: "OFAC USA QUICK CASH",
        paramValue: dataObject.ofacUsaQuickCash,
      });
    }
    if (dataObject.ofacUsaQuickCol) {
      resultArray.push({
        paramName: "OFAC USA QUICK COL",
        paramValue: dataObject.ofacUsaQuickCol,
      });
    }
    if (dataObject.ofacUsaWcDd) {
      resultArray.push({
        paramName: "OFAC USA W/C DD",
        paramValue: dataObject.ofacUsaWcDd,
      });
    }
   
    if (dataObject.systemOverLimitAmount) {
      resultArray.push({
        paramName: "SYSTEM OVER LIMIT AMT",
        paramValue: dataObject.systemOverLimitAmount,
      });
    }
    if (dataObject.systemTstqLimitAmount) {
      resultArray.push({
        paramName: "SYSTEM TST/Q LIMIT AMT",
        paramValue: dataObject.systemTstqLimitAmount,
      });
    }
    if (dataObject.usMaxPaymentAmount) {
      resultArray.push({
        paramName: "US MAX PAYMENT AMT",
        paramValue: dataObject.usMaxPaymentAmount,
      });
    }
    if (dataObject.fxSpreads) {
      resultArray.push({
        paramName: "FX SPREADS",
        paramValue: dataObject.fxSpreads,
      });
    }
    if (dataObject.fxSubsys) {
      resultArray.push({
        paramName: "FX SUBSYS",
        paramValue: dataObject.fxSubsys,
      });
    }
    if (dataObject.disablePcpWuQue) {
      resultArray.push({
        paramName: "DISABLE PCP/WU QUE",
        paramValue: dataObject.disablePcpWuQue,
      });
    }
    if (dataObject.duplicateQue) {
      resultArray.push({
        paramName: "DUPLICATE QUEUE",
        paramValue: dataObject.duplicateQue,
      });
    }
    if (dataObject.disableLog) {
      resultArray.push({
        paramName: "DISABLE LOG",
        paramValue: dataObject.disableLog,
      });
    }
    if (dataObject.eventLog) {
      resultArray.push({
        paramName: "EVENT LOG",
        paramValue: dataObject.eventLog,
      });
    }
    if (dataObject.navyFed) {
      resultArray.push({
        paramName: "NAVY FED",
        paramValue: dataObject.navyFed,
      });
    }
   
    return { records: resultArray };
  };

  onValidateTmonGlobalParameters = () => {
    if (this.state.generalParameter1 === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For Genereal Parameter 1 ",
      });
      return false;
    }
    if (this.state.generalParameter2 === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For Genereal Parameter 2 ",
      });
      return false;
    }
    if (this.state.pathFinderIpAddressP1 === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For IP Address Part1 ",
      });
      return false;
    }
    if (this.state.pathFinderIpAddressP2 === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For IP Address Part2 ",
      });
      return false;
    }
    if (this.state.pathFinderIpAddressP3 === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For IP Address Part3 ",
      });
      return false;
    }
    if (this.state.pathFinderIpAddressP4 === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For IP Address Part4 ",
      });
      return false;
    }
    if (this.state.tmon03TimeInterVal === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For TMON03 Interval ",
      });
      return false;
    }
    if (this.state.tmon05TimeInterVal === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For TMON05 Interval ",
      });
      return false;
    }
    if (this.state.pinTimeOut === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For PIN Timeout ",
      });
      return false;
    }
    if (this.state.hostId === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For HOST ID ",
      });
      return false;
    }
    if (this.state.ofacUsaWcDd === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For OFAC USA Will Call DD ",
      });
      return false;
    }
    if (this.state.ofacIntlWcDd === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For OFAC INT'L Will Call DD ",
      });
      return false;
    }
    if (this.state.ofacIntlQuickCol === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For OFAC INT'L Quick Collect ",
      });
      return false;
    }
    if (this.state.ofacIntlQuickCash === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For OFAC INT'L Quick Cash ",
      });
      return false;
    }
    if (this.state.ofacUsaQuickCol === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For OFAC USA QUICK COL",
      });
      return false;
    }
    if (this.state.ofacUsaQuickCash === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For OFAC USA QUICK CASH",
      });
      return false;
    }
    if (this.state.systemOverLimitAmount === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For valid System over limit amount ",
      });
      return false;
    }
    if (this.state.systemOverLimitAmount !=='' && Number(this.state.systemOverLimitAmount) > 99999.99) {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "System over limit amount should be less than or equal 99999.99",
      });
      return false;
    }

    if (this.state.systemTstqLimitAmount === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For valid System test limit amount",
      });
      return false;
    }
    if (this.state.systemTstqLimitAmount !== "" && Number(this.state.systemTstqLimitAmount) >99999.99) {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "System test limit amount should be less than or equal 99999.99",
      });
      return false;
    }
    if (this.state.usMaxPaymentAmount === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For valid US max pay amount",
      });
      return false;
    }
    if(this.state.usMaxPaymentAmount !== "" && Number(this.state.usMaxPaymentAmount) >99999.99) {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "US max pay amount should be less than or equal 99999.99",
      });
      return false;
    }
    if (this.state.fxSubsys === "") {
      this.setState({
        submitStatusTmonGlobalParameters: "Error",
        submitMsgTmonGlobalParameters: "Please Enter The Value For FX subsys ",
      });
      return false;
    }
    return true;
  };

  onHandleRefreshTmonGlobalParameters = (dataObject) => {
    this.setState({
      generalParameter1: dataObject.generalParameter1,
      generalParameter2: dataObject.generalParameter2,
      pathFinderIpAddressP1: dataObject.pathFinderIpAddressP1,
      pathFinderIpAddressP2: dataObject.pathFinderIpAddressP2,
      pathFinderIpAddressP3: dataObject.pathFinderIpAddressP3,
      pathFinderIpAddressP4: dataObject.pathFinderIpAddressP4,
      tmon03TimeInterVal: dataObject.tmon03TimeInterVal,
      tmon05TimeInterVal: dataObject.tmon05TimeInterVal,
      dmsDownFlag: dataObject.dmsDownFlag,
      rdmsDownFlag:dataObject.rdmsDownFlag,
      pinTimeOut: dataObject.pinTimeOut,
      tmon01DisableFlag: dataObject.tmon01DisableFlag,
      hostId: dataObject.hostId,
      bingoFlag: dataObject.bingoFlag,
      ofacIntlQuickCash: dataObject.ofacIntlQuickCash,
      ofacIntlQuickCol: dataObject.ofacIntlQuickCol,
      ofacIntlWcDd: dataObject.ofacIntlWcDd,
      ofacUsaQuickCash : dataObject.ofacUsaQuickCash,
      ofacUsaQuickCol : dataObject.ofacUsaQuickCol,
      ofacUsaWcDd: dataObject.ofacUsaWcDd,
      systemOverLimitAmount: dataObject.systemOverLimitAmount,
      systemTstqLimitAmount: dataObject.systemTstqLimitAmount,
      usMaxPaymentAmount: dataObject.usMaxPaymentAmount,
      fxSpreads: dataObject.fxSpreads,
      fxSubsys: dataObject.fxSubsys,
      disablePcpWuQue: dataObject.disablePcpWuQue,
      disableLog: dataObject.disableLog,
      eventLog: dataObject.eventLog,
      navyFed: dataObject.navyFed
    });
  };

  onHandleUpdateTmonGlobalParameters = () => {
    if (!this.onValidateTmonGlobalParameters()) {
      return;
    }
    let submittedObj = {};
    submittedObj.generalParameter1 = this.state.generalParameter1;
    submittedObj.generalParameter2 = this.state.generalParameter2;
    submittedObj.pathFinderIpAddressP1 = this.state.pathFinderIpAddressP1;
    submittedObj.pathFinderIpAddressP2 = this.state.pathFinderIpAddressP2;
    submittedObj.pathFinderIpAddressP3 = this.state.pathFinderIpAddressP3;
    submittedObj.pathFinderIpAddressP4 = this.state.pathFinderIpAddressP4;
    submittedObj.tmon03TimeInterVal = this.state.tmon03TimeInterVal;
    submittedObj.tmon05TimeInterVal = this.state.tmon05TimeInterVal;
    submittedObj.dmsDownFlag = this.state.dmsDownFlag;
    submittedObj.rdmsDownFlag = this.state.rdmsDownFlag;
    submittedObj.pinTimeOut = this.state.pinTimeOut;
    submittedObj.tmon01DisableFlag = this.state.tmon01DisableFlag;
    submittedObj.hostId = this.state.hostId;
    submittedObj.bingoFlag = this.state.bingoFlag;
    submittedObj.ofacIntlQuickCash = this.state.ofacIntlQuickCash;
    submittedObj.ofacIntlQuickCol = this.state.ofacIntlQuickCol;
    submittedObj.ofacIntlWcDd = this.state.ofacIntlWcDd;
    submittedObj.ofacUsaQuickCash = this.state.ofacUsaQuickCash;
    submittedObj.ofacUsaQuickCol = this.state.ofacUsaQuickCol;
    submittedObj.ofacUsaWcDd = this.state.ofacUsaWcDd;
    submittedObj.systemOverLimitAmount = this.state.systemOverLimitAmount;
    submittedObj.systemTstqLimitAmount = this.state.systemTstqLimitAmount;
    submittedObj.usMaxPaymentAmount = this.state.usMaxPaymentAmount;
    submittedObj.fxSpreads = this.state.fxSpreads;
    submittedObj.fxSubsys = this.state.fxSubsys;
    submittedObj.disablePcpWuQue = this.state.disablePcpWuQue;
    submittedObj.duplicateQue = this.state.duplicateQue;
    submittedObj.disableLog = this.state.disableLog;
    submittedObj.eventLog = this.state.eventLog;
    submittedObj.navyFed = this.state.navyFed;
    let resultArray = this.objectToArray(submittedObj);
    // console.log(resultArray)
    this.setState({isLoading:true})
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/tmonglobalparameters`,resultArray,{
          headers: {
            ...getHeadersShared2().headers,
            Authorization: this.props.location.state.token,
            "userId":this.props.location.state.operatorIdTMD
          },
       })
      .then((response) => {
        this.setState({isLoading:false})
        if (response.status === 200) {
          this.setState({
            submitStatusTmonGlobalParameters: "OK",
            submitMsgTmonGlobalParameters: response.data.responseString,
          });
        } else {
          this.setState({
            submitStatusTmonGlobalParameters: "Error",
            submitMsgTmonGlobalParameters: response.data.responseString,
          });
        }
        setTimeout(
          () =>
            this.setState({
              submitStatusTmonGlobalParameters: null,
              submitMsgTmonGlobalParameters: ""
            }),
          5000
        );
      });
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WU TMON FILE MAINTENANCE"
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                TMON Global Parameters
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  TMON GLOBAL PARAMETERS
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="generalParameter1" className="font-weight-bold">
                          General Parameter 1
                        </label>
                        <input
                          type="text"                          
                          maxLength="40"
                          value={this.state.generalParameter1}
                          className="form-control form-control-sm"
                          name="generalParameter1"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParameters(e);
                          }}
                          data-test="text-generalParameter1"
                          placeholder="General Parameter 1"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="generalParameter2" className="font-weight-bold">
                          General Parameter 2
                        </label>
                        <input                                                  
                          maxLength="20"
                          type="text"
                          value={this.state.generalParameter2}
                          className="form-control form-control-sm"
                          name="generalParameter2"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParameters(e);
                          }}
                          data-test="text-generalParameter2"
                          placeholder="General Parameter 2"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="pathFinderIpAddressP1" className="font-weight-bold">
                          Pathfinder IP Addr
                        </label>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"                                                        
                              maxLength="3"
                              value={this.state.pathFinderIpAddressP1}
                              className="form-control form-control-sm"
                              name="pathFinderIpAddressP1"
                              onChange={(e) => {
                                this.onChangeControlTmonGlobalParamsNumberOnly(e);
                              }}
                              data-test="text-pathFinderIpAddressP1"
                              placeholder="IP1"
                              disabled={true}
                            />
                          </div>

                          <div className="col-lg">
                            <input
                              type="text"                                                        
                              maxLength="3"
                              value={this.state.pathFinderIpAddressP2}
                              className="form-control form-control-sm"
                              name="pathFinderIpAddressP2"
                              onChange={(e) => {
                                this.onChangeControlTmonGlobalParamsNumberOnly(e);
                              }}
                              data-test="text-pathFinderIpAddressP2"
                              placeholder="IP2"
                              disabled={true}
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"                                                        
                              maxLength="3"
                              value={this.state.pathFinderIpAddressP3}
                              className="form-control form-control-sm"
                              name="pathFinderIpAddressP3"
                              onChange={(e) => {
                                this.onChangeControlTmonGlobalParamsNumberOnly(e);
                              }}
                              data-test="text-pathFinderIpAddressP3"
                              placeholder="IP3"
                              disabled={true}
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"                                                        
                              maxLength="3"
                              value={this.state.pathFinderIpAddressP4}
                              className="form-control form-control-sm"
                              name="pathFinderIpAddressP4"
                              onChange={(e) => {
                                this.onChangeControlTmonGlobalParamsNumberOnly(e);
                              }}
                              data-test="text-pathFinderIpAddressP4"
                              placeholder="IP4"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="tmon03TimeInterVal" className="font-weight-bold">
                          TMON03 Time Interval (Mins)
                        </label>
                        <input
                          type="text"                                                        
                          maxLength="2"
                          value={this.state.tmon03TimeInterVal}
                          className="form-control form-control-sm"
                          name="tmon03TimeInterVal"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-tmon03TimeInterVal"
                          placeholder="TMON03 Time Interval"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="tmon05TimeInterVal" className="font-weight-bold">
                          TMON05 Time Interval (Mins)
                        </label>
                        <input
                          type="text"                                                        
                          maxLength="2"
                          value={this.state.tmon05TimeInterVal}
                          className="form-control form-control-sm"
                          name="tmon05TimeInterVal"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-tmon05TimeInterVal"
                          placeholder="TMON05 Time Interval"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">DMS Down Flag</label>
                        <select
                          className="form-control  form-control-sm"
                          name="dmsDownFlag"
                          value={this.state.dmsDownFlag}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-dmsDownFlag"
                          disabled={true}
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">RDMS Down Flag </label>
                        <select
                          className="form-control  form-control-sm"
                          name="rdmsDownFlag"
                          value={this.state.rdmsDownFlag}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-rdmsDownFlag"
                          disabled={true}
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                        </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="pinTimeOut" className="font-weight-bold">PIN Timeout (Mins)</label>
                        <input
                          type="text"                                                        
                          maxLength="2"
                          value={this.state.pinTimeOut}
                          className="form-control form-control-sm"
                          name="pinTimeOut"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-pinTimeOut"
                          placeholder="PIN Timeout"
                        />
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">TMON01 Disabled Flag </label>
                        <select
                          className="form-control  form-control-sm"
                          name="tmon01DisableFlag"
                          value={this.state.tmon01DisableFlag}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-tmon01DisableFlag"
                          disabled={true}
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="hostId" className="font-weight-bold">HOST ID</label>
                        <input
                          type="text"                                                        
                          maxLength="3"
                          value={this.state.hostId}
                          className="form-control form-control-sm"
                          name="hostId"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParameters(e);
                          }}
                          data-test="text-hostId"
                          placeholder="HOST ID"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">BINGO Flag </label>
                        <select
                          className="form-control  form-control-sm"
                          name="bingoFlag"
                          value={this.state.bingoFlag}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-bingoFlag"
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                        </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="ofacUsaWcDd" className="font-weight-bold">OFAC USA W/C DD</label>
                        <input
                          type="text"                                                                                  
                          maxLength="5"
                          value={this.state.ofacUsaWcDd}
                          className="form-control form-control-sm"
                          name="ofacUsaWcDd"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-ofacUsaWcDd"
                          placeholder="OFAC USA W/C D"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="ofacUsaQuickCol" className="font-weight-bold">
                          OFAC USA QUICK COL
                        </label>
                        <input
                          type="text"                                                                                 
                          maxLength="5"
                          value={this.state.ofacUsaQuickCol}
                          className="form-control form-control-sm"
                          name="ofacUsaQuickCol"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-ofacUsaQuickCol"
                          placeholder="OFAC USA QUICK COL"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="ofacUsaQuickCash" className="font-weight-bold">
                          OFAC USA QUICK CASH
                        </label>
                        <input
                          type="text"                                                                                 
                          maxLength="5"
                          value={this.state.ofacUsaQuickCash}
                          className="form-control form-control-sm"
                          name="ofacUsaQuickCash"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-ofacUsaQuickCash"
                          placeholder="OFAC USA QUICK CASH"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="ofacIntlWcDd" className="font-weight-bold">OFAC INTL W/C DD</label>
                        <input
                          type="text"                                                                                 
                          maxLength="5"
                          value={this.state.ofacIntlWcDd}
                          className="form-control form-control-sm"
                          name="ofacIntlWcDd"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-ofacIntlWcDd"
                          placeholder="OFAC INTL W/C DD"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="ofacIntlQuickCol" className="font-weight-bold">
                          OFAC INTL QUICK COL
                        </label>
                        <input
                          type="text"                                                                                 
                          maxLength="5"
                          value={this.state.ofacIntlQuickCol}
                          className="form-control form-control-sm"
                          name="ofacIntlQuickCol"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-ofacIntlQuickCol"
                          placeholder="OFAC USA QUICK COL"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="ofacIntlQuickCash" className="font-weight-bold">
                          OFAC INTL QUICK CASH
                        </label>
                        <input
                          type="text"                                                                                 
                          maxLength="5"
                          value={this.state.ofacIntlQuickCash}
                          className="form-control form-control-sm"
                          name="ofacIntlQuickCash"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-ofacIntlQuickCash"
                          placeholder="OFAC INTL QUICK CASH"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="systemOverLimitAmount" className="font-weight-bold">
                          SYSTEM OVER LIMIT AMT
                        </label>
                        <input
                          type="text"                                                                                 
                          maxLength="8"
                          value={this.state.systemOverLimitAmount}
                          className="form-control form-control-sm"
                          name="systemOverLimitAmount"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsDecimal(e);
                          }}
                          data-test="text-systemOverLimitAmount"
                          placeholder="SYSTEM OVER LIMIT AMT"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="systemTstqLimitAmount" className="font-weight-bold">
                          SYSTEM TST/Q LIMIT AMT
                        </label>
                        <input
                          type="text"                                                                                
                          maxLength="8"
                          value={this.state.systemTstqLimitAmount}
                          className="form-control form-control-sm"
                          name="systemTstqLimitAmount"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsDecimal(e);
                          }}
                          data-test="text-systemTstqLimitAmount"
                          placeholder="SYSTEM TST/Q LIMIT AMT"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="usMaxPaymentAmount" className="font-weight-bold">
                          US MAX PAYMENT AMT
                        </label>
                        <input
                          type="text"                                                                                
                          maxLength="8"
                          value={this.state.usMaxPaymentAmount}
                          className="form-control form-control-sm"
                          name="usMaxPaymentAmount"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsDecimal(e);
                          }}
                          data-test="text-usMaxPaymentAmount"
                          placeholder="US MAX PAYMENT AMT"
                        />
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">FX SPREADS</label>
                        <select
                          className="form-control  form-control-sm"
                          name="fxSpreads"
                          value={this.state.fxSpreads}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-fxSpreads"
                          disabled={true}
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                          <option value="A">ALL</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="fxSubsys" className="font-weight-bold">FX SUBSYS</label>
                        <input
                          type="text"                                                                                
                          maxLength="1"
                          value={this.state.fxSubsys}
                          className="form-control form-control-sm"
                          name="fxSubsys"
                          onChange={(e) => {
                            this.onChangeControlTmonGlobalParamsNumberOnly(e);
                          }}
                          data-test="text-fxSubsys"
                          placeholder="FX SUBSYS"
                          disabled={true}
                        />
                      </div>

                      <div className="col-lg">
                        <label className="font-weight-bold">DISABLE PCP/WU QUE</label>
                        <select
                          className="form-control  form-control-sm"
                          name="disablePcpWuQue"
                          value={this.state.disablePcpWuQue}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-disablePcpWuQue"
                          disabled={true}
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                        </select>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">DUPLICATE QUEUE</label>
                        <select
                          className="form-control  form-control-sm"
                          name="duplicateQue"
                          value={this.state.duplicateQue}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-duplicateQue"
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold"> DISABLE LOG</label>
                        <select
                          className="form-control  form-control-sm"
                          name="disableLog"
                          value={this.state.disableLog}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-disableLog"
                          disabled={true}
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                        </select>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold"> EVENT LOG</label>
                        <select
                          className="form-control  form-control-sm"
                          name="eventLog"
                          value={this.state.eventLog}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-eventLog"
                          disabled={true}
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                          <option value="T">T</option>
                        </select>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">NAVY FED</label>
                        <select
                          className="form-control  form-control-sm"
                          name="navyFed"
                          value={this.state.navyFed}
                          onChange={this.onChangeControlTmonGlobalParameters}
                          data-test="text-navyFed"
                        >
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4"></div>
              <div className="col-lg-4 text-center mar-bottom5">
                <button
                  type="button"
                  className="btn btn-warning mar-right10"
                  onClick={this.onHandleBackTmonGlobalParameters}
                  data-test="button-back"
                >
                  TMON MAINT
                </button>
                {
                  this.state.isLoading ? 
                    <button className="btn btn-warning mar-right10" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    &nbsp;&nbsp;Loading...
                  </button>
                :
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleUpdateTmonGlobalParameters}
                    data-test="button-update"
                    disabled={!this.props.location.state.accessGrantedWriteTMD1}
                  >
                    UPDATE
                  </button>
                }
                <button
                  type="button"
                  className="btn btn-warning mar-right10"
                  data-test="button-refresh"
                  onClick={() =>
                    this.onHandleRefreshTmonGlobalParameters(
                      this.state.initialObject
                    )
                  }
                >
                  REFRESH
                </button>
                <div className="row mar-top10">
                  
                  <div className="col-lg-12">
                  {this.state.submitStatusTmonGlobalParameters === "OK" && (
                    <div className="alert alert-success alert-padding mar-right17 notification">
                      {this.state.submitMsgTmonGlobalParameters}
                    </div>
                  )}
                  {this.state.submitStatusTmonGlobalParameters === "Error" && (
                    <div className="alert alert-danger alert-padding mar-right17 notification">
                      {this.state.submitMsgTmonGlobalParameters}
                    </div>
                  )}
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-4">
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default TmonGlobalParameters;
