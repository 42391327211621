import React, { Component } from "react";
import { AgGridReact as AgGridReactQTS } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../../header/header";
import {getEstDate} from "../../../utility/utility";

class QueueTransScreen extends Component {
  state = {
    mtcn: "",
    queue: "",
    sender: "",
    senderPhone: "",
    payee: "",
    agent: "",
    agentPhone: "",
    timeStamp: "",
    waitTime: "",
    Country: "",
    principal: "",
    affidavit: "",
    // curTime: new Date().toISOString(),
    curTime:getEstDate(),
    showQueueTransScreenConfirm: false,
    columns: [
      { field: "mtcn", headerName: "MTCN", width: "90px" },
      { field: "queue", headerName: "QUEUE", width: "80px" },
      { field: "sender", headerName: "SENDER", width: "120px" },
      { field: "senderPhone", headerName: "SENDER PHONE", width: "130px" },
      { field: "payee", headerName: "PAYEE", width: "100px" },
      { field: "agent", headerName: "AGENT", width: "100px" },
      { field: "agentPhone", headerName: "AGENT PHONE", width: "125px" },
      { field: "timeStamp", headerName: "TIME-STAMP", width: "140px" },
      { field: "waitTime", headerName: "WAIT-TIME(DAYS)", width: "150px" },
      { field: "country", headerName: "COUNTRY", width: "100px" },
      { field: "principal", headerName: "PRINCIPAL", width: "100px" },
      { field: "affidavit", headerName: "AFFIDAVIT", width: "100px" },
    ],
    rows: [],
    enableBtn: false,
    gridApi: null,
    paginationValQueue: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusQueueTransScreen: null,
    submitMsgQueueTransScreen: "",
    paginationOptionQueueTransScreen: [25, 50, 100, 500],
    overlayLoadingTemplateQTS:
    '<span class="ag-overlay-loading-center">Loading...</span>',
  overlayNoRowsTemplateQTS: '<span style="">No Records Found</span>',
  };
  onChangeControlQueueTransScreen = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onGridReadyQueueTransScreen = (params) => {
    this.setState({
      accessGrantQAWM:this.props.location.state.data.accessGrantQAWM,
      accessGrantWriteQAWM:this.props.location.state.data.accessGrantWriteQAWM,
      operatorIdQAWM:this.props.location.state.data.operatorIdQAWM,
    })
    if (params) {
      this.setState({ gridApi: params.api });
    }
    this.setState({
      rows: this.props.location.state.records,
    });
  };
  onPageSizeChangedQueueTransScreen = (e) => {
    this.setState({ paginationValQueueTrans: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackQueueTransScreen = () => {
    this.props.history.push({
      pathname: "/queue-age-monitor",
      state: { data: this.props.location.state.data },
    });
  };
  onHandlePreviousQueueTransScreen = () => {
    this.props.history.push({
      pathname: "/queue-screen",
      state: { data: this.props.location.state.data },
    });
  };

  onHandleExportQTS = () => {
    var params = {
      fileName: 'queue_transaction.csv',
    };
    this.state.gridApi.exportDataAsCsv(params);
  }

  render() {
    return (
      <>
        <Header history={this.props.history} heading="QUEUE AGE MONITOR" />
        <div
          className="container-fluid"
          data-test="component-queue-trans-screen"
        >
          <div className="card-body">
            <h4 className="card-title main-heading ">
              Queue Transactions Screen
            </h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                Queue Transactions
              </div>
              <div className="card-body card-body-custom">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="pagination-dropdown">
                      <label htmlFor="pagination-select-label">
                        No of records per page:
                      </label>
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          this.onChangeControlQueueTransScreen(e);
                          this.onPageSizeChangedQueueTransScreen(e);
                        }}
                        value={this.state.paginationValQueueTrans}
                        name="paginationDropdownQueueTrans"
                        data-test="select-dropdown"
                      >
                        {this.state.paginationOptionQueueTransScreen.map(
                          (val) => {
                            return (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 text-center">
                    TOTAL RECORDS: {this.state.rows.length}
                  </div>
                  <div className="col-lg-3">DATE/TIME:{this.state.curTime}</div>
                </div>
               </div>           
                <div
                  className="ag-theme-balham"
                  style={{ height: 600, width: "100%" }}
                >
                  <AgGridReactQTS
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyQueueTransScreen}
                    data-test="component-grid"
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateQTS
                    }
                    overlayNoRowsTemplate={
                      this.state.overlayNoRowsTemplateQTS
                    }
                    ensureDomOrder={true}
                  ></AgGridReactQTS>
                </div>
              </div>
              <div className="form-group row ">
                <div className="col-lg text-center">
                  {this.props.location.state.flag && (
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={this.onHandlePreviousQueueTransScreen}
                      data-test="button-previous"
                    >
                      GO TO QUEUES
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-warning mar-right10 mar-top5"
                    onClick={this.onHandleBackQueueTransScreen}
                    data-test="button-back"
                  >
                    GO TO QUEUE GROUPS
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-top5"
                    onClick={this.onHandleExportQTS}
                    data-test="button-export"
                  >
                   Export
                  </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default QueueTransScreen;
