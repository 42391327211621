import React from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersOrder } from "../../utility/utility";

class MoneyTransferUniversalTaxRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorUnitax: false,
      isLoading:true,
      mtutrMsg: '' 
    };
  }

  componentDidMount = () => {
    this.setState({
      mtcn: this.props.history.location.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
    let submittedObj = {};
    submittedObj.mtcn = this.props.history.location.mtcn;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/unitax`,submittedObj, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.history.location.jwtToken
        },
      })
      .then((response) => {
        if(response.data.flag === 1) {
          this.setState({
            mtcn: this.props.history.location.mtcn,
            localityId: response.data.localityId,
            clientID: response.data.clientID,
            productId: response.data.productId,
            taxServerId: response.data.taxServerId,
            taxSWVersion: response.data.WVersion,
            taxDbUVersion: response.data.taxDbUVersion,
            countyName: response.data.countyName,
            outOfCity: response.data.outOfCity,
            consumerPrincipal: response.data.consumerPrincipal,
            stateSalesTax: response.data.stateSalesTax,
            countySalesTax: response.data.countySalesTax,
            citySalesTax: response.data.citySalesTax,
            secCountySalesTax: response.data.secCountySalesTax,
            secCitySalesTax: response.data.secCitySalesTax,
            totalSalesTax: response.data.totalSalesTax,
            completionCode: response.data.completionCode,
            geoCode: response.data.geoCode,
            fipsCode: response.data.fipsCode,
            isLoading: false
          });
        } else {
          this.setState({ errorUnitax: true,isLoading: false, mtutrMsg:response.data.message });
        }
      });
  };

  onHandleBackUniTaxMTCNDetails = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackUniTaxMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">
              Money Transfer Universal Tax Record
            </h4>
            <div className="row mar-top20">
              <div className="col-lg-2" />
              <div className="col-lg-4"><label htmlFor="mtcn" className="padding-left21"><span className="font-weight-bold">MTCN : </span>{this.state.mtcn}</label></div>
              <div className="col-lg-6" />
            </div>
            <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="card-body">
                <div className="card ">
                  <div className="card-header bg-dark text-white card-header-custom">
                    Universal Tax Details
                  </div>
                  <div className="card-body card-body-custom">
                    {
                    this.state.isLoading ? 
                    <div className="text-center">
                      <div className="spinner-border text-warning " role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                    :
                  
                    !this.state.errorUnitax ? 
                    <>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg-4">
                      <span className="font-weight-bold">Locality ID :</span> {this.state.localityId}
                      </div>
                      <div className="col-lg-3">
                      <span className="font-weight-bold">Client ID :</span> {this.state.clientID}
                      </div>
                      <div className="col-lg-3">
                      <span className="font-weight-bold">Product ID :</span> {this.state.productId}
                      </div>
                      <div className="col-lg-1" />
                    </div>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg-4">
                      <span className="font-weight-bold">Tax Server ID :</span> {this.state.taxServerId}
                      </div>
                      <div className="col-lg-3">
                      <span className="font-weight-bold">Tax S/W Version :</span> {this.state.taxSWVersion}
                      </div>
                      <div className="col-lg-3">
                      <span className="font-weight-bold">Tax DB/U Version :</span> {this.state.taxDbUVersion}
                      </div>
                      <div className="col-lg-1" />
                    </div>
                    <div className="mar-top5 mar-bottom5">
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg-3"><span className="font-weight-bold">Consumer Principal :</span></div>
                        <div className="col-lg-1">
                          {this.state.consumerPrincipal}
                        </div>
                        <div className="col-lg-7" />
                      </div>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg-3"><span className="font-weight-bold">State Sales Tax :</span></div>
                        <div className="col-lg-2">
                          {this.state.stateSalesTax}
                        </div>
                        <div className="col-lg-6" />
                      </div>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg-3"><span className="font-weight-bold">Country Sales Tax :</span></div>
                        <div className="col-lg-2">
                          {this.state.countySalesTax}
                        </div>
                        <div className="col-lg-6">
                        <span className="font-weight-bold">Country Name :</span> {this.state.countyName}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg-3"><span className="font-weight-bold">City Sales Tax :</span> </div>
                        <div className="col-lg-2">
                          {this.state.citySalesTax}
                        </div>
                        <div className="col-lg-6">
                        <span className="font-weight-bold">Out of City :</span> {this.state.outOfCity}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg-3"><span className="font-weight-bold">Sec Cnty Sales Tax :</span></div>
                        <div className="col-lg-2">
                          {this.state.secCountySalesTax}
                        </div>
                        <div className="col-lg-6" />
                      </div>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg-3"><span className="font-weight-bold">Sec City Sales Tax :</span></div>
                        <div className="col-lg-2">
                          {this.state.secCitySalesTax}
                        </div>
                        <div className="col-lg-6" />
                      </div>
                      <div className="row">
                        <div className="col-lg-4" />
                        <div className="col-lg-3">-----------</div>
                        <div className="col-lg-5" />
                      </div>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg-3"><span className="font-weight-bold">Total Sales Tax :</span></div>
                        <div className="col-lg-2">
                          {this.state.totalSalesTax}
                        </div>
                        <div className="col-lg-6" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg-3">
                      <span className="font-weight-bold">Completion Code :</span>{this.state.completionCode}
                      </div>
                      <div className="col-lg-3">
                      <span className="font-weight-bold">GeoCode :</span>{this.state.geoCode}
                      </div>
                      <div className="col-lg-3">
                      <span className="font-weight-bold">FIPS Code :</span>{this.state.fipsCode}
                      </div>
                      <div className="col-lg-1" />
                    </div>
                    </>
                    : <div className="row"><div className="col-lg text-center notification">{this.state.mtutrMsg}</div></div>
                    
                    }
                  </div>
                </div>
                <div className="text-center mar-bottom5 mar-top5">
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackUniTaxMTCNDetails}
                    data-test='MTCNDetails-button-back'
                  >
                    MTCN Details
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={this.onHandleBackUniTaxMTCNSearch}
                    data-test='Search-button-back'
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
          </div>
        </div>
      </>
    );
  }
}

export default MoneyTransferUniversalTaxRecord;
