import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactPM } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AiTwotoneEdit } from "react-icons/ai";
import { IconContext } from "react-icons";
import Header from "../header/header";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

import { getHeadersProduct } from "../utility/utility";

class PartnerMaintenance extends Component {
  state = {
    networkId: "",
    payHoldTime: "0",
    storeHoldTime: "0",
    stateCurtain: "N",
    networkCurtain: "N",
    locationCurtain: "N",
    smsEmailReq: " ",
    fxReCalcSW: "N",
    status: 'N',
    showPartnerDetailsConfirm: false,
    columns: [
      {
        headerName: "ACTION",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => this.state.accessGrantWritePM &&this.handleEditPartnerDetails(params)}
            >
              <IconContext.Provider value={{ color: this.state.accessGrantWritePM ?"#FDD017":'#cec9b4', size: "20px" }}>
                <AiTwotoneEdit />
              </IconContext.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "70px",
      },
      {
        field: "networkId",
        headerName: "NETWORK",
        width: "160px",
        sortable: true,
        filter: true,
        floatingFilter: true,
      },
      {
        field: "payHoldTime",
        headerName: "PAY HOLD TIME",
        width: "170px",
        sortable: true,
        filter: true,
        floatingFilter: true,
      },
      {
        field: "storeHoldTime",
        headerName: "	STORE HOLD TIME",
        width: "170px",
        sortable: true,
        filter: true,
        floatingFilter: true,
      },
      {
        field: "locationCurtain",
        headerName: "LOCATION CURTAIN",
        width: "170px",
      },
      {
        field: "stateCurtain",
        headerName: "STATE CURTAIN",
        width: "170px",
      },
      {
        field: "networkCurtain",
        headerName: "NETWORK CURTAIN",
        width: "170px",
      },
      {
        field: "fxReCalcSW",
        headerName: "OPEN INDICATOR",
        width: "153px",
      },
      {
        field: "smsEmailReq",
        headerName: "PTR SMS EMAIL REQUEST",
        width: "153px",
      },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValPartnerDetailsDetails: "25",
    defaultColumnDef: {
      resizable: true,
    },
    submitStatusPartnerDetails: null,
    submitMsgPartnerDetails: "",
    paginationOptionPartnerDetails: [25, 50, 100, 500],
    overlayLoadingTemplatePM:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplatePM: '<span style="">No Records Found</span>',
  };
  componentDidMount(){    
    const accessToken = this.props.authState.accessToken;
    const tokenPM = accessToken.value;
    try {
      const token = jwt_decode(accessToken.value);
      const accessRole = jwt_decode(accessToken.value).role;
      const id=jwt_decode(accessToken.value).sub;
      const operatorIdPM = id.replace(/\D/g,'');
      const accessGrantPM = 
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_PARTNER) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_PARTNER_READ);
      const accessGrantWritePM =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_PARTNER);

      this.setState({
        accessToken: token,
        tokenPM:tokenPM,
        operatorIdPM:operatorIdPM,
        role: accessRole,
        accessGrantPM: accessGrantPM,
        accessGrantWritePM: accessGrantWritePM,
      }, () => {
        if(!this.state.accessGrantPM) { 
          this.setState({
            submitStatusPartnerDetails: 'Error',
            submitMsgPartnerDetails: "Unauthorized!Access Is Denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }
  onChangeControlPartnerDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlPartnerDetailsOnlyNumbers = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^\d]/, ""),
    });
  };

  onChangeControlPartnerDetailsOnlyChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z]/, ""),
    });
  };

  onChangeControlPartnerDetailsCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onGridReadyPartnerDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    if(!this.state.accessGrantPM) {
      this.setState({
        rows:[],
        overlayNoRowsTemplatePM: '<span style="">Unauthorized! Access Is Denied.</span>',
      });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/partnermaintenance/partnerdetails`,
          {
            headers: {
              ...getHeadersProduct().headers,
              Authorization: this.state.tokenPM,
              userId: this.state.operatorIdPM,
            },
          }
        )
        .then((response) => {
          let recPM = [];
          if (response.data.records !== null) {
            recPM = response.data.records;
          }
          this.setState({
            rows: recPM,
          });
        });
    } 
  };
  handleEditPartnerDetails = (params) => {
    let data = params.node.data;
    this.setState({
      networkId: data.networkId,
      payHoldTime: data.payHoldTime,
      storeHoldTime: data.storeHoldTime,
      stateCurtain: data.stateCurtain,
      networkCurtain: data.networkCurtain,
      locationCurtain: data.locationCurtain,
      smsEmailReq: data.smsEmailReq == null ? " " : data.smsEmailReq,
      fxReCalcSW: data.fxReCalcSW,
      status: data.status,
      enableBtn: true,
    });
  };

  onPageSizeChangedPartnerDetails = (e) => {
    this.setState({ paginationValPartnerDetailsDetails: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackPartnerDetails = () => {
    this.props.history.push("/");
  };

  onValidatePartnerDetails = () => {
    if (this.state.networkId === "") {
      this.setState({
        submitStatusPartnerDetails: "Error",
        submitMsgPartnerDetails: "Please Enter Value For Network Details",
      });
      return false;
    }
    // if (this.state.locationCurtain === "") {
    //   this.setState({
    //     submitStatusPartnerDetails: "Error",
    //     submitMsgPartnerDetails: "Please enter Location Curtain",
    //   });
    //   return false;
    // }
    return true;
  };

  onHandleAddPartnerDetails = () => {
    if (!this.onValidatePartnerDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.networkId = this.state.networkId;
    submittedObj.payHoldTime = parseInt(this.state.payHoldTime, 10);
    submittedObj.storeHoldTime = parseInt(this.state.storeHoldTime, 10);
    submittedObj.stateCurtain = this.state.stateCurtain;
    submittedObj.networkCurtain = this.state.networkCurtain;
    submittedObj.locationCurtain = this.state.locationCurtain;
    submittedObj.smsEmailReq = this.state.smsEmailReq;
    submittedObj.fxReCalcSW = this.state.fxReCalcSW;
    submittedObj.status = this.state.status;

    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/partnermaintenance/partnerdetails`, submittedObj, 
      {
        headers: {
          ...getHeadersProduct().headers,
          Authorization: this.state.tokenPM,
          userId: this.state.operatorIdPM,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusPartnerDetails: "OK",
            submitMsgPartnerDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusPartnerDetails: "Error",
            submitMsgPartnerDetails: response.data.responseString,
          });
        }
        this.onHandleClearPartnerDetails();
      });
  };

  onHandleUpdatePartnerDetails = () => {
    if (!this.onValidatePartnerDetails()) {
      return;
    }
    let submittedObj = {}
    submittedObj.networkId = this.state.networkId;
    submittedObj.payHoldTime =  parseInt(this.state.payHoldTime, 10);
    submittedObj.storeHoldTime = parseInt(this.state.storeHoldTime, 10);
    submittedObj.stateCurtain = this.state.stateCurtain;
    submittedObj.networkCurtain = this.state.networkCurtain;
    submittedObj.locationCurtain = this.state.locationCurtain;
    submittedObj.smsEmailReq = this.state.smsEmailReq;
    submittedObj.fxReCalcSW = this.state.fxReCalcSW;
    submittedObj.status = this.state.status;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/partnermaintenance/partnerdetails`, submittedObj, 
      {
        headers: {
          ...getHeadersProduct().headers,
          Authorization: this.state.tokenPM,
          userId: this.state.operatorIdPM,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.networkId === submittedObj.networkId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusPartnerDetails: "OK",
            submitMsgPartnerDetails: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusPartnerDetails: "Error",
            submitMsgPartnerDetails: response.data.responseString,
          });
        }
        this.onHandleClearPartnerDetails();
      });
  };

  onHandleClearPartnerDetails = () => {
    this.setState({
      networkId: "",
      payHoldTime: "0",
      storeHoldTime: "0",
      stateCurtain: "N",
      networkCurtain: "N",
      locationCurtain: "N",
      smsEmailReq: " ",
      fxReCalcSW: "N",
      status: "N",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusPartnerDetails: null,
        }),
      5000
    );
  };

  getPartnerDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showPartnerDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Delete Network Id {this.state.networkId}?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showPartnerDetailsConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeletePartnerDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  getLocationCurtain = () => {
    let curtainOptions = ['A','S','N'];
    let curtainValues = curtainOptions.map((item,i) => (
      <option key={item+i} value={item}>
        {item}
      </option>
    ));
    return curtainValues;
    // return [<option value="">-Select-</option>,curtainValues];
  }

  getPtrSmsEmailReq = () => {
    let smsEmailOption = ['E','S','B'];
    let smsEmailValues = smsEmailOption.map((item,i) => (
      <option key={item+i} value={item}>
        {item}
      </option>
    ));
    return smsEmailValues;
    // return [<option value="">-Select-</option>,smsEmailValues];
  }



  onHandleDeletePartnerDetails = () => {

    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/partnermaintenance/partnerdetails/${this.state.networkId}`, 
      {
        headers: {
          ...getHeadersProduct().headers,
          Authorization: this.state.tokenPM,
          userId: this.state.operatorIdPM,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.networkId !== this.state.networkId;
          });
          this.setState({
            submitStatusPartnerDetails: "OK",
            submitMsgPartnerDetails: response.data.responseString,
            rows: newRows,
          }); 
        } else {
          this.setState({
            submitStatusPartnerDetails: "Error",
            submitMsgPartnerDetails: response.data.responseString,
          });
        }
        this.onHandleClearPartnerDetails();
      });
    this.setState({ showPartnerDetailsConfirm: false });
  };
  render() {
    return this.props.authState.isAuthenticated ?(
      <>
        <Header history={this.props.history} heading="PARTNER MAINTENANCE" />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">PARTNER DETAILS</h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  PARTNER DETAILS FORM
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="networkId" className="font-weight-bold">Network Id</label>
                        <input
                          type="text"
                          maxLength="5"
                          value={this.state.networkId}
                          className="form-control form-control-sm"
                          name="networkId"
                          onChange={(e) => {
                            this.onChangeControlPartnerDetailsCharNumber(e);
                          }}
                          data-test="text-networkId"
                          placeholder="Network Id"
                          disabled={this.state.enableBtn}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="payHoldTime" className="font-weight-bold">Pay Hold Time</label>
                        <input
                          type="text"
                          maxLength="4"
                          value={this.state.payHoldTime}
                          className="form-control form-control-sm"
                          name="payHoldTime"
                          onChange={(e) => {
                            this.onChangeControlPartnerDetailsOnlyNumbers(e);
                          }}
                          data-test="text-payHoldTime"
                          placeholder="Pay Hold Time"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="storeHoldTime" className="font-weight-bold">Store Hold Time</label>
                        <input
                          type="text"
                          maxLength="4"
                          value={this.state.storeHoldTime}
                          className="form-control form-control-sm"
                          name="storeHoldTime"
                          onChange={(e) => {
                            this.onChangeControlPartnerDetailsOnlyNumbers(e);
                          }}
                          data-test="text-storeHoldTime"
                          placeholder="Store Hold Time"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="locationCurtain" className="font-weight-bold">
                          Location Curtain
                        </label>
                        <select
                           value={this.state.locationCurtain}
                            className="form-control form-control-sm"
                            name="locationCurtain"
                            onChange={(e) => {
                              this.onChangeControlPartnerDetails(e);
                            }}
                            data-test="text-locationCurtain"
                          >
                            {this.getLocationCurtain()}
                          </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="stateCurtain" className="font-weight-bold">State Curtain</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="stateCurtain"
                              value="Y"
                              data-test="text-stateCurtain-Yes"
                              checked={this.state.stateCurtain === "Y"}
                              onChange={(e) => {
                                this.onChangeControlPartnerDetails(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="stateCurtain"
                              value="N"
                              data-test="text-stateCurtain"
                              checked={this.state.stateCurtain === "N"}
                              onChange={(e) => {
                                this.onChangeControlPartnerDetails(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="networkCurtain" className="font-weight-bold">Network Curtain</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="networkCurtain"
                              value="Y"
                              data-test="text-networkCurtain-Yes"
                              checked={this.state.networkCurtain === "Y"}
                              onChange={(e) => {
                                this.onChangeControlPartnerDetails(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="networkCurtain"
                              value="N"
                              data-test="text-networkCurtain"
                              checked={this.state.networkCurtain === "N"}
                              onChange={(e) => {
                                this.onChangeControlPartnerDetails(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="fxReCalcSW" className="font-weight-bold">Open Indicator</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="fxReCalcSW"
                              value="Y"
                              data-test="text-fxReCalcSW-Yes"
                              checked={this.state.fxReCalcSW === "Y"}
                              onChange={(e) => {
                                this.onChangeControlPartnerDetails(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="fxReCalcSW"
                              value="N"
                              data-test="text-fxReCalcSW"
                              checked={this.state.fxReCalcSW === "N"}
                              onChange={(e) => {
                                this.onChangeControlPartnerDetails(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="smsEmailReq" className="font-weight-bold">
                        PTR SMS EMAIL REQUEST
                        </label>
                        <select
                           value={this.state.smsEmailReq}
                            className="form-control form-control-sm"
                            name="smsEmailReq"
                            onChange={(e) => {
                              this.onChangeControlPartnerDetails(e);
                            }}
                            data-test="text-smsEmailReq"
                          >
                            <option value=" ">Select</option>
                            {this.getPtrSmsEmailReq()}
                          </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackPartnerDetails}
                          data-test="button-back"
                        >
                          MAINTENANCE MENU
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddPartnerDetails}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.state.accessGrantWritePM || !this.state.accessGrantPM }
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdatePartnerDetails}
                          data-test="button-update"
                          disabled={!this.state.enableBtn}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearPartnerDetails}
                          disabled={!this.state.enableBtn}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showPartnerDetailsConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn}
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                          {this.state.submitStatusPartnerDetails === "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgPartnerDetails}
                            </div>
                          )}
                          {this.state.submitStatusPartnerDetails === "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgPartnerDetails}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getPartnerDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  PARTNER DETAILS DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlPartnerDetails(e);
                        this.onPageSizeChangedPartnerDetails(e);
                      }}
                      value={this.state.paginationValPartnerDetailsDetails}
                      name="paginationDropdownPartner"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionPartnerDetails.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactPM
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyPartnerDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplatePM
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplatePM
                      }
                    ></AgGridReactPM>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ):null;
  }
}

export default  withOktaAuth(PartnerMaintenance);

