import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactWTBCI } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditWTBCI } from "react-icons/ai";
import { IconContext as IconContextWTBCI } from "react-icons";
import {
  getHeadersCustomer,
  getHeadersOrder,
  getFormattedDateForComment,
} from "../../utility/utility";

class WebTmtBankCommentInformation extends Component {
  state = {
    records: [],
    singleData: {},
    customerIdBankId: "",
    customerName: "",
    comment: "",
    updateDate: "",
    operatorId: "",
    mode: "",
    data: {
      bankId: "",
      customerId: "",
      customerNumer: "",
      customerName: "",
    },
    oldComment: "",
    isBankCommentSaveLoading: false,
    isBankCommentBankInfoLoading: false,
    isBankCommentWebTmtLoading: false,
    isBankCommentDeleteLoading: false,
    showWebTmBankCommentInformationConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {
                this.state.accessGrantedWrite &&
                  this.handleEditBankCommentsInfo(params);
              }}
            >
              <IconContextWTBCI.Provider
                value={{
                  color: this.state.accessGrantedWrite ? "#FDD017" : "#cec9b4",
                  size: "20px",
                }}
              >
                <AiTwotoneEditWTBCI />
              </IconContextWTBCI.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "120px",
      },
      { field: "comment", headerName: "Comment", width: "500px" },
      { field: "updateDate", headerName: "Creation Date", width: "270px" },
      { field: "operatorId", headerName: "Operator ID", width: "266px" },
    ],
    rows: null,
    enableBtn: false,
    enableDelBtn: false,
    gridApi: null,
    paginationValError: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusWebTmtBankCommentInfo: null,
    submitMsgWebTmtBankCommentInfo: "",
    paginationOptionWebTmtBankCommentInfo: [25, 50, 100, 500],
    overlayLoadingTemplateWTBCI:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateWTBCI: '<span style="">No Records Found</span>',
  }; 

  getBankCommentsInfo = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            searchKey: this.props.location.state.bankId
              ? this.props.location.state.bankId
              : "",
            Authorization: this.props.location.state.tokenWebTmt ? this.props.location.state.tokenWebTmt : "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.records.length > 0) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
          });
        } else if (response.data.records.length === 0) {
          this.setState({
            records: response.data.records,
          });
        }
      });
  };

  componentDidMount() {
    this.setState({
      tokenWebTmt: this.props.location.state.tokenWebTmt,
      webAdmin: this.props.location.state.webAdmin,
      accessGrantedWrite: this.props.location.state.accessGrantedWrite,
      isAddressDecryprtRequired:
        this.props.location.state.isAddressDecryprtRequired,
      isCreditCardDecryprtRequired:
        this.props.location.state.isCreditCardDecryprtRequired,
      customerIdBankId: this.props.location.state.bankNumber,
      customerName: this.props.location.state.singleData.bankName1,
      bankCommOperatorId: this.props.location.state.operatorId,
    });
    // this.getBankCommentsInfo();
  }

  onChangeWebTmtBankCommentInfo = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value,
        },
      };
    });
  };

  onGridReadyWebTmtBankCommentInfo = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    this.getBankCommentsInfo();
  };

  handleEditBankCommentsInfo = (params) => {
    let data = params.node.data;
    this.setState({
      customerIdBankId: this.state.customerIdBankId,
      customerName: this.state.customerName,
      comment: data.comment,
      updateDate: data.updateDate,
      operatorId: data.operatorId,
      tableKey: data.tableKey,
      oldComment: data.comment,
      enableBtn: true,
      enableDelBtn: this.state.webAdmin ? true : false,
      mode: "edit",
    });
  };

  onPageSizeChangedWebTmtBankCommentInfo = (e) => {
    this.setState({ paginationValError: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onValidateWebTmtBankCommentInfo = () => {
    if (this.state.comment === "") {
      this.setState({
        submitStatusWebTmtBankCommentInfo: "Error",
        submitMsgWebTmtBankCommentInfo: "Please Enter The Value In Comment",
      });
      return false;
    }
    if (this.state.oldComment.trim() === this.state.comment.trim()) {
      this.setState({
        submitStatusWebTmtBankCommentInfo: "Error",
        submitMsgWebTmtBankCommentInfo: "Comment Has Not Been Changed",
      });
      return false;
    }
    return true;
  };

  onHandleUpdateWebTmtBankCommentInfo = () => {
    if (!this.onValidateWebTmtBankCommentInfo()) {
      return;
    }
    this.setState({
      isBankCommentSaveLoading: true,
      enableDelBtn: false
    });
    let submittedObj = {};
    let timestamp = new Date().getUTCMilliseconds();
    submittedObj.customerIdBankId = this.state.customerIdBankId
      ? this.state.customerIdBankId.trim()
      : this.state.customerIdBankId;
    submittedObj.customerName = this.state.customerName;
    submittedObj.comment = this.state.comment;
    submittedObj.updateDate = getFormattedDateForComment(); //this.state.updateDate;
    submittedObj.operatorId = this.state.bankCommOperatorId;

    if (this.state.mode === "edit") {
      submittedObj.tableKey = this.state.tableKey;
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
          submittedObj,
          {
            headers: {
              ...getHeadersCustomer().headers,
              Authorization: this.state.tokenWebTmt
                ? this.state.tokenWebTmt
                : "",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            const updatedRecordIndexWTBCI = this.state.records.findIndex(
              (row) => row.tableKey === submittedObj.tableKey
            );
            const updatedRowsWTBCI = [
              ...this.state.records.slice(0, updatedRecordIndexWTBCI),
              submittedObj,
              ...this.state.records.slice(updatedRecordIndexWTBCI + 1),
            ];
            this.setState({
              submitStatusWebTmtBankCommentInfo: "OK",
              submitMsgWebTmtBankCommentInfo: response.data.responseString,
              //records: [submittedObj, ...this.state.records],
              records: updatedRowsWTBCI,
              mode: "",
              oldComment:""
            });
            this.getBankCommentsInfo();
            this.setState({
              isBankCommentSaveLoading: false,
              enableDelBtn: true
            });
          } else {
            this.setState({
              submitStatusWebTmtBankCommentInfo: "Error",
              submitMsgWebTmtBankCommentInfo: response.data.responseString,
              mode: "",
              isBankCommentSaveLoading: false,
            });
          }
          this.onHandleClearWebTmtBankCommentInfo();
        });
    } else {
      submittedObj.tableKey = timestamp;
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
          submittedObj,
          {
            headers: {
              ...getHeadersCustomer().headers,
              Authorization: this.state.tokenWebTmt
                ? this.state.tokenWebTmt
                : "",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              submitStatusWebTmtBankCommentInfo: "OK",
              submitMsgWebTmtBankCommentInfo: response.data.responseString,
              records: [submittedObj, ...this.state.records],
              mode: "",
              oldComment:""
            });
            //this.getBankCommentsInfo();
            this.setState({
              isBankCommentSaveLoading: false,
            });
          } else {
            this.setState({
              submitStatusWebTmtBankCommentInfo: "Error",
              submitMsgWebTmtBankCommentInfo: response.data.responseString,
              isBankCommentSaveLoading: false,
            });
          }
          this.onHandleClearWebTmtBankCommentInfo();
        });
    }
  };

  onHandleClearWebTmtBankCommentInfo = () => {
    this.setState({
      comment: "",
      updateDate: "",
      operatorId: "",
      enableDelBtn:false,
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusWebTmtBankCommentInfo: null,
          submitMsgWebTmtBankCommentInfo: "",
        }),
      5000
    );
  };

  getWebTmtBankCommentInfoModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showWebTmBankCommentInformationConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Delete This Comment?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showWebTmBankCommentInformationConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteWebTmtBankCommentInfo}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteWebTmtBankCommentInfo = () => {
    if (this.state.tableKey !== "") {
      this.setState({
        isBankCommentDeleteLoading: true,
      });
      axios
        .delete(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
          {
            headers: {
              ...getHeadersCustomer().headers,
              searchKey: this.state.tableKey ? this.state.tableKey : "",
              Authorization: this.state.tokenWebTmt
                ? this.state.tokenWebTmt
                : "",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              submitStatusWebTmtBankCommentInfo: "OK",
              submitMsgWebTmtBankCommentInfo: response.data.responseString,
            });
            this.getBankCommentsInfo();
            this.setState({
              isBankCommentDeleteLoading: false,
              enableDelBtn:false,
              mode:"",
              oldComment:""
            });
          } else {
            this.setState({
              submitStatusWebTmtBankCommentInfo: "Error",
              submitMsgWebTmtBankCommentInfo: response.data.responseString,
              isBankCommentDeleteLoading: false,
            });
          }
          this.onHandleClearWebTmtBankCommentInfo();
          this.setState({ showWebTmBankCommentInformationConfirm: false });
        });
     }//else{
    //   this.setState({
    //     submitStatusWebTmtBankCommentInfo: "Error",
    //     submitMsgWebTmtBankCommentInfo: "Please select comment to delete",
    //     isBankCommentDeleteLoading: false,
    //     enableDelBtn:false,
    //   });
    // }
    this.setState({ showWebTmBankCommentInformationConfirm: false });
  };

  onHandleBackWebTmtMain = () => {
    this.setState({
      isBankCommentWebTmtLoading: true,
    });
    this.props.history.push("/web-tmt-db-maintenance");
    this.setState({
      isBankCommentWebTmtLoading: false,
    });
  };

  onHandleBackBankInfo = () => {
    this.setState({
      isBankCommentBankInfoLoading: true,
    });
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/webtmtdbmaintenance/bankinfo`,
        {
          headers: {
            ...getHeadersOrder().headers,
            bankId: this.state.customerIdBankId
              ? this.state.customerIdBankId
              : "",
            Authorization: this.state.tokenWebTmt ? this.state.tokenWebTmt : "",
          },
        }
      )
      .then((response) => {
        this.setState({
          isBankCommentBankInfoLoading: false,
        });
        if (response.status === 200 && response.data) {
          this.setState({
            singleData: response.data,
            //singleData: response.data.records[0],
            //accountNumber : this.state.customerId
          });
          this.props.history.goBack({
            pathname: "/web-tmt-bank-information",
            state: this.state,
          });
        } else {
          this.setState({
            submitStatusWebTmtBankCommentInfo: "Error",
            submitMsgWebTmtBankCommentInfo: response.data.errMsg,
          });
          return false;
        }
      });
  };

  render() {  
    return (
      <>
        <Header
          history={this.props.history}
          heading="WEB/TMT Database Maintenance"
        />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading">
                WEB/TMT Database Maintenance - BANK COMMENT INFORMATION
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  COMMENT DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <div className="form-group row">
                    <div className="col-lg">
                      <label
                        htmlFor="customerIdBankId"
                        className="font-weight-bold"
                      >
                        Bank Id
                      </label>
                      <input
                        type="text"
                        value={this.state.customerIdBankId}
                        className="form-control form-control-sm"
                        name="customerIdBankId"
                        onChange={(e) => {
                          this.onChangeWebTmtBankCommentInfo(e);
                        }}
                        data-test="text-customerIdBankId"
                        placeholder="Bank Id"
                        disabled
                      />
                    </div>
                    <div className="col-lg">
                      <label
                        htmlFor="customerName"
                        className="font-weight-bold"
                      >
                        Bank Name
                      </label>
                      <input
                        type="text"
                        value={this.state.customerName}
                        className="form-control form-control-sm"
                        name="customerName"
                        onChange={(e) => {
                          this.onChangeWebTmtBankCommentInfo(e);
                        }}
                        data-test="text-customerName"
                        placeholder="Customer Name"
                        disabled
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="updateDate" className="font-weight-bold">
                        Update Date
                      </label>
                      <input
                        type="text"
                        value={this.state.updateDate}
                        className="form-control form-control-sm"
                        name="updateDate"
                        onChange={(e) => {
                          this.onChangeWebTmtBankCommentInfo(e);
                        }}
                        data-test="text-updateDate"
                        placeholder="Update Date"
                        disabled
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="operatorId" className="font-weight-bold">
                        Operator Id{" "}
                      </label>
                      <input
                        type="text"
                        value={this.state.operatorId}
                        className="form-control form-control-sm"
                        name="operatorId"
                        onChange={(e) => {
                          this.onChangeWebTmtBankCommentInfo(e);
                        }}
                        data-test="text-operatorId"
                        placeholder="Operator Id"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="comment" className="font-weight-bold">
                        Comment
                      </label>
                      <input
                        type="text"
                        maxLength="70"
                        value={this.state.comment}
                        className="form-control form-control-sm"
                        name="comment"
                        onChange={(e) => {
                          this.onChangeWebTmtBankCommentInfo(e);
                        }}
                        data-test="text-comment"
                        placeholder="Comment"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      {this.state.isBankCommentWebTmtLoading ? (
                        <button
                          className="btn btn-warning mar-right10"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackWebTmtMain}
                          data-test="button-back"
                          disabled={
                            this.state.isBankCommentSaveLoading === true ||
                            this.state.isBankCommentBankInfoLoading === true ||
                            this.state.isBankCommentWebTmtLoading === true ||
                            this.state.isBankCommentDeleteLoading === true
                          }
                        >
                          WEBTMT MAINT
                        </button>
                      )}
                      {this.state.isBankCommentSaveLoading ? (
                        <button
                          className="btn btn-warning mar-right10"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateWebTmtBankCommentInfo}
                          data-test="button-update"
                          disabled={
                            !this.state.accessGrantedWrite ||
                            this.state.isBankCommentSaveLoading === true ||
                            this.state.isBankCommentBankInfoLoading === true ||
                            this.state.isBankCommentWebTmtLoading === true ||
                            this.state.isBankCommentDeleteLoading === true
                          }
                        >
                          SAVE
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearWebTmtBankCommentInfo}
                        data-test="button-clear"
                      >
                        CLEAR
                      </button>
                      {this.state.isBankCommentDeleteLoading ? (
                        <button
                          className="btn btn-warning mar-right10"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      ) : (
                        <button
                          // disabled={this.state.enableDelBtn ? false : true}
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={() => {
                            this.setState({
                              showWebTmBankCommentInformationConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={
                            this.state.enableDelBtn
                              ? false
                              : true ||
                            this.state.isBankCommentSaveLoading === true ||
                            this.state.isBankCommentBankInfoLoading === true ||
                            this.state.isBankCommentWebTmtLoading === true ||
                            this.state.isBankCommentDeleteLoading === true ||
                            this.state.mode!== "edit"
                          }
                        >
                          DELETE
                        </button>
                      )}
                      {this.state.isBankCommentBankInfoLoading ? (
                        <button
                          className="btn btn-warning mar-right10"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      ) : (
                        <button
                          disabled={
                            //this.state.enableBtn ||
                            this.state.isBankCommentSaveLoading === true ||
                            this.state.isBankCommentBankInfoLoading === true ||
                            this.state.isBankCommentWebTmtLoading === true ||
                            this.state.isBankCommentDeleteLoading === true
                          }
                          type="button"
                          className="btn btn-warning"
                          onClick={this.onHandleBackBankInfo}
                          data-test="button-bankinfo"
                        >
                          BANK INFO
                        </button>
                      )}
                    </div>
                   
                  </div>
                  <div className="form-froup row">
                      <div className="col-lg-4" />
                      <div className="col-lg-4">
                      {this.state.submitStatusWebTmtBankCommentInfo ===
                        "OK" && (
                        <div className="alert alert-success alert-padding notification text-center">
                          {this.state.submitMsgWebTmtBankCommentInfo}
                        </div>
                      )}
                      {this.state.submitStatusWebTmtBankCommentInfo ===
                        "Error" && (
                        <div className="alert alert-danger alert-padding notification text-center">
                          {this.state.submitMsgWebTmtBankCommentInfo}
                        </div>
                      )}
                    </div>
                      </div>
                </div>
              </div>
              {this.getWebTmtBankCommentInfoModal()}
              <div className="card mar-top20">
                {/*<div className="card-header bg-dark text-white card-header-custom">
                  ERROR DETAILS DATA
                </div>*/}
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label
                      htmlFor="pagination-select-label"
                      className="font-weight-bold"
                    >
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeWebTmtBankCommentInfo(e);
                        this.onPageSizeChangedWebTmtBankCommentInfo(e);
                      }}
                      value={this.state.paginationValError}
                      name="paginationDropdownError"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionWebTmtBankCommentInfo.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactWTBCI
                      rowData={this.state.records}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyWebTmtBankCommentInfo}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateWTBCI
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateWTBCI
                      }
                    ></AgGridReactWTBCI>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebTmtBankCommentInformation;
