import React, { Component } from "react";
import { connect } from "react-redux";
import { doSearchMtcn } from "../../../actions/mtcn-search/mtcn-search-action";
import Header from "../../header/header";

class MTDatabaseMoneyTransferRecord extends Component {
  state = {
    showMTCNCancelConfirm: false,
    mtcn: "",
  };

  componentDidMount() {
    // console.log(this.props.history.location.jwtToken);
    if(this.props.location.fromSearch !== 1) {
      let submittedObj = {};
      submittedObj.mtcn = this.props.searchedData.mtcn;
      this.props.doSearchMtcn(submittedObj, "mtcn",this.props.history.location.jwtToken).then(() => {
        // console.log(this.props.searchedData);
        if (this.props.searchedData.mtcnFlag === 1) {
          this.setState({
            mtcn: this.props.searchedData.mtcn,
            jwtToken: this.props.history.location.jwtToken
          });
        }
      });
    }
    

    this.setState({
      mtcn: this.props.searchedData.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
  }

  onHandleTextMTCN = () => {
    this.props.history.push({
      pathname: "/money-transfer-text-record",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleCommentMTCN = () => {
    this.props.history.push({
      pathname: "/money-transfer-comments-record",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleHistoryMTCN = () => {
    this.props.history.push({
      pathname: "/money-transfer-special-handling-record",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleMISC = () => {
    this.props.history.push({
      pathname: "/money-transfer-misc",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleUNIADRMTCN = () => {
    this.props.history.push({
      pathname: "/money-transfer-universal-address",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleUNITAXMTCN = () => {
    this.props.history.push({
      pathname: "/money-transfer-universal-tax-record",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleQueueMTCN = () => {
    this.props.history.push({
      pathname: "/money-transfer-auth-queue-record",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleMISCD = () => {
    this.props.history.push({
      pathname: "/money-transfer-miscd",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleMADREC = () => {
    this.props.history.push({
      pathname: "/money-transfer-madrec",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleMTUB = () => {
    this.props.history.push({
      pathname: "/money-transfer-mtub",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleDFDET = () => {
    this.props.history.push({
      pathname: "/df-receipt-search",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleSPLFUN = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-record-display-utility",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  };

  onHandleSKRET = () => {
    this.props.history.push({
      pathname: "/money-transfer-search-key",
      searchKey: sessionStorage.searchKey,
      searchKeyValue: sessionStorage.searchKeyValue,
      jwtToken: this.state.jwtToken,
      mtcn: this.state.mtcn,
    });
  };

  onHandleBackMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  // onHandleCancelMTCN = () => {

  //   axios
  //     .post(`${process.env.REACT_APP_DB_VIEW_BASE_URL}/money-transfer-utility-cancel`,
  //     getHeadersOrder())
  //     .then((response) => {
  //       if (response.status === 200) {
  //         this.setState({ showMTCNCancelConfirm: false });
  //         this.props.history.push({
  //           pathname: "/money-transfer-database-utility",
  //         });
  //       }
  //     });
  //     this.setState({ showMTCNCancelConfirm: false });
  // };

  // getMTCNCancelModal = () => {
  //   return (
  //     <div>
  //       <Modal
  //         show={this.state.showMTCNCancelConfirm}
  //         animation={true}
  //         size="md"
  //         className="shadow-lg border"
  //       >
  //         <Modal.Header className="bg-dark text-white text-center py-1">
  //           <Modal.Title className="text-center">
  //             <h5 className="modal-header-custom">
  //               Want to cancel the MTCN {this.state.mtcn}?
  //             </h5>
  //           </Modal.Title>
  //         </Modal.Header>
  //         <Modal.Footer className="py-1 d-flex justify-content-center">
  //           <div>
  //             <Button
  //               variant="warning"
  //               onClick={() =>
  //                 this.setState({ showMTCNCancelConfirm: false })
  //               }
  //               data-test="button-no"
  //             >
  //               No
  //             </Button>
  //           </div>
  //           <div>
  //             <Button
  //               variant="warning"
  //               className="mx-2 px-3"
  //               onClick={this.onHandleCancelMTCN}
  //               data-test="button-yes"
  //             >
  //               Yes
  //             </Button>
  //           </div>
  //         </Modal.Footer>
  //       </Modal>
  //     </div>
  //   );
  // };

  getmtDmtrButton = () => {
    // console.log(this.props.searchedData.productCode.df);
    return (
      <div className="row">
        <div className="col-lg">
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-text"
            onClick={this.onHandleTextMTCN}
          >
            TEXT
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-comment"
            onClick={this.onHandleCommentMTCN}
          >
            COMMENT
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-history"
            onClick={this.onHandleHistoryMTCN}
          >
            HISTORY
          </button>
          {/* <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-misc"
            onClick={this.onHandleMISC}
          >
            MISC(0)
          </button> */}
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-unitax"
            onClick={this.onHandleUNITAXMTCN}
          >
            UNITAX
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-uniadr"
            onClick={this.onHandleUNIADRMTCN}
          >
            UNIADR
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-queue"
            onClick={this.onHandleQueueMTCN}
          >
            QUEUE
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-miscd"
            onClick={this.onHandleMISCD}
          >
            MISC
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-madrec"
            onClick={this.onHandleMADREC}
          >
            MADREC
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-mtub"
            onClick={this.onHandleMTUB}
          >
            MTUB
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-splFun"
            onClick={this.onHandleSPLFUN}
          >
            SPLFUN
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-skret"
            onClick={this.onHandleSKRET}
            disabled={
              sessionStorage.searchKeyValue === "undefined" ||
              sessionStorage.searchKeyValue === ""
            }
          >
            SKRET
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-dfdet"
            onClick={this.onHandleDFDET}
            disabled={this.props.searchedData.productCode.df !== "Y"}
          >
            DFDET
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-back"
            onClick={this.onHandleBackMTCNSearch}
          >
            SEARCH
          </button>
          <button
            type="button"
            className="btn btn-warning mtcn-btn"
            data-test="button-cancel"
            // onClick={() => {
            //   this.setState({
            //     showMTCNCancelConfirm: true,
            //   });
            // }}
            disabled={true}
          >
            CANCEL
          </button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div
          className="container-fluid"
          data-test="component-mt-database-money-transfer-record"
        >
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                MT Database Money Transfer Record
              </h4>
              {this.getmtDmtrButton()}
              {/* {this.getMTCNCancelModal()} */}
              <div className="row margin-15">
                <div className="col-lg-2">
                  <strong>MTCN:</strong>
                  {this.props.searchedData.mtcn}
                </div>
                {/* <div className="col-lg">
                  <strong>SOURCE:</strong>
                  {this.props.searchedData.source}
                </div> */}
                <div className="col-lg-1">
                  <strong>PATH:</strong>
                  {this.props.searchedData.path}
                </div>
                <div className="col-lg-2">
                  <strong>Avail:</strong>
                  {this.props.searchedData.avail}
                </div>
                {/* <div className="col-lg">
                  <strong>AREA #:</strong>
                  {this.props.searchedData.area}
                </div> */}
                <div className="col-lg-1">
                  <strong>Bill Type:</strong>
                  {this.props.searchedData.billType}
                </div>
                <div className="col-lg-1">
                  <strong>Status:</strong>
                  {this.props.searchedData.status}
                </div>
                <div className="col-lg-2">
                  <strong>Runa:</strong>
                  {this.props.searchedData.runa}
                </div>
                <div className="col-lg-1">
                  <strong>DivySvc:</strong>
                  {this.props.searchedData.divySvc}
                </div>
                <div className="col-lg-2">
                  <strong>Ownership Flag:</strong>
                  {this.props.searchedData.ownershipFlag}
                </div>
                {/* <div className="col-lg">
                  <strong>DBKey:</strong>
                  {this.props.searchedData.dbKey}
                </div> */}
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Payee Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Receiver : </span>{" "}
                          {this.props.searchedData.payee.receiver}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Addr : </span>
                          {this.props.searchedData.payee.addr}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">City : </span>
                          {this.props.searchedData.payee.city}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Stzip : </span>
                          {this.props.searchedData.payee.stateZip}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Cntry : </span>
                          {this.props.searchedData.payee.country}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Phone : </span>
                          {this.props.searchedData.payee.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Sender Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Name : </span>
                          {this.props.searchedData.sender.name}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">PCN : </span>
                          {this.props.searchedData.sender.pcn}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Addr : </span>
                          {this.props.searchedData.sender.addr}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">City : </span>
                          {this.props.searchedData.sender.city}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">St Zip : </span>
                          {this.props.searchedData.sender.stZip}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Cntry : </span>
                          {this.props.searchedData.sender.cntry}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Phone : </span>
                          {this.props.searchedData.sender.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Queue Indicator
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="col-lg-12">
                        <span className="font-weight-bold">WF : </span>
                        {this.props.searchedData.queueIndicator.wf}
                      </div>
                      <div className="col-lg-12">
                        <span className="font-weight-bold">AAMDBK : </span>
                        {this.props.searchedData.queueIndicator.aamdbk}
                      </div>
                      <div className="col-lg-12">
                        <span className="font-weight-bold">Recln : </span>
                        {this.props.searchedData.queueIndicator.recln}
                      </div>
                      <div className="col-lg-12">
                        <span className="font-weight-bold">Queue : </span>
                        {this.props.searchedData.queueIndicator.queue}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Sending Agent Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Company : </span>
                          {this.props.searchedData.sendingAgent.company}
                        </div>
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Cacct : </span>
                          {this.props.searchedData.sendingAgent.cacct}
                        </div>
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Cycle : </span>
                          {this.props.searchedData.sendingAgent.cycle}
                        </div>
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Ctloff : </span>
                          {this.props.searchedData.sendingAgent.ctlOff}
                        </div>
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Odma : </span>
                          {this.props.searchedData.sendingAgent.odma}
                        </div>
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Ocity : </span>
                          {this.props.searchedData.sendingAgent.ocity}
                        </div>
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Ostzip : </span>
                          {this.props.searchedData.sendingAgent.ostZip}
                        </div>
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Ocntr : </span>
                          {this.props.searchedData.sendingAgent.ocntr}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Recording &amp; Paying Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Recopid : </span>
                          {this.props.searchedData.recordingAndPaying.recOpId}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Rectid : </span>
                          {this.props.searchedData.recordingAndPaying.rectId}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Recsite : </span>
                          {this.props.searchedData.recordingAndPaying.recsite}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Rectd : </span>
                          {this.props.searchedData.recordingAndPaying.rectd}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Payopid : </span>
                          {this.props.searchedData.recordingAndPaying.payOpId}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Paytid : </span>
                          {this.props.searchedData.recordingAndPaying.paytId}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Paysite : </span>
                          {this.props.searchedData.recordingAndPaying.paysite}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Paytd : </span>
                          {this.props.searchedData.recordingAndPaying.paytd}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">SRectid : </span>
                          {this.props.searchedData.recordingAndPaying.srectId}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">SPaytid : </span>
                          {this.props.searchedData.recordingAndPaying.spaytId}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  mar-top5">
                <div className="col-lg">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Country &amp; Pay Agent Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-3">
                          <span className="font-weight-bold">
                            Source Country :{" "}
                          </span>

                          <span className="font-weight-bold">
                            {
                              this.props.searchedData.countryAndPayAgent
                                .sourceCountry
                            }
                          </span>
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">
                            Intended Dest :{" "}
                          </span>
                          <span className="font-weight-bold">
                            {
                              this.props.searchedData.countryAndPayAgent
                                .intendedDest
                            }
                          </span>
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Aaccount : </span>
                          {this.props.searchedData.countryAndPayAgent.aaccount}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Cycle : </span>
                          {this.props.searchedData.countryAndPayAgent.cycle}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Mpayfunds : </span>
                          {this.props.searchedData.countryAndPayAgent.mpayFunds}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Actloff : </span>
                          {this.props.searchedData.countryAndPayAgent.actlOff}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Paycity : </span>
                          {this.props.searchedData.countryAndPayAgent.payCity}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Paystzip : </span>
                          {this.props.searchedData.countryAndPayAgent.payStZip}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">
                            Paycountry :{" "}
                          </span>
                          {
                            this.props.searchedData.countryAndPayAgent
                              .payCountry
                          }
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Mpaydma : </span>
                          {this.props.searchedData.countryAndPayAgent.mpayDma}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Rte : </span>
                          {this.props.searchedData.countryAndPayAgent.rte}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Dinfo : </span>
                          {this.props.searchedData.countryAndPayAgent.dinfo}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">SetSnd : </span>
                          {this.props.searchedData.countryAndPayAgent.setSnd}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Testq : </span>
                          {this.props.searchedData.countryAndPayAgent.testq}
                        </div>
                        <div className="col-lg-3">
                          <span className="font-weight-bold">Tsource : </span>
                          {this.props.searchedData.countryAndPayAgent.tsource}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Sender Financial Info
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Prin : </span>
                          {this.props.searchedData.senderFinancialInfo.prin}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Tolls : </span>
                          {this.props.searchedData.senderFinancialInfo.tolls}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Chgs : </span>
                          {this.props.searchedData.senderFinancialInfo.chgs}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Pchgs : </span>
                          {this.props.searchedData.senderFinancialInfo.pchgs}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Tax1 : </span>
                          {this.props.searchedData.senderFinancialInfo.tax1}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Tax2 : </span>
                          {this.props.searchedData.senderFinancialInfo.tax2}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Tax3 : </span> 
                          {this.props.searchedData.senderFinancialInfo.tax3}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Gross : </span>
                          {this.props.searchedData.senderFinancialInfo.gross}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Payee Financial Info
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Prin : </span>
                          {this.props.searchedData.payeeFinancialInfo.prin}
                        </div>
                        <div className="col-lg-4">
                          {
                            this.props.searchedData.payeeFinancialInfo
                              .mmexprinlit
                          }
                        </div>
                        <div className="col-lg-4">
                          {this.props.searchedData.payeeFinancialInfo.mmexprin}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Cntry : </span>
                          {this.props.searchedData.payeeFinancialInfo.cntry}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Fixed : </span>
                          {this.props.searchedData.payeeFinancialInfo.fixed}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Rate Ind : </span>
                          {this.props.searchedData.payeeFinancialInfo.rateInd}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">FX1 : </span>{this.props.searchedData.payeeFinancialInfo.fx1}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">DSC1 : </span>
                          {this.props.searchedData.payeeFinancialInfo.dsc1}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">FX2 : </span>{this.props.searchedData.payeeFinancialInfo.fx2}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">DSC2 : </span>
                          {this.props.searchedData.payeeFinancialInfo.dsc2}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">ChgType : </span>
                          {this.props.searchedData.payeeFinancialInfo.chgType}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Pseudo Acct : </span>
                          {
                            this.props.searchedData.payeeFinancialInfo
                              .pseudoAcct
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">FX Spread Ind/Key : </span>
                          {
                            this.props.searchedData.payeeFinancialInfo
                              .fxSpreadInd
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">FXCloud : </span>
                          {this.props.searchedData.payeeFinancialInfo.fxCloud}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Product Code &amp; Others
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                        <span className="font-weight-bold">BQC Indicator : </span>
                          {this.props.searchedData.productCode.bqcIndicator}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Send Prod Code : </span>
                          {this.props.searchedData.productCode.sendProdCode}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MQCR : </span>{this.props.searchedData.productCode.mqcr}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MPCUSTCHG : </span>
                          {this.props.searchedData.productCode.mpCustChg}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Delivery : </span>
                          {this.props.searchedData.productCode.delivery}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">PrePay : </span>{this.props.searchedData.productCode.prePay}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Pay Prod Code : </span>
                          {this.props.searchedData.productCode.payProdCode}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Snd Device : </span>
                          {this.props.searchedData.productCode.sndDevice}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Pay Device : </span>
                          {this.props.searchedData.productCode.payDevice}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">EP : </span>{this.props.searchedData.productCode.ep}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MV : </span>{this.props.searchedData.productCode.mv}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Recording PC : </span>
                          {this.props.searchedData.productCode.recordingPC}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Paying PC : </span>
                          {this.props.searchedData.productCode.payingPC}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Agent Commission Base : </span>
                          {
                            this.props.searchedData.productCode
                              .agentCommissionBase
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">QQC Total Charge : </span>
                          {this.props.searchedData.productCode.qqcTotalCharge}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">QQC Client Id : </span>
                          {this.props.searchedData.productCode.qqcClientId}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MProd Pay : </span>
                          {this.props.searchedData.productCode.mprodPay}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">DF : </span>{this.props.searchedData.productCode.df}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">OFAC Indicator : </span>
                          {this.props.searchedData.productCode.ofacIndicator}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">SNDR : </span>{this.props.searchedData.productCode.sndr}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">RCVR : </span>{this.props.searchedData.productCode.rcvr}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">3PTY : </span>{this.props.searchedData.productCode.threePty}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">3RCV : </span>{this.props.searchedData.productCode.threeRrcv}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MANIFON : </span>{this.props.searchedData.productCode.manifon}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MTUB Indicator : </span>
                          {this.props.searchedData.productCode.mtubIndicator}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">SNDC : </span>{this.props.searchedData.productCode.sndc}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">RCVC : </span>{this.props.searchedData.productCode.rcvc}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">PCTL : </span>{this.props.searchedData.productCode.pctl}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">VESTA : </span>{this.props.searchedData.productCode.vesta}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">S&amp;P SND IND : </span>
                          {this.props.searchedData.productCode.spsndInd}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">S&amp;P PAY IND : </span>
                          {this.props.searchedData.productCode.sppayInd}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      MDCACCN CURTAIN BIT
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                        <span className="font-weight-bold">GBS : </span>{this.props.searchedData.mdcaccnCurtainBit.gbs}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">KYC : </span>{this.props.searchedData.mdcaccnCurtainBit.kyc}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">DELAY : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.delay}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">PAY : </span>{this.props.searchedData.mdcaccnCurtainBit.pay}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">STLMNT : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.stlmnt}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">STRHLD : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.strhld}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">APOLLO : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.apollo}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CRHLD : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.crhld}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">RWC : </span>{this.props.searchedData.mdcaccnCurtainBit.rwc}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">RISKQ : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.riskq}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MTIMG : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.mtimg}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">PAYCTL : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.payCtl}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">CAPTURE : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.capture}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">RD IND : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.rdInd}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">PARTBR : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.partbr}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">PFCRCVB : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.pfcrcvb}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">PFCSNDB : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.pfCsndb}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">BITS(01-18) : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.bits01_18}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">BITS(19-36) : </span>
                          {this.props.searchedData.mdcaccnCurtainBit.bits19_36}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Agent Network Info
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Rec Net ID : </span>
                          {this.props.searchedData.agentNetworkInfo.recNetId}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Rec Agnt ID : </span>
                          {this.props.searchedData.agentNetworkInfo.recAgntId}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Rec Addr Type : </span>
                          {this.props.searchedData.agentNetworkInfo.recAddrType}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Pay Net ID : </span>
                          {this.props.searchedData.agentNetworkInfo.payNetId}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Pay Agnt ID : </span>
                          {this.props.searchedData.agentNetworkInfo.payAgntId}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Pay Addr Type : </span>
                          {this.props.searchedData.agentNetworkInfo.payAddrType}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Credit Card Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Credit Card # : </span>
                          {this.props.searchedData.creditCard.creditCardNo}
                        </div>
                        <div className="col-lg-4">
                          <span className="font-weight-bold">Exp Date : </span>
                            {this.props.searchedData.creditCard.expDate}<br/>
                           {this.props.searchedData.creditCard.cryptDisplay} 
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Credit Rating : </span>
                          {this.props.searchedData.creditCard.creditRating}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Auth Cde : </span>{this.props.searchedData.creditCard.authCde}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Cancel Cde : </span>
                          {this.props.searchedData.creditCard.cancelCde}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">MAGNFUNDS : </span>
                          {this.props.searchedData.creditCard.magnFunds}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">PURGE : </span>{this.props.searchedData.creditCard.purge}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  mar-top5 mar-bottom5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Curtain Bits
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-2">
                        <span className="font-weight-bold">REG : </span>{this.props.searchedData.curtainBits.reg}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">REN : </span>{this.props.searchedData.curtainBits.ren}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">SUSP : </span>{this.props.searchedData.curtainBits.susp}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">DEF : </span>{this.props.searchedData.curtainBits.def}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">DELAY : </span>{this.props.searchedData.curtainBits.delay}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">FOS : </span>{this.props.searchedData.curtainBits.fos}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">DDS : </span>{this.props.searchedData.curtainBits.dds}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">D2B : </span>{this.props.searchedData.curtainBits.d2b}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">BB : </span>{this.props.searchedData.curtainBits.bb}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">SZ : </span>{this.props.searchedData.curtainBits.sz}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">AN : </span>{this.props.searchedData.curtainBits.an}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">FR : </span>{this.props.searchedData.curtainBits.fr}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">RN : </span>{this.props.searchedData.curtainBits.rn}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">SN : </span>{this.props.searchedData.curtainBits.sn}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">TSU : </span>{this.props.searchedData.curtainBits.tsu}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">HLD : </span>{this.props.searchedData.curtainBits.hld}
                        </div>
                        <div className="col-lg-2">
                        <span className="font-weight-bold">DDS AVAIL TIME : </span>
                          {this.props.searchedData.curtainBits.ddsAvailTime}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Error Codes
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-8">
                          123456789012345678901234567890123456
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4"><span className="font-weight-bold">MERCODE1 : </span></div>
                        <div className="col-lg-8">
                          {this.props.searchedData.errorCodes.merCode1}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4"><span className="font-weight-bold">MERCODE2 : </span></div>
                        <div className="col-lg-8">
                          {this.props.searchedData.errorCodes.merCode2}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4"><span className="font-weight-bold">MERCODE3 : </span></div>
                        <div className="col-lg-8">
                          {this.props.searchedData.errorCodes.merCode3}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.getmtDmtrButton()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ MTCNSearchReducer }) => {
  return {
    searchedData: MTCNSearchReducer.dataInfo,
  };
};

export default connect(mapStateToProps,{doSearchMtcn})(MTDatabaseMoneyTransferRecord);
