import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactCorridor2B } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { getHeadersGSPS } from "../../../utility/utility";
import { AiTwotoneEdit as AiTwotoneEditCb2 } from "react-icons/ai";
import { IconContext as IconContextCb2 } from "react-icons";

class Corridor2Block extends Component {
  state = {
    corridorblock2id: "",
    blockpay: "",
    account: "",
    isoorigin: "",
    isodestin: "",
    showCorridorBlock2Confirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() =>this.state.accessFlagCorridorBlock2&& this.handleEditCorridor2Block(params)}  
            >
              <IconContextCb2.Provider value={{ color: this.state.accessFlagCorridorBlock2 ?"#FDD017":'#cec9b4', size: "20px" }}>
                <AiTwotoneEditCb2 />
              </IconContextCb2.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      {
        field: "blockpay",
        headerName: "Block Pay",
        width: "224px",
      },
      {
        field: "account",
        headerName: "Account",
        width: "225px",
      },
      {
        field: "isoorigin",
        headerName: "ISO Origin",
        width: "226px",
      },
      {
        field: "isodestin",
        headerName: "ISO Destination",
        width: "222px",
      },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValCorridor2: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusCorridor2Block: null,
    submitMsgCorridor2Block: "",
    paginationOptionCorridor2Block: [25, 50, 100, 500],
    overlayLoadingTemplateCorridor2B:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateCorridor2B: '<span style="">No Records Found</span>',
  };

  onChangeControlCorridor2Block = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlCorridor2BlockOnlyChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z]/, ""),
    });
  };

  onChangeControlCorridor2BlockCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onGridReadyCorridor2Block = (params) => {
    let accessFlagCorridorBlock2 = this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) || this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READWRITE);
    this.setState({accessFlagCorridorBlock2});
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/corridorblock2/getcorridorblock2details`,
        {
          headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken? this.props.jwtToken: "",
          },
        }
      )
      .then((response) => {
        let recCorridor2B = [];
        if (response.data.records !== null) {
          recCorridor2B = response.data.records;
        }
        this.setState({
          rows: recCorridor2B,
        });
      });
  };

  handleEditCorridor2Block = (params) => {
    let corridor2EditData = params.node.data;
    this.setState({
      corridorblock2id: corridor2EditData.account+''+corridor2EditData.isoorigin+''+corridor2EditData.isodestin,
      blockpay: corridor2EditData.blockpay,
      account: corridor2EditData.account,
      isoorigin: corridor2EditData.isoorigin,
      isodestin: corridor2EditData.isodestin,
      enableBtn: true,
    });
  };

  onPageSizeChangedCorridor2Block = (e) => {
    this.setState({ paginationValCorridor2: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackCorridor2Block = () => {
    this.props.history.push("/");
  };

  onValidateCorridor2Block = () => {
    if (this.state.blockpay === "") {
      this.setState({
        submitStatusCorridor2Block: "Error",
        submitMsgCorridor2Block: "Please Enter The Value For Block Pay ",
      });
      return false;
    }
    if (this.state.account === "") {
      this.setState({
        submitStatusCorridor2Block: "Error",
        submitMsgCorridor2Block: "Please Enter The Value For Account ",
      });
      return false;
    }
    if (this.state.isoorigin === "") {
      this.setState({
        submitStatusCorridor2Block: "Error",
        submitMsgCorridor2Block: "Please Enter The Value For ISO Origin ",
      });
      return false;
    }
    if (this.state.isodestin === "") {
      this.setState({
        submitStatusCorridor2Block: "Error",
        submitMsgCorridor2Block: "Please Enter The Value For ISO Destination ",
      });
      return false;
    }
    return true;
  };

  onHandleAddCorridor2Block = () => {
    if (!this.onValidateCorridor2Block()) {
      return;
    }
    let submittedObj = {};
    submittedObj.corridorblock2id = "";
    submittedObj.blockpay = this.state.blockpay;
    submittedObj.account = this.state.account;
    submittedObj.isoorigin = this.state.isoorigin;
    submittedObj.isodestin = this.state.isodestin;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/corridorblock2/addcorridorblock2data`,
        submittedObj,
        {
          headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken? this.props.jwtToken: "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.corridorblock2id = this.state.account +''+this.state.isoorigin+''+this.state.isodestin;
          this.setState({
            submitStatusCorridor2Block: "OK",
            submitMsgCorridor2Block: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          this.onHandleClearCorridor2Block();
        } else {
          this.setState({
            submitStatusCorridor2Block: "Error",
            submitMsgCorridor2Block: response.data.responseString,
          });
          this.onHandleClearCorridor2Block();
        }
      });
  };

  onHandleUpdateCorridor2Block = () => {
    if (!this.onValidateCorridor2Block()) {
      return;
    }
    let submittedObj = {};
    //submittedObj.corridorblock2id = this.state.corridorblock2id;
    submittedObj.blockpay = this.state.blockpay;
    submittedObj.account = this.state.account;
    submittedObj.isoorigin = this.state.isoorigin;
    submittedObj.isodestin = this.state.isodestin;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/corridorblock2/updatecorridorblock2data`,
        submittedObj,
        {
          headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken? this.props.jwtToken: "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => (row.account+''+row.isoorigin+''+row.isodestin) === (this.state.account+''+this.state.isoorigin+''+this.state.isodestin)
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusCorridor2Block: "OK",
            submitMsgCorridor2Block: response.data.responseString,
            rows: updatedRows,
          });
          this.onHandleClearCorridor2Block();
        } else {
          this.setState({
            submitStatusCorridor2Block: "Error",
            submitMsgCorridor2Block: response.data.responseString,
          });
          this.onHandleClearCorridor2Block();
        }
      });
  };

  onHandleClearCorridor2Block = () => {
    this.setState({
      corridorblock2id: "",
      blockpay: "",
      account: "",
      isoorigin: "",
      isodestin: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusCorridor2Block: null,
        }),
      5000
    );
  };

  getCorridorBlock2Modal = () => {
    return (
      <div>
        <Modal
          show={this.state.showCorridorBlock2Confirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete The Block Pay {this.state.blockpay} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showCorridorBlock2Confirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteCorridor2Block}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteCorridor2Block = () => {
    let submittedObj = {};
    submittedObj.account = this.state.account;
    submittedObj.isoorigin = this.state.isoorigin;
    submittedObj.isodestin = this.state.isodestin;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/corridorblock2/deletecorridorblock2data`,
        submittedObj,
        {
          headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken? this.props.jwtToken: "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return (row.account+''+row.isoorigin+''+row.isodestin) !== (submittedObj.account+''+submittedObj.isoorigin+submittedObj.isodestin);
          });

          this.setState({
            submitStatusCorridor2Block: "OK",
            submitMsgCorridor2Block: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearCorridor2Block();
        } else {
          this.setState({
            submitStatusCorridor2Block: "Error",
            submitMsgCorridor2Block: response.data.responseString,
          });
          this.onHandleClearCorridor2Block();
        }
      });
    this.setState({ showCorridorBlock2Confirm: false });
  };

  render() {
    return (
      <div
        className="container-fluid padding-left0"
        data-test="component-corridor-block"
      >
        <div className="card">
          <div className="card-body">
            <h4 className="card-title main-heading ">CORRIDOR BLOCK 2</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                Block Corridor2
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="blockpay" className="font-weight-bold">Block Pay</label>
                      <input
                        type="text"
                        maxLength="1"
                        size="1"
                        value={this.state.blockpay}
                        className="form-control form-control-sm"
                        name="blockpay"
                        onChange={(e) => {
                          this.onChangeControlCorridor2BlockOnlyChar(e);
                        }}
                        data-test="text-blockpay"
                        placeholder="Block Pay"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="account" className="font-weight-bold">Account</label>
                      <input
                        type="text"
                        maxLength="9"
                        size="9"
                        value={this.state.account}
                        className="form-control form-control-sm"
                        name="account"
                        onChange={(e) => {
                          this.onChangeControlCorridor2BlockCharNumber(e);
                        }}
                        data-test="text-account"
                        placeholder="Account"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="isoorigin" className="font-weight-bold">ISO Origin</label>
                      <input
                        type="text"
                        maxLength="3"
                        size="3"
                        value={this.state.isoorigin}
                        className="form-control form-control-sm"
                        name="isoorigin"
                        onChange={(e) => {
                          this.onChangeControlCorridor2BlockOnlyChar(e);
                        }}
                        data-test="text-isoorigin"
                        placeholder="ISO Origin"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="isodestin" className="font-weight-bold">ISO Destination</label>
                      <input
                        type="text"
                        maxLength="3"
                        size="3"
                        value={this.state.isodestin}
                        className="form-control form-control-sm"
                        name="isodestin"
                        onChange={(e) => {
                          this.onChangeControlCorridor2BlockOnlyChar(e);
                        }}
                        data-test="text-isodestin"
                        placeholder="ISO Destination"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                  </div>
                  <div className="form-group row margin-0">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackCorridor2Block}
                        data-test="button-back"
                      >
                        GSP SYS
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddCorridor2Block}
                        data-test="button-add"
                        disabled={this.state.enableBtn || !this.state.accessFlagCorridorBlock2}
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateCorridor2Block}
                        data-test="button-update"
                        disabled={!this.state.enableBtn}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearCorridor2Block}
                        disabled={!this.state.enableBtn}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({ showCorridorBlock2Confirm: true });
                        }}
                        data-test="button-delete"
                        disabled={!this.state.enableBtn}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                        {this.state.submitStatusCorridor2Block === "OK" && (
                          <div className="alert alert-success alert-padding notification">
                            {this.state.submitMsgCorridor2Block}
                          </div>
                        )}
                        {this.state.submitStatusCorridor2Block === "Error" && (
                          <div className="alert alert-danger alert-padding notification">
                            {this.state.submitMsgCorridor2Block}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </form>
              </div>
            </div>
            {this.getCorridorBlock2Modal()}
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                Blocked Corridor2
              </div>
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label" className="font-weight-bold">
                    No of records per page
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlCorridor2Block(e);
                      this.onPageSizeChangedCorridor2Block(e);
                    }}
                    value={this.state.paginationValCorridor2}
                    name="paginationDropdownCorridor2"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionCorridor2Block.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactCorridor2B
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyCorridor2Block}
                    data-test="component-grid"
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateCorridor2B
                    }
                    overlayNoRowsTemplate={
                      this.state.overlayNoRowsTemplateCorridor2B
                    }
                  ></AgGridReactCorridor2B>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Corridor2Block;
