import { 
    MTCN_SEARCH_STARTED,
    MTCN_SEARCH_SUCCESS,
    MTCN_SEARCH_ERROR } from '../actions/mtcn-search/type';

const defaultState = {
    dataInfo: {},
    isLoading: false,
    isError: false,
    error: ''
} 

const MTCNSearchReducer = (state = JSON.parse(JSON.stringify(defaultState)), action = {}) => {

    switch(action.type) {
        case MTCN_SEARCH_STARTED:            
            return {
                ...state,
                isLoading: true
            }
        case MTCN_SEARCH_ERROR:
            return {
                ...state,
                isError: true,
                error: action.payload
            } 
        case MTCN_SEARCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dataInfo: action.payload
            } 
        default:
            return state;
    }
}

export default MTCNSearchReducer;