import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersAgent } from "../../utility/utility";

class MICRPaperSupport extends Component {
  state = {
    chkUtilSel: "1",
    micrpwd: "",
    submitStatusMicrPaperSupport: null,
    submitMsgMicrPaperSupport: "",
    addMicrPaperSupportFlag: false,
    updateMicrPaperSupportFlag: false,
    secondSumbit: true,
    addMicrField:"",
    onHandPaperBalance:"",
  };
  componentDidMount(){
    this.setState({accessGrantWriteCCU:this.props.location.accessGrantWriteCCU,
      operatorIdCCU:this.props.location.operatorIdCCU,
      tokenCCU:this.props.location.tokenCCU})
  }
  onChangeControlMicrPaperSupport = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlMicrPaperSupportOnlyChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z]/, ""),
    });
  };

  onHandleClearMicrPaperSupport = () => {
    setTimeout(
      () =>
        this.setState({
          submitStatusMicrPaperSupport: null,
          chkUtilSel: "1",
          micrpwd: "",
          addMicrPaperSupportFlag: false,
          updateMicrPaperSupportFlag: false,
          secondSumbit: true,
          addMicrField:"",
          onHandPaperBalance:"",
        }),
      5000
    );
  };

  onValidateMicrPaperSupport = () => {
    if (this.state.micrpwd === "" || this.state.micrpwd !=="MICR") {
      this.setState({
        submitStatusMicrPaperSupport: "Error",
        submitMsgMicrPaperSupport: "Please Enter Correct MICR Password",
      });
      return false;
    }
    return true;
  };

  onSecondValidateMicrPaperSupport = () => {
    if (this.state.addMicrPaperSupportFlag && this.state.chkUtilSel ==="1" && this.state.addMicrField === "") {
      this.setState({
        submitStatusMicrPaperSupport: "Error",
        submitMsgMicrPaperSupport: "Please Enter The Value Of MICR Batches",
      });
      return false;
    }
    if (this.state.updateMicrPaperSupportFlag && this.state.chkUtilSel ==="2" && this.state.onHandPaperBalance === "") {
      this.setState({
        submitStatusMicrPaperSupport: "Error",
        submitMsgMicrPaperSupport: "Please Enter The Value Of On-Hand Paper Balance",
      });
      return false;
    }
    return true;
  };

  onHandleSecondSubmitMicrPaperSupport = () => {
    if (!this.onSecondValidateMicrPaperSupport()) {
      return;
    }
    const agentnumber = this.props.location.state;
    if (this.state.chkUtilSel === "1" && this.state.addMicrField) {
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/adjustinventory/${agentnumber}/${this.state.micrpwd}/${this.state.addMicrField}`,
          {},{
            headers: {...getHeadersAgent().headers,Authorization: this.state.tokenCCU,userId: this.state.operatorIdCCU},
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data.responseCode === "200") {
            this.setState({submitStatusMicrPaperSupport: "OK",submitMsgMicrPaperSupport: response.data.responseString});
          } else {
            this.setState({submitStatusMicrPaperSupport: "Error",submitMsgMicrPaperSupport: response.data.responseString});
          }
          this.onHandleClearMicrPaperSupport();
        });
    } else {
      if (this.state.chkUtilSel === "2" && this.state.onHandPaperBalance) {
        axios
          .put(
            `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/adjustinventory/${this.props.location.state}/${this.state.micrpwd}/${this.state.onHandPaperBalance}`,
            {},
            {
              headers: {
                ...getHeadersAgent().headers,
                Authorization: this.state.tokenCCU,
                userId: this.state.operatorIdCCU,
              },
            }
          )
          .then((res) => {
            if (res.status === 200 && res.data.responseCode === "200") {
              this.setState({
                submitStatusMicrPaperSupport: "OK",
                submitMsgMicrPaperSupport: res.data.responseString,
              });
            } else {
              this.setState({
                submitStatusMicrPaperSupport: "Error",
                submitMsgMicrPaperSupport: res.data.responseString,
              });
            }
            this.onHandleClearMicrPaperSupport();
          });
      }
    }
  };

  onHandleSubmitMicrPaperSupport = () => {
    if (!this.onValidateMicrPaperSupport()) {
      return;
    }
    if (this.state.chkUtilSel === "1") {
      this.setState({
        addMicrPaperSupportFlag: true,
        secondSumbit: false,
        submitStatusMicrPaperSupport: null,
      });
    } else {
      if (this.state.chkUtilSel === "2") {
        this.setState({
          updateMicrPaperSupportFlag: true,
          secondSumbit: false,
          submitStatusMicrPaperSupport: null,
        });
      }
    }
  };

  onHandleNewSelectionMicrPaperSupport = () => {
    this.setState({
      chkUtilSel: "1",
      micrpwd: "",
      addMicrField:"",
      addMicrPaperSupportFlag: false,
      updateMicrPaperSupportFlag: false,
      secondSumbit: true,
      submitStatusMicrPaperSupport: null,
      submitMsgMicrPaperSupport: "",
    });
  };

  onHandleBackMicrPaperSupport = () => {
    this.props.history.push({
      pathname: "/check-control-utility",
      // state: { mode: null },
    });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="CHECK CONTROL UTILITY" />
        <div className="container-fluid">
          <h4 className="card-title main-heading ">
            Agent Check Control Utility
          </h4>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  MICR SUPPORT FUNCTIONS
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="row">
                      <div className="col-lg-2" />
                      <div className="col-lg">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="chkUtilSel"
                          value="1"
                          data-test="text-chkUtilSel-1"
                          checked={this.state.chkUtilSel === "1"}
                          onChange={(e) => {
                            this.onChangeControlMicrPaperSupport(e);
                          }}
                          disabled={!this.state.secondSumbit}
                        />
                        <label className="form-check-label font-weight-bold mar-top3" htmlFor="1">
                          ADD PAPER BATCH USE
                        </label>
                      </div>
                      <div className="col-lg">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="chkUtilSel"
                          value="2"
                          data-test="text-chkUtilSel-2"
                          checked={this.state.chkUtilSel === "2"}
                          onChange={(e) => {
                            this.onChangeControlMicrPaperSupport(e);
                          }}
                          disabled={!this.state.secondSumbit}
                        />
                        <label className="form-check-label font-weight-bold mar-top3" htmlFor="2">
                          ADJUST PAPER INVENTORY
                        </label>
                      </div>
                      <div className="col-lg-1" />
                    </div>
                    <div className="row mar-top20">
                      <div className="col-lg-2" />
                      <div className="col-lg">
                        <span className="font-weight-bold">MICR PASSWORD</span>
                        <input
                          type="password"
                          maxLength="4"
                          value={this.state.micrpwd}
                          name="micrpwd"
                          onChange={(e) => {
                            this.onChangeControlMicrPaperSupportOnlyChar(e);
                          }}
                          className="form-control form-control-sm"
                          data-test="text-micrpwd"
                          placeholder="MICR PASSWORD"
                          disabled={!this.state.secondSumbit}
                        />
                      </div>
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="row mar-top20">
                      <div className="col-lg-2" />
                      {this.state.addMicrPaperSupportFlag && (
                        <div className="col-lg-5">
                          <label className="font-weight-bold">ENTER NUMBER OF MICR BATCHES</label>
                          <input
                            type="text"
                            maxLength="1"
                            value={this.state.addMicrField}
                            name="addMicrField"
                            onChange={(e) => {
                              this.onChangeControlMicrPaperSupport(e);
                            }}
                            className="form-control form-control-sm"
                            data-test="text-addMicrField"
                            // placeholder="MICR PASSWORD"
                          /><label className="font-weight-bold">(1 BATCH = 500 MICR PAPER)</label>
                        </div>
                      )}
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="row mar-top20">
                      <div className="col-lg-2" />
                      {this.state.updateMicrPaperSupportFlag && (
                        <div className="col-lg">
                          <label className="font-weight-bold">ENTER ON-HAND PAPER BALANCE</label>
                          <input
                            type="text"
                            maxLength="10"
                            value={this.state.onHandPaperBalance}
                            name="onHandPaperBalance"
                            onChange={(e) => {
                              this.onChangeControlMicrPaperSupport(e);
                            }}
                            className="form-control form-control-sm"
                            data-test="text-onHandPaperBalance"
                            // placeholder="MICR PASSWORD"
                          />
                        </div>
                      )}
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    
                  </form>

                </div>

              </div>

            </div>
            <div className="col-lg-3" />
          </div>

          <div className="row">
            <div className="col-lg-2" />
            
            <div className="col-lg-8">
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  Agent Check Control Utility Details
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg">
                        <label className="font-weight-bold">Agent: {this.props.location.state}</label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          Name: {this.props.location.data.agt_aname}
                        </label>
                      </div>
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          ACHKRD: {this.props.location.data.agt_chkrd}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          ACHKMINOH: {this.props.location.data.agt_chkminoh}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          ACHKCTRLL: {this.props.location.data.agt_chkctrll}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          ACHKTOTOH: {this.props.location.data.agt_chktotoh}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          ACHKTOTUS: {this.props.location.data.agt_chktotus}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          ABANKCODE: {this.props.location.data.agt_bankcode}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          AENCASH: {this.props.location.data.agt_encash}
                        </label>
                      </div>
                      <div className="col-lg" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
          <div className="form-group row">
            <div className="col-lg-3" />
            <div className="col-lg-6 text-center mar-bottom5 mar-top5">
              <button
                type="button"
                className="btn btn-warning mar-right10"
                data-test="button-submit-Agent-Batch-Check-update"
                onClick={
                  this.state.secondSumbit
                    ? () => this.onHandleSubmitMicrPaperSupport()
                    : () => this.onHandleSecondSubmitMicrPaperSupport()
                }
                disabled={!this.state.accessGrantWriteCCU}
                // onClick={() => this.onHandleSubmitMicrPaperSupport()}
              >
                SUBMIT
              </button>
              <button
                type="button"
                className="btn btn-warning  mar-right10"
                data-test="button-clear-Agent-Batch-Check-update"
                onClick={() => this.onHandleNewSelectionMicrPaperSupport()}
              >
                NEW SELECTION
              </button>
              <button
                type="button"
                className="btn btn-warning"
                data-test="button-back-Agent-Batch-Check-update"
                onClick={() => this.onHandleBackMicrPaperSupport()}
              >
                CHK UTILITY
              </button>
            </div>
            <div className="col-lg-3" />
          </div>
          <div className="row">
                      <div className="col-lg-4" />
                      <div className="col-lg-4">
                        {this.state.submitStatusMicrPaperSupport === "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgMicrPaperSupport}
                          </div>
                        )}
                        {this.state.submitStatusMicrPaperSupport ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgMicrPaperSupport}
                          </div>
                        )}
                      </div>

                      <div className="col-lg-4" />
                    </div>
        </div>
      </>
    );
  }
}

export default MICRPaperSupport;
