import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactCB } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AiTwotoneEdit as AiTwotoneEditCb } from "react-icons/ai";
import { IconContext as IconContextCb } from "react-icons";

import { getHeadersGSPS } from "../../../utility/utility";

class CorridorBlock extends Component {
  state = {
    corridorblockid: "",
    blockpay: "",
    network: "",
    isoorigin: "",
    isodestin: "",
    showCorridorBlockConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => this.state.accessFlagCorridorBlock && this.handleEditCorridorBlock(params)}
            >
              <IconContextCb.Provider
                value={{ color: this.state.accessFlagCorridorBlock ?"#FDD017":'#cec9b4', size: "20px" }}y                
              >
                <AiTwotoneEditCb />
              </IconContextCb.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      {
        field: "blockpay",
        headerName: "Block Pay",
        width: "224px",
      },
      {
        field: "network",
        headerName: "Network",
        width: "225px",
      },
      {
        field: "isoorigin",
        headerName: "ISO Origin",
        width: "226px",
      },
      {
        field: "isodestin",
        headerName: "ISO Destination",
        width: "222px",
      },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValCorridor: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusCorridorBlock: null,
    submitMsgCorridorBlock: "",
    paginationOptionCorridorBlock: [25, 50, 100, 500],
    overlayLoadingTemplateCorridorB:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateCorridorB: '<span style="">No Records Found</span>',
  };

  onChangeControlCorridorBlock = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlCorridorBlockOnlyChar = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z]/, ""),
    });
  };

  onChangeControlCorridorBlockCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onGridReadyCorridorBlock = (corridorparams) => {
    let accessFlagCorridorBlock = this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) || this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READWRITE);
    this.setState({accessFlagCorridorBlock});
    if (corridorparams) {
      this.setState({ gridApi: corridorparams.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/corridorblock/getcorridorblockdetails`,
        {
          headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken
              ? this.props.jwtToken
              : "",
          },
        }
      )
      .then((response) => {
        let recCorridorB = [];
        if (response.data.records !== null) {
          recCorridorB = response.data.records;
        }
        this.setState({
          rows: recCorridorB,
        });
      });
  };

  handleEditCorridorBlock = (corridorEditparams) => {
    let corridorEditData = corridorEditparams.node.data;
    this.setState({
      corridorblockid:
        corridorEditData.network +
        "" +
        corridorEditData.isoorigin +
        "" +
        corridorEditData.isodestin,
      blockpay: corridorEditData.blockpay,
      network: corridorEditData.network,
      isoorigin: corridorEditData.isoorigin,
      isodestin: corridorEditData.isodestin,
      enableBtn: true,
    });
  };

  onPageSizeChangedCorridorBlock = (e) => {
    this.setState({ paginationValCorridor: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackCorridorBlock = () => {
    this.props.history.push("/");
  };

  onValidateCorridorBlock = () => {
    if (this.state.blockpay === "") {
      this.setState({
        submitStatusCorridorBlock: "Error",
        submitMsgCorridorBlock: "Please Enter The Value For Block Pay ",
      });
      return false;
    }
    if (this.state.network === "") {
      this.setState({
        submitStatusCorridorBlock: "Error",
        submitMsgCorridorBlock: "Please Enter The Value For Network ",
      });
      return false;
    }
    if (this.state.isoorigin === "") {
      this.setState({
        submitStatusCorridorBlock: "Error",
        submitMsgCorridorBlock: "Please Enter The Value For ISO Origin ",
      });
      return false;
    }
    if (this.state.isodestin === "") {
      this.setState({
        submitStatusCorridorBlock: "Error",
        submitMsgCorridorBlock: "Please Enter The Value For ISO Destination ",
      });
      return false;
    }
    return true;
  };

  onHandleAddCorridorBlock = () => {
    if (!this.onValidateCorridorBlock()) {
      return;
    }
    let submittedObj = {};
    submittedObj.corridorblockid = "";
    submittedObj.blockpay = this.state.blockpay;
    submittedObj.network = this.state.network;
    submittedObj.isoorigin = this.state.isoorigin;
    submittedObj.isodestin = this.state.isodestin;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/corridorblock/addcorridorblockdata`,
        submittedObj,
        {
          headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken
              ? this.props.jwtToken
              : "",
          },
        }
      )
      .then((corridorBlocAddResponse) => {
        if (corridorBlocAddResponse.status === 200 && corridorBlocAddResponse.data.responseString==='Record Added Successfully') {
          submittedObj.corridorblockid =
            this.state.network +
            "" +
            this.state.isoorigin +
            "" +
            this.state.isodestin;
          this.setState({
            submitStatusCorridorBlock: "OK",
            submitMsgCorridorBlock: corridorBlocAddResponse.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusCorridorBlock: "Error",
            submitMsgCorridorBlock: corridorBlocAddResponse.data.responseString,
          });
        }
        this.onHandleClearCorridorBlock();
      });
  };

  onHandleUpdateCorridorBlock = () => {
    if (!this.onValidateCorridorBlock()) {
      return;
    }
    let submittedObj = {};
    submittedObj.blockpay = this.state.blockpay;
    submittedObj.network = this.state.network;
    submittedObj.isoorigin = this.state.isoorigin;
    submittedObj.isodestin = this.state.isodestin;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/corridorblock/updatecorridorblockdata`,
        submittedObj,
        {
          headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken
              ? this.props.jwtToken
              : "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.network + "" + row.isoorigin + "" + row.isodestin ===
              this.state.network +
                "" +
                this.state.isoorigin +
                "" +
                this.state.isodestin
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusCorridorBlock: "OK",
            submitMsgCorridorBlock: response.data.responseString,
            rows: updatedRows,
          });
          this.onHandleClearCorridorBlock();
        } else {
          this.setState({
            submitStatusCorridorBlock: "Error",
            submitMsgCorridorBlock: response.data.responseString,
          });
          this.onHandleClearCorridorBlock();
        }
      });
  };

  onHandleClearCorridorBlock = () => {
    this.setState({
      corridorblockid: "",
      blockpay: "",
      network: "",
      isoorigin: "",
      isodestin: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusCorridorBlock: null,
        }),
      5000
    );
  };

  getCorridorBlockModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showCorridorBlockConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Delete The Block Pay {this.state.blockpay} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showCorridorBlockConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteCorridorBlock}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteCorridorBlock = () => {
    let submittedObj = {};
    submittedObj.network = this.state.network;
    submittedObj.isoorigin = this.state.isoorigin;
    submittedObj.isodestin = this.state.isodestin;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/corridorblock/deletecorridorblockdata`,
        submittedObj,
        {
          headers: {
            ...getHeadersGSPS().headers,
            Authorization: this.props.jwtToken
              ? this.props.jwtToken
              : "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return (
              row.network + "" + row.isoorigin + "" + row.isodestin !==
              submittedObj.network +
                "" +
                submittedObj.isoorigin +
                submittedObj.isodestin
            );
          });

          this.setState({
            submitStatusCorridorBlock: "OK",
            submitMsgCorridorBlock: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearCorridorBlock();
        } else {
          this.setState({
            submitStatusCorridorBlock: "Error",
            submitMsgCorridorBlock: response.data.responseString,
          });
          this.onHandleClearCorridorBlock();
        }
      });
    this.setState({ showCorridorBlockConfirm: false });
  };

  render() {
    return (
      <div
        className="container-fluid padding-left0"
        data-test="component-corridor-block"
      >
        <div className="card">
          <div className="card-body">
            <h4 className="card-title main-heading ">CORRIDOR BLOCK</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                Block Corridor
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="blockpay" className="font-weight-bold">Block Pay</label>
                      <input
                        type="text"
                        maxLength="1"
                        size="1"
                        value={this.state.blockpay}
                        className="form-control form-control-sm"
                        name="blockpay"
                        onChange={(e) => {
                          this.onChangeControlCorridorBlockOnlyChar(e);
                        }}
                        data-test="text-blockpay"
                        placeholder="Block Pay"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="network" className="font-weight-bold">Network</label>
                      <input
                        type="text"
                        maxLength="5"
                        size="5"
                        value={this.state.network}
                        className="form-control form-control-sm"
                        name="network"
                        onChange={(e) => {
                          this.onChangeControlCorridorBlockCharNumber(e);
                        }}
                        data-test="text-network"
                        placeholder="Network"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="isoorigin" className="font-weight-bold">ISO Origin</label>
                      <input
                        type="text"
                        maxLength="3"
                        size="3"
                        value={this.state.isoorigin}
                        className="form-control form-control-sm"
                        name="isoorigin"
                        onChange={(e) => {
                          this.onChangeControlCorridorBlockOnlyChar(e);
                        }}
                        data-test="text-isoorigin"
                        placeholder="ISO Origin"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="isodestin" className="font-weight-bold">ISO Destination</label>
                      <input
                        type="text"
                        maxLength="3"
                        size="3"
                        value={this.state.isodestin}
                        className="form-control form-control-sm"
                        name="isodestin"
                        onChange={(e) => {
                          this.onChangeControlCorridorBlockOnlyChar(e);
                        }}
                        data-test="text-isodestin"
                        placeholder="ISO Destination"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                  </div>
                  <div className="form-group row margin-0">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackCorridorBlock}
                        data-test="button-back"
                      >
                        GSP SYS
                      </button>
                      
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        disabled={this.state.enableBtn || !this.state.accessFlagCorridorBlock}
                        onClick={this.onHandleAddCorridorBlock}
                        data-test="button-add"
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        disabled={!this.state.enableBtn}
                        onClick={this.onHandleUpdateCorridorBlock}
                        data-test="button-update"
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        disabled={!this.state.enableBtn}
                        onClick={this.onHandleClearCorridorBlock}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({ showCorridorBlockConfirm: true });
                        }}
                        disabled={!this.state.enableBtn}
                        data-test="button-delete"
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                          {this.state.submitStatusCorridorBlock === "OK" && (
                            <div className="alert alert-success alert-padding notification text-center">
                              {this.state.submitMsgCorridorBlock}
                            </div>
                          )}
                          {this.state.submitStatusCorridorBlock === "Error" && (
                            <div className="alert alert-danger alert-padding notification text-center">
                              {this.state.submitMsgCorridorBlock}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </form>
              </div>
            </div>
            {this.getCorridorBlockModal()}
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                Blocked Corridor
              </div>
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label" className="font-weight-bold">
                    No of records per page
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlCorridorBlock(e);
                      this.onPageSizeChangedCorridorBlock(e);
                    }}
                    value={this.state.paginationValCorridor}
                    name="paginationDropdownCorridor"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionCorridorBlock.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactCB
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyCorridorBlock}
                    data-test="component-grid"
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateCorridorB
                    }
                    overlayNoRowsTemplate={
                      this.state.overlayNoRowsTemplateCorridorB
                    }
                  ></AgGridReactCB>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CorridorBlock;
