import React from "react";
import { connect } from "react-redux";
import { doSearchMtcn } from "../../../actions/mtcn-search/mtcn-search-action";
import Header from "../../header/header";
import { getValidData } from "../../utility/utility";

class MoneyTransferSearchKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      singleObject: {},
      searchKey: '',
      searchKeyValue: "",
      count: 1,
      enablePrev: false,
      enableNext: true,
      recordType: null,
      totalNumberOfRecords:0,
      currentPageNo:0
    };
  }

  componentDidMount() {
    //console.log(sessionStorage.searchKeyValue);
    // console.log("Coming directly")
    if (this.props.searchedData.skFlag === 1) {
      // console.log("not from mtcn details page");
      // console.log(this.props.searchedData);
      let tempEnableNext1 =
        this.props.searchedData.totalNumberOfRecords > 1 ? true : false;

      // console.log("this.props.searchedData.skKey    "+this.props.searchedData.skKey);
      // console.log("sessionStorage.searchKeyValue    "+sessionStorage.searchKeyValue);
      this.setState({
        searchKey: sessionStorage.searchKey,
        searchKeyValue: sessionStorage.searchKeyValue, //Need to send original search key
        allData: this.props.searchedData.records,
        singleObject: this.props.searchedData.records[0],
        enableNext: tempEnableNext1,
        recordType: this.props.searchedData.recordType,
        totalNumberOfRecords: this.props.searchedData.totalNumberOfRecords,
        mtcn: ""
      });
    } else {
      // console.log("Coming indirectly")
      // let tempEnableNext1 =
      // this.props.searchedData.totalNumberOfRecords > 1 ? true : false;

      // console.log("tempEnableNext1"+tempEnableNext1);
      let submittedObj = {};
      let group = "";
      // let errorMessageTemp = "";

      submittedObj.searchKey = sessionStorage.searchKey;
      submittedObj.searchKeyValue = sessionStorage.searchKeyValue;
      submittedObj.mtcn =  this.props.history.location.mtcn;
      submittedObj.pageNumber =  1;
      // console.log(submittedObj);
      // errorMessageTemp = `Invalid ${submittedObj.searchKey}`;
      group = "skrecord";

      if (Object.keys(submittedObj).length > 0) {
        //console.log(group);
        this.props.doSearchMtcn(submittedObj, group, this.props.history.location.jwtToken).then(() => {
          // console.log("coming directly");
          if (this.props.searchedData.skFlag === 1) {
      
            let tempEnableNext2 =
            this.props.searchedData.totalNumberOfRecords > 1 ? true : false;
            this.setState({
              searchKey: sessionStorage.searchKey,
              searchKeyValue: sessionStorage.searchKeyValue,
              allData: this.props.searchedData.records,
              singleObject: this.props.searchedData.records[0],
              enableNext: tempEnableNext2,
              recordType: this.props.searchedData.recordType,
              error: false,
              errorMessage: "",
              totalNumberOfRecords: this.props.searchedData.totalNumberOfRecords,
              mtcn: this.props.history.location.mtcn
            });
          } else {
            this.setState({
              error: true,
              errorMessage: this.props.searchedData.message,
            });
          }
        });
      }
    }
  }

  onHandleBackAgentRecordMTCNDetails = () => {
    //console.log("Invoked");
    
    let submittedObj = {};
    submittedObj.mtcn = this.state.singleObject.mtcn;
    // console.log(submittedObj);
    this.props.doSearchMtcn(submittedObj, "mtcn",this.props.history.location.jwtToken).then(() => {
      //console.log(this.props.searchedData);
      if (this.props.searchedData.mtcnFlag === 1) {
        this.props.history.push({
          pathname: "/mt-database-money-transfer-record",
          jwtToken: this.props.history.location.jwtToken
        });
      } else {
        this.setState({
          error: true,
          errorMessage: this.props.searchedData.message,
        });
      }
      // this.onClearMessageSearchKey();
    });
  };

  // onClearMessageSearchKey = () => {
  //   setTimeout(
  //     () =>
  //       this.setState({
  //         error: false,
  //         errorMessage: "",
  //       }),
  //     5000
  //   );
  // };

  onHandleBackAgentRecordMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
      jwtToken: this.props.history.location.jwtToken
    });
  };

  onHandlePrevNextData = (type) => {
    if (type === "prev") {
      this.setState(
        {
          count: this.state.count - 1,
        },
        () => {
          let submittedObj = {};
            submittedObj.searchKey = this.state.searchKey;
            submittedObj.searchKeyValue = this.state.searchKeyValue;
            submittedObj.pageNumber = this.state.count;
            submittedObj.mtcn = this.state.mtcn;
            this.props.doSearchMtcn(submittedObj, "skrecord", this.props.history.location.jwtToken).then(() => {
              this.setState({
                enableNext: true,
                singleObject: this.props.searchedData.records[0],
              });
            });

          if (this.state.count === 1) {
            this.setState({
              enablePrev: false,
              enableNext: true,
            });
          }
        }
      );
    } else {
      // console.log("I have been invoked"+this.state.count+"vvvv"+this.state.totalNumberOfRecords)      
      if (this.state.count < this.state.totalNumberOfRecords) {
        this.setState(
          {
            count: this.state.count + 1,
          },
          () => {
          
            let submittedObj = {};
            submittedObj.searchKey = this.state.searchKey;
            submittedObj.searchKeyValue = this.state.searchKeyValue;
            submittedObj.pageNumber = this.state.count;
            submittedObj.mtcn = this.state.mtcn;
            this.props.doSearchMtcn(submittedObj, "skrecord", this.props.history.location.jwtToken).then(() => {
              this.setState({
                enablePrev: true,
                singleObject: this.props.searchedData.records[0],
              });
            });
            if (this.state.count === this.state.totalNumberOfRecords) {
              this.setState({
                enablePrev: true,
                enableNext: false,
              });
            }
          }
        );
      } else if (this.state.count === this.state.totalNumberOfRecords) {
        this.setState({
          enablePrev: false,
          enableNext: false,
        });
      }
    }
  };

  onHandleRecordingAgentMTCNSearchKey = () => {
    //console.log(this.state.singleObject.account);
    let submittedObj = {};
    submittedObj.searchKeyValue = this.state.singleObject.account;
    this.props.doSearchMtcn(submittedObj, "agentAccountNumber", this.props.history.location.jwtToken).then(() => {
      if(parseInt(this.props.searchedData.agentFlag) === 1) {
        this.props.history.push({
          pathname: "/money-transfer-database-agent-record",
          mtcn: this.state.singleObject.mtcn,
          jwtToken: this.props.history.location.jwtToken
        });
      } else {
        this.setState({
          error: true,
          errorMessage: this.props.searchedData.message,
        });
      }
      // this.onClearMessageSearchKey();
    });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <h4 className="card-title main-heading">
            MONEY TRANSFER DATABASE {this.state.recordType} RECORD
          </h4>
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body card-body-custom">
                  <div className="row mar-bottom5">
                    <div className="col-lg-6 font-size-13 text-center">
                    <span className="font-weight-bold">MTCN : </span>{this.state.singleObject.mtcn}
                    </div>
                    <div className="col-lg-6 font-size-13 text-center">
                    <span className="font-weight-bold">SK-KEY : </span>{this.state.searchKeyValue}
                    </div>
                  </div>
                  <div className="card ">
                    <div className="card-body ">
                      <div className="row">
                        <div className="col-lg">
                        <span className="font-weight-bold">SOURCE : </span>{this.state.singleObject.source}
                        </div>
                        <div className="col-lg">
                        <span className="font-weight-bold">RID : </span>{this.state.singleObject.rid}
                        </div>
                        <div className="col-lg">
                        <span className="font-weight-bold">REV : </span>{this.state.singleObject.rev}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                        <span className="font-weight-bold">Status : </span>{this.state.singleObject.status}
                        </div>
                        <div className="col-lg">
                        <span className="font-weight-bold">Runa : </span>{this.state.singleObject.runa}
                        </div>
                        <div className="col-lg">
                        <span className="font-weight-bold">Date/Time : </span>{this.state.singleObject.dateTime}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                        <span className="font-weight-bold">BQC Indicator : </span>{this.state.singleObject.bqcIndicator}
                        </div>
                        <div className="col-lg">
                        <span className="font-weight-bold">Prod Code : </span>{this.state.singleObject.prodCode}
                        </div>
                        <div className="col-lg">
                        <span className="font-weight-bold">Account : </span>{this.state.singleObject.account}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                        <span className="font-weight-bold">Qccid : </span>{this.state.singleObject.qccId}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="card-body ">
                              <div className="row">
                                <div className="col-lg">
                                <span className="font-weight-bold">Sender : </span>{this.state.singleObject.sender}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg">
                                <span className="font-weight-bold">Ocity : </span>{this.state.singleObject.orgCity}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg">
                                <span className="font-weight-bold">Ost : </span>{this.state.singleObject.orgStreet}
                                </div>
                                <div className="col-lg">
                                <span className="font-weight-bold">Ocntry : </span>{this.state.singleObject.orgCountry}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg">
                                <span className="font-weight-bold">Payee : </span>{this.state.singleObject.payee}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg">
                                <span className="font-weight-bold">Dcntry : </span>{this.state.singleObject.desCountry}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row">
                        <div className="col-lg">
                        <span className="font-weight-bold">Prin : </span>{this.state.singleObject.prin}
                        </div>
                        <div className="col-lg">
                        <span className="font-weight-bold">Skfix : </span>{this.state.singleObject.skFix}
                        </div>
                        {/* <div className="col-lg">
                          DBArea: {this.state.singleObject.DBArea}
                        </div>
                        <div className="col-lg">
                          DBKey: {this.state.singleObject.DBKey}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="card mar-top5">
                    <div className="card-body ">
                      <div className="row mar-bottom5">
                        <div className="col-lg text-center">
                        <span className="font-weight-bold">Curtain Ind : </span>{this.state.singleObject.curtainInd}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">HLD : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.hld}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">SN : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.sn}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">RN : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.rn}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">FR : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.fr}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">X : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.x}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">AN : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.an}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">SZ : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.sz}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">BB : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.bb}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">TSU : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.tsu}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">D2B : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.d2b}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">DDS : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.dds}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">FOS : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.fos}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">DELAY : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.delay}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">SUSP : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.susp}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">DEF : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.def}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">REN : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.ren}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">X : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.x1}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="row">
                            <div className="col-lg-8"><span className="font-weight-bold">REG : </span></div>
                            <div className="col-lg-4">
                              {this.state.singleObject.reg}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mar-top5 mar-bottom5 ">
                <button
                  type="button"
                  className="btn btn-warning mr-2"
                  data-test="MTCN-Search-Prior"
                  onClick={() => this.onHandlePrevNextData("prev")}
                  disabled={!this.state.enablePrev}
                >
                  PRIOR SK
                </button>
                <button
                  type="button"
                  className="btn btn-warning mr-2"
                  onClick={() => this.onHandlePrevNextData("next")}
                  data-test="MTCN-Search-Next"
                  disabled={!this.state.enableNext}
                >
                  NEXT DUP.SK
                </button>
                <button
                  type="button"
                  className="btn btn-warning mr-2"
                  onClick={this.onHandleBackAgentRecordMTCNDetails}
                  data-test="MTCNDetails-button-back"
                >
                  MTCN Details
                </button>
                <button
                  type="button"
                  className="btn btn-warning mar-right10"
                  onClick={this.onHandleRecordingAgentMTCNSearchKey}
                  disabled={getValidData(this.state.singleObject.account)}
                  data-test="MTCNsearchKey-Recagnt"
                >
                REC AGENT
                </button>
                <button
                  type="button"
                  className="btn btn-warning mar-right10"
                  onClick={this.onHandleBackAgentRecordMTCNSearch}
                  data-test="Search-button-back"
                >
                  SEARCH
                </button>
                {this.state.error && (
                  <div className="alert alert-danger alert-padding search-key-error font-weight-bold">
                    {this.state.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToPropsMTSK = ({ MTCNSearchReducer }) => {
  return {
    searchedData: MTCNSearchReducer.dataInfo,
  };
};

export default connect(mapStateToPropsMTSK, { doSearchMtcn })(
  MoneyTransferSearchKey
);
