import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactC2C } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditC2C } from "react-icons/ai";
import { IconContext as IconContextC2C } from "react-icons";
import { getHeadersShared2 } from "../../utility/utility";

class C2CFraudPreventionFilter extends Component {
  state = {
    timestamp: "",
    sequenceNum: "",
    ruleId: "",
    sendCountryIso: [],
    receiveCountryIso: [],
    rcvCountryIsoAll: false,
    senderCntry1: "",
    senderCntry2: "",
    senderCntry3: "",
    senderCntry4: "",
    senderCntry5: "",
    senderCntry6: "",
    senderCntry7: "",
    senderCntry8: "",
    senderCntry9: "",
    senderCntry10: "",
    senderCntry11: "",
    senderCntry12: "",
    senderCntry13: "",
    senderCntry14: "",
    senderCntry15: "",
    senderCntry16: "",
    senderCntry17: "",
    senderCntry18: "",
    senderCntry19: "",
    senderCntry20: "",
    receiverCntry1: "",
    receiverCntry2: "",
    receiverCntry3: "",
    receiverCntry4: "",
    receiverCntry5: "",
    receiverCntry6: "",
    receiverCntry7: "",
    receiverCntry8: "",
    receiverCntry9: "",
    receiverCntry10: "",
    receiverCntry11: "",
    receiverCntry12: "",
    receiverCntry13: "",
    receiverCntry14: "",
    receiverCntry15: "",
    receiverCntry16: "",
    receiverCntry17: "",
    receiverCntry18: "",
    receiverCntry19: "",
    receiverCntry20: "",
    startDate: "",
    endDate: "",
    wind: "",
    monitorMode: "",
    action: "",
    queueName: "",
    addressCheck: "",
    comments: "",
    messageIndicator: "N",
    message1: "",
    message2: "",
    message3: "",
    errorMessage: "",
    moneyTransferType: "",
    siteId: "",
    specificCsc: "",
    sendingReceiving: "",
    countryState: "",
    countryStateAll: false,
    agent: "",
    agentAll: false,
    agentNetwork: "",
    agentNetworkAll: false,
    receiverCountryState: "",
    rcvCountryStateAll: false,
    regTran1: "",
    regTran2: "",
    regTran3: "",
    regTran4: "",
    regLowAmt1: "",
    regLowAmt2: "",
    regLowAmt3: "",
    regLowAmt4: "",
    regUppAmt1: "",
    regUppAmt2: "",
    regUppAmt3: "",
    regUppAmt4: "",
    regMin1: "",
    regMin2: "",
    regMin3: "",
    regMin4: "",
    floorUsdAmount: 0,
    catchTran1: "",
    catchTran2: "",
    catchTran3: "",
    catchTran4: "",
    catchAmt1: "",
    catchAmt2: "",
    catchAmt3: "",
    catchAmt4: "",
    catchMin1: "",
    catchMin2: "",
    catchMin3: "",
    catchMin4: "",
    enableBtn: false,
    count: 0,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => this.handleEditC2CFraudPreventionFilter(params)}
            >
              <IconContextC2C.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditC2C />
              </IconContextC2C.Provider>
            </div>
          );
        },
        floatingFilter: false,
        sortable: false,
        filter: false,
        width: "80px",
      },
      {field: "ruleId",headerName: "RULE ID",width: "100px",},
      {field: "sndIso",headerName: "SND ISO",width: "100px",},
      {field: "sndState",headerName: "SND ST",width: "100px",},
      {field: "sndAgent",headerName: "SND AGENT",width: "100px",},
      {field: "sndNetwork",headerName: "SND NETWORK",width: "100px",},
      {field: "rcvIso",headerName: "RCV ISO",width: "100px",},
      {field: "rcvState",headerName: "RCV ST",width: "100px",},
      {field: "startDate",headerName: "START DATE",width: "100px",filter: false,},
      {field: "endDate",headerName: "END DATE",width: "100px",filter: false,},
      {field: "wind",headerName: "WIND",width: "100px",},
      {field: "monitorMode",headerName: "MMODE",width: "120px",},
    ],
    rows: null,
    gridApi: null,
    paginationValC2CFraudPreventionFilter: "25",
    defaultColumnDef: {
      resizable: true,
      flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    paginationOptionC2CFraudPreventionFilter: [25, 50, 100, 500],
    overlayLoadingTemplateC2C:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateC2C: '<span style="">No Records Found</span>',
    showC2CFraudPreventionFilterConfirm: false,
    showC2CFPFUpdateConfirm: false,
    submitStatusC2CFraudPreventionFilter: null,
    submitMsgC2CFraudPreventionFilter: "",
  };

  onPageSizeChangedC2CFraudPreventionFilter = (e) => {
    this.setState({
      paginationValC2CFraudPreventionFilter: e.target.value,
    });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onGridReadyC2CFraudPreventionFilter = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    this.onLoadingC2CFraudPreventionFilter();
  };
  onChangeControlC2CFraudPreventionFilter = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeControlC2CFraudPreventionFilterCharNum = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };
  onChangeDateControlC2CFraudPreventionFilter = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChangeControlC2CFraudPreventionFilterNum = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
    });
  };

  componentDidMount=()=>{
    this.setState({
      accessGrantedWriteFPF:this.props.location.records.accessGrantedWriteFPF
    });
  }

  onLoadingC2CFraudPreventionFilter = () => {
    this.onHandleClearC2CFraudPreventionFilter();
    if (this.props.location.state !== "") {
      axios
        .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/c2csearch`, {
          headers: {
            ...getHeadersShared2().headers,
            countryCpcIso: this.props.location.state,
            Authorization:this.props.location.records.token,
          },
        })
        .then((response) => {
          if(response.data.flag === 1){
          this.setState({
            rows: response.data.records,
          });
        }else{
          this.setState({
            rows: [],
            overlayNoRowsTemplateC2C:`<span style="">${response.data.message}</span>`
        });
        }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/c2csearch`,
          {
            headers: {
              ...getHeadersShared2().headers,
              Authorization:this.props.location.records.token,
            },
          }
        )
        .then((response) => {
          if(response.data.flag === 1){
          this.setState({
            rows: response.data.records,
          });
        }else{
          this.setState({
            rows: [],
            overlayNoRowsTemplateC2C:`<span style="">${response.data.message}</span>`
        });
        }
        });
    }
  };

  handleEditC2CFraudPreventionFilter = (params) => {
    this.onHandleClearC2CFraudPreventionFilter();
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/c2csearch/${params.data.timestamp}/${params.data.sequenceNum} `,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
          },
        }
      )
      .then((response) => {
        if(response.data.flag === 1){
        const arrsendCountryIso = response.data.sendCountryIso;
       /*  if(arrsendCountryIso.length ===1 || arrsendCountryIso.length ===2 || arrsendCountryIso.length ===3 || arrsendCountryIso.length ===4
          || arrsendCountryIso.length ===5 || arrsendCountryIso.length ===6 || arrsendCountryIso.length ===7 || arrsendCountryIso.length ===8
          || arrsendCountryIso.length ===9 || arrsendCountryIso.length ===10 || arrsendCountryIso.length ===11 || arrsendCountryIso.length ===12
          || arrsendCountryIso.length ===13|| arrsendCountryIso.length ===14 || arrsendCountryIso.length ===15 || arrsendCountryIso.length ===16
          || arrsendCountryIso.length ===18|| arrsendCountryIso.length ===19 || arrsendCountryIso.length ===20 ){
            this.setState({
              senderCntry2: "",
              senderCntry3: "",
              senderCntry4: "",
              senderCntry5: "",
              senderCntry6: "",
              senderCntry7: "",
              senderCntry8: "",
              senderCntry9: "",
              senderCntry10: "",
              senderCntry11: "",
              senderCntry12: "",
              senderCntry13: "",
              senderCntry14: "",
              senderCntry15: "",
              senderCntry16: "",
              senderCntry17: "",
              senderCntry18: "",
              senderCntry19: "",
              senderCntry20: "",
            });
          }   */
        const arrRegTrans = response.data.regularRule ? response.data.regularRule.trans : [];
        const arrRegLowAmt = response.data.regularRule ? response.data.regularRule.lowerLimit : [];
        const arrUppAmt = response.data.regularRule ? response.data.regularRule.upperLimit : [];
        const arrMin = response.data.regularRule ? response.data.regularRule.minutes : [];
        const arrCatchTrans = response.data.catchAllRule ? response.data.catchAllRule.trans : [];
        const arrUsdAmt = response.data.catchAllRule ? response.data.catchAllRule.usdAmount : [];
        const arrCatchMin = response.data.catchAllRule ? response.data.catchAllRule.minutes : [];
        let d1C2CFPF = new Date(response.data.startDate);
        let newStartDate = [
          d1C2CFPF.getFullYear(),
          ("0" + (d1C2CFPF.getMonth() + 1)).slice(-2),
          ("0" + d1C2CFPF.getDate()).slice(-2),
        ].join("-");
        let d2C2CFPF = new Date(response.data.endDate);
        let newEndDate = [
          d2C2CFPF.getFullYear(),
          ("0" + (d2C2CFPF.getMonth() + 1)).slice(-2),
          ("0" + d2C2CFPF.getDate()).slice(-2),
        ].join("-");
        /*   if(response.data.receiveCountryIso === "All"){
            receiverCntryIsoFilterAll: response.data.receiveCountryIso,
          } */
        this.setState({
          ruleId: response.data.ruleId,
          sequenceNum: response.data.sequenceNum,
          timestamp: response.data.timestamp,
          sendCountryCpc: response.data.sendCountryCpc,
          startDate: newStartDate,
          endDate: newEndDate,
          action: response.data.action,
          queueName: response.data.queueName,
          monitorMode: response.data.monitorMode,
          addressCheck: response.data.addressCheck,
          siteId: response.data.siteId,
          specificCsc: response.data.specificCsc,
          comments: response.data.comments,
          messageIndicator: response.data.messageIndicator,
          senderCntry1: arrsendCountryIso[0],
          senderCntry2: arrsendCountryIso[1],
          senderCntry3: arrsendCountryIso[2],
          senderCntry4: arrsendCountryIso[3],
          senderCntry5: arrsendCountryIso[4],
          senderCntry6: arrsendCountryIso[5],
          senderCntry7: arrsendCountryIso[6],
          senderCntry8: arrsendCountryIso[7],
          senderCntry9: arrsendCountryIso[8],
          senderCntry10: arrsendCountryIso[9],
          senderCntry11: arrsendCountryIso[10],
          senderCntry12: arrsendCountryIso[11],
          senderCntry13: arrsendCountryIso[12],
          senderCntry14: arrsendCountryIso[13],
          senderCntry15: arrsendCountryIso[14],
          senderCntry16: arrsendCountryIso[15],
          senderCntry17: arrsendCountryIso[16],
          senderCntry18: arrsendCountryIso[17],
          senderCntry19: arrsendCountryIso[18],
          senderCntry20: arrsendCountryIso[19],
          wind: response.data.whiteListIndicator,
          moneyTransferType: response.data.moneyTransferType,
          message1: response.data.message1,
          message2: response.data.message2,
          message3: response.data.message3,
          errorMessage: response.data.errorMessage,
          //  countryState: response.data.sendingReceiving.countryState,
          // agent: response.data.sendingReceiving.agent,
          // agentNetwork: response.data.sendingReceiving.agentNetwork,
          // receiverCountryState: response.data.sendingReceiving.receiverCountryState,
          regTran1: arrRegTrans[0],
          regTran2: arrRegTrans[1],
          regTran3: arrRegTrans[2],
          regTran4: arrRegTrans[3],
          regLowAmt1: arrRegLowAmt[0],
          regLowAmt2: arrRegLowAmt[1],
          regLowAmt3: arrRegLowAmt[2],
          regLowAmt4: arrRegLowAmt[3],
          regUppAmt1: arrUppAmt[0],
          regUppAmt2: arrUppAmt[1],
          regUppAmt3: arrUppAmt[2],
          regUppAmt4: arrUppAmt[3],
          regMin1: arrMin[0],
          regMin2: arrMin[1],
          regMin3: arrMin[2],
          regMin4: arrMin[3],
          //floorUsdAmount: response.data.regularRule.floorUsdAmount,
          floorUsdAmount: response.data.regularRule  ? response.data.regularRule.floorUsdAmount : null,
          catchTran1: arrCatchTrans[0],
          catchTran2: arrCatchTrans[1],
          catchTran3: arrCatchTrans[2],
          catchTran4: arrCatchTrans[3],
          catchAmt1: arrUsdAmt[0],
          catchAmt2: arrUsdAmt[1],
          catchAmt3: arrUsdAmt[2],
          catchAmt4: arrUsdAmt[3],
          catchMin1: arrCatchMin[0],
          catchMin2: arrCatchMin[1],
          catchMin3: arrCatchMin[2],
          catchMin4: arrCatchMin[3],
          enableBtn: true,
        });
        const arrrcvCountryIso = response.data.receiveCountryIso;
        if (arrrcvCountryIso[0] === "") {
          this.setState({
            rcvCountryIsoAll: true,
            receiverCntry1: "",
            receiverCntry2: "",
            receiverCntry3: "",
            receiverCntry4: "",
            receiverCntry5: "",
            receiverCntry6: "",
            receiverCntry7: "",
            receiverCntry8: "",
            receiverCntry9: "",
            receiverCntry10: "",
            receiverCntry11: "",
            receiverCntry12: "",
            receiverCntry13: "",
            receiverCntry14: "",
            receiverCntry15: "",
            receiverCntry16: "",
            receiverCntry17: "",
            receiverCntry18: "",
            receiverCntry19: "",
            receiverCntry20: "",
          });
        } else {
          this.setState({
            receiverCntry1: arrrcvCountryIso[0],
            receiverCntry2: arrrcvCountryIso[1],
            receiverCntry3: arrrcvCountryIso[2],
            receiverCntry4: arrrcvCountryIso[3],
            receiverCntry5: arrrcvCountryIso[4],
            receiverCntry6: arrrcvCountryIso[5],
            receiverCntry7: arrrcvCountryIso[6],
            receiverCntry8: arrrcvCountryIso[7],
            receiverCntry9: arrrcvCountryIso[8],
            receiverCntry10: arrrcvCountryIso[9],
            receiverCntry11: arrrcvCountryIso[10],
            receiverCntry12: arrrcvCountryIso[11],
            receiverCntry13: arrrcvCountryIso[12],
            receiverCntry14: arrrcvCountryIso[13],
            receiverCntry15: arrrcvCountryIso[14],
            receiverCntry16: arrrcvCountryIso[15],
            receiverCntry17: arrrcvCountryIso[16],
            receiverCntry18: arrrcvCountryIso[17],
            receiverCntry19: arrrcvCountryIso[18],
            receiverCntry20: arrrcvCountryIso[19],
            rcvCountryIsoAll: false,
          });
        }
        if (response.data.sendingReceiving.countryState === "") {
          this.setState({
            countryStateAll: true,
            countryState: "",
          });
        } else {
          this.setState({
            countryState: response.data.sendingReceiving.countryState,
            countryStateAll: false,
          });
        }
        if (response.data.sendingReceiving.agent === "") {
          this.setState({
            agentAll: true,
            agent: "",
          });
        } else {
          this.setState({
            agent: response.data.sendingReceiving.agent,
            agentAll: false,
          });
        }
        if (response.data.sendingReceiving.agentNetwork === "") {
          this.setState({
            agentNetworkAll: true,
            agentNetwork: "",
          });
        } else {
          this.setState({
            agentNetwork: response.data.sendingReceiving.agentNetwork,
            agentNetworkAll: false,
          });
        }
        if (response.data.sendingReceiving.receiverCountryState === "") {
          this.setState({
            rcvCountryStateAll: true,
            receiverCountryState: "",
          });
        } else {
          this.setState({
            receiverCountryState:
              response.data.sendingReceiving.receiverCountryState,
            rcvCountryStateAll: false,
          });
        }
      }else{
        this.setState({
          submitStatusC2CFraudPreventionFilter: "Error",
          submitMsgC2CFraudPreventionFilter: response.data.message,
      });
      }
      });
  };

  onHandleBackC2CFraudPreventionFilter = () => {
    this.props.history.push("/fraud-prevention-filter");
  };
  
  onValidateC2CFraudPreventionFilter = (validationType) => {
    var countS = 0;
      if(this.state.senderCntry1 !== "" && this.state.senderCntry1 !== undefined)
      { countS++;}
      if(this.state.senderCntry2 !== "" && this.state.senderCntry2 !== undefined)
      { countS++;}
      if(this.state.senderCntry3 !== "" && this.state.senderCntry3 !== undefined)
      { countS++;}
      if(this.state.senderCntry4 !== "" && this.state.senderCntry4 !== undefined)
      { countS++;}
      if(this.state.senderCntry5 !== "" && this.state.senderCntry5 !== undefined)
      { countS++;}
      if(this.state.senderCntry6 !== "" && this.state.senderCntry6 !== undefined)
      { countS++;}
      if(this.state.senderCntry7 !== "" && this.state.senderCntry7 !== undefined)
      { countS++;}
      if(this.state.senderCntry8 !== "" && this.state.senderCntry8 !== undefined)
      { countS++;}
      if(this.state.senderCntry9 !== "" && this.state.senderCntry9 !== undefined)
      { countS++;}
      if(this.state.senderCntry10 !== "" && this.state.senderCntry10 !== undefined)
      { countS++;}
      if(this.state.senderCntry11 !== "" && this.state.senderCntry11 !== undefined)
      { countS++;}
      if(this.state.senderCntry12 !== "" && this.state.senderCntry12 !== undefined)
      { countS++;}
      if(this.state.senderCntry13 !== "" && this.state.senderCntry13 !== undefined)
      { countS++;}
      if(this.state.senderCntry14 !== "" && this.state.senderCntry14 !== undefined)
      { countS++;}
      if(this.state.senderCntry15 !== "" && this.state.senderCntry15 !== undefined)
      { countS++;}
      if(this.state.senderCntry16 !== "" && this.state.senderCntry16 !== undefined)
      { countS++;}
      if(this.state.senderCntry17 !== "" && this.state.senderCntry17 !== undefined)
      { countS++;}
      if(this.state.senderCntry18 !== "" && this.state.senderCntry18 !== undefined)
      { countS++;}
      if(this.state.senderCntry19 !== "" && this.state.senderCntry19 !== undefined)
      { countS++;}
      if(this.state.senderCntry20 !== "" && this.state.senderCntry20 !== undefined)
      { countS++;}
     
    if(countS>1 && this.state.countryState !== ""){
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Multiple Sending Countries Entered. Please Select The Sender State Value As ALL",
      });
      return false;
    }

    if(countS>1 && this.state.agent !== ""){
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Multiple Sending Countries Entered. Please Select The Sender Agent Value As ALL",
      });
      return false;
    }

    if(countS>1 && this.state.agentNetwork !== ""){
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Multiple Sending Countries Entered. Please Select The Sender Network Value As ALL",
      });
      return false;
    }

    if (countS === 0 ) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Enter The Value For Sending Country ISO",
      });
      return false;
    }

    if (this.state.startDate === "") {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Enter The Value For Start Date",
      });
      return false;
    }

    if (this.state.endDate === "") {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Enter The Value For End Date",
      });
      return false;
    }

    if(validationType === "Add")
    {
      let tDate=new Date()
      let newTDate = [
      tDate.getFullYear(),
       ("0" + (tDate.getMonth() + 1)).slice(-2),
       ("0" + tDate.getDate()).slice(-2),
       ].join("-");

      if( newTDate > this.state.startDate) {
      this.setState({
      submitStatusC2CFraudPreventionFilter: "Error",
      submitMsgC2CFraudPreventionFilter: "Start Date Cannot Be Less Than Today's Date",
     });
     return false;
    }
    }    

    if(new Date(this.state.startDate) > new Date(this.state.endDate)) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "End Date Must Be Greater Than Or Equal To Start Date",
      });
      return false;
    }

    if (this.state.action === "") {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Enter The Value For Action",
      });
      return false;
    }
    if
    (this.state.queueName !== "" && this.state.action === "B") 
    {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Only Queue Filter Action Should Have Queue Name Value",
      });
      return false;
    }
    if (this.state.monitorMode === "") {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Select The Value For Monitor Mode",
      });
      return false;
    }
    if (this.state.addressCheck === "") {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Select The Value For Address Check",
      });
      return false;
    }
    if (this.state.moneyTransferType === "") {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Select The Value For Money Transfer Type",
      });
      return false;
    }
    if (this.state.siteId === "") {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Select The Value For Site Id",
      });
      return false;
    }
    if (
      (this.state.specificCsc === "" || this.state.siteId !== "S") &&
      (this.state.specificCsc !== "" || this.state.siteId === "S")
    ) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Select The Value For Specific CSC and Value For Enter Site Id",
      });
      return false;
    }

    if (
      this.state.messageIndicator === "Y" &&
      this.state.message1 === "" &&
      this.state.messageIndicator === "Y" && 
	    this.state.message2 === "" &&
      this.state.messageIndicator === "Y" &&
      this.state.message3 === ""
    ) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "FPF Message Indicator Is selected. Please Enter The Value For FPF Message",
      });
      return false;
    }

    if(this.state.receiverCntry1 === "" && !this.state.rcvCountryIsoAll)
    {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Receiving Country ISO or Select All",
      });
      return false;
    }
 /*    if(this.state.receiverCntry1 !== "" && this.state.rcvCountryIsoAll){
      if(this.state.receiverCountryState !== "")
      {
        this.setState({
          submitStatusC2CFraudPreventionFilter: "Error",
          submitMsgC2CFraudPreventionFilter:
            "MULTIPLE RECEIVING COUNTRIES ENTERED. PLEASE ONLY SELECT RECEIVER STATE AS ALL",
        });
        return false;
      }
    } */
    
    if (this.state.countryState !== "" && this.state.countryStateAll) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Country State Or Select ALL For Multiple Countries",
      });
      return false;
    }
    if (this.state.countryState === "" && !this.state.countryStateAll) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Country State Or Select ALL For Multiple Countries",
      });
      return false;
    }
  
    if (this.state.agent !== "" && this.state.agentAll) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Agent Or Select ALL For Multiple Countries",
      });
      return false;
    }
    if (this.state.agent === "" && !this.state.agentAll) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Agent Or Select ALL For Multiple Countries",
      });
      return false;
    }

    if (this.state.agentNetwork !== "" && this.state.agentNetworkAll) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Agent Network Or Select ALL For Multiple Countries",
      });
      return false;
    }

    if (this.state.agentNetwork === "" && !this.state.agentNetworkAll) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Agent Network Or Select ALL For Multiple Countries",
      });
      return false;
    }

    if (
      this.state.receiverCountryState !== "" &&
      this.state.rcvCountryStateAll
    ) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Receiving Country State Or Select ALL For Multiple Countries",
      });
      return false;
    }

    if (
      this.state.receiverCountryState === "" &&
      !this.state.rcvCountryStateAll
    ) {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          "Please Enter The Value For Receiving Country State Or Select ALL For Multiple Countries",
      });
      return false;
    }

    if ((Number(this.state.regUppAmt1) < Number(this.state.regLowAmt1)) ||
      (Number(this.state.regUppAmt2) < Number(this.state.regLowAmt2)) ||
      (Number(this.state.regUppAmt3) < Number(this.state.regLowAmt3)) ||
      (Number(this.state.regUppAmt4) < Number(this.state.regLowAmt4))) {
      let indexC2CRegUppLowAmt = 0;
      if (Number(this.state.regUppAmt1) < Number(this.state.regLowAmt1)) {
        indexC2CRegUppLowAmt = 1;
      } else if (Number(this.state.regUppAmt2) < Number(this.state.regLowAmt2)) {
        indexC2CRegUppLowAmt = 2;
      } else if (Number(this.state.regUppAmt3) < Number(this.state.regLowAmt3)) {
        indexC2CRegUppLowAmt = 3;
      } else if (Number(this.state.regUppAmt4) < Number(this.state.regLowAmt4)) {
        indexC2CRegUppLowAmt = 4;
      }
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          `Regular Rule Upper USD Amount Value ${indexC2CRegUppLowAmt} Should Be Greater Than Lower USD Amount`,
      });
      return false;
    }
    
    if (
      ((this.state.regMin1 > 0 || this.state.regMin1 > "0") && (this.state.regUppAmt1 == 0 || this.state.regUppAmt1 === "0")) ||
      ((this.state.regMin2 > 0 || this.state.regMin2 > "0") && (this.state.regUppAmt2 == 0 || this.state.regUppAmt2 === "0")) ||
      ((this.state.regMin3 > 0 || this.state.regMin3 > "0") && (this.state.regUppAmt3 == 0 || this.state.regUppAmt3 === "0")) ||
      ((this.state.regMin4 > 0 || this.state.regMin4 > "0") && (this.state.regUppAmt4 == 0 || this.state.regUppAmt4 === "0"))
    ) {
      let indexC2CRegMinUpp = 0;
      if(this.state.regMin1 > 0 || this.state.regMin1 > "0"){
        indexC2CRegMinUpp = 1;
      }else if(this.state.regMin2 > 0 || this.state.regMin2 > "0"){
        indexC2CRegMinUpp = 2;
      }else if(this.state.regMin3 > 0 || this.state.regMin3 > "0"){
        indexC2CRegMinUpp = 3;
      }else if(this.state.regMin4 > 0 || this.state.regMin4 > "0"){
        indexC2CRegMinUpp = 4;
      }
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          `Regular Rule Upper USD Amount Value ${indexC2CRegMinUpp} Should Be Greater Than Zero`,
      });
      return false;
    }

    if (
      ((this.state.regMin1 > 0 || this.state.regMin1 > "0")  && (this.state.regLowAmt1 == 0 || this.state.regLowAmt1 === "0")) ||
      ((this.state.regMin2 > 0 || this.state.regMin2 > "0") && (this.state.regLowAmt2 == 0 || this.state.regLowAmt2 === "0")) ||
      ((this.state.regMin3 > 0 || this.state.regMin3 > "0") && (this.state.regLowAmt3 == 0 || this.state.regLowAmt3 === "0")) ||
      ((this.state.regMin4 > 0 || this.state.regMin4 > "0") && (this.state.regLowAmt4 == 0 || this.state.regLowAmt4 === "0"))
    ) {
      let indexC2CRegMin = 0;
      if(this.state.regMin1 > 0 || this.state.regMin1 > "0"){
        indexC2CRegMin = 1;
      }else if(this.state.regMin2 > 0 || this.state.regMin2 > "0"){
        indexC2CRegMin = 2;
      }else if(this.state.regMin3 > 0 || this.state.regMin3 > "0"){
        indexC2CRegMin = 3;
      }else if(this.state.regMin4 > 0 || this.state.regMin4 > "0"){
        indexC2CRegMin = 4;
      }
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          `Regular Rule Lower USD Amount Value ${indexC2CRegMin} Should Be Greater Than Zero`,
      });
      return false;
    }

    if (
      ((this.state.catchMin1 > 0 || this.state.catchMin1 > "0") && (this.state.catchAmt1 == 0 || this.state.catchAmt1 === "0")) ||
      ((this.state.catchMin2 > 0 || this.state.catchMin2 > "0") && (this.state.catchAmt2 == 0 || this.state.catchAmt2 === "0") )||
      ((this.state.catchMin3 > 0 || this.state.catchMin3 > "0") && (this.state.catchAmt3 == 0 || this.state.catchAmt3 === "0") )||
      ((this.state.catchMin4 > 0 || this.state.catchMin4 > "0") && (this.state.catchAmt4 == 0 || this.state.catchAmt4 === "0") )
    ) {
      let indexC2CCatchMin = 0;
      if(this.state.catchMin1 > 0 || this.state.catchMin1 > "0"){
        indexC2CCatchMin = 1;
      }else if(this.state.catchMin2 > 0 || this.state.catchMin2 > "0"){
        indexC2CCatchMin = 2;
      }else if(this.state.catchMin3 > 0 || this.state.catchMin3 > "0"){
        indexC2CCatchMin = 3;
      }else if(this.state.catchMin4 > 0 || this.state.catchMin4 > "0"){
        indexC2CCatchMin = 4;
      }
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter:
          `Catch Rule USD Amount Value ${indexC2CCatchMin} Should Be Greater Than Zero`,
      });
      return false;
    }

    if (this.state.floorUsdAmount === "") {
      this.setState({
        submitStatusC2CFraudPreventionFilter: "Error",
        submitMsgC2CFraudPreventionFilter: "Please Enter The Value For Floor Amount Value",
      });
      return false;
    }
    return true;
  };

  convertDateToLegacyFormat = (date) => {
    let d1C2CFPF = new Date(date);
    let newStartDate = [
      ("0" + (d1C2CFPF.getMonth() + 1)).slice(-2),
      ("0" + d1C2CFPF.getDate()).slice(-2),
      d1C2CFPF.getFullYear(),
    ].join("/");
    return newStartDate;
  };
  onHandleAddC2CFraudPreventionFilter = () => {
    if (!this.onValidateC2CFraudPreventionFilter("Add")) {
      return;
    }
    let submittedObjAddC2CFPF = {};
    let senderArrayAddC2CFPF = [];
    let transArrayAddC2CFPF = [];
    let lowerArrayAddC2CFPF = [];
    let upperArrayAddC2CFPF = [];
    let minArrayAddC2CFPF = [];
    let catchtransArrayAddC2CFPF = [];
    let catchAmtArrayAddC2CFPF = [];
    let catchminArrayAddC2CFPF = [];
    submittedObjAddC2CFPF.sendingReceiving = {};
    submittedObjAddC2CFPF.regularRule = {};
    submittedObjAddC2CFPF.catchAllRule = {};

    if (this.state.senderCntry1 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry1.toUpperCase());
    }

    if (this.state.senderCntry2 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry2.toUpperCase());
    }
    if (this.state.senderCntry3 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry3.toUpperCase());
    }
    if (this.state.senderCntry4 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry4.toUpperCase());
    }
    if (this.state.senderCntry5 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry5.toUpperCase());
    }
    if (this.state.senderCntry6 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry6.toUpperCase());
    }
    if (this.state.senderCntry7 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry7.toUpperCase());
    }
    if (this.state.senderCntry8 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry8.toUpperCase());
    }
    if (this.state.senderCntry9 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry9.toUpperCase());
    }
    if (this.state.senderCntry10 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry10.toUpperCase());
    }
    if (this.state.senderCntry11 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry11.toUpperCase());
    }
    if (this.state.senderCntry12 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry12.toUpperCase());
    }
    if (this.state.senderCntry13 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry13.toUpperCase());
    }
    if (this.state.senderCntry14 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry14.toUpperCase());
    }
    if (this.state.senderCntry15 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry15.toUpperCase());
    }
    if (this.state.senderCntry16 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry16.toUpperCase());
    }
    if (this.state.senderCntry17 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry17.toUpperCase());
    }
    if (this.state.senderCntry18 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry18.toUpperCase());
    }
    if (this.state.senderCntry19 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry19.toUpperCase());
    }
    if (this.state.senderCntry20 !== "") {
      senderArrayAddC2CFPF.push(this.state.senderCntry20.toUpperCase());
    }
    //submittedObjAddC2CFPF.sendCountryIso = senderArrayAddC2CFPF;
    const uniqueString = [...new Set(senderArrayAddC2CFPF)];
    submittedObjAddC2CFPF.sendCountryIso = uniqueString;

    transArrayAddC2CFPF.push(this.state.regTran1);
    transArrayAddC2CFPF.push(this.state.regTran2);
    transArrayAddC2CFPF.push(this.state.regTran3);
    transArrayAddC2CFPF.push(this.state.regTran4);
    lowerArrayAddC2CFPF.push(this.state.regLowAmt1);
    lowerArrayAddC2CFPF.push(this.state.regLowAmt2);
    lowerArrayAddC2CFPF.push(this.state.regLowAmt3);
    lowerArrayAddC2CFPF.push(this.state.regLowAmt4);
    upperArrayAddC2CFPF.push(this.state.regUppAmt1);
    upperArrayAddC2CFPF.push(this.state.regUppAmt2);
    upperArrayAddC2CFPF.push(this.state.regUppAmt3);
    upperArrayAddC2CFPF.push(this.state.regUppAmt4);
    minArrayAddC2CFPF.push(this.state.regMin1);
    minArrayAddC2CFPF.push(this.state.regMin2);
    minArrayAddC2CFPF.push(this.state.regMin3);
    minArrayAddC2CFPF.push(this.state.regMin4);
    submittedObjAddC2CFPF.regularRule = {
      trans: transArrayAddC2CFPF,
      lowerLimit: lowerArrayAddC2CFPF.map(Number),
      upperLimit: upperArrayAddC2CFPF.map(Number),
      minutes: minArrayAddC2CFPF,
    };
    submittedObjAddC2CFPF.regularRule.floorUsdAmount = this.state.floorUsdAmount;
    catchtransArrayAddC2CFPF.push(this.state.catchTran1);
    catchtransArrayAddC2CFPF.push(this.state.catchTran2);
    catchtransArrayAddC2CFPF.push(this.state.catchTran3);
    catchtransArrayAddC2CFPF.push(this.state.catchTran4);
    catchAmtArrayAddC2CFPF.push(this.state.catchAmt1);
    catchAmtArrayAddC2CFPF.push(this.state.catchAmt2);
    catchAmtArrayAddC2CFPF.push(this.state.catchAmt3);
    catchAmtArrayAddC2CFPF.push(this.state.catchAmt4);
    catchminArrayAddC2CFPF.push(this.state.catchMin1);
    catchminArrayAddC2CFPF.push(this.state.catchMin2);
    catchminArrayAddC2CFPF.push(this.state.catchMin3);
    catchminArrayAddC2CFPF.push(this.state.catchMin4);
    submittedObjAddC2CFPF.catchAllRule = {
      trans: catchtransArrayAddC2CFPF,
      usdAmount: catchAmtArrayAddC2CFPF.map(Number),
      minutes: catchminArrayAddC2CFPF,
    };
    submittedObjAddC2CFPF.startDate = this.convertDateToLegacyFormat(
      this.state.startDate
    );
    submittedObjAddC2CFPF.endDate = this.convertDateToLegacyFormat(this.state.endDate);
    submittedObjAddC2CFPF.action = this.state.action;
    submittedObjAddC2CFPF.ruleId = this.state.ruleId;
    submittedObjAddC2CFPF.queueName = this.state.queueName;
    submittedObjAddC2CFPF.siteId = this.state.siteId;
    submittedObjAddC2CFPF.specificCsc = this.state.specificCsc;
    submittedObjAddC2CFPF.monitorMode = this.state.monitorMode;
    submittedObjAddC2CFPF.addressCheck = this.state.addressCheck;
    submittedObjAddC2CFPF.comments = this.state.comments;
    submittedObjAddC2CFPF.messageIndicator = this.state.messageIndicator;
    submittedObjAddC2CFPF.message1 = this.state.message1;
    submittedObjAddC2CFPF.message2 = this.state.message2;
    submittedObjAddC2CFPF.message3 = this.state.message3;
    submittedObjAddC2CFPF.errorMessage = this.state.errorMessage;

    this.onHandleC2CFPFAllValue(submittedObjAddC2CFPF);

    submittedObjAddC2CFPF.moneyTransferType = this.state.moneyTransferType;
    submittedObjAddC2CFPF.sendingReceiving.countryState = this.state.countryState;
    submittedObjAddC2CFPF.sendingReceiving.agent = this.state.agent;
    submittedObjAddC2CFPF.sendingReceiving.agentNetwork = this.state.agentNetwork;
    submittedObjAddC2CFPF.sendingReceiving.receiverCountryState =
      this.state.receiverCountryState;
      submittedObjAddC2CFPF.operatorId = this.props.location.records.operatorIdFPF;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/c2csearch`,
        submittedObjAddC2CFPF,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusC2CFraudPreventionFilter: "OK",
            submitMsgC2CFraudPreventionFilter: response.data.responseString,
            rows: [submittedObjAddC2CFPF, ...this.state.rows],
          });

          this.onLoadingC2CFraudPreventionFilter();
        }else {
          this.setState({
            submitStatusC2CFraudPreventionFilter: "Error",
            submitMsgC2CFraudPreventionFilter: response.data.responseString,
          });
        }
        this.onHandleClearC2CFraudPreventionFilter();
      });
  };

  onHandleC2CFPFAllValue = (submittedObjC2CFPF) => {
    let receiverArray = [];
    if (this.state.rcvCountryIsoAll) {
      submittedObjC2CFPF.receiveCountryIso = "";
      receiverArray.push(submittedObjC2CFPF.receiveCountryIso);
      submittedObjC2CFPF.receiveCountryIso = receiverArray;
    } else {
      if (
        this.state.receiverCntry1 !== "" &&
        this.state.receiverCntry1 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry1.toUpperCase());
      }
      if (
        this.state.receiverCntry2 !== "" &&
        this.state.receiverCntry2 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry2.toUpperCase());
      }
      if (
        this.state.receiverCntry3 !== "" &&
        this.state.receiverCntry3 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry3.toUpperCase());
      }
      if (
        this.state.receiverCntry4 !== "" &&
        this.state.receiverCntry4 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry4.toUpperCase());
      }
      if (
        this.state.receiverCntry5 !== "" &&
        this.state.receiverCntry5 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry5.toUpperCase());
      }
      if (
        this.state.receiverCntry6 !== "" &&
        this.state.receiverCntry6 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry6.toUpperCase());
      }
      if (
        this.state.receiverCntry7 !== "" &&
        this.state.receiverCntry7 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry7.toUpperCase());
      }
      if (
        this.state.receiverCntry8 !== "" &&
        this.state.receiverCntry8 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry8.toUpperCase());
      }
      if (
        this.state.receiverCntry9 !== "" &&
        this.state.receiverCntry9 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry9.toUpperCase());
      }
      if (
        this.state.receiverCntry10 !== "" &&
        this.state.receiverCntry10 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry10.toUpperCase());
      }

      if (
        this.state.receiverCntry11 !== "" &&
        this.state.receiverCntry11 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry11.toUpperCase());
      }
      if (
        this.state.receiverCntry12 !== "" &&
        this.state.receiverCntry12 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry12.toUpperCase());
      }
      if (
        this.state.receiverCntry13 !== "" &&
        this.state.receiverCntry13 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry13.toUpperCase());
      }
      if (
        this.state.receiverCntry14 !== "" &&
        this.state.receiverCntry14 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry14.toUpperCase());
      }
      if (
        this.state.receiverCntry15 !== "" &&
        this.state.receiverCntry15 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry15.toUpperCase());
      }
      if (
        this.state.receiverCntry16 !== "" &&
        this.state.receiverCntry16 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry16.toUpperCase());
      }
      if (
        this.state.receiverCntry17 !== "" &&
        this.state.receiverCntry17 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry17.toUpperCase());
      }
      if (
        this.state.receiverCntry18 !== "" &&
        this.state.receiverCntry18 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry18.toUpperCase());
      }
      if (
        this.state.receiverCntry19 !== "" &&
        this.state.receiverCntry19 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry19.toUpperCase());
      }
      if (
        this.state.receiverCntry20 !== "" &&
        this.state.receiverCntry20 !== undefined
      ) {
        receiverArray.push(this.state.receiverCntry20.toUpperCase());
      }
      submittedObjC2CFPF.receiveCountryIso = receiverArray;
    }

    if (this.state.countryStateAll) {
      submittedObjC2CFPF.sendingReceiving.countryState = "";
    } else {
      submittedObjC2CFPF.sendingReceiving.countryState = this.state.countryState;
    }
    if (this.state.agentAll) {
      submittedObjC2CFPF.sendingReceiving.agent = "";
    } else {
      submittedObjC2CFPF.sendingReceiving.agent = this.state.agent;
    }
    if (this.state.agentNetworkAll) {
      submittedObjC2CFPF.sendingReceiving.agentNetwork = "";
    } else {
      submittedObjC2CFPF.sendingReceiving.agentNetwork = this.state.agentNetwork;
    }
    if (this.state.rcvCountryStateAll) {
      submittedObjC2CFPF.sendingReceiving.receiverCountryState = "";
    } else {
      submittedObjC2CFPF.sendingReceiving.receiverCountryState =
        this.state.receiverCountryState;
    }
  };

  onHandleUpdateC2CFraudPreventionFilter = () => {
  /*   if (!this.onValidateC2CFraudPreventionFilter()) {
      return;
    } */
    let submittedObjC2CFPF = {};
    let senderArrayC2CFPF = [];
    //let receiverArray = [];
    let transArrayC2CFPF = [];
    let lowerArrayC2CFPF = [];
    let upperArrayC2CFPF = [];
    let minArrayC2CFPF = [];
    let catchtransArrayC2CFPF = [];
    let catchAmtArrayC2CFPF = [];
    let catchminArrayC2CFPF = [];

    //checked={this.state.receiverCntryIsoFilterAll === "All"}
    submittedObjC2CFPF.sendingReceiving = {};
    submittedObjC2CFPF.regularRule = {};
    submittedObjC2CFPF.catchAllRule = {};
    
    if (this.state.senderCntry1 !== undefined && this.state.senderCntry1 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry1.toUpperCase());
    }
    if (this.state.senderCntry2 !== undefined && this.state.senderCntry2 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry2.toUpperCase());
    }
    if (this.state.senderCntry3 !== undefined && this.state.senderCntry3 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry3.toUpperCase());
    }
    if (this.state.senderCntry4 !== undefined && this.state.senderCntry4 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry4.toUpperCase());
    }
    if (this.state.senderCntry5 !== undefined && this.state.senderCntry5 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry5.toUpperCase());
    }
    if (this.state.senderCntry6 !== undefined && this.state.senderCntry6 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry6.toUpperCase() );
    }
    if (this.state.senderCntry7 !== undefined && this.state.senderCntry7 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry7.toUpperCase());
    }
    if (this.state.senderCntry8 !== undefined && this.state.senderCntry8 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry8.toUpperCase());
    }
    if (this.state.senderCntry9 !== undefined && this.state.senderCntry9 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry9.toUpperCase());
    }
    if (this.state.senderCntry10 !== undefined && this.state.senderCntry10 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry10.toUpperCase());
    }
    if (this.state.senderCntry11 !== undefined && this.state.senderCntry11 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry11.toUpperCase());
    }
    if (this.state.senderCntry12 !== undefined && this.state.senderCntry12 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry12.toUpperCase());
    }
    if (this.state.senderCntry13 !== undefined && this.state.senderCntry13 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry13.toUpperCase());
    }
    if (this.state.senderCntry14 !== undefined && this.state.senderCntry14 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry14.toUpperCase());
    }
    if (this.state.senderCntry15 !== undefined && this.state.senderCntry15 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry15.toUpperCase());
    }
    if (this.state.senderCntry16 !== undefined && this.state.senderCntry16 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry16.toUpperCase());
    }
    if (this.state.senderCntry17 !== undefined && this.state.senderCntry17 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry17.toUpperCase());
    }
    if (this.state.senderCntry18 !== undefined && this.state.senderCntry18 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry18.toUpperCase());
    }
    if (this.state.senderCntry19 !== undefined && this.state.senderCntry19 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry19.toUpperCase());
    }
    if (this.state.senderCntry20 !== undefined && this.state.senderCntry20 !== "") {
      senderArrayC2CFPF.push(this.state.senderCntry20.toUpperCase());
    }
    submittedObjC2CFPF.sendCountryIso = senderArrayC2CFPF;
    transArrayC2CFPF.push(this.state.regTran1);
    transArrayC2CFPF.push(this.state.regTran2);
    transArrayC2CFPF.push(this.state.regTran3);
    transArrayC2CFPF.push(this.state.regTran4);
    lowerArrayC2CFPF.push(this.state.regLowAmt1);
    lowerArrayC2CFPF.push(this.state.regLowAmt2);
    lowerArrayC2CFPF.push(this.state.regLowAmt3);
    lowerArrayC2CFPF.push(this.state.regLowAmt4);
    upperArrayC2CFPF.push(this.state.regUppAmt1);
    upperArrayC2CFPF.push(this.state.regUppAmt2);
    upperArrayC2CFPF.push(this.state.regUppAmt3);
    upperArrayC2CFPF.push(this.state.regUppAmt4);
    minArrayC2CFPF.push(this.state.regMin1);
    minArrayC2CFPF.push(this.state.regMin2);
    minArrayC2CFPF.push(this.state.regMin3);
    minArrayC2CFPF.push(this.state.regMin4);
    // submittedObjC2CFPF.regularRule = {
    //   trans: transArrayC2CFPF,
    //   lowerLimit: lowerArrayC2CFPF.map(Number),
    //   upperLimit: upperArrayC2CFPF.map(Number),
    //   minutes: minArrayC2CFPF,
    // };
    submittedObjC2CFPF.regularRule = {
      trans: transArrayC2CFPF,
      lowerLimit: lowerArrayC2CFPF.map(Number),
      upperLimit: upperArrayC2CFPF.map(Number),
      minutes: minArrayC2CFPF,
    };
    submittedObjC2CFPF.regularRule.floorUsdAmount = this.state.floorUsdAmount;
    catchtransArrayC2CFPF.push(this.state.catchTran1);
    catchtransArrayC2CFPF.push(this.state.catchTran2);
    catchtransArrayC2CFPF.push(this.state.catchTran3);
    catchtransArrayC2CFPF.push(this.state.catchTran4);
    catchAmtArrayC2CFPF.push(this.state.catchAmt1);
    catchAmtArrayC2CFPF.push(this.state.catchAmt2);
    catchAmtArrayC2CFPF.push(this.state.catchAmt3);
    catchAmtArrayC2CFPF.push(this.state.catchAmt4);
    catchminArrayC2CFPF.push(this.state.catchMin1);
    catchminArrayC2CFPF.push(this.state.catchMin2);
    catchminArrayC2CFPF.push(this.state.catchMin3);
    catchminArrayC2CFPF.push(this.state.catchMin4);
    submittedObjC2CFPF.catchAllRule = {
      trans: catchtransArrayC2CFPF,
      usdAmount: catchAmtArrayC2CFPF.map(Number),
      minutes: catchminArrayC2CFPF,
    };
    submittedObjC2CFPF.startDate = this.convertDateToLegacyFormat(
      this.state.startDate
    );
    submittedObjC2CFPF.endDate = this.convertDateToLegacyFormat(this.state.endDate);
    submittedObjC2CFPF.timestamp = this.state.timestamp;
    submittedObjC2CFPF.sequenceNum = this.state.sequenceNum;
    submittedObjC2CFPF.action = this.state.action;
    submittedObjC2CFPF.ruleId = this.state.ruleId;
    submittedObjC2CFPF.queueName = this.state.queueName;
    submittedObjC2CFPF.siteId = this.state.siteId;
    submittedObjC2CFPF.specificCsc = this.state.specificCsc;
    submittedObjC2CFPF.monitorMode = this.state.monitorMode;
    submittedObjC2CFPF.addressCheck = this.state.addressCheck;
    submittedObjC2CFPF.comments = this.state.comments;
    submittedObjC2CFPF.messageIndicator = this.state.messageIndicator;
    submittedObjC2CFPF.message1 = this.state.message1;
    submittedObjC2CFPF.message2 = this.state.message2;
    submittedObjC2CFPF.message3 = this.state.message3;
    submittedObjC2CFPF.errorMessage = this.state.errorMessage;
    this.onHandleC2CFPFAllValue(submittedObjC2CFPF);
    submittedObjC2CFPF.moneyTransferType = this.state.moneyTransferType;
    submittedObjC2CFPF.sendingReceiving.countryState = this.state.countryState;
    submittedObjC2CFPF.sendingReceiving.agent = this.state.agent;
    submittedObjC2CFPF.sendingReceiving.agentNetwork = this.state.agentNetwork;
    submittedObjC2CFPF.sendingReceiving.receiverCountryState =
      this.state.receiverCountryState;
      submittedObjC2CFPF.operatorId = this.props.location.records.operatorIdFPF;
      //console.log(submittedObjC2CFPF);
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/c2csearch`,
        submittedObjC2CFPF,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.timestamp + "" + row.sequenceNum ===
              this.state.timestamp + "" + this.state.sequenceNum
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObjC2CFPF,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusC2CFraudPreventionFilter: "OK",
            submitMsgC2CFraudPreventionFilter: response.data.responseString,
            rows: updatedRows,
          });
          this.onLoadingC2CFraudPreventionFilter();
        } else {
          this.setState({
            submitStatusC2CFraudPreventionFilter: "Error",
            submitMsgC2CFraudPreventionFilter: response.data.responseString,
          });
        }
        this.onHandleClearC2CFraudPreventionFilter();
      });
      this.setState({ showC2CFPFUpdateConfirm: false });
  };

  getC2CFPFUpdateModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showC2CFPFUpdateConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Update This C2C Fraud Prevention Filter?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleUpdateC2CFraudPreventionFilter}
                data-test="button-ok"
              >
                Ok
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showC2CFPFUpdateConfirm: false,
                  })
                }
                data-test="button-cancel"
              >
               Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  onHandleClearC2CFraudPreventionFilter = () => {
    this.setState({
      sendCountryIso: "",
      senderCntry1: "",
      senderCntry2: "",
      senderCntry3: "",
      senderCntry4: "",
      senderCntry5: "",
      senderCntry6: "",
      senderCntry7: "",
      senderCntry8: "",
      senderCntry9: "",
      senderCntry10: "",
      senderCntry11: "",
      senderCntry12: "",
      senderCntry13: "",
      senderCntry14: "",
      senderCntry15: "",
      senderCntry16: "",
      senderCntry17: "",
      senderCntry18: "",
      senderCntry19: "",
      senderCntry20: "",
      startDate: "",
      endDate: "",
      action: "",
      ruleId: "",
      monitorMode: "",
      addressCheck: "",
      moneyTransferType: "",
      queueName: "",
      siteId: "",
      channel: "",
      rcvCountryIsoAll: false,
      specificCsc: "",
      comments: "",
      messageIndicator: "N",
      message1: "",
      message2: "",
      message3: "",
      errorMessage: "",
      receiverCntry1: "",
      receiverCntry2: "",
      receiverCntry3: "",
      receiverCntry4: "",
      receiverCntry5: "",
      receiverCntry6: "",
      receiverCntry7: "",
      receiverCntry8: "",
      receiverCntry9: "",
      receiverCntry10: "",
      receiverCntry11: "",
      receiverCntry12: "",
      receiverCntry13: "",
      receiverCntry14: "",
      receiverCntry15: "",
      receiverCntry16: "",
      receiverCntry17: "",
      receiverCntry18: "",
      receiverCntry19: "",
      receiverCntry20: "",
      countryState: "",
      countryStateAll: false,
      agent: "",
      agentAll: false,
      agentNetwork: "",
      agentNetworkAll: false,
      receiverCountryState: "",
      rcvCountryStateAll: false,
      regTran1: "",
      regTran2: "",
      regTran3: "",
      regTran4: "",
      regLowAmt1: "",
      regLowAmt2: "",
      regLowAmt3: "",
      regLowAmt4: "",
      regUppAmt1: "",
      regUppAmt2: "",
      regUppAmt3: "",
      regUppAmt4: "",
      regMin1: "",
      regMin2: "",
      regMin3: "",
      regMin4: "",
      catchTran1: "",
      catchTran2: "",
      catchTran3: "",
      catchTran4: "",
      catchAmt1: "",
      catchAmt2: "",
      catchAmt3: "",
      catchAmt4: "",
      catchMin1: "",
      catchMin2: "",
      catchMin3: "",
      catchMin4: "",
      floorUsdAmount: 0,
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusC2CFraudPreventionFilter: null,
        }),
      5000
    );
  };

  getC2CFraudPreventionFilterModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showC2CFraudPreventionFilterConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Delete The Sending Country CPC {this.state.senderCntry1} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showC2CFraudPreventionFilterConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteC2CFraudPreventionFilter}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteC2CFraudPreventionFilter = () => {
    let submittedObjC2CFPF = {};
    submittedObjC2CFPF.timestamp = this.state.timestamp;
    submittedObjC2CFPF.sequenceNum = this.state.sequenceNum;
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/c2csearch/${this.state.timestamp}/${this.state.sequenceNum}`,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          let newRows = this.state.rows.filter((row) => {
            return row.timestamp !== submittedObjC2CFPF.timestamp;
          });

          this.setState({
            submitStatusC2CFraudPreventionFilter: "OK",
            submitMsgC2CFraudPreventionFilter: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusC2CFraudPreventionFilter: "Error",
            submitMsgC2CFraudPreventionFilter: response.data.responseString,
          });
        }
        this.onHandleClearC2CFraudPreventionFilter();
      });
    this.setState({ showC2CFraudPreventionFilterConfirm: false });
  };

  onChangeControlC2CALL = (e) => {
    this.setState({
      [e.target.name]: e.target.value === "false" ? true : false,
    });
  };
  onHandleWhiteC2CFraudPreventionFilter = () => {
    const whiteListIndicator = this.state.wind;
    const timeStamp = this.state.timestamp;
    const seqNum = this.state.sequenceNum;
    this.props.history.push({
      pathname: "/whitelist-fraud-rule-c2c",
      state: { timeStamp, seqNum, whiteListIndicator },
      records:this.props.location.records
    });
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="FRAUD PREVENTION FILTER MAINTENANCE"
        />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                C2C FRAUD PREVENTION FILTER MAINTENANCE
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  ISO FILTER DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <label htmlFor="sendCountryIso" className="font-weight-bold">Send Cntry ISO</label>
                    <div className="form-group row">
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry1}
                          className="form-control form-control-sm"
                          name="senderCntry1"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry1"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry2}
                          className="form-control form-control-sm"
                          name="senderCntry2"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry2"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry3}
                          className="form-control form-control-sm"
                          name="senderCntry3"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry3"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry4}
                          className="form-control form-control-sm"
                          name="senderCntry4"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry4"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry5}
                          className="form-control form-control-sm"
                          name="senderCntry5"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry5"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry6}
                          className="form-control form-control-sm"
                          name="senderCntry6"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry6"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry7}
                          className="form-control form-control-sm"
                          name="senderCntry7"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry7"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry8}
                          className="form-control form-control-sm"
                          name="senderCntry8"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry8"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry9}
                          className="form-control form-control-sm"
                          name="senderCntry9"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry9"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry10}
                          className="form-control form-control-sm"
                          name="senderCntry10"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry10"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry11}
                          className="form-control form-control-sm"
                          name="senderCntry11"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry11"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry12}
                          className="form-control form-control-sm"
                          name="senderCntry12"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry12"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry13}
                          className="form-control form-control-sm"
                          name="senderCntry13"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry13"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry14}
                          className="form-control form-control-sm"
                          name="senderCntry14"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry14"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry15}
                          className="form-control form-control-sm"
                          name="senderCntry15"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry15"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry16}
                          className="form-control form-control-sm"
                          name="senderCntry16"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry16"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry17}
                          className="form-control form-control-sm"
                          name="senderCntry17"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry17"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry18}
                          className="form-control form-control-sm"
                          name="senderCntry18"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry18"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry19}
                          className="form-control form-control-sm"
                          name="senderCntry19"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry19"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="3"
                          value={this.state.senderCntry20}
                          className="form-control form-control-sm"
                          name="senderCntry20"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-senderCntry20"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="startDate" className="font-weight-bold">Start Date</label>
                        <br />
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="startDate"
                          value={this.state.startDate}
                          onChange={
                            this.onChangeDateControlC2CFraudPreventionFilter
                          }
                          data-test="text-startDate"
                        ></input>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="endDate" className="font-weight-bold">End Date</label>
                        <br />
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="endDate"
                          value={this.state.endDate}
                          onChange={
                            this.onChangeDateControlC2CFraudPreventionFilter
                          }
                          data-test="text-endDate"
                        ></input>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Action</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="action"
                              value="B"
                              data-test="text-action-B"
                              checked={this.state.action === "B"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="B">
                              Block
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="action"
                              value="Q"
                              data-test="text-action-Q"
                              checked={this.state.action === "Q"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="Q">
                              Queue
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="queueName" className="font-weight-bold">Queue Name</label>
                        <input
                          type="text"
                          maxlength="8"
                          value={this.state.queueName}
                          className="form-control form-control-sm"
                          name="queueName"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-queueName"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="ruleId" className="font-weight-bold"> Rule ID</label>
                        <input
                          type="text"
                          maxlength="12"
                          value={this.state.ruleId}
                          className="form-control form-control-sm"
                          name="ruleId"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilter(e);
                          }}
                          data-test="text-ruleId"
                        />
                      </div>

                      <div className="col-lg">
                        <label className="font-weight-bold">Monitor Mode</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="monitorMode"
                              value="N"
                              data-test="text-monitorMode-N"
                              checked={this.state.monitorMode === "N"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              OFF
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="monitorMode"
                              value="Y"
                              data-test="text-monitorMode-Y"
                              checked={this.state.monitorMode === "Y"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              ON
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Address Check</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="addressCheck"
                              value="N"
                              data-test="text-addressCheck-N"
                              checked={this.state.addressCheck === "N"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              OFF
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="addressCheck"
                              value="Y"
                              data-test="text-addressCheck-Y"
                              checked={this.state.addressCheck === "Y"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="On">
                              ON
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Money Transfer Type</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="moneyTransferType"
                              value="1"
                              data-test="text-moneyTransferType-1"
                              checked={this.state.moneyTransferType === "1"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="1">
                              Will Call
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="moneyTransferType"
                              value="2"
                              data-test="text-moneyTransferType-2"
                              checked={this.state.moneyTransferType === "2"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="2">
                              D2B or
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="moneyTransferType"
                              value="9"
                              data-test="text-moneyTransferType-9"
                              checked={this.state.moneyTransferType === "9"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="9">
                              ALL
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Site Id</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="MOM6"
                              data-test="text-siteId-MOM6"
                              checked={this.state.siteId === "MOM6"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="MOM6">
                              Agent
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="CSC"
                              data-test="text-siteId-CSC"
                              checked={this.state.siteId === "CSC"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="CSC">
                              CSC's or
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value="S"
                              data-test="text-siteId-S"
                              checked={this.state.siteId === "S"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="S">
                              Specific CSC :&nbsp;
                            </label>

                            <input
                              className="form-check-input "
                              type="text"
                              maxLength="4"
                              name="specificCsc"
                              value={this.state.specificCsc}
                              data-test="text-specificCsc"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="siteId"
                              value=" "
                              data-test="text-siteId-All"
                              checked={this.state.siteId === " "}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label">All</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="comments" className="font-weight-bold">Comments</label>
                        <input
                          type="text"
                          maxLength="40"
                          value={this.state.comments}
                          className="form-control form-control-sm"
                          name="comments"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilter(e);
                          }}
                          data-test="text-comments"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Message Indicator</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="messageIndicator"
                              value="Y"
                              data-test="text-messageIndicator-Y"
                              checked={this.state.messageIndicator === "Y"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="messageIndicator"
                              value="N"
                              data-test="text-messageIndicator-N"
                              checked={this.state.messageIndicator === "N"}
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilter(e);
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="ruleId" className="font-weight-bold"> WhiteList In: </label>
                        <input
                          type="text"
                          value={this.state.wind}
                          className="form-control form-control-sm"
                          name="wind"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilter(e);
                          }}
                          data-test="text-wind"
                          placeholder="N"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message1" className="font-weight-bold">Message 1</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message1}
                          className="form-control form-control-sm"
                          name="message1"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilter(e);
                          }}
                          data-test="text-message1"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message2" className="font-weight-bold">Message 2</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message2}
                          className="form-control form-control-sm"
                          name="message2"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilter(e);
                          }}
                          data-test="text-message2"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="message3" className="font-weight-bold">Message 3</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.message3}
                          className="form-control form-control-sm"
                          name="message3"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilter(e);
                          }}
                          data-test="text-message3"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="errorMessage" className="font-weight-bold">Error Message</label>
                        <input
                          type="text"
                          maxLength="85"
                          value={this.state.errorMessage}
                          className="form-control form-control-sm"
                          name="errorMessage"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilter(e);
                          }}
                          data-test="text-errorMessage"
                        />
                      </div>
                    </div>
                    <label htmlFor="receiverCntry1" className="font-weight-bold">Receive Cntry ISO</label>
                    <div className="form-group row">
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry1}
                          className="form-control form-control-sm"
                          name="receiverCntry1"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry1"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry2}
                          className="form-control form-control-sm"
                          name="receiverCntry2"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry2"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry3}
                          className="form-control form-control-sm"
                          name="receiverCntry3"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry3"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry4}
                          className="form-control form-control-sm"
                          name="receiverCntry4"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry4"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry5}
                          className="form-control form-control-sm"
                          name="receiverCntry5"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry5"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry6}
                          className="form-control form-control-sm"
                          name="receiverCntry6"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry6"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry7}
                          className="form-control form-control-sm"
                          name="receiverCntry7"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry7"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry8}
                          className="form-control form-control-sm"
                          name="receiverCntry8"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry8"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry9}
                          className="form-control form-control-sm"
                          name="receiverCntry9"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry9"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry10}
                          className="form-control form-control-sm"
                          name="receiverCntry10"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry10"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry11}
                          className="form-control form-control-sm"
                          name="receiverCntry11"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry11"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry12}
                          className="form-control form-control-sm"
                          name="receiverCntry12"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry12"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry13}
                          className="form-control form-control-sm"
                          name="receiverCntry13"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry13"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry14}
                          className="form-control form-control-sm"
                          name="receiverCntry14"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry14"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry15}
                          className="form-control form-control-sm"
                          name="receiverCntry15"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry15"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry16}
                          className="form-control form-control-sm"
                          name="receiverCntry16"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry16"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry17}
                          className="form-control form-control-sm"
                          name="receiverCntry17"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry17"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry18}
                          className="form-control form-control-sm"
                          name="receiverCntry18"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry18"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry19}
                          className="form-control form-control-sm"
                          name="receiverCntry19"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry19"
                          //placeholder="Sending Country CPC"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.receiverCntry20}
                          className="form-control form-control-sm"
                          name="receiverCntry20"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterCharNum(
                              e
                            );
                          }}
                          data-test="text-receiverCntry20"
                        />
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">ALL :&nbsp;</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="rcvCountryIsoAll"
                          value={this.state.rcvCountryIsoAll}
                          checked={this.state.rcvCountryIsoAll}
                          onChange={(e) => {
                            this.onChangeControlC2CALL(e);
                          }}
                          data-test="text-rcvCountryIsoAll"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="mar-top5">
                <div className="row">
                  <div className="col-lg-4 ">
                    <div className="card">
                        <div className="card-header bg-dark text-white card-header-custom">
                        SENDING/RECEIVING
                      </div>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg">
                              <label htmlFor="countryState" className="font-weight-bold">Country State</label>
                              <input
                                type="text"
                                maxLength="3"
                                value={this.state.countryState}
                                className="form-control form-control-sm"
                                name="countryState"
                                onChange={(e) => {
                                  this.onChangeControlC2CFraudPreventionFilterCharNum(e);
                                }}
                                data-test="text-countryState"
                              />
                            </div>
                            <div className="form-check form-check-inline mar-top25">
                              <label className="form-check-label">or ALL :&nbsp;</label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="countryStateAll"
                                checked={this.state.countryStateAll}
                                value={this.state.countryStateAll}
                                onChange={(e) => {
                                  this.onChangeControlC2CALL(e);
                                }}
                                data-test="text-countryStateAll"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg">
                              <label htmlFor="agent" className="font-weight-bold">Agent</label>
                              <input
                                type="text"
                                maxLength="9"
                                value={this.state.agent}
                                className="form-control form-control-sm"
                                name="agent"
                                onChange={(e) => {
                                  this.onChangeControlC2CFraudPreventionFilterCharNum(e);
                                }}
                                data-test="text-agent"
                              />
                            </div>
                            <div className="form-check form-check-inline mar-top25">
                              <label
                                className="form-check-label"
                                for="flexCheckDefault"
                              >
                                or ALL :&nbsp;
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="agentAll"
                                checked={this.state.agentAll}
                                value={this.state.agentAll}
                                onChange={(e) => {
                                  this.onChangeControlC2CALL(e);
                                }}
                                data-test="text-agentAll"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg">
                              <label htmlFor="agentNetwork" className="font-weight-bold">Agent Network</label>
                              <input
                                type="text"
                                maxLength="5"
                                value={this.state.agentNetwork}
                                className="form-control form-control-sm"
                                name="agentNetwork"
                                onChange={(e) => {
                                  this.onChangeControlC2CFraudPreventionFilterCharNum(e);
                                }}
                                data-test="text-agentNetwork"
                              />
                            </div>
                            <div className="form-check form-check-inline mar-top25">
                              <label className="form-check-label">or ALL :&nbsp;</label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="agentNetworkAll"
                                checked={this.state.agentNetworkAll}
                                value={this.state.agentNetworkAll}
                                onChange={(e) => {
                                  this.onChangeControlC2CALL(e);
                                }}
                                data-test="text-agentNetworkAll"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="receiverCountryState" className="font-weight-bold">
                              RECEIVER:
                              <br></br>
                              Country State:
                            </label>
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.receiverCountryState}
                              className="form-control form-control-sm"
                              name="receiverCountryState"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterCharNum(e);
                              }}
                              data-test="text-receiverCountryState"
                            />
                          </div>
                          <div className="form-check form-check-inline mar-top25">
                            <label className="form-check-label">or ALL :&nbsp;</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="rcvCountryStateAll"
                              checked={this.state.rcvCountryStateAll}
                              value={this.state.rcvCountryStateAll}
                              onChange={(e) => {
                                this.onChangeControlC2CALL(e);
                              }}
                              data-test="text-rcvCountryStateAll"
                            />
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        REGULAR RULE
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="regTran1" className="font-weight-bold">#TRANS</label>
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.regTran1}
                              className="form-control form-control-sm"
                              name="regTran1"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(e);
                              }}
                              data-test="text-regTran1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="regLowAmt1" className="font-weight-bold">LOW AMT</label>
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regLowAmt1}
                              className="form-control form-control-sm"
                              name="regLowAmt1"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regLowAmt1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="regUppAmt1" className="font-weight-bold">UPP AMT</label>
                            <input
                              type="text"
                              maxLength="6"
                              value={this.state.regUppAmt1}
                              className="form-control form-control-sm"
                              name="regUppAmt1"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regUppAmt1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="regMin1" className="font-weight-bold">MINUTES</label>
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.regMin1}
                              className="form-control form-control-sm"
                              name="regMin1"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regMin1"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.regTran2}
                              className="form-control form-control-sm"
                              name="regTran2"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(e);
                              }}
                              data-test="text-regTran2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regLowAmt2}
                              className="form-control form-control-sm"
                              name="regLowAmt2"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regLowAmt2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="6"
                              value={this.state.regUppAmt2}
                              className="form-control form-control-sm"
                              name="regUppAmt2"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regUppAmt2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.regMin2}
                              className="form-control form-control-sm"
                              name="regMin2"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regMin2"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.regTran3}
                              className="form-control form-control-sm"
                              name="regTran3"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(e);
                              }}
                              data-test="text-regTran3"
                              placeholder=""
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regLowAmt3}
                              className="form-control form-control-sm"
                              name="regLowAmt3"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regLowAmt3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="6"
                              value={this.state.regUppAmt3}
                              className="form-control form-control-sm"
                              name="regUppAmt3"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regUppAmt3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.regMin3}
                              className="form-control form-control-sm"
                              name="regMin3"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regMin3"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.regTran4}
                              className="form-control form-control-sm"
                              name="regTran4"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(e);
                              }}
                              data-test="text-regTran4"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.regLowAmt4}
                              className="form-control form-control-sm"
                              name="regLowAmt4"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regLowAmt4"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="6"
                              value={this.state.regUppAmt4}
                              className="form-control form-control-sm"
                              name="regUppAmt4"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regUppAmt4"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="3"
                              value={this.state.regMin4}
                              className="form-control form-control-sm"
                              name="regMin4"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-regMin4"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="floorUsdAmount" className="font-weight-bold">
                          FLOOR USD AMOUNT
                        </label>
                        <input
                          type="text"
                          maxLength="5"
                          value={this.state.floorUsdAmount}
                          className="form-control form-control-sm"
                          name="floorUsdAmount"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterNum(
                              e
                            );
                          }}
                          data-test="text-floorUsdAmount"
                          //placeholder="0"
                        />
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        CATCH ALL RULE
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-lg">
                            <label htmlFor="catchTran1" className="font-weight-bold">#TRANS</label>
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.catchTran1}
                              className="form-control form-control-sm"
                              name="catchTran1"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(e);
                              }}
                              data-test="text-catchTran1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="catchAmt1" className="font-weight-bold">USD AMT</label>
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.catchAmt1}
                              className="form-control form-control-sm"
                              name="catchAmt1"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-catchAmt1"
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="catchMin1" className="font-weight-bold">MINUTES</label>
                            <input
                              type="text"
                              maxLength="4"
                              value={this.state.catchMin1}
                              className="form-control form-control-sm"
                              name="catchMin1"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-catchMin1"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.catchTran2}
                              className="form-control form-control-sm"
                              name="catchTran2"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(e);
                              }}
                              data-test="text-catchTran2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.catchAmt2}
                              className="form-control form-control-sm"
                              name="catchAmt2"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-catchAmt2"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="4"
                              value={this.state.catchMin2}
                              className="form-control form-control-sm"
                              name="catchMin2"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-catchMin2"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="2"
                              value={this.state.catchTran3}
                              className="form-control form-control-sm"
                              name="catchTran3"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(e);
                              }}
                              data-test="text-catchTran3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="5"
                              value={this.state.catchAmt3}
                              className="form-control form-control-sm"
                              name="catchAmt3"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-catchAmt3"
                            />
                          </div>
                          <div className="col-lg">
                            <input
                              type="text"
                              maxLength="4"
                              value={this.state.catchMin3}
                              className="form-control form-control-sm"
                              name="catchMin3"
                              onChange={(e) => {
                                this.onChangeControlC2CFraudPreventionFilterNum(
                                  e
                                );
                              }}
                              data-test="text-catchMin3"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="2"
                          value={this.state.catchTran4}
                          className="form-control form-control-sm"
                          name="catchTran4"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterNum(e);
                          }}
                          data-test="text-catchTran4"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="5"
                          value={this.state.catchAmt4}
                          className="form-control form-control-sm"
                          name="catchAmt4"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterNum(
                              e
                            );
                          }}
                          data-test="text-catchAmt4"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxLength="4"
                          value={this.state.catchMin4}
                          className="form-control form-control-sm"
                          name="catchMin4"
                          onChange={(e) => {
                            this.onChangeControlC2CFraudPreventionFilterNum(
                              e
                            );
                          }}
                          data-test="text-catchMin4"
                        />
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row mar-top5">
                <div className="col-lg-3" />
                <div className="col-lg-6 text-center mar-bottom5">
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackC2CFraudPreventionFilter}
                    data-test="button-back"
                  >
                    FRAUD PRVT
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleAddC2CFraudPreventionFilter}
                    data-test="button-add"
                    disabled={this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    ADD
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    //onClick={this.onHandleUpdateC2CFraudPreventionFilter}
                    onClick={() => {
                      if (!this.onValidateC2CFraudPreventionFilter()) {
                        return;
                      }         
                        this.setState({
                        showC2CFPFUpdateConfirm: true,
                        });
                        }}
                    data-test="button-update"
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    UPDATE
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleClearC2CFraudPreventionFilter}
                    data-test="button-clear"
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    CLEAR
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={() => {
                      this.setState({
                        showC2CFraudPreventionFilterConfirm: true,
                      });
                    }}
                    data-test="button-delete"
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                  >
                    DELETE
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={this.onHandleWhiteC2CFraudPreventionFilter}
                    disabled={!this.state.enableBtn || !this.state.accessGrantedWriteFPF}
                    data-test="button-whitelist"
                  >
                    WHITELIST
                  </button>
                </div>
                <div className="col-lg-3">          
                </div>
              </div>
              <div className="row mar-top10">
                  <div className="col-lg-4"></div>
                  <div className="col-lg-4">
                  {this.state.submitStatusC2CFraudPreventionFilter === "OK" && (
                    <div className="alert alert-success alert-padding notification text-center">
                      {this.state.submitMsgC2CFraudPreventionFilter}
                    </div>
                  )}
                  {this.state.submitStatusC2CFraudPreventionFilter ===
                    "Error" && (
                    <div className="alert alert-danger alert-padding notification text-center">
                      {this.state.submitMsgC2CFraudPreventionFilter}
                    </div>
                  )}
                  </div>
                  <div className="col-lg-4"></div>
              </div>
              {this.getC2CFraudPreventionFilterModal()}
              {this.getC2CFPFUpdateModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  ISO FILTER DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page
                    </label>
                    <select
                      className="form-control  form-control-sm "
                      onChange={(e) => {
                        this.onChangeControlC2CFraudPreventionFilter(e);
                        this.onPageSizeChangedC2CFraudPreventionFilter(e);
                      }}
                      value={this.state.paginationValC2CFraudPreventionFilter}
                      name="paginationDropdown"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionC2CFraudPreventionFilter.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactC2C
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyC2CFraudPreventionFilter}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateC2C
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateC2C
                      }
                    ></AgGridReactC2C>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default C2CFraudPreventionFilter;
