import React, { Component } from "react";
import { connect } from "react-redux";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import Header from "../header/header";
import { doSearchDfdt } from "../../actions/dfdt-search/dfdt-search-action";

class DoddFrankReceiptSearch extends Component {
  state = {
    dfdtSearch: "",
    submitStatusDFDTSearch: null,
    submitMsgDFDTSearch: "",
    showDetails: false,
    julDateAndCtrl: null,
    isLoading: false
  };

  onChangeControlDFDT = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^\d]/, ""),
    });
  };

  onValidateDFDT = () => {
    
    if (this.state.dfdtSearch === "") {
      this.setState({
        submitStatusDFDTSearch: "Error",
        submitMsgDFDTSearch: "Please Enter The Value For MTCN/Control No ",
      });
      return false;
    }
    if (
      this.state.dfdtSearch.toString().length === 10 ||
      this.state.dfdtSearch.toString().length === 16
    ) {
      this.setState({
        submitStatusDFDTSearch: "Ok",
        submitMsgDFDTSearch: "",
      });
      return true;
    } else {
      this.setState({
        submitStatusDFDTSearch: "Error",
        submitMsgDFDTSearch:
          "MTCN Value Should Be 16 Char And Control No Should be 10 Char",
      });
      return false;
    }
  };
  componentDidMount() {
    // console.log(this.props.history.location.searchKey);
    if (this.props.history.location.mtcn) {
        this.setState({
          dfdtSearch: this.props.history.location.searchKey ? this.props.history.location.searchKey:  this.props.history.location.mtcn,
        }, () => {
          this.onHandleSearchDFDT();
        });
    }
    const accessToken = this.props.authState.accessToken;
    const jwt = this.props.authState.accessToken.value;
    try {
      const token = jwt_decode(accessToken.value);
      const accessRole = jwt_decode(accessToken.value).role;
      // console.log(token);
      // console.log(accessRole);
      const accessGrant =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_MTUTILITY) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS);
       
      this.setState({
        accessToken: token,
        role: accessRole,
        accessGrantedDFRS: accessGrant,
        jwtToken:jwt,
      }, () => {
        if(!this.state.accessGrantedDFRS) { 
          this.setState({
            submitStatusDFDTSearch: "Error",
            submitMsgDFDTSearch: "Unauthorized!.Access is denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
    
  }
  onHandleSearchDFDT = (dfrsEvent) => {
    
    if(dfrsEvent) {
      dfrsEvent.preventDefault();
    }
       
    if (!this.onValidateDFDT()) {
      return;
    }
    let submittedObject = {};
    submittedObject.mtcn = this.state.dfdtSearch;
    // Loading Spinner Enabled
    this.setState({isLoading:true})
    this.props.doSearchDfdt(submittedObject, this.state.jwtToken).then(()=> {
       //Loading Spinner Disabled
       this.setState({isLoading:false})
      if(this.props.searchedData.flag === 1) {
         this.setState({
          showDetails: true,
          // julDateAndCtrl: this.props.searchedData.dfr_mtcn_1_6+this.props.searchedData.dfr_mtcn_7_16,
          // searchKey:  this.state.dfdtSearch
        });
      } else {
        this.setState({
          submitStatusDFDTSearch: "Error",
          submitMsgDFDTSearch: this.props.searchedData.message,
        });
      }
     
    });
  };

  onHandleClearDFDT = () => {
    this.setState({
      dfdtSearch: "",
      showDetails: false
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusDFDTSearch: null,
        }),
      3000
    );
  };

  onHandleDetailsDFDT = (mtcn, dfReceiptSourceType) => {
    this.props.history.push({
      pathname: "/df-receipt-details",
      mtcn: mtcn,
      dfReceiptSourceType: dfReceiptSourceType,
      jwtToken: this.state.jwtToken
    });
  };

  render() {
    // console.log("this.props.searchedData");
    // console.log(this.props.searchedData);
    return this.props.authState.isAuthenticated ?(
      <div data-test="receiptSearch-dfdt">
        <Header
          history={this.props.history}
          heading="DODD FRANK RECEIPT SEARCH"
        />
        <div className="container-fluid" data-test="component-dfdt">
          <div className="card mar-top10">
            <div className="card-body">
               <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  DF RECEIPT SEARCH
                </div>
                <div className="card-body card-body-custom">
                  <form onSubmit={this.onHandleSearchDFDT}>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="dfdtSearch" className="font-weight-bold">MTCN/CONTROL Number</label>
                        <input
                          type="text"
                          maxLength="16"
                          value={this.state.dfdtSearch}
                          className="form-control form-control-sm"
                          name="dfdtSearch"
                          onChange={(e) => {
                            this.onChangeControlDFDT(e);
                          }}
                          data-test="text-dfdtSearch"
                          placeholder="MTCN/CONTROL Number"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4 text-center mar-bottom5">
                      {
                        this.state.isLoading ? 
                          <button className="btn btn-warning mar-right10" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                        :
                        (
                          this.state.accessGrantedDFRS ? 
                          <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleSearchDFDT}
                          data-test="button-search"
                        >
                          SEARCH
                        </button> 
                        :
                          <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          data-test="button-search"
                          disabled
                        >
                          SEARCH
                        </button>
                        )
                        
                        }
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearDFDT}
                          data-test="button-clear"
                        >
                          CLEAR
                        </button>
                        <div className="row mar-top10">
                         
                          <div className="col-lg-12">
                          {this.state.submitStatusDFDTSearch === "OK" && (
                            <div className="alert alert-success alert-padding notification">
                                {this.state.submitMsgDFDTSearch}
                              </div>
                            )}
                            {this.state.submitStatusDFDTSearch === "Error" && (
                              <div className="alert alert-danger alert-padding notification">
                                {this.state.submitMsgDFDTSearch}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4"></div>
                    </div>
                  </form>
                </div>
              </div>

              {this.props.searchedData &&
                this.props.searchedData.flag === 1 && this.state.showDetails && (
                  <div className="card mar-top20">
                    <div className="card-header bg-dark text-white card-header-custom">
                      DODD FRANK RECEIPT RESULT
                    </div>
                    <div className="card-body padding-10">
                      <table className="table table-bordered table-sm margin-0">
                        <thead>
                          <tr>
                            <th scope="col">JUL DATE</th>
                            <th scope="col">CNTRL NO</th>
                            <th scope="col">TYPE</th>
                            <th scope="col">SNDR NAME</th>
                            <th scope="col">PAYEE NAME</th>
                            <th scope="col">PRINB</th>
                            <th scope="col">CHARGES</th>
                            <th scope="col">GROSS</th>
                            <th scope="col">PAY CHARGE</th>
                            <th scope="col">PAY PRIN</th>
                            <th scope="col">DIS RATE</th>
                            <th scope="col">DELIV SER</th>
                            <th scope="col">ACCOUNT</th>
                            <th scope="col">D-CNTRY</th>
                            <th scope="col">O-CNTRY</th>
                            <th scope="col">I-DEST</th>
                            <th scope="col">SRC TXN</th>
                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.props.searchedData.records && this.props.searchedData.records.length > 0 && this.props.searchedData.records.map((data, ind) => (
                              <tr key={`${ind}+len`}>
                                <td>{data.dfr_mtcn_1_6}</td>
                                <td>
                                  {data.dfr_mtcn_7_16}
                                </td>
                                <td>{data.dfr_rcpt_src_type}</td>
                                <td>{data.dfr_msndr}</td>
                                <td>{data.dfr_mpayee}</td>
                                <td>{data.dfr_mprinb}</td>
                                <td>{data.dfr_mchargesb}</td>
                                <td>{data.dfr_mgrossb}</td>
                                <td>{data.dfr_pchargeb}</td>
                                <td>{data.dfr_mpayprin}</td>
                                <td>{data.dfr_display_rate}</td>
                                <td>
                                  {data.dfr_dlvysvc}
                                </td>
                                <td>{data.dfr_mcaccount}</td>
                                <td>
                                  {data.dfr_dest_cntry}
                                </td>
                                <td>
                                  {data.dfr_orig_cntry}
                                </td>
                                <td>{data.dfr_intended_dest}</td>
                                <td>{data.dfr_srctxn}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={() => this.onHandleDetailsDFDT(data.dfr_mtcn_1_6+data.dfr_mtcn_7_16,data.dfr_rcpt_src_type)}
                                    data-test="button-dfdtDetails"
                                  >
                                    DETAIL
                                  </button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    ):null;
  }
}
const mapStateToProps = ({ DFDTSearchReducer }) => {
  return {
    searchedData: DFDTSearchReducer.dataInfo.data,
  };
};
export const DoddFrankReceiptSearchRedux = connect(mapStateToProps, { doSearchDfdt })(DoddFrankReceiptSearch);
export default withOktaAuth(DoddFrankReceiptSearchRedux);

