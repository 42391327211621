import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactPED } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AiTwotoneEdit as AiTwotoneEditPED } from "react-icons/ai";
import { IconContext as IconContextPED} from "react-icons";

import { getHeadersProduct } from "../../utility/utility";

class PartnerEmailDetails extends Component {
  state = {
    emailTempValues:[],
    payMethodValues:[],
    partnerIdValues:[],
    agentAcct: "",
    emailTemp: "",
    payMethod: "",
    partnerId: "",
    patnerName: "",
    preference: "W",
    filler: "",
    partnerEmailId: "",
    enableAdd: false,
    showPartnerEmailDetailsConfirm: false,
    allPtnrEmailId: '',
    ptnrGlobalEmailId: '',
    columns: [
      {
        headerName: "ACTION",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {
                if(!this.state.accessGrantedWriteBRM) { 
                  this.setState({
                    submitStatusPartnerEmailDetails: 'Error',
                    submitMsgPartnerEmailDetails: "Update Not Allowed By This Operator",
                  });
                }else{this.handleEditPartnerEmailDetails(params)}
                }}
            >
              <IconContextPED.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditPED />
              </IconContextPED.Provider>
            </div>
          );
        },sortable: false,filter: false,floatingFilter: false,width: "80px",},
      {field: "agentAcct",headerName: "AGENT ACCT",width: "170px",},
      {field: "emailTemp",headerName: "EMAIL TEMPLATE",width: "170px",},
      {field: "payMethod",headerName: "PAY METHOD",width: "170px",},
      {field: "partnerId",headerName: "PTNR ID",width: "170px",},
      {field: "patnerName",headerName: "PTNR NAME",width: "150px",},
      {field: "preference", headerName: "PREFERENCE	",width: "120px",},
      {field: "filler",headerName: "FILLER",width: "200px"},
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValPartnerEmail: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusPartnerEmailDetails: null,
    submitMsgPartnerEmailDetails: "",
    paginationOptionPartnerEmail: [25, 50, 100, 500],
    overlayLoadingTemplatePED:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplatePED: '<span style="">No Records Found</span>',
  };

  onChangeControlPartnerEmail = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlPartnerEmailCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onChangeControlPartnerEmailSelect = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    const { name, value } = e.target;

    if (e.target.name === "patnerName") {
      this.setState({
        [name]: e.nativeEvent.target[index].text,
        partnerId: value,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  componentDidMount = () =>{
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/mcisconstants`,
        {
          headers: {
            ...getHeadersProduct().headers,
            // countryCode: this.props.location.state.data.country.alphaCode,
            Authorization:this.props.location.state.token,
            screenName: "partnerEmail"
          },
        }
      )
      .then((response) => {
          // console.log(response)
          this.setState({
            emailTempValues:response.data.partnerEmailConstants,
            payMethodValues:response.data.paymentMethodConstants,
            partnerIdValues:response.data.partnerIdConstants,
            partnerId: response.data.partnerIdConstants[0].key,
            patnerName: response.data.partnerIdConstants[0].value,
            emailTemp: response.data.partnerEmailConstants[0].key,
          });  
      });
  }

  onGridReadyPartnerEmail = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    this.getAllGridData();
  };

  getAllGridData = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/businessrules/partneremail`,
        {
          headers: {
            ...getHeadersProduct().headers,
            countryCode: this.props.location.state.data.country.alphaCode,
            Authorization:this.props.location.state.token,
          },
        }
      )
      .then((response) => {
        //console.log("GET Values",response)
        let recPartnerEmail = [];
        if (response.data.records !== null) {
          recPartnerEmail = response.data.records;
          this.onHandleSpecialStrings(response.data.records);
        }
        this.setState({
          rows: recPartnerEmail,
          accessGrantedWriteBRM:this.props.location.state.accessGrantedWriteBRM,
        });
      });
  }

  onHandleSpecialStrings = (recPartnerEmail) => {

    let allPtnrEmailIdTemp = '';
    let ptnrGlobalEmailIdTemp = '';
    for(let rec of recPartnerEmail) {
      if(rec.emailTemp === '0') {
        ptnrGlobalEmailIdTemp+=rec.agentAcct+'~';
        allPtnrEmailIdTemp+= '^'+rec.agentAcct+'-'+rec.emailTemp;
      } else {
        ptnrGlobalEmailIdTemp+=rec.agentAcct+'^';
        allPtnrEmailIdTemp+= '^'+rec.agentAcct+'~'+rec.emailTemp;
      }
    }
    this.setState({
      allPtnrEmailId: allPtnrEmailIdTemp,
      ptnrGlobalEmailId:ptnrGlobalEmailIdTemp
    });
  }

  handleEditPartnerEmailDetails = (params) => {
    let data = params.node.data;
    this.setState({
      agentAcct: data.agentAcct,
      emailTemp: data.emailTemp,
      payMethod: data.payMethod,
      partnerId: data.partnerId,
      patnerName: data.patnerName,
      preference: data.preference,
      filler: data.filler,
      enableBtn: true,
      enableAdd: true,
    });
  };

  onPageSizeChangedPartnerEmailDetails = (e) => {
    this.setState({ paginationValPartnerEmail: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackPartnerEmailDetails = () => {
    this.props.history.push({
      pathname: "/display-country-rules",
      state: this.props.location.state,
    });
  };

  onHandleClearPartnerEmailDetails = () => {
    this.setState({
      agentAcct: "",
      emailTemp: "",
      payMethod: "",
      partnerId: "",
      patnerName: "",
      preference: "W",
      filler: "",
      enableBtn: false,
      enableAdd: false
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusPartnerEmailDetails: null,
        }),
      5000
    );
  };

  getPartnerEmailDetails = () => {
    return (
      <div>
        <Modal
          show={this.state.showPartnerEmailDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Partner Email Details For {this.state.agentAcct} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showPartnerEmailDetailsConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeletePartnerEmailDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onValidatePartnerEmailDetails = (operationType) => {

    if(operationType === 'add') {
      if (this.state.agentAcct === "") {
        this.setState({
          submitStatusPartnerEmailDetails: "Error",
          submitMsgPartnerEmailDetails: "Please Enter The Value For Agent Account",
        });
        return false;
      }
      
      if((this.state.emailTemp !== "0" && this.state.allPtnrEmailId.indexOf("^"+this.state.agentAcct+"~"+this.state.emailTemp+"^") !== -1) ||
           (this.state.emailTemp ==="0" && this.state.allPtnrEmailId.indexOf("^"+this.state.agentAcct+"-"+this.state.emailTemp+"^") !== -1) )
        {
          this.setState({
            submitStatusPartnerEmailDetails: "Error",
            submitMsgPartnerEmailDetails: "Partner Agent Email Detail Already Exists",
          });
          return false;
        }
    }
    if(this.state.emailTemp !== "0") {
      if(this.state.ptnrGlobalEmailId.indexOf(this.state.agentAcct+"~") !== -1){
        this.setState({
          submitStatusPartnerEmailDetails: "Error",
          submitMsgPartnerEmailDetails: "Agent Account Has Global Email Template Preference Configured. Please Update Existing Global Template",
        });
        return false;
      }
    
      if(this.state.ptnrGlobalEmailId.indexOf(this.state.agentAcct+"^") === -1){
        this.setState({
          submitStatusPartnerEmailDetails: "Error",
          submitMsgPartnerEmailDetails: "Global Template Has Not Been Configured. Please Create Global Template For Agent Account",
        });
        return false;
      }
      if (this.state.payMethod === "") {
        this.setState({
          submitStatusPartnerEmailDetails: "Error",
          submitMsgPartnerEmailDetails: "Please Select The Value For Payment Method",
        });
        return false;
      }
      if(this.state.preference === "B"){
        this.setState({
          submitStatusPartnerEmailDetails: "Error",
          submitMsgPartnerEmailDetails: "Template Preference WU/PARTNER Email Is Valid For Global Email Template Only",
        });
        return false;
      }
    } else {
      if(this.state.preference !== "B" && this.state.allPtnrEmailId.indexOf("^"+this.state.agentAcct+"~") !== -1){
        this.setState({
          submitStatusPartnerEmailDetails: "Error",
          submitMsgPartnerEmailDetails: "Global Email Template Preference Cannot Be Configured. Please Delete Existing Agent Email Template",
        });
        return false;
      }
      if(this.state.payMethod !== "" && this.state.payMethod !== null){
        this.setState({
          submitStatusPartnerEmailDetails: "Error",
          submitMsgPartnerEmailDetails: "No Payment Method Should Be Selected For Global Email Template",
        });
        return false;
      }
    }
    return true;
  };

  onHandleAddPartnerEmail = () => {
    if (!this.onValidatePartnerEmailDetails('add')) {
      return;
    }
    let submittedObj = {};
    submittedObj.agentAcct = this.state.agentAcct;
    submittedObj.emailTemp = this.state.emailTemp;
    submittedObj.payMethod = this.state.payMethod;
    submittedObj.partnerId = this.state.partnerId;
    submittedObj.patnerName = this.state.patnerName;
    submittedObj.preference = this.state.preference;
    submittedObj.sendCPC = this.props.location.state.countryCode;
    // console.log(submittedObj);
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/businessrules/partneremail`,
        submittedObj,
        {headers: {
            ...getHeadersProduct().headers,
            "x-wu-loginName": this.props.location.state.operatorIdBRM,
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },}
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {          
          this.setState({
            submitStatusPartnerEmailDetails: "OK",
            submitMsgPartnerEmailDetails: response.data.responseString,
          });
          this.getAllGridData();
        } else {
          this.setState({
            submitStatusPartnerEmailDetails: "Error",
            submitMsgPartnerEmailDetails: response.data.responseString,
          });
        }
        this.onHandleClearPartnerEmailDetails();
      });
  };

  onHandleUpdatePartnerEmail = () => {
    if (!this.onValidatePartnerEmailDetails('update')) {
      return;
    }
    let submittedObj = {};
    submittedObj.agentAcct = this.state.agentAcct;
    submittedObj.emailTemp = this.state.emailTemp;
    submittedObj.payMethod = this.state.payMethod;
    submittedObj.partnerId = this.state.partnerId;
    submittedObj.patnerName = this.state.patnerName;
    submittedObj.preference = this.state.preference;
    submittedObj.sendCPC = this.props.location.state.countryCode;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/businessrules/partneremail`,
        submittedObj,
        {
          headers: {
            ...getHeadersProduct().headers,
            "x-wu-loginName": this.props.location.state.operatorIdBRM,
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          this.getAllGridData();
          this.setState({
            submitStatusPartnerEmailDetails: "OK",
            submitMsgPartnerEmailDetails: response.data.responseString,
          });
        } else {
          this.setState({
            submitStatusPartnerEmailDetails: "Error",
            submitMsgPartnerEmailDetails: response.data.responseString,
          });
        }
        this.onHandleClearPartnerEmailDetails();
      });
  };

  onHandleDeletePartnerEmailDetails = () => {
    let submittedObj = {};
    submittedObj.agentAcct = this.state.agentAcct;
    submittedObj.emailTemp = this.state.emailTemp;
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/businessrules/partneremail/${submittedObj.agentAcct}/${submittedObj.emailTemp}`,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          let newRows = this.state.rows.filter((row) => {
            return (
              row.agentAcct + "" + row.emailTemp !==
              submittedObj.agentAcct + "" + submittedObj.emailTemp
            );
          });
          this.onHandleSpecialStrings(newRows);
          this.setState({
            submitStatusPartnerEmailDetails: "OK",
            submitMsgPartnerEmailDetails: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusPartnerEmailDetails: "Error",
            submitMsgPartnerEmailDetails: response.data.responseString,
          });
        }
        this.onHandleClearPartnerEmailDetails();
      });

    this.setState({ showPartnerEmailDetailsConfirm: false });
  };

  getEmailTemp = () => {
    //console.log(this.state.emailTempValues)
    if(this.state.emailTempValues.length > 0) {
      return this.state.emailTempValues.map((item) => (
        <option key={item.value} value={item.key}>
          {item.value}
        </option>
      ));
    } else {
      return null;
    }

  };

  getPayMethod = () => {
    if(this.state.payMethodValues.length > 0) {
      return this.state.payMethodValues.map((item) => (
        <option key={item.value} value={item.key}>
          {item.value}
        </option>
      ));
    } else {
      return null;
    }

  };

  getPartnerId = () => {
    //console.log(this.state.partnerIdValues)
    if(this.state.partnerIdValues.length > 0) {
      return this.state.partnerIdValues.map((item) => (
        <option key={item.value} value={item.key}>
          {item.value}
        </option>
      ));
    } else {
      return null;
    }
  };

  getPreference = () => {
    let preference = [
    { namepreference: "W", labelpreference: "WU EMAIL" },
	  { namepreference: "P", labelpreference: "PARTNER EMAIL" },
	  { namepreference: "B", labelpreference: "WU/PARTNER EMAIL" },
	  { namepreference: "N", labelpreference: "NO EMAILS" },
    ];
    return preference.map((item) => (
      <option key={item.labelpreference} value={item.namepreference}>
        {item.labelpreference}
      </option>
    ));
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="BUSINESS RULES MAINTENANCE"
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                BUSINESS PARTNER EMAIL DETAILS -{" "}
                {this.props.location.state.countryCode}{" "}
              </h4>
              <div className="card ">
                <div className="card-header bg-dark text-white card-header-custom">
                  PARTNER EMAIL DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="agentAcct" className="font-weight-bold">Agent Account</label>
                          <input
                            type="text"
                            maxLength="9"
                            value={this.state.agentAcct}
                            className="form-control form-control-sm"
                            name="agentAcct"
                            disabled={this.state.enableBtn}
                            onChange={(e) => {
                              this.onChangeControlPartnerEmailCharNumber(e);
                            }}
                            data-test="text-agentAcct"
                            placeholder="Agent Account"
                          />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="emailTemp" className="font-weight-bold">Email Template</label>
                          <select
                            className="form-control  form-control-sm "
                            name="emailTemp"
                            data-test="text-emailTemp"
                            onChange={(e) => {
                              this.onChangeControlPartnerEmail(e);
                            }}
                            value={this.state.emailTemp}
                            disabled={this.state.enableBtn}
                          >
                            {this.getEmailTemp()}
                          </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="payMethod" className="font-weight-bold">Payment Method</label>
                        <select
                          className="form-control  form-control-sm "
                          name="payMethod"
                          data-test="text-payMethod"
                          onChange={(e) => {
                            this.onChangeControlPartnerEmail(e);
                          }}
                          value={this.state.payMethod}
                        >
                          {/* <option value="">--Select-- </option> */}
                          {this.getPayMethod()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="patnerName" className="font-weight-bold">Partner Name</label>
                          <select
                            className="form-control  form-control-sm "
                            name="patnerName"
                            data-test="text-patnerName"
                            onChange={(e) => {
                              this.onChangeControlPartnerEmailSelect(e);
                            }}
                            value={this.state.partnerId}
                            disabled={this.state.enableBtn}
                          >
                            {/* <option value="">--Select-- </option> */}
                            {this.getPartnerId()}
                          </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="sendCPC" className="font-weight-bold">Send CPC</label>
                        <input
                          type="text"
                          value={this.props.location.state.countryCode}
                          className="form-control form-control-sm"
                          data-test="text-sendCPC"
                          placeholder="CPC"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="preference" className="font-weight-bold">Template Preference</label>
                        <select
                          className="form-control  form-control-sm "
                          name="preference"
                          data-test="text-preference"
                          onChange={(e) => {
                            this.onChangeControlPartnerEmail(e);
                          }}
                          value={this.state.preference}
                        >
                          {" "}
                          {this.getPreference()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackPartnerEmailDetails}
                          data-test="button-back"
                        >
                          BUSINESS RULE DETAILS
                        </button>

                        {this.state.enableAdd ? (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleUpdatePartnerEmail}
                            data-test="button-save"
                            disabled={!this.state.accessGrantedWriteBRM}
                          >
                            SAVE
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleAddPartnerEmail}
                            data-test="button-add"
                            disabled={!this.state.accessGrantedWriteBRM}

                          >
                            SAVE
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearPartnerEmailDetails}
                          data-test="btn-clear"
                        >
                          CLEAR
                        </button>
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => {
                              this.setState({
                                showPartnerEmailDetailsConfirm: true,
                              });
                            }}
                            data-test="button-delete"
                            disabled={!this.state.enableBtn || !this.state.accessGrantedWriteBRM}
                          >
                            DELETE
                          </button>
                      </div>
                      <div className="col-lg-4">
                      </div>
                    </div>
                    <div className="row mar-top10">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                        {this.state.submitStatusPartnerEmailDetails === "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgPartnerEmailDetails}
                          </div>
                        )}
                        {this.state.submitStatusPartnerEmailDetails === "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgPartnerEmailDetails}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-3"></div>
                      </div>
                  </form>
                </div>
              </div>
              {this.getPartnerEmailDetails()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  PARTNER EMAIL DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page
                    </label>
                    <select
                      className="form-control  form-control-sm "
                      onChange={(e) => {
                        this.onChangeControlPartnerEmail(e);
                        this.onPageSizeChangedPartnerEmailDetails(e);
                      }}
                      value={this.state.paginationValPartnerEmail}
                      name="paginationDropdown"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionPartnerEmail.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactPED
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyPartnerEmail}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplatePED
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplatePED
                      }
                    ></AgGridReactPED>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PartnerEmailDetails;
