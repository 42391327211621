import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { getHeadersProduct } from "../../utility/utility";
import Header from "../../header/header";

class PartnerFraudPreventionDetails extends Component {
  state = {
    allData: [],
    singleData: {
      fraudType: "",
      fraudEntry: "",
      originCountry: "",
      destinationCountry: "",
      principalThreshold: 0,
      fraudAction: "",
      delayMinutes: 0,
    },
    existingData: {},
    count: 0,
    enablePrev: false,
    enableNext: true,
    enableBtn: false,
    enableNew: false, 
    currentMode: null,
    showPartnerFraudPreventionDetailsConfirm: false,
    submitStatusPartnerFraudPreventionDetails: null,
    submitMsgPartnerFraudPreventionDetails: "",
  };

  onChangeControlPartnerFraudPreventionDetails = (e) => {  
    
    const { name, value } = e.target;
    if(name === "originCountry" || name === "destinationCountry") {
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: value.replace(/[^a-zA-Z0-9\s]/, ''),
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: value,
          },
        };
      });
    }
    
  };

  componentDidMount() {
    if (this.props.history.location.mode === "add") {
      this.setState((prevState) => {
        return {
          enableNext: false,
          singleData: {
            ...prevState.singleData,
            fraudType: this.props.history.location.state.fraudType,
            fraudEntry: this.props.history.location.state.fraudEntry,
            originCountry: this.props.history.location.state.originCountry,
            destinationCountry: this.props.history.location.state.destinationCountry,
            accessGrantedWritePFP:this.props.location.state.accessGrantedWritePFP
          },
        };
      });
    } else {
      this.setState({
        allData:this.props.history.location.records,
        singleData: this.props.history.location.records[0],
        enableNext: this.props.history.location.records.length > 1 ? true: false,
        accessGrantedWritePFP:this.props.location.state.accessGrantedWritePFP
      });
    }
  }

  onHandleNewPartnerFraudPreventionDetails = () => {
    
    this.setState((prevState) => {
      return {
        enableNew: true,
        currentMode: true,
        existingData : this.state.singleData,
        singleData: {
          ...prevState.singleData,
          originCountry: "",
          destinationCountry: "",
          principalThreshold: 0,
          delayMinutes: 0,
          fraudAction: "D",
        },
      }
    });
  };

  onHandlePartnerFraudPreventionDetails = (type) => {
    if (type === "prev") {
      this.setState(
        {
          count: this.state.count - 1,
        },
        () => {
          this.setState({
            enableNext: true,
            singleData: this.state.allData[this.state.count],
          });
          if (this.state.count === 0) {
            this.setState({
              enablePrev: false,
              enableNext: true,
            });
          }
        }
      );
    } else {
      if (this.state.count < this.state.allData.length - 1) {
        this.setState(
          {
            count: this.state.count + 1,
          },
          () => {
            this.setState({
              enablePrev: true,
              singleData: this.state.allData[this.state.count],
            });
            if (this.state.count === this.state.allData.length - 1) {
              this.setState({
                enablePrev: true,
                enableNext: false,
              });
            }
          }
        );
      }
    }
  };


  getPartnerFraudPreventionDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showPartnerFraudPreventionDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure,You Want To Delete Fraud Entry {this.state.singleData.fraudEntry} Details ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showPartnerFraudPreventionDetailsConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeletePartnerFraudPreventionDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeletePartnerFraudPreventionDetails = () => {
    // console.log(this.state.singleData);
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/partnerfraudprevention/details/${this.state.singleData.fraudType}/${this.state.singleData.fraudEntry}`,{
        headers:{
          ...getHeadersProduct().headers,
          originCountry: this.state.singleData.originCountry,
          destinationCountry: this.state.singleData.destinationCountry,
          Authorization:this.props.location.state.token
        }
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            submitStatusPartnerFraudPreventionDetails: "OK",
            submitMsgPartnerFraudPreventionDetails:
              response.data.responseString,
          });
          this.onHandleRedirectWithTime();
        } else {
          this.setState({
            submitStatusPartnerFraudPreventionDetails: "Error",
            submitMsgPartnerFraudPreventionDetails:
              response.data.responseString,
          });
        }
      });
      this.setState({ showPartnerFraudPreventionDetailsConfirm: false });
    
  };

  onHandlePartnerFraudPreventionMenu = () => {
    this.props.history.push({
      pathname: "/partner-fraud-prevention",
      state: this.state,
    });
  };

  onValidatePartnerFraudPreventionDetail = () => {
    if (this.state.singleData.originCountry && this.state.singleData.originCountry.length !== 3) {
      this.setState({
        submitStatusPartnerFraudPreventionDetails: "Error",
        submitMsgPartnerFraudPreventionDetails:
          "Please Check The Origin Country",
      });
      return false;
    }
    if (
      this.state.singleData.destinationCountry &&
      this.state.singleData.destinationCountry.length !== 3
    ) {
      this.setState({
        submitStatusPartnerFraudPreventionDetails: "Error",
        submitMsgPartnerFraudPreventionDetails:
          "Please Check The Destination Country",
      });
      return false;
    }
    if (this.state.singleData.fraudAction === "") {
      this.setState({
        submitStatusPartnerFraudPreventionDetails: "Error",
        submitMsgPartnerFraudPreventionDetails: "Please Select Fraud Action",
      });
      return false;
    }
    if (
      this.state.singleData.fraudAction === "D" &&
      this.state.singleData.delayMinutes === 0
    ) {
      this.setState({
        submitStatusPartnerFraudPreventionDetails: "Error",
        submitMsgPartnerFraudPreventionDetails:
          "Delay Minutes Must Be Greater Than 0 When 'Delay' Option Is Selected",
      });
      return false;
    }
    return true;
  };

  onHandleAddUpdatePFPD = (modePFPD) => {
    if(!this.onValidatePartnerFraudPreventionDetail()) {
      return;
    }
    let submittedObj = {};
    submittedObj.fraudType = this.state.singleData.fraudType;
    submittedObj.fraudEntry = this.state.singleData.fraudEntry;
    submittedObj.originCountry = this.state.singleData.originCountry;
    submittedObj.destinationCountry = this.state.singleData.destinationCountry;
    submittedObj.principalThreshold = this.state.singleData.principalThreshold;
    submittedObj.fraudAction = this.state.singleData.fraudAction;
    submittedObj.delayMinutes = this.state.singleData.delayMinutes;

    if(modePFPD === 'add' || this.state.enableNew) {
      axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/partnerfraudprevention/details`, submittedObj, 
      {
        headers:{
          ...getHeadersProduct().headers,
          Authorization:this.props.location.state.token,
          "userId": this.props.location.state.operatorIdPFP,
        }
      })
      .then((response) => {
        if (response.status === 200) {
          if(this.state.enableNew) {
            if(response.data.responseString === 'Record Already Exists') {
              this.setState({
                singleData: this.state.existingData,
                enableNew: false, 
                currentMode: null,
              });
            } else {
              this.setState({
                enableNew: false, 
                currentMode: null,
                allData: [...this.state.allData, submittedObj],
              }, ()=> {
                this.setState({
                  count: this.state.allData.length - 1,
                  enablePrev: this.state.allData.length > 1 ? true: false,
                });
              });
            }
          } else {
            this.onHandleRedirectWithTime();
          }
          this.onHandleMessagePFPD(response.data.responseString, "OK");
         
        } else {
          this.onHandleMessagePFPD(response.data.responseString, "Error");
        }
        this.onHandleClearMessagePFPD();
      });

    } else {
      axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/partnerfraudprevention/details`, submittedObj,
      {
        headers:{
          ...getHeadersProduct().headers,
          Authorization:this.props.location.state.token,
          "userId": this.props.location.state.operatorIdPFP,
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.allData.findIndex(
            (row) => (row.fraudType === submittedObj.fraudType && row.fraudEntry === submittedObj.fraudEntry && row.originCountry === submittedObj.originCountry && row.destinationCountry === submittedObj.destinationCountry)
          );
          const updatedRows = [
            ...this.state.allData.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.allData.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            allData: updatedRows,
          });
          this.onHandleMessagePFPD(response.data.responseString, "OK");

        } else {
          this.onHandleMessagePFPD(response.data.responseString, "Error");
        }
        this.onHandleClearMessagePFPD();
      });

    }
  }

  onHandleMessagePFPD = (responseString, responseType) => {
    this.setState({
      submitStatusPartnerFraudPreventionDetails: responseType,
      submitMsgPartnerFraudPreventionDetails:responseString
    });
  }

  onHandleClearMessagePFPD = () => {
    setTimeout(() => this.onHandleMessagePFPD(null, ""),5000);
  }

  onHandleRedirectWithTime = () => {
    setTimeout(() =>this.onHandlePartnerFraudPreventionMenu(),5000);
  }

  onClickFraudActionStop = () => {
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          delayMinutes: 0,
        },
      };
    });
  }

  render() {
    //console.log(this.props.location.records.accessGrantedWritePFP)
    return (
      <>
        <Header
          history={this.props.history}
          heading="PARTNER FRAUD PREVENTION"
        />
        <div className="container-fluid">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-2" />
              <div className="col-lg-8">
                <div className="card mar-top20">
                  <div className="card-header bg-dark text-white card-header-custom">
                    PARTNER FRAUD PREVENTION DETAILS
                  </div>
                  <div className="card-body card-body-custom">
                    <div className="form-group row">
                      <div className="col-lg-2" />
                      <div className="col-lg-8">
                        {this.state
                          .submitStatusPartnerFraudPreventionDetails ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgPartnerFraudPreventionDetails}
                          </div>
                        )}
                        {this.state
                          .submitStatusPartnerFraudPreventionDetails ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgPartnerFraudPreventionDetails}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-2" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg">
                        <label htmlFor="fraudType" className="font-weight-bold">Fraud Type</label>
                        <input
                          type="text"
                          value={this.state.singleData.fraudType}
                          className="form-control form-control-sm"
                          name="fraudType"
                          disabled
                          data-test="text-fraudType"
                          placeholder="Fraud Type"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="fraudEntry" className="font-weight-bold">Fraud Entry</label>
                          <input
                            type="text"
                            value={this.state.singleData.fraudEntry}
                            className="form-control form-control-sm"
                            name="fraudEntry"
                            disabled
                            data-test="text-fraudEntry"
                            placeholder="Agent Account/Network"
                          />
                      </div>
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg">
                        <label htmlFor="originCountry" className="font-weight-bold">Origin Country</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={this.state.singleData.originCountry}
                            className="form-control form-control-sm"
                            name="originCountry"
                            onChange={(e) => {
                              this.onChangeControlPartnerFraudPreventionDetails(
                                e
                              );
                            }}
                            disabled = {!this.state.enableNew}
                            data-test="text-originCountry"
                            placeholder="Origin Country"
                          />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="destinationCountry" className="font-weight-bold">
                          Destination Country
                        </label>
                          <input
                            type="text"
                            maxLength="3"
                            value={this.state.singleData.destinationCountry}
                            className="form-control form-control-sm"
                            name="destinationCountry"
                            onChange={(e) => {
                              this.onChangeControlPartnerFraudPreventionDetails(
                                e
                              );
                            }}
                            disabled = {!this.state.enableNew}
                            data-test="text-destinationCountry"
                            placeholder="Destination Country"
                          />
                      </div>
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg">
                        <label htmlFor="principalThreshold" className="font-weight-bold">
                          Principal Threshold
                        </label>
                        <input
                          type="text"
                          maxLength="5"
                          value={this.state.singleData.principalThreshold}
                          className="form-control form-control-sm"
                          name="principalThreshold"
                          onChange={(e) => {
                            this.onChangeControlPartnerFraudPreventionDetails(
                              e
                            );
                          }}
                          data-test="text-principalThreshold"
                          placeholder="Principal Threshold"
                        />
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Fraud Action</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="fraudAction"
                              value="D"
                              data-test="text-fraudAction-delay"
                              checked={
                                this.state.singleData.fraudAction === "D"
                              }
                              onChange={(e) => {
                                this.onChangeControlPartnerFraudPreventionDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="delay">
                              Delay
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="fraudAction"
                              value="S"
                              data-test="text-fraudAction-stop"
                              checked={
                                this.state.singleData.fraudAction === "S"
                              }
                              onChange={(e) => {
                                this.onChangeControlPartnerFraudPreventionDetails(
                                  e
                                );
                              }}
                              onClick = {this.onClickFraudActionStop}
                            />
                            <label className="form-check-label" htmlFor="stop">
                              Stop
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg">
                        <label htmlFor="delayMinutes" className="font-weight-bold">Delay Minutes</label>
                          <input
                            type="text"
                            maxLength="5"
                            value={this.state.singleData.delayMinutes}
                            className="form-control form-control-sm"
                            name="delayMinutes"
                            onChange={(e) => {
                              this.onChangeControlPartnerFraudPreventionDetails(
                                e
                              );
                            }}
                            data-test="text-delayMinutes"
                            placeholder="Principal Threshold"
                          />
                      </div>
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          data-test="button-new"
                          onClick={
                            this.onHandleNewPartnerFraudPreventionDetails
                          }
                          disabled={((this.props.history.location.mode === "add" || this.state.currentMode) ? true: false) || !this.props.location.state.accessGrantedWritePFP}
                        >
                          NEW
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          data-test="button-delete"
                          onClick={() => {
                            this.setState({
                              showPartnerFraudPreventionDetailsConfirm: true,
                            });
                          }}
                          disabled={((this.props.history.location.mode === "add" || this.state.currentMode) ? true: false) || !this.props.location.state.accessGrantedWritePFP}
                        >
                          DELETE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          data-test="button-update"
                          onClick= {() => this.onHandleAddUpdatePFPD(this.props.history.location.mode)}
                          disabled ={!this.props.location.state.accessGrantedWritePFP}
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="col-lg-3" />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3" />
                  <div className="col-lg-6 text-center mar-top20 ">
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-back"
                      onClick={this.onHandlePartnerFraudPreventionMenu}
                    >
                      PARTNER FRAUD MENU
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-previous"
                      onClick={() =>
                        this.onHandlePartnerFraudPreventionDetails("prev")
                      }
                     disabled={!this.state.enablePrev}
                    >
                      PREVIOUS
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning"
                      data-test="button-next"
                      onClick={() =>
                        this.onHandlePartnerFraudPreventionDetails("next")
                      }
                      disabled={!this.state.enableNext}
                    >
                      NEXT
                    </button>
                  </div>
                  <div className="col-lg-3" />
                </div>
              </div>
              <div className="col-lg-2" />
            </div>
            {this.getPartnerFraudPreventionDetailsModal()}
          </div>
        </div>
      </>
    );
  }
}
export default PartnerFraudPreventionDetails;
