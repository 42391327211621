import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import axios from "axios";
import { getHeadersOrder } from "../../utility/utility";

class MoneyTransferSpecialHandlingRecord extends Component {
  state = {
    mtcn: "",

    columns: [
      {
        field: "type",
        headerName: "Type",
        width: '100px'
      },
      {
        field: "account",
        headerName: "Account",
        width: '101px'
      },
      {
        field: "hp",
        headerName: "H/P",
        width: '102px'
      },
      {
        field: "site",
        headerName: "Site",
        width: '103px'
      },
      {
        field: "opId",
        headerName: "Opid",
        width: '104px' 
      },
      {
        field: "term",
        headerName: " Term",
        width: '105px'
      },
      {
        field: "dateTime",
        headerName: "Date/Time ",
        width: '130px',
        filter: false,
      },
      {
        field: "action",
        headerName: "Action",
        width: '290px',
        wrapText: true,  
        autoHeight: true, 
      },
    ],
    paginationValHistoryMTCN: "25",
    rows: null,
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    overlayLoadingTemplateHistory:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateHistory:
    '<span style="">No Records Found</span>',
    paginationOptionHistoryMTCN: [25, 50, 100, 500],
  };

  onPageSizeChangedHistoryMTCN = (e) => {
    this.setState({ paginationValHistoryMTCN: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onChangeControlHistoryMTCN = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      mtcn: this.props.history.location.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
  }

  onGridReady = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    let submittedObj = {};
    submittedObj.mtcn = this.state.mtcn;
    axios
    .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/history`,submittedObj,
    {
      headers: {
        ...getHeadersOrder().headers,
        Authorization: this.state.jwtToken
      },
    })
      .then((response) => {
        if(response.data.flag === 1) {
          this.setState({
            rows: response.data.records
          });
        } else {
          this.setState({
            rows:[],
            overlayNoRowsTemplateHistory:`<span style="">${response.data.message}</span>`
          });
        }
      });
  };

  onHandleBackHistoryMTCNDetails = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackHistoryMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  render() {
    return (
      <div>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <div className="card-body">
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                Money Transfer Special Handling Record
              </div>
              <div className="card-body card-body-custom">
                <div className="form-row">
                  <div className="col-lg-1" />
                  <div className="col-lg-4">
                    <div className="mtcn-placing">
                      <label htmlFor="mtcn"><span className="font-weight-bold">MTCN : </span>{this.state.mtcn}</label>
                    </div>
                  </div>
                  <div className="col-lg-7" />
                </div>
                <div className="row">
                  <div className="col-lg-1" />
                  <div className="col-lg-3">
                    <div className="pagination-dropdown">
                      <label htmlFor="pagination-select-label" className="font-weight-bold">
                        No of records per page:
                      </label>
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          this.onChangeControlHistoryMTCN(e);
                          this.onPageSizeChangedHistoryMTCN(e);
                        }}
                        value={this.state.paginationValHistoryMTCN}
                        name="paginationDropdownHistoryMTCN"
                        data-test="select-dropdown"
                      >
                        {this.state.paginationOptionHistoryMTCN.map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6" />      
                </div>
                <div className="d-flex justify-content-center ">
                  <div
                    className="ag-theme-balham "
                    style={{ height: 290, width: "83%" }}
                  >
                    <AgGridReact
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReady}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplateHistory}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplateHistory}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                    ></AgGridReact>
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button
                    type="button"
                    className="btn btn-warning mr-2"
                    onClick={this.onHandleBackHistoryMTCNDetails}
                    data-test='MTCNDetails-button-back'
                  >
                    MTCN Details
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackHistoryMTCNSearch}
                    data-test='Search-button-back'
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MoneyTransferSpecialHandlingRecord;
