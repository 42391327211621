import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactGPD } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditGPD } from "react-icons/ai";
import { IconContext as IconContextGPD } from "react-icons";
import { getHeadersShared2 } from "../../utility/utility";

class GspParamDetails extends Component {
  state = {
    gspId: "",
    paramId: "",
    paramFields: "",
    tempParamFields: '',
    showGspParamDetailsConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={
                () => {
                    if(!this.props.location.state.accessGrantedWriteTMD2) { 
                    this.setState({
                      submitStatusGspParamDetails: 'Error',
                      submitMsgGspParamDetails: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                    });
                  }else{
                    this.handleEditGspParamDetails(params)
                  }  
              }
            }
            >
              <IconContextGPD.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditGPD />
              </IconContextGPD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "100px",
      },
      { field: "paramId", headerName: "Param Id:", width: "535px" },
      { field: "paramFields", headerName: "Param Fields:", width: "575px" },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValGsp: "25",
    defaultColumnDef: { resizable: true, sortable: true, filter: true, floatingFilter: true },
    submitStatusGspParamDetails: null,
    submitMsgGspParamDetails: "",
    paginationOptionGspParamDetails: [25, 50, 100, 500],
    editable: true,
    overlayLoadingTemplateGPD:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateGPD: '<span style="">No Records Found</span>',
  };

  onChangeControlGspParamDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onGridReadyGspParamDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/gspparam`, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        this.setState({
          rows: response.data.records,
        });
      });
  };

  handleEditGspParamDetails = (params) => {
    let data = params.node.data;
    this.setState({
      gspId: data.paramId,
      paramId: data.paramId,
      paramFields: data.paramFields,
      tempParamFields: data.paramFields,
      enableBtn: true,
      editable: false
    });
  };

  onPageSizeChangedGspParamDetails = (e) => {
    this.setState({ paginationValGsp: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackGspParamDetails = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  onValidateGspParamDetails = () => {
    if (this.state.paramId === "") {
      this.setState({
        submitStatusGspParamDetails: "Error",
        submitMsgGspParamDetails: "Please Enter The Value For Gsp Param Id",
      });
      return false;
    }
    if (this.state.paramFields === "") {
      this.setState({
        submitStatusGspParamDetails: "Error",
        submitMsgGspParamDetails: "Please Enter The Value For GSP Fields",
      });
      return false;
    }
    return true;
  };
  onHandleAddGspParamDetails = () => {
    if (!this.onValidateGspParamDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.gspId = "";
    submittedObj.paramId = this.state.paramId;
    submittedObj.paramFields = this.state.paramFields;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/gspparam`, submittedObj, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.gspId = this.state.paramId;
          this.setState({
            submitStatusGspParamDetails: "OK",
            submitMsgGspParamDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
         
        } else {
          this.setState({
            submitStatusGspParamDetails: "Error",
            submitMsgGspParamDetails: response.data.responseString,
          });
        }
        this.onHandleClearGspParamDetails();
      });
  };

  onHandleUpdateGspParamDetails = () => {
    if (!this.onValidateGspParamDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.gspId = this.state.gspId;
    submittedObj.paramId = this.state.paramId;
    submittedObj.paramFields = this.state.paramFields;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/gspparam`, submittedObj, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
     })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.paramId === submittedObj.gspId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusGspParamDetails: "OK",
            submitMsgGspParamDetails: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusGspParamDetails: "Error",
            submitMsgGspParamDetails: response.data.responseString,
          });
        }
        this.onHandleClearGspParamDetails();
      });
  };

  onHandleClearGspParamDetails = () => {
    this.setState({
      gspId: "",
      paramId: "",
      paramFields: "",
      enableBtn: false,
      editable: true
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusGspParamDetails: null,
        }),
      5000
    );
  };

  getGspParamDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showGspParamDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Gsp Param Id {this.state.paramId} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showGspParamDetailsConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteGspParamDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  displayNumbers = () =>{
    let allNumbers = ['1','2','3','4','5','6','7','8','9','10','1','2','3','4','5','6','7','8','9','20','1','2','3','4','5','6','7','8','9','30','1','2','3','4','5','6','7','8','9','40','1','2','3','4','5','6','7','8','9','50','1','2','3','4','5','6','7','8','9','60','1','2','3','4','5','6','7','8','9','70','1','2','3','4','5','6','7','8','9','80'];
    return allNumbers.map((data, gspI) => <td key={gspI} className="padding-4">{data}</td>);
  }

  displayDynamicData = () => {
    let paramFieldsArr = this.state.paramFields.split("");
    let tempParamFieldArr = this.state.tempParamFields.split("");
     return paramFieldsArr.map((data, i)=>{
      //  console.log(paramFieldsArr[i]+' '+tempParamFieldArr[i]);
        if(paramFieldsArr[i] === tempParamFieldArr[i]) {
          return <td key={i} className="padding-4 text-center">{data}</td>
        } else {
          return <td key={i} className="padding-4 red text-center">{data}</td>
        }
      });
  }

  onHandleDeleteGspParamDetails = () => {
    let submittedObj = {};
    submittedObj.gspId = this.state.gspId;
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/gspparam/${submittedObj.gspId}`, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.paramId !== submittedObj.gspId;
          });

          this.setState({
            submitStatusGspParamDetails: "OK",
            submitMsgGspParamDetails: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusGspParamDetails: "Error",
            submitMsgGspParamDetails: response.data.responseString,
          });
        }
        this.onHandleClearGspParamDetails();
      });
    this.setState({ showGspParamDetailsConfirm: false });
  };
  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WU TMON FILE MAINTENANCE"
        />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">GSP PARAM DETAILS</h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  GSP PARAM FORM
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="paramId" className="font-weight-bold">GSP Param Id</label>
                        <input
                          type="text"
                          maxLength="4"
                          value={this.state.paramId}
                          className="form-control form-control-sm"
                          name="paramId"
                          onChange={(e) => {
                            this.onChangeControlGspParamDetails(e);
                          }}
                          data-test="text-paramId"
                          placeholder="GSP Param Id"
                          disabled={!this.state.editable}
                        />
                        <div className="col-lg-8"></div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="paramFields" className="font-weight-bold">GSP Fields</label>
                        <input
                          type="text"
                          maxLength="80"
                          value={this.state.paramFields}
                          className="form-control form-control-sm"
                          name="paramFields"
                          onChange={(e) => {
                            this.onChangeControlGspParamDetails(e);
                          }}
                          onBlur = {this.onShowDynamicData}
                          data-test="text-paramFields"
                          placeholder="GSP Fields"
                        />
                        <div class="table-responsive">
                          <table className="table table-sm table-borderless"> 
                            <tbody>
                              <tr>{this.displayNumbers()}</tr>
                              <tr>{this.displayDynamicData()}</tr>
                            </tbody>
                          </table>
                        </div>
                        <lable className="font-weight-bold">Select Host Id:	 SINGLE HOST</lable>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackGspParamDetails}
                          data-test="button-back"
                        >
                          TMON MAINT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddGspParamDetails}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateGspParamDetails}
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearGspParamDetails}
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showGspParamDetailsConfirm: true,
                            });
                          }}
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                          data-test="button-delete"
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                          {this.state.submitStatusGspParamDetails === "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgGspParamDetails}
                            </div>
                          )}
                          {this.state.submitStatusGspParamDetails === "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgGspParamDetails}
                            </div>
                          )}
                          </div>
                          <div className="col-lg-2"></div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getGspParamDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  GSP PARAM DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlGspParamDetails(e);
                        this.onPageSizeChangedGspParamDetails(e);
                      }}
                      value={this.state.paginationValGsp}
                      name="paginationDropdownGsp"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionGspParamDetails.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactGPD
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyGspParamDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateGPD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateGPD
                      }
                    ></AgGridReactGPD>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GspParamDetails;
