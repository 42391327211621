import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactCSM } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditCSM } from "react-icons/ai";
import { IconContext as IconContextCSM } from "react-icons";
import { getHeadersShared2 } from "../../utility/utility";

class CntryStateMaintenance extends Component {
  state = {
    ctryStateId: "",
    stateCode: "",
    adjStateId1: "",
    adjStateId2: "",
    adjStateId3: "",
    adjStateId4: "",
    adjStateId5: "",
    adjStateId6: "",
    adjStateId7: "",
    adjStateId8: "",
    adjStateId9: "",
    adjStateId10: "",
    adjStateId11: "",
    adjStateId12: "",
    adjStateId13: "",
    adjStateId14: "",
    adjStateId15: "",
    flag: "",
    showCountryStateMaintenanceConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={
                () => {
                    if(!this.props.location.state.accessGrantedWriteTMD2) { 
                    this.setState({
                      submitStatusCountryStateMaintenance: 'Error',
                      submitMsgCountryStateMaintenance: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                    });
                  }else{
                    this.handleEditCountryStateMaintenance(params)
                  }  
              }
            }
            >
              <IconContextCSM.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditCSM />
              </IconContextCSM.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      { field: "stateCode", headerName: "STDCODE", width: 100 },
      { field: "adjStateId1", headerName: "FF1", width: 80 },
      { field: "adjStateId2", headerName: "FF2", width: 80 },
      { field: "adjStateId3", headerName: "FF3", width: 80 },
      { field: "adjStateId4", headerName: "FF4", width: 80 },
      { field: "adjStateId5", headerName: "FF5", width: 80 },
      { field: "adjStateId6", headerName: "FF6", width: 80 },
      { field: "adjStateId7", headerName: "FF7", width: 80 },
      { field: "adjStateId8", headerName: "FF8", width: 80 },
      { field: "adjStateId9", headerName: "FF9", width: 80 },
      { field: "adjStateId10", headerName: "FF10", width: 80 },
      { field: "adjStateId11", headerName: "FF11", width: 80 },
      { field: "adjStateId12", headerName: "FF12", width: 80 },
      { field: "adjStateId13", headerName: "FF13", width: 80 },
      { field: "adjStateId14", headerName: "FF14", width: 80 },
      { field: "adjStateId15", headerName: "FF15", width: 80 },
      { field: "flag", headerName: "FLAG", width: 80 },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValCountryStateMaintenance: "25",
    defaultColumnDef: { resizable: true, sortable: true, filter: true, floatingFilter: true },
    submitStatusCountryStateMaintenance: null,
    submitMsgCountryStateMaintenance: "",
    paginationOptionCountryStateMaintenance: [25, 50, 100, 500],
    editable: true,
    overlayLoadingTemplateCSM:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateCSM: '<span style="">No Records Found</span>',
  };

  onChangeControlCountryStateMaintenance = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeControlCountryStateMaintenanceCharNumber  = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onGridReadyCountryStateMaintenance = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/statecode`, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        this.setState({
          rows: response.data.records,
        });
      });
  };

  handleEditCountryStateMaintenance = (params) => {
    let data = params.node.data;
    this.setState({
      ctryStateId: data.stateCode,
      stateCode: data.stateCode,
      adjStateId1: data.adjStateId1,
      adjStateId2: data.adjStateId2,
      adjStateId3: data.adjStateId3,
      adjStateId4: data.adjStateId4,
      adjStateId5: data.adjStateId5,
      adjStateId6: data.adjStateId6,
      adjStateId7: data.adjStateId7,
      adjStateId8: data.adjStateId8,
      adjStateId9: data.adjStateId9,
      adjStateId10: data.adjStateId10,
      adjStateId11: data.adjStateId11,
      adjStateId12: data.adjStateId12,
      adjStateId13: data.adjStateId13,
      adjStateId14: data.adjStateId14,
      adjStateId15: data.adjStateId15,
      flag: data.flag,
      enableBtn: true,
      editable: false
    });
  };

  onPageSizeChangedCountryStateMaintenance = (e) => {
    this.setState({ paginationValCountryStateMaintenance: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackCountryStateMaintenance = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  onValidateCountryStateMaintenance = () => {
    if (this.state.stateCode === "") {
      this.setState({
        submitStatusCountryStateMaintenance: "Error",
        submitMsgCountryStateMaintenance: "Please Enter The Value For State Code",
      });
      return false;
    }
    return true;
  };

  onHandleAddCountryStateMaintenance = () => {
    if (!this.onValidateCountryStateMaintenance()) {
      return;
    }
    let submittedObj = {};
    submittedObj.ctryStateId = "";
    submittedObj.stateCode = this.state.stateCode;
    submittedObj.adjStateId1 = this.state.adjStateId1;
    submittedObj.adjStateId2 = this.state.adjStateId2;
    submittedObj.adjStateId3 = this.state.adjStateId3;
    submittedObj.adjStateId4 = this.state.adjStateId4;
    submittedObj.adjStateId5 = this.state.adjStateId5;
    submittedObj.adjStateId6 = this.state.adjStateId6;
    submittedObj.adjStateId7 = this.state.adjStateId7;
    submittedObj.adjStateId8 = this.state.adjStateId8;
    submittedObj.adjStateId9 = this.state.adjStateId9;
    submittedObj.adjStateId10 = this.state.adjStateId10;
    submittedObj.adjStateId11 = this.state.adjStateId11;
    submittedObj.adjStateId12 = this.state.adjStateId12;
    submittedObj.adjStateId13 = this.state.adjStateId13;
    submittedObj.adjStateId14 = this.state.adjStateId14;
    submittedObj.adjStateId15 = this.state.adjStateId15;
    submittedObj.flag = this.state.flag;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/statecode`, submittedObj,   {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseString ==='Record Added Successfully') {
          submittedObj.ctryStateId = this.state.stateCode;
          this.setState({
            submitStatusCountryStateMaintenance: "OK",
            submitMsgCountryStateMaintenance: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          this.onHandleClearCountryStateMaintenance();
        } else {
          this.setState({
            submitStatusCountryStateMaintenance: "Error",
            submitMsgCountryStateMaintenance: response.data.responseString,
          });
          this.onHandleClearCountryStateMaintenance();
        }
      });
  };

  onHandleUpdateCountryStateMaintenance = () => {
    if (!this.onValidateCountryStateMaintenance()) {
      return;
    }
    let submittedObj = {};
    submittedObj.ctryStateId = this.state.stateCode;
    submittedObj.stateCode = this.state.stateCode;
    submittedObj.adjStateId1 = this.state.adjStateId1;
    submittedObj.adjStateId2 = this.state.adjStateId2;
    submittedObj.adjStateId3 = this.state.adjStateId3;
    submittedObj.adjStateId4 = this.state.adjStateId4;
    submittedObj.adjStateId5 = this.state.adjStateId5;
    submittedObj.adjStateId6 = this.state.adjStateId6;
    submittedObj.adjStateId7 = this.state.adjStateId7;
    submittedObj.adjStateId8 = this.state.adjStateId8;
    submittedObj.adjStateId9 = this.state.adjStateId9;
    submittedObj.adjStateId10 = this.state.adjStateId10;
    submittedObj.adjStateId11 = this.state.adjStateId11;
    submittedObj.adjStateId12 = this.state.adjStateId12;
    submittedObj.adjStateId13 = this.state.adjStateId13;
    submittedObj.adjStateId14 = this.state.adjStateId14;
    submittedObj.adjStateId15 = this.state.adjStateId15;
    submittedObj.flag = this.state.flag;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/statecode`, submittedObj,  {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
     })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.stateCode === submittedObj.ctryStateId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusCountryStateMaintenance: "OK",
            submitMsgCountryStateMaintenance: response.data.responseString,
            rows: updatedRows,
          });
          this.onHandleClearCountryStateMaintenance();
        } else {
          this.setState({
            submitStatusCountryStateMaintenance: "Error",
            submitMsgCountryStateMaintenance: response.data.responseString,
          });
          this.onHandleClearCountryStateMaintenance();
        }
      });
  };

  onHandleClearCountryStateMaintenance = () => {
    this.setState({
      ctryStateId: "",
      stateCode: "",
      adjStateId1: "",
      adjStateId2: "",
      adjStateId3: "",
      adjStateId4: "",
      adjStateId5: "",
      adjStateId6: "",
      adjStateId7: "",
      adjStateId8: "",
      adjStateId9: "",
      adjStateId10: "",
      adjStateId11: "",
      adjStateId12: "",
      adjStateId13: "",
      adjStateId14: "",
      adjStateId15: "",
      flag: "",
      enableBtn: false,
      editable: true
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusCountryStateMaintenance: null,
        }),
      5000
    );
  };

  getCountryStateMaintenanceModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showCountryStateMaintenanceConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete State Code {this.state.stateCode} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showCountryStateMaintenanceConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteCountryStateMaintenance}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteCountryStateMaintenance = () => {
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/deletestatecode/${this.state.stateCode}`,  {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.stateCode !== this.state.stateCode;
          });

          this.setState({
            submitStatusCountryStateMaintenance: "OK",
            submitMsgCountryStateMaintenance: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearCountryStateMaintenance();
        } else {
          this.setState({
            submitStatusCountryStateMaintenance: "Error",
            submitMsgCountryStateMaintenance: response.data.responseString,
          });
          this.onHandleClearCountryStateMaintenance();
        }
      });
    this.setState({ showCountryStateMaintenanceConfirm: false });
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WU TMON FILE MAINTENANCE"
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                STATE MAINTENENCE DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  STATE MAINTENENCE FORM
                </div>
                <div className="card-body card-body-custom">
                  <div className="form-group row">
                    <div className="col-lg-2">
                      <label htmlFor="stateCode" className="font-weight-bold">State Code</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.stateCode}
                        className="form-control form-control-sm"
                        name="stateCode"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-stateCode"
                        placeholder="State Code"
                        readOnly={!this.state.editable}
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId1" className="font-weight-bold">Adj State ID 1</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId1}
                        className="form-control form-control-sm"
                        name="adjStateId1"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId1"
                        placeholder="Adj State ID 1"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId2" className="font-weight-bold">Adj State ID 2</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId2}
                        className="form-control form-control-sm"
                        name="adjStateId2"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId2"
                        placeholder="Adj State ID 2"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId3" className="font-weight-bold">Adj State ID 3</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId3}
                        className="form-control form-control-sm"
                        name="adjStateId3"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId3"
                        placeholder="Adj State ID 3"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId4" className="font-weight-bold">Adj State ID 4</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId4}
                        className="form-control form-control-sm"
                        name="adjStateId4"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId4"
                        placeholder="Adj State ID 4"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId5" className="font-weight-bold">Adj State ID 5</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId5}
                        className="form-control form-control-sm"
                        name="adjStateId5"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId5"
                        placeholder="Adj State ID 5"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId6" className="font-weight-bold">Adj State ID 6</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId6}
                        className="form-control form-control-sm"
                        name="adjStateId6"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId6"
                        placeholder="Adj State ID 6"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId7" className="font-weight-bold">Adj State ID 7</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId7}
                        className="form-control form-control-sm"
                        name="adjStateId7"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId7"
                        placeholder="Adj State ID 7"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId8" className="font-weight-bold">Adj State ID 8</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId8}
                        className="form-control form-control-sm"
                        name="adjStateId8"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId8"
                        placeholder="Adj State ID 8"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId9" className="font-weight-bold">Adj State ID 9</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId9}
                        className="form-control form-control-sm"
                        name="adjStateId9"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId9"
                        placeholder="Adj State ID 9"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId10" className="font-weight-bold">Adj State ID 10</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId10}
                        className="form-control form-control-sm"
                        name="adjStateId10"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId10"
                        placeholder="Adj State ID 10"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId11" className="font-weight-bold">Adj State ID 11</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId11}
                        className="form-control form-control-sm"
                        name="adjStateId11"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId11"
                        placeholder="Adj State ID 11"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId12" className="font-weight-bold">Adj State ID 12</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId12}
                        className="form-control form-control-sm"
                        name="adjStateId12"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId12"
                        placeholder="Adj State ID 12"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId13" className="font-weight-bold">Adj State ID 13</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId13}
                        className="form-control form-control-sm"
                        name="adjStateId13"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId13"
                        placeholder="Adj State ID 13"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId14" className="font-weight-bold">Adj State ID 14</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId14}
                        className="form-control form-control-sm"
                        name="adjStateId14"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId14"
                        placeholder="Adj State ID 14"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="adjStateId15" className="font-weight-bold">Adj State ID 15</label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.adjStateId15}
                        className="form-control form-control-sm"
                        name="adjStateId15"
                        onChange={(e) => {
                          this.onChangeControlCountryStateMaintenanceCharNumber(e);
                        }}
                        data-test="text-adjStateId15"
                        placeholder="Adj State ID 15"
                      />
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="flag" className="font-weight-bold">Retrieve Curtain</label>
                      <select
                          className="form-control  form-control-sm"
                          name="flag"
                          value={this.state.flag}
                          onChange={this.onChangeControlCountryStateMaintenance}
                          data-test="text-flag"
                        >
                          <option value="">-Select-</option>
                          <option value="N">NO</option>
                          <option value="Y">YES</option>
                      </select>                    
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackCountryStateMaintenance}
                        data-test="button-back"
                      >
                        TMON MAINT
                      </button>
                     <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddCountryStateMaintenance}
                        data-test="button-add"
                        disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateCountryStateMaintenance}
                        data-test="button-update"
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearCountryStateMaintenance}
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({
                            showCountryStateMaintenanceConfirm: true,
                          });
                        }}
                        data-test="button-delete"
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                          {this.state.submitStatusCountryStateMaintenance ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgCountryStateMaintenance}
                            </div>
                          )}
                          {this.state.submitStatusCountryStateMaintenance ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgCountryStateMaintenance}
                            </div>
                          )}
						              </div>
                          <div className="col-lg-2"></div>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </div>
              </div>
              {this.getCountryStateMaintenanceModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  STATE MAINTENENCE DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlCountryStateMaintenance(e);
                        this.onPageSizeChangedCountryStateMaintenance(e);
                      }}
                      value={this.state.paginationValCountryStateMaintenance}
                      name="paginationDropdownGsp"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionCountryStateMaintenance.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactCSM
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyCountryStateMaintenance}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateCSM
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateCSM
                      }
                    ></AgGridReactCSM>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CntryStateMaintenance;
