import React, { Component } from "react";
import {
  getHeadersCustomer,
  getHeadersOrder,
  // getformattedDate,
  getformattedDate1,
} from "../../../../src/components/utility/utility";
import axios from "axios";
import Header from "../../header/header";

class WebTmtBankInformation extends Component {
  state = {
    records: [],
    singleData: {
      bankId: "",
      add: false,
      bankNumber: "",
      bankCountry: "",
      bankCurrency: "",
      bankName1: "",
      bankPhone1: "",
      bankName2: "",
      bankPhone2: "",
      bankPhone3: "",
      bankPhone4: "",
      bankStatus: "",
      statusDate: "",
      createDate: "",
      userID: "",
      updateDate: "",
      cardProd: "",
      cardInd: "",
      dailyCount: 0,
      dailyRefCount: 0,
      dailyDecCount: 0,
      cardType: "",
      weeklyCount: 0,
      weeklyRefCount: 0,
      weeklyDecCount: 0,
      transDate: "",
      calcFactor: "",
      mtd: 0,
      lastYrCount: 0,
      lastMoCount: 0,
      ytd: 0,
    },
    data: {
      bankId: "",
    },
    isBankInfoCommLoading : false,
    isBankInfoUpdateLoading : false,
    isBankInfoNewLoading : false,
    isBankInfoWebTmtLoading: false
  };
  
  onChangeWebTmtBankInfo = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value,
        },
      };
    });
  };

  onChangeControlBankPhoneNums = (e) => {    
    const { name, value } = e.target;    
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: value,
          },
        };
      });
    }
  };

  onChangeControlBankCardDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^A-Za-z]/, ""),
    });
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value.replace(/[^A-Za-z]/, ""),
        },
      };
    });
  };

  onChangeControlBankCountDetails = (e) => {
    
    const { name, value } = e.target;
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
      this.setState((prevState) => {
        return {
          singleData: {
            ...prevState.singleData,
            [name]: value,
          },
        };
      });
    }
  };;

  componentDidMount() {
    this.setState((prevState) => {
      return {
        data: {
          ...prevState,
          bankId: this.props.location.state.bankId,
          singleData: this.props.location.state.singleData,
        },
      };
    });
    this.setState({
      accessGrantedWrite: this.props.location.state.accessGrantedWrite,
      tokenWebTmt:this.props.location.state.tokenWebTmt,
      webAdmin:this.props.location.state.webAdmin,
      isAddressDecryprtRequired: this.props.location.state.isAddressDecryprtRequired,
      isCreditCardDecryprtRequired: this.props.location.state.isCreditCardDecryprtRequired,
      bankId: this.props.location.state.bankId,
      mode: this.props.location.state.mode,
      singleData: this.props.location.state.singleData,
      bankNumber: this.props.location.state.bankNumber,
      operatorId: this.props.location.state.operatorId
    });
    this.getBankInformation();
  }

  getBankInformation = () => {
   
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/webtmtdbmaintenance/bankinfo`,
          {
            headers: {
              ...getHeadersOrder().headers,
              bankId: this.props.location.state.bankId
                ? this.props.location.state.bankId
                : "",
              Authorization: this.props.location.state.tokenWebTmt
                ? this.props.location.state.tokenWebTmt
                : "",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if(response.data.bankName1===null){
              this.setState({
                singleData: {
                  bankNumber: this.props.location.state.bankId
                    ? this.props.location.state.bankId
                    : "",
                  bankName1: this.state.singleData.bankName1,
                  mode:'add',
                },
              });
            }else{
              this.setState({
                singleData: response.data,
                mode:'update'
              });
            }
            
          } else {
            this.setState({
              submitStatusWebTmtBankInformation: "Error",
              submitMsgWebTmtBankInformation: response.data.errMsg,
            });
          }
        });
    
  };

  
  onHandleBackWebTmtMain = () => {
    this.setState({
      isBankInfoWebTmtLoading: true
    })
    this.props.history.push("/web-tmt-db-maintenance");
    this.setState({
      isBankInfoWebTmtLoading: false
    })
  };

  /*onHandleBackWebTmtSearch = () => {
    this.props.history.push({
      pathname: "/web-tmt-db-maintenance",
    });
  };*/

  onHandleWebTmtDBMaintenanceCommentsInfo = () => {
    this.setState({
      isBankInfoCommLoading: true,
    })
    this.setState({
      bankId: this.state.singleData.bankNumber
        ? this.state.singleData.bankNumber
        : "",
        
    });
    this.getBankCommentsInfo();
    this.setState({
      isBankInfoCommLoading: false,
    })
    this.props.history.push({
      pathname: "/web-tmt-bank-comment-information",
      state: this.state,
    });
  };

  getBankCommentsInfo = () => {
    this.setState({
      bankNumber: this.state.bankNumber
        ? this.state.bankNumber
        : "",
      bankName: this.state.singleData.bankName1
        ? this.state.singleData.bankName1
        : "",
    });
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/custcommentinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            searchKey: this.state.bankNumber
              ? this.state.bankNumber
              : this.state.singleData.bankNumber,
            Authorization: this.state.tokenWebTmt ? this.state.tokenWebTmt : "",
          },
        }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.records &&
          response.data.records.length > 0
        ) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
          });
        }
      });
  };

  getMappedRequest = () => {
    let submittedObj = {};
    submittedObj.bankNumber = this.state.singleData.bankNumber;
    submittedObj.bankCountry = this.state.singleData.bankCountry;
    submittedObj.bankCurrency = this.state.singleData.bankCurrency;
    submittedObj.bankName1 = this.state.singleData.bankName1;
    submittedObj.bankPhone1 = this.state.singleData.bankPhone1;
    submittedObj.bankName2 = this.state.singleData.bankName2;
    submittedObj.bankPhone2 = this.state.singleData.bankPhone2;
    submittedObj.bankPhone3 = this.state.singleData.bankPhone3;
    submittedObj.bankPhone4 = this.state.singleData.bankPhone4;
    submittedObj.bankStatus = this.state.singleData.bankStatus;
    submittedObj.statusDate = this.state.singleData.statusDate;
    submittedObj.createDate = this.state.singleData.createDate;
    submittedObj.userID = this.state.operatorId;//this.state.singleData.userID;
    submittedObj.updateDate = this.state.singleData.updateDate;//getformattedDate(); 
    submittedObj.cardProd = this.state.singleData.cardProd;
    submittedObj.cardInd = this.state.singleData.cardInd;
    submittedObj.dailyCount = this.state.singleData.dailyCount;
    submittedObj.dailyRefCount = this.state.singleData.dailyRefCount;
    submittedObj.dailyDecCount = this.state.singleData.dailyDecCount;
    submittedObj.cardType = this.state.singleData.cardType;
    submittedObj.weeklyCount = this.state.singleData.weeklyCount;
    submittedObj.weeklyRefCount = this.state.singleData.weeklyRefCount;
    submittedObj.weeklyDecCount = this.state.singleData.weeklyDecCount;
    submittedObj.transDate = this.state.singleData.transDate;
    submittedObj.calcFactor = this.state.singleData.calcFactor;
    submittedObj.mtd = this.state.singleData.mtd;
    submittedObj.lastYrCount = this.state.singleData.lastYrCount;
    submittedObj.lastMoCount = this.state.singleData.lastMoCount;
    submittedObj.ytd = this.state.singleData.ytd;

    return submittedObj;
  };

  onHandleUpdateBankInfo = () => {
    const submittedObj = this.getMappedRequest();
    this.setState({
      isBankInfoUpdateLoading : true
    })
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/webtmtdbmaintenance/bankinfo`,
        submittedObj,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.state.tokenWebTmt ? this.state.tokenWebTmt : "",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.getBankInformation();
          this.setState({
            submitStatusWebTmtBankInformation: "OK",
            submitMsgWebTmtBankInformation: response.data.responseString,
          });
        } else {
          this.setState({
            submitStatusWebTmtBankInformation: "Error",
            submitMsgWebTmtBankInformation: response.data.responseString,
          });
        }
        this.setState({
          isBankInfoUpdateLoading: false,
        });
        this.onHandleClearWebTmtBankInfo();
      });
  };

  onHandleClearWebTmtBankInfo = () => {
    setTimeout(
      () =>
        this.setState({
          submitStatusWebTmtBankInformation: null,
          submitMsgWebTmtBankInformation: "",
        }),
      5000
    );
  };

  onHandleNewBankInfo = () => {
    if (!this.onValidateBankInfo()) {
      return;
    }
    if (this.state.bankNumber !== "") {
      this.setState({
        isBankInfoNewLoading : true
      })
      let submittedObj = {};
      submittedObj.bankNumber = this.state.bankNumber;
      submittedObj.bankCountry = this.state.singleData.bankCountry;
      submittedObj.bankCurrency = this.state.singleData.bankCurrency;
      submittedObj.bankName1 = this.state.singleData.bankName1;
      submittedObj.bankPhone1 = this.state.singleData.bankPhone1;
      submittedObj.bankName2 = this.state.singleData.bankName2;
      submittedObj.bankPhone2 = this.state.singleData.bankPhone2;
      submittedObj.bankPhone3 = this.state.singleData.bankPhone3;
      submittedObj.bankPhone4 = this.state.singleData.bankPhone4;
      submittedObj.bankStatus = this.state.singleData.bankStatus;
      submittedObj.statusDate = getformattedDate1();
      submittedObj.createDate = getformattedDate1();
      submittedObj.userID = this.state.operatorId;//this.state.singleData.userID;
      submittedObj.updateDate = this.state.singleData.updateDate
        ? this.state.singleData.updateDate
        : "";
      submittedObj.cardProd = this.state.singleData.cardProd
        ? this.state.singleData.cardProd
        : "";
      submittedObj.cardInd = this.state.singleData.cardInd
        ? this.state.singleData.cardInd
        : "";
      submittedObj.dailyCount = this.state.singleData.dailyCount
        ? this.state.singleData.dailyCount
        : 0;
      submittedObj.dailyRefCount = this.state.singleData.dailyRefCount
        ? this.state.singleData.dailyRefCount
        : 0;
      submittedObj.dailyDecCount = this.state.singleData.dailyDecCount
        ? this.state.singleData.dailyDecCount
        : 0;
      submittedObj.cardType = this.state.singleData.cardType
        ? this.state.singleData.cardType
        : "";
      submittedObj.weeklyCount = this.state.singleData.weeklyCount
        ? this.state.singleData.weeklyCount
        : 0;
      submittedObj.weeklyRefCount = this.state.singleData.weeklyRefCount
        ? this.state.singleData.weeklyRefCount
        : 0;
      submittedObj.weeklyDecCount = this.state.singleData.weeklyDecCount
        ? this.state.singleData.weeklyDecCount
        : 0;
      submittedObj.transDate = this.state.singleData.transDate
        ? this.state.singleData.transDate
        : "";
      submittedObj.calcFactor = this.state.singleData.calcFactor
        ? this.state.singleData.calcFactor
        : "";
      submittedObj.mtd = this.state.singleData.mtd
        ? this.state.singleData.mtd
        : 0;
      submittedObj.lastYrCount = this.state.singleData.lastYrCount
        ? this.state.singleData.lastYrCount
        : 0;
      submittedObj.lastMoCount = this.state.singleData.lastMoCount
        ? this.state.singleData.lastMoCount
        : 0;
      submittedObj.ytd = this.state.singleData.ytd
        ? this.state.singleData.ytd
        : 0;
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/webtmtdbmaintenance/bankinfo`,
          submittedObj,
          {
            headers: {
              ...getHeadersOrder().headers,
              Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',              
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.getBankInformation();

            this.setState({
              mode:"",
              submitStatusWebTmtBankInformation: "OK",
              submitMsgWebTmtBankInformation: response.data.responseString,
            });
          } else {
            this.setState({
              submitStatusWebTmtBankInformation: "Error",
              submitMsgWebTmtBankInformation: response.data.responseString,
            });
          }
          this.setState({
            isBankInfoNewLoading : false
          })
        });
    }
  };

  onValidateBankInfo = () => {
    if(this.state.singleData.bankName1 === "" || this.state.singleData.bankName1===undefined || this.state.singleData.bankName1===null) {
      this.setState({
        submitStatusWebTmtBankInformation: "Error",
        submitMsgWebTmtBankInformation: "Please Enter The Value For Bank Name",
      });
      return false;
    }
    return true;
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WEB/TMT DATABASE MAINTENANCE - Bank Information"
        />

        <div
          className="container-fluid"
          data-test="component-account-information"
        >
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                WEB/TMT Database Maintenance - Bank Information
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  BANK DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="bankNumber"
                          className="font-weight-bold"
                        >
                          Bank Number
                        </label>
                        <input
                          type="text"
                          value={this.state.bankNumber}
                          className="form-control form-control-sm"
                          name="bankNumber"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-bankNumber"
                          placeholder="Bank Number"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="bankCountry"
                          className="font-weight-bold"
                        >
                          Bank Country
                        </label>
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.singleData.bankCountry}
                          className="form-control form-control-sm"
                          name="bankCountry"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-bankCountry"
                          placeholder="Bank Country"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="bankCurrency"
                          className="font-weight-bold"
                        >
                          Bank Currency
                        </label>
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.singleData.bankCurrency}
                          className="form-control form-control-sm"
                          name="bankCurrency"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-bankCurrency"
                          placeholder="Bank Currency"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-8">
                        <label htmlFor="bankName1" className="font-weight-bold">
                          Bank Name1 (CSC Operation)
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.bankName1}
                          className="form-control form-control-sm"
                          name="bankName1"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-bankName1"
                          placeholder="Bank Name1"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="bankPhone1"
                          className="font-weight-bold"
                        >
                          Bank Phone1
                        </label>
                        <input
                          type="text"
                          maxLength="15"
                          value={this.state.singleData.bankPhone1}
                          className="form-control form-control-sm"
                          name="bankPhone1"
                          onChange={(e) => {
                            this.onChangeControlBankPhoneNums(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankPhoneNums(e);
                          }}
                          data-test="text-bankPhone1"
                          placeholder="Bank Phone1"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-8">
                        <label htmlFor="bankName2" className="font-weight-bold">
                          Bank Name2 (FDMS, CMS, Etc.)
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.bankName2}
                          className="form-control form-control-sm"
                          name="bankName2"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-bankName2"
                          placeholder="Bank Name2"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="bankPhone2"
                          className="font-weight-bold"
                        >
                          Bank Phone2
                        </label>
                        <input
                          type="text"
                          maxLength="15"
                          value={this.state.singleData.bankPhone2}
                          className="form-control form-control-sm"
                          name="bankPhone2"
                          onChange={(e) => {
                            this.onChangeControlBankPhoneNums(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankPhoneNums(e);
                          }}
                          data-test="text-bankPhone2"
                          placeholder="Bank Phone2"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-8"></div>
                      <div className="col-lg">
                        <label
                          htmlFor="bankPhone3"
                          className="font-weight-bold"
                        >
                          Bank Phone3
                        </label>
                        <input
                          type="text"
                          maxLength="15"
                          value={this.state.singleData.bankPhone3}
                          className="form-control form-control-sm"
                          name="bankPhone3"
                          onChange={(e) => {
                            this.onChangeControlBankPhoneNums(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankPhoneNums(e);
                          }}
                          data-test="text-bankPhone3"
                          placeholder="Bank Phone3"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-8"></div>
                      <div className="col-lg">
                        <label
                          htmlFor="bankPhone4"
                          className="font-weight-bold"
                        >
                          Bank Phone4
                        </label>
                        <input
                          type="text"
                          maxLength="15"
                          value={this.state.singleData.bankPhone4}
                          className="form-control form-control-sm"
                          name="bankPhone4"
                          onChange={(e) => {
                            this.onChangeControlBankPhoneNums(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankPhoneNums(e);
                          }}
                          data-test="text-bankPhone4"
                          placeholder="Bank Phone4"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="bankStatus"
                          className="font-weight-bold"
                        >
                          Bank Status
                        </label>
                        <input
                          type="text"
                          maxLength="1"
                          value={this.state.singleData.bankStatus}
                          className="form-control form-control-sm"
                          name="bankStatus"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-bankStatus"
                          placeholder="Bank Status"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="statusDate"
                          className="font-weight-bold"
                        >
                          Status Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.statusDate}
                          className="form-control form-control-sm"
                          name="statusDate"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-statusDate"
                          placeholder="Status Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="createDate"
                          className="font-weight-bold"
                        >
                          Create Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.createDate}
                          className="form-control form-control-sm"
                          name="createDate"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-createDate"
                          placeholder="Create Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="userID" className="font-weight-bold">
                          User ID
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.userID}
                          className="form-control form-control-sm"
                          name="userID"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-userID"
                          placeholder="User ID"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="updateDate"
                          className="font-weight-bold"
                        >
                          Update Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.updateDate}
                          className="form-control form-control-sm"
                          name="updateDate"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-updateDate"
                          placeholder="Update Date"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label htmlFor="cardProd" className="font-weight-bold">
                          Card Prod
                        </label>
                        <input
                          type="text"
                          maxLength="1"
                          value={this.state.singleData.cardProd}
                          className="form-control form-control-sm"
                          name="cardProd"
                          onChange={(e) => {
                            this.onChangeControlBankCardDetails(e);
                          }}
                          data-test="text-cardProd"
                          placeholder="Card Prod"
                          disabled
                        />
                      </div>
                      <div className="col-lg-2">
                        <label htmlFor="cardInd" className="font-weight-bold">
                          Card Ind
                        </label>
                        <input
                          type="text"
                          maxLength="2"
                          value={this.state.singleData.cardInd}
                          className="form-control form-control-sm"
                          name="cardInd"
                          onChange={(e) => {
                            this.onChangeControlBankCardDetails(e);
                          }}
                          data-test="text-cardInd"
                          placeholder="Card Ind"
                        />
                      </div>
                      <div className="col-lg-2">
                        <label htmlFor="daily" className="font-weight-bold">
                          Daily :
                        </label>
                      </div>
                      <div className="col-lg-2">
                        <label
                          htmlFor="dailyCount"
                          className="font-weight-bold"
                        >
                          Count
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.dailyCount}
                          className="form-control form-control-sm"
                          name="dailyCount"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-dailyCount"
                          placeholder="Count"
                          disabled
                        />
                      </div>
                      <div className="col-lg-2">
                        <label
                          htmlFor="dailyRefCount"
                          className="font-weight-bold"
                        >
                          Ref Limit
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.dailyRefCount}
                          className="form-control form-control-sm"
                          name="dailyRefCount"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-dailyRefCount"
                          placeholder="Ref Limit"
                        />
                      </div>
                      <div className="col-lg-2">
                        <label
                          htmlFor="dailyDecCount"
                          className="font-weight-bold"
                        >
                          Dec Limit
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.dailyDecCount}
                          className="form-control form-control-sm"
                          name="dailyDecCount"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-dailyDecCount"
                          placeholder="Dec limit"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label htmlFor="cardType" className="font-weight-bold">
                          Card Type
                        </label>
                        <input
                          type="text"
                          maxLength="2"
                          value={this.state.singleData.cardType}
                          className="form-control form-control-sm"
                          name="cardType"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-cardType"
                          placeholder="Card Type"
                        />
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="col-lg-2">
                        <label htmlFor="weekly" className="font-weight-bold">
                          Weekly:
                        </label>
                      </div>
                      <div className="col-lg-2">
                        <label
                          htmlFor="weeklyCount"
                          className="font-weight-bold"
                        >
                          Count
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.weeklyCount}
                          className="form-control form-control-sm"
                          name="weeklyCount"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-weeklyCount"
                          placeholder="Count"
                          disabled
                        />
                      </div>
                      <div className="col-lg-2">
                        <label
                          htmlFor="weeklyRefCount"
                          className="font-weight-bold"
                        >
                          Ref Limit
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.weeklyRefCount}
                          className="form-control form-control-sm"
                          name="weeklyRefCount"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-weeklyRefCount"
                          placeholder="Ref Limit"
                        />
                      </div>
                      <div className="col-lg-2">
                        <label
                          htmlFor="weeklyDecCount"
                          className="font-weight-bold"
                        >
                          Dec Limit
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.weeklyDecCount}
                          className="form-control form-control-sm"
                          name="weeklyDecCount"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-weeklyDecCount"
                          placeholder="Dec limit"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label htmlFor="transDate" className="font-weight-bold">
                          Trans Date
                        </label>
                        <input
                          type="text"
                          maxLength="10"
                          value={this.state.singleData.transDate}
                          className="form-control form-control-sm"
                          name="transDate"
                          onChange={(e) => {
                            this.onChangeWebTmtBankInfo(e);
                          }}
                          data-test="text-transDate"
                          placeholder="Trans Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg-2">
                        <label
                          htmlFor="calcFactor"
                          className="font-weight-bold"
                        >
                          Calc Factor
                        </label>
                        <input
                          type="text"
                          maxLength="6"
                          value={this.state.singleData.calcFactor}
                          className="form-control form-control-sm"
                          name="calcFactor"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-calcFactor"
                          placeholder="Calc Factor"
                        />
                      </div>
                      <div className="col-lg-2">
                        <label htmlFor="mtd" className="font-weight-bold">
                          MTD:
                        </label>
                      </div>
                      <div className="col-lg-2">
                        <label htmlFor="MTD" className="font-weight-bold">
                          Count
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.mtd}
                          className="form-control form-control-sm"
                          name="mtd"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-MTD"
                          placeholder="Count"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label
                          htmlFor="lastYrCount"
                          className="font-weight-bold"
                        >
                          Last Yr Count
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.lastYrCount}
                          className="form-control form-control-sm"
                          name="lastYrCount"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-lastYrCount"
                          placeholder="Last Yr Count"
                          disabled
                        />
                      </div>
                      <div className="col-lg-2">
                        <label
                          htmlFor="lastMoCount"
                          className="font-weight-bold"
                        >
                          Last Mo Count
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.lastMoCount}
                          className="form-control form-control-sm"
                          name="lastMoCount"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-lastMoCount"
                          placeholder="Last Mo Count"
                          disabled
                        />
                      </div>
                      <div className="col-lg-2">
                        <label htmlFor="ytd" className="font-weight-bold">
                          YTD:
                        </label>
                      </div>
                      <div className="col-lg-2">
                        <label htmlFor="YTD" className="font-weight-bold">
                          Count
                        </label>
                        <input
                          type="text"
                          maxLength="7"
                          value={this.state.singleData.ytd}
                          className="form-control form-control-sm"
                          name="ytd"
                          onChange={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          onFocus={(e) => {
                            this.onChangeControlBankCountDetails(e);
                          }}
                          data-test="text-YTD"
                          placeholder="Count"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackWebTmtMain}
                          data-test="button-webTmtMaint"
                        >
                          WEBTMT MAINT
                        </button>
                        {this.state.mode === "add" ? (
                          this.state.isBankInfoNewLoading ? (
                            <button
                              className="btn btn-warning mar-right10"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              &nbsp;&nbsp;Loading...
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-warning mar-right10"
                              onClick={this.onHandleNewBankInfo}
                              data-test="button-newRec"
                              disabled={
                                !this.state.accessGrantedWrite ||
                                this.state.isBankInfoUpdateLoading === true ||
                                this.state.isBankInfoNewLoading === true ||
                                this.state.isBankInfoCommLoading === true
                              }
                            >
                              NEW REC
                            </button>
                          )
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            disabled
                          >
                            NEW REC
                          </button>
                        )}
                        {this.state.mode === "add" ? (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            data-test="button-update"
                            disabled
                          >
                            UPDATE
                          </button>
                        ) : this.state.isBankInfoUpdateLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            data-test="button-update"
                            onClick={this.onHandleUpdateBankInfo}
                            disabled={
                              !this.state.accessGrantedWrite ||
                              this.state.isBankInfoUpdateLoading === true ||
                              this.state.isBankInfoNewLoading === true ||
                              this.state.isBankInfoCommLoading === true
                            }
                          >
                            UPDATE
                          </button>
                        )}
                        {this.state.isBankInfoCommLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            data-test="button-commentInfo"
                            onClick={
                              this.onHandleWebTmtDBMaintenanceCommentsInfo
                            }
                            disabled={
                              this.state.isBankInfoUpdateLoading === true ||
                              this.state.isBankInfoNewLoading === true ||
                              this.state.isBankInfoCommLoading === true
                            }
                          >
                            COMMENTS INFO
                          </button>
                        )}
                      </div>                      
                    </div>
                    <div className="form-froup row">
                      <div className="col-lg-4" />
                      <div className="col-lg-4">
                        {this.state.submitStatusWebTmtBankInformation ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgWebTmtBankInformation}
                          </div>
                        )}
                        {this.state.submitStatusWebTmtBankInformation ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgWebTmtBankInformation}
                          </div>
                        )}
                      </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebTmtBankInformation;
