import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";

import MaintenanceMenuDetails from "./components/maintenance-menu-details/maintenance-menu-details";
import Dashboard from "./components/gsp-system-parameters/dashboard/dashboard";

// Money Transfer Utility
import MTDatabaseMoneyTransferRecord from "./components/money-transfer-utility/mt-database-money-transfer-record/mt-database-money-transfer-record";
import MoneyTransferDatabaseUtility from "./components/money-transfer-utility/money-transfer-database-utility/money-transfer-database-utility";
import MoneyTransferTextRecord from "./components/money-transfer-utility/money-transfer-text-record/money-transfer-text-record";
import MoneyTransferCommentsRecord from "./components/money-transfer-utility/money-transfer-comments-record/money-transfer-comments-record";
import MoneyTransferSpecialHandlingRecord from "./components/money-transfer-utility/money-transfer-special-handling-record/money-transfer-special-handling-record";
import MoneyTransferUniversalAddress from "./components/money-transfer-utility/money-transfer-universal-address/money-transfer-universal-address";
import MoneyTransferUniversalTaxRecord from "./components/money-transfer-utility/money-transfer-universal-tax-record/money-transfer-universal-tax-record";
import MoneyTransferMadrec from "./components/money-transfer-utility/money-transfer-madrec/money-transfer-madrec";
import MoneyTransferMTUB from "./components/money-transfer-utility/money-transfer_mtub/money-transfer_mtub";
import MoneyTransferMISCD from "./components/money-transfer-utility/money-transfer-miscd/money-transfer-miscd";
import MoneyTransferAuthQueueRecord from "./components/money-transfer-utility/money-transfer-auth-queue-record/money-transfer-auth-queue-record";
import MoneyTransferDatabaseRecordDisplayUtility from "./components/money-transfer-utility/money-transfer-database-record-display-utility/money-transfer-database-record-display-utility";
import MoneyTransferDatabaseAgentRecord from "./components/money-transfer-utility/money-transfer-database-agent-record/money-transfer-database-agent-record";
import MoneyTransferSearchKey from "./components/money-transfer-utility/money-transfer-search-key/money-transfer-search-key";
import MoneyTransferRDBMSSearchKeyDetails from "./components/money-transfer-utility/money-transfer-rdbms-search-key-details/money-transfer-rdbms-search-key-details";
import DoddFrankReceiptSearch from "./components/dodd-frank-receipt/dodd-frank-receipt-search";
import DoddFrankReceiptDetails from "./components/dodd-frank-receipt/dodd-frank-receipt-details";
import MTCNList from "./components/money-transfer-utility/mtcn-list/mtcn-list";
// Money Transfer Utility

// Directed Maintenance
import DirectedMaintenanceDetails from "./components/directed-maintenance/directed-maintenance-details/directed-maintenance-details";
import DirectedMaintenanceNetworkLinkDetails from "./components/directed-maintenance/directed-maintenance-network-link-maintenance/directed-maintenance-network-link-details";
import WuDirectedPayerDetails from "./components/directed-maintenance/wu-directed-payer-details/wu-directed-payer-details";

//Auto Refile Account Details
import AutoRefileAccountDetails from "./components/auto-refile-account-details/auto-refile-account-details";

//Partner maintenance
import PartnerMaintenance from "./components/partner-maintenance/partner-maintenance";

// Business Rule Maintenance
import BusinessRuleMaintenance from "./components/business-rules-maintenance/business-rule-maintenance/business-rule-maintenance";
import DisplayCountryRules from "./components/business-rules-maintenance/display-country-rules/display-country-rules";
import Mt6Rules from "./components/business-rules-maintenance/mt6-rules/mt6-rules";
import PartnerEmailDetails from "./components/business-rules-maintenance/partner-email-details/partner-email-details";
import BusinessRuleSummary from "./components/business-rules-maintenance/business-rule-summary/business-rule-summary";
import BusinessRuleMerchantId from "./components/business-rules-maintenance/business-rule-merchant-id/business-rule-merchant-id";

// Country Maintenance
import CountryDatabaseMaintenance from "./components/country-maintenance/country-database-maintenance/country-database-maintenance";
import CountryDetails from "./components/country-maintenance/country-details/country-details";
import CorridorRules from "./components/country-maintenance/corridor-rules-table-maintenance/corridor-rules-table-maintenance";
import DelayedDeliverAndCorridorDetails from "./components/country-maintenance/delayed-delivery-and-corridor-details/delayed-deliver-and-corridor-details";
import CountryDeliveryService from "./components/country-maintenance/country-delivery-service/country-delivery-service";
import AddDeliveryService from "./components/country-maintenance/country-delivery-service-address/country-delivery-service-address";
import Flags from "./components/country-maintenance/country-options/country-options";
import Curtains from "./components/country-maintenance/country-payout-curtain-maintenance/country-payout-curtain-maintenance";
import AccountDetails from "./components/country-maintenance/account-details/account-details";

// Country Maintenance

// Network List
import NetworkList from "./components/network-list/network-list";

//Partner Fraud Prevention
import PartnerFraudPreventionSearch from "./components/partner-fraud-prevention/partner-fraud-prevention-search/partner-fraud-prevention-search";
import PartnerFraudPreventionDetails from "./components/partner-fraud-prevention/partner-fraud-prevention-details/partner-fraud-prevention-details";

//WEB TMT DB Maintenance
import WebTmtDatabaseMaintenanceSearch from "./components/web-tmt-data-maintenance/web-tmt-data-maintenance-search/web-tmt-data-maintenance-search";
import WebTmtCustomerAccountInformation from "./components/web-tmt-data-maintenance/web-tmt-customer-account-information/web-tmt-customer-account-information";
import WebTmtCustomerInformation from "./components/web-tmt-data-maintenance/web-tmt-customer-information/web-tmt-customer-information";
import WebTmtAccountInformation from "./components/web-tmt-data-maintenance/web-tmt-account-information/web-tmt-account-information";
import WebTmtBankInformation from "./components/web-tmt-data-maintenance/web-tmt-bank-information/web-tmt-bank-information";
import WebTmtTransactionHistory from "./components/web-tmt-data-maintenance/web-tmt-transaction-history/web-tmt-transaction-history";
import WebTmtCustomerCommentInformation from "./components/web-tmt-data-maintenance/web-tmt-customer-comment-information/web-tmt-customer-comment-information";
import WebTmtBankCommentInformation from "./components/web-tmt-data-maintenance/web-tmt-bank-comment-information/web-tmt-bank-comment-information";

//Check Control Utility
import AgentBatchCheck from "./components/check-control-utility/agent-batch-check/agent-batch-check";
import MICRPaperSupport from "./components/check-control-utility/micr-paper-support/micr-paper-support";
import CheckControlUtility from "./components/check-control-utility/check-control-utility/check-control-utility";

// Queuee Age Monitor
import QueueAgeWorkflowMonitors from "./components/queue-age-monitor/queue-age-workflow-monitors/queue-age-workflow-monitors";
import WorkflowMaintenance from "./components/queue-age-monitor/queue-age-workflow-maintenance/queue-age-workflow-maintenance";
import WorkflowMonitor from "./components/queue-age-monitor/workflow-monitor/workflow-monitor";
import QueueScreen from "./components/queue-age-monitor/search/queue-screen/queue-screen";
import QueueTransScreen from "./components/queue-age-monitor/search/queue-trans-screen/queue-trans-screen";
// Queuee Age Monitor

//Fraud Prevention Filter
import FraudPreventionFilter from "./components/fraud-prevention-filter-maintenance/fraud-prevention-filter/fraud-prevention-filter";
import RegularFraudPreventionFilter from "./components/fraud-prevention-filter-maintenance/regular-fraud-prevention-filter/regular-fraud-prevention-filter";
import C2CFraudPreventionFilter from "./components/fraud-prevention-filter-maintenance/c2c-fraud-prevention-filter/c2c-fraud-prevention-filter";
import PrepaidFraudPreventionFilter from "./components/fraud-prevention-filter-maintenance/prepaid-fraud-prevention-filter/prepaid-fraud-prevention-filter";
import WhitelistFraudRuleC2C from "./components/fraud-prevention-filter-maintenance/whitelist-fraud-rule-c2c/whitelist-fraud-rule-c2c";
import WhitelistFraudRulePrepaid from "./components/fraud-prevention-filter-maintenance/whitelist-fraud-rule-prepaid/whitelist-fraud-rule-prepaid";
import PrepayFraudAccountDetails from "./components/fraud-prevention-filter-maintenance/prepay-fraud-account-details/prepay-fraud-account-details";

// wu-tmon-file-maintenance
import TmonMaintenanceDetails from "./components/wu-tmon-file-maintenance/tmon-maintenance-details/tmon-maintenance-details";
import TmonGlobalParameters from "./components/wu-tmon-file-maintenance/tmon-global-parameters/tmon-global-parameters";
import TmonTxnList from "./components/wu-tmon-file-maintenance/tmon-txn-list/tmon-txn-list";
import GspParamDetails from "./components/wu-tmon-file-maintenance/gsp-param-details/gsp-param-details";
import AgentThirdPartyDetails from "./components/wu-tmon-file-maintenance/agent-third-party-details/agent-third-party-details";
import ErrorDescriptionDetails from "./components/wu-tmon-file-maintenance/error-description-details/error-description-details";
import QueueMaintenance from "./components/wu-tmon-file-maintenance/queue-maintenance/queue-maintenance";
import QueueMaintenanceQueueDetails from "./components/wu-tmon-file-maintenance/queue-maintenance-queue-details/queue-maintenance-queue-details";
import QueueManitenanceGroupDetails from "./components/wu-tmon-file-maintenance/queue-maintenance-group-details/queue-maintenance-group-details";
import ErrorQueueMaintenanceSearch from "./components/wu-tmon-file-maintenance/error-queue-maintenance/error-queue-maintenance-search/error-queue-maintenance-search";
import ErrorQueueMaintenanceDetails from "./components/wu-tmon-file-maintenance/error-queue-maintenance/error-queue-maintenance-details/error-queue-maintenance-details";
import McisTypeMaintenanceDetails from "./components/wu-tmon-file-maintenance/mcis-type-maintenance-details/mcis-type-maintenance-details";
import SaturnResponseDetails from "./components/wu-tmon-file-maintenance/saturn-response-details/saturn-response-details";
import CntryStateMaintenance from "./components/wu-tmon-file-maintenance/cntry-state-maintenance/cntry-state-maintenance";
// wu-tmon-file-maintenance

//Quick Collect Update//
import QuickCollectDatabaseMaintenance from "./components/quick-collect-update/quick-collect-database-maintenance/quick-collect-database-maintenance";
import QuickCollectClientInformation from "./components/quick-collect-update/quick-collect-client-information/quick-collect-client-information";
import QuickCollectDeviceInformation from "./components/quick-collect-update/quick-collect-device-information/quick-collect-device-information";
import FusionConfiguration from "./components/quick-collect-update/fusion-configuration/fusion-configuration";
import QuickCollectAgtIrp from "./components/quick-collect-update/quick-collect-agt-irp/quick-collect-agt-irp";
//Quick Collect Update//

//NRT Agent Network Maintenance//
import SearchNrtAgentNetwork from "./components/nrt-agent-network-maintenance/search-nrt-agent-network/search-nrt-agent-network";
import CreateEditNrtAgentNetwork from "./components/nrt-agent-network-maintenance/create-edit-nrt-agent-network/create-edit-nrt-agent-network";
//NRT Agent Network Maintenance//

// Display Tip Log
import TipLogSearch from './components/display-tip-log/tip-log-search/tip-log-search';
import TipLogAuditReport from './components/display-tip-log/tip-log-audit-report/tip-log-audit-report';
import AccountLogSearch from './components/display-tip-log/account-log-search/account-log-search';
import TipLogAccountDetails from './components/display-tip-log/tip-log-account-details/tip-log-account-details';
// Display Tip Log

// F/X VALID DB MAINTENANCE
import FXValidDBMaintenance from './components/fx-discount-validation/fx-discount-search/fx-discount-search';
import FXDiscountDetails from './components/fx-discount-validation/fx-discount-details/fx-discount-details';
// F/X VALID DB MAINTENANCE

// CONFIG MANAGEMENT
import EditConfigManagementAccess from './components/config-management/config-management-access/edit-config-management-access';
import ConfigManagementQueryExecutor from './components/config-management/config-management-query/config-management-query-executor';
import ConfigManagementRedisUpdate from './components/config-management/config-management-redis/config-management-redis-update';
// CONFIG MANAGEMENT

import "./App.css";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_DB_VIEW_OKTA_ISSUER,
  clientId: process.env.REACT_APP_DB_VIEW_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/authorization-code/callback",
  // redirectUri: process.env.REACT_APP_DB_VIEW_OKTA_REDIRECT_URI,
  pkce: true,
  responseType: "code",
  scopes: ["cus.dbview", "ord.dbview", "pro.dbview", "age.dbview", "profile", "sha.dbview", "ordnrt.dbview", "dbv.gspsys", "openid", "email"],
  auth_server_audiences: [process.env.REACT_APP_AUTH_SERVER_AUDIENCES],
  cookies: { secure: false },
});

export default class App extends Component {

  render() {
    return (
      <div>
        <div className="App">
          <Router>
            <Security oktaAuth={oktaAuth}>
              <Route
                path="/authorization-code/callback"
                render={() => {
                  return <LoginCallback />;
                }}
              />
              {/* <AppWithRouterAccess/> */}
              <SecureRoute path="/" exact component={MaintenanceMenuDetails} />
              <SecureRoute path="/gsp-table-sys" component={Dashboard} />

              <SecureRoute
                path="/money-transfer-database-utility"
                exact
                component={MoneyTransferDatabaseUtility}
              />

              <SecureRoute
                path="/mt-database-money-transfer-record"
                exact
                component={MTDatabaseMoneyTransferRecord}
              />

              <SecureRoute
                path="/money-transfer-text-record"
                exact
                component={MoneyTransferTextRecord}
              />

              <SecureRoute
                path="/money-transfer-comments-record"
                exact
                component={MoneyTransferCommentsRecord}
              />

              <SecureRoute
                path="/money-transfer-special-handling-record"
                exact
                component={MoneyTransferSpecialHandlingRecord}
              />

              <SecureRoute
                path="/money-transfer-universal-address"
                exact
                component={MoneyTransferUniversalAddress}
              />

              <SecureRoute
                path="/money-transfer-universal-tax-record"
                exact
                component={MoneyTransferUniversalTaxRecord}
              />

              <SecureRoute
                path="/money-transfer-madrec"
                exact
                component={MoneyTransferMadrec}
              />

              <SecureRoute
                path="/money-transfer-mtub"
                exact
                component={MoneyTransferMTUB}
              />

              <SecureRoute
                path="/money-transfer-miscd"
                exact
                component={MoneyTransferMISCD}
              />

              <SecureRoute
                path="/money-transfer-auth-queue-record"
                exact
                component={MoneyTransferAuthQueueRecord}
              />

              <SecureRoute
                path="/money-transfer-database-record-display-utility"
                exact
                component={MoneyTransferDatabaseRecordDisplayUtility}
              />

              <SecureRoute
                path="/money-transfer-database-agent-record"
                exact
                component={MoneyTransferDatabaseAgentRecord}
              />

              <SecureRoute
                path="/money-transfer-search-key"
                exact
                component={MoneyTransferSearchKey}
              />

              <SecureRoute
                path="/money-transfer-rdbms-search-key-details"
                exact
                component={MoneyTransferRDBMSSearchKeyDetails}
              />
              <SecureRoute path="/mtcn-list" exact component={MTCNList} />

              <SecureRoute
                path="/df-receipt-search"
                exact
                component={DoddFrankReceiptSearch}
              />

              <SecureRoute
                path="/df-receipt-details"
                exact
                component={DoddFrankReceiptDetails}
              />

              <SecureRoute
                path="/directed-maintenance-details"
                exact
                component={DirectedMaintenanceDetails}
              />

              <SecureRoute
                path="/directed-maintenance-network-link-details"
                exact
                component={DirectedMaintenanceNetworkLinkDetails}
              />

              <SecureRoute
                path="/wu-directed-payer-details"
                exact
                component={WuDirectedPayerDetails}
              />

              <SecureRoute
                path="/refile-account-rules-update"
                exact
                component={AutoRefileAccountDetails}
              />

              <SecureRoute
                path="/partner-maintenance"
                exact
                component={PartnerMaintenance}
              />

              <SecureRoute
                path="/business-rules-maintenance"
                exact
                component={BusinessRuleMaintenance}
              />

              <SecureRoute
                path="/display-country-rules"
                exact
                component={DisplayCountryRules}
              />

              <SecureRoute
                path="/mt6-rules"
                exact component={Mt6Rules}
              />

              <SecureRoute
                path="/partner-email-details"
                exact
                component={PartnerEmailDetails}
              />

              <SecureRoute
                path="/business-rule-summary"
                exact
                component={BusinessRuleSummary}
              />

              <SecureRoute
                path="/business-rule-merchant-id"
                exact
                component={BusinessRuleMerchantId}
              />

              <SecureRoute
                path="/country-maintenance"
                exact
                component={CountryDatabaseMaintenance}
              />

              <SecureRoute
                path="/country-details"
                exact
                component={CountryDetails}
              />

              <SecureRoute
                path="/corridor-rules"
                exact
                component={CorridorRules}
              />

              <SecureRoute
                path="/delivery-service"
                exact
                component={DelayedDeliverAndCorridorDetails}
              />

              <SecureRoute
                path="/country-delivery-service"
                exact
                component={CountryDeliveryService}
              />

              <SecureRoute
                path="/add-delivery-service"
                exact
                component={AddDeliveryService}
              />

              <SecureRoute
                path="/country-options"
                exact
                component={Flags}
              />

              <SecureRoute
                path="/curtains"
                exact
                component={Curtains}
              />

              <SecureRoute
                path="/account-details"
                exact
                component={AccountDetails}
              />

              <SecureRoute
                path="/wu-system-monitor"
                exact
                component={NetworkList}
              />

              <SecureRoute
                path="/partner-fraud-prevention"
                exact
                component={PartnerFraudPreventionSearch}
              />

              <SecureRoute
                path="/partner-fraud-prevention-details"
                exact
                component={PartnerFraudPreventionDetails}
              />

              <SecureRoute
                path="/web-tmt-db-maintenance"
                exact
                component={WebTmtDatabaseMaintenanceSearch}
              />

              <SecureRoute
                path="/web-tmt-customer-account-information"
                exact
                component={WebTmtCustomerAccountInformation}
              />

              <SecureRoute
                path="/web-tmt-customer-information"
                exact
                component={WebTmtCustomerInformation}
              />

              <SecureRoute
                path="/web-tmt-account-information"
                exact
                component={WebTmtAccountInformation}
              />

              <SecureRoute
                path="/web-tmt-bank-information"
                exact
                component={WebTmtBankInformation}
              />

              <SecureRoute
                path="/web-tmt-transaction-history"
                exact
                component={WebTmtTransactionHistory}
              />

              <SecureRoute
                path="/web-tmt-customer-comment-information"
                exact
                component={WebTmtCustomerCommentInformation}
              />

              <SecureRoute
                path="/web-tmt-bank-comment-information"
                exact
                component={WebTmtBankCommentInformation}
              />

              <SecureRoute
                path="/micr-paper-support"
                exact
                component={MICRPaperSupport}
              />

              <SecureRoute
                path="/check-control-utility"
                exact
                component={CheckControlUtility}
              />

              <SecureRoute
                path="/agent-batch-check"
                exact
                component={AgentBatchCheck}
              />

              <SecureRoute
                path="/queue-age-monitor"
                exact
                component={QueueAgeWorkflowMonitors}
              />

              <SecureRoute
                path="/queue-age-workflow-monitor"
                exact
                component={WorkflowMonitor}
              />

              <SecureRoute
                path="/queue-age-workflow-maintenance"
                exact
                component={WorkflowMaintenance}
              />

              <SecureRoute
                path="/queue-screen"
                exact
                component={QueueScreen} />

              <SecureRoute
                path="/queue-trans-screen"
                exact
                component={QueueTransScreen}
              />

              <SecureRoute
                path="/fraud-prevention-filter"
                exact
                component={FraudPreventionFilter}
              />

              <SecureRoute
                path="/prepay-fraud-account-details"
                exact
                component={PrepayFraudAccountDetails}
              />

              <SecureRoute
                path="/regular-fraud-prevention-filter"
                exact
                component={RegularFraudPreventionFilter}
              />

              <SecureRoute
                path="/c2c-fraud-prevention-filter"
                exact
                component={C2CFraudPreventionFilter}
              />

              <SecureRoute
                path="/prepaid-fraud-prevention-filter"
                exact
                component={PrepaidFraudPreventionFilter}
              />

              <SecureRoute
                path="/whitelist-fraud-rule-c2c"
                exact
                component={WhitelistFraudRuleC2C}
              />

              <SecureRoute
                path="/whitelist-fraud-rule-prepaid"
                exact
                component={WhitelistFraudRulePrepaid}
              />

              <SecureRoute
                path="/tmon-maintenance-details"
                exact
                component={TmonMaintenanceDetails}
              />

              <SecureRoute
                path="/tmon-global-parameters"
                exact
                component={TmonGlobalParameters}
              />

              <SecureRoute
                path="/tmon-txn-list"
                exact
                component={TmonTxnList}
              />

              <SecureRoute
                path="/gsp-param-details"
                exact
                component={GspParamDetails}
              />

              <SecureRoute
                path="/agent-third-party-details"
                exact
                component={AgentThirdPartyDetails}
              />

              <SecureRoute
                path="/error-description-details"
                exact
                component={ErrorDescriptionDetails}
              />

              <SecureRoute
                path="/queue-maintenance"
                exact
                component={QueueMaintenance}
              />

              <SecureRoute
                path="/queue-details"
                exact
                component={QueueMaintenanceQueueDetails}
              />

              <SecureRoute
                path="/group-details"
                exact
                component={QueueManitenanceGroupDetails}
              />

              <SecureRoute
                path="/error-queue-maintenance-search"
                exact
                component={ErrorQueueMaintenanceSearch}
              />

              <SecureRoute
                path="/error-queue-maintenance-details"
                exact
                component={ErrorQueueMaintenanceDetails}
              />

              <SecureRoute
                path="/mcis-type-maintenance-details"
                exact
                component={McisTypeMaintenanceDetails}
              />

              <SecureRoute
                path="/saturn-response-details"
                exact
                component={SaturnResponseDetails}
              />

              <SecureRoute
                path="/country-state-maintenance"
                exact
                component={CntryStateMaintenance}
              />

              <SecureRoute
                path="/quick-collect-database-maintenance"
                exact
                component={QuickCollectDatabaseMaintenance}
              />

              <SecureRoute
                path="/quick-collect-client-info"
                exact
                component={QuickCollectClientInformation}
              />

              <SecureRoute
                path="/quick-collect-device-information"
                exact
                component={QuickCollectDeviceInformation}
              />

              <SecureRoute
                path="/fusion-configuration"
                exact
                component={FusionConfiguration}
              />

              <SecureRoute
                path="/quick-collect-agt-irp"
                exact
                component={QuickCollectAgtIrp}
              />

              <SecureRoute
                path="/nrt-agent-network-maintenance"
                exact
                component={SearchNrtAgentNetwork}
              />

              <SecureRoute
                path="/nrt-create-agent-network"
                exact
                component={CreateEditNrtAgentNetwork}
              />

              <SecureRoute
                path="/tip-log-search"
                exact
                component={TipLogSearch}
              />

              <SecureRoute
                path="/tip-log-audit-report"
                exact
                component={TipLogAuditReport}
              />

              <SecureRoute
                path="/account-log-search"
                exact
                component={AccountLogSearch}
              />

              <SecureRoute
                path="/tip-log-account-details"
                exact
                component={TipLogAccountDetails}
              />

              <SecureRoute
                path="/fx-discount-search"
                exact
                component={FXValidDBMaintenance}
              />

              <SecureRoute
                path="/fx-discount-details"
                exact
                component={FXDiscountDetails}
              />

              <SecureRoute
                path="/edit-config-management-access"
                exact
                component={EditConfigManagementAccess}
              />

              <SecureRoute
                path="/config-management-query-executor"
                exact
                component={ConfigManagementQueryExecutor}
              />

              <SecureRoute
                path="/config-management-redis-update"
                exact
                component={ConfigManagementRedisUpdate}
              />
            </Security>
          </Router>
        </div>
      </div>
    );
  }
}
