import React from "react";
import Header from "../../header/header";
import {
  getHeadersCustomer,
} from "../../utility/utility";

import axios from "axios";

class WebTmtTransactionHistory extends React.Component {
  state = {
    records: [],
    singleData: {},
    countWTTH: 0,
    data: {
      mtcn: "",
      mtcnDate: "",
      customerId: "",
    },
    mtcnNumber: 0,
    accountNumber: "",
    accountCCNumber: "",
    receiverName: "",
    callingPhone: "",
    aniPhone: "",
    transactionType: "",
    transactionDate: "",
    chargeBackDate: "",
    chargeBackFlag: "",
    avsResponse: "",
    cvvResponse: "",
    transactionScore: 0,
    txnAmount: "",
    ipAddress: "",
    authCode: "",
    customerID: 0,
    currencyCode: "",
    destCntry: "",
    receiverText: "",
    qualifier: "",
    address: "",
    receiptOpt: "",
    clientId: "",
    payMethod: "",
    flag1: "",
    flag2: "",
    flag3: "",
    flag4: "",
    encrKsn: 11,
    tranRecvUniAddr: "",
    tranRecvCity: "",
    tranRecvState: "",
    tranRecvZip: "",
    tranRecvPhone: "",
    tranRecvCountry: "",
    tranRecvClient: "",
    scoreFraudANI: "",
    scoreRateCode: "",
    scoreFraudPhon: "",
    scoreRiskWise: "",
    scoreSSN: "",
    scoreLast10: "",
    scoreLexNex: "",
    scoreDistance: "",
    scoreOperator: "",
    scoreFraudPayee: "",
    scoreDOB: "",
    scoreCvv2: "",
    scorePhone: "",
    scoreANI: "",
    scoreAddress: "",
    isCustomerInfoLoading:false,
    isPrevTransacLoading:false,
    isNextTransacLoading:false,
    isTransacWebTmtLoading:false
  };

   componentDidMount() {
    this.setState((prevState) => {
      return {
        // enableBtn: true,
        data: {
          ...prevState,
          state: this.props.location.state,
        },
      };
    });
    let tempEnableNext =
      this.props.location.state.records &&
      this.props.location.state.records.length > 1
        ? true
        : false;
    this.setState({
      enableNextWTTH: tempEnableNext,
      webAdmin:this.props.location.state.webAdmin,
      tokenWebTmt: this.props.location.state.tokenWebTmt,
      accessGrantedWrite: this.props.location.state.accessGrantedWrite,
      isAddressDecryprtRequired: this.props.location.state.isAddressDecryprtRequired,
      isCreditCardDecryprtRequired: this.props.location.state.isCreditCardDecryprtRequired,
      operatorId: this.props.location.operatorId
    });
    if (
      this.props.location.state.records &&
      this.props.location.state.records.length > 0
    ) {
      this.setState({
        records: this.props.location.state.records,
        singleData: this.props.location.state.records[0],
        accessMTCCDisplay:this.props.location.state.accessMTCCDisplay,
        operatorId: this.props.location.state.operatorId
      });      
    }

    // {
    //   /* axios
    //       .get(`http://10.252.64.81:6084/v1/cus/dbview/webtmtdbmaintenance/txnhistory`, 
    //         {
    //           headers: { 
    //           ...getHeaders().headers,
    //           mtcn:  this.props.location.state.mtcn,
    //           mtcnDate: this.props.location.state.mtcnDate,
    //           customerId: this.props.location.state.customerId ? this.props.location.state.customerId :""
    //         }
    //        }
    //       )
    //       .then((response) => {
    //         if (response.data.records.length > 0) {
    //           this.setState({
    //             records: response.data.records,
    //             singleData: response.data.records[0],
    //           });
    //         }
    //         let tempEnableNext = this.state.records.length > 1 ? true : false;
    //         this.setState({
    //           enableNextWTTH: tempEnableNext,
    //         });
    //       });		
    //     */
    // }
  }

  onHandleBackTransHistoryWebTmtMain = () => {
    this.setState({
      isTransacWebTmtLoading: true
    })
    this.props.history.push("/web-tmt-db-maintenance");
    this.setState({
      isTransacWebTmtLoading: false
    })
  };

  onHandleBufferDataWTTH = (type) => {
    if (type === "prevWTTH") {
      this.setState(
        {
          countWTTH: this.state.countWTTH - 1,
          isPrevTransacLoading: true
        },
        () => {
          this.setState({
            enableNextWTTH: true,
            singleData: this.state.records[this.state.countWTTH],
             isPrevTransacLoading: false
          });
          if (this.state.countWTTH === 0) {
            this.setState({
              enablePrevWTTH: false,
              enableNextWTTH: true,
            });
          }
        }
      );
    } else {
      if (this.state.countWTTH < this.state.records.length - 1) {
        this.setState(
          {
            countWTTH: this.state.countWTTH + 1,
            isNextTransacLoading: true
          },
          () => {
            this.setState({
              enablePrevWTTH: true,
              singleData: this.state.records[this.state.countWTTH],
              isNextTransacLoading: false
            });
            if (this.state.countWTTH === this.state.records.length - 1) {
              this.setState({
                enablePrevWTTH: true,
                enableNextWTTH: false,
              });
            }
          }
        );
      }
    }
  };
  onHandleBackCustomerInfo = () => {
    this.setState({
      isCustomerInfoLoading: true
    })
    
    if (this.state.singleData.customerNumber !== "" ) {     
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customerinfo`,
          {
            headers: {
              ...getHeadersCustomer().headers,
              customerNumber: this.state.singleData.customerID
                ? this.state.singleData.customerID
                : "",
              accountNumber: "",
              Authorization: this.state.tokenWebTmt
                ? this.state.tokenWebTmt
                : "",
              userId:this.state.operatorId,
            },
          }
        )
        .then((response) => {
          this.setState({
            isCustomerInfoLoading: false,
          });
          if (
            response.status === 200 &&
            response.data.records &&
            response.data.records.length > 0
          ) {
            this.setState({
              records: response.data.records,
              singleData: response.data.records[0],
              customerAccountId: this.props.location.state.customerNumber,
              operatorId: this.state.operatorId,
            });
            if (this.state.singleData.responseType === 1) {
              this.props.history.push({
                pathname: "/web-tmt-customer-information",
                state: this.state,
              });
            }
          } else {
            this.setState({
              submitStatusWebTmtTransHistory: "Error",
              submitMsgWebTmtTransHistory: response.data.errMsg,
            });
            return false;
          }
        });
    }
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WEB/TMT Database Maintenance"
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                {" "}
                WEB/TMT Database Maintenance - Transaction History
              </h4>
              <div className="card ">
                <div className="card-header bg-dark text-white card-header-custom">
                  Record {this.state.countWTTH + 1} of{" "}
                  {this.state.records ? this.state.records.length : ""}
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="mtcnNumber"
                          className="font-weight-bold"
                        >
                          MTCN Number
                        </label>
                        <input
                          type="text"
                          maxLength="16"
                          value={
                            this.state.singleData.mtcnNumber
                              ? this.state.singleData.mtcnNumber.substring(
                                  6,
                                  this.state.singleData.mtcnNumber.length
                                )
                              : ""
                          }
                          className="form-control form-control-sm"
                          name="mtcnNumber"
                          data-test="text-mtcnNumber"
                          placeholder="MTCN Number"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="accountNumber"
                          className="font-weight-bold"
                        >
                          Account Number
                        </label>
                        <input
                          type="text"
                          maxLength="24"
                          value={this.state.singleData.accountCCNumber}
                          className="form-control form-control-sm"
                          name="accountNumber"
                          data-test="text-accountNumber"
                          placeholder="Account Number"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="receiverName"
                          className="font-weight-bold"
                        >
                          Receiver Name
                        </label>
                        <input
                          type="text"
                          maxLength="9"
                          value={this.state.singleData.receiverName}
                          className="form-control form-control-sm"
                          name="receiverName"
                          data-test="text-receiverName"
                          placeholder="Receiver Name"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="callingPhone"
                          className="font-weight-bold"
                        >
                          Calling Phone
                        </label>
                        <input
                          type="text"
                          maxLength="16"
                          value={this.state.singleData.callingPhone}
                          className="form-control form-control-sm"
                          name="callingPhone"
                          data-test="text-callingPhone"
                          placeholder="Calling Phone"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="aniPhone" className="font-weight-bold">
                          ANI Phone
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.aniPhone}
                          className="form-control form-control-sm"
                          name="aniPhone"
                          data-test="text-aniPhone"
                          placeholder="ANI Phone"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="transactionType"
                          className="font-weight-bold"
                        >
                          Transaction Type
                        </label>
                        <input
                          type="text"
                          maxLength="84"
                          value={this.state.singleData.transactionType}
                          className="form-control form-control-sm"
                          name="transactionType"
                          data-test="text-transactionType"
                          placeholder="Transaction Type"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="transactionDate"
                          className="font-weight-bold"
                        >
                          Transaction Date
                        </label>
                        <input
                          type="text"
                          maxLength="84"
                          value={this.state.singleData.transactionDate}
                          className="form-control form-control-sm"
                          name="transactionDate"
                          data-test="text-transactionDate"
                          placeholder="Transaction Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="chargeBackDate"
                          className="font-weight-bold"
                        >
                          Charge Back Date
                        </label>
                        <input
                          type="text"
                          maxLength="15"
                          value={this.state.singleData.chargeBackDate}
                          className="form-control form-control-sm"
                          name="chargeBackDate"
                          data-test="text-chargeBackDate"
                          placeholder="Charge Back Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="chargeBackFlag"
                          className="font-weight-bold"
                        >
                          Charge Back Flag
                        </label>
                        <input
                          type="text"
                          maxLength="40"
                          value={this.state.singleData.chargeBackFlag}
                          className="form-control form-control-sm"
                          name="chargeBackFlag"
                          data-test="text-chargeBackFlag"
                          disabled
                          placeholder="Charge Back Flag"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="avsResponse"
                          className="font-weight-bold"
                        >
                          AVS Response
                        </label>
                        <input
                          type="text"
                          maxLength="40"
                          value={this.state.singleData.avsResponse}
                          className="form-control form-control-sm"
                          name="avsResponse"
                          data-test="text-avsResponse"
                          disabled
                          placeholder="AVS Response"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="cvvResponse"
                          className="font-weight-bold"
                        >
                          CVV Response
                        </label>
                        <input
                          type="text"
                          maxLength="15"
                          value={this.state.singleData.cvvResponse}
                          className="form-control form-control-sm"
                          name="cvvResponse"
                          data-test="text-cvvResponse"
                          disabled
                          placeholder="CVV Response"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="transactionScore"
                          className="font-weight-bold"
                        >
                          Transaction Score
                        </label>
                        <input
                          type="text"
                          maxLength="16"
                          value={this.state.singleData.transactionScore}
                          className="form-control form-control-sm"
                          name="transactionScore"
                          data-test="text-transactionScore"
                          disabled
                          placeholder="Transaction Score"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="txnAmount" className="font-weight-bold">
                          TXN Amount
                        </label>
                        <input
                          type="text"
                          maxLength="16"
                          value={this.state.singleData.txnAmount}
                          className="form-control form-control-sm"
                          name="txnAmount"
                          data-test="text-txnAmount"
                          disabled
                          placeholder="TXN Amount"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="ipAddress" className="font-weight-bold">
                          IP Address
                        </label>
                        <input
                          type="text"
                          maxLength="16"
                          value={this.state.singleData.ipAddress}
                          className="form-control form-control-sm"
                          name="ipAddress"
                          data-test="text-ipAddress"
                          placeholder="IP Address"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="authCode" className="font-weight-bold">
                          Auth Code
                        </label>
                        <input
                          type="text"
                          maxLength="16"
                          value={this.state.singleData.authCode}
                          className="form-control form-control-sm"
                          name="authCode"
                          data-test="text-authCode"
                          placeholder="Auth Code"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="card ">
                      <div className="card-header bg-dark text-white card-header-custom">
                        RECEIVING DETAILS
                      </div>
                      <div className="card-body card-body-custom">
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="customerID"
                              className="font-weight-bold"
                            >
                              Customer Id
                            </label>
                            <input
                              type="text"
                              maxLength="20"
                              value={this.state.singleData.customerID}
                              className="form-control form-control-sm"
                              name="customerID"
                              data-test="text-customerID"
                              placeholder="Customer Id"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="currencyCode"
                              className="font-weight-bold"
                            >
                              Currency Code
                            </label>
                            <input
                              type="text"
                              maxLength="1"
                              value={this.state.singleData.currencyCode}
                              className="form-control form-control-sm"
                              name="currencyCode"
                              data-test="text-currencyCode"
                              placeholder="Currency Code"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="destCntry"
                              className="font-weight-bold"
                            >
                              Dest Cntry
                            </label>
                            <input
                              type="text"
                              value={this.state.singleData.destCntry}
                              maxLength="15"
                              className="form-control form-control-sm"
                              name="destCntry"
                              data-test="text-destCntry"
                              placeholder="Dest Cntry"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="receiverText"
                              className="font-weight-bold"
                            >
                              Receiver Text
                            </label>
                            <input
                              type="text"
                              maxLength="13"
                              value={this.state.singleData.receiverText}
                              className="form-control form-control-sm"
                              name="receiverText"
                              data-test="text-receiverText"
                              placeholder="Receiver Text"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="qualifier"
                              className="font-weight-bold"
                            >
                              Qualifier
                            </label>
                            <input
                              type="text"
                              maxLength="9"
                              value={this.state.singleData.qualifier}
                              className="form-control form-control-sm"
                              name="qualifier"
                              data-test="text-qualifier"
                              placeholder="Qualifier"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="address"
                              className="font-weight-bold"
                            >
                              Receiver Address
                            </label>
                            <input
                              type="text"
                              maxLength="13"
                              value={this.state.singleData.address}
                              className="form-control form-control-sm"
                              name="address"
                              data-test="text-address"
                              placeholder="Receiver Address"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="receiptOpt"
                              className="font-weight-bold"
                            >
                              Receipt Opt
                            </label>
                            <input
                              type="text"
                              maxLength="20"
                              value={this.state.singleData.receiptOpt}
                              className="form-control form-control-sm"
                              name="receiptOpt"
                              data-test="text-receiptOpt"
                              placeholder="Receipt Opt"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="tranRecvCity"
                              className="font-weight-bold"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              maxLength="1"
                              value={this.state.singleData.tranRecvCity}
                              className="form-control form-control-sm"
                              name="tranRecvCity"
                              data-test="text-tranRecvCity"
                              disabled
                              placeholder="City"
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="tranRecvState"
                              className="font-weight-bold"
                            >
                              State
                            </label>
                            <input
                              type="text"
                              value={this.state.singleData.tranRecvState}
                              maxLength="15"
                              className="form-control form-control-sm"
                              name="tranRecvState"
                              data-test="text-tranRecvState"
                              placeholder="State"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="tranRecvZip"
                              className="font-weight-bold"
                            >
                              Zip
                            </label>
                            <input
                              type="text"
                              maxLength="13"
                              value={this.state.singleData.tranRecvZip}
                              className="form-control form-control-sm"
                              name="tranRecvZip"
                              data-test="text-tranRecvZip"
                              placeholder="Zip"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="tranRecvPhone"
                              className="font-weight-bold"
                            >
                              Phone
                            </label>
                            <input
                              type="text"
                              maxLength="9"
                              value={this.state.singleData.tranRecvPhone}
                              className="form-control form-control-sm"
                              name="tranRecvPhone"
                              data-test="text-tranRecvPhone"
                              placeholder="Phone"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="tranRecvCountry"
                              className="font-weight-bold"
                            >
                              Country
                            </label>
                            <input
                              type="text"
                              maxLength="13"
                              value={this.state.singleData.tranRecvCountry}
                              className="form-control form-control-sm"
                              name="tranRecvCountry"
                              data-test="text-tranRecvCountry"
                              placeholder="Country"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="tranRecvClient"
                              className="font-weight-bold"
                            >
                              Client Id
                            </label>
                            <input
                              type="text"
                              maxLength="20"
                              value={this.state.singleData.tranRecvClient}
                              className="form-control form-control-sm"
                              name="tranRecvClient"
                              data-test="text-tranRecvClient"
                              placeholder="Client Id"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label
                              htmlFor="payMethod"
                              className="font-weight-bold"
                            >
                              Pay Method
                            </label>
                            <input
                              type="text"
                              maxLength="1"
                              value={this.state.singleData.payMethod}
                              className="form-control form-control-sm"
                              name="payMethod"
                              data-test="text-payMethod"
                              placeholder="Pay Method"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="flag1" className="font-weight-bold">
                              Flag1
                            </label>
                            <input
                              type="text"
                              value={this.state.singleData.flag1}
                              maxLength="15"
                              className="form-control form-control-sm"
                              name="flag1"
                              data-test="text-flag1"
                              placeholder="Flag1"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="flag2" className="font-weight-bold">
                              Flag2
                            </label>
                            <input
                              type="text"
                              maxLength="13"
                              value={this.state.singleData.flag2}
                              className="form-control form-control-sm"
                              name="flag2"
                              data-test="text-flag2"
                              placeholder="Flag2"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="flag3" className="font-weight-bold">
                              Flag3
                            </label>
                            <input
                              type="text"
                              maxLength="9"
                              value={this.state.singleData.flag3}
                              className="form-control form-control-sm"
                              name="flag3"
                              data-test="text-flag3"
                              placeholder="Flag3"
                              disabled
                            />
                          </div>
                          <div className="col-lg">
                            <label htmlFor="flag4" className="font-weight-bold">
                              Flag4
                            </label>
                            <input
                              type="text"
                              maxLength="13"
                              value={this.state.singleData.flag4}
                              className="form-control form-control-sm"
                              name="flag4"
                              data-test="text-flag4"
                              placeholder="Flag4"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg">
                            <label
                              htmlFor="encrKsn"
                              className="font-weight-bold"
                            >
                              ENCR KSN
                            </label>
                            <input
                              type="text"
                              maxLength="13"
                              value={this.state.singleData.encrKsn}
                              className="form-control form-control-sm"
                              name="encrKsn"
                              data-test="text-encrKsn"
                              placeholder="ENCR KSN"
                              disabled
                            />
                          </div>
                          <div className="col-lg"></div>
                          <div className="col-lg"></div>
                          <div className="col-lg"></div>
                          <div className="col-lg"></div>
                          <div className="col-lg"></div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        {this.state.isTransacWebTmtLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleBackTransHistoryWebTmtMain}
                            data-test="button-webTmtMaint"
                            disabled={
                              this.state.isCustomerInfoLoading === true ||
                              this.state.isPrevTransacLoading === true ||
                              this.state.isNextTransacLoading === true ||
                              this.state.isTransacWebTmtLoading === true
                            }
                          >
                            WEBTMT MAINT
                          </button>
                        )}
                        {this.state.isPrevTransacLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mr-2"
                            onClick={() =>
                              this.onHandleBufferDataWTTH("prevWTTH")
                            }
                            data-test="WEBTMTAcctInfo-button-prevWTTH"
                            disabled={
                              !this.state.enablePrevWTTH ||
                              this.state.isCustomerInfoLoading === true ||
                              this.state.isPrevTransacLoading === true ||
                              this.state.isNextTransacLoading === true ||
                              this.state.isTransacWebTmtLoading === true
                            }
                          >
                            Previous
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-warning mr-2"
                          onClick={() => this.onHandleBufferDataWTTH("next")}
                          data-test="WEBTMTAcctInfo-button-tcn"
                          disabled={
                            !this.state.enableNextWTTH ||
                            this.state.isCustomerInfoLoading === true ||
                            this.state.isPrevTransacLoading === true ||
                            this.state.isNextTransacLoading === true ||
                            this.state.isTransacWebTmtLoading === true
                          }
                        >
                          Next
                        </button>
                        {this.state.isCustomerInfoLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleBackCustomerInfo}
                            data-test="WEBTMTAcctInfo-button-ci"
                            disabled={
                              this.state.isCustomerInfoLoading === true ||
                              this.state.isPrevTransacLoading === true ||
                              this.state.isNextTransacLoading === true ||
                              this.state.isTransacWebTmtLoading === true
                            }
                          >
                            CUSTOMER INFO
                          </button>
                        )}
                      </div>
                      <div className="col-lg-3">
                        {this.state.submitStatusWebTmtTransHistory === "OK" && (
                          <div className="alert alert-success alert-padding notification">
                            {this.state.submitMsgWebTmtTransHistory}
                          </div>
                        )}
                        {this.state.submitStatusWebTmtTransHistory ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification">
                            {this.state.submitMsgWebTmtTransHistory}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebTmtTransactionHistory;
