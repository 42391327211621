import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersProduct } from "../../utility/utility";

class FusionConfiguration extends Component {
  state = {
    fusionConfiguration: {
      marketVertical: "RN",
      feeModifier: "",
      posTemplate: "",
      sod0: "",
      sod1: "",
      sod2: "",
      sod3: "",
      sellPrepaid: "",
      comments: "",
      qccClientCode: "",
      version: "",
      lastUpdated: "",
      operator: "",
      multipleNotification: "",
      ignoreDelays: "",
      accountReqdFlag: "",
      preconfiguredPayee: "",
      ignoreDuplicateQ: "",
      workDayIndicator: "",
      cutOffHours1: "",
      cutOffHours2: "",
      cutOffHours3: "",
      cutOffHours4: "",
      cutOffHours5: "",
      cutOffHours6: "",
      cutOffHours7: "",
    },
    arrayCheckBox: [],
    valOfMV:[],
    submitStatusFusionConfiguration: null,
    submitMsgFusionConfiguration: "",
  };

  onChangeControlFusionConfiguration = (e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    //console.log(newName, newValue);
    this.setState((prevState) => {
      return {
        fusionConfiguration: {
          ...prevState.fusionConfiguration,
          [newName]: newValue.toUpperCase(),
        },
      };
    });
  };

  onChangeControlFusionConfigurationOnlyNumber = (e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    //console.log(newName, newValue);
    this.setState((prevState) => {
      return {
        fusionConfiguration: {
          ...prevState.fusionConfiguration,
          [newName]: newValue.replace(/[^\d]/, ""),
        },
      };
    });
  };

  onChangeControlCheckFusionConfiguration1 = (e, index) => {
    //debugger
    let arrayCheckBoxNew = this.state.arrayCheckBox;
  
    if (e.target.value === "N") {
      arrayCheckBoxNew[index] = "Y";
    } else {
      arrayCheckBoxNew[index] = "N";
    }
    this.setState({ arrayCheckBox: arrayCheckBoxNew });
  };

  onChangeControlCheckFusionConfiguration = (e) => {
    //debugger
    const { name, value } = e.target;

    if (value === "N") {
      this.setState((prevState) => {
        return {
          fusionConfiguration: {
            ...prevState.fusionConfiguration,
            [name]: "Y",
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          fusionConfiguration: {
            ...prevState.fusionConfiguration,
            [name]: "N",
          },
        };
      });
    }
  };

  componentDidMount = () => {
    axios	
    .get(	
      `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/mcisconstants`,	
      {	
        headers: {	
          ...getHeadersProduct().headers,	
          Authorization:this.props.location.state.data.tokenQCDM,	
          screenName: "fusionConfiguration"	
        },	
      }	
    )	
    .then((response) => {	
      this.setState({	
        valOfMV: response.data.marketVerticalConstants,	
      });	
    });
    this.onLoadFC();
  };
  onLoadFC=()=>{
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/fusionconfig/${this.props.location.state.cityCodeState}`,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization: this.props.location.state.data.tokenQCDM,
            userId: this.props.location.state.data.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        if (response.data !== null) {
          this.setState({
            fusionConfiguration: response.data,
            fc: response.data,
            arrayCheckBox:
              response.data.workDayIndicator === null
                ? []
                : response.data.workDayIndicator.split(""),
            acb:
              response.data.workDayIndicator === null
                ? []
                : response.data.workDayIndicator.split(""),
          });
        }
      });
  }
  onHandleRefreshFusionConfig = () => {
    this.setState({
      fusionConfiguration: this.state.fc,
      arrayCheckBox: this.state.acb,
    });
  };

  onHandleQCCMenuFusionConfig = () => {
    this.props.history.push("/quick-collect-database-maintenance");
  };

  onHandleQCCClientFusionConfig = () => {
    this.props.history.push({
      pathname: "/quick-collect-client-info",
      state: this.props.location.state.data,
    });
  };

  onvalidateFusionConfiguration = () => {
    if (this.state.fusionConfiguration.cutOffHours1 > 2400) {
      this.setState({
        submitStatusFusionConfiguration: "Error",
        submitMsgFusionConfiguration:
          "Sunday Cut Off Hour Should Not Be Greater Than 2400",
      });
      return false;
    }
    if (this.state.fusionConfiguration.cutOffHours2 > 2400) {
      this.setState({
        submitStatusFusionConfiguration: "Error",
        submitMsgFusionConfiguration:
          "Monday Cut Off Hour Should Not Be Greater Than 2400",
      });
      return false;
    }
    if (this.state.fusionConfiguration.cutOffHours3 > 2400) {
      this.setState({
        submitStatusFusionConfiguration: "Error",
        submitMsgFusionConfiguration:
          "Tuesday Cut Off Hour Should Not Be Greater Than 2400",
      });
      return false;
    }
    if (this.state.fusionConfiguration.cutOffHours4 > 2400) {
      this.setState({
        submitStatusFusionConfiguration: "Error",
        submitMsgFusionConfiguration:
          "Wednesday Cut Off Hour Should Not Be Greater Than 2400",
      });
      return false;
    }
    if (this.state.fusionConfiguration.cutOffHours5 > 2400) {
      this.setState({
        submitStatusFusionConfiguration: "Error",
        submitMsgFusionConfiguration:
          "Thursday Cut Off Hour Should Not Be Greater Than 2400",
      });
      return false;
    }
    if (this.state.fusionConfiguration.cutOffHours6 > 2400) {
      this.setState({
        submitStatusFusionConfiguration: "Error",
        submitMsgFusionConfiguration:
          "Friday Cut Off Hour Should Not Be Greater Than 2400",
      });
      return false;
    }
    if (this.state.fusionConfiguration.cutOffHours7 > 2400) {
      this.setState({
        submitStatusFusionConfiguration: "Error",
        submitMsgFusionConfiguration:
          "Saturday Cut Off Hour Should Not Be Greater Than 2400",
      });
      return false;
    }
    return true;
  };

  onHandleUpdateFusionConfig = () => {
    if (!this.onvalidateFusionConfiguration()) {
      return;
    }
    let submittedObj = {};
    submittedObj = this.state.fusionConfiguration;
    submittedObj.workDayIndicator = this.state.arrayCheckBox.join("");
    if(submittedObj.marketVertical.trim()=== ''){
      submittedObj.marketVertical = 'RN';
    }
    //console.log(submittedObj);
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/fusionconfig`,
        submittedObj,
        {
          headers: {
            ...getHeadersProduct().headers,            
            Authorization: this.props.location.state.data.tokenQCDM,
            userId: this.props.location.state.data.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          this.setState({
            submitStatusFusionConfiguration: "OK",
            submitMsgFusionConfiguration: response.data.responseString,
          });
          this.onLoadFC();
        } else {
          this.setState({
            submitStatusFusionConfiguration: "Error",
            submitMsgFusionConfiguration: response.data.responseString,
          });
        }
      });
  };

  getMarketVertical = () => {
    if(this.state.valOfMV.length > 0) {	
      return this.state.valOfMV.map((item) => (	
        <option key={item.value} value={item.key}>	
          {item.value}	
        </option>	
      ));	
    } else {	
      return null;	
    }
  };

  getSellPrepaid = (uniqueKey) => {
    let valOfSP = ["N","A","B","C","D","E","F","G","H","I","J","K","L","M","O","P","Q","T","U","V","W","X","Z","0","1","2","3","4","5","6","7","8","9"];
    let resultValOfSP = valOfSP.map((item) => (
      <option key={[item, uniqueKey]} value={item}>
        {item}
      </option>
    ));
    return [<option value="">-Select-</option>, resultValOfSP];
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="FUSION CONFIGURATION" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">FUSION CONFIGURATION</h4>
            <div className="form-group row">
              <div className="col-lg-2" />
              <div className="col-lg-8">
                <div className="card mar-bottom20">
                  <div className="card-header bg-dark text-white card-header-custom">
                    CLIENT CLASSIFICATION
                  </div>
                  <div className="card-body card-body-custom">
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="marketVertical"
                          className="font-weight-bold"
                        >
                          Market Vetical
                        </label>
                        <select
                          className="form-control  form-control-sm "
                          name="marketVertical"
                          data-test="text-marketVertical"
                          onChange={(e) => {
                            this.onChangeControlFusionConfiguration(e);
                          }}
                          value={this.state.fusionConfiguration.marketVertical}
                        >
                          {this.getMarketVertical()}
                        </select>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="feeModifier"
                          className="font-weight-bold"
                        >
                          Fee Modifier
                        </label>
                        <input
                          type="text"
                          maxlength="8"
                          value={this.state.fusionConfiguration.feeModifier}
                          className="form-control form-control-sm"
                          name="feeModifier"
                          onChange={(e) => {
                            this.onChangeControlFusionConfiguration(e);
                          }}
                          data-test="text-feeModifier"
                          placeholder="Fee Modifier"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="posTemplate"
                          className="font-weight-bold"
                        >
                          POS Template
                        </label>
                        <input
                          type="text"
                          maxlength="5"
                          value={this.state.fusionConfiguration.posTemplate}
                          className="form-control form-control-sm"
                          name="posTemplate"
                          onChange={(e) => {
                            this.onChangeControlFusionConfiguration(e);
                          }}
                          data-test="text-posTemplate"
                          placeholder="POS Template"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-2 mar-top20">
                        <label className="font-weight-bold">
                          Speed of Delivery:
                        </label>
                      </div>
                      <div className="col-lg mar-top20">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          name="sod0"
                          id="sod0"
                          checked={this.state.fusionConfiguration.sod0 === "Y"}
                          value={this.state.fusionConfiguration.sod0}
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="checkbox-sod0"
                        />
                        <label className="form-check-label mar-top3">Same Day</label>
                      </div>
                      <div className="col-lg mar-top20">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="sod1"
                          id="sod1"
                          checked={this.state.fusionConfiguration.sod1 === "Y"}
                          value={this.state.fusionConfiguration.sod1}
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="checkbox-sod1"
                        />
                        <label className="form-check-label mar-top3">Next Day</label>
                      </div>
                      <div className="col-lg mar-top20">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="sod2"
                          id="sod2"
                          value={this.state.fusionConfiguration.sod2}
                          checked={this.state.fusionConfiguration.sod2 === "Y"}
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="checkbox-sod2"
                        />
                        <label className="form-check-label mar-top3">2nd Day</label>
                      </div>
                      <div className="col-lg mar-top20">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="sod3"
                          id="sod3"
                          value={this.state.fusionConfiguration.sod3}
                          checked={this.state.fusionConfiguration.sod3 === "Y"}
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="checkbox-sod3"
                        />
                        <label className="form-check-label mar-top3"> 3rd Day</label>
                      </div>
                      <div className="col-lg-4">
                        <label
                          htmlFor="sellPrepaid"
                          className="font-weight-bold"
                        >
                          Sell Prepaid
                        </label>
                        {this.props.location.state.rppsBiller === "Y" ?
                          (
                            <select
                              className="form-control  form-control-sm "
                              name="sellPrepaid"
                              disabled
                              data-test="text-sellPrepaid"
                              value="R"
                            >
                              <option value="R">R</option>
                            </select>
                          ) : (
                            <select
                              className="form-control  form-control-sm "
                              name="sellPrepaid"
                              data-test="text-sellPrepaid"
                              onChange={(e) => {
                                this.onChangeControlFusionConfiguration(e);
                              }}
                              value={this.state.fusionConfiguration.sellPrepaid}
                            >
                              {this.getSellPrepaid()}
                            </select>
                          )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-8">
                        <label className="font-weight-bold">Comments</label>
                        <input
                          type="text"
                          maxlength="40"
                          value={this.state.fusionConfiguration.comments}
                          className="form-control form-control-sm"
                          name="comments"
                          onChange={(e) => {
                            this.onChangeControlFusionConfiguration(e);
                          }}
                          data-test="text-comments"
                          placeholder="Comments"
                        />
                      </div>
                      {/* <div className="col-lg">
                        
                      </div> */}
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">
                          QCC Client Code :
                        </label>{" "}
                        {this.state.fusionConfiguration.qccClientCode}
                      </div>
                      <div className="col-lg">
                        <label
                          className="mar-left30 font-weight-bold"
                        >
                          Version :
                        </label>{" "}
                        {this.state.fusionConfiguration.version}
                      </div>
                      <div className="col-lg-4">
                        <label className="font-weight-bold">
                          Last Updated :
                        </label>{" "}
                        {this.state.fusionConfiguration.lastUpdated}
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold"> Operator :</label>{" "}
                        {this.state.fusionConfiguration.operator}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mar-bottom20">
                  <div className="card-header bg-dark text-white card-header-custom">
                    CLIENT SPECIAL FLAGS
                  </div>
                  <div className="card-body">
                    <div className="form-group row padding-left21">
                      <div className="col-lg">
                        <input
                          className="form-check-input mar-top3"
                          type="checkbox"
                          name="multipleNotification"
                          id="multipleNotification"
                          value={
                            this.state.fusionConfiguration.multipleNotification
                          }
                          checked={
                            this.state.fusionConfiguration
                              .multipleNotification === "Y"
                          }
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="select-checkbox-multipleNotification"
                        />
                        <label className="form-check-label mar-top3">
                          Multiple Notification
                        </label>
                      </div>
                      <div className="col-lg">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ignoreDelays"
                          id="ignoreDelays"
                          value={this.state.fusionConfiguration.ignoreDelays}
                          checked={
                            this.state.fusionConfiguration.ignoreDelays === "Y"
                          }
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="select-checkbox-ignoreDelays"
                        />
                        <label className="form-check-label mar-top3">
                          Ignore Delays
                        </label>
                      </div>
                      <div className="col-lg">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="accountReqdFlag"
                          id="accountReqdFlag"
                          value={this.state.fusionConfiguration.accountReqdFlag}
                          checked={
                            this.state.fusionConfiguration.accountReqdFlag ===
                            "Y"
                          }
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="select-checkbox-accountReqdFlag"
                        />
                        <label className="form-check-label mar-top3">
                          Account Reqd Flag
                        </label>
                      </div>
                      <div className="col-lg">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="preconfiguredPayee"
                          id="preconfiguredPayee"
                          value={
                            this.state.fusionConfiguration.preconfiguredPayee
                          }
                          checked={
                            this.state.fusionConfiguration
                              .preconfiguredPayee === "Y"
                          }
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="select-checkbox-preconfiguredPayee"
                        />
                        <label className="form-check-label mar-top3">
                          Preconfigured Payee
                        </label>
                      </div>
                      <div className="col-lg">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ignoreDuplicateQ"
                          id="ignoreDuplicateQ"
                          value={
                            this.state.fusionConfiguration.ignoreDuplicateQ
                          }
                          checked={
                            this.state.fusionConfiguration.ignoreDuplicateQ ===
                            "Y"
                          }
                          onChange={(e) => {
                            this.onChangeControlCheckFusionConfiguration(e);
                          }}
                          data-test="select-checkbox-ignoreDuplicateQ"
                        />
                        <label className="form-check-label mar-top3">
                          Ignore Duplicate Q
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mar-bottom20">
                  <div className="card-header bg-dark text-white card-header-custom">
                    CLIENT HOURS OF OPERATION
                  </div>
                  <div className="card-body card-body-custom">
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label className="font-weight-bold">Day:</label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Sunday</label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Monday</label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Tuesday</label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Wednesday</label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Thursday</label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Friday</label>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Saturday</label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label className="font-weight-bold">
                          Cut Off Hours:
                        </label>
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.fusionConfiguration.cutOffHours1}
                          className="form-control form-control-sm"
                          name="cutOffHours1"
                          onChange={(e) => {
                            this.onChangeControlFusionConfigurationOnlyNumber(
                              e
                            );
                          }}
                          data-test="text-cutOffHours1"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.fusionConfiguration.cutOffHours2}
                          className="form-control form-control-sm"
                          name="cutOffHours2"
                          onChange={(e) => {
                            this.onChangeControlFusionConfigurationOnlyNumber(
                              e
                            );
                          }}
                          data-test="text-cutOffHours2"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.fusionConfiguration.cutOffHours3}
                          className="form-control form-control-sm"
                          name="cutOffHours3"
                          onChange={(e) => {
                            this.onChangeControlFusionConfigurationOnlyNumber(
                              e
                            );
                          }}
                          data-test="text-cutOffHours3"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.fusionConfiguration.cutOffHours4}
                          className="form-control form-control-sm"
                          name="cutOffHours4"
                          onChange={(e) => {
                            this.onChangeControlFusionConfigurationOnlyNumber(
                              e
                            );
                          }}
                          data-test="text-cutOffHours4"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.fusionConfiguration.cutOffHours5}
                          className="form-control form-control-sm"
                          name="cutOffHours5"
                          onChange={(e) => {
                            this.onChangeControlFusionConfigurationOnlyNumber(
                              e
                            );
                          }}
                          data-test="text-cutOffHours5"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.fusionConfiguration.cutOffHours6}
                          className="form-control form-control-sm"
                          name="cutOffHours6"
                          onChange={(e) => {
                            this.onChangeControlFusionConfigurationOnlyNumber(
                              e
                            );
                          }}
                          data-test="text-cutOffHours6"
                        />
                      </div>
                      <div className="col-lg">
                        <input
                          type="text"
                          maxlength="4"
                          value={this.state.fusionConfiguration.cutOffHours7}
                          className="form-control form-control-sm"
                          name="cutOffHours7"
                          onChange={(e) => {
                            this.onChangeControlFusionConfigurationOnlyNumber(
                              e
                            );
                          }}
                          data-test="text-cutOffHours7"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label className="font-weight-bold">
                          Work Day Indicator:
                        </label>
                      </div>
                      <div className="col-lg">
                        <div className="form-group row">
                          <div className="col-lg" />
                          <div className="col-lg">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="arrayCheckBox0"
                              id="arrayCheckBox0"
                              value={this.state.arrayCheckBox[0]}
                              checked={this.state.arrayCheckBox[0] === "Y"}
                              onChange={(e) => {
                                this.onChangeControlCheckFusionConfiguration1(
                                  e,
                                  0
                                );
                              }}
                              data-test="checkbox-arrayCheckBox0"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-group row">
                          <div className="col-lg" />
                          <div className="col-lg">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              //name="fusionConfigurationChekbox"
                              name="arrayCheckBox1"
                              id="arrayCheckBox1"
                              value={this.state.arrayCheckBox[1]}
                              checked={this.state.arrayCheckBox[1] === "Y"}
                              onChange={(e) => {
                                this.onChangeControlCheckFusionConfiguration1(
                                  e,
                                  1
                                );
                              }}
                              data-test="checkbox-arrayCheckBox1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-group row">
                          <div className="col-lg" />
                          <div className="col-lg">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="arrayCheckBox2"
                              id="arrayCheckBox2"
                              value={this.state.arrayCheckBox[2]}
                              checked={this.state.arrayCheckBox[2] === "Y"}
                              onChange={(e) => {
                                this.onChangeControlCheckFusionConfiguration1(
                                  e,
                                  2
                                );
                              }}
                              data-test="checkbox-arrayCheckBox2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-group row">
                          <div className="col-lg" />
                          <div className="col-lg">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="arrayCheckBox3"
                              id="arrayCheckBox3"
                              value={this.state.arrayCheckBox[3]}
                              checked={this.state.arrayCheckBox[3] === "Y"}
                              onChange={(e) => {
                                this.onChangeControlCheckFusionConfiguration1(
                                  e,
                                  3
                                );
                              }}
                              data-test="checkbox-arrayCheckBox3"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-group row">
                          <div className="col-lg" />
                          <div className="col-lg">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="arrayCheckBox4"
                              id="arrayCheckBox4"
                              value={this.state.arrayCheckBox[4]}
                              checked={this.state.arrayCheckBox[4] === "Y"}
                              onChange={(e) => {
                                this.onChangeControlCheckFusionConfiguration1(
                                  e,
                                  4
                                );
                              }}
                              data-test="checkbox-arrayCheckBox4"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-group row">
                          <div className="col-lg" />
                          <div className="col-lg">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="arrayCheckBox5"
                              id="arrayCheckBox5"
                              value={this.state.arrayCheckBox[5]}
                              checked={this.state.arrayCheckBox[5] === "Y"}
                              onChange={(e) => {
                                this.onChangeControlCheckFusionConfiguration1(
                                  e,
                                  5
                                );
                              }}
                              data-test="checkbox-arrayCheckBox5"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-group row">
                          <div className="col-lg" />
                          <div className="col-lg">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="arrayCheckBox6"
                              id="arrayCheckBox6"
                              value={this.state.arrayCheckBox[6]}
                              checked={this.state.arrayCheckBox[6] === "Y"}
                              onChange={(e) => {
                                this.onChangeControlCheckFusionConfiguration1(
                                  e,
                                  6
                                );
                              }}
                              data-test="checkbox-arrayCheckBox6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3" />
                  <div className="col-lg-6 mar-top5">
                    {this.state.submitStatusFusionConfiguration === "OK" && (
                      <div className="alert alert-success alert-padding notification text-center">
                        {this.state.submitMsgFusionConfiguration}
                      </div>
                    )}
                    {this.state.submitStatusFusionConfiguration === "Error" && (
                      <div className="alert alert-danger alert-padding notification">
                        {this.state.submitMsgFusionConfiguration}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3" />
                </div>
                <div className="form-group row">
                  <div className="col-lg-3" />
                  <div className="col-lg-6 text-center mar-bottom5">
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={this.onHandleRefreshFusionConfig}
                      data-test="button-refresh"
                    >
                      REFRESH
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={this.onHandleQCCMenuFusionConfig}
                      data-test="button-qccmenu"
                    >
                      QCC MENU
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={this.onHandleUpdateFusionConfig}
                      data-test="button-update"
                      disabled={
                        !this.props.location.state.data.accessGrantWriteQCDM
                      }
                    >
                      UPDATE
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={this.onHandleQCCClientFusionConfig}
                      data-test="button-qccclient"
                    >
                      QCC CLIENT
                    </button>
                  </div>
                  <div className="col-lg-3" />
                </div>
              </div>
              <div className="col-lg-2" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default FusionConfiguration;
