import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactQuickPay } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { getHeadersGSPS } from "../../../utility/utility";
import { AiTwotoneEdit as AiTwotoneEditQPB } from "react-icons/ai";
import { IconContext as IconContextQPB } from "react-icons";

class QuickPayBlock extends Component {
  state = {
    quickpayid: "",
    networlid: "",
    citycode: "",
    maxlimit: "",
    showQuickPayBlockConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() =>this.state.accessFlagQuickPayBlock && this.handleEditQuickPayBlock(params)}
            >
              <IconContextQPB.Provider
                value={{ color: this.state.accessFlagQuickPayBlock?"#FDD017":'#cec9b4', size: "20px" }}
              >
                <AiTwotoneEditQPB />
              </IconContextQPB.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      {
        field: "networlid",
        headerName: "Network ID",
        width: "297px",
      },
      {
        field: "citycode",
        headerName: "City Code",
        width: "299px",
      },
      {
        field: "maxlimit",
        headerName: "Max Limit",
        width: "295px",
      },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValQuickPay: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusQuickPayBlock: null,
    submitMsgQuickPayBlock: "",
    paginationOptionQuickPayBlock: [25, 50, 100, 500],
    overlayLoadingTemplateQuickPay:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateQuickPay: '<span style="">No Records Found</span>',
  };

  onChangeControlQuickPayBlock = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlQuickPayBlockCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9\s]/, ""),
    });
  };

  onChangeControlQuickPayBlockOnlyNumbers = (e) => {
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onGridReady = (params) => {
    let accessFlagQuickPayBlock = this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) || this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READWRITE);
    this.setState({accessFlagQuickPayBlock});
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/quickpaylimit/getquickpaylimitdetails`,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        let recQuickPay = [];
        if (response.data.records !== null) {
          recQuickPay = response.data.records;
        }
        this.setState({
          rows: recQuickPay,
        });
      });
  };
  handleEditQuickPayBlock = (params) => {
    let data = params.node.data;
    this.setState({
      quickpayid: data.networlid + "" + data.citycode,
      networlid: data.networlid,
      citycode: data.citycode,
      maxlimit: data.maxlimit,
      enableBtn: true,
    });
  };

  onPageSizeChangedQuickPayBlock = (e) => {
    this.setState({ paginationValQuickPay: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackQuickPayBlock = () => {
    this.props.history.push("/");
  };

  onValidateQuickPay = () => {
    if (this.state.networlid === "") {
      this.setState({
        submitStatusQuickPayBlock: "Error",
        submitMsgQuickPayBlock: "Please Enter The Value For Network ID",
      });
      return false;
    }
    if (this.state.citycode === "") {
      this.setState({
        submitStatusQuickPayBlock: "Error",
        submitMsgQuickPayBlock: "Please Enter The Value For City Code ",
      });
      return false;
    }
    if (this.state.maxlimit === "") {
      this.setState({
        submitStatusQuickPayBlock: "Error",
        submitMsgQuickPayBlock: "Please Enter The Value For Max Limit ",
      });
      return false;
    }
    if (this.state.maxlimit > 99999999.99) {
      this.setState({
        submitStatusQuickPayBlock: "Error",
        submitMsgQuickPayBlock: "Max Limit Cannot Be Greater than 99999999.99",
      });
      return false;
    }
    return true;
  };

  onHandleAddQuickPayBlock = () => {
    if (!this.onValidateQuickPay()) {
      return;
    }

    let submittedObj = {};
    submittedObj.networlid = this.state.networlid;
    submittedObj.citycode = this.state.citycode;
    submittedObj.maxlimit = this.state.maxlimit;
    submittedObj.createUser = "";
    submittedObj.modifyUser = "";
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/quickpaylimit/addquickpaylimitdata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusQuickPayBlock: "OK",
            submitMsgQuickPayBlock: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          this.onHandleClearQuickPayBlock();
        } else {
          this.setState({
            submitStatusQuickPayBlock: "Error",
            submitMsgQuickPayBlock: response.data.responseString,
          });
          this.onHandleClearQuickPayBlock();
        }
      });
  };

  onHandleUpdateQuickPayBlock = () => {
    if (!this.onValidateQuickPay()) {
      return;
    }
    let submittedObj = {};
    submittedObj.quickpayid = this.state.networlid + "" + this.state.citycode;
    submittedObj.networlid = this.state.networlid;
    submittedObj.citycode = this.state.citycode;
    submittedObj.maxlimit = this.state.maxlimit;
    submittedObj.createUser = "";
    submittedObj.modifyUser = "";
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/quickpaylimit/updatequickpaylimitdata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.networlid + "" + row.citycode === submittedObj.quickpayid
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusQuickPayBlock: "OK",
            submitMsgQuickPayBlock: response.data.responseString,
            rows: updatedRows,
          });
          this.onHandleClearQuickPayBlock();
        } else {
          this.setState({
            submitStatusQuickPayBlock: "Error",
            submitMsgQuickPayBlock: response.data.responseString,
          });
          this.onHandleClearQuickPayBlock();
        }
      });
  };

  onHandleClearQuickPayBlock = () => {
    this.setState({
      quickpayid: "",
      networlid: "",
      citycode: "",
      maxlimit: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusQuickPayBlock: null,
        }),
      5000
    );
  };

  getQuickPayBlockModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showQuickPayBlockConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete The Network Id {this.state.networlid} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showQuickPayBlockConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteQuickPayBlock}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteQuickPayBlock = () => {
    let submittedObj = {};
    submittedObj.networlid = this.state.networlid;
    submittedObj.citycode = this.state.citycode;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/quickpaylimit/deletequickpaylimitdata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return (
              row.networlid + "" + row.citycode !==
              submittedObj.networlid + "" + submittedObj.citycode
            );
          });

          this.setState({
            submitStatusQuickPayBlock: "OK",
            submitMsgQuickPayBlock: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearQuickPayBlock();
        } else {
          this.setState({
            submitStatusQuickPayBlock: "Error",
            submitMsgQuickPayBlock: response.data.responseString,
          });
          this.onHandleClearQuickPayBlock();
        }
      });
    this.setState({ showQuickPayBlockConfirm: false });
  };

  render() {
    return (
      <div className="container-fluid padding-left0">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title main-heading">Quick Pay Limit</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                Block Quick Pay
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="networlid" className="font-weight-bold">Network ID</label>
                      <input
                        type="text"
                        maxLength="8"
                        size="8"
                        value={this.state.networlid}
                        className="form-control form-control-sm"
                        name="networlid"
                        onChange={(e) => {
                          this.onChangeControlQuickPayBlockCharNumber(e);
                        }}
                        data-test="text-networlid"
                        placeholder="Network ID"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="citycode" className="font-weight-bold">City Code</label>
                      <input
                        type="text"
                        maxLength="24"
                        size="24"
                        value={this.state.citycode}
                        className="form-control form-control-sm"
                        name="citycode"
                        onChange={(e) => {
                          this.onChangeControlQuickPayBlockCharNumber(e);
                        }}
                        data-test="text-citycode"
                        placeholder="City Code"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="maxlimit" className="font-weight-bold">Max Limit</label>
                      <input
                        type="text"
                        maxLength="11"
                        size="11"
                        value={this.state.maxlimit}
                        className="form-control form-control-sm"
                        name="maxlimit"
                        onChange={(e) => {
                          this.onChangeControlQuickPayBlockOnlyNumbers(e);
                        }}
                        data-test="text-maxlimit"
                        placeholder="Max Limit"
                      />
                    </div>
                  </div>
                  <div className="form-group row margin-0">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackQuickPayBlock}
                        data-test="button-back"
                      >
                        GSP SYS
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddQuickPayBlock}
                        disabled={this.state.enableBtn || !this.state.accessFlagQuickPayBlock}
                        data-test="button-add"
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateQuickPayBlock}
                        disabled={!this.state.enableBtn}
                        data-test="button-update"
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearQuickPayBlock}
                        disabled={!this.state.enableBtn}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({ showQuickPayBlockConfirm: true });
                        }}
                        data-test="button-delete"
                        disabled={!this.state.enableBtn}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                        {this.state.submitStatusQuickPayBlock === "OK" && (
                          <div className="alert alert-success alert-padding notification">
                            {this.state.submitMsgQuickPayBlock}
                          </div>
                        )}
                        {this.state.submitStatusQuickPayBlock === "Error" && (
                          <div className="alert alert-danger alert-padding notification">
                            {this.state.submitMsgQuickPayBlock}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </form>
              </div>
            </div>
            {this.getQuickPayBlockModal()}
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                Blocked Quick Pay
              </div>
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label" className="font-weight-bold">
                    No of records per page
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlQuickPayBlock(e);
                      this.onPageSizeChangedQuickPayBlock(e);
                    }}
                    value={this.state.paginationValQuickPay}
                    name="paginationDropdownQuickPay"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionQuickPayBlock.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactQuickPay
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReady}
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateQuickPay
                    }
                    overlayNoRowsTemplate={
                      this.state.overlayNoRowsTemplateQuickPay
                    }
                  ></AgGridReactQuickPay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuickPayBlock;
