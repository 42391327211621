import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactQMQD } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditQMQD } from "react-icons/ai";
import { IconContext as IconContextQMQD } from "react-icons";
import { getHeadersOrder } from "../../utility/utility";

class QueueMaintenanceQueueDetails extends Component {
  state = {
    queueId: "",
    groupId: "",
    queueDesc: "",
    callBack: "",
    retrievalHist: "",
    releaseHist: "",
    retToQueue: "",
    willCall: "",
    direct: "",
    workFlow: "",
    mrunaWc: "",
    statusWc: "",
    mrunaDd: "",
    statusDd: "",
    count: "",
    showQueueMantenanceQueueDetailsConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {
                if(!this.props.location.state.accessGrantedWriteTMD3) { 
                this.setState({
                  submitStatusQueueMantenanceQueueDetails: 'Error',
                  submitMsgQueueMantenanceQueueDetails: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                });
              }else{
                this.handleEditQueueMantenanceQueueDetails(params)
              }  
          }}
            >
              <IconContextQMQD.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditQMQD />
              </IconContextQMQD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      { field: "queueId", headerName: "QUEUE", width: "100px", sortable: true, filter: true, floatingFilter: true },
      { field: "groupId", headerName: "GROUP", width: "100px", sortable: true, filter: true, floatingFilter: true },
      { field: "queueDesc", headerName: "DESCRIPTION", width: "160px", sortable: true, filter: true, floatingFilter: true },
      { field: "callBack", headerName: "CALL BACK", width: "100px" },
      { field: "retrievalHist", headerName: "RT", width: "60px" },
      { field: "releaseHist", headerName: "RL", width: "60px" },
      { field: "retToQueue", headerName: "RQ", width: "60px" },
      { field: "willCall", headerName: "WC", width: "60px" },
      { field: "direct", headerName: "DT", width: "60px" },
      { field: "workFlow", headerName: "WF", width: "60px" },
      { field: "mrunaWc", headerName: "RUNAWC", width: "95px" },
      { field: "statusWc", headerName: "STATWC", width: "80px" },
      { field: "mrunaDd", headerName: "RUNADD", width: "80px" },
      { field: "statusDd", headerName: "STATDD", width: "80px" },
      { field: "count", headerName: "COUNT", width: "80px" },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValQueue: "25",
    defaultColumnDef: {
      resizable: true,
      // flex: 1,
    },
    submitStatusQueueMantenanceQueueDetails: null,
    submitMsgQueueMantenanceQueueDetails: "",
    paginationOptionQueueMantenanceQueueDetails: [25, 50, 100, 500],
    editable: true,
    overlayLoadingTemplateQMQD:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateQMQD: '<span style="">No Records Found</span>',
  };

  onChangeControlQueueMantenanceQueueDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlQueueMantenanceQueueDetailsOnlyNumb = (e) => {
    let regex = /[^\d]/;
    if (!regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onGridReadyQueueMantenanceQueueDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/queuemaint`,{
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        // console.log(response);
        this.setState({
          rows: response.data.records,
        });
      });
  };

  handleEditQueueMantenanceQueueDetails = (params) => {
    let data = params.node.data;
    this.setState({
      queueId: data.queueId,
      queueDesc: data.queueDesc,
      groupId: data.groupId,
      callBack: data.callBack,
      retrievalHist: data.retrievalHist,
      releaseHist: data.releaseHist,
      retToQueue: data.retToQueue,
      willCall: data.willCall,
      direct: data.direct,
      workFlow: data.workFlow,
      mrunaWc: data.mrunaWc,
      statusWc: data.statusWc,
      mrunaDd: data.mrunaDd,
      statusDd: data.statusDd,
      count: data.count,
      enableBtn: true,
      editable: false
    });
  };

  onPageSizeChangedQueueMantenanceQueueDetails = (e) => {
    this.setState({ paginationValQueue: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackQueueMantenanceQueueDetails = () => {
    this.props.history.push({pathname:"/queue-maintenance",state:this.props.location.state});
  };

  onValidateQueueMantenanceQueueDetails = () => {
    if (this.state.queueId === "") {
      this.setState({
        submitStatusQueueMantenanceQueueDetails: "Error",
        submitMsgQueueMantenanceQueueDetails: "Please Enter The Value For Queue Id ",
      });
      return false;
    }
    if (this.state.queueDesc === "") {
      this.setState({
        submitStatusQueueMantenanceQueueDetails: "Error",
        submitMsgQueueMantenanceQueueDetails: "Please Enter The Value For Queue Description ",
      });
      return false;
    }
    if (this.state.callBack === this.state.queueId) {
      this.setState({
        submitStatusQueueMantenanceQueueDetails: "Error",
        submitMsgQueueMantenanceQueueDetails:
          "Callback Queue cannot be same as Queue ID ",
      });
      return false;
    }
    if (this.state.retrievalHist === "" || this.state.retrievalHist == 0) {
      this.setState({
        submitStatusQueueMantenanceQueueDetails: "Error",
        submitMsgQueueMantenanceQueueDetails:
          "Please Enter The Value For Retrival History, and should be greater than 0",
      });
      return false;
    }
    if (this.state.releaseHist === "" || this.state.releaseHist == 0) {
      this.setState({
        submitStatusQueueMantenanceQueueDetails: "Error",
        submitMsgQueueMantenanceQueueDetails:
          "Please Enter The Value For Release History, and should be greater than 0",
      });
      return false;
    }
    if (this.state.retToQueue === "" || this.state.retToQueue == 0) {
      this.setState({
        submitStatusQueueMantenanceQueueDetails: "Error",
        submitMsgQueueMantenanceQueueDetails:
          "Please Enter The Value For Return-to-queue, and should be greater than 0",
      });
      return false;
    }
    if (this.state.mrunaWc == 0 || this.state.mrunaWc > 29) {
      this.setState({
        submitStatusQueueMantenanceQueueDetails: "Error",
        submitMsgQueueMantenanceQueueDetails:
          "The MRUNA value should be greater than 0 and less than 29",
      });
      return false;
    }
    return true;
  };

  onHandleAddQueueMantenanceQueueDetails = () => {
    if (!this.onValidateQueueMantenanceQueueDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.queueId = this.state.queueId;
    submittedObj.queueDesc = this.state.queueDesc;
    submittedObj.groupId = this.state.groupId;
    submittedObj.callBack = this.state.callBack;
    submittedObj.retrievalHist = this.state.retrievalHist;
    submittedObj.releaseHist = this.state.releaseHist;
    submittedObj.retToQueue = this.state.retToQueue;
    submittedObj.willCall = this.state.willCall;
    submittedObj.direct = this.state.direct;
    submittedObj.workFlow = this.state.workFlow;
    submittedObj.mrunaWc = this.state.mrunaWc;
    submittedObj.statusWc = this.state.statusWc;
    submittedObj.mrunaDd = this.state.mrunaDd;
    submittedObj.statusDd = this.state.statusDd;
    submittedObj.count = this.state.count;
    // console.log(submittedObj);
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/queuemaint`, submittedObj,  {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        // console.log(response)
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.queueId = this.state.queueId;
          this.setState({
            submitStatusQueueMantenanceQueueDetails: "OK",
            submitMsgQueueMantenanceQueueDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusQueueMantenanceQueueDetails: "Error",
            submitMsgQueueMantenanceQueueDetails: response.data.responseString,
          });
        }
        this.onHandleClearQueueMantenanceQueueDetails();
      });
  };

  onHandleUpdateQueueMantenanceQueueDetails = () => {
    if (!this.onValidateQueueMantenanceQueueDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.queueId = this.state.queueId;
    submittedObj.queueDesc = this.state.queueDesc;
    submittedObj.groupId = this.state.groupId;
    submittedObj.callBack = this.state.callBack;
    submittedObj.retrievalHist = this.state.retrievalHist;
    submittedObj.releaseHist = this.state.releaseHist;
    submittedObj.retToQueue = this.state.retToQueue;
    submittedObj.willCall = this.state.willCall;
    submittedObj.direct = this.state.direct;
    submittedObj.workFlow = this.state.workFlow;
    submittedObj.mrunaWc = this.state.mrunaWc;
    submittedObj.statusWc = this.state.statusWc;
    submittedObj.mrunaDd = this.state.mrunaDd;
    submittedObj.statusDd = this.state.statusDd;
    submittedObj.count = this.state.count;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/queuemaint`, submittedObj, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.queueId === submittedObj.queueId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusQueueMantenanceQueueDetails: "OK",
            submitMsgQueueMantenanceQueueDetails: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusQueueMantenanceQueueDetails: "Error",
            submitMsgQueueMantenanceQueueDetails: response.data.responseString,
          });
        }
        this.onHandleClearQueueMantenanceQueueDetails();
      });
  };

  onHandleClearQueueMantenanceQueueDetails = () => {
    this.setState({
      id: "",
      queueId: "",
      groupId: "",
      queueDesc: "",
      callBack: "",
      retrievalHist: "",
      releaseHist: "",
      retToQueue: "",
      willCall: "",
      direct: "",
      workFlow: "",
      mrunaWc: "",
      statusWc: "",
      mrunaDd: "",
      statusDd: "",
      count: "",
      enableBtn: false,
      editable: true
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusQueueMantenanceQueueDetails: null,
        }),
      5000
    );
  };

  getQueueMantenanceQueueDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showQueueMantenanceQueueDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Queue Id {this.state.queueId} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showQueueMantenanceQueueDetailsConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteQueueMantenanceQueueDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteQueueMantenanceQueueDetails = () => {
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/deletequeue/${this.state.queueId}`, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.queueId !== this.state.queueId;
          });
          this.setState({
            submitStatusQueueMantenanceQueueDetails: "OK",
            submitMsgQueueMantenanceQueueDetails: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusQueueMantenanceQueueDetails: "Error",
            submitMsgQueueMantenanceQueueDetails: response.data.responseString,
          });
        }
        this.onHandleClearQueueMantenanceQueueDetails();
      });
    this.setState({ showQueueMantenanceQueueDetailsConfirm: false });
  };
  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="	WU TMON FILE MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                QUEUE DESCRIPTION DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  QUEUE DETAILS
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="queueId" className="font-weight-bold">Queue ID</label>
                        <input
                          type="text"
                          maxLength="8"
                          value={this.state.queueId}
                          className="form-control form-control-sm"
                          name="queueId"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetails(e);
                          }}
                          data-test="text-queueId"
                          placeholder="Queue ID"
                          disabled={!this.state.editable}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="queueDesc" className="font-weight-bold">Queue Desc</label>
                        <input
                          type="text"
                          maxLength="40"
                          value={this.state.queueDesc}
                          className="form-control form-control-sm"
                          name="queueDesc"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetails(e);
                          }}
                          data-test="text-queueDesc"
                          placeholder="Queue Desc"
                        />
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Group</label>
                        <select
                          className="form-control  form-control-sm"
                          name="groupId"
                          value={this.state.groupId}
                          onChange={
                            this.onChangeControlQueueMantenanceQueueDetails
                          }
                          data-test="text-groupId"
                        >
                          <option value=""> Select</option>
                          <option value="CUBA">
                            CUBA QUEUES (REMITTANCE, EMIGRATION)
                          </option>
                          <option value="FAST">FAST REVIEW QUEUES</option>
                          <option value="FASX">
                            INTERNATIONAL FAST REVIEW QUEUES
                          </option>
                          <option value="FRD">BANK FRAUD QUEUES</option>
                          <option value="GBS">GLOBAL BANK SERVICE</option>
                          <option value="HARP">TEST TIP LOGGING</option>
                          <option value="LPMT">LPMT QUEUES</option>
                          <option value="OFA2">
                            ADDITIONAL OFAC QUEUES (RCVR)
                          </option>
                          <option value="OFAC">
                            OFAC QUEUES (OFAC, CALLBACK, PFC)
                          </option>
                          <option value="OFAX">
                            INTERNATIONAL OFAC QUEUES
                          </option>
                          <option value="OKYC">OFAC KYC QUEUES</option>
                          <option value="ORVL">OV</option>
                          <option value="OSEZ">OFAC SEIZURE QUEUES</option>
                          <option value="OTHR">OTHER QUEUE</option>
                          <option value="SEIZ">SEIZURE QUEUES</option>
                          <option value="SPV">
                            SUPERVISOR QUEUES (SPV, OVL, ACR)
                          </option>
                          <option value="SPVX">
                            INTERNATIONAL SUPERVISOR QUEUES
                          </option>
                          <option value="TEST">TEST123</option>
                          <option value="VIGO">VIGO</option>
                          <option value="WEB">WEB GROUP</option>
                          <option value="WFLW">WORKFLOW MANAGEMENT</option>
                          <option value="CROO">
                            FRAUD PREVENTION -OVERLIMIT1
                          </option>
                        </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="callBack" className="font-weight-bold">Callback</label>
                        <input
                          type="text"
                          maxLength="8"
                          value={this.state.callBack}
                          className="form-control form-control-sm"
                          name="callBack"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetails(e);
                          }}
                          data-test="text-callBack"
                          placeholder="Callback"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="retrievalHist" className="font-weight-bold">Retrieval Hist</label>
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.retrievalHist}
                          className="form-control form-control-sm"
                          name="retrievalHist"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetailsOnlyNumb(
                              e
                            );
                          }}
                          data-test="text-retrievalHist"
                          placeholder="Retrieval Hist"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="releaseHist" className="font-weight-bold">Release Hist</label>
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.releaseHist}
                          className="form-control form-control-sm"
                          name="releaseHist"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetailsOnlyNumb(
                              e
                            );
                          }}
                          data-test="text-releaseHist"
                          placeholder="Release Hist"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="retToQueue" className="font-weight-bold">Ret-To-Queue</label>
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.retToQueue}
                          className="form-control form-control-sm"
                          name="retToQueue"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetailsOnlyNumb(
                              e
                            );
                          }}
                          data-test="text-retToQueue"
                          placeholder="Ret To Queue"
                        />
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Will Call</label>
                        <div className="row">
                          <div className="mar-right15" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="willCall"
                              value="Y"
                              data-test="text-willCall-Yes"
                              checked={this.state.willCall === "Y"}
                              onChange={(e) => {
                                this.onChangeControlQueueMantenanceQueueDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="willCall"
                              value="N"
                              data-test="text-willCall-No"
                              checked={this.state.willCall === "N"}
                              onChange={(e) => {
                                this.onChangeControlQueueMantenanceQueueDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Direct</label>
                        <div className="row">
                          <div className="mar-right15" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="direct"
                              value="Y"
                              data-test="text-direct-Yes"
                              checked={this.state.direct === "Y"}
                              onChange={(e) => {
                                this.onChangeControlQueueMantenanceQueueDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="direct"
                              value="N"
                              data-test="text-direct-No"
                              checked={this.state.direct === "N"}
                              onChange={(e) => {
                                this.onChangeControlQueueMantenanceQueueDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="workFlow" className="font-weight-bold"> WorkFlow </label>
                        <div className="row">
                          <div className="mar-right15" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="workFlow"
                              value="Y"
                              data-test="text-workFlow-Yes"
                              checked={this.state.workFlow === "Y"}
                              onChange={(e) => {
                                this.onChangeControlQueueMantenanceQueueDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="workFlow"
                              value="N"
                              data-test="text-workFlow-No"
                              checked={this.state.workFlow === "N"}
                              onChange={(e) => {
                                this.onChangeControlQueueMantenanceQueueDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="N">
                              No
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="workFlow"
                              value="Z"
                              data-test="text-workFlow-Z"
                              checked={this.state.workFlow === "Z"}
                              onChange={(e) => {
                                this.onChangeControlQueueMantenanceQueueDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="Z">
                              Z
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="workFlow"
                              value="6"
                              data-test="text-workFlow-6"
                              checked={this.state.workFlow === "6"}
                              onChange={(e) => {
                                this.onChangeControlQueueMantenanceQueueDetails(
                                  e
                                );
                              }}
                            />
                            <label className="form-check-label" htmlFor="6">
                              6
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="mrunaWc" className="font-weight-bold">MRUNA WC</label>
                        <input
                          type="text"
                          maxLength="2"
                          value={this.state.mrunaWc}
                          className="form-control form-control-sm"
                          name="mrunaWc"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetailsOnlyNumb(
                              e
                            );
                          }}
                          data-test="text-mrunaWc"
                          placeholder="MRUNA WC"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="statusWc" className="font-weight-bold">STATUS WC</label>
                        <input
                          type="text"
                          maxLength="4"
                          value={this.state.statusWc}
                          className="form-control form-control-sm"
                          name="statusWc"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetails(e);
                          }}
                          data-test="text-statusWc"
                          placeholder="STATUS WC"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="mrunaDd" className="font-weight-bold">MRUNA DD</label>
                        <input
                          type="text"
                          maxLength="2"
                          value={this.state.mrunaDd}
                          className="form-control form-control-sm"
                          name="mrunaDd"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetailsOnlyNumb(e);
                          }}
                          data-test="text-mrunaDd"
                          placeholder="MRUNA DD"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="statusDd" className="font-weight-bold">STATUS DD</label>
                        <input
                          type="text"
                          maxLength="4"
                          value={this.state.statusDd}
                          className="form-control form-control-sm"
                          name="statusDd"
                          onChange={(e) => {
                            this.onChangeControlQueueMantenanceQueueDetails(e);
                          }}
                          data-test="text-statusDd"
                          placeholder="STATUS DD"
                        />
                      </div>
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackQueueMantenanceQueueDetails}
                          data-test="button-back"
                        >
                          QUEUE MAINT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={
                            this.onHandleAddQueueMantenanceQueueDetails
                          }
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={
                            this.onHandleUpdateQueueMantenanceQueueDetails
                          }
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={
                            this.onHandleClearQueueMantenanceQueueDetails
                          }
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showQueueMantenanceQueueDetailsConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                          <div className="col-lg-1"></div>
                          <div className="col-lg-10">
                            {this.state.submitStatusQueueMantenanceQueueDetails ===
                              "OK" && (
                              <div className="alert alert-success alert-padding notification">
                                {this.state.submitMsgQueueMantenanceQueueDetails}
                              </div>
                            )}
                            {this.state.submitStatusQueueMantenanceQueueDetails ===
                              "Error" && (
                              <div className="alert alert-danger alert-padding notification">
                                {this.state.submitMsgQueueMantenanceQueueDetails}
                              </div>
                            )}
						              </div>
                          <div className="col-lg-1"></div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getQueueMantenanceQueueDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  QUEUE DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlQueueMantenanceQueueDetails(e);
                        this.onPageSizeChangedQueueMantenanceQueueDetails(e);
                      }}
                      value={this.state.paginationValQueue}
                      name="paginationDropdownQueue"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionQueueMantenanceQueueDetails.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactQMQD
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyQueueMantenanceQueueDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateQMQD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateQMQD
                      }
                    ></AgGridReactQMQD>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QueueMaintenanceQueueDetails;
