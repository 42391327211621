import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactName } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { getHeadersGSPS } from "../../../utility/utility";
import { AiTwotoneEdit as AiTwotoneEditName } from "react-icons/ai";
import { IconContext as IconContextName } from "react-icons";

class NameBlock extends Component {
  state = {
    name: "",
    showNameBlockConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() =>this.state.accessFlagNameBlock && this.handleEditNameBlock(params)} 
            >
              <IconContextName.Provider value={{ color: this.state.accessFlagNameBlock?"#FDD017":'#cec9b4', size: "20px" }}>
                <AiTwotoneEditName />
              </IconContextName.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      {
        field: "name",
        headerName: "Name",
        width: "880px",
      },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValName: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusNameBlock: null,
    submitMsgNameBlock: "",
    paginationOptionNameBlock: [25, 50, 100, 500],
    overlayLoadingTemplateName:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateName: '<span style="">No Records Found</span>',
  };

  onChangeControlNameBlock = (e) => {
    this.setState({
      //[e.target.name]: e.target.value.replace(/[^a-zA-Z\s]/, ""),
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlNameBlockPagination = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onGridReadyNameBlock = (params) => {
    let accessFlagNameBlock = this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) || this.props.accessRole.includes(process.env.REACT_APP_DB_VIEW_GSP_READWRITE);
    this.setState({accessFlagNameBlock});
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/nameblock/getnameblockdetails`,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        let recName = [];
        if (response.data.records !== null) {
          recName = response.data.records;
        }
        this.setState({
          rows: recName,
        });
      });
  };

  handleEditNameBlock = (params) => {
    let data = params.node.data;
    this.setState({
      name: data.name,
      enableBtn: true,
    });
  };

  onPageSizeChangedNameBlock = (e) => {
    this.setState({ paginationValName: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackNameBlock = () => {
    this.props.history.push("/");
  };

  onValidateNameBlock = () => {
    if (this.state.name === "") {
      this.setState({
        submitStatusNameBlock: "Error",
        submitMsgNameBlock: "Please Enter The Value For Name ",
      });
      return false;
    }
    return true;
  };

  onHandleAddNameBlock = () => {
    if (!this.onValidateNameBlock()) {
      return;
    }
    let submittedObj = {};
    submittedObj.name = this.state.name;
    submittedObj.createUser = "";
    submittedObj.modifyUser = "";
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/nameblock/addnameblockdata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.nameid = response.data.id;
          this.setState({
            submitStatusNameBlock: "OK",
            submitMsgNameBlock: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
          this.onHandleClearNameBlock();
        } else {
          this.setState({
            submitStatusNameBlock: "Error",
            submitMsgNameBlock: response.data.responseString,
          });
          this.onHandleClearNameBlock();
        }
      });
  };

  onHandleClearNameBlock = () => {
    this.setState({
      name: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusNameBlock: null,
        }),
      5000
    );
  };

  getNameBlockModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showNameBlockConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete The Name {this.state.name} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() => this.setState({ showNameBlockConfirm: false })}
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteNameBlock}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteNameBlock = () => {
    let submittedObj = {};
    submittedObj.name = this.state.name;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_GSPSYS_SERVICE_URL}/v1/dbv/gspsys/nameblock/deletenameblockdata`,
        submittedObj,
        {headers: {
          ...getHeadersGSPS().headers,
          Authorization: this.props.jwtToken? this.props.jwtToken: "",
        }}
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.name !== submittedObj.name;
          });

          this.setState({
            submitStatusNameBlock: "OK",
            submitMsgNameBlock: response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearNameBlock();
        } else {
          this.setState({
            submitStatusNameBlock: "Error",
            submitMsgNameBlock: response.data.responseString,
          });
          this.onHandleClearNameBlock();
        }
      });
    this.setState({ showNameBlockConfirm: false });
  };

  render() {
    return (
      <div className="container-fluid padding-left0">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title main-heading">NAME BLOCK TABLE</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                Block Name
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="name" className="font-weight-bold">Name</label>
                      <input
                        type="text"
                        maxLength="84"
                        size="84"
                        value={this.state.name}
                        className="form-control form-control-sm"
                        name="name"
                        onChange={(e) => {
                          this.onChangeControlNameBlock(e);
                        }}
                        data-test="text-name"
                        placeholder="Name"
                        disabled={this.state.enableBtn}
                      />
                    </div>
                  </div>
                  <div className="form-group row margin-0">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackNameBlock}
                        data-test="button-back"
                      >
                        GSP SYS
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddNameBlock}
                        disabled={this.state.enableBtn || !this.state.accessFlagNameBlock}
                        data-test="button-add"
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearNameBlock}
                        disabled={!this.state.enableBtn}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({ showNameBlockConfirm: true });
                        }}
                        data-test="button-delete"
                        disabled={!this.state.enableBtn}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                        {this.state.submitStatusNameBlock === "OK" && (
                          <div className="alert alert-success alert-padding notification">
                            {this.state.submitMsgNameBlock}
                          </div>
                        )}
                        {this.state.submitStatusNameBlock === "Error" && (
                          <div className="alert alert-danger alert-padding notification">
                            {this.state.submitMsgNameBlock}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </form>
              </div>
            </div>
            {this.getNameBlockModal()}
            <div className="card mar-top20">
              <div className="card-header bg-dark text-white card-header-custom">
                Blocked Name
              </div>
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label" className="font-weight-bold">
                    No of records per page
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlNameBlockPagination(e);
                      this.onPageSizeChangedNameBlock(e);
                    }}
                    value={this.state.paginationValName}
                    name="paginationDropdownName"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionNameBlock.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactName
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyNameBlock}
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={
                      this.state.overlayLoadingTemplateName
                    }
                    overlayNoRowsTemplate={
                      this.state.overlayNoRowsTemplateName
                    }
                  ></AgGridReactName>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NameBlock;
