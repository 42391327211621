import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactEDD  } from "ag-grid-react";
import ReactExport from "react-data-export";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditEDD } from "react-icons/ai";
import { IconContext as IconContextEDD } from "react-icons";
import { getHeadersShared2 } from "../../utility/utility";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ErrorDescriptionDetails extends Component {
  state = {
    errorDescriptionId: "",
    errorCode: "U",
    errorNum: "",
    sevLevel: "",
    errorText: "",
    showErrorDescriptionDetailsConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={
                () => {
                    if(!this.props.location.state.accessGrantedWriteTMD2) { 
                    this.setState({
                      submitStatusErrorDescriptionDetails: 'Error',
                      submitMsgErrorDescriptionDetails: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                    });
                  }else{
                    this.handleEditErrorDescriptionDetails(params)
                  }  
              }
            }
            >
              <IconContextEDD.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditEDD />
              </IconContextEDD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "120px",
      },
      { field: "errorCode", headerName: "ERROR CODE", width: "270px" },
      { field: "errorNum", headerName: "ERROR NUMBER", width: "270px" },
      { field: "sevLevel", headerName: "SEV LEVEL", width: "265px" },
      { field: "errorText", headerName: "ERROR TEXT", width: "300px" },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValError: "25",
    defaultColumnDef: { resizable: true, sortable: true, filter: true, floatingFilter: true },
    submitStatusErrorDescriptionDetails: null,
    submitMsgErrorDescriptionDetails: "",
    paginationOptionErrorDescriptionDetails: [25, 50, 100, 500],
    editable: true,
    overlayLoadingTemplateEDD:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateEDD: '<span style="">No Records Found</span>',
  };

  onChangeControlErrorDescriptionDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlErrorDescriptionDetailsOnlyNumber = (e) => {
    let regex = /[^\d]/;
    if (!regex.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onGridReadyErrorDescriptionDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/errorinfo`,  {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        //console.log(response);
        this.setState({
          rows: response.data.records,
        });
      });
  };

  handleEditErrorDescriptionDetails = (params) => {
    let data = params.node.data;
    this.setState({
      errorDescriptionId: data.errorCode+""+data.errorNum,
      errorCode: data.errorCode,
      errorNum: data.errorNum,
      sevLevel: data.sevLevel,
      errorText: data.errorText,
      enableBtn: true,
      editable: false
    });
  };

  onPageSizeChangedErrorDescriptionDetails = (e) => {
    this.setState({ paginationValError: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackErrorDescriptionDetails = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  onValidateErrorDescriptionDetails = () => {
    if (this.state.errorNum === "") {
      this.setState({
        submitStatusErrorDescriptionDetails: "Error",
        submitMsgErrorDescriptionDetails: "Please Enter The Value For Error Number",
      });
      return false;
    }
    if (this.state.sevLevel === "") {
      this.setState({
        submitStatusErrorDescriptionDetails: "Error",
        submitMsgErrorDescriptionDetails: "Please Enter The Value For Sev Level",
      });
      return false;
    }
    if (this.state.errorText === "") {
      this.setState({
        submitStatusErrorDescriptionDetails: "Error",
        submitMsgErrorDescriptionDetails: "Please Enter The Value For Error Text",
      });
      return false;
    }
    if (this.state.errorNum === "0") {
      this.setState({
        submitStatusErrorDescriptionDetails: "Error",
        submitMsgErrorDescriptionDetails: "Error Number Value is invalid,It should be greater than 0",
      });
      return false;
    }
    return true;
  };

  onHandleAddErrorDescriptionDetails = () => {
    if (!this.onValidateErrorDescriptionDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.errorDescriptionId = "";
    submittedObj.errorCode = this.state.errorCode;
    submittedObj.errorNum = this.state.errorNum;
    submittedObj.sevLevel = this.state.sevLevel;
    submittedObj.errorText = this.state.errorText;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/errorinfo`, submittedObj,  {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          submittedObj.errorDescriptionId = this.state.errorCode+""+this.state.errorNum;
          this.setState({
            submitStatusErrorDescriptionDetails: "OK",
            submitMsgErrorDescriptionDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusErrorDescriptionDetails: "Error",
            submitMsgErrorDescriptionDetails: response.data.responseString,
          });
        }
        this.onHandleClearErrorDescriptionDetails();
      });
  };

  onHandleUpdateErrorDescriptionDetails = () => {
    if (!this.onValidateErrorDescriptionDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.errorDescriptionId = this.state.errorDescriptionId;
    submittedObj.errorCode = this.state.errorCode;
    submittedObj.errorNum = this.state.errorNum;
    submittedObj.sevLevel = this.state.sevLevel;
    submittedObj.errorText = this.state.errorText;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/errorinfo`, submittedObj, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
     })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => (row.errorCode+""+row.errorNum) === submittedObj.errorDescriptionId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusErrorDescriptionDetails: "OK",
            submitMsgErrorDescriptionDetails: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusErrorDescriptionDetails: "Error",
            submitMsgErrorDescriptionDetails: response.data.responseString,
          });
        }
        this.onHandleClearErrorDescriptionDetails();
      });
  };

  onHandleClearErrorDescriptionDetails = () => {
    this.setState({
      errorDescriptionId: "",
      errorCode: "U",
      errorNum: "",
      sevLevel: "",
      errorText: "",
      enableBtn: false,
      editable: true
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusErrorDescriptionDetails: null,
        }),
      5000
    );
  };

  getErrorDescriptionDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showErrorDescriptionDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Error Code {this.state.errorCode} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({ showErrorDescriptionDetailsConfirm: false })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteErrorDescriptionDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteErrorDescriptionDetails = () => {
    let submittedObj = {};
    submittedObj.errorDescriptionId = this.state.errorCode+""+this.state.errorNum;
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/tmonfilemaintenance/deleteerrorinfo/${this.state.errorCode}/${this.state.errorNum}`, {
        headers: {
          ...getHeadersShared2().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return (row.errorCode+""+row.errorNum) !== submittedObj.errorDescriptionId;
          });

          this.setState({
            submitStatusErrorDescriptionDetails: "OK",
            submitMsgErrorDescriptionDetails: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusErrorDescriptionDetails: "Error",
            submitMsgErrorDescriptionDetails: response.data.responseString,
          });
        }
        this.onHandleClearErrorDescriptionDetails();
      });
    this.setState({ showErrorDescriptionDetailsConfirm: false });
  };
  getExcelEDDColumn = () => {
    let valECC = [
      { eddVal: "errorCode", eddLabel: "ERROR CODE" },
      { eddVal: "errorNum", eddLabel: "ERROR NUMBER"},
      { eddVal: "sevLevel", eddLabel: "SEV LEVEL"},
      { eddVal: "errorText", eddLabel: "ERROR TEXT" },
    ];
    return valECC.map((item,i) => (
      <ExcelColumn label={item.eddLabel} value={item.eddVal} key={i} />
    ));
  };
  onHandleEddReport = () => {
    this.excelrefEDDReport.handleDownload();
  }
  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WU TMON FILE MAINTENANCE"
        />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading">
                ERROR DESCRIPTION DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  ERROR DETAILS FORM
                </div>
                <div className="card-body card-body-custom">
                  <div className="form-group row">
                    <div className="col-lg">
                      <label className="font-weight-bold">Error Code</label>
                      <select
                        className="form-control  form-control-sm"
                        name="errorCode"
                        value={this.state.errorCode}
                        onChange={(e) => {
                          this.onChangeControlErrorDescriptionDetails(e);
                        }}
                        data-test="text-errorCode"
                        disabled={!this.state.editable}
                      >
                        <option value="U"> Unisys</option>
                        <option value="T">Tandem</option>
                        <option value="R">RTRA</option>
                      </select>
                    </div>
                    <div className="col-lg">
                      <label htmlFor="errorNum" className="font-weight-bold">Error Number</label>
                      <input
                        type="text"
                        maxLength="4"
                        value={this.state.errorNum}
                        className="form-control form-control-sm"
                        name="errorNum"
                        onChange={(e) => {
                          this.onChangeControlErrorDescriptionDetailsOnlyNumber(e);
                        }}
                        data-test="text-errorNum"
                        placeholder="Error Number"
                        disabled={!this.state.editable}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="sevLevel" className="font-weight-bold">Sev Level</label>
                      <input
                        type="text"
                        maxLength="1"
                        value={this.state.sevLevel}
                        className="form-control form-control-sm"
                        name="sevLevel"
                        onChange={(e) => {
                          this.onChangeControlErrorDescriptionDetailsOnlyNumber(e);
                        }}
                        data-test="text-sevLevel"
                        placeholder="Sev Level"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="errorText" className="font-weight-bold">Error Text</label>
                      <input
                        type="text"
                        maxLength="72"
                        value={this.state.errorText}
                        className="form-control form-control-sm"
                        name="errorText"
                        onChange={(e) => {
                          this.onChangeControlErrorDescriptionDetails(e);
                        }}
                        data-test="text-errorText"
                        placeholder="Error Text"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackErrorDescriptionDetails}
                        data-test="button-back"
                      >
                        TMON MAINT
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddErrorDescriptionDetails}
                        data-test="button-add"
                        disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateErrorDescriptionDetails}
                        data-test="button-update"
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleClearErrorDescriptionDetails}
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({
                            showErrorDescriptionDetailsConfirm: true,
                          });
                        }}
                        data-test="button-delete"
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD2}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                          {this.state.submitStatusErrorDescriptionDetails ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgErrorDescriptionDetails}
                            </div>
                          )}
                          {this.state.submitStatusErrorDescriptionDetails ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgErrorDescriptionDetails}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>    
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </div>
              </div>
              {this.getErrorDescriptionDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  ERROR DETAILS DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm mar-right10"
                      onChange={(e) => {
                        this.onChangeControlErrorDescriptionDetails(e);
                        this.onPageSizeChangedErrorDescriptionDetails(e);
                      }}
                      value={this.state.paginationValError}
                      name="paginationDropdownError"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionErrorDescriptionDetails.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                    <button
                      type="button"
                      className="btn btn-warning"
                      data-test="button-eddReport"
                      onClick={this.onHandleEddReport}
                    >
                      Excel
                  </button>    
                  </div>
                  
                  <ExcelFile
                      ref={(ref) => (this.excelrefEDDReport = ref)}
                      filename="EDDReport"
                      element={<></>}
                    >
                      <ExcelSheet
                        data={this.state.rows}
                        name="EDDReport"
                      >
                        {this.getExcelEDDColumn()}
                      </ExcelSheet>
                    </ExcelFile>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactEDD
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyErrorDescriptionDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateEDD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateEDD
                      }
                    ></AgGridReactEDD>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ErrorDescriptionDetails;
