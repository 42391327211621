import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersTipLog } from "../../utility/utility";

class AccountLogSearch extends Component {
  state = {
    accountNumberALS: "",
    startDateALS:"",
    endDateALS: "",
    pageRecordsALS: 50,
    pageNumberALS: 1,
    errorALS: false,
    errorMessageALS: "",
    isLoading: false,
  };

  onChangeControlTLS = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
 
  onChangeControlOnlyNumberALS = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^\d]/, ""),
    });
  };

  onChangeControlALSCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onValidateALS = () => {
    
    if (this.state.accountNumberALS === "") {
      this.setState({
        errorALS: true,
        errorMessageALS: "Enter Account Number",
      });
      return false;
    }

    if (this.state.startDateALS !== "" && this.state.endDateALS !=='' && new Date(this.state.startDateALS) > new Date(this.state.endDateALS)) {
      this.setState({
        errorALS: true,
        errorMessageALS: "End Date Must be greater or equal to start date ",
      });
      return false;
    }
    
    if (this.state.pageRecordsALS == "") {
      this.setState({
        errorALS: true,
        errorMessageALS: "Enter Page record to be displayed value",
      });
      return false;
    }
    
    return true;
  };

  onHandleSearchALS = () => {
    if (!this.onValidateALS()) {
      return;
    }
    let submittedObjALS = {};
    submittedObjALS.accountNumber = this.state.accountNumberALS;
    submittedObjALS.startDate = this.state.startDateALS;
    submittedObjALS.endDate = this.state.endDateALS;
    submittedObjALS.pageRecords = this.state.pageRecordsALS;
    submittedObjALS.pageNumber = this.state.pageNumberALS;

    // console.log(submittedObjALS);
    this.setState({
      isLoading: true
    });
    // console.log(this.props.userId);
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_TIPLOG_SERVICE_URL}/v1/sha/dbview/tiplog/Acclogsearch`,submittedObjALS, 
      {
        headers: {
          ...getHeadersTipLog().headers,
          Authorization: this.props.history.location.authorization,
          userId:this.props.history.location.userId
        },
      })
      .then((response) => {
        this.setState({
          isLoading: false
        });
        //  console.log(response);
        this.props.history.push({
          pathname: "/tip-log-account-details",
          records: response.data.records,
          submittedData: this.state,
          totalNoOfRecordsALS: response.data.noOfRecords,
          accessGrantedReadTL: this.props.history.location.accessGrantedReadTL,
          authorization: this.props.history.location.authorization,
          userId:this.props.history.location.userId
        });
      });
  };

  render() {
    // console.log(this.props.history.location.accessGrantedReadTL)
    return (
      <>
        <Header history={this.props.history} heading="" />
        <div className="container-fluid ">
          <div className="card-body account-log-search-container">
            <h4 className="card-title main-heading ">AS400 Account Log Search Screen</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                AS400 Account Log Search
              </div>
              <div className="card-body card-body-custom ">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="accountNumberALS" className="font-weight-bold">Account Number</label>
                      <input
                        type="text"
                        maxLength="10"
                        value={this.state.accountNumberALS}
                        className="form-control form-control-sm"
                        name="accountNumberALS"
                        onChange={(e) => {
                          this.onChangeControlALSCharNumber(e);
                        }}
                        data-test="text-accountNumberALS"
                        placeholder="Account Number"
                      />  
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="startDateALS" className="font-weight-bold">Start Date</label>
                      <input
                        type="date"
                        value={this.state.startDateALS}
                        className="form-control form-control-sm"
                        name="startDateALS"
                        onChange={(e) => {
                          this.onChangeControlTLS(e);
                        }}
                        data-test="text-startDateALS"
                        placeholder="Start Date"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="endDateALS" className="font-weight-bold">End Date</label>
                      <input
                        type="date"
                        value={this.state.endDateALS}
                        className="form-control form-control-sm"
                        name="endDateALS"
                        onChange={(e) => {
                          this.onChangeControlTLS(e);
                        }}
                        data-test="text-endDateALS"
                        placeholder="End Date"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    
                    <div className="col-lg-6">
                      <label htmlFor="pageRecordsALS" className="font-weight-bold">Page Records</label>
                      <input
                        type="text"
                        maxLength="4"
                        value={this.state.pageRecordsALS}
                        className="form-control form-control-sm"
                        name="pageRecordsALS"
                        onChange={(e) => {
                          this.onChangeControlOnlyNumberALS(e);
                        }}
                        data-test="text-pageRecordsALS"
                      />
                    </div>   
                  </div>
                  <div className="form-group row mar-top20 ">
                    <div className="col-lg-5" />
                    <div className="col-lg-2 text-center">
                      {this.state.isLoading ? 
                        <button className="btn btn-warning mar-right5" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          &nbsp;&nbsp;Loading...
                        </button> :
                        !this.props.history.location.accessGrantedReadTL ?
                        (
                          <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          disabled
                          data-test="button-search"
                        >
                          Search
                        </button>
                        )
                        :
                         (<button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleSearchALS}
                          data-test="button-search"
                        >
                          Search
                        </button>)
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      {this.state.errorALS && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.errorMessageALS}
                          </div>
                        )} 
                    </div>
                  </div> 
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccountLogSearch;
