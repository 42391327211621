import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../header/header";

export default class MaintenanceMenuDetails extends Component {
  state = {
    disableLink: null
  }
  componentDidMount() {
    this.setState({ disableLink: process.env.REACT_APP_DB_VIEW_DISABLE_LINK });
  }
  render() {
    return (
      <div data-test="component-maintenance-menu-details">
        <Header
          changeComponent={this.changeComponent}
          history={this.props.history}
          heading="DB View"
        />
        <div className="container-fluid">
          <div className="card maintenance-menu-card">
            <div className="card-body maintenance-menu-box">
              <div className="row">
                <div className="col-lg">
                  <Link to="./wu-system-monitor" className="btn btn-warning btn-block">WU SYSTEM MONITOR </Link>
                </div>
                <div className="col-lg">
                  <Link to="/tmon-maintenance-details" className="btn btn-warning btn-block">WU TMON FILE MAINTENANCE</Link>
                </div>
                <div className="col-lg">
                  <Link to="/quick-collect-database-maintenance" className="btn btn-warning btn-block">QUICK COLLECT UPDATE</Link>
                </div>
                <div className="col-lg">
                  <Link to="/partner-maintenance" className="btn btn-warning btn-block">PARTNER MAINTENANCE</Link>
                </div>
              </div>
              <div className="row mar-top20">
                <div className="col-lg">
                  <Link to="/country-maintenance" className="btn btn-warning btn-block">COUNTRY MAINTENANCE</Link>
                </div>
                <div className="col-lg">
                  <Link to="./web-tmt-db-maintenance" className="btn btn-warning btn-block">WEB/TMT DB MAINTENANCE</Link>
                </div>
                <div className="col-lg">
                  <Link to="/queue-age-monitor" className="btn btn-warning btn-block">QUEUE AGE MONITOR</Link>
                </div>
                <div className="col-lg">
                  <Link to="/money-transfer-database-utility" className="btn btn-warning btn-block">MONEY TRANSFER UTILITY</Link>
                </div>
              </div>
              <div className="row mar-top20">
                <div className="col-lg">
                  <Link to="/refile-account-rules-update" className="btn btn-warning btn-block">REFILE ACCOUNT RULES UPDATE</Link>
                </div>
                <div className="col-lg">
                  <Link to="/business-rules-maintenance" className="btn btn-warning btn-block">BUSINESS RULES MAINTENANCE</Link>
                </div>
                <div className="col-lg">
                  <Link to="/check-control-utility" className="btn btn-warning btn-block">CHECK CONTROL UTILITY</Link>
                </div>
                <div className="col-lg">
                  <Link to="/fraud-prevention-filter" className="btn btn-warning btn-block">FRAUD PREVENTION FILTER</Link>
                </div>
              </div>
              <div className="row mar-top20">
                <div className="col-lg">
                  <Link to="/tip-log-search" className="btn btn-warning btn-block">DISPLAY TIP LOG</Link>
                </div>
                <div className="col-lg">
                  <Link to="/partner-fraud-prevention" className="btn btn-warning btn-block">PARTNER FRAUD PREVENTION</Link>
                </div>
                <div className="col-lg">
                  <Link to="/df-receipt-search" className="btn btn-warning btn-block">DF RECEIPT DETAILS</Link>
                </div>
                <div className="col-lg">
                  <Link to="/directed-maintenance-details" className="btn btn-warning btn-block">DIRECTED MANAGEMENT</Link>
                </div>
              </div>
              <div className="row mar-top20">
                <div className="col-lg-3">
                  <Link to="/gsp-table-sys" className="btn btn-warning btn-block">GLOBAL SYSTEM PARAMETER</Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/nrt-agent-network-maintenance" className="btn btn-warning btn-block">NRT AGENT NETWORK</Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/fx-discount-search" className="btn btn-warning btn-block">F/X VALID DB MAINTENANCE</Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/config-management-query-executor" className="btn btn-warning btn-block">CONFIG MANAGEMENT</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
