import React from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersOrder } from "../../utility/utility";

class MoneyTransferAuthQueueRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aaSkKeys: [],
      noData: null,
      noDataMsg: "",
      isLoading:true
    };
  }

  componentDidMount = () => {
    this.setState({
      mtcn: this.props.history.location.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
    let submittedObj = {};
    submittedObj.mtcn = this.props.history.location.mtcn;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/queue`,
        submittedObj,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.history.location.jwtToken
          },
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.data.flag === 1) {
          this.setState({
            //mtcn: this.props.history.location.mtcn,
            aaMtcn: response.data.mtcn,
            aaoId: response.data.queueName,
            noData: false,
            noDataMsg: "",
            isLoading:false
            //aaSkKeys: response.data.aaSkKeys,
          });
        } else {
          this.setState({
            noData: true,
            noDataMsg: response.data.message,
            isLoading:false
          });
        }
      });
  };

  onHandleBackQueueMTCNDetails = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackQueueMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  render() {
    //console.log(this.state.noData);
    return (
      <>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <h4 className="card-title main-heading">
            Money Transfer Auth Queue Records
          </h4>
          <div className="card-body card-body-custom">
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-5" />
                  <span className="font-weight-bold">MTCN : </span>{this.state.mtcn}
                  <div className="col-md-7" />
                </div>
              </div>
              <div className="col-md-7" />
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-lg-3" />
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Queue Details
                    </div>
                    <div className="card-body card-body-custom">
                     
                        {
                         this.state.isLoading ? 
                         <div className="text-center">
                           <div className="spinner-border text-warning " role="status">
                             <span className="sr-only">Loading...</span>
                           </div>
                         </div>
                         :      
                          this.state.noData === false ? (
                            <div className="row">
                              <div className="col-md-3" />
                              <div className="col-md-4">
                              <span className="font-weight-bold">AAMTCN :</span> {this.state.aaMtcn}
                              </div>
                              <div className="col-md-3">
                              <span className="font-weight-bold">AAOID :</span> {this.state.aaoId}
                              </div>
                              <div className="col-md-2" />
                            </div>
                          ) : (
                            <div className="row"><div className="col-md text-center notification">{this.state.noDataMsg}</div></div>
                          )
                        }
                        {/* <div className="row">
                        <div className="col-md-3" />
                        <div className="col-md-3">AASKKEYS:</div>
                        <div className="col-md-6" />
                      </div>
                      <div className="row">
                        <div className="col-md-3" />
                        <div className="col-md-3">
                          {this.state.aaSkKeys.map((val, i) => {
                            return (
                              <div>
                                AASK {i + 1}: {val}
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-md-6" />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-3" />
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-warning mar-right10"
                onClick={this.onHandleBackQueueMTCNDetails}
                data-test="QueueMTCNDetails-button-back"
              >
                MTCN Details
              </button>
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.onHandleBackQueueMTCNSearch}
                data-test="QueueMTCNDetails-Search-button-back"
              >
                SEARCH
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MoneyTransferAuthQueueRecord;
