import React from "react";
import { connect } from "react-redux";
import Header from "../../header/header";
import { doSearchMtcn } from "../../../actions/mtcn-search/mtcn-search-action";

class MoneyTransferDatabaseRecordDisplayUtility extends React.Component {
  state = {
    mtcn: "",
    account: "",
    payingAgentAccount: "",
    payeeName: "",
    //unPaidPayee: "",
    senderName: "",
    //unPaidSender: "",
    callingPhone: "",
    city: "",
    errorMTDRDU: false,
    errorMessageMTDRDU: ""
    //unPaidCallingPhone: "",
    //genSkRec: "",
  };

  componentDidMount() {
    this.setState({
      mtcn: this.props.searchedData.mtcn,
      controlNumber: this.props.searchedData.mtcn.toString().substr(-10),
      //controlNumber: this.props.searchedData.mtcn.substring(this.props.searchedData.mtcn.length - 10),
      account: this.props.searchedData.sendingAgent.cacct ? this.props.searchedData.sendingAgent.cacct.trim() : null,
      payingAgentAccount: this.props.searchedData.countryAndPayAgent.aaccount ? this.props.searchedData.countryAndPayAgent.aaccount.trim() : null,
      payeeName: this.props.searchedData.payee.receiver ? this.props.searchedData.payee.receiver.trim() : null,
      senderName: this.props.searchedData.sender.name ? this.props.searchedData.sender.name.trim() : null,
      callingPhone: this.props.searchedData.sender.phone ? this.props.searchedData.sender.phone.trim() : null,
      city:  this.props.searchedData.payee.addr1 ? this.props.searchedData.payee.addr1.trim() : null,
      jwtToken: this.props.history.location.jwtToken
    });
  
  }

  onHandleRecordingAgent = (acct) => {
    let submittedObj = {};
    submittedObj.searchKeyValue = acct;
    // console.log(acct)
    this.props.doSearchMtcn(submittedObj, "agentAccountNumber", this.state.jwtToken).then(() => {
      if(parseInt(this.props.searchedData.agentFlag) === 1) {
        this.props.history.push({
          pathname: "/money-transfer-database-agent-record",
          mtcn: this.state.mtcn,
          jwtToken: this.state.jwtToken
        });
      } else {
        this.setState({
          errorMTDRDU: true,
          errorMessageMTDRDU: this.props.searchedData.message,
        });
      }
    });
  };
  // onHandlePayingAgent = () => {
  //   this.props.history.push({
  //     pathname: "/money-transfer-database-agent-record",
  //     mtcn: this.state.mtcn,
  //     account: this.state.account
  //   });
  // };

  onHandleBackMTCNRecordDisplayUtility = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackSerachMTCNRecordDisplayUtility = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  onHandleRDBMSSearchKeyDetails = () => {
    this.props.history.push({
      pathname: "/money-transfer-rdbms-search-key-details",
      mtcn: this.state.mtcn,
      jwtToken: this.state.jwtToken
    });
  }

  onHandleSearchKey = (searchKeyType,searchKeyVal) => {
    //console.log(searchKeyType,searchKeyVal);
    this.props.history.push({
      pathname: "/money-transfer-search-key",
      searchKey: searchKeyType,
      searchKeyValue: searchKeyVal,
      jwtToken: this.state.jwtToken,
      mtcn: this.state.mtcn
    });
     //Adding session storage
     //if (typeof(Storage) !== "undefined") {
      sessionStorage.searchKey = searchKeyType;
      sessionStorage.searchKeyValue = searchKeyVal;
    //}
  }

  render() {

    return (
      <>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <h4 className="card-title main-heading">
            Money Transfer Database Record Display Utility
          </h4>
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="card maintenance-menu-box">
                <div className="row">

                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 mtcn-record-display">
                    <span className="font-weight-bold">
                      THE FOLLOWING FUNCTIONS WILL ONLY WORK FROM THIS SCREEN
                    </span>
                    <span className="font-weight-bold">
                      THE FETCHES WILL OPERATE USING THE DATA IN THE CURRENT
                      MONEY ORDER RECORD
                    </span>
                    <span><strong>MTCN : </strong>{this.state.mtcn}</span>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="card-body">
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={() => this.onHandleRecordingAgent(this.state.account)}
                        disabled={!this.state.account}
                        data-test='button-recording-agent'
                      >
                        RECORDING AGENT
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        //onClick={this.onHandlePayingAgent}
                        onClick={() => this.onHandleRecordingAgent(this.state.payingAgentAccount)}
                        disabled={!this.state.payingAgentAccount}
                        data-test='button-paying-agent'
                      >
                       PAYING AGENT
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                    <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('controlNumber', this.state.controlNumber)}
                        //onClick={()=>this.onHandleSearchKey('controlNumber',this.state.mtcn)}
                        data-test='button-search-key-ctrlno'
                      >
                        SEARCH KEY - CONTROL NUMBER (CN)
                      </button>
                    </div>
                    <div className="col-lg-4">
                    <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('payeeName',this.state.payeeName)}
                        disabled={!this.state.payeeName}
                        data-test='button-search-key-pn'
                      >
                        SEARCH KEY - PAYEE NAME (PN)
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                    <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('senderName',this.state.senderName)}
                        disabled={!this.state.senderName}
                        data-test='button-search-key-senderName'
                      >
                        SEARCH KEY - SENDER NAME (SN)
                      </button>
                    </div>
                    <div className="col-lg-4">
                    <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('callingPhone',this.state.callingPhone)}
                        disabled={!this.state.callingPhone}
                        data-test='button-search-key-callingPhone'
                      >
                        SEARCH KEY - CALLING PHONE (CF)
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                    <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={this.onHandleRDBMSSearchKeyDetails}
                        data-test='button-search-key-rdbms'
                       // disabled={this.state.callingPhone === "undefined"}
                      >
                        SEARCH KEY - RDMS DETAILS
                      </button>
                    </div>
                    <div className="col-lg-4">
                    <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={this.onHandleBackMTCNRecordDisplayUtility}
                        data-test='button-previous-screen'
                      >
                        RETURN TO PREVIOUS SCREEN
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  {/* <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('accountId',this.state.account)}
                        // disabled={!this.state.account}
                        data-test='button-search-key-actid'
                        disabled={true}
                      >
                        SEARCH KEY - ACCOUNT ID (ID)
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('controlNumber', this.state.controlNumber)}
                        //onClick={()=>this.onHandleSearchKey('controlNumber',this.state.mtcn)}
                        data-test='button-search-key-ctrlno'
                      >
                        SEARCH KEY - CONTROL NUMBER (CN)
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('payeeName',this.state.payeeName)}
                        disabled={!this.state.payeeName}
                        data-test='button-search-key-pn'
                      >
                        SEARCH KEY - PAYEE NAME (PN)
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('unpaidPayee',this.state.payeeName)}
                        disabled={!this.state.payeeName}
                        data-test='button-search-key-up'
                      >
                       SEARCH KEY - UNPAID PAYEE (UP)
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('senderName',this.state.senderName)}
                        disabled={!this.state.senderName}
                        data-test='button-search-key-senderName'
                      >
                        SEARCH KEY - SENDER NAME (SN)
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('unpaidSender',this.state.senderName)}
                        disabled={!this.state.senderName}
                        data-test='button-search-key-unpaidSender'
                      >
                        SEARCH KEY - UNPAID SENDER (US)
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('callingPhone',this.state.callingPhone)}
                        disabled={!this.state.callingPhone}
                        data-test='button-search-key-callingPhone'
                      >
                        SEARCH KEY - CALLING PHONE (CF)
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={()=>this.onHandleSearchKey('unpaidCallingPhone',this.state.callingPhone)}
                        disabled={!this.state.callingPhone}
                        data-test='button-search-key-unPaidCallingPhone'
                      >
                        SEARCH KEY - UNPAID CALLING PHONE (UF)
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        disabled={true}
                      >
                        SEARCH KEY - GENSKREC (GEN)
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={this.onHandleRDBMSSearchKeyDetails}
                        data-test='button-search-key-rdbms'
                       // disabled={this.state.callingPhone === "undefined"}
                      >
                        SEARCH KEY - RDMS DETAILS
                      </button>
                    </div>
                    <div className="col-lg-2" />
                  </div> */}
                  <div className="row mar-top20">
                    <div className="col-lg-2" />
                    <div className="col-lg-4">
                    <button
                        type="button"
                        className="btn btn-warning btn-block"
                        onClick={this.onHandleBackSerachMTCNRecordDisplayUtility}
                        data-test='button-search-screen'
                      >
                          SEARCH SCREEN
                      </button>
                    </div>
                    <div className="col-lg-4">
                      &nbsp;
                    </div>
                    <div className="col-lg-2" />
                  </div>
                  <div className="row mar-top20">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 text-center">
                    {this.state.errorMTDRDU && (
                      <div className="alert alert-danger alert-padding search-key-error">
                        {this.state.errorMessageMTDRDU}
                      </div>
                    )}
                    </div>
                    <div className="col-lg-2"></div>
                     
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ MTCNSearchReducer }) => {

  return {
    searchedData: MTCNSearchReducer.dataInfo,
  };
};

export default connect(mapStateToProps, { doSearchMtcn })(MoneyTransferDatabaseRecordDisplayUtility);
