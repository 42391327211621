import React, { Component } from "react";
import { AgGridReact as AgGridReactMtcnText } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import axios from "axios";
import { getHeadersOrder } from "../../utility/utility";

class MoneyTransferTextRecord extends Component {
  state = {
    
    columns: [
      
      {
        field: "noOfTextBlock",
        headerName: "No Of Text Blocks",
        width: 250,
      },
      // {
      //   field: "seqNum",
      //   headerName: "Sequence Number",
      //   width: 250,
      // },
      {
        field: "textDetails",
        headerName: "Text Details",
        width: 1000,
        wrapText: true,  
        autoHeight: true, 
      },
    ],
    rows: null,
    paginationValTextMTCN: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      //flex: 1,
    },
    paginationOptionTextMTCN: [25, 50, 100, 500],
    overlayLoadingTemplateText:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateText:
    '<span style="">No Records Found</span>',
  };

  onPageSizeChangedTextMTCN = (e) => {
    this.setState({ paginationValTextMTCN: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  componentDidMount() {
    this.setState({
      mtcn: this.props.history.location.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
  }

  onChangeControlTextMTCN = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onGridReadyTextMTCN = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    let submittedObj = {};
    submittedObj.mtcn = this.state.mtcn;

    axios.post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/text`,submittedObj,
    {
      headers: {
        ...getHeadersOrder().headers,
        Authorization: this.state.jwtToken
      },
    }).then((response) => {
      if(response.data.flag === 1) {
        this.setState({
          rows: response.data.records
        });
      } else {
        this.setState({
          rows:[],
          overlayNoRowsTemplateText:`<span style="">${response.data.message}</span>`
        });
      }
    });
  };

  onHandleBackTextMTCNDetails = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackTextMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  render() {
    return (
      <div>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <div className="card-body">
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                Money Transfer Text Record
              </div>
              <div className="card-body card-body-custom">
                <div className="form-row">
                  <div className="col-lg-2">
                    <label htmlFor="mtcn"><span className="font-weight-bold">MTCN : </span>{this.state.mtcn}</label>
                  </div>
                  <div className="col-lg-10" />
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="pagination-dropdown float-left">
                      <label htmlFor="pagination-select-label" className="font-weight-bold">
                        No of records per page:
                      </label>
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          this.onChangeControlTextMTCN(e);
                          this.onPageSizeChangedTextMTCN(e);
                        }}
                        value={this.state.paginationValTextMTCN}
                        name="paginationDropdownTextMTCN"
                        data-test="select-dropdown"
                      >
                        {this.state.paginationOptionTextMTCN.map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-9" />      
                </div>
                <div className="d-flex justify-content-center ">
                  <div
                    className="ag-theme-balham "
                    style={{ height: 290, width: "100%" }}
                  >
                    <AgGridReactMtcnText
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyTextMTCN}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplateText}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplateText}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                    ></AgGridReactMtcnText>
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button
                    type="button"
                    className="btn btn-warning mr-2"
                    onClick={this.onHandleBackTextMTCNDetails}
                    data-test='MTCNDetails-button-back'
                  >
                    MTCN Details
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackTextMTCNSearch}
                    data-test='Search-button-back'
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MoneyTransferTextRecord;
