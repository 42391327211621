import React, { Component } from "react";
import { connect } from "react-redux";
import { doSearchMtcn } from "../../actions/mtcn-search/mtcn-search-action";
import { getHeadersOrder } from "../utility/utility";
import axios from "axios";
import Header from "../header/header";

class DoddFrankReceiptDetails extends Component {
  state = {
    mtcn: "",
    displayData: {},
    error: false,
    errorMessage: "",
    isLoading:true
  };

  componentDidMount() {
    if (this.props.history.location.mtcn) {
      let submittedObject = {};
      submittedObject.mtcn = this.props.history.location.mtcn;
      submittedObject.dfReceiptSourceType = this.props.history.location.dfReceiptSourceType;
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/dfdetails`,
          submittedObject,
          {
            headers: {
              ...getHeadersOrder().headers,
              Authorization: this.props.history.location.jwtToken
            },
          }
        )
        .then((response) => {
          this.setState({
            mtcn: response.data.mtcn,
            displayData: response.data,
            searchKey: this.props.history.location.searchKey,
            isLoading: false
          });
        });
    }
  }

  onHandleNewDDFRSearch = () => {
    this.props.history.push({
      pathname: "/df-receipt-search",
      mtcn: "",
    });
  };

  onHandlePreviousDDFRSerach = () => {
    this.props.history.push({
      pathname: "/df-receipt-search",
      mtcn: this.state.mtcn,
      searchKey: this.state.searchKey
    });
  };

  onHandleMTCNData = () => {
    // this.props.doSearchMtcn(this.state.mtcn).then(()=> {
    //   this.props.history.push({
    //     pathname: '/mt-database-money-transfer-record',
    //   });
    // });
    let submittedObj = {};
    submittedObj.mtcn = this.state.mtcn;
    this.props.doSearchMtcn(submittedObj, "mtcn",  this.props.history.location.jwtToken).then(() => {
      //console.log(this.props.searchedData);
      if (this.props.searchedData.mtcnFlag === 1) {
        this.props.history.push({
          pathname: "/mt-database-money-transfer-record",
          jwtToken:  this.props.history.location.jwtToken
        });
      } else {
        this.setState({
          error: true,
          errorMessage: "Invalid MTCN",
        });
      }
      this.onClearMessageDFDTDetails();
    });
  };

  onClearMessageDFDTDetails = () => {
    setTimeout(
      () =>
        this.setState({
          error: false,
          errorMessage: "",
        }),
      5000
    );
  };

  render() {
    //console.log(this.state.displayData.payee);
    return (
      <>
        <Header
          history={this.props.history}
          heading="DODD FRANK RECEIPT DETAILS"
        />
        {
        this.state.isLoading ? 
        <div className="text-center mar-top20">
          <div className="spinner-border text-warning " role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        :    
        Object.keys(this.state.displayData).length !== 0 && (
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                DODD FRANK RECEIPT DETAILS
              </h4>
              <div className="row margin-15">
                <div className="col-lg">
                  <span className="font-weight-bold">MTCN : </span>
                  {this.state.mtcn}
                </div>
                <div className="col-lg">
                  <span className="font-weight-bold">Receipt Type : </span>
                  {this.state.displayData!==null && this.state.displayData.dfr_rcpt_src_type!==null ? this.state.displayData.dfr_rcpt_src_type :""}
                </div>
                <div className="col-lg">
                  <span className="font-weight-bold">Src Txn : </span>
                  {this.state.displayData!==null && this.state.displayData.dfr_src_txn!==null ? this.state.displayData.dfr_src_txn :""}
                </div>
                <div className="col-lg">
                  <span className="font-weight-bold">Bill Type : </span>
                  {this.state.displayData!==null && this.state.displayData.dfr_billtype!==null ? this.state.displayData.dfr_billtype :""}
                </div>
                <div className="col-lg">
                  <span className="font-weight-bold">DlvySvc : </span>
                  {this.state.displayData!==null && this.state.displayData.dfr_dlvysvc!==null ? this.state.displayData.dfr_dlvysvc :""}
                </div>
                {/* <div className="col-lg">
                  <strong>Key Seq:</strong>
                  {this.state.displayData.dfr_encrypt_key_seq}
                </div> */}
              </div>
              <div className="row mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Payee Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Receiver : </span>
                          {this.state.displayData.payee!==null && this.state.displayData.payee.dfr_mpayee !==null ? this.state.displayData.payee.dfr_mpayee : ""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Soundex : </span>
                          {this.state.displayData.payee!==null && this.state.displayData.payee.dfr_payee_soundex !==null ?this.state.displayData.payee.dfr_payee_soundex :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Address : </span>
                          {this.state.displayData.payee!==null && this.state.displayData.payee.address!==null ?this.state.displayData.payee.address :"" }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">City : </span>
                          {this.state.displayData.payee!==null && this.state.displayData.payee.city!==null ? this.state.displayData.payee.city : ""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">State : </span>
                          {this.state.displayData.payee!==null && this.state.displayData.payee.state!==null ? this.state.displayData.payee.state :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Zip : </span>
                          {this.state.displayData.payee!==null && this.state.displayData.payee.zip!==null ? this.state.displayData.payee.zip :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Country : </span>
                          {this.state.displayData.payee!==null && this.state.displayData.payee.country!==null ? this.state.displayData.payee.country :""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Sender Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Sender : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.dfr_msndr!==null ? this.state.displayData.sender.dfr_msndr :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Soundex : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.dfr_sndr_soundex ? this.state.displayData.sender.dfr_sndr_soundex :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Address : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.address!==null ? this.state.displayData.sender.address :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">City : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.city!==null ? this.state.displayData.sender.city :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">State : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.state!==null ? this.state.displayData.sender.state :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Zip : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.zip!==null ? this.state.displayData.sender.zip :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Country : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.country!==null ? this.state.displayData.sender.country :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Phone : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.dfr_msphone!==null ? this.state.displayData.sender.dfr_msphone :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Email : </span>
                          {this.state.displayData.sender!==null && this.state.displayData.sender.dfr_sndr_email!==null ? this.state.displayData.sender.dfr_sndr_email :""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Sending Agent Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Company : </span>
                          {this.state.displayData.sendingAgent!==null && this.state.displayData.sendingAgent.dfr_aaname!==null ? this.state.displayData.sendingAgent.dfr_aaname :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Account : </span>
                          {this.state.displayData.sendingAgent!==null && this.state.displayData.sendingAgent.dfr_mcaccount!==null ? this.state.displayData.sendingAgent.dfr_mcaccount :""}
                      </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Phone : </span>
                          {this.state.displayData.sendingAgent!==null && this.state.displayData.sendingAgent.dfr_agt_phone!==null ? this.state.displayData.sendingAgent.dfr_agt_phone :""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Recording &amp; Paying Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Oper Id : </span>
                          {
                            this.state.displayData.recordingAndPayingDTO
                            !==null && this.state.displayData.recordingAndPayingDTO
                              .dfr_opid!==null ? this.state.displayData.recordingAndPayingDTO
                              .dfr_opid :""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Rectd : </span>
                          {
                            this.state.displayData.recordingAndPayingDTO!==null && this.state.displayData.recordingAndPayingDTO
                            .dfr_mrectd!==null ? this.state.displayData.recordingAndPayingDTO
                            .dfr_mrectd :""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">MTD : </span>
                          {this.state.displayData.recordingAndPayingDTO!==null && this.state.displayData.recordingAndPayingDTO.dfr_mtd!==null ? this.state.displayData.recordingAndPayingDTO.dfr_mtd :""}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">
                            Receipt DT :{" "}
                          </span>
                          {
                            this.state.displayData.recordingAndPayingDTO!==null && this.state.displayData.recordingAndPayingDTO
                            .dfr_date_time!==null ? this.state.displayData.recordingAndPayingDTO
                            .dfr_date_time :""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Country &amp; Pay Agent Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">
                            Source Country :{" "}
                          </span>
                          {
                            this.state.displayData.countryAndPayAgent!==null && this.state.displayData.countryAndPayAgent
                            .dfr_orig_cntry!==null ? this.state.displayData.countryAndPayAgent
                            .dfr_orig_cntry:""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">
                            Destination Country :{" "}
                          </span>
                          {
                            this.state.displayData.countryAndPayAgent!==null && this.state.displayData.countryAndPayAgent
                              .dfr_dest_cntry!==null ? this.state.displayData.countryAndPayAgent.dfr_dest_cntry :""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">
                            Intended Dest :{" "}
                          </span>
                          {
                            this.state.displayData.countryAndPayAgent!==null && this.state.displayData.countryAndPayAgent
                            .dfr_intended_dest!==null ? this.state.displayData.countryAndPayAgent
                            .dfr_intended_dest :""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Testq : </span>
                          {this.state.displayData.countryAndPayAgent!==null && this.state.displayData.countryAndPayAgent.dfr_mtestq!==null ? this.state.displayData.countryAndPayAgent.dfr_mtestq :""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Credit Card And QCC Details
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">
                            Credit Card# :{" "}
                          </span>
                          {
                            this.state.displayData.creditCardAndQqc!==null && this.state.displayData.creditCardAndQqc
                            .dfr_db_cr_crd!==null ? this.state.displayData.creditCardAndQqc
                            .dfr_db_cr_crd :""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Exp Date : </span>
                          {
                            this.state.displayData.creditCardAndQqc!==null && this.state.displayData.creditCardAndQqc
                            .dfr_db_cr_exp_date!==null ? this.state.displayData.creditCardAndQqc
                            .dfr_db_cr_exp_date :""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Auth Cde : </span>
                          {
                            this.state.displayData.creditCardAndQqc!==null && this.state.displayData.creditCardAndQqc
                            .dfr_db_cr_auth!==null ? this.state.displayData.creditCardAndQqc
                            .dfr_db_cr_auth :""
                          }
                        </div>
                        {/* <div className="col-lg-4">
                          Key Seq:{" "}
                          {this.state.displayData.creditCardAndQqc.dfr_encrypt_key_seq}
                        </div> */}
                        <div className="col-lg-6">
                          <span className="font-weight-bold">
                            QCC Mask Acct :{" "}
                          </span>
                          {
                            this.state.displayData.creditCardAndQqc!==null && this.state.displayData.creditCardAndQqc
                            .dfr_maskd_acct!==null ? this.state.displayData.creditCardAndQqc
                            .dfr_maskd_acct :""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">
                            QCC Ref Num :{" "}
                          </span>
                          {
                            this.state.displayData.creditCardAndQqc!==null && this.state.displayData.creditCardAndQqc
                            .dfr_ref_number!==null ? this.state.displayData.creditCardAndQqc
                            .dfr_ref_number :""
                          }
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">QCC Attn : </span>
                          {this.state.displayData.creditCardAndQqc!==null && this.state.displayData.creditCardAndQqc.dfr_qccattn!==null ? this.state.displayData.creditCardAndQqc.dfr_qccattn :""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Sender Financial Info
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Principal : </span>
                          {
                            this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo
                            .dfr_mprinb!==null ? this.state.displayData.senderFinancialInfo
                            .dfr_mprinb :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Tolls : </span>
                          {
                            this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo
                            .dfr_mtollsb!==null ? this.state.displayData.senderFinancialInfo
                            .dfr_mtollsb :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Charges : </span>
                          {
                            this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo
                            .dfr_mchargesb!==null ? this.state.displayData.senderFinancialInfo
                            .dfr_mchargesb :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Pay Charges : </span>
                          {
                            this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo
                            .dfr_mpchargeb!==null ? this.state.displayData.senderFinancialInfo
                            .dfr_mpchargeb :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Tax1 : </span>
                          {this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo.dfr_mtax1 !==null ?
                          this.state.displayData.senderFinancialInfo.dfr_mtax1:""}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Tax2 : </span>
                          {this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo.dfr_mtax2!==null ?
                          this.state.displayData.senderFinancialInfo.dfr_mtax2 :""}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Tax3 : </span>
                          {this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo.dfr_mtax3!==null ?
                          this.state.displayData.senderFinancialInfo.dfr_mtax3 :""}
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Gross : </span>
                          {
                            this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo
                            .dfr_mgrossb!==null ? this.state.displayData.senderFinancialInfo
                            .dfr_mgrossb :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Discount : </span>
                          {
                            this.state.displayData.senderFinancialInfo!==null && this.state.displayData.senderFinancialInfo
                            .dfr_promo_discount!==null ? this.state.displayData.senderFinancialInfo
                            .dfr_promo_discount :""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      Payee Financial Info
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Principal : </span>
                          {
                            this.state.displayData.payeeFinancialInfo!==null && this.state.displayData.payeeFinancialInfo
                            .dfr_mpayprin ? this.state.displayData.payeeFinancialInfo
                            .dfr_mpayprin :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Display Rate : </span>
                          {
                            this.state.displayData.payeeFinancialInfo!==null && this.state.displayData.payeeFinancialInfo
                            .dfr_display_rate!==null ? this.state.displayData.payeeFinancialInfo
                            .dfr_display_rate :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Receipt Fix Flg : </span>
                          {
                            this.state.displayData.payeeFinancialInfo!==null && this.state.displayData.payeeFinancialInfo
                            .dfr_receipt_fix_flag!==null ?this.state.displayData.payeeFinancialInfo
                            .dfr_receipt_fix_flag :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Txn Type : </span>
                          {
                            this.state.displayData.payeeFinancialInfo!==null && this.state.displayData.payeeFinancialInfo
                            .dfr_orn_txn_type!==null ? this.state.displayData.payeeFinancialInfo
                            .dfr_orn_txn_type :""
                          }
                        </div>
                        <div className="col-lg-4">
                        <span className="font-weight-bold">Sys Trace : </span>
                          {
                            this.state.displayData.payeeFinancialInfo!==null && this.state.displayData.payeeFinancialInfo
                            .dfr_systrace!==null ? this.state.displayData.payeeFinancialInfo
                            .dfr_systrace :""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      PAYEE UAB DETAILS
                    </div>
                    <div className="card-body card-body-custom">
                      <table className="table table-bordered margin-0 table-sm">
                        <thead>
                          <tr>
                            <th scope="col">FIELD NAME</th>
                            <th scope="col">FIELD DATA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.displayData.dfr_payee_uba &&
                            this.state.displayData.dfr_payee_uba.map(
                              (data, i) => (
                                <tr key={`${i}+payee-uab`}>
                                  <td>{data.fieldNameId}</td>
                                  <td>{data.fieldData}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      SENDER UAB DETAILS
                    </div>
                    <div className="card-body card-body-custom">
                      <table className="table table-bordered margin-0 table-sm">
                        <thead>
                          <tr>
                            <th scope="col">FIELD NAME</th>
                            <th scope="col">FIELD DATA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.displayData.dfr_sndr_uba &&
                            this.state.displayData.dfr_sndr_uba.map(
                              (data, i) => (
                                <tr key={`${i}+sender-uab`}>
                                  <td>{data.fieldNameId}</td>
                                  <td>{data.fieldData}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mar-top5">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      DODD FRANK BUFFER
                    </div>
                    <div className="card-body card-body-custom">
                      <table className="table table-bordered margin-0 table-sm">
                        <thead>
                          <tr>
                            <th scope="col">FIELD NAME</th>
                            <th scope="col">FIELD DATA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.displayData.dfBuffer &&
                            this.state.displayData.dfBuffer.map((data, i) => (
                              <tr key={`${i}+dodd-frank-buffer`}>
                                <td>{data.fieldNameId}</td>
                                <td>{data.fieldData}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header bg-dark text-white card-header-custom">
                      CHARGES BUFFER
                    </div>
                    <div className="card-body card-body-custom">
                      <table className="table table-bordered margin-0 table-sm">
                        <thead>
                          <tr>
                            <th scope="col">FIELD NAME</th>
                            <th scope="col">FIELD DATA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.displayData.chargesBuffer &&
                            this.state.displayData.chargesBuffer.map(
                              (data, i) => (
                                <tr key={`${i}+charges-buffer`}>
                                  <td>{data.fieldNameId}</td>
                                  <td>{data.fieldData}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mar-top5">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 text-center mar-bottom5">
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleNewDDFRSearch}
                    data-test="button-new-search"
                  >
                    NEW SEARCH
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandlePreviousDDFRSerach}
                    data-test="button-pre-results"
                  >
                    PRE RESULTS
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleMTCNData}
                    data-test="button-mtcn-data"
                  >
                    MTCN DATA
                  </button>
                  {this.state.error && (
                    <div className="alert alert-danger alert-padding search-key-error">
                      {this.state.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ MTCNSearchReducer }) => {
  return {
    searchedData: MTCNSearchReducer.dataInfo,
  };
};
export default connect(mapStateToProps, { doSearchMtcn })(
  DoddFrankReceiptDetails
);
