import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersShared } from "../../utility/utility";

export default class CountryDetails extends Component {
  state = {
    data: {
      countryCode: null,
      numericCode: 0,
      isoCode: "",
      status: 2,
      countryName: "",
      currency: "",
      routeType: "",
      roudingAmount: "0.01",
      ccAuthQ: "",
      route: "",
      testQuestion: "N",
      fixOnSend: "N",
      fixOnReceive: "N",
      fixOnIntraCountry: "N",
      qqcMax: 0,
      bestBuy: "",
      payoutMax: 0,
      usdMaxPayout: "N",
      usDiscount: "0.0000",
      nonUsDiscount: "0.0000",
      purgePeriod: 0,
      exRateSingleBidAsk: "",
      exRatefxAdjust: "0.00",
      updateHistoryDate: "",
      updateHistoryOperator: "",
      updateHistoryTerminal: "",
    },
    enableBtn: false,
    submitStatusCountryDetails: null,
    submitMsgCountryDetails: "",
  };

  componentDidMount() {
    this.setState({
      tokenCM:this.props.location.state.state.tokenCM,
      accessGrantedCM:this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    })
    if (this.props.location.state.mode === "add") {
      this.setState((prevState) => {
        return {
          enableBtn: true,          
          data: {
            ...prevState.data,
            countryCode: this.props.location.state.data.countryCode,
            countryName: this.props.location.state.data.countryName,
          },
        };
      });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/country`,
          {
            headers: {
              ...getHeadersShared().headers,
              countryCode: this.props.location.state.data.countryCode,
              countryName: this.props.location.state.data.countryName,
              Authorization: this.props.location.state.state.tokenCM,
              userId: this.props.location.state.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          if (response.data.countryCode) {
            this.setState((prevState) => {
              return {
                data: {
                  ...prevState.data,
                  countryCode: response.data.countryCode,
                },
              };
            });
            this.setState({
              data: response.data,
            });
          } else {
            this.setState({
              submitStatusCountryMaintenance: "Error",
              submitMsgCountryMaintenance: "NO COUNTRY RECORD FOUND",
            });
          }
        });
    }
  }

  onChangeControlCountryDetailsNum = (e) => {    
    const { name, value } = e.target;

    this.setState((prevState) => {
      return {
        data: {
          ...prevState.data,
          [name]: value.replace(/[^0-9]/, ""),
        },
      };
    });
  };

  onChangeControlCountryDetails = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      return {
        data: {
          ...prevState.data,
          [name]: value,
        },
      };
    });
  };

  onChangeControlCountryDetailsUpperCase = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      return {
        data: {
          ...prevState.data,
          [name]: value.toUpperCase(),
        },
      };
    });
  };

  onValidateCountryDetailsAdd = () => {
    if (this.state.data.numericCode === "") {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "Please Enter Valid Value For Numeric Code",
      });
      return false;
    }
    if (this.state.data.countryName === "") {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "Please Enter Valid Value For Country Name",
      });
      return false;
    }
    return true;
  };
  onValidateCountryDetailsUpdate = () => {    
    if (this.state.data.testQuestion === " ") {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "Please Enter Valid Value For Test Question",
      });
      return false;
    }
    if (this.state.data.bestBuy === "") {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "Please Enter Valid Value For BestBuy",
      });
      return false;
    }
    if (this.state.data.usDiscount > 99.99) {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "US Discount Value Is Invalid",
      });
      return false;
    }
    if (this.state.data.nonUsDiscount > 99.99) {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "NON-US Discount Value Is Invalid",
      });
      return false;
    }
    if (this.state.data.bestBuy !== "B" && this.state.data.bestBuy !== "N") {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "Best Buy Must Be B/N",
      });
      return false;
    }
    if (this.state.data.roudingAmount > 99999999.99) {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "FX Adjust Value Is Invalid",
      });
      return false;
    }
    if (this.state.data.exRatefxAdjust > 99.99) {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: "FX Adjust Value Is Invalid",
      });
      return false;
    }
    return true;
  };

  onChangeControlCountryDetailsOnlyNumber = (e) => {
    const { name, value } = e.target;
    //value.replace(/[^a-zA-Z]/, '')
    this.setState((prevState) => {
      return {
        data: {
          ...prevState.data,
          [name]: value.replace(/[^\d]/, ""),
        },
      };
    });
  };

  onChangeControlCountryDetailsOnlyDecimal= (e) => {
    const { name, value } = e.target;
    //value.replace(/[^a-zA-Z]/, '')
    this.setState((prevState) => {
      return {
        data: {
          ...prevState.data,
          [name]: value.replace(/[^\d.]/, ""),
        },
      };
    });
  };

  onHandleUpdateCountryDetails = () => {
    // if (
    //   this.props.location.state.mode === "add" &&
    //   !this.onValidateCountryDetailsUpdate()
    // ) {
    //   return;
    // } else if (
    //   this.props.location.state.mode === "add" &&
    //   !this.onValidateCountryDetailsAdd()
    // ) {
    //   return;
    // } else if (
    //   this.props.location.state.mode === "update" &&
    //   !this.onValidateCountryDetailsUpdate()
    // ) {
    if((this.props.location.state.mode === "add" && !this.onValidateCountryDetailsUpdate())||
    (this.props.location.state.mode === "add" && !this.onValidateCountryDetailsAdd())||
    (this.props.location.state.mode === "update" && !this.onValidateCountryDetailsUpdate())
    ){
      return;
    }
    let submittedObj = {};
    submittedObj.countryCode = this.state.data.countryCode;
    submittedObj.numericCode = this.state.data.numericCode.toString();
    submittedObj.countryName = this.state.data.countryName;
    submittedObj.isoCode = this.state.data.isoCode;
    submittedObj.status = this.state.data.status;
    submittedObj.currency = this.state.data.currency;
    submittedObj.fixOnSend = this.state.data.fixOnSend;
    submittedObj.fixOnReceive = this.state.data.fixOnReceive;
    submittedObj.fixOnIntraCountry = this.state.data.fixOnIntraCountry;
    submittedObj.routeType = this.state.data.routeType;
    submittedObj.roudingAmount = this.state.data.roudingAmount;
    submittedObj.ccAuthQ = this.state.data.ccAuthQ;
    submittedObj.route = this.state.data.route;
    submittedObj.testQuestion = this.state.data.testQuestion;
    submittedObj.usdMaxPayout = this.state.data.usdMaxPayout;
    submittedObj.qqcMax = this.state.data.qqcMax;
    submittedObj.bestBuy = this.state.data.bestBuy.replace(/ /g, "");
    submittedObj.payoutMax = this.state.data.payoutMax;
    submittedObj.usDiscount = this.state.data.usDiscount;
    submittedObj.nonUsDiscount = this.state.data.nonUsDiscount;
    submittedObj.purgePeriod = this.state.data.purgePeriod;
    submittedObj.exRateSingleBidAsk = this.state.data.exRateSingleBidAsk;
    submittedObj.exRatefxAdjust = this.state.data.exRatefxAdjust;
    submittedObj.updateHistoryOperator = this.state.data.updateHistoryOperator;
    submittedObj.updateHistoryTerminal = this.state.data.updateHistoryTerminal;
    if (this.props.location.state.mode === "add") {
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/country`,
          submittedObj,
          {
            headers: {
              ...getHeadersShared().headers,              
              Authorization: this.props.location.state.state.tokenCM,
              userId: this.props.location.state.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          this.responseAfterNwCallCD(response);
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/country`,
          submittedObj,
          {
            headers: {
              ...getHeadersShared().headers,              
              Authorization: this.props.location.state.state.tokenCM,
              userId: this.props.location.state.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          this.responseAfterNwCallCD(response);
        });
    }
    this.setState((prevState) => {
      return {
        data: {
          ...prevState.data,
          roudingAmount: this.state.data.roudingAmount===''?0:this.state.data.roudingAmount,
          qqcMax: this.state.data.qqcMax===''?0:this.state.data.qqcMax,
          payoutMax: this.state.data.payoutMax===''?0:this.state.data.payoutMax,
          usDiscount: this.state.data.usDiscount===''?0:this.state.data.usDiscount,
          nonUsDiscount: this.state.data.nonUsDiscount===''?0:this.state.data.nonUsDiscount,
          purgePeriod: this.state.data.purgePeriod===''?0:this.state.data.purgePeriod,
          exRatefxAdjust: this.state.data.exRatefxAdjust===''?0:this.state.data.exRatefxAdjust,
        },
      };
    });
  };

  responseAfterNwCallCD = (response) => {
    if (response.data.responseCode === 200 || response.data.responseCode === '200') {
      this.setState({
        submitStatusCountryDetails: "OK",
        submitMsgCountryDetails: response.data.responseString,
        enableBtn: false,
      });
    } else {
      this.setState({
        submitStatusCountryDetails: "Error",
        submitMsgCountryDetails: response.data.responseString,
      });
    }
  };

  onHandleCorridorCountryDetails = () => {
    const state = {
      countryName: this.state.data.countryName,
      countryCode: this.state.data.countryCode,
      tokenCM:this.props.location.state.state.tokenCM,
      accessGrantedCM:this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    };

    this.props.history.push({
      pathname: "/corridor-rules",
      state: { state },
    });
  };

  onHandleCurtainCountryDetails = () => {
    const data = {
      countryName: this.state.data.countryName,
      countryCode: this.state.data.countryCode,
    };

    this.props.history.push({
      pathname: "/curtains",
      state: { data: data,state:this.state },
    });
  };

  onHandleFlagCountryDetails = () => {
    const data = {
      countryName: this.state.data.countryName,
      countryCode: this.state.data.countryCode,
    };

    this.props.history.push({
      pathname: "/country-options",
      state: { data: data,state:this.state },
    });
  };

  onHandleCountryDeliveryService = () => {
    const data = {
      countryName: this.state.data.countryName,
      countryCode: this.state.data.countryCode,
    };
    this.props.history.push({
      pathname: "/country-delivery-service",
      state: { data,state:this.state },
    });
  };

  onHandleBackCountryDetails = () => {
    this.props.history.push("/country-maintenance");
  };

  getCountryDetailsValues = () => {
    let countryDetails = ["Y", "N"];
    let countryDetailsValues = countryDetails.map((item, i) => (
      <option key={item + i} value={item}>
        {item}
      </option>
    ));
    return countryDetailsValues;
  };

  render() {
    return (
      <>
      <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
      <div
        className="container-fluid"
        data-test="component-country-details"
      >
          <div className="card-body">
            <h4 className="card-title main-heading ">COUNTRY DETAILS</h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                COUNTRY DETAILS
              </div>
              <div className="card-body card-body-custom">
                <form>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="countryCode" className="font-weight-bold">
                        Country Code
                      </label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.data.countryCode}
                        name="countryCode"
                        disabled
                        className="form-control form-control-sm"
                        data-test="text-countryCode"
                        placeholder="Country  Code"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="numericCode" className="font-weight-bold">
                        Numeric Code
                      </label>
                      {this.state.enableBtn ? (
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.data.numericCode}
                          name="numericCode"
                          onChange={(e) => {
                            this.onChangeControlCountryDetails(e);
                          }}
                          className="form-control form-control-sm"
                          data-test="text-numericCode"
                          placeholder="Numeric  Code"
                        />
                      ) : (
                        <input
                          type="text"
                          maxLength="3"
                          value={this.state.data.numericCode}
                          name="numericCode"
                          disabled
                          className="form-control form-control-sm"
                          data-test="text-numericCode"
                          placeholder="Numeric  Code"
                        />
                      )}
                    </div>
                    <div className="col-lg">
                      <label htmlFor="isoCode" className="font-weight-bold">
                        ISO Code
                      </label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.data.isoCode}
                        name="isoCode"
                        onChange={(e) => {
                          this.onChangeControlCountryDetails(e);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-isoCode"
                        placeholder="ISO  Code"
                      />
                    </div>
                    <div className="col-lg form-group ">
                      <label htmlFor="status" className="font-weight-bold">
                        Status
                      </label>
                      <div className="row">
                        <div className="mar-right10" />
                        <div className="form-check form-check-inline ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value={1}
                            checked={
                              this.state.data.status === 1 ||
                              this.state.data.status === "1"
                            }
                            onChange={(e) => {
                              this.onChangeControlCountryDetails(e);
                            }}
                            data-test="radio-status-active"
                          />
                          <label className="form-check-label" htmlFor="status">
                            Active
                          </label>
                        </div>
                        <div className=" form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value={2}
                            checked={
                              this.state.data.status === 2 ||
                              this.state.data.status === "2"
                            }
                            onChange={(e) => {
                              this.onChangeControlCountryDetails(e);
                            }}
                            data-test="radio-status-suspended"
                          />
                          <label className="form-check-label" htmlFor="status">
                            Suspended
                          </label>
                        </div>
                        <div className=" form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value={3}
                            checked={
                              this.state.data.status === 3 ||
                              this.state.data.status === "3"
                            }
                            onChange={(e) => {
                              this.onChangeControlCountryDetails(e);
                            }}
                            data-test="radio-status-delete"
                          />
                          <label className="form-check-label" htmlFor="status">
                            Delete
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="countryName" className="font-weight-bold">
                        Country Name
                      </label>
                      {this.state.enableBtn ? (
                        <input
                          type="text"
                          maxLength="40"
                          value={this.state.data.countryName}
                          name="countryName"
                          onChange={(e) => {
                            this.onChangeControlCountryDetails(e);
                          }}
                          className="form-control form-control-sm"
                          data-test="text-countryName"
                          placeholder="Country Name"
                        />
                      ) : (
                        <input
                          type="text"
                          maxLength="40"
                          value={this.state.data.countryName}
                          name="countryName"
                          disabled
                          className="form-control form-control-sm"
                          data-test="text-countryName"
                          placeholder="Country Name"
                        />
                      )}
                    </div>
                    <div className="col-lg">
                      <label htmlFor="currency" className="font-weight-bold">
                        Currency
                      </label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.data.currency}
                        name="currency"
                        onChange={(e) => {
                          this.onChangeControlCountryDetails(e);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-currency"
                        placeholder="Currency"
                      />
                    </div>                    
                    <div className="col-lg">
                      <label htmlFor="currency" className="font-weight-bold">
                        Xrate
                      </label>
                      <input
                        type="text"
                        maxLength="3"
                        value={this.state.data.cbr_fi_xrate}
                        name="cbr_fi_xrate"                        
                        className="form-control form-control-sm"
                        data-test="text-cbr_fi_xrate"
                        placeholder="0"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="card-header bg-dark text-white card-header-custom">
                          M/T Fix On
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="form-group row">
                            <div className="col-lg-6">
                              <label
                                htmlFor="fixOnSend"
                                className="font-weight-bold"
                              >
                                Send
                              </label>
                              <select
                                className="form-control  form-control-sm "
                                name="fixOnSend"
                                data-test="select-fixOnSend"
                                onChange={(e) => {
                                  this.onChangeControlCountryDetails(e);
                                }}
                                value={this.state.data.fixOnSend}
                              >
                                <option value=" "></option>
                                <option value="F">F</option>
                                <option value="N">N</option>
                                <option value="W">W</option>
                                <option value="X">X</option>
                                <option value="Y">Y</option>
                              </select>
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="fixOnReceive"
                                className="font-weight-bold"
                              >
                                Receive
                              </label>
                              <select
                                className="form-control  form-control-sm "
                                name="fixOnReceive"
                                data-test="select-fixOnReceive"
                                onChange={(e) => {
                                  this.onChangeControlCountryDetails(e);
                                }}
                                value={this.state.data.fixOnReceive}
                              >
                                <option value=" "></option>
                                <option value="N">N</option>
                                <option value="R">R</option>
                                <option value="Y">Y</option>
                              </select>
                            </div>
                          </div>{" "}
                          <div className="form-group row">
                            <div className="col-lg">
                              <label
                                htmlFor="fixOnIntraCountry"
                                className="font-weight-bold"
                              >
                                Intra Country
                              </label>
                              <select
                                className="form-control  form-control-sm "
                                name="fixOnIntraCountry"
                                data-test="select-fixOnIntraCountry"
                                onChange={(e) => {
                                  this.onChangeControlCountryDetails(e);
                                }}
                                value={this.state.data.fixOnIntraCountry}
                              >
                                <option value=" "></option>
                                <option value="N">N</option>
                                <option value="Y">Y</option>  
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9  d-flex">
                      <div className="form-group row justify-content-center align-self-center ">
                        <div className="col-lg ">
                          <label
                            htmlFor="routeType"
                            className="font-weight-bold"
                          >
                            Route Type
                          </label>
                          <select
                            className="form-control  form-control-sm "
                            name="routeType"
                            data-test="text-routeType"
                            onChange={(e) => {
                              this.onChangeControlCountryDetails(e);
                            }}
                            value={this.state.data.routeType}
                          >
                            <option value="">Select</option>
                            <option value="E">Esl</option>
                            <option value="I">Internet</option>
                            <option value="M">Multi</option>
                            <option value="A">Alt</option>
                          </select>
                        </div>
                        <div className="col-lg">
                          <label
                            htmlFor="roudingAmount"
                            className="font-weight-bold"
                          >
                            {" "}
                            Rounding Amt
                          </label>
                          <input
                            type="text"
                            maxLength="11"
                            value={this.state.data.roudingAmount}
                            name="roudingAmount"
                            onChange={(e) => {
                              this.onChangeControlCountryDetailsOnlyDecimal(e);
                            }}
                            className="form-control form-control-sm"
                            data-test="text-roudingAmount"
                            placeholder="	Rounding Amt"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="ccAuthQ" className="font-weight-bold">
                            CCAUTHQ
                          </label>
                          <input
                            type="text"
                            maxLength="7"
                            value={this.state.data.ccAuthQ}
                            name="ccAuthQ"
                            onChange={(e) => {
                              this.onChangeControlCountryDetails(e);
                            }}
                            className="form-control form-control-sm"
                            data-test="text-ccAuthQ"
                            placeholder="CCAUTHQ"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="route" className="font-weight-bold">
                            Route
                          </label>
                          <input
                            type="text"
                            maxLength="40"
                            value={this.state.data.route}
                            name="route"
                            onChange={(e) => {
                              this.onChangeControlCountryDetails(e);
                            }}
                            className="form-control form-control-sm"
                            data-test="text-route"
                            placeholder="Route"
                          />
                        </div>
                        <div className="col-lg">
                          <label
                            htmlFor="testQuestion"
                            className="font-weight-bold"
                          >
                            Test Question
                          </label>
                          <select
                            className="form-control  form-control-sm "
                            name="testQuestion"
                            data-test="text-testQuestion"
                            onChange={(e) => {
                              this.onChangeControlCountryDetails(e);
                            }}
                            value={this.state.data.testQuestion}
                          >
                            <option value=" ">Select</option>
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                            <option value="P">P</option>
                          </select>
                        </div>
                        <div className="col-lg">
                          <label
                            htmlFor="usdMaxPayout"
                            className="font-weight-bold"
                          >
                            USD MAX PAYOUT
                          </label>
                          <select
                            className="form-control  form-control-sm "
                            name="usdMaxPayout"
                            data-test="text-usdMaxPayout"
                            onChange={(e) => {
                              this.onChangeControlCountryDetails(e);
                            }}
                            value={this.state.data.usdMaxPayout}
                          >
                            {this.getCountryDetailsValues()}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="qqcMax" className="font-weight-bold">
                        Cntry QQCMAX
                      </label>
                      <input
                        type="text"
                        maxLength="10"
                        value={this.state.data.qqcMax}
                        name="qqcMax"
                        onChange={(e) => {
                          this.onChangeControlCountryDetailsNum(e);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-qqcMax"
                        placeholder="Cntry QQCMAX"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="bestBuy" className="font-weight-bold">
                        {" "}
                        Best Buy(B/N)
                      </label>
                      <input
                        type="text"
                        maxLength="1"
                        value={this.state.data.bestBuy}
                        name="bestBuy"
                        onChange={(e) => {
                          this.onChangeControlCountryDetailsUpperCase(e);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-bestBuy"
                        placeholder="Best Buy"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="payoutMax" className="font-weight-bold">
                        {" "}
                        Cntry Payout Max
                      </label>
                      <input
                        type="text"
                        maxLength="10"
                        value={this.state.data.payoutMax}
                        name="payoutMax"
                        onChange={(e) => {
                          this.onChangeControlCountryDetailsOnlyNumber(e);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-payoutMax"
                        placeholder="Cntry Payout Max"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="usDiscount" className="font-weight-bold">
                        US Discount
                      </label>
                      <input
                        type="text"
                        maxLength="6"
                        value={this.state.data.usDiscount}
                        name="usDiscount"
                        onChange={(e) => {
                          this.onChangeControlCountryDetailsOnlyDecimal(e);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-usDiscount"
                        placeholder="US Discount"
                      />
                    </div>
                    <div className="col-lg">
                      <label
                        htmlFor="nonUsDiscount"
                        className="font-weight-bold"
                      >
                        {" "}
                        Non-US Discount
                      </label>
                      <input
                        type="text"
                        maxLength="6"
                        value={this.state.data.nonUsDiscount}
                        name="nonUsDiscount"
                        onChange={(e) => {
                          this.onChangeControlCountryDetailsNum(e);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-nonUsDiscount"
                        placeholder="Non-US Discount"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="purgePeriod" className="font-weight-bold">
                        {" "}
                        Purge Period
                      </label>
                      <input
                        type="text"
                        maxLength="5"
                        value={this.state.data.purgePeriod}
                        name="purgePeriod"
                        onChange={(e) => {
                          this.onChangeControlCountryDetailsOnlyNumber(e);
                        }}
                        className="form-control form-control-sm"
                        data-test="text-purgePeriod"
                        placeholder="Purge Period"
                      />
                      {"Days(0=no purge)"}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <div className="card">
                        <div className="card-header bg-dark text-white card-header-custom">
                          Exchange Rate
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="form-group row">
                            <div className="col-lg-1" />
                            <div className="col-lg-5 form-group ">
                              <label
                                htmlFor="exRateSingleBidAsk"
                                className="font-weight-bold"
                              >
                                Exchange Rate
                              </label>
                              <div className="row">
                                <div className="mar-right10" />
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exRateSingleBidAsk"
                                    value={1}
                                    checked={
                                      this.state.data.exRateSingleBidAsk ===
                                        1 ||
                                      this.state.data.exRateSingleBidAsk === "1"
                                    }
                                    onChange={(e) => {
                                      this.onChangeControlCountryDetails(e);
                                    }}
                                    data-test="select-radio-exRateSingleBidAsk-single"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exRateSingleBidAsk"
                                  >
                                    Single
                                  </label>
                                </div>

                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exRateSingleBidAsk"
                                    value={2}
                                    checked={
                                      this.state.data.exRateSingleBidAsk ===
                                        2 ||
                                      this.state.data.exRateSingleBidAsk === "2"
                                    }
                                    onChange={(e) => {
                                      this.onChangeControlCountryDetails(e);
                                    }}
                                    data-test="select-radio-exRateSingleBidAsk-bidask"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exRateSingleBidAsk"
                                  >
                                    Bid/Ask
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <label
                                htmlFor="exRatefxAdjust"
                                className="font-weight-bold"
                              >
                                FX Adjust
                              </label>
                              <input
                                type="text"
                                maxLength="6"
                                value={this.state.data.exRatefxAdjust}
                                name="exRatefxAdjust"
                                onChange={(e) => {
                                  this.onChangeControlCountryDetailsOnlyDecimal(
                                    e
                                  );
                                }}
                                className="form-control form-control-sm"
                                data-test="text-exRatefxAdjust"
                                placeholder="FX Adjust"
                              />
                            </div>
                            <div className="col-lg-1" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="card">
                        <div className="card-header bg-dark text-white card-header-custom">
                          Update History
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="form-group row">
                            <div className="col-lg">
                              <label
                                htmlFor="updateHistoryDate"
                                className="font-weight-bold"
                              >
                                Date
                              </label>
                              <input
                                type="text"
                                value={this.state.data.updateHistoryDate}
                                name="updateHistoryDate"
                                className="form-control form-control-sm"
                                data-test="text-updateHistoryDate"
                                placeholder="Date"
                                disabled
                              />
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="updateHistoryOperator"
                                className="font-weight-bold"
                              >
                                Operator
                              </label>
                              <input
                                type="text"
                                value={this.state.data.updateHistoryOperator}
                                name="updateHistoryOperator"
                                className="form-control form-control-sm"
                                data-test="text-updateHistoryOperator"
                                placeholder="Operator"
                                disabled
                              />
                            </div>
                            <div className="col-lg">
                              <label
                                htmlFor="updateHistoryTerminal"
                                className="font-weight-bold"
                              >
                                Terminal
                              </label>
                              <input
                                type="text"
                                value={this.state.data.updateHistoryTerminal}
                                name="updateHistoryTerminal"
                                className="form-control form-control-sm"
                                data-test="text-updateHistoryTerminal"
                                placeholder="Terminal"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mar-top20">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleCountryDeliveryService}
                        disabled={this.state.enableBtn}
                        data-test="button-delivery-service"
                      >
                        DELIVERY SERVICE
                      </button>

                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleUpdateCountryDetails}
                        data-test="button-update"
                        disabled={
                          !this.props.location.state.state.accessGrantedCM
                        }
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleCorridorCountryDetails}
                        disabled={this.state.enableBtn}
                        data-test="button-corridor"
                      >
                        CORRIDOR
                      </button>

                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleCurtainCountryDetails}
                        disabled={this.state.enableBtn}
                        data-test="button-curtains"
                      >
                        CURTAINS
                      </button>

                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleFlagCountryDetails}
                        disabled={this.state.enableBtn}
                        data-test="button-flags"
                      >
                        FLAGS
                      </button>

                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackCountryDetails}
                        data-test="button-country-maintenance"
                      >
                        CNTRY MAINT
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4" />
                    <div className="col-lg-4">
                      {this.state.submitStatusCountryDetails === "OK" && (
                        <div className="alert alert-success alert-padding notification text-center">
                          {this.state.submitMsgCountryDetails}
                        </div>
                      )}
                      {this.state.submitStatusCountryDetails === "Error" && (
                        <div className="alert alert-danger alert-padding notification text-center">
                          {this.state.submitMsgCountryDetails}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      </>
    );
  }
}
