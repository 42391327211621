import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import Header from "../../header/header";
import { getHeadersShared } from "../../utility/utility";

class CountryPayoutCurtainMaintenance extends Component {
  state = {
    countryCode: "",
    countryName: "",
    adjStateId1: "",
    adjStateId2: "",
    adjStateId3: "",
    adjStateId4: "",
    adjStateId5: "",
    adjStateId6: "",
    adjStateId7: "",
    adjStateId8: "",
    adjStateId9: "",
    adjStateId10: "",
    adjStateId11: "",
    adjStateId12: "",
    adjStateId13: "",
    adjStateId14: "",
    adjStateId15: "",
    flag: "",
    flagCPCM: false,
    flagDel:false,
    showCountryPayoutCurtainMaintenanceConfirm: false,
    submitStatusCountryPayoutCurtainMaintenance: null,
    submitMsgCountryPayoutCurtainMaintenance: "",
  };

  componentDidMount = () => {
    const countryCode = this.props.location.state.data.countryCode;
    this.setState({
      tokenCM:this.props.location.state.state.tokenCM,
      accessGrantedCM:this.props.location.state.state.accessGrantedCM,
      operatorIdCM: this.props.location.state.state.operatorIdCM,
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/curtain/${countryCode}`,
        {
          headers: {
            ...getHeadersShared().headers,              
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        if (response.data === "") {
          this.setState({
            flagCPCM: true,
            flagDel:true,
          });
        }
        this.setState({
          adjStateId1: response.data.adjStateId1,
          adjStateId2: response.data.adjStateId2,
          adjStateId3: response.data.adjStateId3,
          adjStateId4: response.data.adjStateId4,
          adjStateId5: response.data.adjStateId5,
          adjStateId6: response.data.adjStateId6,
          adjStateId7: response.data.adjStateId7,
          adjStateId8: response.data.adjStateId8,
          adjStateId9: response.data.adjStateId9,
          adjStateId10: response.data.adjStateId10,
          adjStateId11: response.data.adjStateId11,
          adjStateId12: response.data.adjStateId12,
          adjStateId13: response.data.adjStateId13,
          adjStateId14: response.data.adjStateId14,
          adjStateId15: response.data.adjStateId15,
          flag: response.data.flag,
          countryCode: response.data.countryCode,
          countryName: response.data.countryName,
        });
      });
  };

  onChangeControlCountryPayoutCurtainMaintenance = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onHandleBackCountryPayoutCurtainMaintenance = () => {
    const data = {
      countryName: this.props.location.state.data.countryName,
      countryCode: this.props.location.state.data.countryCode,
    };

    this.props.history.push({
      pathname: "/country-details",
      state: { mode: null, data: data ,state:this.state},
    });
  };

  onHandleUpdateCountryPayoutCurtainMaintenance = () => {
    let submittedObj = {};
    submittedObj.countryCode = this.props.location.state.data.countryCode;
    submittedObj.countryName = this.props.location.state.data.countryName;
    submittedObj.adjStateId1 = this.state.adjStateId1;
    submittedObj.adjStateId2 = this.state.adjStateId2;
    submittedObj.adjStateId3 = this.state.adjStateId3;
    submittedObj.adjStateId4 = this.state.adjStateId4;
    submittedObj.adjStateId5 = this.state.adjStateId5;
    submittedObj.adjStateId6 = this.state.adjStateId6;
    submittedObj.adjStateId7 = this.state.adjStateId7;
    submittedObj.adjStateId8 = this.state.adjStateId8;
    submittedObj.adjStateId9 = this.state.adjStateId9;
    submittedObj.adjStateId10 = this.state.adjStateId10;
    submittedObj.adjStateId11 = this.state.adjStateId11;
    submittedObj.adjStateId12 = this.state.adjStateId12;
    submittedObj.adjStateId13 = this.state.adjStateId13;
    submittedObj.adjStateId14 = this.state.adjStateId14;
    submittedObj.adjStateId15 = this.state.adjStateId15;
    submittedObj.flag = this.state.flag;
    if (this.state.flagCPCM === true) {
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/curtain`,
          submittedObj,
          {
            headers: {
              ...getHeadersShared().headers,              
              Authorization: this.props.location.state.state.tokenCM,
              userId: this.props.location.state.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          this.responseAfterNwCallCPCM(response);
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/curtain`,
          submittedObj,
          {
            headers: {
              ...getHeadersShared().headers,              
              Authorization: this.props.location.state.state.tokenCM,
              userId: this.props.location.state.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          this.responseAfterNwCallCPCM(response);
        });
    }
  };

  responseAfterNwCallCPCM = (response) => {
    if (response.status === 200) {
      this.setState({
        submitStatusCountryPayoutCurtainMaintenance: "OK",
        submitMsgCountryPayoutCurtainMaintenance: response.data.responseString,
        flagDel:false,
      });
    } else {
      this.setState({
        submitStatusCountryPayoutCurtainMaintenance: "Error",
        submitMsgCountryPayoutCurtainMaintenance: response.data.responseString,
      });
    }
  };

  onHandleClearCountryPayoutCurtainMaintenance = () => {
    this.setState({
      countryCode: "",
      countryName: "",
      adjStateId1: "",
      adjStateId2: "",
      adjStateId3: "",
      adjStateId4: "",
      adjStateId5: "",
      adjStateId6: "",
      adjStateId7: "",
      adjStateId8: "",
      adjStateId9: "",
      adjStateId10: "",
      adjStateId11: "",
      adjStateId12: "",
      adjStateId13: "",
      adjStateId14: "",
      adjStateId15: "",
      flag: "",
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusCountryPayoutCurtainMaintenance: null,
        }),
      5000
    );
  };

  getCountryPayoutCurtainMaintenanceModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showCountryPayoutCurtainMaintenanceConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Are You Sure, You Want To Delete This Payout Curtain Details?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showCountryPayoutCurtainMaintenanceConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteCountryPayoutCurtainMaintenance}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteCountryPayoutCurtainMaintenance = () => {
    const countryCode = this.props.location.state.data.countryCode;
    // const data = {
    //   countryCode: this.props.location.state.data.countryCode,
    //   countryName: this.props.location.state.data.countryName,
    // };
    axios
      //.delete(`${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/curtain/{countryCode}`, {

      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/curtain/${countryCode}`,
        {
          headers: {
            ...getHeadersShared().headers,              
            Authorization: this.props.location.state.state.tokenCM,
            userId: this.props.location.state.state.operatorIdCM,
          },
        },
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            submitStatusCountryPayoutCurtainMaintenance: "OK",
            submitMsgCountryPayoutCurtainMaintenance:
              response.data.responseString,
            adjStateId1: "",
            adjStateId2: "",
            adjStateId3: "",
            adjStateId4: "",
            adjStateId5: "",
            adjStateId6: "",
            adjStateId7: "",
            adjStateId8: "",
            adjStateId9: "",
            adjStateId10: "",
            adjStateId11: "",
            adjStateId12: "",
            adjStateId13: "",
            adjStateId14: "",
            adjStateId15: "",
            flag: "",
            flagDel:true,
          });
        } else {
          this.setState({
            submitStatusCountryPayoutCurtainMaintenance: "Error",
            submitMsgCountryPayoutCurtainMaintenance:
              response.data.responseString,
          });
        }
        this.onHandleClearCountryPayoutCurtainMaintenance();
      });
    this.setState({
      showCountryPayoutCurtainMaintenanceConfirm: false,      
    });
    // this.props.history.push({
    //   pathname: "/country-details",
    //   state: { mode: null, data: data, state:this.state},
    // });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">
              COUNTRY PAYOUT CURTAIN MAINTENANCE
            </h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                COUNTRY PAYOUT CURTAIN DETAILS
              </div>
              <div className="card-body card-body-custom">
                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="countryCode">
                      <span className="font-weight-bold">Country Code:</span>{" "}
                      {this.props.location.state.data.countryCode}
                    </label>
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="countryName">
                      <span className="font-weight-bold">Country Name:</span>{" "}
                      {this.props.location.state.data.countryName}
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId1" className="font-weight-bold">
                      Adj State ID 1
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId1}
                      className="form-control form-control-sm"
                      name="adjStateId1"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId1"
                      placeholder="Adj State ID 1"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId2" className="font-weight-bold">
                      Adj State ID 2
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId2}
                      className="form-control form-control-sm"
                      name="adjStateId2"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId2"
                      placeholder="Adj State ID 2"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId3" className="font-weight-bold">
                      Adj State ID 3
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId3}
                      className="form-control form-control-sm"
                      name="adjStateId3"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId3"
                      placeholder="Adj State ID 3"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId4" className="font-weight-bold">
                      Adj State ID 4
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId4}
                      className="form-control form-control-sm"
                      name="adjStateId4"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId4"
                      placeholder="Adj State ID 4"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId5" className="font-weight-bold">
                      Adj State ID 5
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId5}
                      className="form-control form-control-sm"
                      name="adjStateId5"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId5"
                      placeholder="Adj State ID 5"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId6" className="font-weight-bold">
                      Adj State ID 6
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId6}
                      className="form-control form-control-sm"
                      name="adjStateId6"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId6"
                      placeholder="Adj State ID 6"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId7" className="font-weight-bold">
                      Adj State ID 7
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId7}
                      className="form-control form-control-sm"
                      name="adjStateId7"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId7"
                      placeholder="Adj State ID 7"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId8" className="font-weight-bold">
                      Adj State ID 8
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId8}
                      className="form-control form-control-sm"
                      name="adjStateId8"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId8"
                      placeholder="Adj State ID 8"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="adjStateId9" className="font-weight-bold">
                      Adj State ID 9
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId9}
                      className="form-control form-control-sm"
                      name="adjStateId9"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId9"
                      placeholder="Adj State ID 9"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label
                      htmlFor="adjStateId10"
                      className="font-weight-bold"
                    >
                      Adj State ID 10
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId10}
                      className="form-control form-control-sm"
                      name="adjStateId10"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId10"
                      placeholder="Adj State ID 10"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label
                      htmlFor="adjStateId11"
                      className="font-weight-bold"
                    >
                      Adj State ID 11
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId11}
                      className="form-control form-control-sm"
                      name="adjStateId11"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId11"
                      placeholder="Adj State ID 11"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label
                      htmlFor="adjStateId12"
                      className="font-weight-bold"
                    >
                      Adj State ID 12
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId12}
                      className="form-control form-control-sm"
                      name="adjStateId12"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId12"
                      placeholder="Adj State ID 12"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-2">
                    <label
                      htmlFor="adjStateId13"
                      className="font-weight-bold"
                    >
                      Adj State ID 13
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId13}
                      className="form-control form-control-sm"
                      name="adjStateId13"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId13"
                      placeholder="Adj State ID 13"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label
                      htmlFor="adjStateId14"
                      className="font-weight-bold"
                    >
                      Adj State ID 14
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId14}
                      className="form-control form-control-sm"
                      name="adjStateId14"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId14"
                      placeholder="Adj State ID 14"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label
                      htmlFor="adjStateId15"
                      className="font-weight-bold"
                    >
                      Adj State ID 15
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.adjStateId15}
                      className="form-control form-control-sm"
                      name="adjStateId15"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-adjStateId15"
                      placeholder="Adj State ID 15"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="flag" className="font-weight-bold">
                      Flag
                    </label>
                    <input
                      type="text"
                      maxLength="1"
                      value={this.state.flag}
                      className="form-control form-control-sm"
                      name="flag"
                      onChange={(e) => {
                        this.onChangeControlCountryPayoutCurtainMaintenance(
                          e
                        );
                      }}
                      data-test="text-flag"
                      placeholder="Flag"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3" />
                  <div className="col-lg-6 text-center mar-bottom5">
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={
                        this.onHandleBackCountryPayoutCurtainMaintenance
                      }
                      data-test="button-back"
                    >
                      CNTRY DETAIL
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={
                        this.onHandleUpdateCountryPayoutCurtainMaintenance
                      }
                      data-test="button-update"
                      disabled={
                        !this.props.location.state.state.accessGrantedCM
                      }
                    >
                      SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => {
                        this.setState({
                          showCountryPayoutCurtainMaintenanceConfirm: true,
                        });
                      }}
                      disabled={
                        !this.props.location.state.state.accessGrantedCM || this.state.flagDel
                      }
                      data-test="button-delete"
                    >
                      DELETE
                    </button>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4" />
                  <div className="col-lg-4">
                    {this.state
                      .submitStatusCountryPayoutCurtainMaintenance ===
                      "OK" && (
                      <div className="alert alert-success alert-padding notification text-center">
                        {this.state.submitMsgCountryPayoutCurtainMaintenance}
                      </div>
                    )}
                    {this.state
                      .submitStatusCountryPayoutCurtainMaintenance ===
                      "Error" && (
                      <div className="alert alert-danger alert-padding notification text-center">
                        {this.state.submitMsgCountryPayoutCurtainMaintenance}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.getCountryPayoutCurtainMaintenanceModal()}
          </div>
        </div>
      </>
    );
  }
}
export default CountryPayoutCurtainMaintenance;
