import React, { Component} from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersCustomer } from "../../utility/utility";

class WebTmtCustomerAccountInformation extends Component {
  state = {
    records: [],
    singleData: {},
    countWTCAI: 0,
    enablePrevWTCAI: false,
    enableNextWTCAI: true, 
    enableBtn: false,
    enableNew: false,
    data: {
      aliasType: "",
      aliasIssuer: "",
      aliasId: "",
      customerId: "",
      customerNum: "",
    },
    customerAccountId: "",
    customerAccountNumber: "",
    customerAccntKey: "",
    customerName: "",
    createDate: "",
    activityDate: "",
    updateDate: "",
    experianCode: "",
    experianDetailCode: "",
    operatorId: "",
    displayData: {},
    newCustomerName: "",
    isCustAcctUpdateLoading: false,
    isCustAcctWebTmtLoading: false,
    isCustAcctCustInfoLoading: false,
    isCustAcctNextLoading: false,
    isCustAcctPrevLoading: false,
    submitStatusCustomerAcctInformation: null,
    submitMsgCustomerAccInformation: "",
  };
  
  /*componentDidMount() {
    if (this.props.location.state.mode === "add") {
      this.setState((prevState) => {
        return {
          // enableBtn: true,
          data: {
            ...prevState.data,
            fraudType: this.props.location.state.fraudType,
            fraudEntry: this.props.location.state.fraudEntry,
          },
        };
      });
    } else {
      if(this.props.location.state.aliasType!=="" && this.props.location.state.aliasIssuer!==""
      && this.props.location.state.aliasId !==""){
      axios
        .get(`${baseURL}/v1/cus/dbview/webtmtdbmaintenance/customeraccountinfo`, 
          {
            headers: { 
            ...getHeaders().headers,
            aliasType:this.props.location.state.aliasType,
            aliasIssuer: this.props.location.state.aliasIssuer,
            aliasId: this.props.location.state.aliasId,
            customerId: this.props.location.customerId ? this.props.location.customerId : ""

          }
        }
        )
        .then((response) => {
          let tempEnableNext = response.data.records.length > 1 ? true : false;
          this.setState({
            enableNextWTCAI: tempEnableNext,
          });
          if (response.data.records.length > 0) {
            this.setState({
              records: response.data.records,
              singleData: response.data.records[0],
            });
          }
          if(this.state.singleData.responseType === 1){
            this.props.history.push({
              pathname:'/web-tmt-customer-information',
              state: this.state,
            });
          }
        });
    }
    else if(this.props.location.state.customerId!=="" || this.props.location.state.customerNum!=="")
    {
      axios
        .get(`${baseURL}/v1/cus/dbview/webtmtdbmaintenance/customerinfo`, 
          {
            headers: { 
            ...getHeaders().headers,
            accountNumber:  this.props.location.state.customerId,
            customerNumber: this.props.location.state.customerNum,
          }
        }
        )
        .then((response) => {
          let tempEnableNext = response.data.records.length > 1 ? true : false;
          this.setState({
            enableNextWTCAI: tempEnableNext,
          });
          if (response.data.records.length > 0) {
            this.setState({
              records: response.data.records,
              singleData: response.data.records[0],
            });
          }
          if(this.state.singleData.responseType === 1){
            this.props.history.push({
              pathname:'/web-tmt-customer-information',
              state: this.state,
            });
          }
        });
    }
  }
  }*/

  componentDidMount() {
    this.setState((prevState) => {
      return {
        // enableBtn: true,
        data: {
          ...prevState,
          state: this.props.location.state,
        },
      };
    });
    this.setState({
      state: this.props.location.state,
      tokenWebTmt:this.props.location.state.tokenWebTmt,
      webAdmin:this.props.location.state.webAdmin,
      accessGrantedWrite: this.props.location.state.accessGrantedWrite,
      isAddressDecryprtRequired: this.props.location.state.isAddressDecryprtRequired,
      isCreditCardDecryprtRequired: this.props.location.state.isCreditCardDecryprtRequired,
      accessMTCCDisplay:this.props.location.state.accessMTCCDisplay,
      records: this.props.location.state.records,
      singleData: this.props.location.state.singleData,
      aliasType: this.props.location.state.aliasType,
      aliasIssuer: this.props.location.state.aliasIssuer,
      aliasId: this.props.location.state.aliasId,
      customerId: this.props.location.state.customerId
        ? this.state.customerId
        : "",
      newCustomerName: this.props.location.state.singleData.customerName,
      operatorId: this.props.location.state.operatorId
    });
    let tempEnableNext =
      this.props.location.state.records.length > 1 ? true : false;
    this.setState({
      enableNextWTCAI: tempEnableNext,
    });

    this.getCustomerAccountInformation();
  }

  onChangeCustomerAccInfo = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        singleData: {
          ...prevState.singleData,
          [name]: value,
        },
      };
    });
  };

  getCustomerAccountInformation = () => {
      axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customeraccountinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            aliasType: this.props.location.state.aliasType
              ? this.props.location.state.aliasType
              : "",
            aliasIssuer: this.props.location.state.aliasIssuer
              ? this.props.location.state.aliasIssuer
              : "",
            aliasId: this.props.location.state.aliasId
              ? this.props.location.state.aliasId
              : "",
            customerId: this.props.location.state.customerId
              ? this.props.location.state.customerId
              : this.props.location.state.customerNumber,
            Authorization:this.props.location.state.tokenWebTmt?this.props.location.state.tokenWebTmt:'',
            userId:this.props.location.state.operatorId,
          },
        }
      )
      .then((response) => {
        if (response.status===200 &&
          response.data.records !== null &&
          response.data.records.length > 0
        ) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[this.state.countWTCAI],
          });
        }
      });
       
  };

  onHandleUpdateCustomerAccInformation = () => {
    
    if (this.state.newCustomerName === this.state.singleData.customerName) {
      this.setState({
        submitStatusCustomerAccInformation: "OK",
        submitMsgCustomerAccInformation:
          "No Field Changed - Customer Account Table",
      });
    } else {
      this.setState({
        isCustAcctUpdateLoading: true
      })
      let submittedObj = {};
      submittedObj.WTCAIId =
        this.state.singleData.customerAccountId +
        "" +
        this.state.singleData.customerAccountNumber +
        "" +
        this.state.singleData.customerName +
        "" +
        this.state.singleData.createDate +
        "" +
        this.state.singleData.activityDate +
        "" +
        this.state.singleData.updateDate +
        "" +
        this.state.singleData.experianCode +
        "" +
        this.state.singleData.experianDetailCode +
        "" +
        this.state.singleData.operatorId +
        "" +
        this.state.singleData.customerAccntKey;
      submittedObj.responseType = 0;
      submittedObj.customerAccountId = this.state.singleData.customerAccountId;
      submittedObj.customerAccountNumber =
        this.state.singleData.customerAccountNumber;
      submittedObj.customerName = this.state.singleData.customerName;
      submittedObj.createDate = this.state.singleData.createDate;
      submittedObj.activityDate = this.state.singleData.activityDate;
      submittedObj.updateDate = this.state.singleData.updateDate;
      submittedObj.experianCode = this.state.singleData.experianCode;
      submittedObj.experianDetailCode =
        this.state.singleData.experianDetailCode;
      //submittedObj.operatorId = this.state.singleData.operatorId;
      submittedObj.operatorId = this.state.operatorId;
      submittedObj.customerAccntKey = this.state.singleData.customerAccntKey;
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customeraccountinfo`,
          submittedObj,
          {
            headers: {
              ...getHeadersCustomer().headers,
              Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            let tempRecord = this.state.records;
            tempRecord[this.state.countWTCAI] = this.state.singleData;
            this.setState({
              submitStatusCustomerAccInformation: "OK",
              submitMsgCustomerAccInformation: response.data.responseString,
              records: tempRecord,
              newCustomerName:submittedObj.customerName
              //customerId: this.state.singleData.customerAccountId,
            });
            this.getCustomerAccountInformation();
            this.setState({
              isCustAcctUpdateLoading: false
            })
          } else {
            this.setState({
              submitStatusCustomerAccInformation: "Error",
              submitMsgCustomerAccInformation: response.data.responseString,
              isCustAcctUpdateLoading: false
            });
          }
          this.onHandleClearWebTmtCustomerAcctInfo();
        });
    }
  };

  onHandleClearWebTmtCustomerAcctInfo = () => {
    setTimeout(
      () =>
        this.setState({
          submitStatusCustomerAccInformation: null,
          submitMsgCustomerAccInformation: "",
        }),
      5000
    );
  };

  onHandleWebTmtDBMaintenanceCustomerInfo = () => {
    if (
      this.state.singleData.customerAccountId !== "" ||
      this.state.singleData.customerAccountNumber !== ""
    ) {
      this.setState({
        isCustAcctCustInfoLoading: true
      })
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customerinfo`,
          {
            headers: {
              ...getHeadersCustomer().headers,
              customerNumber: this.state.singleData.customerAccountId
                ? this.state.singleData.customerAccountId
                : "",
              accountNumber: this.state.singleData.customerAccountNumber
                ? this.state.singleData.customerAccountNumber
                : "",
              Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
              userId:this.state.operatorId,
              //isAddressDecryprtRequired: this.state.isAddressDecryprtRequired,
            },
          }
        )
        .then((response) => {
          this.setState({
            isCustAcctCustInfoLoading: false
          })
          if (response.status === 200 && response.data.records &&response.data.records.length > 0) {
            this.setState({
              records: response.data.records,
              singleData: response.data.records[0],
              customerAccountId:this.state.singleData.customerAccountId,
              operatorId: this.state.operatorId
            });
            if (this.state.singleData.responseType === 1) {
              this.props.history.push({
                pathname: "/web-tmt-customer-information",
                state: this.state,
              });
            }           
          } else {
            this.setState({
              submitStatusCustomerAccInformation: "Error",
              submitMsgCustomerAccInformation: response.data.errMsg,
            });
            return false;
          }
        });
    }
  };

  onHandleBackWebTmtMain = () => {
    this.setState({
      isCustAcctWebTmtLoading: true
    })
    this.props.history.push("/web-tmt-db-maintenance");
    this.setState({
      isCustAcctWebTmtLoading: false
    })
  };

  onHandleBufferData = (typeWTCAI) => {
    if (typeWTCAI === "prevWTCAI") {
      this.setState(
        {
          isCustAcctPrevLoading: true,
          countWTCAI: this.state.countWTCAI - 1,
        },
        () => {
          this.setState({
            enableNextWTCAI: true,
            singleData: this.state.records[this.state.countWTCAI],
            newCustomerName:
              this.state.records[this.state.countWTCAI].customerName,
            isCustAcctPrevLoading: false
          });
          if (this.state.countWTCAI === 0) {
            this.setState({
              enablePrevWTCAI: false,
              enableNextWTCAI: true,
            });
          }
        }
      );
    } else {
      if (this.state.countWTCAI < this.state.records.length - 1) {
        this.setState(
          {
            isCustAcctNextLoading: true,
            countWTCAI: this.state.countWTCAI + 1,
          },
          () => {
            this.setState({
              enablePrevWTCAI: true,
              singleData: this.state.records[this.state.countWTCAI],
              newCustomerName:
                this.state.records[this.state.countWTCAI].customerName,
                isCustAcctNextLoading: false
            });
            if (this.state.countWTCAI === this.state.records.length - 1) {
              this.setState({
                enablePrevWTCAI: true,
                enableNextWTCAI: false,
              });
            }
          }
        );
      }
    }
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="WEB/TMT DATABASE MAINTENANCE - Customer-Account Information"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                WEB/TMT DATABASE MAINTENANCE - Customer Account Information
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  Records {this.state.countWTCAI + 1} of{" "}
                  {this.state.records ? this.state.records.length : ""}
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="customerAccountId"
                          className="font-weight-bold"
                        >
                          Customer Accnt Id
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.customerAccountId}
                          className="form-control form-control-sm"
                          name="customerAccountId"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-customerAccountId"
                          placeholder="Customer Accnt Id"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="customerAccountNumber"
                          className="font-weight-bold"
                        >
                          Customer Accnt Num
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.custAcntCCNumber}
                          className="form-control form-control-sm"
                          name="customerAccountNumber"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-customerAccountNumber"
                          placeholder="Customer Accnt Num"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="customerName"
                          className="font-weight-bold"
                        >
                          Customer Name
                        </label>
                        <input
                          type="text"
                          maxLength="60"
                          value={this.state.singleData.customerName}
                          className="form-control form-control-sm"
                          name="customerName"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-customerName"
                          placeholder="Customer Name"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="createDate"
                          className="font-weight-bold"
                        >
                          Create Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.createDate}
                          className="form-control form-control-sm"
                          name="createDate"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-createDate"
                          placeholder="Create Date"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="activityDate"
                          className="font-weight-bold"
                        >
                          Activity Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.activityDate}
                          className="form-control form-control-sm"
                          name="activityDate"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-activityDate"
                          placeholder="Activity Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="updateDate"
                          className="font-weight-bold"
                        >
                          Update Date
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.updateDate}
                          className="form-control form-control-sm"
                          name="updateDate"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-updateDate"
                          placeholder="Update Date"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="experianCode"
                          className="font-weight-bold"
                        >
                          Experian Code
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.experianCode}
                          className="form-control form-control-sm"
                          name="experianCode"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-experianCode"
                          placeholder="Experian Code"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="experianDetailCode"
                          className="font-weight-bold"
                        >
                          Experian Detail Code
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.experianDetailCode}
                          className="form-control form-control-sm"
                          name="experianDetailCode"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-experianDetailCode"
                          placeholder="Experian Detail Code"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label
                          htmlFor="operatorId"
                          className="font-weight-bold"
                        >
                          Operator ID
                        </label>
                        <input
                          type="text"
                          value={this.state.singleData.operatorId}
                          className="form-control form-control-sm"
                          name="operatorId"
                          onChange={(e) => {
                            this.onChangeCustomerAccInfo(e);
                          }}
                          data-test="text-operatorId"
                          placeholder="Operator ID"
                          disabled
                        />
                      </div>
                      <div className="col-lg"></div>
                      <div className="col-lg"></div>
                      <div className="col-lg"></div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        {this.state.isCustAcctWebTmtLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleBackWebTmtMain}
                            data-test="button-webTmtMaint"
                            disabled={
                              this.state.isCustAcctUpdateLoading === true ||
                              this.state.isCustAcctWebTmtLoading === true ||
                              this.state.isCustAcctCustInfoLoading === true ||
                              this.state.isCustAcctNextLoading === true ||
                              this.state.isCustAcctPrevLoading === true
                            }
                          >
                            WEBTMT MAINT
                          </button>
                        )}
                        {this.state.isCustAcctUpdateLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleUpdateCustomerAccInformation}
                            data-test="button-update"
                            disabled={
                              !this.state.accessGrantedWrite ||
                              this.state.isCustAcctUpdateLoading === true ||
                              this.state.isCustAcctWebTmtLoading === true ||
                              this.state.isCustAcctCustInfoLoading === true ||
                              this.state.isCustAcctNextLoading === true ||
                              this.state.isCustAcctPrevLoading === true
                            }
                          >
                            UPDATE
                          </button>
                        )}
                        {this.state.isCustAcctPrevLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mr-2"
                            onClick={() => this.onHandleBufferData("prevWTCAI")}
                            data-test="WEBTMTAcctInfo-button-prev"
                            disabled={
                              !this.state.enablePrevWTCAI ||
                              this.state.isCustAcctUpdateLoading === true ||
                              this.state.isCustAcctWebTmtLoading === true ||
                              this.state.isCustAcctCustInfoLoading === true ||
                              this.state.isCustAcctNextLoading === true ||
                              this.state.isCustAcctPrevLoading === true
                            }
                          >
                            Previous
                          </button>
                        )}
                        {this.state.isCustAcctNextLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mr-2"
                            onClick={() => this.onHandleBufferData("nextWTCAI")}
                            data-test="WEBTMTAcctInfo-button-tcn"
                            disabled={
                              !this.state.enableNextWTCAI ||
                              this.state.isCustAcctUpdateLoading === true ||
                              this.state.isCustAcctWebTmtLoading === true ||
                              this.state.isCustAcctCustInfoLoading === true ||
                              this.state.isCustAcctNextLoading === true ||
                              this.state.isCustAcctPrevLoading === true
                            }
                          >
                            Next
                          </button>
                        )}
                        {this.state.isCustAcctCustInfoLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={
                              this.onHandleWebTmtDBMaintenanceCustomerInfo
                            }
                            data-test="btn-customerInfo"
                            disabled={
                              this.state.isCustAcctUpdateLoading === true ||
                              this.state.isCustAcctWebTmtLoading === true ||
                              this.state.isCustAcctCustInfoLoading === true ||
                              this.state.isCustAcctNextLoading === true ||
                              this.state.isCustAcctPrevLoading === true
                            }
                          >
                            CUSTOMER INFO
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="form-froup row">
                      <div className="col-lg-4" />
                      <div className="col-lg-4">
                        {this.state.submitStatusCustomerAccInformation ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgCustomerAccInformation}
                          </div>
                        )}
                        {this.state.submitStatusCustomerAccInformation ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgCustomerAccInformation}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebTmtCustomerAccountInformation;
