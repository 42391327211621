import React, { Component } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import Header from "../../../header/header";
import { getHeadersOrder } from "../../../utility/utility";

class ErrorQueueMaintenanceSearch extends Component {
  state = {
    eqmsQueueId: "",
    eqmsMtcn: "",
    submitStatusEqms: false,
    submitMessageEqms: "",
    isLoading: false
  };

  onChangeQueueIdEqms = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onChangeMtcnEqms = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^\d]/, ""),
    });
  };

  onHandleBackEqms = () => {
    this.props.history.push("/tmon-maintenance-details");
  };

  onValidateEqms = () => {
    if (this.state.eqmsQueueId === "" && this.state.eqmsMtcn === "") {
      this.setState({
        submitStatusEqms: true,
        submitMessageEqms: "Please Enter The Value For Queue Id Or MTCN",
      });
      return false;
    }
    if (this.state.eqmsMtcn !== "" && this.state.eqmsMtcn.length !== 16) {
      this.setState({
        submitStatusEqms: true,
        submitMessageEqms: "Value Of The MTCN Must Be Of 16 Characters",
      });
      return false;
    }
    return true;
  };
  onHandleSearchEqms = () => {
    if (!this.onValidateEqms()) {
      return;
    }
    this.setState({isLoading:true})
    axios
    .get(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/queuerecords`,
      {
        headers: {
          ...getHeadersOrder().headers,
          queueId:this.state.eqmsQueueId,
          mtcn: this.state.eqmsMtcn,
          pageNo: 0,
          pageSize: 1,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD 
        },
      }
    )
    .then((response) => {
      this.setState({isLoading:false})
      if(response.data.flag === 1) {
        // console.log(response.data);
        this.props.history.push({
          pathname: "/error-queue-maintenance-details",
          records: response.data.records,
          mtcn: this.state.eqmsMtcn,
          queueId: this.state.eqmsQueueId,
          totalNoOfRecords: response.data.totalNoOfRecords,
          state:this.props.location.state
        });
      } else {
        this.setState({
          submitStatusEqms: true,
          submitMessageEqms: response.data.message,
        });
      }
    });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="Error Queue" />
        <div className="container-fluid">
          <div className="card-body eqms-container">
            <h4 className="card-title main-heading ">
              ERROR QUEUE SEARCH SCREEN
            </h4>
            <div className="card ">
              <div className="card-header bg-dark text-white card-header-custom">
                ERROR QUEUE SEARCH
              </div>
              <div className="card-body card-body-custom">
                <form>
                <div className="form-group row">
                    <div className="col-lg">
                    <span className="font-weight-bold">PLEASE ENTER QUEUE ID (OR) MTCN</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="Queue ID" className="font-weight-bold">Queue ID</label>
                      <input
                        type="text"
                        maxLength="8"
                        value={this.state.eqmsQueueId}
                        className="form-control form-control-sm"
                        name="eqmsQueueId"
                        onChange={(e) => {
                          this.onChangeQueueIdEqms(e);
                        }}
                        data-test="text-eqmsQueueId"
                        placeholder="Queue ID"
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="MTCN" className="font-weight-bold">MTCN</label>
                      <input
                        type="text"
                        maxLength="16"
                        value={this.state.eqmsMtcn}
                        className="form-control form-control-sm"
                        name="eqmsMtcn"
                        onChange={(e) => {
                          this.onChangeMtcnEqms(e);
                        }}
                        data-test="text-eqmsMtcn"
                        placeholder="MTCN"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4" />
                    <div className="col-lg-4 text-center mar-bottom5">
                    {
                        this.state.isLoading ? 
                          <button className="btn btn-warning mar-right10" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          &nbsp;&nbsp;Loading...
                        </button>
                      :
                      !this.props.location.state.accessGrantedReadTMD1 ? (
                        <Button
                          type="button"
                          className="btn btn-warning btn-block"
                          disabled
                          data-test="button-searchD"
                        >
                         Search
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleSearchEqms}
                          data-test="button-searchE"
                        >
                         Search
                        </Button>
                      )
                    
                      }
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.onHandleBackEqms}
                        data-test="button-back"
                      >
                        TMON MAINT
                      </button>
                    </div>
                    <div className="col-lg-4"></div>
                  </div>
                  <div className="row mar-top10">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                      {this.state.submitStatusEqms && (
                        <div className="alert alert-danger alert-padding notification text-center">
                          {this.state.submitMessageEqms}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-2"></div>
                  </div>    
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ErrorQueueMaintenanceSearch;
