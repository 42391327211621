import React, { Component } from "react";
import axios from "axios";
import ReactExport from "react-data-export";
import Header from "../../header/header";
import { getHeadersTipLog, getTiplogData } from "../../utility/utility";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class TipLogAuditReport extends Component {
  state = {
    displayRecordsTLAR: [],
    stateDataTLAR:null,
    disableFirstTLAR: true,
    disableLastTLAR: false,
    disablePreviousTLAR: true,
    disableNextTLAR: false,
    noOfRecordsPerPageTLAR: 0,
    totalNoofRecordsTLAR:0,
    pageNumberTLAR:1,
    totalNumberOfPagesTLAR:0,
    isLoadingPrev: false,
    isLoadingNext: false,
    isLoadingFirst: false,
    isLoadingLast: false,
  };

  componentDidMount() {
    
    this.setState({
      stateDataTLAR: this.props.history.location.submittedData,
      totalNoofRecordsTLAR: this.props.history.location.totalNoOfRecordsTLS,
      noOfRecordsPerPageTLAR: this.props.history.location.submittedData.pageRecordsTLS,
      totalNumberOfPagesTLAR: Math.ceil(this.props.history.location.totalNoOfRecordsTLS / this.props.history.location.submittedData.pageRecordsTLS) 
    });
    
    if(this.props.history.location.records && this.props.history.location.records.length > 0) {
      this.setState({
        displayRecordsTLAR: this.props.history.location.records
      });
      if(this.props.history.location.totalNoOfRecordsTLS <= this.props.history.location.submittedData.pageRecordsTLS) {
        this.setState({
          disableLastTLAR: true,
          disableNextTLAR: true
        });
      }
    } else {
      this.setState({
        disableLastTLAR: true,
        disableNextTLAR: true
      });
    }
  }

  onHandleBackTLAR = () => {
    this.props.history.push({
      pathname: "/tip-log-search",
      stateData: this.state.stateDataTLAR
    });
  };

  onHandleFirstTLAR = () => {
    this.setState({
      pageNumberTLAR: 1
    }, ()=> {
      this.setState({
        disableFirstTLAR: true,
        disablePreviousTLAR: true,
        disableNextTLAR: false,
        disableLastTLAR: false
      });
      this.getTipLogAuditReportData('isLoadingFirst');
    });
  };

  onHandlePreviousTLAR = () => {
    // console.log("Previous");
    this.setState({
      pageNumberTLAR: this.state.pageNumberTLAR - 1,
    }, () => {
      if(this.state.pageNumberTLAR === 1) {
        this.setState({
          disableFirstTLAR: true,
          disablePreviousTLAR: true,
          disableNextTLAR: false,
          disableLastTLAR: false
        });
      } else {
        this.setState({
          disableNextTLAR: false,
          disableLastTLAR: false
        });
      }
      this.getTipLogAuditReportData('isLoadingPrev');
    });
  };

  onHandleNextTLAR = () => {
    this.setState({
      pageNumberTLAR: this.state.pageNumberTLAR + 1,
    }, () => {
      // console.log(this.state.pageNumberTLAR+' '+this.state.totalNumberOfPagesTLAR);
      
      if(this.state.pageNumberTLAR < this.state.totalNumberOfPagesTLAR) {
        this.setState({
          disableFirstTLAR: false,
          disablePreviousTLAR: false
        });
      } else {
        this.setState({
          disableNextTLAR: true,
          disableLastTLAR: true,
          disableFirstTLAR: false,
          disablePreviousTLAR: false
        });
      }
      this.getTipLogAuditReportData('isLoadingNext');
    });
  };

  onHandleLastTLAR = () => {
    // console.log("Last");
    this.setState({
      pageNumberTLAR: this.state.totalNumberOfPagesTLAR,
    }, ()=> {
      this.setState({
        disableFirstTLAR: false,
        disablePreviousTLAR: false,
        disableNextTLAR: true,
        disableLastTLAR: true
      });
      this.getTipLogAuditReportData('isLoadingLast');
    });
  };

  getTipLogAuditReportData = (buttonType) => {
    let submittedObjTLAR = {};
    submittedObjTLAR.searchType = this.state.stateDataTLAR.searchTypeTLS;
    submittedObjTLAR.searchKey = this.state.stateDataTLAR.searchKeyTLS;
    submittedObjTLAR.operatorId = this.state.stateDataTLAR.operatorIdTLS;
    submittedObjTLAR.startDate = this.state.stateDataTLAR.startDateTLS;
    submittedObjTLAR.endDate = this.state.stateDataTLAR.endDateTLS;
    submittedObjTLAR.table = this.state.stateDataTLAR.tableType;
    submittedObjTLAR.pageRecords = this.state.stateDataTLAR.pageRecordsTLS;
    submittedObjTLAR.pageNumber = this.state.pageNumberTLAR;
    this.buttonEnableDisable(buttonType, true);
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_TIPLOG_SERVICE_URL}/v1/sha/dbview/tiplog/logsearch`,submittedObjTLAR, 
        {
          headers: {
            ...getHeadersTipLog().headers,
            Authorization: this.props.history.location.submittedData.token,
            userId:this.props.history.location.submittedData.operatorIdTL
          },
        }
      )
      .then((response) => {
        this.buttonEnableDisable(buttonType, false);
         this.setState({
          displayRecordsTLAR: getTiplogData(this.state.stateDataTLAR.searchTypeTLS,response.data)
        });
      });
  }

  buttonEnableDisable = (buttonType, status) => {
    this.setState({
      [buttonType]: status
    })
  }

  tipLogAuditData = () => {
    let dataTypeTLAR = this.props.history.location.submittedData.searchTypeTLS;
    // console.log(this.state.displayRecordsTLAR.length);
    return this.state.displayRecordsTLAR.length > 0 ? this.state.displayRecordsTLAR.map((record, i) => {
      // console.log(record.amount);
      if(dataTypeTLAR === 'ART') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td><td className="text-nowrap">{record.date}</td><td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td><td className="text-nowrap">{record.orgin}</td><td className="text-nowrap">{record.destination}</td>
        <td className="text-nowrap">{record.type}</td><td className="text-nowrap">{record.reason}</td><td className="text-nowrap">{record.account}</td>
        <td className="text-nowrap">{record.refAcct}</td><td className="text-nowrap">{record.adjReq}</td><td className="text-nowrap">{record.drfReq}</td>
        <td className="text-nowrap">{record.notReq}</td><td className="text-nowrap">{record.chgDate}</td><td className="text-nowrap">{record.operatorId2}</td>
        <td className="text-nowrap">{record.siteId}</td>
      </tr>)
      } else if(dataTypeTLAR === 'FPF') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td><td className="text-nowrap">{record.date}</td><td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td><td className="text-nowrap">{record.timestamp}</td><td className="text-nowrap">{record.seqNo}</td>
        <td className="text-nowrap">{record.startDate}</td><td className="text-nowrap">{record.endDate}</td><td className="text-nowrap">{record.filType}</td>
        <td className="text-nowrap">{record.channel}</td><td className="text-nowrap">{record.wc}</td><td className="text-nowrap">{record.d2b}</td>
        <td className="text-nowrap">{record.h2h}</td><td className="text-nowrap">{record.stg}</td><td className="text-nowrap">{record.qch}</td>
        <td className="text-nowrap">{record.abmt}</td><td className="text-nowrap">{record.site}</td><td className="text-nowrap">{record.sndrCountry}</td>
        <td className="text-nowrap">{record.sndrState}</td><td className="text-nowrap">{record.rcvrCountry}</td><td className="text-nowrap">{record.rcvrState}</td>
        <td className="text-nowrap">{record.phone}</td><td className="text-nowrap">{record.agent}</td><td className="text-nowrap">{record.network}</td>
        <td className="text-nowrap">{record.lowLimit}</td><td className="text-nowrap">{record.upLimit}</td><td className="text-nowrap">{record.act}</td>
        <td className="text-nowrap">{record.dmin}</td><td className="text-nowrap">{record.comments}</td><td className="text-nowrap">{record.operatorId2}</td>
        <td className="text-nowrap">{record.term}</td>
        <td className="text-nowrap">{record.queue}</td>
        <td className="text-nowrap">{record.mntr}</td>
        <td className="text-nowrap">{record.addr}</td>
        <td className="text-nowrap">{record.acct}</td>
        <td className="text-nowrap">{record.client}</td>
        <td className="text-nowrap">{record.rule1}</td>
        <td className="text-nowrap">{record.rule2}</td>
        <td className="text-nowrap">{record.famt}</td>
        <td className="text-nowrap">{record.msgInd}</td>
        <td className="text-nowrap">{record.message}</td>
      </tr>)
      } else if(dataTypeTLAR === 'FPFC') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.timestamp}</td>
        <td className="text-nowrap">{record.seqNo}</td>
        <td className="text-nowrap">{record.startDate}</td>
        <td className="text-nowrap">{record.endDate}</td>
        <td className="text-nowrap">{record.filType}</td>
        <td className="text-nowrap">{record.channel}</td>
        <td className="text-nowrap">{record.wc}</td>
        <td className="text-nowrap">{record.d2b}</td>
        <td className="text-nowrap">{record.h2h}</td>
        <td className="text-nowrap">{record.stg}</td>
        <td className="text-nowrap">{record.qch}</td>
        <td className="text-nowrap">{record.abmt}</td>
        <td className="text-nowrap">{record.site}</td>
        <td className="text-nowrap">{record.sndrCountry}</td>
        <td className="text-nowrap">{record.sndrState}</td>
        <td className="text-nowrap">{record.rcvrCountry}</td>
        <td className="text-nowrap">{record.rcvrState}</td>
        <td className="text-nowrap">{record.phone}</td>
        <td className="text-nowrap">{record.agent}</td>
        <td className="text-nowrap">{record.network}</td>
        <td className="text-nowrap">{record.lowLimit}</td>
        <td className="text-nowrap">{record.upLimit}</td>
        <td className="text-nowrap">{record.act}</td>
        <td className="text-nowrap">{record.dmin}</td>
        <td className="text-nowrap">{record.comments}</td>
        <td className="text-nowrap">{record.operatorId2}</td>
        <td className="text-nowrap">{record.term}</td>
        <td className="text-nowrap">{record.queue}</td>
        <td className="text-nowrap">{record.mntr}</td>
        <td className="text-nowrap">{record.addr}</td>
        <td className="text-nowrap">{record.acct}</td>
        <td className="text-nowrap">{record.client}</td>
        <td className="text-nowrap">{record.rule1}</td>
        <td className="text-nowrap">{record.rule2}</td>
        <td className="text-nowrap">{record.famt}</td>
        <td className="text-nowrap">{record.ruleId}</td>
        <td className="text-nowrap">{record.wlStInd}</td>
        <td className="text-nowrap">{record.msgInd}</td>
        <td className="text-nowrap">{record.message}</td>
        
      </tr>)
      } else if(dataTypeTLAR === 'GSP') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.options}</td>
        <td className="text-nowrap">{record.host}</td>
      </tr>)
      } else if(dataTypeTLAR === 'GRP') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.group}</td>
        <td className="text-nowrap">{record.description}</td>
        <td className="text-nowrap">{record.queueInd}</td>
      </tr>)
      } else if(dataTypeTLAR === 'QUE') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.queue}</td>
        <td className="text-nowrap">{record.description}</td>
        <td className="text-nowrap">{record.group}</td>
        <td className="text-nowrap">{record.callback}</td>
        <td className="text-nowrap">{record.ret}</td>
        <td className="text-nowrap">{record.rel}</td>
        <td className="text-nowrap">{record.ttq}</td>
        <td className="text-nowrap">{record.wc}</td>
        <td className="text-nowrap">{record.dt}</td>
        <td className="text-nowrap">{record.wflow}</td>
      </tr>)
      } else if(dataTypeTLAR === 'RULE') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.authFlag}</td>
        <td className="text-nowrap">{record.site}</td>
        <td className="text-nowrap">{record.acctStart}</td>
        <td className="text-nowrap">{record.AcctEnd}</td>
        <td className="text-nowrap">{record.alias}</td>
      </tr>)
      } else if(dataTypeTLAR === 'SITE') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.site}</td>
        <td className="text-nowrap">{record.sequence}</td>
        <td className="text-nowrap">{record.queue}</td>
      </tr>)
      } else if(dataTypeTLAR === 'TMNS') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.recId}</td>
        <td className="text-nowrap">{record.param1}</td>
        <td className="text-nowrap">{record.param2}</td>
        <td className="text-nowrap">{record.nfed}</td>
        <td className="text-nowrap">{record.pAdr1}</td>
        <td className="text-nowrap">{record.pAdr2}</td>
        <td className="text-nowrap">{record.pAdr3}</td>
        <td className="text-nowrap">{record.pAdr4}</td>
        <td className="text-nowrap">{record.t3Int}</td>
        <td className="text-nowrap">{record.t5Int}</td>
        <td className="text-nowrap">{record.ddf}</td>
        <td className="text-nowrap">{record.rdf}</td>
        <td className="text-nowrap">{record.recnum}</td>
        <td className="text-nowrap">{record.pinMin}</td>
        <td className="text-nowrap">{record.dFlag}</td>
        <td className="text-nowrap">{record.hids}</td>
        <td className="text-nowrap">{record.bFlag}</td>
        <td className="text-nowrap">{record.usddamt}</td>
        <td className="text-nowrap">{record.uscolamt}</td>
        <td className="text-nowrap">{record.uscashamt}</td>
        <td className="text-nowrap">{record.itddamt}</td>
        <td className="text-nowrap">{record.itcolamt}</td>
        <td className="text-nowrap">{record.itcashamt}</td>
        <td className="text-nowrap">{record.overamt}</td>
        <td className="text-nowrap">{record.tstqamt}</td>
        <td className="text-nowrap">{record.payamt}</td>
        <td className="text-nowrap">{record.fxFlg}</td>
        <td className="text-nowrap">{record.sw}</td>
        <td className="text-nowrap">{record.subSys}</td>
        <td className="text-nowrap">{record.dpFlg}</td>
        <td className="text-nowrap">{record.loadDT11}</td>
        <td className="text-nowrap">{record.loadDT12}</td>
        <td className="text-nowrap">{record.loadDT13}</td>
        <td className="text-nowrap">{record.loadTM11}</td>
        <td className="text-nowrap">{record.loadTM12}</td>
        <td className="text-nowrap">{record.loadTM13}</td>
        <td className="text-nowrap">{record.loadDT21}</td>
        <td className="text-nowrap">{record.loadDT22}</td>
        <td className="text-nowrap">{record.loadDT23}</td>
        <td className="text-nowrap">{record.loadTM21}</td>
        <td className="text-nowrap">{record.loadTM22}</td>
        <td className="text-nowrap">{record.loadTM23}</td>
        <td className="text-nowrap">{record.dLog}</td>
        <td className="text-nowrap">{record.eFlg}</td>
      </tr>)
      } else if(dataTypeTLAR === 'TMNP') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.tcode}</td>
        <td className="text-nowrap">{record.recNum}</td>
        <td className="text-nowrap">{record.gprCd}</td>
        <td className="text-nowrap">{record.dflg}</td>
        <td className="text-nowrap">{record.dlvl}</td>
        <td className="text-nowrap">{record.cd1}</td>
        <td className="text-nowrap">{record.cd2}</td>
        <td className="text-nowrap">{record.sw1}</td>
        <td className="text-nowrap">{record.sw2}</td>
        <td className="text-nowrap">{record.sw3}</td>
        <td className="text-nowrap">{record.sw4}</td>
        <td className="text-nowrap">{record.sw5}</td>
        <td className="text-nowrap">{record.sw6}</td>
        <td className="text-nowrap">{record.sw7}</td>
        <td className="text-nowrap">{record.sw8}</td>
        <td className="text-nowrap">{record.sw9}</td>
        <td className="text-nowrap">{record.sw10}</td>
        <td className="text-nowrap">{record.sw11}</td>
        <td className="text-nowrap">{record.sw12}</td>
        <td className="text-nowrap">{record.amount}</td>
      </tr>)
      } else if(dataTypeTLAR === 'TMNG') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.param1}</td>
        <td className="text-nowrap">{record.param2}</td>
        <td className="text-nowrap">{record.paddress}</td>
        <td className="text-nowrap">{record.t3Int}</td>
        <td className="text-nowrap">{record.t5Int}</td>
        <td className="text-nowrap">{record.ddf}</td>
        <td className="text-nowrap">{record.rdf}</td>
        <td className="text-nowrap">{record.pinMin}</td>
        <td className="text-nowrap">{record.dflag}</td>
        <td className="text-nowrap">{record.hids}</td>
        <td className="text-nowrap">{record.bflag}</td>
        <td className="text-nowrap">{record.itddamt}</td>
        <td className="text-nowrap">{record.itcolamt}</td>
        <td className="text-nowrap">{record.itcashamt}</td>
        <td className="text-nowrap">{record.usddamt}</td>
        <td className="text-nowrap">{record.uscolamt}</td>
        <td className="text-nowrap">{record.uscashamt}</td>
        <td className="text-nowrap">{record.overamt}</td>
        <td className="text-nowrap">{record.tstqamt}</td>
        <td className="text-nowrap">{record.payamt}</td>
        <td className="text-nowrap">{record.fxFlg}</td>
        <td className="text-nowrap">{record.subSys}</td>
        <td className="text-nowrap">{record.pcpq}</td>
        <td className="text-nowrap">{record.dupq}</td>
        <td className="text-nowrap">{record.dlog}</td>
        <td className="text-nowrap">{record.elog}</td>
        <td className="text-nowrap">{record.nfed}</td>
      </tr>)
      } else if(dataTypeTLAR === 'CCMT') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.rid}</td>
        <td className="text-nowrap">{record.rev}</td>
        <td className="text-nowrap">{record.site}</td>
        <td className="text-nowrap">{record.id}</td>
        <td className="text-nowrap">{record.name}</td>
        <td className="text-nowrap">{record.rights1}</td>
        <td className="text-nowrap">{record.rights2}</td>
        <td className="text-nowrap">{record.operatorId2}</td>
        <td className="text-nowrap">{record.chgFlg}</td>
        <td className="text-nowrap">{record.uptd}</td>
        <td className="text-nowrap">{record.retryCnt}</td>
        <td className="text-nowrap">{record.rtryLckTs}</td>
      </tr>)
      } else if(dataTypeTLAR === 'IRP') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.clientId}</td>
        <td className="text-nowrap">{record.ClientName}</td>
        <td className="text-nowrap">{record.type}</td>
        <td className="text-nowrap">{record.cpc}</td>
        <td className="text-nowrap">{record.stdFee}</td>
        <td className="text-nowrap">{record.mm}</td>
        <td className="text-nowrap">{record.dd}</td>
        <td className="text-nowrap">{record.ccyy}</td>
        <td className="text-nowrap">{record.updDT}</td>
        <td className="text-nowrap">{record.oper}</td>
        <td className="text-nowrap">{record.act}</td>
        <td className="text-nowrap">{record.cieling}</td>
        <td className="text-nowrap">{record.tierFee}</td>
        <td className="text-nowrap">{record.tierPct}</td>
        <td className="text-nowrap">{record.posFee}</td>
        <td className="text-nowrap">{record.posPct}</td>
      </tr>)
      } else if(dataTypeTLAR === 'CM03') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td><td className="text-nowrap">{record.date}</td><td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td><td className="text-nowrap">{record.idAlp}</td><td className="text-nowrap">{record.idNum}</td>
        <td className="text-nowrap">{record.tstq}</td><td className="text-nowrap">{record.rttp}</td><td className="text-nowrap">{record.snd}</td>
        <td className="text-nowrap">{record.rec}</td><td className="text-nowrap">{record.intra}</td><td className="text-nowrap">{record.dds}</td>
        <td className="text-nowrap">{record.time1}</td> <td className="text-nowrap">{record.time2}</td><td className="text-nowrap">{record.time3}</td>
        <td className="text-nowrap">{record.time4}</td><td className="text-nowrap">{record.time5}</td><td className="text-nowrap">{record.time6}</td>
        <td className="text-nowrap">{record.time7}</td><td className="text-nowrap">{record.time8}</td><td className="text-nowrap">{record.time9}</td>
        <td className="text-nowrap">{record.time10}</td><td className="text-nowrap">{record.route}</td><td className="text-nowrap">{record.qcMax}</td>
        <td className="text-nowrap">{record.poMax}</td><td className="text-nowrap">{record.intlMax}</td><td className="text-nowrap">{record.ccAuthQ}</td>
        <td className="text-nowrap">{record.xrate}</td><td className="text-nowrap">{record.arate}</td><td className="text-nowrap">{record.fee1}</td>
        <td className="text-nowrap">{record.fee2}</td><td className="text-nowrap">{record.bwtTo}</td><td className="text-nowrap">{record.bwtFrm}</td>
        <td className="text-nowrap">{record.name}</td><td className="text-nowrap">{record.tz}</td><td className="text-nowrap">{record.xrType}</td>
        <td className="text-nowrap">{record.carr}</td><td className="text-nowrap">{record.curr}</td><td className="text-nowrap">{record.serv}</td>
        <td className="text-nowrap">{record.bestBuy}</td><td className="text-nowrap">{record.iso}</td><td className="text-nowrap">{record.fxAdj}</td>
        <td className="text-nowrap">{record.purgePrd}</td><td className="text-nowrap">{record.usdMax}</td>
      </tr>)
      } else if(dataTypeTLAR === 'CM04') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.acode}</td>
        <td className="text-nowrap">{record.ccode}</td>
        <td className="text-nowrap">{record.ruc}</td>
        <td className="text-nowrap">{record.stCd}</td>
        <td className="text-nowrap">{record.cd1}</td>
        <td className="text-nowrap">{record.cd2}</td>
        
      </tr>)
      } else if(dataTypeTLAR === 'CM06') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>

        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.idAlp}</td><td className="text-nowrap">{record.idNum}</td>
        <td className="text-nowrap">{record.tstq}</td><td className="text-nowrap">{record.rttp}</td>

        <td className="text-nowrap">{record.snd}</td> <td className="text-nowrap">{record.rec}</td>
        <td className="text-nowrap">{record.intra}</td><td className="text-nowrap">{record.dds}</td>
        <td className="text-nowrap">{record.time1}</td><td className="text-nowrap">{record.time2}</td>

        <td className="text-nowrap">{record.time3}</td>
        <td className="text-nowrap">{record.time4}</td>
        <td className="text-nowrap">{record.time5}</td>
        <td className="text-nowrap">{record.time6}</td>
        <td className="text-nowrap">{record.time7}</td>
        <td className="text-nowrap">{record.time8}</td>
        <td className="text-nowrap">{record.time9}</td>
        <td className="text-nowrap">{record.time10}</td>
        <td className="text-nowrap">{record.route}</td>
        <td className="text-nowrap">{record.qcMax}</td>
        <td className="text-nowrap">{record.poMax}</td>
        <td className="text-nowrap">{record.intlMax}</td>
        <td className="text-nowrap">{record.ccAuthQ}</td>
        <td className="text-nowrap">{record.xrate}</td>
        <td className="text-nowrap">{record.arate}</td>
        <td className="text-nowrap">{record.fee1}</td>
        <td className="text-nowrap">{record.fee2}</td>
        <td className="text-nowrap">{record.rdTo}</td>
        <td className="text-nowrap">{record.bwtTo}</td>
        <td className="text-nowrap">{record.bwtFrm}</td>
        <td className="text-nowrap">{record.name}</td>
        <td className="text-nowrap">{record.tz}</td>
        <td className="text-nowrap">{record.xrType}</td>
        <td className="text-nowrap">{record.carr}</td>
        <td className="text-nowrap">{record.curr}</td>
        <td className="text-nowrap">{record.serv}</td>
        <td className="text-nowrap">{record.bestBuy}</td>
        <td className="text-nowrap">{record.iso}</td>
        <td className="text-nowrap">{record.fxAdj}</td>
        <td className="text-nowrap">{record.purgePrd}</td>
        <td className="text-nowrap">{record.usdMax}</td>        
      </tr>)
      } else if(dataTypeTLAR === 'CM07') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.fmto}</td>
        <td className="text-nowrap">{record.fee}</td>
        <td className="text-nowrap">{record.cfStatus}</td>
		    <td className="text-nowrap">{record.cntyFil}</td>
        <td className="text-nowrap">{record.utd}</td>
        <td className="text-nowrap">{record.operatorId2}</td>
        <td className="text-nowrap">{record.status}</td>
        <td className="text-nowrap">{record.tid}</td>
        <td className="text-nowrap">{record.fil1}</td>
        <td className="text-nowrap">{record.feeTrig}</td>
        <td className="text-nowrap">{record.amtTrig}</td>
        <td className="text-nowrap">{record.cntfil}</td>               
      </tr>)
      } else if(dataTypeTLAR === 'CM09') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.cname}</td>
        <td className="text-nowrap">{record.service}</td>
        <td className="text-nowrap">{record.address1}</td>
		    <td className="text-nowrap">{record.address2}</td>
        <td className="text-nowrap">{record.address3}</td>
        <td className="text-nowrap">{record.address4}</td>              
      </tr>)
      } else if(dataTypeTLAR === 'CM10') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
	      <td className="text-nowrap">{record.sndCC}</td>
        <td className="text-nowrap">{record.rcvCC}</td>
        <td className="text-nowrap">{record.sdate}</td>
        <td className="text-nowrap">{record.edate}</td>
		    <td className="text-nowrap">{record.dm0}</td>
        <td className="text-nowrap">{record.dm1}</td>
        <td className="text-nowrap">{record.dm2}</td>                
        <td className="text-nowrap">{record.dm3}</td>                
        <td className="text-nowrap">{record.dm4}</td>                
        <td className="text-nowrap">{record.dm5}</td>                
        <td className="text-nowrap">{record.dm6}</td>                
        <td className="text-nowrap">{record.dm7}</td>                
        <td className="text-nowrap">{record.dm8}</td>                
        <td className="text-nowrap">{record.dm9}</td>                
      </tr>)
      } else if(dataTypeTLAR === 'CM12') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.cityName}</td>
        <td className="text-nowrap">{record.cityCode}</td>
        <td className="text-nowrap">{record.ff1}</td>
		    <td className="text-nowrap">{record.ff2}</td>
        <td className="text-nowrap">{record.ff3}</td>
        <td className="text-nowrap">{record.ff4}</td>                
        <td className="text-nowrap">{record.ff5}</td>                
        <td className="text-nowrap">{record.ff6}</td>                
        <td className="text-nowrap">{record.ff7}</td>                
        <td className="text-nowrap">{record.ff8}</td>                
        <td className="text-nowrap">{record.ff9}</td>                
        <td className="text-nowrap">{record.ff10}</td>                
        <td className="text-nowrap">{record.ff11}</td>                
        <td className="text-nowrap">{record.ff12}</td>                
        <td className="text-nowrap">{record.ff13}</td>                
        <td className="text-nowrap">{record.ff14}</td>                
        <td className="text-nowrap">{record.ff15}</td>                
        <td className="text-nowrap">{record.flag}</td>                
      </tr>)
      } else if(dataTypeTLAR === 'CM13') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.orgCountry}</td>
        <td className="text-nowrap">{record.desCountry}</td>
        <td className="text-nowrap">{record.fix}</td>
		    <td className="text-nowrap">{record.flag}</td>
        <td className="text-nowrap">{record.operatorId2}</td>
        <td className="text-nowrap">{record.term}</td>                
        <td className="text-nowrap">{record.site}</td>                
        <td className="text-nowrap">{record.dateTime}</td>                
        <td className="text-nowrap">{record.errorMsg}</td>                        
      </tr>)
      } else if(dataTypeTLAR === 'CM14') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
	    	<td className="text-nowrap">{record.country}</td>
        <td className="text-nowrap">{record.option1}</td>
        <td className="text-nowrap">{record.option2}</td>
		    <td className="text-nowrap">{record.option3}</td>
        <td className="text-nowrap">{record.option4}</td>
        <td className="text-nowrap">{record.option5}</td>                
        <td className="text-nowrap">{record.option6}</td>                
        <td className="text-nowrap">{record.option7}</td>                
        <td className="text-nowrap">{record.option8}</td>                        
        <td className="text-nowrap">{record.option9}</td>                        
        <td className="text-nowrap">{record.option10}</td>                        
        <td className="text-nowrap">{record.option11}</td>                        
        <td className="text-nowrap">{record.option12}</td>                        
        <td className="text-nowrap">{record.option13}</td>                        
        <td className="text-nowrap">{record.option14}</td>                        
        <td className="text-nowrap">{record.option15}</td>                        
        <td className="text-nowrap">{record.option16}</td>                        
        <td className="text-nowrap">{record.option17}</td>                        
        <td className="text-nowrap">{record.option18}</td>                        
        <td className="text-nowrap">{record.option19}</td>                        
        <td className="text-nowrap">{record.option20}</td>                        
      </tr>)
      } else if(dataTypeTLAR === 'PTNR') {
        return (<tr key={`${i}+len`}>
          <td className="text-nowrap">{record.operatorId}</td>
          <td className="text-nowrap">{record.date}</td>
          <td className="text-nowrap">{record.action}</td>
          <td className="text-nowrap">{record.key}</td>
          <td className="text-nowrap">{record.network}</td>
          <td className="text-nowrap">{record.stateCurtain}</td>
          <td className="text-nowrap">{record.networkCurtain}</td>
          <td className="text-nowrap">{record.payHoldTime}</td>
          <td className="text-nowrap">{record.storeHoldTime}</td>
          <td className="text-nowrap">{record.locationCurtain}</td>
          <td className="text-nowrap">{record.fxRecalcSw}</td>
        </tr>)
      } else if(dataTypeTLAR === 'WUDP') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.deliService}</td>
        <td className="text-nowrap">{record.isoCode}</td>
        <td className="text-nowrap">{record.network}</td>
        <td className="text-nowrap">{record.asstNetwork}</td>
        <td className="text-nowrap">{record.networkName}</td>
      </tr>)
      } else if(dataTypeTLAR === '0002') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.id}</td>
        <td className="text-nowrap">{record.name}</td>
        <td className="text-nowrap">{record.address}</td>
        <td className="text-nowrap">{record.phone}</td>
        <td className="text-nowrap">{record.psrc}</td>
        <td className="text-nowrap">{record.altPhone}</td>
        <td className="text-nowrap">{record.email}</td>
        <td className="text-nowrap">{record.dob}</td>
        <td className="text-nowrap">{record.tflag}</td>
        <td className="text-nowrap">{record.pin}</td>
        <td className="text-nowrap">{record.updDate}</td>
        <td className="text-nowrap">{record.updId}</td>
        <td className="text-nowrap">{record.rtCode}</td>
        <td className="text-nowrap">{record.vflag}</td>
        <td className="text-nowrap">{record.vldDT}</td>
        <td className="text-nowrap">{record.level}</td>
        <td className="text-nowrap">{record.creId}</td>
        <td className="text-nowrap">{record.guarantee}</td>
      </tr>)
      } else if(dataTypeTLAR === '0003') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.mtcn}</td>
        <td className="text-nowrap">{record.acctNumber}</td>
        <td className="text-nowrap">{record.custID}</td>
        <td className="text-nowrap">{record.recDT}</td>
        <td className="text-nowrap">{record.amount}</td>
        <td className="text-nowrap">{record.curCD}</td>
        <td className="text-nowrap">{record.dcountry}</td>
        <td className="text-nowrap">{record.type}</td>
        <td className="text-nowrap">{record.score}</td>
        <td className="text-nowrap">{record.athCd}</td>
        <td className="text-nowrap">{record.avs}</td>
        <td className="text-nowrap">{record.cvv}</td>
        <td className="text-nowrap">{record.pin}</td>
        <td className="text-nowrap">{record.cbFlag}</td>
        <td className="text-nowrap">{record.cbDT}</td>
        <td className="text-nowrap">{record.phone}</td>
        <td className="text-nowrap">{record.ani}</td>
        <td className="text-nowrap">{record.ipAddress}</td>
        <td className="text-nowrap">{record.recName}</td>
        <td className="text-nowrap">{record.updID}</td>
        <td className="text-nowrap">{record.updDT}</td>
        <td className="text-nowrap">{record.totUpd}</td>
        <td className="text-nowrap">{record.codex}</td>
        <td className="text-nowrap">{record.kRes}</td>
        <td className="text-nowrap">{record.oRes}</td>
        <td className="text-nowrap">{record.sgScore}</td>
        <td className="text-nowrap">{record.status}</td>
      </tr>)
      } else if(dataTypeTLAR === '0004') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.number}</td>
        <td className="text-nowrap">{record.type}</td>
        <td className="text-nowrap">{record.sinfo}</td>
        <td className="text-nowrap">{record.billAddress}</td>
        <td className="text-nowrap">{record.billPhone}</td>
        <td className="text-nowrap">{record.iso}</td>
        <td className="text-nowrap">{record.expDT}</td>
        <td className="text-nowrap">{record.tflag}</td>
        <td className="text-nowrap">{record.actDT}</td>
        <td className="text-nowrap">{record.creDT}</td>
        <td className="text-nowrap">{record.updDT}</td>
        <td className="text-nowrap">{record.updId}</td>
        <td className="text-nowrap">{record.updTD}</td>
        <td className="text-nowrap">{record.totUpd}</td>
      </tr>)
      } else if(dataTypeTLAR === '0005') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.key2}</td>
        <td className="text-nowrap">{record.custId}</td>
        <td className="text-nowrap">{record.actNumber}</td>
        <td className="text-nowrap">{record.btName}</td>
        <td className="text-nowrap">{record.creDT}</td>
        <td className="text-nowrap">{record.actDT}</td>
        <td className="text-nowrap">{record.updDT}</td>
        <td className="text-nowrap">{record.updId}</td>
        <td className="text-nowrap">{record.updTD}</td>
        <td className="text-nowrap">{record.totUpd}</td>
      </tr>)
      } else if(dataTypeTLAR === 'BRMT') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.code}</td>
        <td className="text-nowrap">{record.name}</td>
        <td className="text-nowrap">{record.iso}</td>
        <td className="text-nowrap">{record.days}</td>
        <td className="text-nowrap">{record.lnew}</td>
        <td className="text-nowrap">{record.ldays}</td>
        <td className="text-nowrap">{record.ldaily}</td>
        <td className="text-nowrap">{record.vtran}</td>
        <td className="text-nowrap">{record.vday}</td>
        <td className="text-nowrap">{record.bp}</td>
		    <td className="text-nowrap">{record.bpRc}</td>
        <td className="text-nowrap">{record.bpAmt}</td>
        <td className="text-nowrap">{record.bpCvv}</td>
        <td className="text-nowrap">{record.req}</td>
        <td className="text-nowrap">{record.reqTran}</td>
        <td className="text-nowrap">{record.reqAA}</td>
        <td className="text-nowrap">{record.cvv}</td>
        <td className="text-nowrap">{record.avs}</td>
		    <td className="text-nowrap">{record.defRule}</td>
        <td className="text-nowrap">{record.sat}</td>
        <td className="text-nowrap">{record.exp}</td>
        <td className="text-nowrap">{record.ibp}</td>
        <td className="text-nowrap">{record.lc}</td>
        <td className="text-nowrap">{record.queue}</td>
        <td className="text-nowrap">{record.prodCD1}</td>
        <td className="text-nowrap">{record.merId1}</td>
		    <td className="text-nowrap">{record.pmtType1}</td>
        <td className="text-nowrap">{record.path1}</td>
        <td className="text-nowrap">{record.prodCD2}</td>
		    <td className="text-nowrap">{record.merId2}</td>
        <td className="text-nowrap">{record.pmtType2}</td>
        <td className="text-nowrap">{record.path2}</td>
        <td className="text-nowrap">{record.prodCD3}</td>
        <td className="text-nowrap">{record.merId3}</td>
        <td className="text-nowrap">{record.pmtType3}</td>
        <td className="text-nowrap">{record.path3}</td>
        <td className="text-nowrap">{record.prodCD4}</td>
		    <td className="text-nowrap">{record.merId4}</td>
        <td className="text-nowrap">{record.pmtType4}</td>
        <td className="text-nowrap">{record.path4}</td>
        <td className="text-nowrap">{record.prodCD5}</td>
        <td className="text-nowrap">{record.merId5}</td>
        <td className="text-nowrap">{record.pmtType5}</td>
        <td className="text-nowrap">{record.path5}</td>
        <td className="text-nowrap">{record.site1}</td>
		    <td className="text-nowrap">{record.start1}</td>
        <td className="text-nowrap">{record.end1}</td>
        <td className="text-nowrap">{record.alias1}</td>
		    <td className="text-nowrap">{record.site2}</td>
        <td className="text-nowrap">{record.start2}</td>
        <td className="text-nowrap">{record.end2}</td>
        <td className="text-nowrap">{record.alias2}</td>
        <td className="text-nowrap">{record.site3}</td>
        <td className="text-nowrap">{record.start3}</td>
        <td className="text-nowrap">{record.end3}</td>
        <td className="text-nowrap">{record.alias3}</td>
		    <td className="text-nowrap">{record.site4}</td>
        <td className="text-nowrap">{record.start4}</td>
        <td className="text-nowrap">{record.end4}</td>
        <td className="text-nowrap">{record.alias4}</td>
        <td className="text-nowrap">{record.email}</td>
        <td className="text-nowrap">{record.authRev}</td>
        <td className="text-nowrap">{record.euId}</td>
        <td className="text-nowrap">{record.cpc}</td>
		    <td className="text-nowrap">{record.date2}</td>
        <td className="text-nowrap">{record.vdays}</td>
        <td className="text-nowrap">{record.vlNew}</td>
        <td className="text-nowrap">{record.vlDays}</td>
        <td className="text-nowrap">{record.vlDaily}</td>
       
      </tr>)
      } else if(dataTypeTLAR === 'BRPT') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.agentAcct}</td>
        <td className="text-nowrap">{record.emailNumber}</td>
        <td className="text-nowrap">{record.payMethod}</td>
        <td className="text-nowrap">{record.partnerId}</td>
        <td className="text-nowrap">{record.partnerName}</td>
        <td className="text-nowrap">{record.sendCPC}</td>
        <td className="text-nowrap">{record.templatePref}</td>
        <td className="text-nowrap">{record.filler}</td>
      </tr>)
      } else if(dataTypeTLAR === 'MRCH') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.paymentGateway}</td>
        <td className="text-nowrap">{record.channel}</td>
        <td className="text-nowrap">{record.transType}</td>
        <td className="text-nowrap">{record.pmtMethod}</td>
        <td className="text-nowrap">{record.thirdPartyIden}</td>
        <td className="text-nowrap">{record.pmtEmulated}</td>
        <td className="text-nowrap">{record.billingID}</td>
        <td className="text-nowrap">{record.fillerField}</td>
      </tr>)
      } else if(dataTypeTLAR === 'JQCC') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.id}</td>
        <td className="text-nowrap">{record.version}</td>
        <td className="text-nowrap">{record.account}</td>
		    <td className="text-nowrap">{record.sosg}</td>
        <td className="text-nowrap">{record.name}</td>
        <td className="text-nowrap">{record.country}</td>                
        <td className="text-nowrap">{record.contact}</td>                
        <td className="text-nowrap">{record.phone}</td>                
        <td className="text-nowrap">{record.vruId}</td>                        
        <td className="text-nowrap">{record.spay}</td>                        
        <td className="text-nowrap">{record.epay}</td>                        
        <td className="text-nowrap">{record.epurg}</td>                        
        <td className="text-nowrap">{record.edisc}</td>                        
        <td className="text-nowrap">{record.efee}</td>                        
        <td className="text-nowrap">{record.rptTyp}</td>                        
        <td className="text-nowrap">{record.type}</td>                        
        <td className="text-nowrap">{record.ltype}</td>                        
        <td className="text-nowrap">{record.ofac}</td>                        
        <td className="text-nowrap">{record.dev1}</td>                        
        <td className="text-nowrap">{record.area1}</td>                        
        <td className="text-nowrap">{record.dev2}</td>                        
        <td className="text-nowrap">{record.area2}</td>                        
        <td className="text-nowrap">{record.devFlag}</td>                        
        <td className="text-nowrap">{record.cflag}</td>                        
        <td className="text-nowrap">{record.crte}</td>                        
        <td className="text-nowrap">{record.iflag}</td>                        
        <td className="text-nowrap">{record.irte}</td>                        
        <td className="text-nowrap">{record.grs}</td>                        
        <td className="text-nowrap">{record.srate}</td>                        
        <td className="text-nowrap">{record.rate}</td>                        
        <td className="text-nowrap">{record.grscan}</td>                        
        <td className="text-nowrap">{record.srcan}</td>                        
        <td className="text-nowrap">{record.rcan}</td>                        
        <td className="text-nowrap">{record.stat1}</td>                        
        <td className="text-nowrap">{record.btd1}</td>                        
        <td className="text-nowrap">{record.bstat2}</td>                        
        <td className="text-nowrap">{record.btd2}</td>                        
        <td className="text-nowrap">{record.bstat3}</td>                        
        <td className="text-nowrap">{record.btd3}</td>                        
        <td className="text-nowrap">{record.email}</td>                        
        <td className="text-nowrap">{record.acctCd}</td>                        
        <td className="text-nowrap">{record.fusInd}</td>                        
        <td className="text-nowrap">{record.status}</td>                        
        <td className="text-nowrap">{record.rpps}</td>                        
      </tr>)
      } else if(dataTypeTLAR === 'JFUS') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.id}</td>
        <td className="text-nowrap">{record.mv}</td>
        <td className="text-nowrap">{record.feemod}</td>
		    <td className="text-nowrap">{record.posTemp}</td>
        <td className="text-nowrap">{record.sod0}</td>
        <td className="text-nowrap">{record.sod1}</td>                
        <td className="text-nowrap">{record.sod2}</td>                
        <td className="text-nowrap">{record.sod3}</td>                
        <td className="text-nowrap">{record.wdInd}</td>                        
        <td className="text-nowrap">{record.mnot}</td>                        
        <td className="text-nowrap">{record.idly}</td>                        
        <td className="text-nowrap">{record.arFlag}</td>                        
        <td className="text-nowrap">{record.pcPayee}</td>                        
        <td className="text-nowrap">{record.idupq}</td>                        
        <td className="text-nowrap">{record.comments}</td>                        
        <td className="text-nowrap">{record.fusInd}</td>                        
        <td className="text-nowrap">{record.prepaid}</td>                        
        <td className="text-nowrap">{record.version}</td>                                            
      </tr>)
      } else if(dataTypeTLAR === 'JDEV') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		<td className="text-nowrap">{record.id}</td>
        <td className="text-nowrap">{record.qtype}</td>
        <td className="text-nowrap">{record.classVar}</td>
		<td className="text-nowrap">{record.area}</td>
        <td className="text-nowrap">{record.numad}</td>
        <td className="text-nowrap">{record.numdip}</td>                
        <td className="text-nowrap">{record.account}</td>                
        <td className="text-nowrap">{record.name}</td>                
        <td className="text-nowrap">{record.country}</td>                        
        <td className="text-nowrap">{record.contact}</td>                        
        <td className="text-nowrap">{record.phone}</td>                        
        <td className="text-nowrap">{record.maxPrn}</td>                        
        <td className="text-nowrap">{record.trace}</td>                        
        <td className="text-nowrap">{record.route1}</td>                        
        <td className="text-nowrap">{record.drftfm1}</td>                        
        <td className="text-nowrap">{record.type1}</td>                        
        <td className="text-nowrap">{record.status1}</td>                        
        <td className="text-nowrap">{record.rept1}</td>                                        
        <td className="text-nowrap">{record.route2}</td>                                        
        <td className="text-nowrap">{record.drftfm2}</td>                                        
        <td className="text-nowrap">{record.type2}</td>                                        
        <td className="text-nowrap">{record.status2}</td>                                        
        <td className="text-nowrap">{record.rept2}</td>                                        
        <td className="text-nowrap">{record.route3}</td>                                        
        <td className="text-nowrap">{record.drftfm3}</td>                                        
        <td className="text-nowrap">{record.type3}</td>                                        
        <td className="text-nowrap">{record.status3}</td>                                        
        <td className="text-nowrap">{record.rept3}</td>                                        
        <td className="text-nowrap">{record.route4}</td>                                        
        <td className="text-nowrap">{record.drftfm4}</td>                                        
        <td className="text-nowrap">{record.type4}</td>                                        
        <td className="text-nowrap">{record.status4}</td>                                        
        <td className="text-nowrap">{record.rept4}</td>                                        
        <td className="text-nowrap">{record.tz}</td>                                        
        <td className="text-nowrap">{record.dsth}</td>                                        
        <td className="text-nowrap">{record.ih}</td>                                        
        <td className="text-nowrap">{record.delay}</td>                                        
        <td className="text-nowrap">{record.clr}</td>                                        
        <td className="text-nowrap">{record.susDay}</td>                                        
        <td className="text-nowrap">{record.hopen1}</td>                                        
        <td className="text-nowrap">{record.hclose1}</td>                                        
        <td className="text-nowrap">{record.hopen2}</td>                                        
        <td className="text-nowrap">{record.hclose2}</td>                                        
        <td className="text-nowrap">{record.hopen3}</td>                                        
        <td className="text-nowrap">{record.hclose3}</td>                                        
        <td className="text-nowrap">{record.hopen4}</td>                                        
        <td className="text-nowrap">{record.hclose4}</td>                                        
        <td className="text-nowrap">{record.hopen5}</td>                                        
        <td className="text-nowrap">{record.hclose5}</td>                                        
        <td className="text-nowrap">{record.hopen6}</td>                                        
        <td className="text-nowrap">{record.hclose6}</td>                                        
        <td className="text-nowrap">{record.hopen7}</td>                                        
        <td className="text-nowrap">{record.hclose7}</td>                                        
        <td className="text-nowrap">{record.mdlvFlag}</td>                                        
        <td className="text-nowrap">{record.mdlvDaily}</td>                                                                             
      </tr>)
      } else if(dataTypeTLAR === 'FXDB') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		<td className="text-nowrap">{record.country}</td>
        <td className="text-nowrap">{record.currency}</td>
        <td className="text-nowrap">{record.iso}</td>
		<td className="text-nowrap">{record.type}</td>
        <td className="text-nowrap">{record.splAccount}</td>
        <td className="text-nowrap">{record.wuCode}</td>                
        <td className="text-nowrap">{record.wuRoute}</td>                
        <td className="text-nowrap">{record.countryName}</td>                
        <td className="text-nowrap">{record.currencyName}</td>                        
        <td className="text-nowrap">{record.status}</td>                        
        <td className="text-nowrap">{record.a2}</td>                        
        <td className="text-nowrap">{record.num_3166}</td>                        
        <td className="text-nowrap">{record.num_4217}</td>                        
        <td className="text-nowrap">{record.majorUnit}</td> 
      </tr>)
      } else if(dataTypeTLAR === 'FXSD') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		<td className="text-nowrap">{record.orginCountry}</td>
        <td className="text-nowrap">{record.orginCurrency}</td>
        <td className="text-nowrap">{record.acctType}</td>
		<td className="text-nowrap">{record.destCountry}</td>
        <td className="text-nowrap">{record.destCurrency}</td>
        <td className="text-nowrap">{record.acctId}</td>                
        <td className="text-nowrap">{record.splAccount}</td>                
        <td className="text-nowrap">{record.seqNumber}</td>                
        <td className="text-nowrap">{record.dPair}</td>                        
        <td className="text-nowrap">{record.defDisc}</td>                        
        <td className="text-nowrap">{record.prin1}</td>                        
        <td className="text-nowrap">{record.disc1}</td>                        
        <td className="text-nowrap">{record.prin2}</td>                        
        <td className="text-nowrap">{record.disc2}</td> 
        <td className="text-nowrap">{record.prin3}</td> 
        <td className="text-nowrap">{record.disc3}</td> 
        <td className="text-nowrap">{record.promoStart}</td> 
        <td className="text-nowrap">{record.promoEnd}</td> 
        <td className="text-nowrap">{record.prodCd}</td> 
        <td className="text-nowrap">{record.state}</td> 
        <td className="text-nowrap">{record.city}</td> 
        <td className="text-nowrap">{record.dma}</td> 
        <td className="text-nowrap">{record.network}</td> 
      </tr>)
      } else if(dataTypeTLAR === 'DQMT') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.mtcn}</td>
        <td className="text-nowrap">{record.deviceId}</td>
        <td className="text-nowrap">{record.type}</td>
      </tr>)
      } else if(dataTypeTLAR === 'JOVP') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.network}</td>
        <td className="text-nowrap">{record.account}</td>
        <td className="text-nowrap">{record.name}</td>
        <td className="text-nowrap">{record.batchInd}</td>
        <td className="text-nowrap">{record.d2bInd}</td>
        <td className="text-nowrap">{record.canInd}</td>
        <td className="text-nowrap">{record.queue}</td>
        <td className="text-nowrap">{record.homeDelivery}</td>
        <td className="text-nowrap">{record.amount}</td>
        <td className="text-nowrap">{record.wcInd}</td>
        <td className="text-nowrap">{record.wcQueue}</td>
        <td className="text-nowrap">{record.mcInd}</td>
        <td className="text-nowrap">{record.mcAccount}</td>
      </tr>)
      } else if(dataTypeTLAR === 'FCAL') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.userId}</td>
        <td className="text-nowrap">{record.hostId}</td>
      </tr>)
      } else if(dataTypeTLAR === 'WFLW' || dataTypeTLAR === 'QUEA') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.logData}</td>
      </tr>)
      } else if(dataTypeTLAR === 'ALL') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
		    <td className="text-nowrap">{record.data}</td>
      </tr>)
      } else if(dataTypeTLAR === '0001') {
        return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{record.operatorId}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.action}</td>
        <td className="text-nowrap">{record.key}</td>
        <td className="text-nowrap">{record.operatorId2}</td>
        {/* <td className="text-nowrap">{record.site}</td> */}
      </tr>)
      }
    })
    : <tr><td colSpan="50"><div className="text-center font-weight-bold">No Record Found</div></td></tr>;
  }

  getTipLogAuditHeaders = () => {
    let headerTypeTLAR = this.props.history.location.submittedData.searchTypeTLS;
    let headersTLAR = [];
    if(headerTypeTLAR === 'ART') {
      headersTLAR = ['OPER ID', 'DATE', 'ACTION', 'KEY', 'ORGIN', 'DESTINATION', 'TYPE', 'REASON', 'ACCOUNT', 'REF ACCT', 'ADJ REQ', 'DRF REQ', 'NOT REQ', 'CHG DT', 'OPER ID', 'SITE ID']
    } else if(headerTypeTLAR === 'FPF') {
      headersTLAR = ['OPER ID', 'DATE', 'ACTION', 'KEY', 'TIMESTAMP', 'SEQ NO', 'START DATE', 'END DATE', 'FIL TYPE', 'CHANNEL', 'WC', 'D2B', 'H2H', 'STG', 'QCH', 'ABMT','SITE','SCNTRY','SSTATE','RCNTRY','RSTATE','PHONE','AGENT','NETWORK','LOW LMT','UPP LMT','ACT','DMIN','COMMENTS','OPER','TERM','QUEUE','MNTR','ADDR','ACCT','CLIENT','RULE1','RULE2','F-AMT','MSG IND','MESSAGE']
    } else if(headerTypeTLAR === 'FPFC') {
      headersTLAR = ['OPER ID', 'DATE', 'ACTION', 'KEY', 'TIMESTAMP', 'SEQ NO', 'START DATE', 'END DATE', 'FIL TYPE', 'CHANNEL', 'WC', 'D2B', 'H2H', 'STG', 'QCH', 'ABMT','SITE','SCNTRY','SSTATE','RCNTRY','RSTATE','PHONE','AGENT','NETWORK','LOW LMT','UPP LMT','ACT','DMIN','COMMENTS','OPER','TERM','QUEUE','MNTR','ADDR','ACCT','CLIENT','RULE1','RULE2','F-AMT','RULEID','WLST IND','MSG IND','MESSAGE']
    } else if(headerTypeTLAR === 'GSP') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','OPTIONS','HOST'] 
    } else if(headerTypeTLAR === 'GRP') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','GROUP','DESCRIPTION','QUEUE IND']
    } else if(headerTypeTLAR === 'QUE') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','QUEUE','DESCRIPTION','GROUP','CALLBACK','RET','REL','TTQ','WC','DT','WFLOW']
    } else if(headerTypeTLAR === 'RULE') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','AUTH FLAG','SITE','ACCT START','ACCT END','ALIAS']
    } else if(headerTypeTLAR === 'SITE') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','SITE','SEQUENCE','QUEUE'] 
    } else if(headerTypeTLAR === 'TMNS') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','RECID','PARAM1','PARAM2','NFED','PADR1','PADR2','PADR3','PADR4','T3INT','T5INT','DDF','RDF','RECNUM','PINMIN','DFLG','HIDS','BFLG','USDDAMT','USCOLAMT','USCASHAMT','ITDDAMT','ITCOLAMT','ITCASHAMT','OVERAMT','TSTQAMT','PAYAMT','FXFLG','SW','SUBSYS','DPFLG','LOAD DT11','LOAD DT12','LOAD DT13','LOAD TM11','LOAD TM12','LOAD TM13','LOAD DT21','LOAD DT22','LOAD DT23','LOAD TM21','LOAD TM22','LOAD TM23','DLOG','EFLG']
    } else if(headerTypeTLAR === 'TMNP') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','TCODE','RECNUM','GPR CD','DFLG','DLVL','CD1','CD2','SW1','SW2','SW3','SW4','SW5','SW6','SW7','SW8','SW9','SW10','SW11','SW12','AMOUNT']
    } else if(headerTypeTLAR === 'TMNG') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','PARAM1','PARAM2','PADDRESS','T3INT','T5INT','DDF','RDF','PINMIN','DFLG','HIDS','BFLG','ITDDAMT','ITCOLAMT','ITCASHAMT','USDDAMT','USCOLAMT','USCASHAMT','OVERAMT','TSTQAMT','PAYAMT','FXFLG','SUBSYS','PCPQ','DUPQ','DLOG','ELOG','NFED']
    } else if(headerTypeTLAR === 'CCMT') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','RID','REV','SITE','ID','NAME','RIGHTS1','RIGHTS2','OPID','CHG_FLG','UPTD','RETRY CNT','RTRY LCK TS']
    } else if(headerTypeTLAR === 'IRP') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','CLIENT ID','CLIENT NAME','TYPE','CPC','STD FEE','MM','DD','CCYY','UPD DT','OPER','ACT','CIELING','TIER FEE','TIER PCT','POS FEE','POS PCT']
    } else if(headerTypeTLAR === 'CM03') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','ID ALP','ID NUM','TSTQ','RTTP','SND','REC','INTRA','DDS','TIME1','TIME2','TIME3','TIME4','TIME5','TIME6','TIME7','TIME8','TIME9','TIME10','ROUTE','QCMAX','POMAX','INTLMAX','CCAUTHQ','XRATE','ARATE','FEE1','FEE2','BWTTO','BWTFRM','NAME','TZ','XRTYPE','CARR','CURR','SERV','BEST BUY','ISO','FXADJ','PURGE PRD','USDMAX']
    } else if(headerTypeTLAR === 'CM04') {  
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','ACODE','CCODE','RUC','STCD','CD1','CD2'] 
    } else if(headerTypeTLAR === 'CM06') { 
        headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','ID ALP','ID NUM','TSTQ','RTTP','SND','REC','INTRA','DDS','TIME1','TIME2','TIME3','TIME4','TIME5','TIME6','TIME7','TIME8','TIME9','TIME10','ROUTE','QCMAX','POMAX','INTLMAX','CCAUTHQ','XRATE','ARATE','FEE1','FEE2','RDTO','BWTTO','BWTFRM','NAME','TZ','XRTYPE','CARR','CURR','SERV','BEST BUY','ISO','FXADJ','PURGE PRD','USDMAX']   	
    } else if(headerTypeTLAR === 'CM07') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','FMTO','FEE','CFSTATUS','CNTYFIL','UTD','OPID','STATUS','TID','FIL1','FEETRIG','AMTTRIG','CNTFIL']
    } else if(headerTypeTLAR === 'CM09') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','CNAME','SERVICE','ADDRESS1','ADDRESS2','ADDRESS3','ADDRESS4'] 
    } else if(headerTypeTLAR === 'CM10') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','SND CC','RCV CC','SDATE','EDATE','DM0','DM1','DM2','DM3','DM4','DM5','DM6','DM7','DM8','DM9']
    } else if(headerTypeTLAR === 'CM12') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','CTYNAME','CTYCODE','FF1','FF2','FF3','FF4','FF5','FF6','FF7','FF8','FF9','FF10','FF11','FF12','FF13','FF14','FF15','FLAG']
    } else if(headerTypeTLAR === 'CM13') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','OCNTY','DCNTY','FIX','FLG','OPER','TERM','SITE','DATE TIME','ERROR MSG']
    } else if(headerTypeTLAR === 'CM14') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','COUNTRY','OPT1','OPT2','OPT3','OPT4','OPT5','OPT6','OPT7','OPT8','OPT9','OPT10','OPT11','OPT12','OPT13','OPT14','OPT15','OPT16','OPT17','OPT18','OPT19','OPT20'] 
    } else if(headerTypeTLAR === '0001') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','OPER ID']
    } else if(headerTypeTLAR === '0002') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','ID','NAME','ADDRESS','PHONE','PSRC','ALT PHONE','EMAIL','DOB','TFLG','PIN','UPD DT','UPD ID','RT CODE','VFLG','VLD DT','LVL','CRE ID','GUARANTEE']
    } else if(headerTypeTLAR === '0003') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','MTCN','ACCT NBR','CUST ID','REC DT','AMOUNT','CUR CD','D CTRY','TYPE','SCORE','ATH CD','AVS','CVV','PIN','CB FLG','CB DT','PHONE','ANI','IP ADDR','REC NAME','UPD ID','UPD TD','TOT UPD','CODEX','K RES','O RES','SG SCORE','STATUS']
    } else if(headerTypeTLAR === '0004') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','NUMBER','TYPE','SINFO','BADDR','BPHONE','ISO','EXP DT','TFLG','ACT DT','CRE DT','UPD DT','UPD ID','UPD TD','TOT UPD']
    } else if(headerTypeTLAR === '0005') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','KEY','CUST ID','ACT NBR','BTNAME','CRE DT','ACT DT','UPD DT','UPD ID','UPD TD','TOT UPD']
    } else if(headerTypeTLAR === 'BRMT') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','CODE','NAME','ISO','Days','LNEW','LDAYS','LDAILY','VTRAN','VDAY','BP','BP RC','BP AMT','BP CVV','REQ','REQ TRAN','REQ AA','CVV','AVS','DEF RULE','SAT','EXP','IBP','LC','QUEUE','PROD CD1','MER ID1','PMT TYPE1','PATH1','PROD CD2','MER ID2','PMT TYPE2','PATH2','PROD CD3','MER ID3','PMT TYPE3','PATH3','PROD CD4','MER ID4','PMT TYPE4','PATH4','PROD CD5','MER ID5','PMT TYPE5','PATH5','SITE1','START1','END1','ALIAS1','SITE2','START2','END2','ALIAS2','SITE3','START3','END3','ALIAS3','SITE4','START4','END4','ALIAS4','EMAIL','AUTHREV','EUID','CPC','DATE','VDAYS','VLNEW','VLDAYS','VLDAILY']
    } else if(headerTypeTLAR === 'BRPT') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','Agent Acct','Email Num','Pay Method','Partner Id','Partner Name','Send CPC','Template Pref','Filler']
    } else if(headerTypeTLAR === 'MRCH') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','Pmt Gateway','Channel','Txn Type','Pmt Method','Third party Id','Pmt method emu','Billing ID','Filler']
    } else if(headerTypeTLAR === 'JQCC') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','ID','VERSION','ACCOUNT','SOSG','NAME','CNTRY','CNTACT','PHONE','VRUID','SPAY','EPAY','EPURG','EDISC','EFEE','RPTTYP','TYPE','LTYPE','OFAC','DEV1','AREA1','DEV2','AREA2','DEVFLG','CFLG','CRTE','IFLG','IRTE','GRS','SRATE','RATE','GRSCAN','SRCAN','RCAN','STAT1','BTD1','BSTAT2','BTD2','BSTAT3','BTD3','EMAIL','ACCTCD','FUS_IND','STATUS','RPPS']
    } else if(headerTypeTLAR === 'JFUS') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','ID','MV','FEEMOD','POSTEMP','SOD0','SOD1','SOD2','SOD3','WD_IND','MNOT','IDLY','AR_FLG','PCPAYEE','IDUPQ','COMMENTS','FUS_IND','PREPAID','VERSION']
    } else if(headerTypeTLAR === 'JDEV') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','ID','QTYPE','CLASS','AREA','NUMAD','NUMDIP','ACCOUNT','NAME','CNTRY','CNTACT','PHONE','MAXPRN','TRACE','ROUTE1','DRFTFM1','TYPE1','STATUS1','REPT1','ROUTE2','DRFTFM2','TYPE2','STATUS2','REPT2','ROUTE3','DRFTFM3','TYPE3','STATUS3','REPT3','ROUTE4','DRFTFM4','TYPE4','STATUS4','REPT4','TZ','DSTH','IH','DELAY','CLR','SUSDAY','HOPEN1','HCLOSE1','HOPEN2','HCLOSE2','HOPEN3','HCLOSE3','HOPEN4','HCLOSE4','HOPEN5','HCLOSE5','HOPEN6','HCLOSE6','HOPEN7','HCLOSE7','MDLVFLG','MDLVDLY']
    } else if(headerTypeTLAR === 'FXDB') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','CNTRY','CURR','ISO','TYPE','SPL ACCT','WU CODE','WU ROUTE','CNTRY NAME','CURR NAME','STATUS','A2','3166 NUM','4217 NUM','MAJOR UNIT']
    } else if(headerTypeTLAR === 'FXSD') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','O-CNTRY','O-CURR','ACCT TYP','D-CNTRY','D-CURR','ACCT ID','SPL ACCT','SEQ NO','D-PAIR','DEF DISC','PRIN1','DISC1','PRIN2','DISC2','PRIN3','DISC3','PROMO START','PROMO END','PROD CD','STATE','CITY','DMA','NETWORK']
    } else if(headerTypeTLAR === 'DQMT') {  
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','MTCN','DEVICE ID','TYPE']
    } else if(headerTypeTLAR === 'JOVP') {  
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','NETWORK','ACCOUNT','NAME','BATCH_IND','D2B_IND','CAN_IND','QUEUE','HOME_DELIV','AMOUNT','WC_IND','WC_QUEUE','MCIND','MCACCOUNT']
    } else if(headerTypeTLAR === 'FCAL') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','USER ID','HOST ID','EMAILID']
    } else if(headerTypeTLAR === 'WFLW' || headerTypeTLAR === 'QUEA') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','LOG DATA']
    } else if(headerTypeTLAR === 'PTNR') {
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','NETWORK','STATE CURTAIN','NETWORK CURTAIN','PAY HOLD TIME','STORE HOLD TIME','LOCATION CURTAIN','FX RECALC SW']
    } else if(headerTypeTLAR === 'WUDP') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','DELIV SERVICE','ISO CODE','NETWORK','ASST NETWORKS','NETWORK NAME']
    } else if(headerTypeTLAR === 'ALL') { 
      headersTLAR = [ 'OPER ID','DATE','ACTION','KEY','DATA']
    }
    return headersTLAR.map((item, i) => (
      <th key={i} className="text-nowrap">
        {item}
      </th>
    ));
  }

  onHandleExportToExcelTLAR = () => {
    this.excelrefTipLogAuditReport.handleDownload();
  }

  getExcelColumns = () => {
    let excelTypeTLAR = this.props.history.location.submittedData.searchTypeTLS;
    let excelColumnsData = [];
    if(excelTypeTLAR === 'ART') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" }, { excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },
        { excelLabel: "ORGIN", excelVal: "orgin" },{ excelLabel: "DESTINATION", excelVal: "destination" },
        { excelLabel: "TYPE", excelVal: "type" },{ excelLabel: "REASON", excelVal: "reason" },
        { excelLabel: "ACCOUNT", excelVal: "account" },{ excelLabel: "REF ACCT", excelVal: "refAcct" },
        { excelLabel: "ADJ REQ", excelVal: "adjReq" },{ excelLabel: "DRF REQ", excelVal: "drfReq" },
        { excelLabel: "NOT REQ", excelVal: "notReq" },{ excelLabel: "CHG DT", excelVal: "chgDate" },
        { excelLabel: "OPER ID", excelVal: "operatorId2" },{ excelLabel: "SITE ID", excelVal: "siteId" }
      ];
    } else if(excelTypeTLAR === 'FPF') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },
      { excelLabel: "TIMESTAMP", excelVal: "timestamp" },{ excelLabel: "SEQ NO", excelVal: "seqNo" },
      { excelLabel: "START DATE", excelVal: "startDate" },{ excelLabel: "END DATE", excelVal: "endDate" },
      { excelLabel: "FIL TYPE", excelVal: "filType" },{ excelLabel: "CHANNEL", excelVal: "channel" },
      { excelLabel: "WC", excelVal: "wc" },{ excelLabel: "D2B", excelVal: "d2b" },
      { excelLabel: "STG", excelVal: "stg" },{ excelLabel: "QCH", excelVal: "qch" },
      { excelLabel: "ABMT", excelVal: "abmt" },{ excelLabel: "SITE", excelVal: "site" },
      { excelLabel: "SCNTRY", excelVal: "sndrCountry" },{ excelLabel: "SSTATE", excelVal: "sndrState" },
      { excelLabel: "RCNTRY", excelVal: "rcvrCountry" },{ excelLabel: "RSTATE", excelVal: "rcvrState" },
      { excelLabel: "PHONE", excelVal: "phone" },{ excelLabel: "AGENT", excelVal: "agent" },
      { excelLabel: "NETWORK", excelVal: "network" },{ excelLabel: "LOW LMT", excelVal: "lowLimit" },
      { excelLabel: "UPP LMT", excelVal: "upLimit" },{ excelLabel: "ACT", excelVal: "act" },
      { excelLabel: "DMIN", excelVal: "dmin" },{ excelLabel: "COMMENTS", excelVal: "comments" },
      { excelLabel: "OPER", excelVal: "operatorId2" },{ excelLabel: "TERM", excelVal: "term" },
      { excelLabel: "QUEUE", excelVal: "queue" },{ excelLabel: "MNTR", excelVal: "mntr" },
      { excelLabel: "ADDR", excelVal: "addr" },{ excelLabel: "ACCT", excelVal: "acct" },
      { excelLabel: "CLIENT", excelVal: "client" },{ excelLabel: "RULE1", excelVal: "rule1" },
      { excelLabel: "RULE2", excelVal: "rule2" },{ excelLabel: "F-AMT", excelVal: "famt" },
      { excelLabel: "MSG IND", excelVal: "msgInd" },{ excelLabel: "MESSAGE", excelVal: "message" }
      ];
    } else if(excelTypeTLAR === 'FPFC') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
      { excelLabel: "KEY", excelVal: "key" },
      { excelLabel: "TIMESTAMP", excelVal: "timestamp" },{ excelLabel: "SEQ NO", excelVal: "seqNo" },
      { excelLabel: "START DATE", excelVal: "startDate" },{ excelLabel: "END DATE", excelVal: "endDate" },
      { excelLabel: "FIL TYPE", excelVal: "filType" },{ excelLabel: "CHANNEL", excelVal: "channel" },
      { excelLabel: "WC", excelVal: "wc" },{ excelLabel: "D2B", excelVal: "d2b" },
      { excelLabel: "H2H", excelVal: "h2h" },{ excelLabel: "STG", excelVal: "stg" },
      { excelLabel: "QCH", excelVal: "qch" },{ excelLabel: "ABMT", excelVal: "abmt" },
      { excelLabel: "SITE", excelVal: "site" },{ excelLabel: "SCNTRY", excelVal: "sndrCountry" },
      { excelLabel: "SSTATE", excelVal: "sndrState" },{ excelLabel: "RCNTRY", excelVal: "rcvrCountry" },
      { excelLabel: "RSTATE", excelVal: "rcvrState" },{ excelLabel: "PHONE", excelVal: "phone" },
      { excelLabel: "AGENT", excelVal: "agent" },{ excelLabel: "NETWORK", excelVal: "network" },
      { excelLabel: "LOW LMT", excelVal: "lowLimit" },{ excelLabel: "UPP LMT", excelVal: "upLimit" },
      { excelLabel: "ACT", excelVal: "act" },{ excelLabel: "DMIN", excelVal: "dmin" },
      { excelLabel: "COMMENTS", excelVal: "comments" },{ excelLabel: "OPER", excelVal: "operatorId2" },
      { excelLabel: "TERM", excelVal: "term" },{ excelLabel: "QUEUE", excelVal: "queue" },
      { excelLabel: "MNTR", excelVal: "mntr" },{ excelLabel: "ADDR", excelVal: "addr" },
      { excelLabel: "ACCT", excelVal: "acct" },{ excelLabel: "CLIENT", excelVal: "client" },
      { excelLabel: "RULE1", excelVal: "rule1" },{ excelLabel: "RULE2", excelVal: "rule2" },
      { excelLabel: "F-AMT", excelVal: "famt" },{ excelLabel: "RULEID", excelVal: "ruleId" },
      { excelLabel: "WLST IND", excelVal: "wlStInd" },{ excelLabel: "MSG IND", excelVal: "msgInd" },
      { excelLabel: "MESSAGE", excelVal: "message" }
      ];
    } else if(excelTypeTLAR === 'GSP') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
      { excelLabel: "KEY", excelVal: "key" },
      { excelLabel: "OPTIONS", excelVal: "options" },{ excelLabel: "HOST", excelVal: "host" },
      ];
    } else if(excelTypeTLAR === 'GRP') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
      { excelLabel: "KEY", excelVal: "key" },
      { excelLabel: "GROUP", excelVal: "group" },{ excelLabel: "DESCRIPTION", excelVal: "description" },{ excelLabel: "QUEUE IND", excelVal: "queueInd" }
      ];
    } else if(excelTypeTLAR === 'QUE') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
      { excelLabel: "KEY", excelVal: "key" }, { excelLabel: "QUEUE", excelVal: "queue" },{ excelLabel: "DESCRIPTION", excelVal: "description" },{ excelLabel: "GROUP", excelVal: "group" },{ excelLabel: "CALLBACK", excelVal: "callback" },{ excelLabel: "RET", excelVal: "ret" },{ excelLabel: "REL", excelVal: "rel" },{ excelLabel: "TTQ", excelVal: "ttq" },{ excelLabel: "WC", excelVal: "wc" },{ excelLabel: "DT", excelVal: "dt" },{ excelLabel: "WFLOW", excelVal: "wflow" }
      ];
    } else if(excelTypeTLAR === 'RULE') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
      { excelLabel: "KEY", excelVal: "key" }, { excelLabel: "AUTH FLAG", excelVal: "authFlag" },{ excelLabel: "SITE", excelVal: "site" },{ excelLabel: "ACCT START", excelVal: "acctStart" },{ excelLabel: "ACCT END", excelVal: "AcctEnd" },{ excelLabel: "ALIAS", excelVal: "alias" }
      ];
    } else if(excelTypeTLAR === 'SITE') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
      { excelLabel: "KEY", excelVal: "key" }, { excelLabel: "SITE", excelVal: "site" },{ excelLabel: "SEQUENCE", excelVal: "sequence" },{ excelLabel: "QUEUE", excelVal: "queue" }
      ];
    } else if(excelTypeTLAR === 'TMNS') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" }, { excelLabel: "RECID", excelVal: "recId" },
      { excelLabel: "PARAM1", excelVal: "param1" },{ excelLabel: "PARAM2", excelVal: "param2" },{ excelLabel: "NFED", excelVal: "nfed" }
    ,{ excelLabel: "PADR1", excelVal: "pAdr1" },{ excelLabel: "PADR2", excelVal: "pAdr2" },{ excelLabel: "PADR3", excelVal: "pAdr3" }
    ,{ excelLabel: "PADR4", excelVal: "pAdr4" },{ excelLabel: "T3INT", excelVal: "t3Int" },{ excelLabel: "T5INT", excelVal: "t5Int" }
    ,{ excelLabel: "DDF", excelVal: "ddf" },{ excelLabel: "RDF", excelVal: "rdf" },{ excelLabel: "RECNUM", excelVal: "recnum" }
    ,{ excelLabel: "PINMIN", excelVal: "pinMin" },{ excelLabel: "DFLG", excelVal: "dFlag" },{ excelLabel: "HIDS", excelVal: "hids" }
    ,{ excelLabel: "BFLG", excelVal: "bFlag" },{ excelLabel: "USDDAMT", excelVal: "usddamt" },{ excelLabel: "USCASHAMT", excelVal: "uscashamt" }
    ,{ excelLabel: "ITDDAMT", excelVal: "itddamt" },{ excelLabel: "ITCOLAMT", excelVal: "itcolamt" },{ excelLabel: "ITCASHAMT", excelVal: "itcashamt" }
    ,{ excelLabel: "OVERAMT", excelVal: "overamt" },{ excelLabel: "TSTQAMT", excelVal: "tstqamt" },{ excelLabel: "PAYAMT", excelVal: "payamt" }
    ,{ excelLabel: "FXFLG", excelVal: "fxFlg" },{ excelLabel: "SW", excelVal: "sw" },{ excelLabel: "SUBSYS", excelVal: "subSys" }
    ,{ excelLabel: "DPFLG", excelVal: "dpFlg" },{ excelLabel: "LOAD DT11", excelVal: "loadDT11" },{ excelLabel: "LOAD DT12", excelVal: "loadDT12" }
    ,{ excelLabel: "LOAD DT13", excelVal: "loadDT13" },{ excelLabel: "LOAD TM11", excelVal: "loadTM11" },{ excelLabel: "LOAD TM12", excelVal: "loadTM12" }
    ,{ excelLabel: "LOAD DT21", excelVal: "loadDT21" },{ excelLabel: "LOAD DT22", excelVal: "loadDT22" },{ excelLabel: "LOAD DT23", excelVal: "loadDT23" }
    ,{ excelLabel: "LOAD TM21", excelVal: "loadTM21" },{ excelLabel: "LOAD TM22", excelVal: "loadTM22" },{ excelLabel: "LOAD TM23", excelVal: "loadTM23" }
    ,{ excelLabel: "DLOG", excelVal: "dLog" },{ excelLabel: "EFLG", excelVal: "eFlg" }
      ];
    } else if(excelTypeTLAR === 'TMNP') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" }, { excelLabel: "TCODE", excelVal: "tcode" },
      { excelLabel: "RECNUM", excelVal: "recNum" },{ excelLabel: "GPR CD", excelVal: "gprCd" },{ excelLabel: "DFLG", excelVal: "dflg" }
      ,{ excelLabel: "DLVL", excelVal: "dlvl" },{ excelLabel: "CD1", excelVal: "cd1" },{ excelLabel: "CD2", excelVal: "cd2" }
      ,{ excelLabel: "SW1", excelVal: "sw1" },{ excelLabel: "SW2", excelVal: "sw2" },{ excelLabel: "SW3", excelVal: "sw3" }
      ,{ excelLabel: "SW4", excelVal: "sw4" },{ excelLabel: "SW5", excelVal: "sw5" },{ excelLabel: "SW6", excelVal: "sw6" }
      ,{ excelLabel: "SW7", excelVal: "sw7" },{ excelLabel: "SW8", excelVal: "sw8" },{ excelLabel: "SW9", excelVal: "sw9" }
      ,{ excelLabel: "SW10", excelVal: "sw10" },{ excelLabel: "SW11", excelVal: "sw11" },{ excelLabel: "SW12", excelVal: "sw12" }
      ,{ excelLabel: "AMOUNT", excelVal: "amount" }
      ];
    } else if(excelTypeTLAR === 'TMNG') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" }, { excelLabel: "PARAM1", excelVal: "param1" },
      { excelLabel: "PARAM2", excelVal: "param2" },{ excelLabel: "PADDRESS", excelVal: "paddress" }
	  ,{ excelLabel: "T3INT", excelVal: "t3Int" },{ excelLabel: "T5INT", excelVal: "t5Int" },{ excelLabel: "DDF", excelVal: "ddf" }
	  ,{ excelLabel: "RDF", excelVal: "rdf" },{ excelLabel: "PINMIN", excelVal: "pinMin" },{ excelLabel: "DFLG", excelVal: "dflag" }
	  ,{ excelLabel: "HIDS", excelVal: "hids" },{ excelLabel: "BFLG", excelVal: "bflag" },{ excelLabel: "ITDDAMT", excelVal: "itddamt" }
	  ,{ excelLabel: "ITCOLAMT", excelVal: "itcolamt" },{ excelLabel: "ITCASHAMT", excelVal: "itcashamt" },{ excelLabel: "USDDAMT", excelVal: "usddamt" }
	  ,{ excelLabel: "USCOLAMT", excelVal: "uscolamt" },{ excelLabel: "USCASHAMT", excelVal: "uscashamt" },{ excelLabel: "OVERAMT", excelVal: "overamt" }
	  ,{ excelLabel: "TSTQAMT", excelVal: "tstqamt" },{ excelLabel: "PAYAMT", excelVal: "payamt" },{ excelLabel: "FXFLG", excelVal: "fxFlg" }
	  ,{ excelLabel: "SUBSYS", excelVal: "subSys" },{ excelLabel: "PCPQ", excelVal: "pcpq" },{ excelLabel: "DUPQ", excelVal: "dupq" }
	  ,{ excelLabel: "DLOG", excelVal: "dlog" },{ excelLabel: "ELOG", excelVal: "elog" },{ excelLabel: "NFED", excelVal: "nfed" }
      ];
    } else if(excelTypeTLAR === 'CCMT') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" }, { excelLabel: "RID", excelVal: "rid" },
      { excelLabel: "REV", excelVal: "rev" },{ excelLabel: "SITE", excelVal: "site" }
      ,{ excelLabel: "ID", excelVal: "id" },{ excelLabel: "NAME", excelVal: "name" },{ excelLabel: "RIGHTS1", excelVal: "rights1" }
      ,{ excelLabel: "RIGHTS2", excelVal: "rights2" },{ excelLabel: "OPID", excelVal: "operatorId2" },{ excelLabel: "CHG_FLG", excelVal: "chgFlg" }
      ,{ excelLabel: "UPTD", excelVal: "uptd" },{ excelLabel: "RETRY CNT", excelVal: "retryCnt" },{ excelLabel: "RTRY LCK TS", excelVal: "rtryLckTs" }
      ];
    } else if(excelTypeTLAR === 'IRP') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" }, { excelLabel: "CLIENT ID", excelVal: "clientId" },
      { excelLabel: "CLIENT NAME", excelVal: "ClientName" },{ excelLabel: "TYPE", excelVal: "type" }
      ,{ excelLabel: "CPC", excelVal: "cpc" },{ excelLabel: "STD FEE", excelVal: "stdFee" },{ excelLabel: "MM", excelVal: "mm" }
      ,{ excelLabel: "DD", excelVal: "dd" },{ excelLabel: "CCYY", excelVal: "ccyy" },{ excelLabel: "UPD DT", excelVal: "updDT" }
      ,{ excelLabel: "OPER", excelVal: "oper" },{ excelLabel: "ACT", excelVal: "act" },{ excelLabel: "CIELING", excelVal: "cieling" }
      ,{ excelLabel: "TIER FEE", excelVal: "tierFee" },{ excelLabel: "TIER PCT", excelVal: "tierPct" },{ excelLabel: "POS FEE", excelVal: "posFee" }
      ,{ excelLabel: "POS PCT", excelVal: "posPct" }
      ];
    } else if(excelTypeTLAR === 'CM03') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" }, { excelLabel: "ID ALP", excelVal: "idAlp" },
      { excelLabel: "ID NUM", excelVal: "idNum" },{ excelLabel: "TSTQ", excelVal: "tstq" }
      ,{ excelLabel: "RTTP", excelVal: "rttp" },{ excelLabel: "SND", excelVal: "snd" },{ excelLabel: "REC", excelVal: "rec" }
      ,{ excelLabel: "INTRA", excelVal: "intra" },{ excelLabel: "DDS", excelVal: "dds" },{ excelLabel: "TIME1", excelVal: "time1" }
      ,{ excelLabel: "TIME2", excelVal: "time2" },{ excelLabel: "TIME3", excelVal: "time3" },{ excelLabel: "TIME4", excelVal: "time4" }
      ,{ excelLabel: "TIME5", excelVal: "time5" },{ excelLabel: "TIME6", excelVal: "time6" },{ excelLabel: "TIME7", excelVal: "time7" }
      ,{ excelLabel: "TIME8", excelVal: "time8" },{ excelLabel: "TIME9", excelVal: "time9" },{ excelLabel: "TIME10", excelVal: "time10" }
      ,{ excelLabel: "ROUTE", excelVal: "route" },{ excelLabel: "QCMAX", excelVal: "qcMax" },{ excelLabel: "POMAX", excelVal: "poMax" }
      ,{ excelLabel: "INTLMAX", excelVal: "intlMax" },{ excelLabel: "CCAUTHQ", excelVal: "ccAuthQ" },{ excelLabel: "XRATE", excelVal: "xrate" }
      ,{ excelLabel: "ARATE", excelVal: "arate" },{ excelLabel: "FEE1", excelVal: "fee1" },{ excelLabel: "FEE2", excelVal: "fee2" }
      ,{ excelLabel: "BWTTO", excelVal: "bwtTo" },{ excelLabel: "BWTFRM", excelVal: "bwtFrm" },{ excelLabel: "NAME", excelVal: "name" }
      ,{ excelLabel: "TZ", excelVal: "tz" },{ excelLabel: "XRTYPE", excelVal: "xrType" },{ excelLabel: "CARR", excelVal: "carr" }
      ,{ excelLabel: "CURR", excelVal: "curr" },{ excelLabel: "SERV", excelVal: "serv" },{ excelLabel: "BEST BUY", excelVal: "bestBuy" }
      ,{ excelLabel: "ISO", excelVal: "iso" },{ excelLabel: "FXADJ", excelVal: "fxAdj" },{ excelLabel: "PURGE PRD", excelVal: "purgePrd" }
      ,{ excelLabel: "USDMAX", excelVal: "usdMax" }
      ];
    } else if(excelTypeTLAR === 'CM04') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },
      { excelLabel: "ACODE", excelVal: "acode" },{ excelLabel: "CCODE", excelVal: "ccode" }
      ,{ excelLabel: "RUC", excelVal: "rttp" },{ excelLabel: "STCD", excelVal: "snd" },{ excelLabel: "CD1", excelVal: "rec" }
      ,{ excelLabel: "CD2", excelVal: "intra" }
      ];
    } else if(excelTypeTLAR === 'CM06') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },
      { excelLabel: "ID ALP", excelVal: "idAlp" },{ excelLabel: "ID NUM", excelVal: "idNum" }
      ,{ excelLabel: "TSTQ", excelVal: "tstq" },{ excelLabel: "RTTP", excelVal: "rttp" },{ excelLabel: "SND", excelVal: "snd" }
      ,{ excelLabel: "REC", excelVal: "rec" },{ excelLabel: "INTRA", excelVal: "intra" },{ excelLabel: "DDS", excelVal: "dds" },
      { excelLabel: "TIME1", excelVal: "time1" },{ excelLabel: "TIME2", excelVal: "time2" }
      ,{ excelLabel: "TIME3", excelVal: "time3" },{ excelLabel: "TIME4", excelVal: "time4" },{ excelLabel: "TIME5", excelVal: "time5" }
      ,{ excelLabel: "TIME6", excelVal: "time6" },{ excelLabel: "TIME7", excelVal: "time7" },{ excelLabel: "TIME8", excelVal: "time8" }
      ,{ excelLabel: "TIME9", excelVal: "time9" },{ excelLabel: "TIME10", excelVal: "time10" },{ excelLabel: "ROUTE", excelVal: "route" }
      ,{ excelLabel: "QCMAX", excelVal: "qcMax" },{ excelLabel: "POMAX", excelVal: "poMax" },{ excelLabel: "INTLMAX", excelVal: "intlMax" }
      ,{ excelLabel: "CCAUTHQ", excelVal: "ccAuthQ" },{ excelLabel: "XRATE", excelVal: "xrate" },{ excelLabel: "ARATE", excelVal: "arate" }
      ,{ excelLabel: "FEE1", excelVal: "fee1" },{ excelLabel: "FEE2", excelVal: "fee2" },{ excelLabel: "RDTO", excelVal: "rdTo" }
      ,{ excelLabel: "BWTTO", excelVal: "bwtTo" },{ excelLabel: "BWTFRM", excelVal: "bwtFrm" },{ excelLabel: "NAME", excelVal: "name" }
      ,{ excelLabel: "TZ", excelVal: "tz" },{ excelLabel: "XRTYPE", excelVal: "xrType" },{ excelLabel: "CARR", excelVal: "carr" }
      ,{ excelLabel: "CURR", excelVal: "curr" },{ excelLabel: "SERV", excelVal: "serv" },{ excelLabel: "BEST BUY", excelVal: "bestBuy" }
      ,{ excelLabel: "ISO", excelVal: "iso" },{ excelLabel: "FXADJ", excelVal: "fxAdj" },{ excelLabel: "PURGE PRD", excelVal: "purgePrd" }
      ,{ excelLabel: "USDMAX", excelVal: "usdMax" }
      ];
    } else if(excelTypeTLAR === 'CM07') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "FMTO", excelVal: "fmto" }
      ,{ excelLabel: "FEE", excelVal: "fee" },{ excelLabel: "CFSTATUS", excelVal: "cfStatus" },{ excelLabel: "CNTYFIL", excelVal: "cntyFil" }
      ,{ excelLabel: "UTD", excelVal: "utd" },{ excelLabel: "OPID", excelVal: "operatorId2" },{ excelLabel: "STATUS", excelVal: "status" },
      { excelLabel: "TID", excelVal: "tid" },{ excelLabel: "FIL1", excelVal: "fil1" }
      ,{ excelLabel: "FEETRIG", excelVal: "feeTrig" },{ excelLabel: "AMTTRIG", excelVal: "amtTrig" },{ excelLabel: "CNTFIL", excelVal: "cntfil" }
      ];
    } else if(excelTypeTLAR === 'CM09') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
	    { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "CNAME", excelVal: "cname" }
	   ,{ excelLabel: "SERVICE", excelVal: "service" },{ excelLabel: "ADDRESS1", excelVal: "address1" },{ excelLabel: "ADDRESS2", excelVal: "address2" }
	   ,{ excelLabel: "ADDRESS3", excelVal: "address3" },{ excelLabel: "ADDRESS4", excelVal: "address4" }
      ];
    } else if(excelTypeTLAR === 'CM10') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
	   { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "SND CC", excelVal: "sndCC" }
	  ,{ excelLabel: "RCV CC", excelVal: "rcvCC" },{ excelLabel: "SDATE", excelVal: "sdate" },{ excelLabel: "EDATE", excelVal: "edate" }
	  ,{ excelLabel: "DM0", excelVal: "dm0" },{ excelLabel: "DM1", excelVal: "dm1" },{ excelLabel: "DM2", excelVal: "dm2" }
	  ,{ excelLabel: "DM3", excelVal: "dm3" },{ excelLabel: "DM4", excelVal: "dm4" },{ excelLabel: "DM5", excelVal: "dm5" }
	  ,{ excelLabel: "DM6", excelVal: "dm6" },{ excelLabel: "DM7", excelVal: "dm7" },{ excelLabel: "DM8", excelVal: "dm8" }
	  ,{ excelLabel: "DM9", excelVal: "dm9" }
      ];
    } else if(excelTypeTLAR === 'CM12') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
	    { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "CTYNAME", excelVal: "cityName" }
    ,{ excelLabel: "CTYCODE", excelVal: "cityCode" },{ excelLabel: "FF1", excelVal: "ff1" },{ excelLabel: "FF2", excelVal: "ff2" }
    ,{ excelLabel: "FF3", excelVal: "ff3" },{ excelLabel: "FF4", excelVal: "ff4" },{ excelLabel: "FF5", excelVal: "ff5" }
    ,{ excelLabel: "FF6", excelVal: "ff6" },{ excelLabel: "FF7", excelVal: "ff7" },{ excelLabel: "FF8", excelVal: "ff8" }
    ,{ excelLabel: "FF9", excelVal: "ff9" },{ excelLabel: "FF10", excelVal: "ff10" },{ excelLabel: "FF11", excelVal: "ff11" }
    ,{ excelLabel: "FF12", excelVal: "ff12" },{ excelLabel: "FF13", excelVal: "ff13" },{ excelLabel: "FF14", excelVal: "ff14" }
    ,{ excelLabel: "FF15", excelVal: "ff15" },{ excelLabel: "FLAG", excelVal: "flag" }
      ];
    } else if(excelTypeTLAR === 'CM13') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "OCNTY", excelVal: "orgCountry" }
      ,{ excelLabel: "DCNTY", excelVal: "desCountry" },{ excelLabel: "FIX", excelVal: "fix" },{ excelLabel: "FLG", excelVal: "flag" }
      ,{ excelLabel: "OPER", excelVal: "operatorId2" },{ excelLabel: "TERM", excelVal: "term" },{ excelLabel: "SITE", excelVal: "site" }
      ,{ excelLabel: "DATE TIME", excelVal: "dateTime" },{ excelLabel: "ERROR MSG", excelVal: "errorMsg" }
      ];
    } else if(excelTypeTLAR === 'CM14') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
	    { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "COUNTRY", excelVal: "country" }
      ,{ excelLabel: "OPT1", excelVal: "option1" },{ excelLabel: "OPT2", excelVal: "option2" },{ excelLabel: "OPT3", excelVal: "option3" }
      ,{ excelLabel: "OPT4", excelVal: "option4" },{ excelLabel: "OPT5", excelVal: "option5" },{ excelLabel: "OPT6", excelVal: "option6" }
      ,{ excelLabel: "OPT7", excelVal: "option7" },{ excelLabel: "OPT8", excelVal: "option8" },{ excelLabel: "OPT9", excelVal: "option9" }
      ,{ excelLabel: "OPT10", excelVal: "option10" },{ excelLabel: "OPT11", excelVal: "option11" },{ excelLabel: "OPT12", excelVal: "option12" }
      ,{ excelLabel: "OPT13", excelVal: "option13" },{ excelLabel: "OPT14", excelVal: "option14" },{ excelLabel: "OPT15", excelVal: "option15" }
      ,{ excelLabel: "OPT16", excelVal: "option16" },{ excelLabel: "OPT17", excelVal: "option17" },{ excelLabel: "OPT18", excelVal: "option18" }
      ,{ excelLabel: "OPT19", excelVal: "option19" },{ excelLabel: "OPT20", excelVal: "option20" }
      ];
    } else if(excelTypeTLAR === 'PTNR') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },
        { excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },
        { excelLabel: "NETWORK", excelVal: "network" },{ excelLabel: "STATE CURTAIN", excelVal: "stateCurtain" },
        { excelLabel: "NETWORK CURTAIN", excelVal: "networkCurtain" },{ excelLabel: "PAY HOLD TIME", excelVal: "payHoldTime" },
        { excelLabel: "STORE HOLD TIME", excelVal: "storeHoldTime" },{ excelLabel: "LOCATION CURTAIN", excelVal: "locationCurtain" },
        { excelLabel: "FX RECALC SW", excelVal: "fxRecalcSw" }
      ];
    } else if(excelTypeTLAR === 'WUDP') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },
        { excelLabel: "DELIV SERVICE", excelVal: "deliService" },{ excelLabel: "ISO CODE", excelVal: "isoCode" },
        { excelLabel: "NETWORK", excelVal: "network" },{ excelLabel: "ASST NETWORKS", excelVal: "asstNetwork" },
        { excelLabel: "NETWORK NAME", excelVal: "networkName" }
      ];
    } else if(excelTypeTLAR === '0002') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "ID", excelVal: "id" },{ excelLabel: "NAME", excelVal: "name" },
        { excelLabel: "ADDRESS", excelVal: "address" },{ excelLabel: "PHONE", excelVal: "phone" },
        { excelLabel: "PSRC", excelVal: "psrc" },{ excelLabel: "ALT PHONE", excelVal: "altPhone" },
        { excelLabel: "EMAIL", excelVal: "email" },{ excelLabel: "DOB", excelVal: "dob" },
        { excelLabel: "TFLG", excelVal: "tflag" },{ excelLabel: "PIN", excelVal: "pin" },
        { excelLabel: "UPD DT", excelVal: "updDate" },{ excelLabel: "UPD ID", excelVal: "updId" },
        { excelLabel: "RT CODE", excelVal: "rtCode" },{ excelLabel: "VFLG", excelVal: "vflag" },
        { excelLabel: "VLD DT", excelVal: "vldDT" },{ excelLabel: "LVL", excelVal: "level" },
        { excelLabel: "CRE ID", excelVal: "creId" },{ excelLabel: "GUARANTEE", excelVal: "guarantee" },
      ];
    } else if(excelTypeTLAR === '0003') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },

        { excelLabel: "MTCN", excelVal: "mtcn" },{ excelLabel: "ACCT NBR", excelVal: "acctNumber" },
        { excelLabel: "CUST ID", excelVal: "custID" },{ excelLabel: "REC DT", excelVal: "recDT" },
        { excelLabel: "AMOUNT", excelVal: "amount" },{ excelLabel: "CUR CD", excelVal: "curCD" },
        { excelLabel: "D CTRY", excelVal: "dcountry" },{ excelLabel: "TYPE", excelVal: "type" },
        { excelLabel: "SCORE", excelVal: "score" },{ excelLabel: "ATH CD", excelVal: "athCd" },
        { excelLabel: "AVS", excelVal: "avs" },{ excelLabel: "CVV", excelVal: "cvv" },
        { excelLabel: "PIN", excelVal: "pin" },{ excelLabel: "CB FLG", excelVal: "cbFlag" },
        { excelLabel: "CB DT", excelVal: "cbDT" },{ excelLabel: "PHONE", excelVal: "phone" },
        { excelLabel: "ANI", excelVal: "ani" },{ excelLabel: "IP ADDR", excelVal: "ipAddress" },
        { excelLabel: "REC NAME", excelVal: "recName" },{ excelLabel: "UPD ID", excelVal: "updID" },
        { excelLabel: "UPD TD", excelVal: "updDT" },{ excelLabel: "TOT UPD", excelVal: "totUpd" },
        { excelLabel: "CODEX", excelVal: "codex" },{ excelLabel: "K RES", excelVal: "kRes" },
        { excelLabel: "O RES", excelVal: "oRes" },{ excelLabel: "SG SCORE", excelVal: "sgScore" },
        { excelLabel: "STATUS", excelVal: "status" }
      ];
    } else if(excelTypeTLAR === '0004') {
      excelColumnsData = [
        
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },
        { excelLabel: "NUMBER", excelVal: "number" },{ excelLabel: "TYPE", excelVal: "type" },
        { excelLabel: "SINFO", excelVal: "sinfo" },{ excelLabel: "BADDR", excelVal: "billAddress" },
        { excelLabel: "ISO", excelVal: "iso" },{ excelLabel: "EXP DT", excelVal: "expDT" },
        { excelLabel: "TFLG", excelVal: "tflag" },{ excelLabel: "ACT DT", excelVal: "actDT" },
        { excelLabel: "CRE DT", excelVal: "creDT" },{ excelLabel: "UPD DT", excelVal: "updDT" },
        { excelLabel: "UPD ID", excelVal: "updId" },{ excelLabel: "UPD TD", excelVal: "updTD" },
        { excelLabel: "TOT UPD", excelVal: "totUpd" }
      ];
    } else if(excelTypeTLAR === '0005') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },  { excelLabel: "KEY", excelVal: "key" },
        { excelLabel: "KEY", excelVal: "key2" },{ excelLabel: "CUST ID", excelVal: "custId" },
        { excelLabel: "ACT NBR", excelVal: "actNumber" },{ excelLabel: "BTNAME", excelVal: "btName" },
        { excelLabel: "CRE DT", excelVal: "creDT" },{ excelLabel: "ACT DT", excelVal: "actDT" },
        { excelLabel: "UPD DT", excelVal: "updDT" },{ excelLabel: "UPD ID", excelVal: "updId" },
        { excelLabel: "UPD TD", excelVal: "updTD" },{ excelLabel: "TOT UPD", excelVal: "totUpd" }
      ];
    } else if(excelTypeTLAR === 'BRMT') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },
        { excelLabel: "CODE", excelVal: "code" },{ excelLabel: "NAME", excelVal: "name" },
        { excelLabel: "ISO", excelVal: "iso" },
        { excelLabel: "Days", excelVal: "days" },{ excelLabel: "VTRAN", excelVal: "vtran" },
        { excelLabel: "VDAY", excelVal: "vday" },{ excelLabel: "BP", excelVal: "bp" },
        { excelLabel: "BP RC", excelVal: "bpRc" },{ excelLabel: "BP AMT", excelVal: "bpAmt" },
        { excelLabel: "BP CVV", excelVal: "bpCvv" },{ excelLabel: "REQ", excelVal: "req" },
        { excelLabel: "REQ TRAN", excelVal: "reqTran" },{ excelLabel: "REQ AA", excelVal: "reqAA" },
        { excelLabel: "CVV", excelVal: "cvv" },{ excelLabel: "AVS", excelVal: "avs" },
        { excelLabel: "DEF RULE", excelVal: "defRule" },{ excelLabel: "SAT", excelVal: "sat" },
        { excelLabel: "EXP", excelVal: "exp" },{ excelLabel: "IBP", excelVal: "ibp" },
        { excelLabel: "LC", excelVal: "lc" },{ excelLabel: "QUEUE", excelVal: "queue" },
        { excelLabel: "PROD CD1", excelVal: "prodCD1" },{ excelLabel: "MER ID1", excelVal: "merId1" },
        { excelLabel: "PMT TYPE1", excelVal: "pmtType1" },{ excelLabel: "PATH1", excelVal: "path1" },
        { excelLabel: "PROD CD2", excelVal: "prodCD2" },{ excelLabel: "MER ID2", excelVal: "merId2" },
        { excelLabel: "PMT TYPE2", excelVal: "pmtType2" },{ excelLabel: "PATH2", excelVal: "path2" },
        { excelLabel: "PROD CD3", excelVal: "prodCD3" },{ excelLabel: "MER ID3", excelVal: "merId3" },
        { excelLabel: "PMT TYPE3", excelVal: "pmtType3" },{ excelLabel: "PATH3", excelVal: "path3" },
        { excelLabel: "PROD CD4", excelVal: "prodCD4" },{ excelLabel: "MER ID4", excelVal: "merId4" },
        { excelLabel: "PMT TYPE4", excelVal: "pmtType4" },{ excelLabel: "PATH4", excelVal: "path4" },
        { excelLabel: "PROD CD5", excelVal: "prodCD5" },{ excelLabel: "MER ID5", excelVal: "merId5" },
        { excelLabel: "PMT TYPE5", excelVal: "pmtType5" },{ excelLabel: "PATH5", excelVal: "path5" },
        { excelLabel: "SITE1", excelVal: "site1" },{ excelLabel: "START1", excelVal: "start1" },
        { excelLabel: "END1", excelVal: "end1" },{ excelLabel: "ALIAS1", excelVal: "alias1" },
        { excelLabel: "SITE2", excelVal: "site2" },{ excelLabel: "START2", excelVal: "start2" },
        { excelLabel: "END2", excelVal: "end2" },{ excelLabel: "ALIAS2", excelVal: "alias2" },
        { excelLabel: "SITE3", excelVal: "site3" },{ excelLabel: "START3", excelVal: "start3" },
        { excelLabel: "END3", excelVal: "end3" },{ excelLabel: "ALIAS3", excelVal: "alias3" },
        { excelLabel: "SITE4", excelVal: "site4" },{ excelLabel: "START4", excelVal: "start4" },
        { excelLabel: "END4", excelVal: "end4" },{ excelLabel: "ALIAS4", excelVal: "alias4" },
        { excelLabel: "EMAIL", excelVal: "email" },{ excelLabel: "AUTHREV", excelVal: "authRev" },
        { excelLabel: "EUID", excelVal: "euId" },{ excelLabel: "CPC", excelVal: "cpc" },
        { excelLabel: "DATE", excelVal: "date2" },{ excelLabel: "VLNEW", excelVal: "vlNew" },
        { excelLabel: "VLDAYS", excelVal: "vlDays" },{ excelLabel: "VLDAILY", excelVal: "vlDaily" },
        { excelLabel: "LNEW", excelVal: "lnew" },{ excelLabel: "LDAYS", excelVal: "ldays" },
        { excelLabel: "LDAILY", excelVal: "ldaily" },{ excelLabel: "VDAYS", excelVal: "vdays" },
      ];
    } else if(excelTypeTLAR === 'BRPT') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },  { excelLabel: "KEY", excelVal: "key" },
        { excelLabel: "Agent Acct", excelVal: "agentAcct" },{ excelLabel: "Email Num", excelVal: "emailNumber" },
        { excelLabel: "Pay Method", excelVal: "payMethod" },{ excelLabel: "Partner Id", excelVal: "partnerId" },
        { excelLabel: "Partner Name", excelVal: "partnerName" },{ excelLabel: "Send CPC", excelVal: "sendCPC" },
        { excelLabel: "Template Pref", excelVal: "templatePref" },{ excelLabel: "Filler", excelVal: "filler" }
      ];
    } else if(excelTypeTLAR === 'MRCH') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },  { excelLabel: "KEY", excelVal: "key" },
        { excelLabel: "Pmt Gateway", excelVal: "paymentGateway" },{ excelLabel: "Channel", excelVal: "channel" },
        { excelLabel: "Txn Type", excelVal: "transType" },{ excelLabel: "Pmt Method", excelVal: "pmtMethod" },
        { excelLabel: "Third party Id", excelVal: "thirdPartyIden" },{ excelLabel: "Pmt method emu", excelVal: "pmtEmulated" },
        { excelLabel: "Billing ID", excelVal: "billingID" },{ excelLabel: "Filler", excelVal: "fillerField" }
      ];
    } else if(excelTypeTLAR === 'JQCC') {
      excelColumnsData = [
        { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
        { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "ID", excelVal: "id" }
        ,{ excelLabel: "VERSION", excelVal: "version" },{ excelLabel: "ACCOUNT", excelVal: "account" },{ excelLabel: "SOSG", excelVal: "sosg" }
        ,{ excelLabel: "NAME", excelVal: "name" },{ excelLabel: "CNTRY", excelVal: "country" },{ excelLabel: "CNTACT", excelVal: "contact" }
        ,{ excelLabel: "PHONE", excelVal: "phone" },{ excelLabel: "SPAY", excelVal: "spay" },{ excelLabel: "EPAY", excelVal: "epay" }
        ,{ excelLabel: "EPURG", excelVal: "epurg" },{ excelLabel: "EDISC", excelVal: "edisc" },{ excelLabel: "EFEE", excelVal: "efee" }
        ,{ excelLabel: "RPTTYP", excelVal: "rptTyp" },{ excelLabel: "TYPE", excelVal: "type" },{ excelLabel: "LTYPE", excelVal: "ltype" }
        ,{ excelLabel: "OFAC", excelVal: "ofac" },{ excelLabel: "DEV1", excelVal: "dev1" },{ excelLabel: "AREA1", excelVal: "area1" }
        ,{ excelLabel: "DEV2", excelVal: "dev2" },{ excelLabel: "AREA2", excelVal: "area2" },{ excelLabel: "DEVFLG", excelVal: "devFlag" }
        ,{ excelLabel: "CFLG", excelVal: "cflag" },{ excelLabel: "CRTE", excelVal: "crte" },{ excelLabel: "IFLG", excelVal: "iflag" }
        ,{ excelLabel: "IRTE", excelVal: "irte" },{ excelLabel: "GRS", excelVal: "grs" },{ excelLabel: "SRATE", excelVal: "srate" }
        ,{ excelLabel: "RATE", excelVal: "rate" },{ excelLabel: "GRSCAN", excelVal: "grscan" },{ excelLabel: "SRCAN", excelVal: "srcan" }
        ,{ excelLabel: "RCAN", excelVal: "rcan" },{ excelLabel: "STAT1", excelVal: "stat1" },{ excelLabel: "BTD1", excelVal: "btd1" }
        ,{ excelLabel: "BSTAT2", excelVal: "bstat2" },{ excelLabel: "BTD2", excelVal: "btd2" },{ excelLabel: "BSTAT3", excelVal: "bstat3" }
        ,{ excelLabel: "BTD3", excelVal: "btd3" },{ excelLabel: "EMAIL", excelVal: "email" },{ excelLabel: "ACCTCD", excelVal: "acctCd" }
        ,{ excelLabel: "FUS_IND", excelVal: "fusInd" },{ excelLabel: "STATUS", excelVal: "status" },{ excelLabel: "RPPS", excelVal: "rpps" }
      ];
    } else if(excelTypeTLAR === 'JFUS') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
      { excelLabel: "KEY", excelVal: "key" },{ excelLabel: "ID", excelVal: "id" },{ excelLabel: "MV", excelVal: "mv" },
      { excelLabel: "FEEMOD", excelVal: "feemod" },{ excelLabel: "POSTEMP", excelVal: "posTemp" },{ excelLabel: "SOD0", excelVal: "sod0" },
      { excelLabel: "SOD1", excelVal: "sod1" },{ excelLabel: "SOD2", excelVal: "sod2" },{ excelLabel: "SOD3", excelVal: "sod3" },
      { excelLabel: "WD_IND", excelVal: "wdInd" },{ excelLabel: "MNOT", excelVal: "mnot" },{ excelLabel: "IDLY", excelVal: "idly" },
      { excelLabel: "AR_FLG", excelVal: "arFlag" },{ excelLabel: "PCPAYEE", excelVal: "pcPayee" },{ excelLabel: "IDUPQ", excelVal: "idupq" },
      { excelLabel: "COMMENTS", excelVal: "comments" },{ excelLabel: "FUS_IND", excelVal: "fusInd" },{ excelLabel: "PREPAID", excelVal: "prepaid" },{ excelLabel: "VERSION", excelVal: "version" }
      ];
    } else if(excelTypeTLAR === 'JDEV') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
	  { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "ID", excelVal: "country" }
	  ,{ excelLabel: "QTYPE", excelVal: "qtype" },{ excelLabel: "CLASS", excelVal: "classVar" },{ excelLabel: "AREA", excelVal: "area" }
	  ,{ excelLabel: "NUMAD", excelVal: "numad" },{ excelLabel: "NUMDIP", excelVal: "numdip" },{ excelLabel: "ACCOUNT", excelVal: "account" }
	  ,{ excelLabel: "NAME", excelVal: "name" },{ excelLabel: "CNTRY", excelVal: "country" },{ excelLabel: "CNTACT", excelVal: "contact" }
	  ,{ excelLabel: "PHONE", excelVal: "phone" },{ excelLabel: "MAXPRN", excelVal: "maxPrn" },{ excelLabel: "TRACE", excelVal: "trace" }
	  ,{ excelLabel: "ROUTE1", excelVal: "route1" },{ excelLabel: "DRFTFM1", excelVal: "drftfm1" },{ excelLabel: "TYPE1", excelVal: "type1" }
	  ,{ excelLabel: "STATUS1", excelVal: "status1" },{ excelLabel: "REPT1", excelVal: "rept1" },{ excelLabel: "ROUTE2", excelVal: "route2" }
	  ,{ excelLabel: "DRFTFM2", excelVal: "drftfm2" },{ excelLabel: "TYPE2", excelVal: "type2" },{ excelLabel: "STATUS2", excelVal: "status2" }
	  ,{ excelLabel: "REPT2", excelVal: "rept2" },{ excelLabel: "ROUTE3", excelVal: "route3" },{ excelLabel: "DRFTFM3", excelVal: "drftfm3" }
	  ,{ excelLabel: "TYPE3", excelVal: "type3" },{ excelLabel: "STATUS3", excelVal: "status3" },{ excelLabel: "REPT3", excelVal: "rept3" }
	  ,{ excelLabel: "ROUTE4", excelVal: "route4" },{ excelLabel: "DRFTFM4", excelVal: "drftfm4" },{ excelLabel: "TYPE4", excelVal: "type4" }
	  ,{ excelLabel: "STATUS4", excelVal: "status4" },{ excelLabel: "REPT4", excelVal: "rept4" },{ excelLabel: "TZ", excelVal: "tz" }
	  ,{ excelLabel: "DSTH", excelVal: "dsth" },{ excelLabel: "IH", excelVal: "ih" },{ excelLabel: "DELAY", excelVal: "delay" }
	  ,{ excelLabel: "CLR", excelVal: "clr" },{ excelLabel: "SUSDAY", excelVal: "susDay" },{ excelLabel: "HOPEN1", excelVal: "hopen1" }
	  ,{ excelLabel: "HCLOSE1", excelVal: "hclose1" },{ excelLabel: "HOPEN2", excelVal: "hopen2" },{ excelLabel: "HCLOSE2", excelVal: "hclose2" }
	  ,{ excelLabel: "HOPEN3", excelVal: "hopen3" },{ excelLabel: "HCLOSE3", excelVal: "hclose3" },{ excelLabel: "HOPEN4", excelVal: "hopen4" }
	  ,{ excelLabel: "HCLOSE4", excelVal: "hclose4" },{ excelLabel: "HOPEN5", excelVal: "hopen5" },{ excelLabel: "HCLOSE5", excelVal: "hclose5" }
	  ,{ excelLabel: "HOPEN6", excelVal: "hopen6" },{ excelLabel: "HCLOSE6", excelVal: "hclose6" },{ excelLabel: "HOPEN7", excelVal: "hopen7" }
	  ,{ excelLabel: "HCLOSE7", excelVal: "hclose7" },{ excelLabel: "MDLVFLG", excelVal: "mdlvFlag" },{ excelLabel: "MDLVDLY", excelVal: "mdlvDaily" }
      ];
    } else if(excelTypeTLAR === 'FXDB') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "CNTRY", excelVal: "country" }
      ,{ excelLabel: "CURR", excelVal: "currency" },{ excelLabel: "ISO", excelVal: "iso" },{ excelLabel: "TYPE", excelVal: "type" }
      ,{ excelLabel: "SPL ACCT", excelVal: "splAccount" },{ excelLabel: "WU CODE", excelVal: "wuCode" },{ excelLabel: "WU ROUTE", excelVal: "wuRoute" }
      ,{ excelLabel: "CNTRY NAME", excelVal: "countryName" },{ excelLabel: "CURR NAME", excelVal: "currencyName" },{ excelLabel: "STATUS", excelVal: "status" }
      ,{ excelLabel: "A2", excelVal: "a2" },{ excelLabel: "3166 NUM", excelVal: "num_3166" },{ excelLabel: "4217 NUM", excelVal: "num_4217" }
      ,{ excelLabel: "MAJOR UNIT", excelVal: "majorUnit" }
      ];
    } else if(excelTypeTLAR === 'FXSD') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "O-CNTRY", excelVal: "orginCountry" }
      ,{ excelLabel: "O-CURR", excelVal: "orginCurrency" },{ excelLabel: "ACCT TYP", excelVal: "acctType" },{ excelLabel: "D-CNTRY", excelVal: "destCountry" }
      ,{ excelLabel: "D-CURR", excelVal: "destCurrency" },{ excelLabel: "ACCT ID", excelVal: "acctId" },{ excelLabel: "SPL ACCT", excelVal: "splAccount" }
      ,{ excelLabel: "SEQ NO", excelVal: "seqNumber" },{ excelLabel: "D-PAIR", excelVal: "dPair" },{ excelLabel: "DEF DISC", excelVal: "defDisc" }
      ,{ excelLabel: "PRIN1", excelVal: "prin1" },{ excelLabel: "DISC1", excelVal: "disc1" },{ excelLabel: "PRIN2", excelVal: "prin2" }
      ,{ excelLabel: "DISC2", excelVal: "disc2" },{ excelLabel: "PRIN3", excelVal: "prin3" },{ excelLabel: "DISC3", excelVal: "disc3" }
      ,{ excelLabel: "PROMO START", excelVal: "promoStart" },{ excelLabel: "PROMO END", excelVal: "promoEnd" },{ excelLabel: "PROD CD", excelVal: "prodCd" }
      ,{ excelLabel: "STATE", excelVal: "state" },{ excelLabel: "CITY", excelVal: "city" },{ excelLabel: "DMA", excelVal: "dma" }
      ,{ excelLabel: "NETWORK", excelVal: "network" }
      ];
    } else if(excelTypeTLAR === 'DQMT') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
	    { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "MTCN", excelVal: "mtcn" }
	    ,{ excelLabel: "DEVICE ID", excelVal: "deviceId" },{ excelLabel: "TYPE", excelVal: "type" }
      ];
    } else if(excelTypeTLAR === 'JOVP') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
      { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "NETWORK", excelVal: "network" }
      ,{ excelLabel: "ACCOUNT", excelVal: "account" },{ excelLabel: "NAME", excelVal: "name" },{ excelLabel: "BATCH_IND", excelVal: "batchInd" }
      ,{ excelLabel: "D2B_IND", excelVal: "d2bInd" },{ excelLabel: "CAN_IND", excelVal: "canInd" },{ excelLabel: "QUEUE", excelVal: "queue" }
      ,{ excelLabel: "HOME_DELIV", excelVal: "homeDelivery" },{ excelLabel: "AMOUNT", excelVal: "amount" },{ excelLabel: "WC_IND", excelVal: "wcInd" }
      ,{ excelLabel: "WC_QUEUE", excelVal: "wcQueue" },{ excelLabel: "MCIND", excelVal: "mcInd" },{ excelLabel: "MCACCOUNT", excelVal: "mcAccount" }
      ];
    } else if(excelTypeTLAR === 'FCAL') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
	    { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "USER ID", excelVal: "userId" }
	    ,{ excelLabel: "HOST ID", excelVal: "hostId" },{ excelLabel: "EMAILID", excelVal: "emailId" }
      ];
    } else if(excelTypeTLAR === 'WFLW' || excelTypeTLAR === 'QUEA') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },
	    { excelLabel: "ACTION", excelVal: "action" },{ excelLabel: "KEY", excelVal: "key" },{ excelLabel: "LOG DATA", excelVal: "logData" }
      ];
    } else if(excelTypeTLAR === 'ALL') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
	    { excelLabel: "KEY", excelVal: "key" },{ excelLabel: "DATA", excelVal: "data" }
      ];
    } else if(excelTypeTLAR === '0001') {
      excelColumnsData = [
      { excelLabel: "OPER ID", excelVal: "operatorId" },{ excelLabel: "DATE", excelVal: "date" },{ excelLabel: "ACTION", excelVal: "action" },
	    { excelLabel: "KEY", excelVal: "key" },{ excelLabel: "OPER ID", excelVal: "operatorId2" }
      ];
    }

    return excelColumnsData.map((item, i) => (
      <ExcelColumn label={item.excelLabel} value={item.excelVal} key={i} />
    ));
  };

  getTLARButtons = () => {
    // console.log(this.state.displayRecordsTLAR);
    return (
        <>
        {this.state.isLoadingFirst ? 
          <button className="btn btn-warning mar-right5" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;&nbsp;Loading...
          </button> :
          
          !this.props.history.location.submittedData.accessGrantedReadTL?<button
            type="button"
            className="btn btn-warning mar-right5"
            disabled
          >
            FIRST
          </button> :
          <button
            type="button"
            className="btn btn-warning mar-right5"
            data-test="button-first"
            onClick={this.onHandleFirstTLAR}
            disabled={this.state.disableFirstTLAR}
          >
            FIRST
          </button>
          }
          {this.state.isLoadingPrev ? 
          <button className="btn btn-warning mar-right5" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;&nbsp;Loading...
          </button> : 
          !this.props.history.location.submittedData.accessGrantedReadTL?
          <button
            type="button"
            className="btn btn-warning mar-right5"
            disabled
          >
            PREVIOUS
          </button>:
          <button
            type="button"
            className="btn btn-warning mar-right5"
            data-test="button-previous"
            onClick={this.onHandlePreviousTLAR}
            disabled={this.state.disablePreviousTLAR}
          >
            PREVIOUS
          </button>
          }
          {this.state.isLoadingNext ? 
          <button className="btn btn-warning mar-right5" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;&nbsp;Loading...
          </button> : 
           !this.props.history.location.submittedData.accessGrantedReadTL?<button
            type="button"
            className="btn btn-warning mar-right5"
            disabled
          >
            NEXT
          </button>
          :
          <button
            type="button"
            className="btn btn-warning mar-right5"
            data-test="button-next"
            onClick={this.onHandleNextTLAR}
            disabled={this.state.disableNextTLAR}
          >
            NEXT
          </button>
          }
          {this.state.isLoadingLast ? 
          <button className="btn btn-warning mar-right5" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;&nbsp;Loading...
          </button> : 
          !this.props.history.location.submittedData.accessGrantedReadTL?<button
            type="button"
            className="btn btn-warning mar-right5"
            disabled
          >
            LAST
          </button>:
          <button
            type="button"
            className="btn btn-warning mar-right5"
            data-test="button-last"
            onClick={this.onHandleLastTLAR}
            disabled={this.state.disableLastTLAR}
          >
            LAST
          </button>
          }
          <button
            type="button"
            className="btn btn-warning mar-right5"
            data-test="button-Back"
            onClick={this.onHandleBackTLAR}
          >
            TIPLOG SEARCH
          </button>
          <button
            type="button"
            className="btn btn-warning"
            data-test="button-history"
            onClick={this.onHandleExportToExcelTLAR}
            disabled={this.state.displayRecordsTLAR.length > 0 ? false : true}
          >
            EXCEL
          </button>
          <ExcelFile
              ref={(ref) => (this.excelrefTipLogAuditReport = ref)}
              filename="TipLogAuditReport"
              element={<></>}
            >
            <ExcelSheet
              data={this.state.displayRecordsTLAR}
              name="TipLogAuditReport"
            >
              {this.getExcelColumns()}
            </ExcelSheet>
          </ExcelFile>
      </>
    );
  }
  
  render() {
    return (
      <>
        <Header history={this.props.history} heading="" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading">TIPLOG AUDIT REPORT</h4>
            <div className="card ">
              <div className="card-body card-body-custom padding-10">
              <div className="row">
                <div className="col-lg-5">
                  {this.getTLARButtons()}
                </div>
                <div className="col-lg-3 padding-7">
                  <span className="font-weight-bold">Type:</span> {this.props.history.location.submittedData.searchValueTLS}
                </div>
                <div className="col-lg-4 padding-7">
                  <span className="font-weight-bold">Page:</span> {this.state.pageNumberTLAR}/{this.state.totalNumberOfPagesTLAR} of Max {this.state.noOfRecordsPerPageTLAR} Records Per Page
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-bordered mar-top10">
                  <thead>     
                     <tr>
                     {this.getTipLogAuditHeaders()}
                    </tr>
                  </thead>
                  <tbody>
                    {this.tipLogAuditData()}
                  </tbody>
                </table>
              </div>
              <div className="row mar-top10">
                <div className="col-lg-5">
                  {this.getTLARButtons()}
                </div>
                <div className="col-lg-7"></div>
              </div>
              </div>    
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TipLogAuditReport;
