import React, { Component } from "react";
import Header from "../../header/header";

import axios from "axios";

import { getHeadersShared } from "../../utility/utility";

export default class Mt6Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mt6Rules: {
        country: {
          numericCode: "",
          alphaCode: "",
          isoCode: "",
          name: "",
        },
        businessRules: {
          available: "N",
          limit: {
            days: 0,
            limitNew: 0,
            repeat: 0,
          },
          limitDaily: 0,
          verifiedLimit: {
            days: 0,
            limitNew: 0,
            repeat: 0,
          },
          verifiedLimitDaily: 0,
          fxCountryNum: 0,
          startDate: 0,
        },
        velocity: {
          transaction: 0,
          days: 0,
        },
        byPass: {
          allow: "N",
          rc: 0,
          prin: 0,
          cvv: 0,
        },
        idRequest: {
          yn: "N",
          trans: 0,
          aa: "N",
        },
        request: {
          cvv: "N",
          avs: "N",
        },
        newRulesDef: "N",
        saturnAARateCode: 0,
        authReversal: "N",
        emailDetails: "N",
        ibpPayment: "N",
        localCardPayment: "N",
        euIdDetails: "N",
      },
      submitStatusMt6Rules: null,
      submitMsgMt6Rules: "",
      
    };
  }

  getAvailablityMt6 = () => {
    let availableMt6 = ['Y','N'];
    let availableValues = availableMt6.map((item,i) => (
      <option key={item+i} value={item}>
        {item}
      </option>
    ));
    return [<option value="">-Select-</option>,availableValues];
  }

  getEnableDisableMt6 = () => {
    let availableMt6 = [{keyGEDM:'Y', valGEDM: 'Enabled'},{keyGEDM:'N', valGEDM:'Not Enabled'}];
    return availableMt6.map((item) => (
      <option key={item.keyGEDM} value={item.keyGEDM}>
        {item.valGEDM}
      </option>
    ));
  }

  onChangeControlMT6Rules = (inputName, e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    this.setState((prevState) => {
      return {
        mt6Rules: {
          ...prevState.mt6Rules,
          [inputName]: {
            ...prevState.mt6Rules[inputName],
            [newName]: newValue,
          },
        },
      };
    });
  };

  onChangeControlMT6RulesOnlyDecimal = (inputName, e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    this.setState((prevState) => {
      return {
        mt6Rules: {
          ...prevState.mt6Rules,
          [inputName]: {
            ...prevState.mt6Rules[inputName],
            [newName]: newValue.replace(/[^\d.]/, ""),
          },
        },
      };
    });
  };

  onChangeControlNonNestedMT6RulesInput = (e) => {
    let newValue = e.target.value;
    let newName = "saturnAARateCode";
    this.setState((prevState) => {
      return {
        mt6Rules: {
          ...prevState.mt6Rules,
          [newName]:newValue,
        },
      };
    });
  };

  onChangeControlMT6RulesOnlyNumber = (inputName, e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    this.setState((prevState) => {
      return {
        mt6Rules: {
          ...prevState.mt6Rules,
          [inputName]: {
            ...prevState.mt6Rules[inputName],
            [newName]: newValue.replace(/[^\d]/, ""),
          },
        },
      };
    });
  };

  onChangeControlNonNestedMT6Rules = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        mt6Rules: {
          ...prevState.mt6Rules,
          [name]: value,
        },
      };
    });
  };
  
  onChangeControlNestedMT6RulesOnlyDecimal = (inputName1, inputName2, e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    this.setState((prevState) => {
      return {
        mt6Rules: {
          ...prevState.mt6Rules,
          [inputName1]: {
            ...prevState.mt6Rules[inputName1],
            [inputName2]: {
              ...prevState.mt6Rules[inputName1][inputName2],
              [newName]: newValue.replace(/[^\d.]/, ""),
            },
          },
        },
      };
    });
  };

  onChangeControlNestedMT6RulesOnlyNumber = (inputName1, inputName2, e) => {
    let newValue = e.target.value;
    let newName = e.target.name;
    this.setState((prevState) => {
      return {
        mt6Rules: {
          ...prevState.mt6Rules,
          [inputName1]: {
            ...prevState.mt6Rules[inputName1],
            [inputName2]: {
              ...prevState.mt6Rules[inputName1][inputName2],
              [newName]: newValue.replace(/[^\d]/, ""),
            },
          },
        },
      };
    });
  };

  componentDidMount() {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/businessrules/mt6rules`,
        {
          headers: {
            ...getHeadersShared().headers,
            countryCode: this.props.location.state.countryCode,
            channel: this.props.location.state.channel,
            product: this.props.location.state.product,
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },
        }
      )
      .then((response) => {
        this.setState({
          mt6Rules: response.data,
          accessGrantedWriteBRM:this.props.location.state.accessGrantedWriteBRM,
        });
      });
      if (this.props.location.state.channel === "190") {
        this.setState({
          displayChannelMT6Rules: "WEB",
        });
      } else if (this.props.location.state.channel === "280") {
        this.setState({
          displayChannelMT6Rules: "TMT",
        });
      } else if (this.props.location.state.channel === "SMP") {
        this.setState({
          displayChannelMT6Rules: "SMP",
        });
      }
      if (this.props.location.state.product === "1") {
        this.setState({
          displayProductMT6Rules: "MT",
        });
      } else if (this.props.location.state.product === "2") {
        this.setState({
          displayProductMT6Rules: "QC",
        });
      }
  }
  onValidateMT6Rules = () => {
    if (this.state.mt6Rules.businessRules.available === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN AVAIL FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.limit.days === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN DAYS FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.limit.limitNew === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN LIMIT NEW FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.limit.limitNew > 9999999999.99) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "LIMIT NEW VALUE CANNOT BE GREATER THAN 9999999999.99",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.limit.repeat === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN LIMIT REPEAT FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.limit.repeat > 9999999999.99) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "LIMIT REPEAT VALUE CANNOT BE GREATER THAN 9999999999.99",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.limitDaily === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN LIMIT REPEAT FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.limitDaily > 9999999999.99) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "LIMIT DAILY VALUE CANNOT BE GREATER THAN 9999999999.99",
      });
      return false;
    }
    if (
      this.state.mt6Rules.businessRules.verifiedLimit.days === ""
    ) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN VERIFY DAYS FIELD",
      });
      return false;
    }
    if (
      this.state.mt6Rules.businessRules.verifiedLimit.limitNew === ""
    ) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN VERIFY LIMIT NEW FIELD",
      });
      return false;
    }
    if (
      this.state.mt6Rules.businessRules.verifiedLimit.limitNew > 9999999999.99
    ) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "VERIFY LIMIT NEW VALUE CANNOT BE GREATER THAN 9999999999.99",
      });
      return false;
    }
    if (
      this.state.mt6Rules.businessRules.verifiedLimit.repeat === ""
    ) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN VERIFY LIMIT REPEAT FIELD",
      });
      return false;
    }
    if (
      this.state.mt6Rules.businessRules.verifiedLimit.repeat > 9999999999.99
    ) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "VERIFY LIMIT REPEAT VALUE CANNOT BE GREATER THAN 9999999999.99",
      });
      return false;
    }
    if (
      this.state.mt6Rules.businessRules.verifiedLimitDaily === ""
    ) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN VERIFY LIMIT DAILY FIELD",
      });
      return false;
    }
    if (
      this.state.mt6Rules.businessRules.verifiedLimitDaily > 9999999999.99
    ) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "VERIFY LIMIT DAILY VALUE CANNOT BE GREATER THAN 9999999999.99",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.fxCountryNum === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN FX CNTRY NUM FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.businessRules.startDate === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN START DATE FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.velocity.transaction === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN VELOCITY TRANS FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.velocity.transaction > 99999) {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "VELOCITY TRANS VALUE CANNOT BE GREATER THAN 99999",
      });
      return false;
    }
    if (this.state.mt6Rules.velocity.days === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN VELOCITY DAYS FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.byPass.allow === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN BYPASS ALLOW Y/N FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.byPass.rc === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN BYPASS RATE CODE FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.byPass.prin === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN BYPASS PRIN FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.byPass.cvv === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN BYPASS CVV FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.idRequest.yn === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN IDREQD Y/N FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.idRequest.trans === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN IDREQD TRANS FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.idRequest.aa === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN IDREQD AUTO APPROVE FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.request.cvv === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN REQD CVV FIEL",
      });
      return false;
    }
    if (this.state.mt6Rules.request.avs === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN REQD AVS FIELD",
      });
      return false;
    }
    if (this.state.mt6Rules.newRulesDef === "") {
      this.setState({
        submitStatusMt6Rules: "Error",
        submitMsgMt6Rules: "ENTER VALUE IN NEW RULES DEF FIELD",
      });
      return false;
    }
    return true;
  };

  handleSubmitMT6Rules = () => {
    if (!this.onValidateMT6Rules()) {
      return;
    }
    let submittedObj = {};
    submittedObj = this.state.mt6Rules;
    //console.log(submittedObj)
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/businessrules/mt6rules`,
        submittedObj,
        {
          headers: {
            ...getHeadersShared().headers,
            channel: this.props.location.state.channel,
            Authorization:this.props.location.state.token,
            "userId":this.props.location.state.operatorIdBRM
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusMt6Rules: "OK",
            submitMsgMt6Rules: response.data.responseString,
          });
        } else {
          this.setState({
            submitStatusMt6Rules: "Error",
            submitMsgMt6Rules: response.data.responseString,
          });
        }
      });
  };

  handleBacktMT6Rules = () => {
    this.props.history.push({
      pathname: "/display-country-rules",
      state: this.props.location.state
    });
  };

  handleBusinessRuleMaintMT6Rules = () => {
    this.props.history.push("/business-rules-maintenance");
  };
  render() {
    var date = new Date();
    var currentDate = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return (
      <>
        <Header
          history={this.props.history}
          heading="BUSINESS RULES MAINTENANCE"
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-center main-heading">
                BUSINESS MT6 RULE MAINTENANCE FOR{" "}
                {this.state.displayChannelMT6Rules}{" "}
                {this.state.displayProductMT6Rules} Date&nbsp;:&nbsp;{month>9?month:'0'+month}/{currentDate>9?currentDate:'0'+currentDate}/{year.toString().substr(-2)}
              </h4>
              <div className="card ">
                <div className="card-header bg-dark text-white card-header-custom">COUNTRY</div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="numericCode">Numeric Code</label>
                        <input
                          type="text"
                          value={
                            this.state.mt6Rules.country.numericCode
                          }
                          className="form-control form-control-sm"
                          placeholder="Numeric Code"
                          data-test="text-countryNumericCode"
                          disabled
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="alphaCode">Alpha Code</label>
                        <input
                          type="text"
                          value={
                            this.state.mt6Rules.country.alphaCode
                          }
                          className="form-control form-control-sm"
                          placeholder="Alpha Code"
                          data-test="text-countryAlphaCode"
                          disabled
                        />
                      </div>

                      <div className="col-lg">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          value={this.state.mt6Rules.country.name}
                          className="form-control form-control-sm"
                          placeholder="Name"
                          data-test="text-countryName"
                          disabled
                        />
                      </div>

                      <div className="col-lg">
                        <label htmlFor="isoCode">ISO Code</label>
                        <input
                          type="text"
                          value={this.state.mt6Rules.country.isoCode}
                          className="form-control form-control-sm"
                          placeholder="ISO Code"
                          data-test="text-countryISOCode"
                          disabled
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>{" "}
              <div className="card mar-top20 ">
                <div className="card-header bg-dark text-white card-header-custom">
                  BUSINESS RULES
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group ">
                      <div className="row">
                        <div className="col-lg">
                          <label htmlFor="available">Available</label>
                          <select
                            value={
                              this.state.mt6Rules.businessRules
                                .available
                            }
                            className="form-control form-control-sm"
                            name="available"
                            onChange={(e) => {
                              this.onChangeControlMT6Rules("businessRules", e);
                            }}
                            placeholder="Available"
                            data-test="text-businessRulesAvailable"
                          >
                            {this.getAvailablityMt6()}
                          </select>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="days">Days</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={
                              this.state.mt6Rules.businessRules.limit
                                .days
                            }
                            className="form-control form-control-sm"
                            name="days"
                            onChange={(e) => {
                              this.onChangeControlNestedMT6RulesOnlyNumber(
                                "businessRules",
                                "limit",
                                e
                              );
                            }}
                            placeholder="Days"
                            data-test="text-businessRulesLimitDays"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="new">Limit New</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.mt6Rules.businessRules.limit
                                .limitNew
                            }
                            className="form-control form-control-sm"
                            name="limitNew"
                            onChange={(e) => {
                              this.onChangeControlNestedMT6RulesOnlyDecimal(
                                "businessRules",
                                "limit",
                                e
                              );
                            }}
                            placeholder="Limit New"
                            data-test="text-businessRulesLimitNew"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="repeat">Limit Repeat</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.mt6Rules.businessRules.limit
                                .repeat
                            }
                            className="form-control form-control-sm"
                            name="repeat"
                            onChange={(e) => {
                              this.onChangeControlNestedMT6RulesOnlyDecimal(
                                "businessRules",
                                "limit",
                                e
                              );
                            }}
                            placeholder="Limit Repeat"
                            data-test="text-businessRulesLimitRepeat"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="limitDaily">Limit Daily</label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.mt6Rules.businessRules
                                .limitDaily
                            }
                            className="form-control form-control-sm"
                            name="limitDaily"
                            onChange={(e) => {
                              this.onChangeControlMT6RulesOnlyDecimal("businessRules", e);
                            }}
                            placeholder="Limit Daily"
                            data-test="text-businessRulesLimitDaily"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg mar-top5">
                          {" "}
                          <label htmlFor="available">Verified Limits</label>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="verifiedLimitdays">Days</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={
                              this.state.mt6Rules.businessRules
                                .verifiedLimit.days
                            }
                            className="form-control form-control-sm"
                            name="days"
                            onChange={(e) => {
                              this.onChangeControlNestedMT6RulesOnlyNumber(
                                "businessRules",
                                "verifiedLimit",
                                e
                              );
                            }}
                            placeholder="Days"
                            data-test="text-businessRulesVerifiedLimitDays"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="verifiedLimitNew">Limit New</label>
                          <input
                            type="text"
                            value={
                              this.state.mt6Rules.businessRules
                                .verifiedLimit.limitNew
                            }
                            className="form-control form-control-sm"
                            name="limitNew"
                            maxLength="13"
                            onChange={(e) => {
                              this.onChangeControlNestedMT6RulesOnlyDecimal(
                                "businessRules",
                                "verifiedLimit",
                                e
                              );
                            }}
                            placeholder="Limit New"
                            data-test="text-businessRulesVerifiedLimitNew"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="verifiedLimitrepeat">
                            Limit Repeat
                          </label>
                          <input
                            type="text"
                            value={
                              this.state.mt6Rules.businessRules
                                .verifiedLimit.repeat
                            }
                            className="form-control form-control-sm"
                            name="repeat"
                            maxLength="13"
                            onChange={(e) => {
                              this.onChangeControlNestedMT6RulesOnlyDecimal(
                                "businessRules",
                                "verifiedLimit",
                                e
                              );
                            }}
                            placeholder="Limit Repeat"
                            data-test="text-businessRulesVerifiedLimitRepeat"
                          />
                        </div>
                        <div className="col-lg">
                          <label htmlFor="verifiedLimitDaily">
                            Limit Daily
                          </label>
                          <input
                            type="text"
                            maxLength="13"
                            value={
                              this.state.mt6Rules.businessRules
                                .verifiedLimitDaily
                            }
                            className="form-control form-control-sm"
                            name="verifiedLimitDaily"
                            onChange={(e) => {
                              this.onChangeControlMT6RulesOnlyDecimal("businessRules", e);
                            }}
                            placeholder="Limit Daily"
                            data-test="text-businessRulesVerifiedLimitDaily"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg mar-top5">
                          <label htmlFor="fxCountryNum">Fx Cntry Num</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={
                              this.state.mt6Rules.businessRules
                                .fxCountryNum
                            }
                            className="form-control form-control-sm"
                            name="fxCountryNum"
                            onChange={(e) => {
                              this.onChangeControlMT6RulesOnlyNumber(
                                "businessRules",
                                e
                              );
                            }}
                            placeholder="Fx Cntry Num"
                            data-test="text-businessRulesFxCountryNum"
                          />
                        </div>
                        <div className="col-lg mar-top5">
                          <label htmlFor="startDate"> Start Date(YYDDD)</label>
                          <input
                            type="text"
                            maxLength="5"
                            value={
                              this.state.mt6Rules.businessRules
                                .startDate
                            }
                            className="form-control form-control-sm"
                            name="startDate"
                            onChange={(e) => {
                              this.onChangeControlMT6RulesOnlyNumber(
                                "businessRules",
                                e
                              );
                            }}
                            placeholder="	Start Date"
                            data-test="text-businessRulesStartDate"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg">
                          <div className="card mar-top20">
                            <div className="card-header bg-dark text-white  ">
                              Velocity
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="transaction">
                                    Transaction
                                  </label>
                                  <input
                                    type="text"
                                    maxLength="5"
                                    value={
                                      this.state.mt6Rules.velocity
                                        .transaction
                                    }
                                    className="form-control form-control-sm"
                                    name="transaction"
                                    onChange={(e) => {
                                      this.onChangeControlMT6RulesOnlyNumber(
                                        "velocity",
                                        e
                                      );
                                    }}
                                    placeholder="transaction"
                                    data-test="text-velocityTranscation"
                                  />
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="days">Days</label>
                                  <input
                                    type="text"
                                    maxLength="2"
                                    value={
                                      this.state.mt6Rules.velocity
                                        .days
                                    }
                                    className="form-control form-control-sm"
                                    name="days"
                                    onChange={(e) => {
                                      this.onChangeControlMT6RulesOnlyNumber(
                                        "velocity",
                                        e
                                      );
                                    }}
                                    placeholder="Days"
                                    data-test="text-velocityDays"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="card  mar-top20">
                            <div className="card-header bg-dark text-white ">
                              By Pass{" "}
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="allow">Allow</label>
                                  <select
                                    value={
                                      this.state.mt6Rules.byPass
                                        .allow
                                    }
                                    className="form-control form-control-sm"
                                    name="allow"
                                    onChange={(e) => {
                                      this.onChangeControlMT6Rules("byPass", e);
                                    }}
                                    data-test="text-byPassAllow"
                                  >
                                    {this.getAvailablityMt6()}
                                  </select>
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="rc">RC</label>
                                  <input
                                    type="text"
                                    maxLength="1"
                                    value={
                                      this.state.mt6Rules.byPass.rc
                                    }
                                    className="form-control form-control-sm"
                                    name="rc"
                                    onChange={(e) => {
                                      this.onChangeControlMT6RulesOnlyNumber(
                                        "byPass",
                                        e
                                      );
                                    }}
                                    placeholder="RC"
                                    data-test="text-byPassRC"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="prin">Prin</label>
                                  <input
                                    type="text"
                                    maxLength="5"
                                    value={
                                      this.state.mt6Rules.byPass.prin
                                    }
                                    className="form-control form-control-sm"
                                    name="prin"
                                    onChange={(e) => {
                                      this.onChangeControlMT6RulesOnlyNumber(
                                        "byPass",
                                        e
                                      );
                                    }}
                                    placeholder="Prin"
                                    data-test="text-byPassPrin"
                                  />
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="cvv">CVV</label>
                                  <input
                                    type="text"
                                    maxLength="5"
                                    value={
                                      this.state.mt6Rules.byPass.cvv
                                    }
                                    className="form-control form-control-sm"
                                    name="cvv"
                                    onChange={(e) => {
                                      this.onChangeControlMT6RulesOnlyNumber(
                                        "byPass",
                                        e
                                      );
                                    }}
                                    placeholder="CVV"
                                    data-test="text-byPassCvv"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="card mar-top20">
                            <div className="card-header bg-dark text-white ">
                              ID Request
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="yn">Y/N</label>
                                  <select
                                    value={
                                      this.state.mt6Rules.idRequest
                                        .yn
                                    }
                                    className="form-control form-control-sm"
                                    name="yn"
                                    onChange={(e) => {
                                      this.onChangeControlMT6Rules(
                                        "idRequest",
                                        e
                                      );
                                    }}
                                    data-test="text-idRequestYN"
                                  >
                                    {this.getAvailablityMt6()}
                                  </select>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="trans">Trans</label>
                                  <input
                                    type="text"
                                    maxLength="1"
                                    value={
                                      this.state.mt6Rules.idRequest
                                        .trans
                                    }
                                    className="form-control form-control-sm"
                                    name="trans"
                                    onChange={(e) => {
                                      this.onChangeControlMT6RulesOnlyNumber(
                                        "idRequest",
                                        e
                                      );
                                    }}
                                    placeholder="Trans"
                                    data-test="text-idRequestTrans"
                                  />
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="aa">AA</label>
                                  <select
                                    value={
                                      this.state.mt6Rules.idRequest
                                        .aa
                                    }
                                    className="form-control form-control-sm"
                                    name="aa"
                                    onChange={(e) => {
                                      this.onChangeControlMT6Rules(
                                        "idRequest",
                                        e
                                      );
                                    }}
                                    data-test="text-idRequestAA"
                                  >
                                    {this.getAvailablityMt6()}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="card mar-top20">
                            <div className="card-header bg-dark text-white ">
                              Request
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="cvv">CVV</label>
                                  <select
                                    value={
                                      this.state.mt6Rules.request.cvv
                                    }
                                    className="form-control form-control-sm"
                                    name="cvv"
                                    onChange={(e) => {
                                      this.onChangeControlMT6Rules(
                                        "request",
                                        e
                                      );
                                    }}
                                    data-test="text-requestCVV"
                                  >
                                    {this.getAvailablityMt6()}
                                  </select>
                                </div>
                                <div className="col-lg">
                                  <label htmlFor="avs">AVS</label>
                                  <select
                                    value={
                                      this.state.mt6Rules.request.avs
                                    }
                                    className="form-control form-control-sm"
                                    name="avs"
                                    onChange={(e) => {
                                      this.onChangeControlMT6Rules(
                                        "request",
                                        e
                                      );
                                    }}
                                    data-test="text-requestAVS"
                                  >
                                    {this.getAvailablityMt6()}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg">
                          <div className="card mar-top20">
                            <div className="card-header bg-dark text-white ">
                              New Rules Def
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg">
                                  <label htmlFor="newRulesDef">
                                    New Rules Def
                                  </label>
                                  <select
                                    value={
                                      this.state.mt6Rules.newRulesDef
                                    }
                                    className="form-control form-control-sm"
                                    name="newRulesDef"
                                    onChange={(e) => {
                                      this.onChangeControlNonNestedMT6Rules(e);
                                    }}
                                    data-test="text-newRulesDef"
                                  >
                                    {this.getAvailablityMt6()}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card-body mar-top20 card-header-custom">
                <div className="row">
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        SATURN
                      </div>
                      <div className="card-body">
                        <form>
                          <label htmlFor="autoApprRateCode">
                            Auto Approve Rate Code
                          </label>
                          <input
                            type="text"
                            maxLength="1"
                            value={
                              this.state.mt6Rules.saturnAARateCode
                            }
                            className="form-control form-control-sm"
                            name="autoApprRateCode"
                            onChange={(e) => {
                              this.onChangeControlNonNestedMT6RulesInput(e);
                            }}
                            placeholder="Auto Approve Rate Code"
                            data-test="text-saturnAutoApprRateCode"
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        AUTH REVERSAL
                      </div>
                      <div className="card-body">
                        <label className="mar-top20 ">AUTH REVERSAL</label>
                        <select
                          className="form-control  form-control-sm mar-bottom20"
                          name="authReversal"
                          data-test="text-authReversal"
                          onChange={(e) => {
                            this.onChangeControlNonNestedMT6Rules(e);
                          }}
                          value={this.state.mt6Rules.authReversal}
                        >
                          {this.getEnableDisableMt6()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        EMAIL DETAILS
                      </div>
                      <div className="card-body">
                        <label className="mar-top20 ">EMAIL DETAILS</label>
                        <select
                          className="form-control  form-control-sm mar-bottom20"
                          aria-label=".form-select-lg example"
                          name="emailDetails"
                          data-test="text-emailDetails"
                          onChange={(e) => {
                            this.onChangeControlNonNestedMT6Rules(e);
                          }}
                          value={this.state.mt6Rules.emailDetails}
                        >
                            {this.getEnableDisableMt6()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        IBP PAYMENT
                      </div>
                      <div className="card-body">
                        <label className="mar-top20 ">IBP PAYMENT</label>
                        <select
                          className="form-control  form-control-sm mar-bottom20"
                          aria-label=".form-select-lg example"
                          name="ibpPayment"
                          data-test="text-ibpPayment"
                          onChange={(e) => {
                            this.onChangeControlNonNestedMT6Rules(e);
                          }}
                          value={this.state.mt6Rules.ibpPayment}
                        >
                            {this.getEnableDisableMt6()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        LOCAL CARD PAYMENT
                      </div>
                      <div className="card-body">
                        <label className="mar-top20 ">LOCAL CARD PAYMENT</label>
                        <select
                          className="form-control  form-control-sm mar-bottom20"
                          aria-label=".form-select-lg example"
                          name="localCardPayment"
                          data-test="text-localCardPayment"
                          onChange={(e) => {
                            this.onChangeControlNonNestedMT6Rules(e);
                          }}
                          value={
                            this.state.mt6Rules.localCardPayment
                          }
                        >
                            {this.getEnableDisableMt6()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        EUID DETAILS
                      </div>
                      <div className="card-body">
                        <label className="mar-top20 ">EUID DETAILS</label>
                        <select
                          className="form-control  form-control-sm mar-bottom20"
                          aria-label=".form-select-lg example"
                          name="euIdDetails"
                          data-test="text-euIdDetails"
                          onChange={(e) => {
                            this.onChangeControlNonNestedMT6Rules(e);
                          }}
                          value={this.state.mt6Rules.euIdDetails}
                        >
                            {this.getEnableDisableMt6()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 text-center mar-top5 mar-bottom5">
                  <button
                    type="button"
                    className="btn btn-warning mar-right10 mar-left100"
                    onClick={this.handleSubmitMT6Rules}
                    data-test="button-display-country-rules-update"
                    disabled={!this.state.accessGrantedWriteBRM}
                  >
                    UPDATE
                  </button>
                  <button
                    type="button"
                    onClick={this.handleBacktMT6Rules}
                    className="btn btn-warning mar-right10 mar-left100"
                    data-test="button-display-country-rules-back"
                  >
                    BUSINESS RULES
                  </button>
                  <button
                    type="button"
                    onClick={this.handleBusinessRuleMaintMT6Rules}
                    className="btn btn-warning mar-right10 mar-left100"
                    data-test="button-display-country-rules-business-rule"
                  >
                    BUSINESS RULE MAINTENANCE
                  </button>
                </div>
                <div className="col-lg-3 mar-top5">
                </div>
              </div>
              <div className="row mar-top10">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                        {this.state.submitStatusMt6Rules === "OK" && (
                    <div className="alert alert-success alert-padding notification">
                      {this.state.submitMsgMt6Rules}
                    </div>
                  )}
                  {this.state.submitStatusMt6Rules === "Error" && (
                    <div className="alert alert-danger alert-padding notification">
                      {this.state.submitMsgMt6Rules}
                    </div>
                  )}
                        </div>
                        <div className="col-lg-4"></div>
                      </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
