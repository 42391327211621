import React, { Component } from "react";
import Header from "../../header/header";
import axios from "axios";
import { getHeadersOrder } from "../../utility/utility";

class MoneyTransferMadrec extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      noData: null,
      mtcn: '',
      isLoading:true,
      mtmMsg: ''
    };
  }
  

  componentDidMount() {
    this.setState({
      mtcn: this.props.history.location.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
    let submittedObj = {};
    submittedObj.mtcn = this.props.history.location.mtcn;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/madrecbuff`,submittedObj,
      {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.history.location.jwtToken
        },
      })
      .then((response) => {
        //console.log(response);
        if (response.data.flag === 1) {
          this.setState({
            allData: response.data.records,
            noData: false,
            isLoading: false
          });
        } else {
          this.setState({
            noData: true,
            isLoading: false,
            mtmMsg: response.data.message
          });
        }
      });
  }

  onHandleBackMadrecMTCNDetails = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackMadrecMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  showMadrecData = () => {
    //console.log(this.state.allData.length);
    if (this.state.noData === false) {
      return this.state.allData.map((record, i) => {
        return (
          <div className="row mar-top10" key={`${i}+rec`}>
            <div className="col-lg-2"></div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body card-body-custom">
                  <div className="row">
                    <div className="col-lg-9">
                    <span className="font-weight-bold">Record Type :</span> {record.madtype}
                    </div>
                    {/* <div className="col-lg-3">Length : {record.length}</div> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-9">
                    <span className="font-weight-bold">Madrec Buffer :</span> {record.madRecBuff}
                    </div>
                    {/* <div className="col-lg-3">SeqNo : {record.seqNo}</div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body mtcn-madrec-data card-body-custom">
                  <div className="table-responsive">
                    <table className="table table-bordered margin-0 table-sm ">
                      <thead>
                        <tr>
                          <th scope="col" className="col-lg-2">ID:LEN</th>
                          <th scope="col" className="col-lg-10">DATA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          record.mtubbuffPars && record.mtubbuffPars.length > 0 && record.mtubbuffPars.map((data, iInner) => (
                            <tr key={`${iInner}+len`}>
                              <td className="col-lg-2">{data.name}</td>
                              <td className="col-lg-10">{data.type}</td>
                            </tr>
                          ))
                         }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>                  
          </div>
        );
      });
    } else if(this.state.noData === true) {
    return <div className="row mar-top5"><div className="col-lg text-center notification">{this.state.mtmMsg}</div></div>;
    }
  };

  render() {
    return (
      <div>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <div className="card-body">
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                Money Transfer Madrec Buffer Dump
              </div>
              <div className="card-body card-body-custom">
                <div className="form-row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-2">
                    <label htmlFor="mtcn"><span className="font-weight-bold">MTCN : </span>{this.state.mtcn}</label>
                  </div>
                  <div className="col-lg-8" />
                </div>
                {
                 this.state.isLoading ? 
                 <div className="text-center">
                   <div className="spinner-border text-warning " role="status">
                     <span className="sr-only">Loading...</span>
                   </div>
                 </div>
                 :    
                this.showMadrecData()
                
                }
                <div className="text-center mt-2">
                  <button
                    type="button"
                    className="btn btn-warning mr-2"
                    onClick={this.onHandleBackMadrecMTCNDetails}
                    data-test='MTCNDetails-button-back'
                  >
                    MTCN Details
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackMadrecMTCNSearch}
                    data-test='Search-button-back'
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MoneyTransferMadrec;
