import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersAgent } from "../../utility/utility";

class AgentBatchCheck extends Component {
  state = {
    agentBatchCheckValues: {},
    count: 0,
    allData: [],
    agentBatchCheckTable: [],
    //enablePrev: false,
    enableNext: true,
    submitStatusAgentBatchCheck: null,
    submitMsgAgentBatchCheck: "",
    showMessage:null
  };

  onHandleBackAgentBatchCheck = () => {
    this.props.history.push({
      pathname: "/check-control-utility",
    });
  };

  componentDidMount() {
    // console.log(this.props.location.state)
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/batchsequence/${this.props.location.state}`,
        {
          headers: {
            ...getHeadersAgent().headers,
            Authorization: this.props.location.data.tokenCCU,
            userId: this.props.location.data.operatorIdCCU,
          },
        }
      )
      .then((response) => {
        let tempEnableNext = response.data.record !== null ||response.data.records.length > 1 ? true : false;
        this.setState({
          enableNext: tempEnableNext,
        });
        if (response.data.flag === 0) {
          this.setState({
            submitStatusAgentBatchCheck: "Error",
            submitMsgAgentBatchCheck:response.data.message,
            showMessage: true
          });
        } else {
          if (response.data.records.length > 0) {
            this.setState({
              allData: response.data.records,
              agentBatchCheckValues: response.data.records[0],
              agentBatchCheckTable: response.data.records[0].listOfBits,
              agentAccount: this.props.location.state.agentAccount,
              showMessage: false
            });
          }
        }
      });
  }

  getCCUHeaders = () => {
    let ccuHeader = [
      "CHECK",
      "B01",
      "B02",
      "B03",
      "B04",
      "B05",
      "B06",
      "B07",
      "B08",
      "B09",
      "B10",
      "B11",
      "B12",
      "B13",
      "B14",
      "B15",
      "B16",
      "B17",
      "B18",
      "B19",
      "B20",
    ];
    return ccuHeader.map((item, i) => (
      <th key={i} scope="row">
        {item}
      </th>
    ));
  };

  onHandleNextAgentBatchCheck = (type) => {
    // if (type === "prev") {
    //   this.setState(
    //     {
    //       count: this.state.count - 1,
    //     },
    //     () => {
    //       this.setState({
    //         enableNext: true,
    //         agentBatchCheckValues: this.state.allData[this.state.count],
    //         agentBatchCheckTable: this.state.allData[this.state.count].listOfBits,
    //       });
    //       if (this.state.count === 0) {
    //         this.setState({
    //           enablePrev: false,
    //           enableNext: true,
    //         });
    //       }
    //     }
    //   );
    // } else {
      if (this.state.count < this.state.allData.length - 1) {
        this.setState(
          {
            count: this.state.count + 1,
          },
          () => {
            this.setState({
              //enablePrev: true,
              agentBatchCheckValues: this.state.allData[this.state.count],
              agentBatchCheckTable:
                this.state.allData[this.state.count].listOfBits,
            });
            if (this.state.count === this.state.allData.length - 1) {
              this.setState({
                //enablePrev: true,
                enableNext: false,
              });
            }
          }
        );
      }
    // }
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="CHECK CONTROL UTILITY" />
        <div className="container-fluid">
          <h4 className="card-title main-heading ">
            Agent Check Control Utility
          </h4>
          <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  Agent Batch Check Header
                </div>
                <div className="card-body card-body-custom">
                  {Object.keys(this.state.agentBatchCheckValues).length > 0 && !this.state.showMessage &&
                    <form>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg">
                          <label className="font-weight-bold">
                            Agent: {this.state.agentBatchCheckValues.agent}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg">
                          <label htmlFor="chkbatch" className="font-weight-bold">
                            CHKBATCH:{" "}
                            {this.state.agentBatchCheckValues.chkbatch}
                          </label>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="chkfirst" className="font-weight-bold">
                            CHKFIRST:{" "}
                            {this.state.agentBatchCheckValues.chkfirst}
                          </label>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="chkaccnt2" className="font-weight-bold">
                            CHKACCNT2:{" "}
                            {this.state.agentBatchCheckValues.chkaccnt2}
                          </label>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="chkdone" className="font-weight-bold">
                            CHKDONE: {this.state.agentBatchCheckValues.chkdone}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-1" />
                        <div className="col-lg">
                          <label htmlFor="chkbank" className="font-weight-bold">
                            CHKBANK: {this.state.agentBatchCheckValues.chkbank}
                          </label>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="chklast" className="font-weight-bold">
                            CHKLAST: {this.state.agentBatchCheckValues.chklast}
                          </label>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="chkstale" className="font-weight-bold">
                            CHKSTALE:{" "}
                            {this.state.agentBatchCheckValues.chkstale}
                          </label>
                        </div>
                        <div className="col-lg">
                          <label htmlFor="chkbcode" className="font-weight-bold">
                            CHKBCODE:{" "}
                            {this.state.agentBatchCheckValues.chkbcode}
                          </label>
                        </div>
                      </div>
                    </form>
                  }             

                   {this.state.showMessage && <div className="row">
                      <div className="col-lg text-center mar-top5 font-weight-bold">
                        NO BATCH RECORD FOUND FOR AGENT
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-1" />
          </div>
          <div className="form-group row">
            <div className="col-lg-3" />
            <div className="col-lg-6 text-center mar-bottom5 mar-top5">
              {/* <button
                type="button"
                className="btn btn-warning mar-right10"
                data-test="button-update"
                onClick={() => this.onHandleNextAgentBatchCheck("prev")}
                //disabled={!this.state.enableNext}
              >
                PREVIOUS
              </button> */}
              <button
                type="button"
                className="btn btn-warning mar-right10"
                data-test="button-Agent-Batch-Check-update"
                onClick={() => this.onHandleNextAgentBatchCheck("next")}
                disabled={!this.state.enableNext}
              >
                NEXT
              </button>
              <button
                type="button"
                className="btn btn-warning"
                data-test="button-back"
                onClick={this.onHandleBackAgentBatchCheck}
              >
                NEW SELECTION
              </button>
            </div>
            <div className="col-lg-3" />
          </div>
          <div className="form-group row">
                <div className="col-lg-3" />
                <div className="col-lg-6">
                  {this.state.submitStatusAgentBatchCheck === "OK" && (
                    <div className="alert alert-success alert-padding notification text-center">
                      {this.state.submitMsgAgentBatchCheck}
                    </div>
                  )}
                  {this.state.submitStatusAgentBatchCheck === "Error" && (
                    <div className="alert alert-danger alert-padding notification text-center">
                      {this.state.submitMsgAgentBatchCheck}
                    </div>
                  )}
                </div>
                <div className="col-lg-3" />
              </div>
          {Object.keys(this.state.agentBatchCheckTable).length > 0 && !this.state.showMessage &&
            <div className="card-body padding-4">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark text-center">
                  <tr>{this.getCCUHeaders()}</tr>
                </thead>
                <tbody>
                  {this.state.agentBatchCheckTable.map((el, i) => (
                    <tr key={i} data-test="tr">
                      <td>{el.check}</td>
                      <td>{el.b01}</td>
                      <td>{el.b02}</td>
                      <td>{el.b03}</td>
                      <td>{el.b04}</td>
                      <td>{el.b05}</td>
                      <td>{el.b06}</td>
                      <td>{el.b07}</td>
                      <td>{el.b08}</td>
                      <td>{el.b09}</td>
                      <td>{el.b10}</td>
                      <td>{el.b11}</td>
                      <td>{el.b12}</td>
                      <td>{el.b13}</td>
                      <td>{el.b14}</td>
                      <td>{el.b15}</td>
                      <td>{el.b16}</td>
                      <td>{el.b17}</td>
                      <td>{el.b18}</td>
                      <td>{el.b19}</td>
                      <td>{el.b20}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
    
          {this.state.showMessage && <div className="row">
              <div className="col-lg text-center mar-top5 font-weight-bold">
                No Table Records Found
              </div>
            </div>
          }
        </div>
      </>
    );
  }
}

export default AgentBatchCheck;
