import React, { Component } from "react";
import axios from "axios";
import { AgGridReact as AgGridReactWorkflowMonitor1 } from "ag-grid-react";
import { AgGridReact as AgGridReactWorkflowMonitor2 } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { getHeadersOrder } from "../../utility/utility";

class WorkflowMonitor extends Component {
  state = {
    queueStatus: "",
    queueStatusDesc: "",
    count5Mins: "",
    count5To15Mins: "",
    count15Mins: "",
    dateTime: "",
    oldestMtcn: "",
    workFlowStatus: "",
    countRecords: [
      {
        field: "queueStatus",
        headerName: "Queue Status",
        width: "230px",
      },
      {
        field: "queueStatusDesc",
        headerName: "Queue Status Desc",
        width: "225px",
      },
      {
        field: "count5Mins",
        headerName: "Count<5 Min",
        width: "225px",
      },
      {
        field: "count5To15Mins",
        headerName: "Count 5-15 Min",
        width: "225px",
      },
      {
        field: "count15Mins",
        headerName: "Count>15 Min",
        width: "225px",
      },
    ],
    statusRecords: [
      {
        field: "queueStatus",
        headerName: "Queue Status",
        width: "285px",
      },
      {
        field: "queueStatusDesc",
        headerName: "Queue Status Desc",
        width: "280px",
      },
      {
        field: "dateTime",
        headerName: "Date/Time",
        width: "280px",
      },
      {
        field: "oldestMtcn",
        headerName: "OLDEST MTCN",
        width: "280px",
      },
    ],
    rows: null,
    statusRecordsData: [],
    countRecordsData: [],
    enableBtn: false,
    gridApi: null,
    paginationValWorkflow: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusWorkflowMonitor: null,
    submitMsgWorkflowMonitor: "",
    paginationOptionWorkflowMonitor: [25, 50, 100, 500],
    overlayLoadingTemplateWM:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateWM: '<span style="">No Records Found</span>',
  };
  componentDidMount(){
    this.setState({
      accessGrantQAWM:this.props.location.state.accessGrantQAWM,
      accessGrantWriteQAWM:this.props.location.state.accessGrantWriteQAWM,
      operatorIdQAWM:this.props.location.state.operatorIdQAWM,
      tokenQAWM:this.props.location.state.tokenQAWM,
    })
  }
  onGridReadyWorkflowMonitor1 = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/workflowmonitor`,{
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.location.state.tokenQAWM,
            userId: this.props.location.state.operatorIdQAWM}})
      .then((response) => {
        let recWM1=[];
        if(response.data.records !== null){
          recWM1=response.data.countRecords
        }
        this.setState({
          countRecordsData: recWM1,
          workFlowStatus: response.data.workFlowStatus,
          currentDateTime: response.data.currentDateTime,
        });
      });
  };

  onGridReadyWorkflowMonitor2 = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/workflowmonitor`,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.location.state.tokenQAWM,
            userId: this.props.location.state.operatorIdQAWM,
          },
        }
      )
      .then((response) => {
        let recWM2=[];
        if(response.data.records !== null){
          recWM2=response.data.statusRecords
        }
        this.setState({
          statusRecordsData: recWM2,
        });
      });
  };

  onPageSizeChangedWorkflowMonitor = (e) => {
    this.setState({ paginationValWorkflow: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackWorkflowMonitor = () => {
    this.props.history.push("/queue-age-monitor");
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="QUEUE AGE MONITOR" />
        <div className="container-fluid" data-test="component-workflow-monitor">
          <div className="card-body">
            <h4 className="card-title main-heading "> WORKFLOW MONITOR</h4>
            <p className=" datetime-mar-left">
              <b>Date/Time : {this.state.currentDateTime}</b>
            </p>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                QUEUE AGE WORKFLOW MONITOR
              </div>

              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label" className="font-weight-bold">
                    No of records per page:
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onPageSizeChangedWorkflowMonitor(e);
                    }}
                    value={this.state.paginationValWorkflow}
                    name="paginationDropdownWorkflow"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionWorkflowMonitor.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactWorkflowMonitor1
                    rowData={this.state.countRecordsData}
                    columnDefs={this.state.countRecords}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyWorkflowMonitor1}
                    data-test="component-grid"
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={this.state.overlayLoadingTemplateWM}
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplateWM}
                  ></AgGridReactWorkflowMonitor1>
                </div>
              </div>
              <div className="card-body card-body-custom">
                <div
                  className="ag-theme-balham"
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReactWorkflowMonitor2
                    rowData={this.state.statusRecordsData}
                    columnDefs={this.state.statusRecords}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyWorkflowMonitor2}
                    data-test="component-grid-1"
                    enableCellTextSelection={true}
                    overlayLoadingTemplate={this.state.overlayLoadingTemplateWM}
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplateWM}
                  ></AgGridReactWorkflowMonitor2>
                </div>
              </div>
              <div className="text-center mar-bottom20 mar-top5">
                <b>WORKFLOW STATUS ON MT SYSTEM:{this.state.workFlowStatus}</b>
              </div>
              <div className="form-group row">
                <div className="text-center col-lg">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={this.onHandleBackWorkflowMonitor}
                    data-test="button-back"
                  >
                    GO TO QUE GROUPS
                  </button>
                </div>
                <div className="col-lg-0" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default WorkflowMonitor;
