import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";

import { getHeadersShared2 } from "../../utility/utility";

class WhitelistFraudRuleC2C extends Component {
  state = {
    WhiteListData: [],
    ruleType: "",
    ruleAccount: "",
    timestamp: "",
    sequenceNum: "",
    whiteListCount: "",
    countryCpcIso: "",
    whiteListFlag: false,
    submitStatusWhitelistFraudRuleC2C: null,
    submitMsgWhitelistFraudRuleC2C: "",
  };

  onChangeControlWhitelistFraudRuleC2C = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeControlWhitelistFraudRuleC2CCharNum = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onLoadingWhitelistFraudRuleC2C = () => {
    const timeStampWhitelistC2C = this.props.location.state.timeStamp;
    const seqNumWhitelistC2C = this.props.location.state.seqNum;
    if (this.props.location.state.whiteListIndicator === "Y" || this.props.location.state.whiteListIndicator === "N") {
      axios
        .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/whitelist/${timeStampWhitelistC2C}/${seqNumWhitelistC2C}`,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
          },
        }
        )
        .then((response) => {

          this.setState({
            WhiteListData: response.data.records,
          });
          if(response.data.flag===0){
            this.setState({
              submitStatusWhitelistFraudRuleC2C: "Error",
              submitMsgWhitelistFraudRuleC2C: response.data.message,
            });
          }
        });
    }
  }
  componentDidMount() {
    this.onLoadingWhitelistFraudRuleC2C();
      this.setState({
        accessGrantedWriteFPF:this.props.location.records.accessGrantedWriteFPF
      });
  }

  onHandleBackWhitelistFraudRuleC2C = () => {
    const countrycpcIso = this.state.countryCpcIso;
    this.props.history.push({
      pathname: "/c2c-fraud-prevention-filter",
      state: countrycpcIso,
      records:this.props.location.records
    });
  };

  onValidateWhitelistFraudRuleC2C = () => {
    if(this.state.ruleType === "") {
      this.setState({
        submitStatusWhitelistFraudRuleC2C: "Error",
        submitMsgWhitelistFraudRuleC2C: "Please Select The Value For Rule Type",
      });
      return false;
    }
    if(this.state.ruleAccount === "") {
      this.setState({
        submitStatusWhitelistFraudRuleC2C: "Error",
        submitMsgWhitelistFraudRuleC2C: "Please Enter The Value For Rule Account",
      });
      return false;
    }
    if(this.state.ruleType === "A" && this.state.ruleAccount.length !== 9) {
      this.setState({
        submitStatusWhitelistFraudRuleC2C: "Error",
        submitMsgWhitelistFraudRuleC2C: "Agent Account Should Be Of 9 Alpha-Numeric Character",
      });
      return false;
    }
    return true;
  };

  onHandleAddWhitelistFraudRuleC2C = () => {
    if (!this.onValidateWhitelistFraudRuleC2C()) {
      return;
    }
    const WhiteListData = this.state.WhiteListData;

    let submittedObjWhitelistC2C = {};
    submittedObjWhitelistC2C.timestamp = this.props.location.state.timeStamp;
    submittedObjWhitelistC2C.sequenceNum = this.props.location.state.seqNum;
    submittedObjWhitelistC2C.ruleType = this.state.ruleType;
    submittedObjWhitelistC2C.ruleAccount = this.state.ruleAccount;
    submittedObjWhitelistC2C.whiteListCount = WhiteListData.length;

    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/whitelist`,
        submittedObjWhitelistC2C,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          },
        }
      )
      .then((response) => {

        if (response.status === 200 && response.data.responseCode === "200") {
          this.setState({
            submitStatusWhitelistFraudRuleC2C: "OK",
            submitMsgWhitelistFraudRuleC2C: response.data.responseString,
            WhiteListData: [submittedObjWhitelistC2C, ...this.state.WhiteListData],
          });
          this.onLoadingWhitelistFraudRuleC2C();
        }else {
          this.setState({
            submitStatusWhitelistFraudRuleC2C: "Error",
            submitMsgWhitelistFraudRuleC2C: response.data.responseString,
          });
        }
        this.onHandleClearWhitelistFraudRuleC2C();
      });
  };


  onHandleClearWhitelistFraudRuleC2C = () => {
    this.setState({
      ruleType: "",
      ruleAccount: "",
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusWhitelistFraudRuleC2C: null,
        }),
      5000
    );
  };

  onHandleDeleteWhitelistFraudRuleC2C = (e, data) => {
    let submittedObjWhitelistC2C = {};

    const timeStampWhitelistC2C = this.props.location.state.timeStamp;
    const seqNumWhitelistC2C = this.props.location.state.seqNum;

    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fraudpreventionfilter/deletewhitelist/${data.ruleType}/${timeStampWhitelistC2C}/${seqNumWhitelistC2C}`,
        {
          headers: {
            ...getHeadersShared2().headers,
            ruleAccount: data.ruleAccount,
            Authorization:this.props.location.records.token,
            "userId":this.props.location.records.operatorIdFPF
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.WhiteListData.filter((row) => {
            return row.ruleAccount !== submittedObjWhitelistC2C.ruleAccount;
          });

          this.setState({
            submitStatusWhitelistFraudRuleC2C: "OK",
            submitMsgWhitelistFraudRuleC2C: response.data.responseString,
            WhiteListData: newRows,
          });
          this.onLoadingWhitelistFraudRuleC2C();
        } else {
          this.setState({
            submitStatusWhitelistFraudRuleC2C: "Error",
            submitMsgWhitelistFraudRuleC2C: response.data.responseString,
          });
        }
        this.onHandleClearWhitelistFraudRuleC2C();
      });
  };
  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="FRAUD PREVENTION FILTER MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                WHITELIST FRAUD RULE DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  WHITELIST FRAUD RULE
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <label htmlFor="ruleType" className="font-weight-bold">Rule Type</label>
                    <div classNme="form-group row">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="ruleType"
                          value="A"
                          data-test="text-ruleType-A"
                          checked={this.state.ruleType === "A"}
                          onChange={(e) => {
                            this.onChangeControlWhitelistFraudRuleC2C(
                              e
                            );
                          }}
                        />
                        <label className="form-check-label" htmlFor="Block">
                          Agent
                            </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="ruleType"
                          value="N"
                          data-test="text-ruleType-N"
                          checked={this.state.ruleType === "N"}
                          onChange={(e) => {
                            this.onChangeControlWhitelistFraudRuleC2C(
                              e
                            );
                          }}
                        />
                        <label className="form-check-label" htmlFor="N">
                          Network
                            </label>

                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="ruleAccount" className="font-weight-bold">Rule Account:</label>
                        <input
                          type="text"
                          maxlength ="16"
                          value={this.state.ruleAccount}
                          className="form-control form-control-sm"
                          name="ruleAccount"
                          onChange={(e) => {
                            this.onChangeControlWhitelistFraudRuleC2CCharNum(e);
                          }}
                          data-test="text-ruleAccount"

                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackWhitelistFraudRuleC2C}
                          data-test="button-back"
                        >
                          FRAUD FILTER
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddWhitelistFraudRuleC2C}
                          disabled={!this.state.accessGrantedWriteFPF}
                          data-test="button-add"
                        >
                          ADD
                          </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          data-test="button-clear"
                          onClick={this.onHandleClearWhitelistFraudRuleC2C}
                          disabled={!this.state.accessGrantedWriteFPF}
                        >
                          CLEAR
                          </button>
                      </div>
                      <div className="col-lg-3">
                      
                      </div>
                    </div>
                    <div className="row mar-top10">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                        {this.state.submitStatusWhitelistFraudRuleC2C ===
                          "OK" && (
                            <div className="alert alert-success alert-padding notification text-center">
                              {this.state.submitMsgWhitelistFraudRuleC2C}
                            </div>
                          )}
                        {this.state.submitStatusWhitelistFraudRuleC2C ===
                          "Error" && (
                            <div className="alert alert-danger alert-padding notification text-center">
                              {this.state.submitMsgWhitelistFraudRuleC2C}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-4"></div>
                      </div>
                  </form>
                </div>
              </div>

              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  WHITELIST FRAUD RULE DATA
                </div>
                <table className="table table-bordered margin-0"  >
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">TYPE</th>
                      <th scope="col">ACCOUNT</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.WhiteListData.map(
                        (data, i) => (
                          <tr key={i} data-test="tr">
                            <td>{data.ruleType}</td>
                            <td>{data.ruleAccount}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={(e) => this.onHandleDeleteWhitelistFraudRuleC2C(e, data)}
                                data-test="button-delete"
                              >
                                DELETE
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhitelistFraudRuleC2C;
