import { 
    DFDT_SEARCH_STARTED,
    DFDT_SEARCH_SUCCESS,
    DFDT_SEARCH_ERROR } from '../actions/dfdt-search/type';

const defaultState = {
    dataInfo: {},
    isLoading: false,
    isError: false,
    error: ''
} 

const DFDTSearchReducer = (state = JSON.parse(JSON.stringify(defaultState)), action = {}) => {

    switch(action.type) {
        case DFDT_SEARCH_STARTED:            
            return {
                ...state,
                isLoading: true
            }
        case DFDT_SEARCH_ERROR:
            return {
                ...state,
                isError: true,
                error: action.payload
            } 
        case DFDT_SEARCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dataInfo: action.payload
            } 
        default:
            return state;
    }
}

export default DFDTSearchReducer;