import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactQMGD } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit as AiTwotoneEditQMGD } from "react-icons/ai";
import { IconContext as IconContextQMGD } from "react-icons";
import { getHeadersOrder } from "../../utility/utility";

class QueueMaintenanceGroupDetails extends Component {
  state = {
    groupId: "",
    groupDesc: "",
    queAgeInd: "N",
    showQueueMantenanceGroupDetailsConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {
                if(!this.props.location.state.accessGrantedWriteTMD3) { 
                this.setState({
                  submitStatusQueueMantenanceGroupDetails: 'Error',
                  submitMsgQueueMantenanceGroupDetails: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                });
              }else{
                this.handleEditQueueMantenanceGroupDetails(params)
              }  
            }}
            >
              <IconContextQMGD.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditQMGD />
              </IconContextQMGD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "100px",
      },
      { field: "groupId", headerName: "Group Id", width: "200px" },
      { field: "groupDesc", headerName: "Group Description ",width: "700px" },
      { field: "queAgeInd", headerName: "Queage Ind", width: "200px" },
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValNetwork: "25",
    editable: true,
    defaultColumnDef: { resizable: true, sortable: true, filter: true, floatingFilter: true },
    submitStatusQueueMantenanceGroupDetails: null,
    submitMsgQueueMantenanceGroupDetails: "",
    paginationOptionQueueMantenanceGroupDetails: [25, 50, 100, 500],
    overlayLoadingTemplateQMGD:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateQMGD: '<span style="">No Records Found</span>',
  };

  onChangeControlQueueMantenanceGroupDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onGridReadyQueueMantenanceGroupDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/group`,{
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        this.setState({
          rows: response.data.records,
        });
      });
  };

  handleEditQueueMantenanceGroupDetails = (params) => {
    let data = params.node.data;
    this.setState({
      groupId: data.groupId,
      groupDesc: data.groupDesc,
      queAgeInd: data.queAgeInd,
      enableBtn: true,
      editable: false
    });
  };

  onPageSizeChangedQueueMantenanceGroupDetails = (e) => {
    this.setState({ paginationValQueue: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackQueueMantenanceGroupDetails = () => {
    this.props.history.push({pathname:"/queue-maintenance",state:this.props.location.state});
  };

  onValidateQueueMantenanceGroupDetails = () => {
    if (this.state.groupId === "") {
      this.setState({
        submitStatusQueueMantenanceGroupDetails: "Error",
        submitMsgQueueMantenanceGroupDetails: "Please Enter The Value For Group Id ",
      });
      return false;
    }
    if (this.state.groupDesc === "") {
      this.setState({
        submitStatusQueueMantenanceGroupDetails: "Error",
        submitMsgQueueMantenanceGroupDetails: "Please Enter The Value For Group Description ",
      });
      return false;
    }
    return true;
  };

  onHandleAddQueueMantenanceGroupDetails = () => {
    if (!this.onValidateQueueMantenanceGroupDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.groupId = this.state.groupId;
    submittedObj.groupDesc = this.state.groupDesc;
    submittedObj.queAgeInd = this.state.queAgeInd;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/group`, submittedObj, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200  && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusQueueMantenanceGroupDetails: "OK",
            submitMsgQueueMantenanceGroupDetails: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusQueueMantenanceGroupDetails: "Error",
            submitMsgQueueMantenanceGroupDetails: response.data.responseString,
          });
        }
        this.onHandleClearQueueMantenanceGroupDetails();
      });
  };

  onHandleUpdateQueueMantenanceGroupDetails = () => {
    if (!this.onValidateQueueMantenanceGroupDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.groupId = this.state.groupId;
    submittedObj.groupDesc = this.state.groupDesc;
    submittedObj.queAgeInd = this.state.queAgeInd;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/group`, submittedObj, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.groupId === submittedObj.groupId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusQueueMantenanceGroupDetails: "OK",
            submitMsgQueueMantenanceGroupDetails: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusQueueMantenanceGroupDetails: "Error",
            submitMsgQueueMantenanceGroupDetails: response.data.responseString,
          });
        }
        this.onHandleClearQueueMantenanceGroupDetails();
      });
  };

  onHandleClearQueueMantenanceGroupDetails = () => {
    this.setState({
      groupId: "",
      groupDesc: "",
      queAgeInd: "N",
      enableBtn: false,
      editable: true
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusQueueMantenanceGroupDetails: null,
        }),
      5000
    );
  };

  getQueueMantenanceGroupDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showQueueMantenanceGroupDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Group Id {this.state.groupId} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showQueueMantenanceGroupDetailsConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteQueueMantenanceGroupDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteQueueMantenanceGroupDetails = () => {
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/tmonfilemaintenance/deletegroup/${this.state.groupId}`, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.props.location.state.token,
          "userId":this.props.location.state.operatorIdTMD
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let newRows = this.state.rows.filter((row) => {
            return row.groupId !== this.state.groupId;
          });

          this.setState({
            submitStatusQueueMantenanceGroupDetails: "OK",
            submitMsgQueueMantenanceGroupDetails: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusQueueMantenanceGroupDetails: "Error",
            submitMsgQueueMantenanceGroupDetails: response.data.responseString,
          });
        }
        this.onHandleClearQueueMantenanceGroupDetails();
      });
    this.setState({ showQueueMantenanceGroupDetailsConfirm: false });
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="	WU TMON FILE MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading ">
                GROUP DESCRIPTION DETAILS
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  GROUP DETAILS FORM
                </div>
                <div className="card-body card-body-custom">
                  <div className="form-group row">
                    <div className="col-lg">
                      <label htmlFor="groupId" className="font-weight-bold">Group ID</label>
                      <input
                        type="text"
                        maxLength="4"
                        value={this.state.groupId}
                        className="form-control form-control-sm"
                        name="groupId"
                        onChange={(e) => {
                          this.onChangeControlQueueMantenanceGroupDetails(e);
                        }}
                        data-test="text-groupId"
                        placeholder="Group ID"
                        disabled={!this.state.editable}
                      />
                    </div>
                    <div className="col-lg">
                      <label htmlFor="groupDesc" className="font-weight-bold">Group Desc</label>
                      <input
                        type="text"
                        maxLength="40"
                        value={this.state.groupDesc}
                        className="form-control form-control-sm"
                        name="groupDesc"
                        onChange={(e) => {
                          this.onChangeControlQueueMantenanceGroupDetails(e);
                        }}
                        data-test="text-groupDesc"
                        placeholder="Group Desc"
                      />
                    </div>
                    <div className="col-lg">
                      <label className="font-weight-bold">Queue Age Ind</label>
                      <div className="row">
                        <div className="mar-right15" />
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="queAgeInd"
                            value="Y"
                            data-test="text-queAgeInd-Yes"
                            checked={this.state.queAgeInd === "Y"}
                            onChange={(e) => {
                              this.onChangeControlQueueMantenanceGroupDetails(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="Y">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="queAgeInd"
                            value="N"
                            data-test="text-queAgeInd"
                            checked={this.state.queAgeInd === "N"}
                            onChange={(e) => {
                              this.onChangeControlQueueMantenanceGroupDetails(
                                e
                              );
                            }}
                          />
                          <label className="form-check-label" htmlFor="N">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 text-center mar-bottom5">
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleBackQueueMantenanceGroupDetails}
                        data-test="button-back"
                      >
                        QUEUE MAINT
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={this.onHandleAddQueueMantenanceGroupDetails}
                        data-test="button-add"
                        disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                      >
                        ADD
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={
                          this.onHandleUpdateQueueMantenanceGroupDetails
                        }
                        data-test="button-update"
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning mar-right10"
                        onClick={
                          this.onHandleClearQueueMantenanceGroupDetails
                        }
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                      >
                        CLEAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          this.setState({
                            showQueueMantenanceGroupDetailsConfirm: true,
                          });
                        }}
                        data-test="button-delete"
                        disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteTMD3}
                      >
                        DELETE
                      </button>
                      <div className="row mar-top10">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                          {this.state.submitStatusQueueMantenanceGroupDetails ===
                            "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgQueueMantenanceGroupDetails}
                            </div>
                          )}
                          {this.state.submitStatusQueueMantenanceGroupDetails ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgQueueMantenanceGroupDetails}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </div>
              </div>
              {this.getQueueMantenanceGroupDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  GROUP DETAILS DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlQueueMantenanceGroupDetails(e);
                        this.onPageSizeChangedQueueMantenanceGroupDetails(e);
                      }}
                      value={this.state.paginationValQueue}
                      name="paginationDropdownQueue"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionQueueMantenanceGroupDetails.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactQMGD
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyQueueMantenanceGroupDetails}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateQMGD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateQMGD
                      }
                    ></AgGridReactQMGD>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QueueMaintenanceGroupDetails;
