import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactDmnld } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { AiTwotoneEdit } from "react-icons/ai";
import { IconContext } from "react-icons";

import { getHeadersProduct } from "../../utility/utility";

class DirectedMaintenanceNetworkLinkDetails extends Component {
  state = {
    networkId: "",
    wuAccount: "",
    vigoAccount: "",
    description: "",
    showDirectedMaintenanceNetworkLinkDetailsConfirm: false,
    columns: [
      {
        headerName: "ACTION",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() =>{ 
                if(!this.props.location.state.accessGrantedWriteDMD) { 
                this.setState({
                  submitStatusDirectedMaintenanceNetworkLinkDetails: 'Error',
                  submitMsgDirectedMaintenanceNetworkLinkDetails: "Update Not Allowed By This Operator",
                });
              }else{this.handleEditDirectedMaintenanceNetworkLinkDetails(params)}}
              }
            >
              <IconContext.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEdit />
              </IconContext.Provider>
            </div>
          );
        },
        sortable: false,filter: false,floatingFilter: false,width: "80px",},
      {field: "networkId",headerName: "NETWORK",width: "250px",editable: true,readonly: true},
      {field: "wuAccount",headerName: "WU ACCT",width: "250px",},
      {field: "vigoAccount",headerName: "VIGO ACCT",width: "250px",},
      {field: "description",headerName: "DESCRIPTION",width: "400px",},
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValQueue: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusDirectedMaintenanceNetworkLinkDetails: null,
    submitMsgDirectedMaintenanceNetworkLinkDetails: "",
    paginationOptionDirectedMaintenanceNetworkLinkDetails: [25, 50, 100, 500],
    overlayLoadingTemplateDMNLD:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateDMNLD: '<span style="">No Records Found</span>',
  };

  onChangeControlDirectedMaintenanceNetworkLinkDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeControlDirectedMaintenanceNetworkLinkDetailsCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onGridReadyDirectedMaintenanceNetworkLinkDetails = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/directedmanagement/networklinkdetails`,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.token,
          },
        }
      )
      .then((response) => {
        let recNetworkLink = [];
        if (response.data.records !== null) {
          recNetworkLink = response.data.records;
        } else {
          this.setState({
            overlayNoRowsTemplateDMNLD: `<span style="">${response.data.message}</span>`,
          });
        }
        this.setState({
          rows: recNetworkLink,
        });
      });
  };
  handleEditDirectedMaintenanceNetworkLinkDetails = (
    directedManagementNetworkLinkparams
  ) => {
    let directedManagementNetworkLinkEditData =
      directedManagementNetworkLinkparams.node.data;
    this.setState({
      networkId: directedManagementNetworkLinkEditData.networkId,
      wuAccount: directedManagementNetworkLinkEditData.wuAccount,
      vigoAccount: directedManagementNetworkLinkEditData.vigoAccount,
      description: directedManagementNetworkLinkEditData.description,
      enableBtn: true,
    });
  };

  onPageSizeChangedDirectedMaintenanceNetworkLinkDetails = (e) => {
    this.setState({ paginationValQueue: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackDirectedMaintenanceNetworkLinkDetails = () => {
    this.props.history.push("/directed-maintenance-details");
  };

  onValidateDirectedMaintenanceNetworkLinkDetails = () => {
    if (this.state.networkId === "") {
      this.setState({
        submitStatusDirectedMaintenanceNetworkLinkDetails: "Error",
        submitMsgDirectedMaintenanceNetworkLinkDetails:
          "Please Enter The Value For Network Id",
      });
      return false;
    }
    if (this.state.wuAccount !== "" && this.state.vigoAccount === "") {
      this.setState({
        submitStatusDirectedMaintenanceNetworkLinkDetails: "Error",
        submitMsgDirectedMaintenanceNetworkLinkDetails:
          "Please Enter The Value For VIGO Account ",
      });
      return false;
    }
    if (this.state.vigoAccount !== "" && this.state.wuAccount === "") {
      this.setState({
        submitStatusDirectedMaintenanceNetworkLinkDetails: "Error",
        submitMsgDirectedMaintenanceNetworkLinkDetails:
          "Please Enter The Value For WU Account ",
      });
      return false;
    }

    return true;
  };

  onHandleAddDirectedMaintenanceNetworkLinkDetails = () => {
    if (!this.onValidateDirectedMaintenanceNetworkLinkDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.networkId = this.state.networkId;
    submittedObj.wuAccount = this.state.wuAccount;
    submittedObj.vigoAccount = this.state.vigoAccount;
    submittedObj.description = this.state.description;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/directedmanagement/networklinkdetails`,
        submittedObj,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.token,
            "userId": this.props.location.state.operatorIdDMD,
          },
        }
      )
      .then((response) => {
        //console.log(response.data.responseCode);
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusDirectedMaintenanceNetworkLinkDetails: "OK",
            submitMsgDirectedMaintenanceNetworkLinkDetails:
              response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        }else {
          this.setState({
            submitStatusDirectedMaintenanceNetworkLinkDetails: "Error",
            submitMsgDirectedMaintenanceNetworkLinkDetails:
              response.data.responseString,
          });
        }
        this.onHandleClearDirectedMaintenanceNetworkLinkDetails();
      });
  };

  onHandleUpdateDirectedMaintenanceNetworkLinkDetails = () => {
    if (!this.onValidateDirectedMaintenanceNetworkLinkDetails()) {
      return;
    }
    let submittedObj = {};
    submittedObj.networkId = this.state.networkId;
    submittedObj.wuAccount = this.state.wuAccount;
    submittedObj.vigoAccount = this.state.vigoAccount;
    submittedObj.description = this.state.description;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/directedmanagement/networklinkdetails`,
        submittedObj,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.props.location.state.token,
            "userId": this.props.location.state.operatorIdDMD,
          },
        }
      )
      .then((response) => {
        //console.log(response.data.responseCode);
        if (response.status === 200 && response.data.responseCode === "200") {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.networkId === submittedObj.networkId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusDirectedMaintenanceNetworkLinkDetails: "OK",
            submitMsgDirectedMaintenanceNetworkLinkDetails:
              response.data.responseString,
            rows: updatedRows,
          });
        }else {
          this.setState({
            submitStatusDirectedMaintenanceNetworkLinkDetails: "Error",
            submitMsgDirectedMaintenanceNetworkLinkDetails:
              response.data.responseString,
          });
        }
        this.onHandleClearDirectedMaintenanceNetworkLinkDetails();
      });
  };

  onHandleClearDirectedMaintenanceNetworkLinkDetails = () => {
    this.setState({
      networkId: "",
      wuAccount: "",
      vigoAccount: "",
      description: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusDirectedMaintenanceNetworkLinkDetails: null,
        }),
      5000
    );
  };

  getDirectedMaintenanceNetworkLinkDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showDirectedMaintenanceNetworkLinkDetailsConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
              Are You Sure, You Want To Delete Network Id {this.state.networkId} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showDirectedMaintenanceNetworkLinkDetailsConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={
                  this.onHandleDeleteDirectedMaintenanceNetworkLinkDetails
                }
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteDirectedMaintenanceNetworkLinkDetails = () => {
    let submittedObj = {};
    submittedObj.networkId = this.state.networkId;
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/directedmanagement/networklinkdetails`,
        {
          headers: {
            ...getHeadersProduct().headers,
            networkId: submittedObj.networkId,
            Authorization: this.props.location.state.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.responseCode === "200") {
          let newRows = this.state.rows.filter((row) => {
            return row.networkId !== submittedObj.networkId;
          });

          this.setState({
            submitStatusDirectedMaintenanceNetworkLinkDetails: "OK",
            submitMsgDirectedMaintenanceNetworkLinkDetails:
              response.data.responseString,
            rows: newRows,
          });
          this.onHandleClearDirectedMaintenanceNetworkLinkDetails();
        } else {
          this.setState({
            submitStatusDirectedMaintenanceNetworkLinkDetails: "Error",
            submitMsgDirectedMaintenanceNetworkLinkDetails:
              response.data.responseString,
          });
          this.onHandleClearDirectedMaintenanceNetworkLinkDetails();
        }
      });
    this.setState({ showDirectedMaintenanceNetworkLinkDetailsConfirm: false });
  };
  render() {
    return (
      <>
        <Header history={this.props.history} heading="DIRECTED MANAGEMENT" />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">NETWORK LINK DETAILS</h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  NETWORK LINK FORM
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="networkId" className="font-weight-bold">Network Id</label>
                        <input
                          type="text"
                          maxLength="5"
                          size="6"
                          value={this.state.networkId}
                          className="form-control form-control-sm"
                          name="networkId"
                          onChange={(e) => {
                            this.onChangeControlDirectedMaintenanceNetworkLinkDetailsCharNumber(
                              e
                            );
                          }}
                          data-test="text-networkId"
                          placeholder="Network Id"
                          disabled={this.state.enableBtn}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="wuAccount" className="font-weight-bold">WU Account</label>
                        <input
                          type="text"
                          maxLength="9"
                          size="11"
                          value={this.state.wuAccount}
                          className="form-control form-control-sm"
                          name="wuAccount"
                          onChange={(e) => {
                            this.onChangeControlDirectedMaintenanceNetworkLinkDetailsCharNumber(
                              e
                            );
                          }}
                          data-test="text-wuAccount"
                          placeholder="WU Account"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="vigoAccount" className="font-weight-bold">VIGO Account</label>
                        <input
                          type="text"
                          maxLength="9"
                          size="11"
                          value={this.state.vigoAccount}
                          className="form-control form-control-sm"
                          name="vigoAccount"
                          onChange={(e) => {
                            this.onChangeControlDirectedMaintenanceNetworkLinkDetailsCharNumber(
                              e
                            );
                          }}
                          data-test="text-vigoAccount"
                          placeholder="VIGO Account"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="description" className="font-weight-bold">Description</label>
                        <input
                          type="text"
                          maxLength="40"
                          size="50"
                          value={this.state.description}
                          className="form-control form-control-sm"
                          name="description"
                          onChange={(e) => {
                            this.onChangeControlDirectedMaintenanceNetworkLinkDetails(
                              e
                            );
                          }}
                          data-test="text-description"
                          placeholder="Description"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={
                            this
                              .onHandleBackDirectedMaintenanceNetworkLinkDetails
                          }
                          data-test="button-back"
                        >
                          DIRECTED MAINT
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddDirectedMaintenanceNetworkLinkDetails}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.props.location.state.accessGrantedWriteDMD}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateDirectedMaintenanceNetworkLinkDetails}
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteDMD}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearDirectedMaintenanceNetworkLinkDetails}
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteDMD}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showDirectedMaintenanceNetworkLinkDetailsConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn || !this.props.location.state.accessGrantedWriteDMD}
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                            {this.state
                            .submitStatusDirectedMaintenanceNetworkLinkDetails ===
                              "OK" && (
                              <div className="alert alert-success alert-padding notification">
                                {
                                  this.state
                                    .submitMsgDirectedMaintenanceNetworkLinkDetails
                                }
                              </div>
                            )}
                            {this.state
                              .submitStatusDirectedMaintenanceNetworkLinkDetails ===
                              "Error" && (
                              <div className="alert alert-danger alert-padding notification">
                                {
                                  this.state
                                    .submitMsgDirectedMaintenanceNetworkLinkDetails
                                }
                              </div>
                            )}
                          </div>
                          <div className="col-lg-2"></div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getDirectedMaintenanceNetworkLinkDetailsModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  NETWORK LINK DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlDirectedMaintenanceNetworkLinkDetails(
                          e
                        );
                        this.onPageSizeChangedDirectedMaintenanceNetworkLinkDetails(
                          e
                        );
                      }}
                      value={this.state.paginationValQueue}
                      name="paginationDropdownQueue"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionDirectedMaintenanceNetworkLinkDetails.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactDmnld
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={
                        this.onGridReadyDirectedMaintenanceNetworkLinkDetails
                      }
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateDMNLD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateDMNLD
                      }

                    ></AgGridReactDmnld>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DirectedMaintenanceNetworkLinkDetails;
