import {
  DFDT_SEARCH_STARTED,
  DFDT_SEARCH_SUCCESS,
  DFDT_SEARCH_ERROR,
} from "./type";

import axios from "axios";
import { getHeadersOrder } from "../../components/utility/utility";

export const doSearchDfdt = (submittedData, jwtToken) => {
  return function doSearchDfdtAction(dispatch) {
    dispatch(doDfdtSearchStarted());
    return axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/df`, submittedData, {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: jwtToken
        },
      })
      .then((response) => {
        dispatch(doDfdtSearchSuccess(response));
      })
      .catch(error=>{
        dispatch(doDfdtSearchError(error));
    })
  };
};

export const doDfdtSearchStarted = () => {
  return { type: DFDT_SEARCH_STARTED };
};

export const doDfdtSearchSuccess = (dataInfo) => {
  return { type: DFDT_SEARCH_SUCCESS, payload: dataInfo };
};

export const doDfdtSearchError = (error) => {
  return { type: DFDT_SEARCH_ERROR, payload: error };
};
