import React, { Component } from "react";
import Header from "../../header/header";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { getHeadersProduct } from "../../utility/utility";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

class QuickCollectDatabaseMaintenance extends Component {
  state = {
    cityCode: "",
    clientName: "",
    accountId: "",
    deviceId: "",
    agent: "N",
    submitStatusQuickCollectDbMaintenance: null,
    submitMsgQuickCollectDbMaintenance: "",
    showQuickCollectDbMaintModal: false,
  };

  getQuickCollectClientInfoModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showQuickCollectDbMaintModal}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
                Adding Records Please Enter Fields
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() => {
                  this.setState({
                    showQuickCollectDbMaintModal: false,
                  });
                  if (
                    this.state.clientName === "" &&
                    this.state.cityCode === "" &&
                    this.state.deviceId !== ""
                  ) {
                    this.props.history.push({
                      pathname: "/quick-collect-device-information",
                      state: this.state,
                    });
                  } else {
                    this.props.history.push({
                      pathname: "/quick-collect-client-info",
                      state: this.state,
                    });
                  }
                }}
                data-test="button-yes"
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  componentDidMount(){
    const accessToken = this.props.authState.accessToken;
      const tokenQCDM = accessToken.value;
      try {
        const token = jwt_decode(accessToken.value);
        const accessRole = jwt_decode(accessToken.value).role;
        const id=jwt_decode(accessToken.value).sub;
        const operatorIdQCDM = id.replace(/\D/g,'');
        const accessGrantQCDM =
          accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCMANAGER) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_PRODUPDATE) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_PRODCLR) ;         
        const accessGrantWriteQCDM =accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCMANAGER) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_PRODUPDATE) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_PRODCLR);
        const adminQCDM = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCMANAGER);
        const adminQCCI = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCMANAGER) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK);
        const adminQCDI=  accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCMANAGER)||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_PRODUPDATE) ||
          accessRole.includes(process.env.REACT_APP_DB_VIEW_PRODCLR);
        this.setState({
          accessToken: token,
          tokenQCDM:tokenQCDM,
          operatorIdQCDM:operatorIdQCDM,
          role: accessRole,
          accessGrantQCDM: accessGrantQCDM,
          accessGrantWriteQCDM: accessGrantWriteQCDM,
          adminQCDM:adminQCDM,
          adminQCDI:adminQCDI,
          adminQCCI:adminQCCI,
        }, () => {
          if(!this.state.accessGrantQCDM) { 
            this.setState({
              submitStatusQuickCollectDbMaintenance: 'Error',
              submitMsgQuickCollectDbMaintenance: "Unauthorized! Access Is Denied.",
            });
          }
        });
        // valid token format
      } catch (error) {
        // invalid token format
      }
  }
  onChangeControlQuickCollectDbMaintenance = (e) => {
    let val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val,
    });
  };
  onChangeControlQuickCollectDbMaintenanceClientName = (e) => {
    let val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val.replace(/[^&a-zA-Z0-9\s]/g, ""),
    });
  };
  onValidateQuickCollectDbMaintenance = () => {
    if (
      this.state.cityCode === "" &&
      this.state.clientName === "" &&
      this.state.deviceId === ""
    ) {
      this.setState({
        submitStatusQuickCollectDbMaintenance: "Error",
        submitMsgQuickCollectDbMaintenance:
          "Please Enter Value In One Of The Fields",
      });
      return false;
    }

    return true;
  };
  onValidateQuickCollectAgtIrp = () => {
    if (this.state.accountId === "") {
      this.setState({
        submitStatusQuickCollectDbMaintenance: "Error",
        submitMsgQuickCollectDbMaintenance: "Please Enter Value For Account Id",
      });
      return false;
    }
    return true;
  };
  onHandleAgtIrp = () => {
    if (!this.onValidateQuickCollectAgtIrp()) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/agentirp/${this.state.accountId}`,
        {
          headers: {
            ...getHeadersProduct().headers,
            Authorization: this.state.tokenQCDM,
            userId: this.state.operatorIdQCDM,
          },
        }
      )
      .then((response) => {
        if (response.data.clientId === null) {
          this.setState({
            submitStatusQuickCollectDbMaintenance: "Error",
            submitMsgQuickCollectDbMaintenance: "Account Id Is Invalid ",
          });
        } else {
          this.props.history.push({
            pathname: "/quick-collect-agt-irp",
            state: { data: this.state, accountId: this.state.accountId },
          });
        }
      });
  };

  handleSearchQCDM = () => {
    if (!this.onValidateQuickCollectDbMaintenance()) {
      return;
    }
    if (
      this.state.cityCode === "" &&
      this.state.clientName === "" &&
      this.state.deviceId !== ""
    ) {
      this.state.deviceId = this.state.deviceId.trim();
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/qqcdeviceinfo/${this.state.deviceId}`,
          {
            headers: {
              ...getHeadersProduct().headers,
              agent: this.state.agent === "" ? "N" : this.state.agent,
              Authorization: this.state.tokenQCDM,
              userId: this.state.operatorIdQCDM,
            },
          }
        )
        .then((response) => {
          if (
            response.data.records !== null &&
            response.data.records.length > 0
          ) {
            this.props.history.push({
              pathname: "/quick-collect-device-information",
              state: { deviceId: this.state.deviceId, agent: this.state.agent, data:this.state },
            });
          } else {
            this.setState({
              showQuickCollectDbMaintModal: true,
            });
          }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_PRODUCT_SERVICE_URL}/v1/pro/dbview/qqcupdate/qqcclientinfo`,
          {
            headers: {
              ...getHeadersProduct().headers,
              codeCityState: this.state.cityCode,
              clientName: this.state.clientName,
              Authorization: this.state.tokenQCDM,
              userId: this.state.operatorIdQCDM,
            },
          }
        )
        .then((response) => {
          if (response.data.records.length === 0 ){
            this.setState({
              submitStatusQuickCollectDbMaintenance: "Error",
              submitMsgQuickCollectDbMaintenance: "Client Record Not Found",
            });
          }
          else if (response.data.records.length > 0 &&
           ( response.data.records[0].clientName === null ||
            response.data.records[0].clientName === "")
          ) {
            this.setState({
              showQuickCollectDbMaintModal: true,
            });
          }
          else {
            this.props.history.push({
              pathname: "/quick-collect-client-info",
              state: this.state,
            });
          }
        });
    }
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid row">
          <div className="col-lg-3"></div>
          <div className="card-body col-lg-6">
            <h4 className="card-title main-heading ">
              Quick Collect Database Maintenance
            </h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                QUICK COLLECT SEARCH
              </div>
              <div className="card-body card-body-custom">
                <div className="form-group row">
                <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    {this.state.submitStatusQuickCollectDbMaintenance ===
                      "OK" && (
                      <div className="alert alert-success alert-padding notification text-center">
                        {this.state.submitMsgQuickCollectDbMaintenance}
                      </div>
                    )}
                    {this.state.submitStatusQuickCollectDbMaintenance ===
                      "Error" && (
                      <div className="alert alert-danger alert-padding notification text-center">
                        {this.state.submitMsgQuickCollectDbMaintenance}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="cityCode" className="font-weight-bold">Code City / State</label>
                    <input
                      type="text"
                      maxLength="24"
                      value={this.state.cityCode}
                      className="form-control form-control-sm"
                      name="cityCode"
                      onChange={(e) => {
                        this.onChangeControlQuickCollectDbMaintenance(e);
                      }}
                      data-test="text-cityCode"
                      placeholder="City Code/State"
                    />
                  </div>

                  <div className="col-lg">
                    <label htmlFor="clientName" className="font-weight-bold">Client Name</label>
                    <input
                      type="text"
                      value={this.state.clientName}
                      maxLength="28"
                      className="form-control form-control-sm"
                      name="clientName"
                      onChange={(e) => {
                        this.onChangeControlQuickCollectDbMaintenanceClientName(e);
                      }}
                      data-test="text-clientName"
                      placeholder="Client Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="accountId" className="font-weight-bold">Account Id</label>
                    <input
                      type="text"
                      value={this.state.accountId}
                      maxLength="9"
                      className="form-control form-control-sm"
                      name="accountId"
                      onChange={(e) => {
                        this.onChangeControlQuickCollectDbMaintenance(e);
                      }}
                      data-test="text-accountId"
                      placeholder="Account ID"
                    />
                  </div>

                  <div className="col-lg">
                    <label htmlFor="deviceId" className="font-weight-bold">Device Id</label>
                    <input
                      type="text"
                      value={this.state.deviceId}
                      maxLength="12"
                      className="form-control form-control-sm"
                      name="deviceId"
                      onChange={(e) => {
                        this.onChangeControlQuickCollectDbMaintenance(e);
                      }}
                      data-test="text-deviceId"
                      placeholder="Device ID"
                    />
                  </div>

                  <div className="col-lg">
                    <label htmlFor="agent" className="font-weight-bold">Agent</label>
                    <select
                      className="form-control  form-control-sm "
                      name="agent"
                      data-test="select-agent"
                      onChange={(e) => {
                        this.onChangeControlQuickCollectDbMaintenance(e);
                      }}
                      value={this.state.agent}
                    >
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </select>
                  </div>
                </div>
                <p className="text-center">
                <span className="font-weight-bold"> Please enter Code City / State (or) Client Name (or) Device
                  Id.</span>
                 
                </p>
                <div className="form-group row">
                  <div className="col-lg text-center mar-bottom5 mar-top20">
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-search"
                      onClick={this.handleSearchQCDM}
                      disabled={!this.state.accessGrantQCDM}
                    >
                      SEARCH
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-agt-irp"
                      onClick={() => {
                        this.onHandleAgtIrp();
                      }}
                      disabled={!this.state.accessGrantQCDM}
                    >
                      AGT IRP
                    </button>
                  </div>
                </div>
                {this.getQuickCollectClientInfoModal()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withOktaAuth(QuickCollectDatabaseMaintenance);
