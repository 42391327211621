import React from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersOrder } from "../../utility/utility";

class MoneyTransferUniversalAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noDataUniAddr: null,
      isLoading:true,
      mtuaMsg: ''
    };
  }

  componentDidMount = () => {
    this.setState({
      mtcn: this.props.history.location.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
    let submittedObj = {};
    submittedObj.mtcn = this.props.history.location.mtcn;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/universaladdr`,
        submittedObj,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.history.location.jwtToken
          },
        }
      )
      .then((response) => {
        
        if (response.data.flag === 1) {
          if(response.data.sender !== null) {
            this.setState({
              senderName: response.data.sender.name,
              senderPhone: response.data.sender.phone,
              senderAddress: response.data.sender.address,
              senderCity: response.data.sender.city,
              senderState: response.data.sender.state,
              senderZip: response.data.sender.zip,
              senderCountry: response.data.sender.country,
            });
          }
          if(response.data.receiver !== null) {
            this.setState({
              receiverName: response.data.receiver.name,
              receiverPhone: response.data.receiver.phone,
              receiverAddress: response.data.receiver.address,
              receiverCity: response.data.receiver.city,
              receiverState: response.data.receiver.state,
              receiverZip: response.data.receiver.zip,
              receiverCountry: response.data.receiver.country,
              receiverBank: response.data.receiver.bankName,
              receiverRouting: response.data.receiver.bankRoute,
              receiverAccount: response.data.receiver.bankAcct,
              receiverRecKey: response.data.receiver.clientKey,
              receiverCpf: response.data.receiver.cpf,
            });
          }
          if(response.data.billing !== null) {
            this.setState({
              billingPhone: response.data.billing.phone,
              billingAddress: response.data.billing.address,
              billingCity: response.data.billing.city,
              billingState: response.data.billing.state,
              billingZip: response.data.billing.zip,
              billingCountry: response.data.billing.country,
            });
          }
          this.setState({ noDataUniAddr: false,  isLoading: false  });
          
        } else {
          this.setState({ noDataUniAddr: true, isLoading: false, mtuaMsg:response.data.message  });
        }
      });
  };

  onHandleBackUniAdrMTCNDetails = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackUniAdrMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <h4 className="card-title main-heading ">
            Money Transfer Universal Address
          </h4>
          <div className="row mar-top20">
            <div className="col-lg-2" />
            <div className="col-lg-3">
              <label htmlFor="mtcn"><span className="font-weight-bold">MTCN : </span>{this.state.mtcn}</label>
            </div>
            <div className="col-lg-7" />
          </div>
          {
            this.state.isLoading ? 
            <div className="text-center">
              <div className="spinner-border text-warning " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            :            
            this.state.noDataUniAddr === false ? (
              <>
                <div className="row">
                  <div className="col-lg-2" />
                  <div className="col-lg-8">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        Sender
                      </div>
                      <div className="card-body card-body-custom">
                        <div className="row">
                          <div className="col-lg-1" />
                          <div className="col-lg-3">
                            <span className="font-weight-bold">Name :</span> {this.state.senderName}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Phone :</span> {this.state.senderPhone}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Address :</span> {this.state.senderAddress}
                          </div>
                          <div className="col-lg-2">
                          <span className="font-weight-bold">City :</span> {this.state.senderCity}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-1" />
                          <div className="col-lg-3">
                          <span className="font-weight-bold">State :</span> {this.state.senderState}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Zip :</span> {this.state.senderZip}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Country :</span> {this.state.senderCountry}
                          </div>
                          <div className="col-lg-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2" />
                </div>
                <div className="row mar-top5">
                  <div className="col-lg-2" />
                  <div className="col-lg-8">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        Receiver
                      </div>
                      <div className="card-body card-body-custom">
                        <div className="row">
                          <div className="col-lg-1" />
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Name :</span> {this.state.receiverName}
                          </div>
                          <div className="col-lg-3">
                            Phone : {this.state.receiverPhone}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Address :</span> {this.state.receiverAddress}
                          </div>
                          <div className="col-lg-2">
                          <span className="font-weight-bold">City :</span> {this.state.receiverCity}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-1" />
                          <div className="col-lg-3">
                          <span className="font-weight-bold">State :</span> {this.state.receiverState}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Zip :</span> {this.state.receiverZip}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Country :</span> {this.state.receiverCountry}
                          </div>
                          <div className="col-lg-2" />
                        </div>
                        <div className="row">
                          <div className="col-lg-1" />
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Bank :</span> {this.state.receiverBank}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Routing # :</span> {this.state.receiverRouting}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Acct :</span> {this.state.receiverAccount}
                          </div>
                          <div className="col-lg-2" />
                        </div>
                        <div className="row">
                          <div className="col-lg-1" />
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Clnt Rec Key :</span> {this.state.receiverRecKey}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">CPF :</span> {this.state.receiverCpf}
                          </div>
                          <div className="col-lg-5" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2" />
                </div>
                <div className="row mar-top5">
                  <div className="col-lg-2" />
                  <div className="col-lg-8">
                    <div className="card">
                      <div className="card-header bg-dark text-white card-header-custom">
                        Billing
                      </div>
                      <div className="card-body card-body-custom">
                        <div className="row">
                          <div className="col-lg-1" />
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Address :</span> {this.state.billingAddress}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Phone :</span> {this.state.billingPhone}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">City :</span> {this.state.billingCity}
                          </div>
                          <div className="col-lg-2" />
                        </div>
                        <div className="row">
                          <div className="col-lg-1" />
                          <div className="col-lg-3">
                          <span className="font-weight-bold">State :</span> {this.state.billingState}
                          </div>
                          <div className="col-lg-3">
                          <span className="font-weight-bold">Zip :</span> {this.state.billingZip}
                          </div>
                          <div className="col-lg-2">
                          <span className="font-weight-bold">Country :</span> {this.state.billingCountry}
                          </div>
                          <div className="col-lg-3" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2" />
                </div>
              </>
            ) :
            this.state.noDataUniAddr === true && (
              <div className="row">
                <div className="col-lg text-center notification">{this.state.mtuaMsg}</div>
              </div>
            )

          }

          <div className="text-center mar-top5">
            <button
              type="button"
              className="btn btn-warning mar-right10"
              onClick={this.onHandleBackUniAdrMTCNDetails}
              data-test="MTCNDetails-button-back"
            >
              MTCN Details
            </button>
            <button
              type="button"
              className="btn btn-warning"
              onClick={this.onHandleBackUniAdrMTCNSearch}
              data-test="Search-button-back"
            >
              SEARCH
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default MoneyTransferUniversalAddress;
