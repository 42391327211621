import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactARAD } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../header/header";
import { AiTwotoneEdit as AiTwotoneEditARAD } from "react-icons/ai";
import { IconContext as IconContextARAD } from "react-icons";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

import { getHeadersOrder } from "../utility/utility";

class AutoRefileAccountDetails extends Component {
  state = {
    id: "",
    originCountry: "",
    payCountry: "",
    mtType: "",
    refileReason: "",
    qccAccount: "",
    refileAccount: "",
    adjRequest: "N",
    draftRequest: "N",
    notifyRequest: "N",
    showRefileAccountRulesConfirm: false,
    autoRefileGridcolumns: [
      {
        headerName: "ACTION",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div
              className="cursor-pointer text-center"
              onClick={() => {
                if(!this.state.accessGrantedWriteARAD) { 
                  this.setState({
                    submitStatusRefileAccountRulesDetails: 'Error',
                    submitMsgRefileAccountRulesUpdate: "Update Not Allowed By This Operator",
                  });
                }else{this.handleEditRefileAccountRulesDetails(params)}
                }}
            >
              <IconContextARAD.Provider value={{ color: "#FDD017", size: "20px" }}>
                <AiTwotoneEditARAD />
              </IconContextARAD.Provider>
            </div>
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "70px",
      },
      { field: "originCountry", headerName: "ORIGIN CNTRY", width: "125px" },
      { field: "payCountry", headerName: "PAY CNTRY", width: "126px" },
      { field: "mtType", headerName: "MT TYPE", width: "127px" },
      { field: "refileReason", headerName: "REFILE REASON", width: "128px" },
      { field: "qccAccount", headerName: "QCC ACCT", width: "129px" },
      { field: "refileAccount", headerName: "REF ACCT", width: "130px" },
      { field: "adjRequest", headerName: "ADJ REQ", width: "131px" },
      { field: "draftRequest", headerName: "DRAFT REQ", width: "132px" },
      { field: "notifyRequest", headerName: "NOTIFY REQ", width: "133px" },
    ],
    rows: null,
    countries: [],
    mtTypesValues: [],
    refileReasonsValues: [],
    enableBtn: false,
    gridApi: null,
    paginationValQueue: "25",
    defaultColumnDef: {
      resizable: true,
      //flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusRefileAccountRulesDetails: null,
    submitMsgRefileAccountRulesUpdate: "",
    paginationOptionRefileAccountRulesUpdate: [25, 50, 100, 500],
    overlayLoadingTemplateARAD:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>',
  };

  onChangeControlRefileAccountRulesUpdateCharNumber = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onChangeControlRefileAccountRulesUpdate = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount(){
    const accessToken = this.props.authState.accessToken;
    try {
      const token = jwt_decode(accessToken.value);
      const id=jwt_decode(accessToken.value).sub;
      const operatorIdARAD = id.replace(/\D/g,'');
      const accessRole = jwt_decode(accessToken.value).role;
      const accessGrantARAD = 
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_REFILERULES);
      const accessGrantWriteARAD =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_REFILERULES);
      this.setState({
        accessToken: token,
        token:accessToken.value,
        role: accessRole,
        accessGrantedARAD: accessGrantARAD,
        accessGrantedWriteARAD: accessGrantWriteARAD,
        operatorIdARAD: operatorIdARAD
      }
       , () => {
        if(!this.state.accessGrantedARAD) { 
          this.setState({
            submitStatusRefileAccountRulesDetails: 'Error',
            submitMsgRefileAccountRulesUpdate: "Unauthorized!Access is denied.",
          });
        }
      }
      );
      // valid token format
    } catch (error) {
      // invalid token format
    }
    }

  onGridReadyRefileAccountRulesUpdate = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    if(!this.state.accessGrantedARAD) {
      this.setState({
        rows:[],
        overlayNoRowsTemplateARAD: '<span style="">Unauthorized! Access Is Denied.</span>',
      });
    } else {
      this.onLoadingRefileAccountRulesDetails();
    }
  };

  onLoadingRefileAccountRulesDetails = () =>{
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/autorefileaccount/businessrule`,
        {headers: {...getHeadersOrder().headers,Authorization: this.state.token,},}
      )
      .then((response) => {
        if(response.data.flag === 1){
          // console.log(response.data)
          this.setState({
            rows: response.data.records,
            countries: response.data.refileCntryList,
            mtTypesValues: response.data.mtTypeList,
            refileReasonsValues: response.data.refileReasonList,
          });
        }else{
          this.setState({
            rows:[],
            overlayNoRowsTemplateARAD:`<span style="">${response.data.message}</span>`
        });
        }
       
      });
  }
  handleEditRefileAccountRulesDetails = (params) => {
    let data = params.node.data;
    this.setState({
      id:
        data.originCountry +
        "" +
        data.payCountry +
        "" +
        data.refileReason +
        "" +
        data.qccAccount +
        "" +
        data.mtType,
      originCountry: data.originCountry,
      payCountry: data.payCountry,
      mtType: data.mtType,
      refileReason: data.refileReason,
      qccAccount: data.qccAccount,
      refileAccount: data.refileAccount,
      adjRequest: data.adjRequest,
      draftRequest: data.draftRequest,
      notifyRequest: data.notifyRequest,
      enableBtn: true,
    });
  };

  onPageSizeChangedRefileAccountRulesUpdate = (e) => {
    this.setState({ paginationValQueue: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackRefileAccountRulesUpdate = () => {
    this.props.history.push("/");
  };

  onValidateRefileAccountRulesDetails = () => {
    if (this.state.originCountry === "") {
      this.setState({
        submitStatusRefileAccountRulesDetails: "Error",
        submitMsgRefileAccountRulesUpdate: "Please Select The Value For Origin Cntry",
      });
      return false;
    }
    if (this.state.payCountry === "") {
      this.setState({
        submitStatusRefileAccountRulesDetails: "Error",
        submitMsgRefileAccountRulesUpdate: "Please Select The Value For Pay Cntry",
      });
      return false;
    }
    if (this.state.mtType === "") {
      this.setState({
        submitStatusRefileAccountRulesDetails: "Error",
        submitMsgRefileAccountRulesUpdate: "Please Select The Value For MT Type",
      });
      return false;
    }
    if (this.state.refileReason === "") {
      this.setState({
        submitStatusRefileAccountRulesDetails: "Error",
        submitMsgRefileAccountRulesUpdate: "Please Select The Value For Refile Reason",
      });
      return false;
    }
    // if (this.state.qccAccount === "") {
    //   this.setState({
    //     submitStatusRefileAccountRulesDetails: "Error",
    //     submitMsgRefileAccountRulesUpdate: "Please enter Qcc Account",
    //   });
    //   return false;
    // }
    // if (this.state.refileAccount === "") {
    //   this.setState({
    //     submitStatusRefileAccountRulesDetails: "Error",
    //     submitMsgRefileAccountRulesUpdate: "Please enter Refile Account",
    //   });
    //   return false;
    // }
    return true;
  };

  onHandleAddRefileAccountRulesDetails = () => {
    if (!this.onValidateRefileAccountRulesDetails()) {
      return;
    }
    let submittedObj = {};
    //submittedObj.id = "";
    submittedObj.originCountry = this.state.originCountry;
    submittedObj.payCountry = this.state.payCountry;
    submittedObj.mtType = this.state.mtType;
    submittedObj.refileReason = this.state.refileReason;
    // submittedObj.qccAccount = this.state.qccAccount.toUpperCase();
    submittedObj.refileAccount = this.state.refileAccount.toUpperCase();
    submittedObj.adjRequest = this.state.adjRequest;
    submittedObj.draftRequest = this.state.draftRequest;
    submittedObj.notifyRequest = this.state.notifyRequest;
    submittedObj.operatorId = this.state.operatorIdARAD;
    if(this.state.qccAccount === ""){
      submittedObj.qccAccount = " ";
    }else {
      submittedObj.qccAccount = this.state.qccAccount.toUpperCase();
    } 

    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/autorefileaccount/businessrule`,
        submittedObj,
        {headers: {...getHeadersOrder().headers,Authorization: this.state.token,"userId":this.state.operatorIdARAD},
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.data.responseCode === "200" && response.data.responseString==='Record Added Successfully') {
          submittedObj.id =
            this.state.originCountry +
            "" +
            this.state.payCountry +
            "" +
            this.state.refileReason +
            "" +
            this.state.qccAccount +
            "" +
            this.state.mtType;
          this.setState({
            submitStatusRefileAccountRulesDetails: "OK",
            submitMsgRefileAccountRulesUpdate: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusRefileAccountRulesDetails: "Error",
            submitMsgRefileAccountRulesUpdate: response.data.responseString,
          });
        }
        this.onHandleClearRefileAccountRulesDetails();
      });
  };

  onHandleUpdateRefileAccountRulesDetails = () => {
    if (!this.onValidateRefileAccountRulesDetails()) {
      return;
    }
    let submittedObj = {};
    //submittedObj.id = this.state.id;
    submittedObj.originCountry = this.state.originCountry;
    submittedObj.payCountry = this.state.payCountry;
    submittedObj.mtType = this.state.mtType;
    submittedObj.refileReason = this.state.refileReason;
    submittedObj.qccAccount = this.state.qccAccount;
    submittedObj.refileAccount = this.state.refileAccount;
    submittedObj.adjRequest = this.state.adjRequest;
    submittedObj.draftRequest = this.state.draftRequest;
    submittedObj.notifyRequest = this.state.notifyRequest;
    submittedObj.operatorId = this.state.operatorIdARAD;
    axios
      .put(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/autorefileaccount/businessrule`,
        submittedObj,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.state.token,
            "userId":this.state.operatorIdARAD
          },
        }
      )
      .then((response) => {
        if (response.data.responseCode === "200") {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) =>
              row.originCountry +
                "" +
                row.payCountry +
                "" +
                row.refileReason +
                "" +
                row.qccAccount +
                "" +
                row.mtType ===
              this.state.id
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusRefileAccountRulesDetails: "OK",
            submitMsgRefileAccountRulesUpdate: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusRefileAccountRulesDetails: "Error",
            submitMsgRefileAccountRulesUpdate: response.data.responseString,
          });
        }
        this.onHandleClearRefileAccountRulesDetails();
      });
  };

  onHandleClearRefileAccountRulesDetails = () => {
    this.setState({
      id: "",
      originCountry: "",
      payCountry: "",
      mtType: "",
      refileReason: "",
      qccAccount: "",
      refileAccount: "",
      adjRequest: "N",
      draftRequest: "N",
      notifyRequest: "N",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusRefileAccountRulesDetails: null,
        }),
      5000
    );
  };

  getRefileAccountRulesUpdateModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showRefileAccountRulesConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
               Are You Sure, You Want To Delete QCC Account {this.state.qccAccount} ?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() =>
                  this.setState({
                    showRefileAccountRulesConfirm: false,
                  })
                }
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteRefileAccountRulesDetails}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteRefileAccountRulesDetails = () => {
    if (this.state.qccAccount === "") {
      this.state.qccAccount = " ";
    }
    // console.log("QCCAccount:",this.state.qccAccount)
    axios
      .delete(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/autorefileaccount/businessrule/${this.state.originCountry}/${this.state.payCountry}/${this.state.mtType}/${this.state.refileReason}/${this.state.qccAccount}/`,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.state.token,
            "userId":this.state.operatorIdARAD
          },
        }
      )
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          // let newRows = this.state.rows.filter((row) => {
          //   return (
          //     row.originCountry +
          //       "" +
          //       row.payCountry +
          //       "" +
          //       row.refileReason +
          //       "" +
          //       row.qccAccount +
          //       "" +
          //       row.mtType !==
          //     this.state.originCountry +
          //       "" +
          //       this.state.payCountry +
          //       "" +
          //       this.state.refileReason +
          //       "" +
          //       this.state.qccAccount +
          //       "" +
          //       this.state.mtType
          //   );
          // });
          // console.log(newRows)
          this.setState({
            submitStatusRefileAccountRulesDetails: "OK",
            submitMsgRefileAccountRulesUpdate: response.data.responseString,
          });
          this.onLoadingRefileAccountRulesDetails();
        } else {
          this.setState({
            submitStatusRefileAccountRulesDetails: "Error",
            submitMsgRefileAccountRulesUpdate: response.data.responseString,
          });
        }
        this.onHandleClearRefileAccountRulesDetails();
      });
    this.setState({ showRefileAccountRulesConfirm: false });
  };

  getCountries = () => {
    if(this.state.countries.length > 0) {
      return this.state.countries.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else  {
      return null;
    }
    
  };

  getMtTypes = () => {
    if(this.state.mtTypesValues.length > 0) {
      return this.state.mtTypesValues.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else {
      return null;
    }
    
  };

  getRefileReasons = () => {
    if(this.state.refileReasonsValues.length > 0) {
      return this.state.refileReasonsValues.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else {
      return null;
    }
   
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="REFILE ACCOUNT RULES UPDATE"
        />

        <div className="container-fluid" data-test="component-network-list">
          
              <h4 className="card-title main-heading ">
                AUTO REFILE ACCOUNT BUSINESS RULE SCREEN
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  AUTO REFILE ACCOUNT FORM
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Origin Cntry</label>
                        <select
                          className="form-control  form-control-sm"
                          name="originCountry"
                          disabled={this.state.enableBtn}
                          value={this.state.originCountry}
                          data-test="text-originCountry"
                          onChange={
                            this.onChangeControlRefileAccountRulesUpdate
                          }
                        >
                          <option value=""> Select</option>
                          {this.getCountries()}
                        </select>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Pay Cntry</label>
                        <select
                          className="form-control  form-control-sm"
                          name="payCountry"
                          disabled={this.state.enableBtn}
                          value={this.state.payCountry}
                          data-test="text-payCountry"
                          onChange={
                            this.onChangeControlRefileAccountRulesUpdate
                          }
                        >
                          <option value="">Select</option>
                          {this.getCountries()}
                        </select>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">MT Type</label>
                        <select
                          className="form-control  form-control-sm"
                          name="mtType"
                          disabled={this.state.enableBtn}
                          value={this.state.mtType}
                          data-test="text-mtType"
                          onChange={
                            this.onChangeControlRefileAccountRulesUpdate
                          }
                        >
                          <option value=""> Select</option>
                          {this.getMtTypes()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Refile Reason</label>

                        <select
                          className="form-control  form-control-sm"
                          name="refileReason"
                          disabled={this.state.enableBtn}
                          value={this.state.refileReason}
                          data-test="text-refileReason"
                          onChange={
                            this.onChangeControlRefileAccountRulesUpdate
                          }
                        >
                          <option value=""> Select</option>
                          {this.getRefileReasons()}
                        </select>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="qccAccount" className="font-weight-bold">Qcc Account</label>
                       
                          <input
                            type="text"
                            maxLength="9"
                            disabled ={this.state.enableBtn}
                            value={this.state.qccAccount}
                            className="form-control form-control-sm"
                            name="qccAccount"
                            data-test="text-qccAccount"
                            placeholder="Qcc Account"
                            onChange={(e) => {
                              this.onChangeControlRefileAccountRulesUpdateCharNumber(e);
                            }}
                          />
                      
                      </div>
                      <div className="col-lg">
                        <label htmlFor="refileAccount" className="font-weight-bold">Refile Account</label>
                        <input
                          type="text"
                          maxLength="9"
                          value={this.state.refileAccount}
                          className="form-control form-control-sm"
                          name="refileAccount"
                          onChange={(e) => {
                            this.onChangeControlRefileAccountRulesUpdateCharNumber(
                              e
                            );
                          }}
                          data-test="text-refileAccount"
                          placeholder="Refile Account"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label className="font-weight-bold">Adj Req</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="adjRequest"
                              value="Y"
                              data-test="text-adjRequest-Yes"
                              checked={this.state.adjRequest === "Y"}
                              onChange={(e) => {
                                this.onChangeControlRefileAccountRulesUpdate(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="adjRequest"
                              value="N"
                              data-test="text-adjRequest-No"
                              checked={this.state.adjRequest === "N"}
                              onChange={(e) => {
                                this.onChangeControlRefileAccountRulesUpdate(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Draft Req</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="draftRequest"
                              value="Y"
                              data-test="text-draftRequest-Yes"
                              checked={this.state.draftRequest === "Y"}
                              onChange={(e) => {
                                this.onChangeControlRefileAccountRulesUpdate(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="draftRequest"
                              value="N"
                              data-test="text-draftRequest-No"
                              checked={this.state.draftRequest === "N"}
                              onChange={(e) => {
                                this.onChangeControlRefileAccountRulesUpdate(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg">
                        <label className="font-weight-bold">Notify Req</label>
                        <div className="row">
                          <div className="mar-right17" />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="notifyRequest"
                              value="Y"
                              data-test="text-notifyRequest-Yes"
                              checked={this.state.notifyRequest === "Y"}
                              onChange={(e) => {
                                this.onChangeControlRefileAccountRulesUpdate(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="Y">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="notifyRequest"
                              value="N"
                              data-test="text-notifyRequest-No"
                              checked={this.state.notifyRequest === "N"}
                              onChange={(e) => {
                                this.onChangeControlRefileAccountRulesUpdate(e);
                              }}
                            />
                            <label className="form-check-label font-weight-bold" htmlFor="N">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg" />
                      <div className="col-lg" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackRefileAccountRulesUpdate}
                          data-test="button-back"
                        >
                          MAINTENANCE MENU
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleAddRefileAccountRulesDetails}
                          data-test="button-add"
                          disabled={this.state.enableBtn || !this.state.accessGrantedWriteARAD}
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateRefileAccountRulesDetails}
                          data-test="button-update"
                          disabled={!this.state.enableBtn || !this.state.accessGrantedWriteARAD}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearRefileAccountRulesDetails}
                          disabled={!this.state.enableBtn || !this.state.accessGrantedWriteARAD}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({
                              showRefileAccountRulesConfirm: true,
                            });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn || !this.state.accessGrantedWriteARAD}
                        >
                          DELETE
                        </button>
                      </div>
                      <div className="col-lg-3">
                        
                      </div>
                    </div>

                    <div className="row mar-top10">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                        {this.state.submitStatusRefileAccountRulesDetails ===
                          "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgRefileAccountRulesUpdate}
                          </div>
                        )}
                        {this.state.submitStatusRefileAccountRulesDetails ===
                          "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgRefileAccountRulesUpdate}
                          </div>
                        )}
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getRefileAccountRulesUpdateModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  AUTO REFILE ACCOUNT DATA
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlRefileAccountRulesUpdate(e);
                        this.onPageSizeChangedRefileAccountRulesUpdate(e);
                      }}
                      value={this.state.paginationValQueue}
                      name="paginationDropdownQueue"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionRefileAccountRulesUpdate.map(
                        (val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactARAD
                      rowData={this.state.rows}
                      columnDefs={this.state.autoRefileGridcolumns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyRefileAccountRulesUpdate}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateARAD
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateARAD
                      }
                    ></AgGridReactARAD>
                  </div>
                </div>
              </div>
            </div>
          
      </>
    );
  }
}

export default withOktaAuth(AutoRefileAccountDetails);
