import React, { Component } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Form from "react-bootstrap/Form";
import Header from "../../../header/header";
import { getHeadersOrder,getEstDate } from "../../../utility/utility";

class QueueScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      queueName: "",
      queueGroup: "",
      count24Hours: "",
      countBw12to24Hours: "",
      countBw1to12Hours: "",
      count1Hours: "",
      checkboxOptions: [],
      records: [],
      arr: [],
      flagCheckedQS: false,
      rows: [],
      curTime: getEstDate(),
      date: "",
      enableBtn: false,
      gridApi: null,
      paginationValQueue: "25",
      defaultColumnDef: {
        resizable: true,
      },
      submitStatusQueueScreen: null,
      submitMsgQueueScreen: "",
      paginationOptionQueueScreen: [25, 50, 100, 500],
    };
  }

  onValidateQueueScreen = () => {
    if (this.state.arr.length < 1) {
      this.setState({
        submitStatusQueueScreen: "Error",
        submitMsgQueueScreen: "Please Select Atleast One Queue",
      });
      return false;
    }
    return true;
  };

  onChangeControlQueueScreen = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      accessGrantQAWM:this.props.location.state.data.accessGrantQAWM,
      accessGrantWriteQAWM:this.props.location.state.data.accessGrantWriteQAWM,
      operatorIdQAWM:this.props.location.state.data.operatorIdQAWM,
      tokenQAWM:this.props.location.state.data.tokenQAWM,
    })
    const submittedObjQS = { records: this.props.location.state.data.arr };
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/queuelist`,
        submittedObjQS,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.location.state.data.tokenQAWM,
            userId: this.props.location.state.data.operatorIdQAWM,
          },
        }
      )
      .then((response) => {
        let recQS = [];
        if(response.data.record !== null){
          recQS=response.data.records
        }
        this.setState({
          rows:recQS ,
        });
      });
  }

  onPageSizeChangedQueueScreen = (e) => {
    this.setState({ paginationValQueue: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onHandleBackQueueScreen = () => {
    this.props.history.push({
      pathname: "/queue-age-monitor",
      state: this.props.location.state.data,
    });
  };
  covertToLegacyQS = (date) => {
    let newDate = new Date(date);
    let newStartDateQS = [
      ("0" + (newDate.getMonth() + 1)).slice(-2),
      ("0" + newDate.getDate()).slice(-2),
      newDate.getFullYear(),
    ].join("/");
    return newStartDateQS;
  };
  onHandleSearchQueueScreen = () => {
    if (!this.onValidateQueueScreen()) {
      return;
    }

    let submittedObjQS = {};
    submittedObjQS.mtcn = this.state.mtcn;
    submittedObjQS.startdate =
      this.state.date === "" ? "" : this.covertToLegacyQS(this.state.date);
    submittedObjQS.queueNameList = this.state.arr;
    axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/queuetransaction`,
        submittedObjQS,
        {
          headers: {
            ...getHeadersOrder().headers,
            Authorization: this.props.location.state.data.tokenQAWM,
            userId: this.props.location.state.data.operatorIdQAWM,
          },
        }
      )
      .then((response) => {
        this.props.history.push({
          pathname: "/queue-trans-screen",
          state: {
            data: this.props.location.state.data,
            flag: true,
            records: response.data.records,
          },
        });
      });
  };

  onSelectionChanged = (el, e) => {
    
    const obj = el;
    obj.checked = !obj.checked;
    let checkedArray = this.state.arr;

    let arrayIndex = checkedArray.indexOf(obj.qdtQueId);

    if (arrayIndex === -1 && obj.checked === true) {
      checkedArray.push(obj.qdtQueId);

      this.setState({ arr: checkedArray });
    } else {
      let array = this.state.arr;
      array.splice(arrayIndex, 1);
      this.setState({ arr: array });
    }
  };
  render() {
    return (
      <>
        <Header history={this.props.history} heading="QUEUE AGE MONITOR " />
        <div className="container-fluid" data-test="component-network-list">
          <div className="card-body">
            <h4 className="card-title main-heading">QUEUE SCREEN</h4>
            <div><b>Date/Time:{this.state.curTime}</b></div>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                QUEUE SCREEN
              </div>
              
              <div className="card-body card-body-custom">
                <div className="pagination-dropdown">
                  <label htmlFor="pagination-select-label">
                    No of records per page:
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.onChangeControlQueueScreen(e);
                      this.onPageSizeChangedQueueScreen(e);
                    }}
                    value={this.state.paginationValQueue}
                    name="paginationDropdownQueueScreen"
                    data-test="select-dropdown"
                  >
                    {this.state.paginationOptionQueueScreen.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <table class="table table-bordered" data-test="table">
                  <thead class="table-dark text-center">
                    <tr>
                      <th></th>
                      <th>QUEUE NAME</th>
                      <th>QUEUE GROUP</th>
                      <th>COUNT &gt; 24 HRS</th>
                      <th>COUNT B/W 12-24 HRS</th>
                      <th>COUNT B/W 1-12 HRS</th>
                      <th>COUNT &lt; 1 HRS</th>
                    </tr>
                  </thead>
                  <tbody className=" text-center">
                    {this.state.rows.map((el, i) => (
                      <tr key={i} data-test="tr">
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={el.flagCheckedQS}
                              id="flagCheckedQS"
                              name="flagCheckedQS"
                              onClick={(e) => {
                                this.onSelectionChanged(el, e);
                              }}
                              data-test="select-checkbox"
                            />
                          </div>
                        </td>
                        <td>{el.qdtQueDesc}</td>
                        <td>{el.qdtQueGroup}</td>
                        <td>{el.count24Hours}</td>
                        <td>{el.countBw12to24Hours}</td>
                        <td>{el.countBw1to12Hours}</td>
                        <td>{el.count1Hours}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="card-body file-alert">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <label htmlFor="mtcn">MTCN</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="mtcn"
                          value={this.state.mtcn}
                          onChange={(e) => {
                            this.onChangeControlQueueScreen(e);
                          }}
                          data-test="text-mtcn"
                        />
                      </div>
                      <div className="col-lg-6">
                        <Form.Group>
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={this.state.date}
                            onChange={(e) => {
                              this.onChangeControlQueueScreen(e);
                            }}
                            data-test="text-startDate"
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 text-center mar-bottom5">
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleSearchQueueScreen}
                    data-test="button-search"
                  >
                    SEARCH
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10 "
                    onClick={this.onHandleBackQueueScreen}
                    data-test="button-back"
                  >
                    QUEUE GROUPS
                  </button>
                </div>
                <div className="col-lg-3"></div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4" />
                <div className="col-lg-4">
                  {this.state.submitStatusQueueScreen === "OK" && (
                    <div className="alert alert-success alert-padding notification text-center">
                      {this.state.submitMsgQueueScreen}
                    </div>
                  )}
                  {this.state.submitStatusQueueScreen === "Error" && (
                    <div className="alert alert-danger alert-padding notification text-center">
                      {this.state.submitMsgQueueScreen}
                    </div>
                  )}
                </div>
                <div className="col-lg-4" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default QueueScreen;
