import React, { Component } from "react";
import axios from "axios";
import { AiOutlineZoomIn } from "react-icons/ai";
import { IconContext } from "react-icons";
import { Button, Modal } from "react-bootstrap";
import Header from "../../header/header";
import { getHeadersTipLog } from "../../utility/utility";


class TipLogAccountDetails extends Component {
  state = {
    displayRecordsTLAD: [],
    stateDataTLAD:null,
    disableFirstTLAD: true,
    disableLastTLAD: false,
    disablePreviousTLAD: true,
    disableNextTLAD: false,
    noOfRecordsPerPageTLAD: 0,
    totalNoofRecordsTLAD:0,
    pageNumberTLAD:1,
    totalNumberOfPagesTLAD:0,
    isLoadingPrev: false,
    isLoadingNext: false,
    isLoadingFirst: false,
    isLoadingLast: false,
    showTipLogAccountDetailsModal: false,
    currentModalRecord: {}
  };

  componentDidMount() {
    // console.log(this.props.history.location.totalNoOfRecordsALS);
    // console.log(this.props.history.location.submittedData);
    
  
    this.setState({
      stateDataTLAD: this.props.history.location.submittedData,
      totalNoofRecordsTLAD: this.props.history.location.totalNoOfRecordsALS,
      noOfRecordsPerPageTLAD: this.props.history.location.submittedData.pageRecordsALS,
      totalNumberOfPagesTLAD: Math.ceil(this.props.history.location.totalNoOfRecordsALS / this.props.history.location.submittedData.pageRecordsALS) 
    });
    
    if(this.props.history.location.records && this.props.history.location.records.length > 0) {
      this.setState({
        displayRecordsTLAD: this.props.history.location.records
      });
      if(this.props.history.location.totalNoOfRecordsALS <= this.props.history.location.submittedData.pageRecordsALS) {
        this.setState({
          disableLastTLAD: true,
          disableNextTLAD: true
        });
      }
    } else {
      this.setState({
        disableLastTLAD: true,
        disableNextTLAD: true
      });
    }
  }

  onHandleBackTLAD = () => {
    this.props.history.push({
      pathname: "/account-log-search",
      accessGrantedReadTL: this.props.history.location.accessGrantedReadTL,
      authorization: this.props.history.location.authorization,
      userId:this.props.history.location.userId
    });
  };

  onHandleFirstTLAD = () => {
    this.setState({
      pageNumberTLAD: 1
    }, ()=> {
      this.setState({
        disableFirstTLAD: true,
        disablePreviousTLAD: true,
        disableNextTLAD: false,
        disableLastTLAD: false
      });
      this.getTipLogAccountDetailsData('isLoadingFirst');
    });
  };

  onHandlePreviousTLAD = () => {
    this.setState({
      pageNumberTLAD: this.state.pageNumberTLAD - 1,
    }, () => {
      if(this.state.pageNumberTLAD === 1) {
        this.setState({
          disableFirstTLAD: true,
          disablePreviousTLAD: true,
          disableNextTLAD: false,
          disableLastTLAD: false
        });
      } else {
        this.setState({
          disableNextTLAD: false,
          disableLastTLAD: false
        });
      }
      this.getTipLogAccountDetailsData('isLoadingPrev');
    });
  };

  onHandleNextTLAD = () => {
    this.setState({
      pageNumberTLAD: this.state.pageNumberTLAD + 1,
    }, () => {
      if(this.state.pageNumberTLAD < this.state.totalNumberOfPagesTLAD) {
        this.setState({
          disableFirstTLAD: false,
          disablePreviousTLAD: false
        });
      } else {
        this.setState({
          disableNextTLAD: true,
          disableLastTLAD: true,
          disableFirstTLAD: false,
          disablePreviousTLAD: false
        });
      }
      this.getTipLogAccountDetailsData('isLoadingNext');
    });
  };


  onHandleLastTLAD = () => {
    this.setState({
      pageNumberTLAD: this.state.totalNumberOfPagesTLAD,
    }, ()=> {
      this.setState({
        disableFirstTLAD: false,
        disablePreviousTLAD: false,
        disableNextTLAD: true,
        disableLastTLAD: true
      });
      this.getTipLogAccountDetailsData('isLoadingLast');
    });
  };

  getTipLogAccountDetailsData = (buttonType) => {
    let submittedObjALS = {};
    submittedObjALS.accountNumber = this.state.stateDataTLAD.accountNumberALS;
    submittedObjALS.startDate = this.state.stateDataTLAD.startDateALS;
    submittedObjALS.endDate = this.state.stateDataTLAD.endDateALS;
    submittedObjALS.pageRecords = this.state.stateDataTLAD.pageRecordsALS;
    submittedObjALS.pageNumber = this.state.pageNumberTLAD;
    this.buttonEnableDisable(buttonType, true);
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_TIPLOG_SERVICE_URL}/v1/sha/dbview/tiplog/Acclogsearch`,submittedObjALS, 
        {
          headers: {
            ...getHeadersTipLog().headers,
            Authorization: this.props.authorization,
            userId:this.props.userId
          },
        })
        .then((response) => {
          this.buttonEnableDisable(buttonType, false);
          this.setState({
          displayRecordsTLAD:response.data.records
        });
      });
  }

  buttonEnableDisable = (buttonType, status) => {
    this.setState({
      [buttonType]: status
    })
  }

  accountDetailsData = () => {
    return this.state.displayRecordsTLAD.length > 0 ? this.state.displayRecordsTLAD.map((record, i) => {
      return (<tr key={`${i}+len`}>
        <td className="text-nowrap">{
           <div
            className="cursor-pointer text-center"
            data-test='button-account-popup-details'
            onClick={() => this.handleAccountPopupDetails(record)}
          >
            <IconContext.Provider value={{ color: "#FDD017", size: "20px" }}>
              <AiOutlineZoomIn />
            </IconContext.Provider>
          </div>
        }</td>
        <td className="text-nowrap">{record.accountNumber}</td>
        <td className="text-nowrap">{record.type}</td>
        <td className="text-nowrap">{record.date}</td>
        <td className="text-nowrap">{record.batchId}</td>
        <td className="text-nowrap">{record.recordNumber}</td>
        <td className="text-nowrap">{record.bufferData}</td>
      </tr>)
    }): <tr><td colSpan='7'><div className="text-center font-weight-bold">No Record Found</div></td></tr>;
  }

  getTipLogAccountDetailsHeaders = () => {
    let headersTLAD = ['ACT', 'ACCOUNT NBR', 'TYPE', 'DATE', 'BATCH ID', 'RECORD NUM ', 'BUFFER DATA'];
    return headersTLAD.map((item, i) => (
      <th key={i} className="text-nowrap">
        {item}
      </th>
    ));
  }

  getTLADButtons = () => {
    return (
      <>
      {this.state.isLoadingFirst ? 
        <button className="btn btn-warning mar-right5" type="button" disabled>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;&nbsp;Loading...
        </button> :
        
        !this.props.history.location.accessGrantedReadTL?<button
          type="button"
          className="btn btn-warning mar-right5"
          disabled
        >
          FIRST
        </button> :
        <button
          type="button"
          className="btn btn-warning mar-right5"
          data-test="button-first"
          onClick={this.onHandleFirstTLAD}
          disabled={this.state.disableFirstTLAD}
        >
          FIRST
        </button>
        }
        {this.state.isLoadingPrev ? 
        <button className="btn btn-warning mar-right5" type="button" disabled>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;&nbsp;Loading...
        </button> : 
        !this.props.history.location.accessGrantedReadTL?
        <button
          type="button"
          className="btn btn-warning mar-right5"
          disabled
        >
          PREVIOUS
        </button>:
        <button
          type="button"
          className="btn btn-warning mar-right5"
          data-test="button-previous"
          onClick={this.onHandlePreviousTLAD}
          disabled={this.state.disablePreviousTLAD}
        >
          PREVIOUS
        </button>
        }
        {this.state.isLoadingNext ? 
        <button className="btn btn-warning mar-right5" type="button" disabled>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;&nbsp;Loading...
        </button> : 
         !this.props.history.location.accessGrantedReadTL?<button
          type="button"
          className="btn btn-warning mar-right5"
          disabled
        >
          NEXT
        </button>
        :
        <button
          type="button"
          className="btn btn-warning mar-right5"
          data-test="button-next"
          onClick={this.onHandleNextTLAD}
          disabled={this.state.disableNextTLAD}
        >
          NEXT
        </button>
        }
        {this.state.isLoadingLast ? 
        <button className="btn btn-warning mar-right5" type="button" disabled>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;&nbsp;Loading...
        </button> : 
        !this.props.history.location.accessGrantedReadTL?<button
          type="button"
          className="btn btn-warning mar-right5"
          disabled
        >
          LAST
        </button>:
        <button
          type="button"
          className="btn btn-warning mar-right5"
          data-test="button-last"
          onClick={this.onHandleLastTLAD}
          disabled={this.state.disableLastTLAD}
        >
          LAST
        </button>
        }
        <button
          type="button"
          className="btn btn-warning mar-right5"
          data-test="button-Back"
          onClick={this.onHandleBackTLAD}
        >
          TIPLOG SEARCH
        </button>
    </>
  );
  }

  handleAccountPopupDetails = (record) => {
    this.setState({
      showTipLogAccountDetailsModal: true,
      currentModalRecord: record
    });
  }
  
  getTipLogAccountDetailsModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showTipLogAccountDetailsModal}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          {/* <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">
               This is title
              </h5>
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <div className="card-body">
              <h4 className="card-title main-heading">ACCOUNT {this.state.currentModalRecord.type === 'CSC' ? 'CSC' : 'AS400'} FIELD DETAILS</h4>
              {this.getCloseButton()}
              {
                this.state.currentModalRecord.type === 'CSC' ?
                (
                  <>
                  <div className="card">
                    <h4 className="card-title main-heading">CSC FIELD DETAILS</h4>
                    <div className="card-body card-body-custom">
                      <div className="row">
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Account ID:</span> {this.state.currentModalRecord.accountCSCFieldDetails.accountId}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Account Rev:</span> {this.state.currentModalRecord.accountCSCFieldDetails.accountRev}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Operator ID:</span> {this.state.currentModalRecord.accountCSCFieldDetails.operatorId}
                        </div>
                        <div className="col-lg-6">
                          <span className="font-weight-bold">Terminal ID:</span> {this.state.currentModalRecord.accountCSCFieldDetails.terminalId}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mar-top10">
                    <table className="table table-bordered margin-0 table-sm">
                      <thead>
                        <tr>
                          <th scope="col" className="font-weight-bold">FIELD ID</th>
                          <th scope="col" className="font-weight-bold">TYPE</th>
                          <th scope="col" className="font-weight-bold">FIELD DATA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.currentModalRecord.accountCSCFieldDetails && this.state.currentModalRecord.accountCSCFieldDetails.fieldRecords.length > 0 && this.state.currentModalRecord.accountCSCFieldDetails.fieldRecords.map((data, iInner) => (
                            <tr key={`${iInner}+len`}>
                              <td>{data.fieldId}</td>
                              <td>{data.fieldType}</td>
                              <td>{data.fieldData}</td>
                            </tr>
                          ))
                         }
                      </tbody>
                    </table>
                  </div>
                  </>
                )
                :
                (
                  <div className="table-responsive">
                    <table className="table table-bordered margin-0 table-sm">
                      <thead>
                        <tr>
                          <th scope="col" className="font-weight-bold">FIELD ID</th>
                          <th scope="col" className="font-weight-bold">FIELD DATA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.currentModalRecord.accountCSCFieldDetails && this.state.currentModalRecord.accountCSCFieldDetails.fieldRecords.length > 0 && this.state.currentModalRecord.accountCSCFieldDetails.fieldRecords.map((data, iInner) => (
                            <tr key={`${iInner}+len`}>
                              <td>{data.fieldId}</td>
                              <td>{data.fieldData}</td>
                            </tr>
                          ))
                         }
                      </tbody>
                    </table>
                  </div>
                )
              }
              {this.getCloseButton()}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  getCloseButton = () => {

    return (
        <div className="mtcn-list-button-container mar-top-bottom-10">
          <Button
            variant="warning"
            onClick={() =>
              this.setState({
                showTipLogAccountDetailsModal: false,
              })
            }
            data-test="button-close"
          >
            Close
          </Button>
        </div>
    )
  }
  render() {
    return (
      <>
        <Header history={this.props.history} heading="" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading">AS400 ACCOUNT DETAILS</h4>
            <div className="card ">
              <div className="card-body card-body-custom padding-10">
              <div className="row">
                <div className="col-lg-5">
                  {this.getTLADButtons()}
                </div>
                <div className="col-lg-7 padding-7">
                <span className="font-weight-bold">Page:</span> {this.state.pageNumberTLAD}/{this.state.totalNumberOfPagesTLAD} of Max {this.state.noOfRecordsPerPageTLAD} Records Per Page
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-bordered mar-top10">
                  <thead>
                    <tr>
                     {this.getTipLogAccountDetailsHeaders()}
                    </tr>
                  </thead>
                  <tbody>
                    {this.accountDetailsData()}
                  </tbody>
                </table>
              </div>
              {this.getTipLogAccountDetailsModal()}
              <div className="row mar-top10">
                <div className="col-lg-5">
                  {this.getTLADButtons()}
                </div>
                <div className="col-lg-7"></div>
              </div>
              </div>    
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TipLogAccountDetails;
