import React, { Component } from "react";

import { Nav } from "react-bootstrap";
import {
  FaNetworkWired,
  FaPhoneSquare,
  FaListAlt,
  FaHandshake,
} from "react-icons/fa";
import { MdPeople, MdPayment } from "react-icons/md";
import { IoIosFolderOpen, IoIosFolder } from "react-icons/io";

import "./sidebar.css";

export default class Sidebar extends Component {
  state = {
    phone: false,
    name: false,
    corridor: false,
    corridor2: false,
    network: false,
    iso: false,
    quick: false,
    settelement: false,
  };
  onHandleClick = (item) => {
    if (item === "Phone") {
      this.setState({
        phone: true,
        name: false,
        corridor: false,
        corridor2: false,
        network: false,
        iso: false,
        quick: false,
        settelement: false,
      });
    }
    if (item === "Name") {
      this.setState({
        phone: false,
        name: true,
        corridor: false,
        corridor2: false,
        network: false,
        iso: false,
        quick: false,
        settelement: false,
      });
    }
    if (item === "Corridor") {
      this.setState({
        phone: false,
        name: false,
        corridor: true,
        corridor2: false,
        network: false,
        iso: false,
        quick: false,
        settelement: false,
      });
    }
    if (item === "Corridor2") {
      this.setState({
        phone: false,
        name: false,
        corridor: false,
        corridor2: true,
        network: false,
        iso: false,
        quick: false,
        settelement: false,
      });
    }
    if (item === "Network") {
      this.setState({
        phone: false,
        name: false,
        corridor: false,
        corridor2: false,
        network: true,
        iso: false,
        quick: false,
        settelement: false,
      });
    }
    if (item === "Iso") {
      this.setState({
        phone: false,
        name: false,
        corridor: false,
        corridor2: false,
        network: false,
        iso: true,
        quick: false,
        settelement: false,
      });
    }
    if (item === "Quick") {
      this.setState({
        phone: false,
        name: false,
        corridor: false,
        corridor2: false,
        network: false,
        iso: false,
        quick: true,
        settelement: false,
      });
    }
    if (item === "Settlement") {
      this.setState({
        phone: false,
        name: false,
        corridor: false,
        corridor2: false,
        network: false,
        iso: false,
        quick: false,
        settelement: true,
      });
    }
    if (this.props) {
      this.props.changeComponent(item);
    }
  };

  render() {
    return (
      <div className="my-navbar">
        <Nav className="flex-column">
          <Nav.Item
            onClick={() => {
              this.onHandleClick("Phone");
            }}
            className={this.state.phone ? "selected" : ""}
            data-test="link-phonenumber"
          >
            <FaPhoneSquare
              size={20}
              color={this.state.phone ? "black" : "#f7bf07"}
              className="icon"
            />
            PHONE BLOCK
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              this.onHandleClick("Name");
            }}
            className={this.state.name ? "selected" : ""}
            data-test="link-name"
          >
            <MdPeople
              size={20}
              color={this.state.name ? "black" : "#f7bf07"}
              className="icon"
            />
            NAME BLOCK
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              this.onHandleClick("Corridor");
            }}
            className={this.state.corridor ? "selected" : ""}
            data-test="link-corridor"
          >
            <IoIosFolderOpen
              size={20}
              color={this.state.corridor ? "black" : "#f7bf07"}
              className="icon"
            />
            CORRIDOR BLOCK
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              this.onHandleClick("Corridor2");
            }}
            className={this.state.corridor2 ? "selected" : ""}
            data-test="link-corridor2"
          >
            <IoIosFolder
              size={20}
              color={this.state.corridor2 ? "black" : "#f7bf07"}
              className="icon"
            />
            CORRIDOR BLOCK 2
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              this.onHandleClick("Network");
            }}
            className={this.state.network ? "selected" : ""}
            data-test="link-network"
          >
            <FaNetworkWired
              size={20}
              color={this.state.network ? "black" : "#f7bf07"}
              className="icon"
            />
            CROSS-NETWORK AGENT UPDATE
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              this.onHandleClick("Iso");
            }}
            className={this.state.iso ? "selected" : ""}
            data-test="link-iso"
          >
            <FaListAlt
              size={20}
              color={this.state.iso ? "black" : "#f7bf07"}
              className="icon"
            />
            ISO REGISTRATION BLOCK
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              this.onHandleClick("Quick");
            }}
            className={this.state.quick ? "selected" : ""}
            data-test="link-quick"
          >
            <MdPayment
              size={20}
              color={this.state.quick ? "black" : "#f7bf07"}
              className="icon"
            />
            QUICK PAY LIMIT BLOCK
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              this.onHandleClick("Settlement");
            }}
            className={this.state.settelement ? "selected" : ""}
            data-test="link-settelement"
          >
            <FaHandshake
              size={20}
              color={this.state.settelement ? "black" : "#f7bf07"}
              className="icon"
            />
            SETTLEMENT REASON CODE BLOCK
          </Nav.Item>
        </Nav>
      </div>
    );
  }
}
