import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import axios from "axios";
import { getHeadersOrder } from "../../utility/utility";
import Header from "../../header/header";

class MoneyTransferRDBMSSearchKeyDetails extends Component {
  state = {
    mtcn: "",
    columns: [
      {
        field: "mtcn",
        headerName: "MTCN",
        width: 150,
      },
      {
        field: "julDate",
        headerName: "JULIAN",
        width: 150,
      },
      {
        field: "type",
        headerName: "TYP",
        width: 60,
      },
      {
        field: "key",
        headerName: "KEY",
        width: 150,
      },
      {
        field: "phone",
        headerName: "PHONE",
        width: 150,
      },
      {
        field: "mobCcd",
        headerName: "MOBCCD",
        width: 100,
      },
      {
        field: "priCcd",
        headerName: "PRICCD",
        width: 100,
      },
      {
        field: "path",
        headerName: "PATH",
        width: 60,
      },
      {
        field: "status",
        headerName: "STATUS",
        width: 80,
      },
      {
        field: "runa",
        headerName: "RUNA",
        width: 60,
      },
      {
        field: "fix",
        headerName: "FIX",
        width: 60,
      },
      {
        field: "recDate",
        headerName: "REC DATE",
        width: 150,
      },
      {
        field: "prinb",
        headerName: "PRINB",
        width: 60,
      },
      {
        field: "payPrin",
        headerName: "PAY PRIN",
        width: 100,
      },
      {
        field: "deliveryservice",
        headerName: "DELIV SERV",
        width: 100,
      },
      {
        field: "payee",
        headerName: "PAYEE",
        width: 150,
      },
      {
        field: "sender",
        headerName: "SENDER",
        width: 150,
      },
      {
        field: "orignCity",
        headerName: "OCITY",
        width: 100,
      },
      {
        field: "orignState",
        headerName: "OSTATE",
        width: 100,
      },
      {
        field: "bqc",
        headerName: "BQC",
        width: 80,
      },
      {
        field: "qccId",
        headerName: "QCC ID",
        width: 80,
      },
      {
        field: "mcurtain",
        headerName: "MCURTAIN",
        width: 100,
      },
      {
        field: "orignCountry",
        headerName: "OCNTRY",
        width: 100,
      },
      {
        field: "account",
        headerName: "ACCOUNT",
        width: 100,
      },
      {
        field: "prodCode",
        headerName: "PROD CODE",
        width: 100,
      },
      {
        field: "destCountry",
        headerName: "DCNTRY",
        width: 100,
      },
      {
        field: "scurtain",
        headerName: "SCURTAIN",
        width: 100,
      }
      // ,
      // {
      //   field: "dbKey",
      //   headerName: "DBKEY",
      //   width: 80,
      // },
    ],
    rows: null,
    overlayLoadingTemplateRdbms:
    '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateRdbms:
    '<span style="">No Records Found</span>',
    paginationValRDBMSSKMTCN: "25",
    defaultColumnDef: {
      resizable: true,
    },
    paginationOptionTextMTCN: [25, 50, 100, 500],
  };

  onPageSizeChangedRDBMSSKMTCN = (e) => {
    this.setState({ paginationValRDBMSSKMTCN: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };

  onChangeControlRDBMSSKMTCN = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      mtcn: this.props.history.location.mtcn,
      jwtToken: this.props.history.location.jwtToken
    });
  }
  
  onGridReadyRDBMSSKMTCN = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    let submittedObj = {};
    submittedObj.mtcn = this.state.mtcn;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/moneytransferutility/rdms`, submittedObj,  {
        headers: {
          ...getHeadersOrder().headers,
          Authorization: this.state.jwtToken
        },
      })
      .then((response) => {
        if(response.data.flag === 1) {
          this.setState({
            rows: response.data.records
          });
        } else {
          this.setState({
            rows:[],
            overlayNoRowsTemplateRdbms:`<span style="">${response.data.message}</span>`
          });
        }
      });
  };

  onHandleBackRDBMSSKMTCNDetails = () => {
    this.props.history.push({
      pathname: "/mt-database-money-transfer-record",
      jwtToken: this.state.jwtToken
    });
  };

  onHandleBackRDBMSSKMTCNSearch = () => {
    this.props.history.push({
      pathname: "/money-transfer-database-utility",
    });
  };

  render() {
    return (
      <div>
        <Header history={this.props.history} heading="MONEY TRANSFER UTILITY" />
        <div className="container-fluid">
          <div className="card-body">
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                MONEY TRANSFER RDMS SEARCH KEY DETAILS
              </div>
              <div className="card-body card-body-custom">
                <div className="form-row">
                  <div className="col-lg">
                    <label htmlFor="mtcn" className="font-weight-bold">MTCN: {this.state.mtcn}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <div className="pagination-dropdown float-left">
                      <label htmlFor="pagination-select-label" className="font-weight-bold">
                        No of records per page:
                      </label>
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          this.onChangeControlRDBMSSKMTCN(e);
                          this.onPageSizeChangedRDBMSSKMTCN(e);
                        }}
                        value={this.state.paginationValRDBMSSKMTCN}
                        name="paginationDropdownRDBMSSKMTCN"
                        data-test="select-dropdown"
                      >
                        {this.state.paginationOptionTextMTCN.map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div
                  className="ag-theme-balham "
                  style={{ height: 250, width: "100%" }}
                >
                  <AgGridReact
                    rowData={this.state.rows}
                    columnDefs={this.state.columns}
                    defaultColDef={this.state.defaultColumnDef}
                    pagination={true}
                    paginationPageSize={25}
                    onGridReady={this.onGridReadyRDBMSSKMTCN}
                    overlayLoadingTemplate={this.state.overlayLoadingTemplateRdbms}
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplateRdbms}
                    data-test="component-grid"
                    enableCellTextSelection={true}
                  ></AgGridReact>
                </div>

                <div className="text-center mt-2">
                  <button
                    type="button"
                    className="btn btn-warning mr-2"
                    onClick={this.onHandleBackRDBMSSKMTCNDetails}
                    data-test="MTCNDetails-button-back"
                  >
                    MTCN Details
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning mar-right10"
                    onClick={this.onHandleBackRDBMSSKMTCNSearch}
                    data-test="Search-button-back"
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MoneyTransferRDBMSSearchKeyDetails;
